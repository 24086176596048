.tab-content--container{
  padding-bottom: 25px;
  &.seashell{
    background: $seashell;
  }
  h2{
    text-align: center;
    margin-top: 35px;
    display: inline-block;
    width: 100%;
  }
  $sec-nav-bg: $seashell;
  $sec-nav-menu-width: auto;
  .sec-navigation-wrapper {
    flex-grow: 1;
    display: block;
    padding: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @media screen and (min-width: 1200px) {
      width: $sec-nav-menu-width;
    }

    // overflow scroll hiding effect
    @mixin sec-nav-overflow-bg($direction: 90deg) {
      background-image: -webkit-gradient(linear, left top, left bottom, from($direction), color-stop(30%, rgba($sec-nav-bg, 1)), to(rgba($white, 0)));
      background-image: -webkit-linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
      background-image: -o-linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
      background-image: linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 30px;
      height: 100%;
      top: 0;
      z-index: 2;
    }

    &:before {
      @include sec-nav-overflow-bg(90deg);
      left: -15px;
    }

    &:after {
      @include sec-nav-overflow-bg(270deg);
      right: 0;
    }
    .sec-navigation {
      display: block;
      padding: 0;
      position: relative;
      overflow-x: auto;
      text-align: center;
      margin-bottom: -5px;

      &::-webkit-scrollbar { display: none; }

      @media screen and (max-width: 1199px) {
        overflow-x: scroll;
        -webkit-user-select: none;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
      }

      ul {
        display: inline-block;
        position: relative;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0;

        // prevent selection
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */

        &:hover,
        &:focus {
          cursor: pointer;
        }

        li {
          list-style-type: none;
          display: inline-block;
          margin: 0;
          padding: 0 20px 0 20px;
          text-align: center;
          vertical-align: middle;
          margin-bottom: -1px;
          &:first-child {
            padding-left: 15px;

            @media screen and (max-width: 479px) {
              padding-left: 30px;
            }
          }

          &:last-child {
            padding-right: 30px;
          }

          a {
            float: left;
            @include font-size(16);
            font-family: $font-family-medium;
            text-align: center;
            background: 0;
            &:hover {
              color: $errorred;
            }
          }

          .hvr-underline-from-left {
            &:before {
              background: $errorred;
            }
          }

          .btn-primary {
            text-align: center;
            margin-top: 0;
            font-family: $font-family;
            padding: 12px 30px 14px;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
  ul{
    &.nav-tabs{
      padding: 0 6% 0 8% !important;
      margin-bottom: 40px;
      li{
        float: none;
        a{
          font-family: $font-family-medium;
          color: $countdownblue;
          @include font-size(14);
          padding: 15px 14px;
          &:hover{
            background: transparent;
            border-top: 1px solid $seashell;
            border-left: 1px solid $seashell;
            border-right: 1px solid $seashell;
          }
        }
        &.active{
          margin-top: 3px;
          a{
            border: 0;
            color: $errorred;
            padding: 13px 15px;
            border-bottom: 3px solid $errorred;
            &:before{
              height: 0;
            }
          }
        }
        &:before{
          background: $errorred;
        }
      }
    }
  }
  .tab-content{
    .tab-pane{
      padding: 0 8%;
    }
  }
  .sec-nav--arrow{
    position: absolute;
    @include font-size(24);
    @include opacity(0.2);
    @include z-index(passengarDetails);
    display: none;
    &.left{
      left: 15px;
      top: 8px;
    }
    &.right{
      right: 15px;
      top: 8px;
    }
  }
}

@include respond-to(tablet-portrait){
  .tab-content--container{
    .sec-navigation-wrapper{
      .sec-navigation{
        ul{
          li{
            padding: 0 10px;
          }
        }
      }
    }
  }
  .sec-nav--arrow{
    &.left{
      left: 15px;
      top: 4px;
    }
    &.right{
      right: 15px;
      top: 4px;
    }
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
  .tab-content--container{
    .sec-navigation-wrapper{
      .sec-navigation{
        ul{
          margin-bottom: 5px;
          li{
            margin-bottom: -1px;
            a{
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .sec-nav--arrow{
    &.left{
      left: 15px;
      top: 6px;
    }
    &.right{
      right: 15px;
      top: 6px;
    }
  }
}