#view-booking--container, #view-nfs--container, #mhupgrade-in--container, #ibe-stay--container, #view-upgrade {
  @include z-index(bookflight);
  background-color: $white;
  height: auto;
  // position: relative;
  display: none;

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    width: 100%;
    max-width: $ibe-width-less-than-1200;
    margin: 0 auto;
  }

  @media screen and (min-width: 1440px) {
    width: $ibe-width-more-than-1200;
    // margin-left: $ibe-left-margin-more-than-1200;
  }
  .mh-upgrade--containertab {
    display: none;
  }

  .form-group {
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
    width: calc(100% - 30px);

    .col-lg-5 {
      padding: 0;
      // padding: 0 0 0 15px;
      // width: 41.5%;
      &.lastName {
        padding: 0 0 0 10px;
      }
    }
    .col-lg-2 {
      padding: 0 10px;
    }
  }
  .container {
    width: 100%;
    &.close-container {
      .fa-times {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }
    .fa-times {
      @include font-size(18);
      color: $darkgray;
    }
  }
  .view-booking {
    width: 100%;
    padding: 0 20px;
    p {
      @include font-size(14);
      color: $darkgray;
      padding-bottom: 15px;
    }
    .form-validate{
      padding-bottom: 10px;
    }
    .checkInPnr {
      width: 31%;
    }
    .input-lg {
      @include border-radius(50px);
      height: 48px;
      color: $blue;
      @include font-size(14);
      &.input-focused {
        padding: 21px 20px 4px 21px;
      }
      &.has-error {
        border: 1px solid $errorred;
      }
      &:focus {
        border: 1px solid $blue;
        box-shadow: none;
      }
    }
  }
}

#mob-viewbooking--container,#mob-nfs--container, #mob-mhupgrade--container, #mob-stay--container {
  p {
    color: $darkgray;
    @include font-size(14);
  }
  .mob-mh-upgrade--containertab {
    display: none;
  }
  .view-booking {
    width: 100%;
    padding: 0 15px;

    /* MABUAT1-2 Fix */
    /* Add new rule */
    >.row>div {
      width: 100%;
    }
    /* MABUAT1-2 Fix */
    p {
      @include font-size(14);
      color: $darkgray;
      padding-bottom: 15px;
    }
    .input-lg {
      @include border-radius(50px);
      height: 48px;
      color: $blue;
      @include font-size(14);
      &.input-focused {
        padding: 21px 20px 4px 21px;
      }
      &.has-error {
        border: 1px solid $errorred;
      }
      &:focus {
        border: 1px solid $blue;
        box-shadow: none;
      }
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  #view-booking--container, #view-nfs--container, #view-upgrade {
    .col-lg-5 {
      // width: 38%;
    }
    .col-lg-2 {
      // width: 22%;
      padding-right: 0 !important;
    }
  }
}

@include respond-to(tablet-portrait) { // 767px
  .mobile-ibe {
   // #mob-viewbooking--container {
   //  padding: 15px 20px;
   // }
    .view-booking {
      width: 100%;
      margin: 0;
      // padding: 15px 20px;

      p {
        padding-bottom: 0;
      }
      .col-lg-12 {
        margin-bottom: 10px !important;
      }
    }
  }
}
/// inflight css
#in-flight-menu--container, #mob-in-flight-menu--container{
  .container {
    padding: 0 14px;
  }
  .inflight-menu-section {
    color: #333;
    font-size: 14px;
    font-family: Gotham, Arial;
    div {
      display: inline-block;
    }
    .ml-15 {
      margin-left: 15px;
    }
  }
  .error-validation-seperator, .error-validation-seperator-inflight,.mob-error-validation-seperator, .mob-error-validation-seperator-inflight {
    background-color: #E9E9E9;
    height: 1.5px;
    width: 100%;
    margin: 10px auto;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .errors-info, .errors-info-inflight, .mob-errors-info, .mob-errors-info-inflight {
    font-size: .875rem;
    margin-top: 6px;
    position: relative;
    i {
      position: absolute;
      top: 3px;
    }
  }
  .calender-element {
    position: relative;
    i {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }
  #desdate {
    &.form-control {
      padding-top: 22px;
    }
  }
  #destinationTabDate, #flightNumberDate, #flightNumber {
    &.form-control {
      padding-top: 28px;
    }
  }
  #flightNumber {
    text-transform: uppercase;
  }
  .flight-number-prefix {
    position: absolute;
    top: 24px;
    left: 49px;
    font-size: 11px;
    color: #005aaa;
  }
  .flightNumber {
    position: relative;
    input::-webkit-inner-spin-button {
      display: none;
    }
  }
  #flightNumber {
    &.form-control {
      padding-left: 43px;
      padding-top: 30px;
    }
  }
}
#mob-in-flight-menu--container {
  padding-bottom: 25px;
  .mob-inflight-menu-section {
    font-size: 14px;
    color: #333;
    .search-box {
      display: inline-block;
    }
    .search-box:first-child {
      padding-right: 10px;
    }
    .search-box:nth-child(2) {
      padding-right: 10px;
    }
  }
  #mobDestinationTabDate, #mobFlightNumberDate, #mobFlightNumber {
    &.form-control {
      padding-top: 30px;
    }
  }
  #mobFlightNumber {
    padding-left: 41px;
  }
  .flight-number-prefix {
    position: absolute;
    top: 24px;
    left: 32px;
    font-size: 11px;
    color: #005aaa;
  }
  .mobFlightNumber {
    position: relative;
    input::-webkit-inner-spin-button {
      display: none;
    }
  }
}
.errors-info-inflight, .errors-info, .mob-errors-info-inflight, .mob-errors-info {
  i {
    color: red;
  }
}
