/**
 * Members Portal
 * - header
 */

// base styles for member portal
$members-sidebar-width: 300px;
$content-padding: 20px;
$navbar-height: 102px;

$nav-toggle-width: 46px; // nav toggle margin: 15px;
$nav-width-more-than-1200: calc(100% - #{120px + 120px});
$nav-left-margin-more-than-1200: 120px;
$nav-width-less-than-1200: 1200px;

.members-nav {
  .alligneft{
    margin-left: 92px;
   }
  .alleft{
    margin-left:75px;
    color: #575757 !important;
  }
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  float: left;
  border: 0;

  &__left,
  &__right {
    float: left;
  }

  &__left {
    width: 65%;

    @media screen and (max-width: 1200px) {
      width: calc(65% - #{$nav-toggle-width} - 20px);
    }
  }

  &__right {
    width: 35%;
    text-align: right;
    padding-right: 12.5px;
  }

  &__toggle-btn {
    border: 0;
    float: left;
    margin-right: 10px;
    margin-left: 10px;

    @media screen and (max-width: 767px) {
      padding-top: 6px;
      margin-top: 0;
      padding-bottom: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    &.navbar-toggle {
      .icon-bar {
        height: 3px;
        background-color: $enrich-neutral;


      }
    }
  }

  // .navbar-header {
  //   margin-bottom: 10px;
  //   width: 100%;

  //   @media screen and (max-width: 767px) {
  //     padding: 10px 0;
  //     border-top: solid 1px $lightgray;
  //     border-bottom: solid 1px $lightgray;
  //     margin-bottom: 0;
  //   }
  // }

  .sidebar-menu {
    @include respond-to(1200) {
      width: 100%;
      float: left;
    }

    @media screen and (min-width: 1201px) {
      position: absolute;
      top: -67px;
      left: -14px;
      width: 300px;
    }

    &.navbar-collapse {
      padding-left: 0;
      padding-right: 0;

      &.collapse:not(.in) {
        @media screen and (min-width: 768px) {
          display: none !important;
          overflow: hidden !important;
        }
      }
    }

   &-item {
      &.active {
          font-family: $font-family-medium;
            // background-color: #c8b06f;
            // color: #ffffff !important;
            .alleft{
              color: #fff !important;
            }
           .in {
         display: block;
        }
           color: white !important;
           a{
             color: white ;
             .user-cog-icon{
              background-image: url('/etc/designs/mas/clientlib-site/images/user-cog.svg');
              background-position: center left;
              background-repeat: no-repeat;
            }           
            .travel-pass-img {
              background-image: url('/content/dam/mas/enrich/new-header-menu/icon_RedeemFlight_Blue.svg');
              background-position: center left;
              background-repeat: no-repeat;
              background-size: 20px 15px;
            }
           }
        span {
          display: inline-block;
          // padding-bottom: 2px;
          // border-bottom: dashed 1px $white;
        }
      }
      span {
        font: normal normal 14px/16px $font-family;
      }

      .sidebar-menu-link {
        display: block;
        color: #ffffff;
        .user-cog-icon{
          background-image: url('/etc/designs/mas/clientlib-site/images/user-cog-dark.svg');
          background-position: center left;
          background-repeat: no-repeat;
         }
        
        .travel-pass-img {
          background-image: url('/content/dam/mas/enrich/new-header-menu/icon_RedeemFlight_Inactive.svg');
          background-position: center left;
          background-repeat: no-repeat;
          background-size: 20px 15px;
        }
    .alert_mytrip{
    background:#00A9E5 ;
    text-align: center;
    margin-top: -22px;
    margin-left: 140px;
    width: 43px;
    color: white;
    border-radius: 100px;
       }
    
    .alert_notifaction{
    background: #ed1c24;
    text-align: center;
    margin-top: -22px;
    margin-left: 190px;
    width: 43px;
    color: white;
    border-radius: 100px;
       }
   .alerttravellers{
    background:#00A9E5 ;
    text-align: center;
    margin-top: -22px;
    margin-left: 190px;
    width: 43px;
    color: white;
    border-radius: 100px;
       }
      }
    }
  }

  .member-nav--user-info {
    span {
      display: inline-block;
      float: left;
    }

    .user-name--member-type,
    .user-member-number {
      display: block;
      float: left;
      width: 100%;
    }

    .username {
      color: $gray;
      font-family: $font-family-medium;
      font-size: rem(20);
      line-height: rem(32);
      font-weight: 600;

      @media screen and (max-width: 1200px) {
        display: block;
        float: none;
      }

      @media screen and (max-width: 767px) {
        font-size: rem(14);
        line-height: rem(22);
      }
    }

    .user-member-number {
      color: $gray;
      font-family: $font-family;
      font-weight: 400;

      @media screen and (max-width: 767px) {
        font-size: rem(12);
      }

      .member-type-label:after {
        display: inline-block;
        margin-right: 3px;
        content: ':';
      }

      .member-number-value {
        display: inline-block;
        text-transform: uppercase;
        line-height: rem(24);
        font-size: rem(16);


      @media screen and (max-width: 767px) {
        font-size: rem(12);
        line-height: 1.42857;
      }
      }
    }
  }
  .member--gold{
    .slideout-panel{
      .members-nav{
            .sidebar-menu-item.active{
              background-color: #c8b06f !important;
            }
          }
      
    }
  }
  .member-nav--miles-info {
    margin-top: #{$navbar-height - 74px};
    color: $gray;

    @media screen and (max-width: 767px) {
      margin-top: 3px;
    }

    .miles-value {
      font-size: rem(20);
      line-height: rem(24);
      font-family: $font-family-bold;

      @media screen and (max-width: 767px) {
        display: block;
        font-size: rem(18);
        line-height: rem(21);
      }
    }

    .miles-label {
      margin-left: 6px;
      font-size: rem(20);
      line-height: rem(24);

      @media screen and (max-width: 767px) {
        display: block;
        font-size: rem(14);
        line-height: rem(22);
      }
    }
  }
}

.members__menu {
  list-style: none;
  padding-left: 20px;
  background-color: #E5E5E5 ;

  &.sidebar-menu--level-1 > .sidebar-menu-item {
    border-top: none !important;
    &:first-child {
      border-top: 0;
    }

    &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    @media screen and (max-width: 767px) {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    
    }
   
    > a {
    .not{
    background: #ED1C24 ;
    border-radius: 100px;
   }
    .logo{
      padding-right: 6px;
      height: 29px;
      padding-left: 3px;
      margin-left: -21px;
      width: 49px;
  
    }
    .user-cog-icon{
      padding-right: 27px;
      height: 29px;
      padding-left: 5px;
      width: 49px;
    }
    .travel-pass-icon{      
      height: 15px;      
      width: 27px;
      margin-right: 8px;
      margin-left: -7px;
      vertical-align: text-top;
      padding-right: 27px;
    }
    .new-icon {
      font-style: normal;
      padding: 3px 10px;
      background-color: #ED1C24;    
      color: #fff;
      border-radius: 100px;
      margin-left: 10px;
    }
    .contactlogo{
      padding-right: 6px;
      height: 15px;      
      margin-left: -3px;
      width: 30px;
      }
    .logos{
    padding-right: 10px;
    height: 18px;
    padding-left: 3px;
    width: 31px;
    }
      padding: 20px 0;
      // font-family: $font-family;
      font: normal normal 14px/16px $font-family;
      padding-left: 25px;
      // font-weight: 300 ;
      

      @media screen and (max-width: 1200px) {
        padding: 20px;
      }

      @media screen and (max-width: 767px) {
        padding: 8px 16px;
        font-size: rem(13);
      }
    }
  }

  &:not(.sidebar-menu--level-1) > .sidebar-menu-item {
   
      margin-left: -20px;
    a {
      position: relative;
      padding: 15px 0;

      @media screen and (max-width: 1200px) {
        padding-right: 20px;
      }

      @media screen and (max-width: 767px) {
        padding: 8px 16px;
        font-size: rem(13);
      }

      &:before {
        position: absolute;
        left: -20px;
        content: none !important;
        width: 20px;
        text-align: center;
      }

      + ul {
        margin-top: 5px;
      }
    }
  }


  // .member--blue & {
  //   // background-color:  #E5E5E5;
  // }

  .sidebar-menu-item {
    i.faa{
      width: 30px;
      float: left;
      line-height: rem(22);
      -webkit-transition: 0.25s all ease-in-out;
      -o-transition: 0.25s all ease-in-out;
      transition: 0.25s all ease-in-out;
    }
  i.qa{
     float: right!important;
     font-weight: 600;
     -webkit-transition: 0.25s all ease-in-out;
     -o-transition: 0.25s all ease-in-out;
     transition: 0.25s all ease-in-out;
  }
    i.ga{
     float: right!important;
     margin-right: 14px;
     font-weight: 600;
     font: normal normal normal 22px/1 FontAwesome;
     -webkit-transition: 0.25s all ease-in-out;
     -o-transition: 0.25s all ease-in-out;
     transition: 0.25s all ease-in-out;
  }
    i.ka {
      -webkit-transition: 0.25s all ease-in-out;
      -o-transition: 0.25s all ease-in-out;
      transition: 0.25s all ease-in-out;
      width: 30px;
      float: left;
      font: normal normal normal 14px/1 FontAwesome;
      line-height: rem(22);
      margin-top: -4px;
      
    }  
    > .members__menu {
      margin-bottom: 0px !important;
      background-color: #E5E5E5   !important;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    // .sidebar-menu-link.collapsed {
    //   i.fa-angle-down {
    //      -ms-transform: rotate(180deg) !important; /* IE 9 */
    //    -webkit-transform: rotate(180deg)!important;; /* Safari prior 9.0 */
    //    transform: rotate(180deg)!important;; 
    //   }
    // }

    .sidebar-menu-link.collapsed {
      i.fa, i.ga  {
        -ms-transform: rotate(180deg) !important; /* IE 9 */
        -webkit-transform: rotate(180deg)!important; /* Safari prior 9.0 */
        transform: rotate(180deg)!important;
        @media screen and (max-width: 767px) {
          -ms-transform: rotate(-90deg) !important; /* IE 9 */
          -webkit-transform: rotate(-90deg)!important; /* Safari prior 9.0 */
          transform: rotate(-90deg)!important;
        }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          -ms-transform: rotate(-90deg) !important; /* IE 9 */
          -webkit-transform: rotate(-90deg)!important; /* Safari prior 9.0 */
          transform: rotate(-90deg)!important;
        }
      }     
    }
  }


  .sidebar-menu > & {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 40px;
    // background: #F1F1F1;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
  }
}

.members__nav {
  position: relative;
  z-index: 2;

  @media screen and (min-width: 768px) {
    margin-top: 40px !important;
  }

  @include respond-to(1440) {
    width: 100%;
    max-width: $nav-width-less-than-1200;
    margin: 0 auto;
  }

  @media screen and (min-width: 1441px) {
    width: $nav-width-more-than-1200;
    margin-left: $nav-left-margin-more-than-1200;
    margin-right: $nav-left-margin-more-than-1200;
  }
}

.member-type {
  margin-left: 5px;
  margin-top: 4px;
  margin-bottom: 4px;

  @media screen and (max-width: 1200px) {
    display: block;
    float: none;
    margin-left: 0;
    margin-top: 0;
  }

  label {
    font-family: $font-family-bold;
    font-weight: bold;
    padding: 3px 5px;
    font-size: rem(10);
    text-transform: uppercase;
    color: $white;
    line-height: rem(14);
    margin-top: 1px;
    margin-bottom: 1px;
    display: inline-block;
    background-color: $enrich-neutral;

    @media screen and (max-width: 767px) {
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;

    }

    .member--gold & {
      background-color: $enrich-gold;
    }

    .member--silver & {
      background-color: $enrich-silver;
    }

    .member--platinum & {
      background-color: $enrich-platinum;
    }

    .member--blue & {
      background-color: $enrich-blue;
    }
  }

}
.member--gold{
   .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item.active{
  background-color: #c8b06f !important;
  
}
// .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item:hover {
//   background: yellow;
// }
}
.member--silver{
 .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item.active{
  background-color: #aaadb0 !important;
}
// .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item:hover {
//   background: green;
// }
}
.member--platinum {
  .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item.active{
  background-color: #506883 !important;
}
// .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item:hover {
//   background: greenyellow;
// }
}
.member--blue{
   .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item.active{
  background-color: #00bff3 !important;
}
// .slideout-panel .members-nav .sidebar-menu .members__menu .sidebar-menu-item:hover {
//   background: blanchedalmond;
// }
}