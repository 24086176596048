.deals-container {
  &.detailed-container {
    width: 100%;
    .deals-selector {
      .deals-selector__separator {
        margin: 18px 0 0;
        padding: 0;
        hr {
          border-top: 1px solid $lightgray;
        }
      }
    }
    .deals-filter--container {
      display: block;
      margin: 2% 0 3%;
      form {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        flex-wrap: wrap;
        >div {
          position: relative;
          display: block;
        }
      }
      .deals-selector__separator {
        width: 7%;
      }
      .deals-cabin {
        width: 23%;
        padding-right: 15px;
      }
      .trip-type {
        width: 30%;
        padding: 0;
        a {
          &.btn {
            border: 1px solid $lightgray;
            padding: 12px 20px;
            width: 50%;
            float: left;
            &.return {
              border-radius: 50px 0 0 50px;
            }
            &.oneWay {
              border-left: 0;
              border-radius: 0 50px 50px 0;
            }
            &.selected {
              background: $linkblue;
              color: $white;
              border: 1px solid $linkblue;
              &:last-child {
                border-left: 1px solid $white;
              }
            }
          }
          &:hover {
            color: $linkblue;
          }
        }
      }
      .departureCity {
        padding-right: 20px;
        width: 35%;
      }
      .booking-form--title {
        position: absolute;
        left: 20px;
        top: 14px;
        color: #666;
        font-size: rem(14);
        line-height: rem(21);
        font-size: 0.875rem;
        line-height: 1.3125rem;
        @include z-index(bookformtitle);
        pointer-events: none;
        display: block;
        max-width: initial;
        margin-bottom: initial;
        font-weight: initial;
        &.input-filled {
          top: 6px;
        }
      }
    }
    .deals-content--container {
      &.detailed-deals {
        .detailed-deals-item {
          margin: 0;
          padding: 0;
          background-color: $seashell;
          @include respond-to(414) {
            width: 100% !important;
          }
        }
      }
      .deals-price--container {
        display: block;
        clear: both;
        height: auto;
        overflow: hidden;
        margin-left: 8%;
        .deals-list--items {
          width: 100%;
          clear: both;
          margin-bottom: 12px;
          float: left;
          padding: 0 3% 8px;
          border-bottom: 1px solid $lightgray;
          a {
            display: block;
            height: auto;
            overflow: hidden
          }
          .deals-location--list {
            width: 80%;
            float: left;
            .location {
              width: 100%;
              clear: both;
              .city-name {
                float: left;
                @include font-size(24);
                color: $blue;
              }
              .country {
                float: left;
                @include font-size(18);
                color: $gray;
                margin: 6px 0 0 10px;
              }
            }
            .trip-type--class {
              clear: both;
              @include font-size(12);
              color: $darkgray;
              .trip-type {
                float: left;
              }
              .sep {
                float: left;
                font-family: $font-family-medium;
                @include font-size(28);
                line-height: 0.05em;
                margin: 0 6px;
                border-radius: 50%;
              }
              .class {
                float: left;
              }
            }
            .travel-period {
              clear: both;
              label {
                float: left;
                text-align: left;
                padding-left: 0;
                clear: both;
                width: 100%;
                @include font-size(12);
                color: $darkgray;
                font-family: $font-family-medium;
                margin-top: 10px;
                line-height: 1em;
              }
              .travel-date {
                @include font-size(12);
                color: $darkgray;
                line-height: 1em;
              }
            }
          }
          .deals-price--list {
            width: 20%;
            float: left;
            padding: 6% 0;
            .from-label {
              display: block;
              clear: both;
              @include font-size(12);
              color: $darkgray;
              line-height: 1em;
              text-align: right;
            }
            .from-price {
              @include font-size(30);
              color: $blue;
              font-family: $font-family-medium;
              line-height: 1em;
              text-align: right;
              sup {
                font-family: $font-family;
                @include font-size(15);
                color: $darkgray;
              }
            }
          }
        }
      }
      .btn-secondary {
        width: 100%;
        margin: 0;
      }
    }
    .loading--bg-effect {
      // animation: loadingcolors 2s ease infinite;
    }

    .loading {
      .loading--bg-effect {
        // background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, $midgray 100%, rgba(0, 0, 0, 0) 0%);
        // background-position: 0% 50%;
        // background-size: 800% 100%;
        // color: transparent;
        color: transparent;
        background: transparent;
      }

      .promo-item,
      .deals-price--content {
        opacity: 0;
      }

      img {
        opacity: 0;
      }
    }

    // @keyframes loadingcolors {
    //   0% {
    //     background-position: 0% 50%
    //   }
    //   50% {
    //     background-position: 100% 50%
    //   }
    //   100% {
    //     background-position: 0% 50%
    //   }
    // }
    .show-more--deals {
      .total-deals {
        text-align: center;
        clear: both;
        width: 100%;
        display: block;
        margin: 2% 0 0;
        label {
          font-family: $font-family;
          color: $gray;
          @include font-size(14);
          padding-left: 0;
        }
        .no-of--items,
        .total-items {
          font-family: $font-family-medium;
          @include font-size(14);
          color: $dealsprice;
          display: inline-block;
        }
      }
    }
    .deals-show--more {
      padding: 2% 42% 1%;
      a {
        width: 100%;
        text-align: center;
      }
    }
    .deals-content--container {
      margin: 0 0 1%;
      .deals-price--container {
        margin-left: 0;
        .deals-list--items {
          .deals-price--list {
            padding: 2% 0;
          }
        }
        .deals-list--head,
        .deals-list--items {
          width: 100%;
          clear: both;
          display: block;
          padding: 0;
          height: auto;
          overflow: hidden;
          .location-name {
            float: left;
            width: 20%;
            display: block;
            font-family: $font-family-medium;
          }
          .from-price {
            float: left;
            width: 15%;
            font-family: $font-family-medium;
            @include font-size(14);
            color: $gray;
            text-transform: uppercase;
            text-align: left;
            .currency {
              display: inline-block;
            }
          }
          .book-before {
            float: left;
            width: 20%;
            font-family: $font-family-medium;
            @include font-size(14);
            color: $gray;
            text-transform: uppercase;
            text-align: left;
          }
          .travel-period {
            float: left;
            width: 20%;
            font-family: $font-family-medium;
            @include font-size(14);
            color: $gray;
            text-transform: uppercase;
            text-align: left;
          }
          .more-deals {
            float: right;
            width: 20%;
            text-align: center;
          }
          .fa {
            &.fa-angle-down {
              color: $blue;
              margin-left: 8px;
            }
          }
        }
        .deals-list--head {
          border-bottom: 1px solid $lightgray;
          padding-bottom: 10px;
        }
      }
    }
    .deals-table--content {
      margin: 0 0 100px 0;
      padding: 0;
      table {
        td {
          @include font-size(15);
        }
      }
    }
    .dataTable,
    .dataTables_wrapper {
      .dataTables_filter {
        display: none !important;
        visibility: hidden !important;
      }
      thead {
        th {
          text-align: left;
          font-family: $font-family-medium;
          @include font-size(14);
          color: $gray;
          text-transform: uppercase;
          border-bottom: 1px solid $lightgray;
          padding-bottom: 10px;
          @include respond-to(ipad-portrait) {
            font-size: rem(10) !important;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $lightgray;
          padding: 10px 0;
        }
      }
      .country {
        display: none;
        @include font-size(24);
        color: $gray;
        float: left;
      }
      .city-name {
        @include font-size(18);
        color: $blue;
        float: left;
        padding: 4px 8px 0 0;
      }
      .from-price {
        @include font-size(24);
        color: $blue;
        font-family: $font-family-medium;
        line-height: 1em;
        text-align: left;
        sup {
          font-family: $font-family;
          @include font-size(15);
          color: $darkgray;
        }
      }
      .travel-period {
        width: 100%;
        clear: both;
        height: auto;
        overflow: hidden;
        margin: 3px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include respond-to(ipad-portrait) {
          font-size: rem(12) !important;
        }

        span:nth-child(2) {
          padding: 0 8px;
        }
      }
      .more-deals {
        text-align: center;
        float: left;
        width: 100%;
        padding: 10px 0;
        @include respond-to(ipad-portrait) {
          a {
            @include font-size(12);
          }
        }
      }
      .dataTables_info {
        float: left;
        text-align: left;
        padding-left: 0;
        clear: both;
        width: 50%;
        @include font-size(14);
        color: $darkgray;
        margin-top: 30px;
        line-height: 1em;
      }
      table {
        &.dataTable {}
      }
      .dataTables_paginate {
        float: right;
        text-align: right;
        padding-top: 2em;
        width: 50%;
      }
    }
  }
}


@include respond-to(1200) {
  .deals-container {
    &.detailed-container {
      .deals-content--container {
        .big {
          width: 50%;
        }
        .deals-price {
          .deals-price--content {
            padding: 5% 10%;
          }
        }
      }
      .deals-table--content {
        margin: 0 0 120px 0;
        padding: 0;
      }
    }
    .deals-show--more {
      padding: 2% 36% 1% !important;
    }
  }
}

@include respond-to(980) {
  .deals-container {
    &.detailed-container {
      .promo-item {
        .price {
          @include font-size(10);
        }
        .offer-details {
          @include font-size(10);
        }
      }
      .deals-selector {
        .deals-selector__separator {
          display: none;
        }
        .deals-selector__dropdown {
          width: 100%;
        }
      }
      .deals-filter--container {
        .deals-selector__separator {
          display: none;
          padding: 0;
          margin: 0 12px;
        }
        .deals-cabin {
          display: block !important;
        }
        .trip-type {
          width: 34%;
          display: block;
          margin: 0;
        }
        .departureCity {
          width: 40%;
          padding: 0 15px 0 0;
        }
      }
      .deals-content--container {
        .big {
          width: 50%;
        }
        .deals-price {
          .deals-price--content {
            padding: 5% 10%;
            .city-name,
            .amount {
              @include font-size(16);
            }
            .currency-type {
              margin-top: 10px;
            }
          }
        }
        .deals-offer--date {
          @include font-size(10);
        }
        .deals-price--container {
          .deals-list--items {
            .deals-location--list {
              .location {
                .country {
                  @include font-size(20);
                }
                .city-name {
                  @include font-size(16);
                  margin: 4px 0 0 10px;
                }
              }
            }
            .deals-price--list {
              .from-price {
                @include font-size(24);
              }
            }
          }
        }
      }
      .dataTables_wrapper {
        thead {
          th {
            @include font-size(13);
          }
        }
        .city-name,
        .book-before,
        .travel-period {
          @include font-size(15);
          @include respond-to(ipad-portrait) {
            font-size: rem(12) !important;
          }
        }
        .from-price {
          @include font-size(24);
          @include respond-to(ipad-portrait) {
            font-size: rem(20) !important;
          }
        }
      }
    }
    .deals-show--more {
      padding: 2% 36% 1% !important;
    }
  }
}

@include respond-to(880) {
  .deals-container {
    &.detailed-container {
      .more-deals--container {
        .deals-price--container {
          .deals-list--items {
            .more-deals {
              .btn-secondary {
                padding: 12px 12px 14px !important;
              }
            }
          }
        }
      }
    }
    .deals-content--container {
      .btn-secondary {
        @include font-size(12);
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  .deals-container {
    &.detailed-container {
      .deals-filter--container {
        .departureCity {
          width: 100%;
          margin-bottom: 15px;
          padding: 0;
        }
        .deals-cabin {
          padding: 0;
          width: 100%;
          margin: 0 0 15px 0;
        }
        .trip-type {
          width: 100%;
          margin: 0 0 15px 0;
          a {
            &.btn {
              &.oneWay {
                padding: 12px 13px;
              }
            }
          }
        }
      }
      .deals-content--container {
        display: none;
        &.detailed-deals {
          display: block;
          .detailed-deals-wrapper {
            padding: 0;
            .detailed-deals-item {
              display: none;
            }
          }
        }
      }
      .deals-show--more {
        padding: 2% 12% 1% !important;
      }
      .row {
        margin: 0;
      }
      .deals-table--content {
        margin: 0 0 30px 0;
      }
      .dataTables_wrapper {
        thead {
          th {
            &:nth-child(2) {
              text-align: right;
            }
          }
        }
        .dataTables_info {
          width: 60%;
          text-align: center;
          display: block;
          float: none;
          margin: 20px auto 0;
        }
        .dataTables_paginate {
          width: 100%;
          float: left;
          text-align: center;
          display: inline-block; // .paginate_button{
        }
        .from-price {
          text-align: right;
        }
        table {
          &.dataTable {
            thead {
              .sorting,
              .sorting_asc,
              .sorting_desc {
                &:after {
                  font: normal normal normal 18px/1 FontAwesome;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
    .deals-selector {
      .deals-from {
        display: block;
        width: 100%;
        text-align: center; //clear: both;
      }
      .deals-city {
        clear: both;
        margin: 0 10px;
        width: 80%;
        text-align: center;
        .deals-city-select-wrapper {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .destination-container {
    margin-bottom: 30px;
  }
}

@include respond-to(475) {
  .deals-container {
    &.detailed-container {
      .deals-selector {
        margin: 0 !important;
      }
      .deals-filter--container {
        .departureCity {
          padding: 0 !important;
        }
      }
      .deals-content--container {
        .deals-price--container {
          .deals-list--items {
            margin-bottom: 0;
            .deals-location--list {
              .location {
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(479) {
  .deals-container {
    &.detailed-container {
      .dataTables_wrapper {
        .dataTables_info {
          width: 80%;
        }
      }
    }
  }
}
