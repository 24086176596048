.helpsearch {
  $search-height: 48px;

  &__title {
    text-align: center;
    margin-top: 30px;

    h1 {
      line-height: 1;
      color: $countdownblue;
    }
  }

  &__form {
    max-width: 856px;
    margin: 0 auto;
    margin-bottom: 15px;

    @include respond-to(#{856 + 20 + 20}) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .form-group {
      display: inline-block;
      width: 100%;
    }
  }

  &__searchitem {
    position: relative;
    width: calc(100% - 146px - 10px);
    margin-right: 10px;
    float: left;

    @include respond-to(767) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__searchbar {
    border-radius: #{$search-height / 2};
    height: $search-height;
    padding-left: 20.5px;
    padding-right: $search-height;
    position: relative;
    z-index: 1;
  }

  &__clearbtn {
    $btn-width: $search-height;

    position: absolute;
    right: 0;
    top: 0;
    width: $btn-width;
    height: $btn-width;
    display: none;
    z-index: 2;
    cursor: pointer;

    .fa {
      display: block;
      text-align: center;
      line-height: $btn-width;
    }
  }

  &__searchbtn {
    width: 146px !important;
    float: left;

    @include respond-to(767) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

}
