.configurable-table--container {
  .cabin-class--container {
    width: 100%;
  }
  $sec-nav-bg: $white;
  $sec-nav-menu-width: auto;
  .sec-navigation-wrapper {
    flex-grow: 1;
    display: block;
    padding: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @media screen and (min-width: 1200px) {
      width: $sec-nav-menu-width;
    }

    // overflow scroll hiding effect
    @mixin sec-nav-overflow-bg($direction: 90deg) {
      background-image: -webkit-gradient(linear, left top, left bottom, from($direction), color-stop(30%, rgba($sec-nav-bg, 1)), to(rgba($white, 0)));
      background-image: -webkit-linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
      background-image: -o-linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
      background-image: linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 30px;
      height: 100%;
      top: 0;
      z-index: 2;
    }

    &:before {
      @include sec-nav-overflow-bg(90deg);
      left: -15px;
    }

    &:after {
      @include sec-nav-overflow-bg(270deg);
      right: 0;
    }
    .sec-navigation {
      display: block;
      padding: 0;
      position: relative;
      overflow-x: auto;
      //text-align: center;
      margin-bottom: -5px;

      &::-webkit-scrollbar { display: none; }

      @media screen and (max-width: 1199px) {
        overflow-x: scroll;
        -webkit-user-select: none;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
      }

      ul {
        position: relative;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 100%;
        @include respond-to(1200) {
          width: auto;
        }
        // prevent selection
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */

        &:hover,
        &:focus {
          cursor: pointer;
        }

        li {
          list-style-type: none;
          display: inline-block;
          margin: 0;
          padding: 0 20px 0 20px;
          text-align: center;
          vertical-align: middle;
          margin-bottom: -2px;
          &:first-child {
            padding-left: 15px;

            @media screen and (max-width: 479px) {
              padding-left: 30px;
            }
          }

          &:last-child {
            padding-right: 30px;
          }

          a {
            float: left;
            @include font-size(16);
            font-family: $font-family-medium;
            text-align: center;
            &:hover {
              color: $errorred;
            }
          }

          .btn-primary {
            text-align: center;
            margin-top: 0;
            font-family: $font-family;
            padding: 12px 30px 14px;
            &:hover {
              color: $white;
            }

          }
        }
      }
    }
  }
  ul {
    &.nav-tabs {
      padding: 0 6% 1px 8% !important;
      margin-bottom: 30px;
      li {
        float: none;
        a {
          font-family: $font-family-medium;
          color: $countdownblue;
          @include font-size(14);
          padding: 15px 14px;
          &:hover {
            background: transparent;
            border-top: 1px solid $white;
            border-left: 1px solid $white;
            border-right: 1px solid $white;
          }
        }
        &.active {
          margin-top: 3px;
          a {
            border: 0;
            color: $errorred;
            padding: 13px 15px;
            border-bottom: 3px solid $errorred;
            &:before {
              height: 0;
            }
          }
        }
        &:before {
          background: $errorred;
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 0 8%;
      @include respond-to(767) {
        padding: 0;
      }
    }
  }
  .sec-nav--arrow {
    position: absolute;
    @include font-size(24);
    @include opacity(0.2);
    @include z-index(passengarDetails);
    display: none;
    &.left {
      left: 15px;
      top: 8px;
    }
    &.right {
      right: 15px;
      top: 8px;
    }
  }
  table,
  .dataTables_wrapper {
    width: 100%;
    thead {
      th {
        text-align: left;
        font-family: $font-family-medium;
        @include font-size(20);
        color: $gray;
        text-transform: none;
        border-bottom: 1px solid $lightgray;
        padding-bottom: 10px;
        padding-left: 20px;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $lightgray;
        padding: 10px 0;
        td {
          height: 120px;
          vertical-align: top;
          padding: 10px;
          &:first-child {
            background: $blue;
            color: $white;
            padding-left: 30px + 40px;
            vertical-align: middle;
            position: relative;

              @media screen and (max-width: 1199px) {
                padding-left: 10px;
              }
              @media screen and (max-width: 1024px) {
                padding: 0 30px;
              }

            .fa {
              @include font-size(36);
              line-height: 1em;
              color: $white;
              width: 40px;
              left: 30px;
              top: 50%;
              position: absolute;
              transform: translateY(-50%);

              @media screen and (max-width: 1199px) {
                position: relative;
                transform: initial;
                transform: unset;
                top: initial;
                top: unset;
                left: initial;
                left: unset;
                width: 100%;
                text-align: center;
              }
            }
            span {
              margin-left: 15px;

              @media screen and (max-width: 1199px) {
                margin-left: 0;
                text-align: center;
                display: block;
              }

              @media screen and (max-width: 1024px) {
                display: none;
              }
            }
          }
          p {
            margin-bottom: 5px;
          }
        }
        &:last-child {
          td {
            height: unset;
            &:first-child {
              background: transparent;
            }
          }
        }
      }
    }
  }
  .btn-primary {
    width: auto;
    text-align: center;
    margin-left: 50px;
    .fa {
      margin-right: 10px;
      font-size: rem(16);
    }
  }
}

.dataTables_wrapper {
  &.no-footer {
    .dataTables_scrollBody {
      border-bottom: 0 !important;
    }
  }
}

@include respond-to(1400) {
  .configurable-table--container {
    .dataTables_wrapper {
      tbody {
        tr {
          td {
            .fa {
              // margin: 0 44%;
            }
            span {
              text-align: center;
              margin: 5px 0 0;
            }
          }
        }
      }
    }
    .btn-primary {
      // width: 60%;
    }
  }
}

@media screen and (min-width: 768px) {
  .configurable-table--container {
    .dataTables_wrapper {
      thead {
        th {
          @include font-size(16);
          padding-left: 20px;
        }
      }
      tbody {
        tr {
          td {
            p {
              @include font-size(14);
              padding-left: 10px;
            }
            .fa {
              // margin: 0 36%;
            }
          }
        }
      }
    }
    .btn-primary {
      // width: 80%;
      margin-left: 30px;
    }
  }
}

@include respond-to(tablet-portrait) {
  .configurable-table--container {
    .cabin-class--container {
      width: 100%;
      margin: 0;
    }
    .dataTables_wrapper {
      thead {
        th {
          white-space: nowrap;
          &:first-child {
            background: $white;
            min-width: 40px;
            //padding: 0 50px 0 120px;
          }
        }
      }
      tbody {
        tr {
          td {
            white-space: nowrap;
            vertical-align: middle;
            padding: 20px;
            &:first-child {
              padding: 0 20px;
            }
            .fa {
            }
            span {
              @include font-size(0);
            }
            p {
              @include font-size(14);
              // margin-left: 10px;
            }
          }
          &:last-child {
            td {
              padding: 30px;
            }
          }
        }
      }
    }
    .btn-primary {
      width: 100%;
      margin-left: 0px;
      @include font-size(14);
    }
  }
}
