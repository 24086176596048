.cuglogin-component {
  margin-top: 15px;
  margin-bottom: 15px;
  h1 {
    text-align: center;
  }
  h2 {
    line-height: 0.6em;
    color: $gray;
  }
  h3 {
    color: $focusblue;
    line-height: 1em;
    margin-bottom: 10px;
    margin-top: 12px;
  }
  p {
    font-family: $font-family-light;
    @include font-size(14);
  }
  a {
    @include font-size(14);
    margin-left: 22px;
    margin-right: 6px;
    &.btn {
      width: 47%;
      float: left;
      margin-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .row {
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .form-element {
    display: block;
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }
  }
  .form-validate {
    padding: 0;
  }
  .booking-form--title {
    position: absolute;
    margin-left: 24px;
    margin-top: 14px;
    color: $darkgray;
    @include font-size(14);
    @include z-index(bookformtitle);
    &.input-filled {
      margin-top: 6px;
      margin-left: 22px;
      @include font-size(12);
    }
    &.has-error {
      color: $errorred;
    }
  }
  input {
    &.input-focused {
      padding: 21px 20px 4px 21px;
    }
  }

  .cuglogin-error--validation {
    border: 1px solid $errorred;
    padding: 10px 0;
    display: none;
    width: 100% / 12 * 8;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    &.show-errors {
      display: flex;
    }

    > .col-sm-1,
    > .col-sm-11 {
      width: auto;
      float: none;
    }

    > .col-sm-11 {
      flex-grow: 999;
    }

    .fa {
      color: $errorred;
      @include font-size(18);
      line-height: rem(21);
    }
    .error-msgs {
      @include font-size(14);
      color: $errorred;
      padding-left: 0;
    }
    #result {
      @include font-size(14);
      color: $gray;
    }
    .cuglogin-validator {
      @include font-size(14);
    }
  }
}


@include respond-to(1300) {
  .cuglogin-component {
    .col-lg-8 {
      width: 100%;
    }
    .col-lg-4 {
      width: 0%;
    }
  }
}

@include respond-to(tablet-portrait) {
  .cuglogin-component {
    .sep {
      width: 100%;
      height: 1px;
      margin: 25px 0 15px;
    }
    h1 {
      @include font-size(36);
    }
    h2 {
      @include font-size(30);
      line-height: 1em;
      margin-bottom: 0;
    }
    h3 {
      @include font-size(30);
      line-height: 1em;
    }
    p {
      margin-top: 20px;
    }
  }
}
