.container.offer-banner {
    font-family:Gotham-Book,Arial !important;
}
.offer-banner .offer-booking-form .offer-submit-button {
    text-transform: capitalize !important;
    background-color: #005aaa!important;
    border-color: #005aaa!important;
    font-size: 0.875rem !important;
}
.offer-banner .offer-booking-form .offer-submit-button:hover, .offer-banner .offer-booking-form .offer-submit-button:active {    
    background-color: #19286e!important;
    border-color: #19286e!important;
}

@media only screen and (min-width: 320px){
    .offer-price-text {
        display: inline-block !important;
        margin: 0 auto !important;
        font-size: 9px !important;
        margin-top: 12px !important;
        margin-left: -130px !important;
    }
    .offer-cities {
        font-size: 20px !important;
    }
    .calendar_bottomPanel {
    font-size: 11px !important;
}
.calendar_monthHeader {
    font-size: 11px !important;
}
.calendar_increaseMonth > span, .calendar_decreaseMonth > span {
   font-size: 0.5em !important;
}
.calendar_container {
    width: 300px !important;
    margin-left: -9px;
}
.calendar_mainPanelPicker {
    font-size: 10px;
}
.calendar_mainPanel {
    width: 300px !important;
}
.calendar-container .calendar_dayPicker table {
    width: 310px !important;
}
.calendar_dayPicker > table > tbody > tr td.selectableDate, .calendar_dayPicker > table > tbody > tr td.calendar_disabled, .calendar_dayPicker > table > tbody > tr td.empty{
    min-width: 42px !important; 
    height: 45px !important;
}
.passengers-dialog {
    margin-left: -260px !important;
    width: 250px !important;
}
.selected-from-price {
   display: inline-block !important;
   margin: 0 auto !important;
   margin-left: 300px !important;
   position: relative !important;
   width: 200px !important;
   top: -30px !important;
   font-size: 13px !important;
}
.selected-from-price span {
    font-size: 16px !important;
}
.offer-price-from, .offer-price-amount {
    font-size: 14px !important;
}
}
@media only screen and (min-width: 375px){
    .offer-price-text {
        display: inline-block !important;
        margin: 0 auto !important;
        font-size: 12px !important;
        margin-top: 15px !important;
        margin-left: -130px !important;
    }
    .offer-cities {
        font-size: 20px !important;
    }
    .calendar_bottomPanel {
    font-size: 12px !important;
}
.calendar_monthHeader {
    font-size: 11px !important;
}
.calendar_increaseMonth > span, .calendar_decreaseMonth > span {
   font-size: 0.5em !important;
}
.calendar_container {
    width: 330px !important;
    margin-left: -20px;
}
.calendar_mainPanelPicker {
    font-size: 11px;
}
.calendar_mainPanel {
    width: 330px !important;
}
.calendar-container .calendar_dayPicker table {
    width: 320px !important;
}
.calendar_dayPicker > table > tbody > tr td.selectableDate, .calendar_dayPicker > table > tbody > tr td.calendar_disabled, .calendar_dayPicker > table > tbody > tr td.empty{
    min-width: 45px !important; 
    height: 45px !important;
}
.passengers-dialog {
    margin-left: -260px !important;
    width: 250px !important;
}
.selected-from-price {
   display: inline-block !important;
   margin: 0 auto !important;
   margin-left: 200px !important;
   position: relative !important;
   width: 200px !important;
   top: 20px !important;
   font-size: 13px !important;
}
.selected-from-price span {
    font-size: 15px !important;
}
}
@media only screen and (min-width: 414px) {
.offer-price-bloc .offer-price-from {
    font-size: 13px !important;
}
.offer-price-bloc .offer-price-amount {
    font-size: 15px !important;
}
.offer-price-bloc .offer-price-text {
    font-size: 9px !important;
}
.offer-days-left {
    font-size: 12px !important;
    margin-right: 5px;    
}
.offer-details-bloc {
    font-size: 13px !important;
}
.offer-cities {
    font-size: 25px !important;
}
.calendar_bottomPanel {
    font-size: 12px !important;
}
.calendar_monthHeader {
    font-size: 12.5px !important;
}
.calendar_increaseMonth > span, .calendar_decreaseMonth > span {
   font-size: 0.8em !important;
}
.calendar_container {
    width: 370px !important;
    margin-left: -15px;
}
.calendar_mainPanelPicker {
    font-size: 10px;
}
.calendar_mainPanel {
    width: 370px !important;
}
.calendar-container .calendar_dayPicker table {
    width: 370px !important;
}
.calendar_dayPicker > table > tbody > tr td.selectableDate, .calendar_dayPicker > table > tbody > tr td.calendar_disabled, .calendar_dayPicker > table > tbody > tr td.empty{
    min-width: 50px !important; 

}
.passengers-dialog {
    margin-left: -310px !important;
    width : 250px !important;
}
.selected-from-price {
/*    display: inline-block !important;
   margin: 0 auto !important;
   margin-left: 210px !important;
   position: relative !important; */
/*    width: 200px !important; */
/*    top: 70px !important; */
}
.selected-from-price span {
    font-size: 19px !important;
}
.offer-submit-bloc > button {
    min-width: 150px !important;
    font-size: 14px;
}
}
@media only screen and (min-width: 768px){
.offer-price-bloc .offer-price-from {
    font-size: 13px !important;
}
.offer-price-bloc .offer-price-amount {
    font-size: 15px !important;
}
.offer-days-left {
    font-size: 13px !important;
    margin-right: 5px;
}
.offer-selectDate-header {
    font-size: 17px !important;
}
.selected-from-price {
   display: inline-block !important;
   margin: 0 auto !important;
   margin-left: 200px !important;
   position: relative !important;
   width: 200px !important;
   top: 30px !important;
}
.calendar_container {
    width: 562px !important;
    margin-left: -5% !important;
}
.calendar_mainPanelPicker {
    font-size: 13px;
}
.calendar_mainPanel {
    width: 562px !important;
}
.calendar-container .calendar_dayPicker table {
    width: 562px !important;
}
.calendar_dayPicker > table > tbody > tr td.selectableDate, .calendar_dayPicker > table > tbody > tr td.calendar_disabled, .calendar_dayPicker > table > tbody > tr td.empty{
    min-width: 80px !important; 

}
.offer-submit-bloc > button {
    min-width: 135px !important;
    font-size: 13px
}
.passengers-dialog {
    margin-left: 10px !important;
    width : 300px !important;
}
.selected-from-price {
   display: inline-block !important;
   margin: 0 auto !important;
   margin-left: 210px !important;
   position: relative !important;
/*    width: 200px !important; */
   top: -30px !important;
}
.selected-from-price span {
    font-size: 19px !important;
}
.offer-selectPassengers-header {
    font-size : 14px !important;
}
}

@media only screen and (min-width: 1025px) {
.calendar_container {
    width: 562px !important;
}
.calendar_mainPanelPicker {
    font-size: 13px;
}
.calendar_mainPanel {
    width: 562px !important;
}
.calendar-container .calendar_dayPicker table {
    width: 562px !important;
}
.calendar_dayPicker > table > tbody > tr td.selectableDate, .calendar_dayPicker > table > tbody > tr td.calendar_disabled, .calendar_dayPicker > table > tbody > tr td.empty{
    min-width: 80px !important; 

}
.selected-from-price {
   display: inline-block !important;
   margin: 0 auto !important;
   margin-left: 220px !important;
   position: relative !important;
   width: 200px !important;
   top: 10px !important;
}
.selected-from-price span {
    font-size: 19px !important;
}
}

.offer-submit-button {
    border-radius: 10px !important;
}
.offer-days-left {
    border-radius: 5px;
}
.offer-selectPassengers-header {
/*     font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Arial, sans-serif !important; */
    color: #15262f !important;
    font-weight: 400 !important;
}
.offer-selectDate-header {
    text-align: center;
}
#NewOfferBanner .offer-left-column{
    background-color: #f3f0f0 !important;
    
}
.datepicker-input-field .datepicker{ 
 border: 1px solid #19286e!important;
}
.offer-pax-input, .calendar-btn { 
    background-color: white !important;
    border: 1px solid #19286e!important;
}
.icon-calendar {
    display: flex !important;
    margin: 0 auto !important;    
}
.offer-pax-button {
    display: inline !important;
    margin: 0 auto;
    padding-top: 1% !important;
}
.offer-pax-button .icon-sort {
    font-size: 20px !important;

}
.calendar_container .calendar_monthHeader .month[aria-selected=true]  {
     background-color: #19286e !important; 
     color: white !important;  
}
.calendar_container .calendar_monthHeader .month {
    background-color: white !important;

}
.monthChanger {
    font-weight: 600 !important;
    font-size: 20px !important;
}
.offer-days-left {
    background: none !important;
    border-bottom: 2px solid red !important;
}
.passengers-dialog {
    background: white !important;    
}
/*EndTo fix Instant Search CSS*/