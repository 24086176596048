ul {
  &.bulletlist {
    list-style: disc;
  }

  li {
    margin: 0;
  }
}


