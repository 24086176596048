#member-profile-enrichcard
{
    #enrichLcpFormUpdate {
        select[disabled='disabled'] {
            background-color: #eee;
            opacity: 1;
        }
        .selectize-control .selectize-input.disabled {
            background-color: #eee;
            opacity: 1;
        }
        .selectize-control.single .selectize-input {
            &.disabled:after {
                background-color: #eee;
            }
        }
    }
    @media (max-width: 767px)
        {
            padding-right: 17px;
        }
    .tab_descrp,.prefrence--description
    {
        font-family: Gotham-Book,Arial;
        font-size: 14px;
        margin-bottom: 22px;
    }
    h5
    {
        color: #006dbb;
    }
    .card-width
    {
        padding: 10px;
    }
    .members--card-image .card--details {
        bottom: 23px;
        .card--username
        {
            font: normal bold 10pt Gotham, Arial;
            margin-bottom: -6px;
            color: white;
        }
        .card--number
        {
            font: normal bold 10pt Gotham, Arial;
            color: white;
        }
        .card--expiry
        {
            font: normal bold 7pt Gotham, Arial;
            color: white;
        }
    }

    .card-width .members--card-image{
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .card-width .members--card-image .card--details{
        bottom:20px;
        position: absolute;
        left: 23px;
        width: calc(100% - 10px - 36px);
    }

    .card-width .members--card-image .card--details .card-cardusername{
        font: normal normal 12px/13px Gotham,Arial;
          bottom: 43px;
          margin-left: 15px;
          color: #fff;
          z-index: 9;
          position: relative;
    }
  
    .card-display_title
    {
        @media (min-width: 768px)
        {
             width: 500px;
        }
        border: 1px solid #e2dbdb;
        text-align: center;
        background-color: #f3efef;
        padding: 4px;
    }
    .card-display
    {
        @media (min-width: 768px)
        {
            width: 500px;
        }
        border: 1px solid #e2dbdb;
    }
    #members-save-card,#members-print-card
    {
        padding: 11px 12px;
    }
    .update_address
    {
        margin-bottom: 15px;
    }
    background-color: #fff;
    padding-left: 17px;
    .selectize-control
    {
        .selectize-input
        {
            border-radius: 10px;
        }
    } 

        .redeem_miles
        {
            margin-top: 30px;
            h5
            {
                text-align: center;
                border-bottom: 1px solid;
                background-color: #f1f1f1;
            }
            @media (min-width: 768px)
            {
            width: 400px;
            }
            border: 1px solid;
            .row
            {
                border-bottom: 1px solid;
                margin: 0;
            }
            #payment_now
            {
                margin-top: 10px;
                border: 1px solid;
                width: 142px;
                padding: 6px 12px;
                margin-bottom: 10px;
                font-size: 11px;
                background : #888888 !important;
            }
            .redeem_now_active{
                margin-top: 10px;
                border: 1px solid;
                width: 142px;
                padding: 6px 12px;
                margin-bottom: 10px;
                font-size: 11px;
            }
            .redeem_now_disabled{
                margin-top: 10px;
                border: 1px solid;
                width: 142px;
                padding: 6px 12px;
                margin-bottom: 10px;
                font-size: 11px;
                background : #888888 !important;
            }
            .reedem_row,.payment_now
            {
                text-align: center;
            }
        }

        .termsandcondition{
            margin-top:25px;
            margin-bottom:25px;
        }

        label{
            position: absolute;
            margin-top: -10px;
            background-color: #fff;
            margin-left: 20px;
            padding-left: 4px;
            padding-right: 4px;
        }
        input
        {
            border-radius: 10px;
            margin-right: 5px;
        }
    
        .prefrence_card
        {
            border: 1px solid #006dbb;
            margin-top: 30px;
            .ta_head
            {
                text-align: center;
            }
            .th_head
            {
                width: 200px;
            }
            thead
            {
                border: 1px solid #006dbb;
                background: #006dbb;
                th
                {
                    color: white;
                }
            }
            th
            {
                // width: 204px;
                    font-size: 15px;
                @media (max-width: 767px)
                {
                    font-size: 12px;
                }

                    font-weight: 300;
            }
            tr
            {
                border: 1px solid #006dbb;
            }
        }
        #request-card
        {
            margin-top: 10px;
                border: 1px solid;
                width: 142px;
                padding: 6px 12px;
                margin-bottom: 10px;
                font-size: 11px;
            @media (max-width: 767px)
            {
                margin-bottom: 15px;
                font-size: 10px;
            }
        }
}