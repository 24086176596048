.related-partners{
	.title-description{
		width: 100%;
		padding: 0 0 30px;
		text-align: center;
	}
	.related-partner--container{
		border-top: 1px solid $lightgray;
		height: auto;
		overflow: hidden;
		display: block;
		padding-top: 2%;
		h2{
			text-align: center;
			margin-bottom: 0;
			color: $countdownblue;
		}
		p{
			@include font-size(16);
			color: $gray;
			text-align: center;
		}
		.related-partner--category{
			border-bottom: 1px solid $lightgray;
			margin: 20px 0 30px;
			padding-bottom: 30px;
			.slider-elements{
				width: 100%;
				height: auto;
				overflow: hidden;
				.slider{
					padding: 15px 40px 0 54px;

          &:not(.slick-initialized) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: start;
            -ms-flex-align: start;
                    align-items: flex-start;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
          }

					.slick-track{
						margin: auto !important;
					}
					> div, .slick-slide{
						margin-right: 20px;
						a{
							p{
								color: $blue;
								@include font-size(16);
								text-align: center;
								margin-top: 15px;
							}
						}
						.img-wrapper{
							border: 1px solid $lightgray;
							background: $white;
						}
						&:focus{
							outline: 0;
						}
					}
          > div:not(.slick-list) {
            width: 300px;
            margin-bottom: 20px;
          }
				}
				button{
					@include border-radius(100px);
					border: 1px solid $blue;
					padding: 20px;
					&:before{
						color: $blue;
						opacity: 1;
						line-height: 0;
						position: relative;
						left: -9px;
						top: -1px;
					}
					&.slick-prev{
						left: 0px;

					}
					&.slick-next{
						right: 0px;
						&:before{
							left: -6px;
						}
					}
					&:hover{
						background: $focusblue;
						&:before{
							color: $white;
						}
					}
				}
			}
			.btn-secondary{
				margin: 30px 43% 0;
			    display: inline-block;
			    width: 14%;
			}
			.slick-prev, .slick-next{
				&:before{
					@include font-size(42);
				}
			}
			.slick-prev, .slick-next{
				&:hover, &:focus{
					border: 1px solid $blue !important;
				}
			}
			&:last-child{
				border-bottom: 0;
			}
			.slick-dots{
				margin: 0;
				border-top: 0;
				bottom: -40px;
				padding: 0;
				li{
					width: 10px;
					button{
						padding: 0;
						width: 10px;
						height: 10px;
						&:before{
							opacity: 0;
						}
					}
					&.slick-active{
						button{
							background: $blue;
						}
					}
				}
			}
		}
	}
}

@include respond-to(450){
	.related-partners{
		.related-partner--container{
			.related-partner--category{
				.slick-dots{
					width: 72% !important;
				}
			}
		}
	}
}
