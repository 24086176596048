.enrich-revamp {
    .enrich-header {
        .container.container--main-header {        
            margin-top: -1px;
            background: #1E4073 !important;
            .header-wrapper {
                background: #1E4073 !important;
                border-bottom-right-radius: 0px !important;
            }
        }
        #slidemenu .nav.navbar-nav {
            float: left;
            padding-left: 34px;
            li {
                padding: 18px 10px 0;
            }
        }
        #mab-header .search-ele {        
            float: left;
            margin-left: 40px;
            margin-right: 10px;
            @media screen and (min-width: 1440px){
                margin-left: 2%;
            }
        }
        #mab-header.headroom--not-top {
            background: #1E4073 !important;
            border-bottom-right-radius: 0px !important;
        }
        #mab-header .logo-section {
            width: 74% !important;
            @media screen and (max-width: 1200px) {
                width: 100% !important;
                left: 0 !important;
            }
        }
        @media screen and (min-width: 1201px) and (max-width: 1440px){
            #mab-header>.row:first-child{
                padding-right: 0;
            }
        }
        #mab-header #logged-in-user-menu{
            right:0;
            bottom: -2px;
        }
        .select-country{
            display: none!important; 
        }
        #search-container{
            input[type=text]:focus{
                width: 35%;
            }
        }
        @media only screen and (max-width: 475px) {
            #mab-header .navbar.navbar-default .navbar-brand.MHLogo {
                margin-left: 38px;
            }
        }
        @media only screen and (min-width: 768px) and (max-width: 1200px) {
            #mab-header .navbar.navbar-default .navbar-brand.MHLogo {
                margin-left: 30%;
            }
        }
        @media screen and (max-width: 1200px) {
            #mab-header #slide-nav .enrich-login {
                float: left;
            }
            #mab-header #slide-nav .enrich-login--success {
                float: left;                
            }
        }
        #search-container {
            width: calc(100% - 292px);
            @media screen and (min-width: 1440px){
                width: 77%;
            }
        }
        #enrich-login-container {
            @media screen and (max-width: 1200px) {
                display: none;                
            }
            ul {
                list-style: none;
            }
            li {
                padding: 25px 15px 0;
                a {
                color: $white;
                @include font-size(14);
                padding: 0 0 5px 0;
                float: right;
                font-weight: bold;
                &.hvr-underline-from-left {
                    &.active:before,
                    &:before {
                    background: $errorred;
                    }
    
                    &.active:before {
                    right: 0;
                    }
                }
    
                &.active {
                    border: none;
                }
                }
            }
            .login {
                float: right;
        
                &.logged-in {display: none;}
              }
            .login-success {
                color: $white;
                @include font-size(14);
                display: none;
                padding: 0 0 5px 0;
                float: right;
                font-weight: bold;
                &.hvr-underline-from-left {
                    &.active:before,
                    &:before {
                    background: $errorred;
                    }
    
                    &.active:before {
                    right: 0;
                    }
                }
    
                &.active {
                    border: none;
                }
            }
        }
        .banner-container .enrich-content-holder {
            bottom: 46%;
            margin: 0 8%;
            width: 80%;
            color: unset;
            @media screen and (max-width: 767px) {
                bottom: 60%;
                margin: 20px;
                width: 85%;
            }
            @media screen and (min-width: 767px) and (max-width: 1200px) {
                bottom: unset; 
                width: 97%;
                margin: 0;               
            }
            @media screen and (max-width: 300px) {
                margin: 12px;
            }
        }
        .banner-container {
            overflow: visible;
            .img-responsive {
                @media screen and (max-width: 767px) {
                    height: 100%;
                    object-fit: cover;
                }
            }
            @media screen and (max-width: 767px) {
                position: absolute;
                padding: 0;
                left: 0;
                overflow: hidden;
                height: calc(100vh - 55px);
                .enrich-img-holder {
                    .mobile-version {
                        height: 100vh;
                        .enrich-home-swipe-more {
                            position: fixed;
                            bottom: 50px;
                            width: 100%;
                            text-align: center;
                            a.link-swipe-more {
                                color: #ffffff;
                            }
                            p.swipe-arrow {
                                line-height: 0;
                                margin-bottom: 5px;
                                i.fa-angle-up {
                                    font-size: 35px;
                                    line-height: .5;
                                }
                            }

                        } 
                    } 
                }
            }
        }
        .enrich-home-container{
            #mab-bookingnav {
                #slide-nav #bookingNav .navbar-nav li {        
                    background-color: transparent;
                    margin-right: 31px;
                    @media only screen and (min-width: 768px) and (max-width: 1200px) {
                        margin-right: 0px;
                    }
                }
             }
        }
        #mab-bookingnav {
            // background-color: unset;
            background-color: white;
            border-radius: unset;
            margin-top: -5%;
            .navbar {
                margin-bottom: 0px;
                border-radius: 10px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    border-radius: 0px;
                }
            }
            .navbar-default {
                background-color: #fff;
                border-color: #fff;
                width: 99.9%;
                box-shadow: 2px -4px 5px 0 rgba(0,0,0,.4);
                @media screen and (min-width: 1440px) {                    
                    margin-left: 0px;
                  }
            }
            #slide-nav #bookingNav .navbar-nav li {        
                background-color: transparent;
                margin-right: 35px;
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    margin-right: 30px;
                }
            }
            #slide-nav>.container {
                text-align: center;
                margin-top: 10px;           
            }
            .navbar.navbar-default #bookingNav .nav.navbar-nav {
                width: 100% !important;
                justify-content: center !important;
            }
            #bookingNav {
                display: inline-block;
                margin: 0 auto;
                padding: 3px;
                float: none !important;
                &:after {
                    content: "";
                    position: absolute;
                    border-bottom: 1px solid #00000029;
                    width: calc(100% - 40px);
                    height: 0;
                    bottom: 0;
                    left: 20px;
                }
            }
            #slide-nav #bookingNav .navbar-nav li.selected a {
                border-top: none;
                background-color: #1E4073;
                color: #fff;
                padding: 15px 30px;
                font: normal bold 14px/16px Gotham-Book, Arial;   
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    font: normal bold 12px/16px Gotham-Book, Arial;  
                }
                border-radius: 32px;    
            }
            #slide-nav #bookingNav .navbar-nav li.selected a.more-links {
                color: #fff;
            }
            #slide-nav #bookingNav .navbar-nav li a {
                font: normal bold 14px/16px Gotham-Book, Arial;
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    font: normal bold 12px/16px Gotham-Book, Arial;
                }
                color: #333333;
                padding: 15px 30px 13px;
            }
            #slide-nav #bookingNav .navbar-nav li a.more-links {
                color: #E84131;
            }
            #slide-nav #bookingNav .navbar-nav li a.hvr-overline-from-left:before {
                border-radius: 32px;  
                background-color: #1E4073;
                color: #fff;
                border-top: none;
            }
            #slide-nav #bookingNav .navbar-nav li.mholiday-tab a:hover {
                background-color: transparent;
                color: #1E4073;   
                border-radius: 32px;
            }
            #slide-nav #bookingNav .navbar-nav li a:hover {
                background-color: transparent;
                color: #1E4073;
            }
            #slide-nav #bookingNav .navbar-nav li a.more-links:hover {               
                color: #EA7B83;
            }
            #slide-nav #bookingNav .navbar-nav li.selected a:hover {
                border-radius: 32px;  
                background-color: #1E4073;
                color: #fff;
            }
            #check-in--container{
                border-radius: 0px;
            }
            .ibe-form--container {
                margin:initial;
                border-radius: 10px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                width: 99.9%;
                position: relative;
                z-index: 99;
                @media screen and (min-width: 767px) and (max-width: 1200px) {
                    border-radius: 0px;
                  
                }
                .close-container {
                    display: none;
                }
              
                .booking-form--title {
                    font-weight: bold !important;
                }
                @media screen and (min-width: 1440px) {                   
                    margin-left: 0px;
                  }
                
            } 
            .ibe-form--container:not(#mholiday-ibe--container) {
                .payment-type-container {
                    display: none !important;
                }
            } 
            .selectize-control .selectize-input {
                border-radius: 10px;
            }
            .selectize-control {               
                .airportcode {
                    background-color: #0D66B0;
                }
                .selectize-dropdown {
                    border-color: #1E4073;
                    .selectize-dropdown-content {
                        color: #1E4073;
                        &::-webkit-scrollbar-thumb {
                            background: #1E4073;
                        }
                    }
                    .option {
                        color: #1E4073;
                        font-weight: bold;
                    }
                    .active {
                        color: #ffffff;
                    }
                    p {
                        color: unset;
                    }
                }
            }
            .selectize-input {           
                border-radius: 10px;
                div {
                    color: #1E4073;
                    font-weight: bold;
                } 
                
                &.input-active {
                &.dropdown-active {               
                    border-radius: 10px 10px 0 0;  
                    border-color: #1E4073;               
                }
                }        
            
            }        
            .selectize-dropdown {           
                border-radius: 0 0 10px 10px; 
                .active {
                    background-color: #1E4073;
                }
            }
            .date-container-enrich {
                display: block;
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    padding-left: 0px;
                    padding-bottom: unset;
                }
                .col-lg-3 {
                    padding-left: 13px;
                    width: 30%;
                    @media only screen and (min-width: 768px) and (max-width: 1200px) {
                        padding-left: unset;
                        width: 50%;
                    }
                }
                .dep-return--title {
                    label {
                    font-weight: bold !important;
                    }
                }
            }
            
            .search-container, .date-container-enrich {
            
                .book-date--selector {
                
                @include border-radius(10px);
                &.oneWayTrip {       
                    .departureDate{
                    
                    @include border-radius(10px);
                    color: #1E4073;
                    
                    }
                } 
                &.returnTrip {
                    .departureDate{
                        color: #1E4073;
                    }
                    .returnDate{
                        color: #1E4073;
                    }
                }
                }
                .passenger-details,
                #passengar-details--mobile {    
                @include border-radius(10px);
                .title-caption {
                    font-weight: bold;
                }
                .total-passengers {
                    color: #1E4073;
                    font-weight: bold;
                } 
                }    
                .passenger-details-selected {  
                    width: calc(100% - 15px); 
                    @include respond-to(mid-screen-768-1200) {
                        width: 100%;
                    }
                    .title-caption {
                        font-weight: bold;
                    } 
                    .total-passengers {
                        color: #1E4073;
                        font-weight: bold;
                    } 
                @include border-radius(10px);
                .booking-person {
                    button {
                    @include border-radius(10px);    
                    background: #1E4073;    
                    &:disabled {
                        background: #CCCCCC;
                        border-color: #CCCCCC;
                    } 
                    } 
                }
                }   

                .couponcode-input, .hotelInputClass,
                .couponcode-input-mob {
                @include border-radius(10px);
                } 
            }
            .multi-cityTrip--Container {
                .book-date--selector{                    
                        @include border-radius(10px);
                        .multi-dep-title {
                            font-weight: bold;                            
                        }                    
                }
            }
            .trip-container {
                .trip-type--container {
                    li {
                       
                        &.selected {
                            color: #1E4073 !important;
                            a {
                                color: #1E4073 !important;
                            }
                        }
                        a {
                            color: #61666F!important;
                        }
                    }
                }
            }
            .search-container {
                .col-lg-4 {
                    width: 45%;
                    padding-left: 0px;
                    @media only screen and (min-width: 768px) and (max-width: 1200px) {
                        width: 50%;
                    }
                }
                .ibe_freesidetrip {
                    .col-lg-4 {
                        width: 25%;
                        padding-left: 10px;
                    }
                }
            }
            #view-booking--container .view-booking .input-lg {
                @include border-radius(10px);
            } 
            #check-in--container, #flight-status--container, #view-upgrade {
                .form-control {
                    @include border-radius(10px);
                } 
            }
            #more-links--container {
                padding: 20px;
                h6 {
                    font: normal bold 16px/18px Gotham-Book, Arial;
                    margin-bottom: 8px;
                }
                .more-list-container { 
                    ul {
                        padding-left: 0px;                        
                        list-style-type: none;
                        overflow: hidden;
                        margin-left: -20px;
                        li {                       
                            float: left;
                            a {
                                text-align: center;
                                padding: 15px 0px 0 0px;
                                display: block;
                                width: 107px;
                                p {
                                    color: #333333;
                                    font: normal normal 12px/13px Gotham-Book, Arial;
                                    margin-top: 7px;;
                                }
                                .img-more-holder {
                                    height: 60px;
                                    width: 60px;
                                    background-color: #F8F8F8;
                                    box-shadow: 0px 3px 6px #00000029;
                                    border-radius: 20px;
                                    padding: 17px 14px 17px 15px;
                                    margin: 0 auto;
                                }
                                .logo {
                                    width: 31px;
                                    height: 25px;
                                }                               
                            }
                        }
                    }
                }
            }   
            .btn-primary {
                @include border-radius(10px);
                background-color: #1E4073 !important;
            }
            .btn-primary--violetBkg {
                @include border-radius(10px);
            }
            .location-container {
                .fromLocation {
                    padding-right: 0px;
                }
                .toLocation {
                    padding-left: 0px;
                }
                .col-lg-5 {
                    width: 33%;
                }
                @include respond-to(mid-screen-768-1200) {
                    .col-lg-5 {
                        width: 50%;
                    }
                }
            }
            .ibe-from--location, .mholidays-ibe-from--location {
                .selectize-input {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    &.input-active {
                        &.dropdown-active {               
                            border-top-right-radius: 0px;                                      
                        }
                    }   
                }
            }
            .ibe-to--location, .mholidays-ibe-to--location {
                .selectize-input {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    &.input-active {
                        &.dropdown-active {               
                            border-top-left-radius: 0px;              
                        }
                    }      
                }
            }
            #slide-nav.mholidays-navbar {
                background-color: #F1955A;
                border-color: #F1955A;
                    #bookingNav { 
                        &::after {
                            border-bottom: 1px solid #ffffff;
                        }
                        .navbar-nav {
                            li a {               
                                color: #fff; 
                            }
                            li.selected a {                   
                                background-color: #ffffff;
                                color: #333333;
                            }               
                    }
                }
            }
            #mholiday-ibe--container {
                    .search-container {
                        position: unset;                       
                    }
                    .payment-type-container {
                    &::after {
                        border-bottom: 0px;
                    }
                    .payment-method-options {
                        label.active { 
                            background-color: #ffffff;
                            color: #1E4073;
                            border-radius: 25px;
                            .logo {
                                height: 16px;
                                float: left;
                                padding-right: 7px;                               
                            }
                            img {
                                &.logo {
                                    display: none;
                                }
                                &.active {
                                    display: inline-block;
                                }
                            }
                        }
                        label {
                            padding: 13px 41px;
                            border: 1px solid #ffffff;                            
                            color: #ffffff;
                            border-radius: 25px;
                            margin-bottom: 0px;
                            .logo {
                                height: 16px;
                                float: left;
                                padding-right: 7px;                                
                            }
                            [type=radio] { 
                                position: absolute;
                                opacity: 0;
                                width: 0;
                                height: 0;
                            }
                            img {
                                &.logo {
                                    display: inline-block;
                                }
                                &.active {
                                    display: none;
                                }
                            }
                        }

                    }
                }
                .hotel-container {
                    .search-container {
                        .col-lg-4 {
                            width: 22%;
                            padding-left: 10px;
                        }
                        .passenger-details-selected {
                            width: 425px;
                            @include respond-to(mid-screen-768-1200) {
                                .col-lg-1 {
                                    margin-top: unset;
                                }
                            }
                        }
                        .hotel-input-boarder{
                            border-radius: 10px 10px 0px 0px !important;
                          }
                        .hotel-display-tui-container {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                        .enrich-hotel-earn-display-tui-container {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                            .hotel-not{
                                padding-left: 5px;
                              }
                        }
                        .enrich-hotel-redeem-display-tui-container {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                            .hotel-not{
                                padding-left: 5px;
                              }
                        }
                        .col-lg-3 {
                            width: 26%;                            
                        }
                    }
                }
                @include respond-to(mid-screen-768-1200) {
                    .location-container {
                        padding-right: 20px;
                    }
                }
                @include respond-to(mid-screen-768-1200) {
                    .flight-container {
                        .search-container {
                            .col-lg-4 {
                                margin-left: 10px;
                            }
                            .cabinClass, .col-lg-1 {
                                margin-top: 10px;
                            }                            
                        }
                    }
                }
            }
                  
        }  
        .enrich-subhome {
            // position: fixed;         
            width: 100%; 
            left: 0;
            right: 0;            
            .enrich-subhome-page-links {
                display: flex;
                margin: 0 auto;
                padding-top: 11px;                      
                border-bottom: 1px solid #CCCCCC;  
                text-align: center;   
                ul {
                    padding-inline-start: 30px;
                    display: grid;
                    margin: 0 auto 6px auto;
                    list-style-type: none;
                    grid-template-columns: auto auto;  
                    text-align: start;
                    width: 100%;
                    li {
                        padding: 0 25px 0 0;
                        a {
                            font: normal bold 14px/16px Gotham-Book, Arial;
                            color: #333333;
                            padding: 0 0 0 0; 
                            @media screen and (max-width: 330px) {
                                font: normal bold 12px/16px Gotham-Book, Arial;
                            }      
                        }
                        a.hvr-underline-from-left:before {
                            background: #1E4073;
                            height: 4px;
                            border-radius: 2px;
                        }    
                    }
                }            
            }
        }
        .enrich-subhome-static-head {
            background-color: #ffffff;
            margin-bottom: 0;
        }
        .enrich-subhome-header-wrapper {
            height: 99px !important;
        }       

    }
    .ui-datepicker {
        @include border-radius(10px);
        .ui-datepicker-title-text {
            color: #1E4073;
        }
        .ui-datepicker-title {
            color: #1E4073;
        }
        .ui-datepicker-row-break {
            color: #1E4073;
        }
        a.ui-state-hover,
        .ui-datepicker-current-day a.ui-state-active {
          background: #0D66B0;     
        }
        .ui-state-selected,
        .highlight {
          background: #0D66B0 !important;
         
        }
    }   
    //Home news and promotions sliders
    .slider-wrapper{
        padding: 30px 8% 15px;
        color: #1E4073;
        background-color: white;
        .slider-header{
            display: flex;
            justify-content: space-between;
        }
        .slider-title{
            font: normal normal 18px/21px Gotham-Medium, Arial;
            padding:0;
            margin-bottom:30px;
            padding-left: 15px;
            width: 100%;
        }
        .see-all{
            color: #00376C;
            text-align: right;
            white-space: nowrap;
            font: normal normal 18px/15px Gotham-Book,Arial;
            align-self: flex-start;
            position: absolute;
            right:7%;
            line-height: 22px;
        }
        .slider,.slider-header{
            @media screen and (max-width:767px){
                &{  margin: 0px;
                    margin-right:-10%;
                    .slider-title{
                        padding-left: 0;
                        text-align: left;
                    }
                }
            }
            @media screen and (min-width:768px) and (max-width:992px){
                &{  margin: 0 -50px;
                    .slider-title{
                        padding-left: 15px;
                    }
                }
            }
        }
        ul{
            list-style: none;
            padding: 0%;
            display: flex;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
            li{
                margin-inline-end: 20px;
                padding-bottom: 5px;
                scroll-snap-align: start;
                @media screen and (max-width:767px) {
                    &:last-child{
                        padding-right:0px;
                    }
                }
            }
            
        }
        .slider-item-card{ 
            width:300px;
            height:280px;
            @include border-radius(10px);
            overflow: hidden;
            box-shadow: 0px 3px 6px #CCCCCC54;
            transform: translateZ(0);


            img{
                width:100%;
                height:160px;   
                object-fit: cover;
            }

            .card-body{
                padding: 14px 22px 18px 18px;
                background-color: $white;
                height:120px;
                &.overlay{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height:100%;
                }
            }
            .title{
                font: normal normal 14px/21px $font-family-medium;
                margin-bottom: 23px;
            }
            .link{
                font: normal normal normal 14px/21px Gotham-Book, Arial;
            }
        }
        .enrich-important{

            li{ 
                margin-inline-end: 20px;
                flex: 1;
                @media screen and (max-width:768px){
                    margin-inline-end: 13px;
                    flex: unset;
                }
                &:last-child{
                margin-inline-end: 0px;
                padding-right:0px
            }
            }
            .slider-item-card{
                height: 80px;
                width: auto;
                img{
                    height: 100%;
                    object-position: unset;
                }
            }
            .see-all{
                display: none;
            }
            .card-body{
                background: none;
                padding: 14px 18px 8px 15px;
                color: white;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                @media screen and (width:768px){
                    padding: 0 10px 8px 10px
                }
                & *{
                    color: white;
                    margin: 0;
                }
                .title p{
                    font:normal normal 18px/21px Gotham-Book, Arial;
                }
                i{
                    border: 3px white solid;
                    border-radius: 20px;
                    font-size: 10px;
                    line-height: 17px;
                    padding: 0 4px;
                    vertical-align: text-top;
                }
                .link{
                    @media screen and (width:768px){
                        display:none;
                    }
                }
                &.darken {
                    background: rgba(30, 64, 115, 0.5);
                }
            }
            @media screen and (max-width:767px){
                overflow-x: visible;
                display: block;
                min-width: 80px;
                .slider-item-card{
                        max-width: 92vw;
                        width: auto;
                }
                .slick-dots{
                    justify-content: center;
                    bottom: -37px;
                }

                .slick-dots li.slick-active button:before{
                    background: #1e4073;
                    content: " ";
                }
                .slick-dots li button:before{
                    opacity: 1;
                    content: " ";
                    border-radius: 8px;
                    border: 1px solid #707070;
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .enrich-upgrade{
            .slider-item-card{
                background: #eeeeee;
                width: 250px;
                height: auto;
                overflow: visible;
                border-radius: 5px;
                padding: 5px;
                box-shadow: none;
                @media screen and (min-width:320px)and (max-width:767px){
                    width:200px;
                }
                img{
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: auto;
                }
                &:hover{
                    background: #d4ad8b;
                    .card-body {
                        background: #d4ad8b;
                        & *{
                            color: white
                        }
                    }
                }
            }
            .card-body{
                background: #eeeeee;
                color: #616161;
                height: auto;
                padding: 15px;
                & *{
                    margin: 0;
                    color: #616161;;
                }
                h4#title{
                    font: normal normal 16px/12px Gotham-Book,arial;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                p{
                    font: normal normal 14px/15px Gotham-Book,Arial;
                }
                .link a{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    
                }
            }
            & ~ .seq-prev, & ~ .seq-next{
                top:52%;
            }  
        }
        .seq-prev,
        .seq-next {
        position: absolute;
        top: 40%;
        padding: 12px;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        color:white;
        z-index: 5;

        &:hover,
        &:active {
            background: rgba($black, 0.5);
        }

        @media  screen and (max-width:992px){
            &:hover,
            &:active {
            background: none;
        }
        }
        }
        .seq-prev{
            left:7%;
            display: none;
        }
        .seq-next{
            right:7%;
            @media  screen and (max-width:992px){
                right:3%;
            }
        }
    }
    #download-app-container{
        padding: 30px 8% 15px;
        background-color: white;
        #download-app-banner{
            background-color:#F8F8F8;
        }
        .banner-content{
            display: grid;
            padding: 0 50px;
            margin-top: -35px;
            grid-template-rows: auto 100px;
        }
        .title{
            display: flex;
            grid-column: 1;
            grid-row: 1;
            align-self: end;
            .app-icon{
                width: 70px;
                margin-right:10px; 
                
            }
            .title-content{
                align-self: center;
            }
            h4{
                font: normal normal 24px/18px Gotham-Book, Arial;
                color: #1E4073;
                margin-bottom:25px;
            }
            p{
                font: normal normal 18px/18px Gotham-Book, Arial;
                color: #61666F;
            }
        }
        .store-links{
            display: flex;
            grid-column: 1;
            grid-row: 2;
            align-self: end;
            padding-bottom: 25px;
            img{
                width: 135px;
                height:40px;
            }
        }
        .banner-img{
            grid-column: 2;
            grid-row-start: 1;
            grid-row-end: 3;
            justify-self: flex-end;
            img{
                width: 435px;
                @media screen and (max-width: 1048px) {
                    width: 300px;
                }
            }
        }
        @media screen and (max-width:1048px){
            .banner-content{
                padding: 0px;
            }
        }
        @media screen and (max-width:767px){
            
            padding:0px;
            .row{
                padding: 0 30px;
                background-color: #f0f0f0!important;
            }
            .banner-content{
                display: block;
                margin-top:0px;
                padding: 30px 30px;
            }
            .title p{
                font: normal normal 14px/18px Gotham-Book;
                
            }
            .title-content{
             align-self:center;       
            }
            .store-links{
                padding-bottom:0px;
                padding-top: 30px;
                flex-wrap: wrap;
                img{
                    height: 30px;
                    width: 100px;
                    margin-right: 5px;
                }
            }
        }
    }
    .mobile-enrich-home {
        .home-page-links {
            position: absolute;
            width: 100%;
            ul {
                padding-inline-start: 3px;
                width: 100%;
                list-style-type: none;
                margin: 0 auto; 
                display: grid;
                grid-template-columns: auto auto;           
                li {
                    padding: 0 20px 0 0;
                    // float: left;
                    // margin-right: 35px;
                    // &:nth-child(2) {
                    //     margin-right: calc(100%-77%);
                    //     float: right;
                    // }
      
                    
                    @include respond-to(320) {
                        margin-right: 15px;
                    }
                    a {
                        color: #ffffff;
                        font: normal bold 14px/16px Gotham-Book, Arial; 
                        line-height: 1.3125rem;
                        padding: 0px 0 3px 0; 
                        text-shadow: 0px 0px 10px #000000B3;      
                        @media screen and (max-width: 330px) {
                            font: normal bold 11px/13px Gotham-Book, Arial; 
                        }                                  
                    }
                    a.hvr-underline-from-left:before {
                        background: #ffffff;
                        height: 4px;
                        border-radius: 2px;
                    }    
                }
            }            
        }
        .enrich-home-search-flight {            
            margin-top: 80px;
            a.link-search-flight {
                padding: 22px 0px 22px 10px;
                background-color: #fff;
                border: 1px solid #CCCCCC;
                border-radius: 10px; 
                img.search-image {
                    margin-right: 17px;
                }
                label {
                    width: 100%;
                    font: normal bold 14px/16px Gotham-Book, Arial !important;
                    color: #707070;
                }
            }
        }
    }
    .enrich-sub-home-page {
        background-color: #ffffff;
        padding: 10px 20px;        
        .enrich-subhome-banner {
            .subhome-for-you {
                font: normal bold 18px/21px Gotham-Book, Arial;
                color: #1E4073;
                padding-left: 30px;
                margin-bottom: 10px;
            }
            .promo-offer-text {
                margin-top: 13px;
                padding-left: 7px;
                font: normal bold 14px/21px Gotham-Book, Arial;
                color: #333333;
            }
            .subhome-image {
                width: 100%;
            }
            .mob-enrich-subhome-book-now {
                width: 40%;
                background-color: #1E4073 !important;
                float: right;
                border-radius: 10px;
                margin-top: 20px;
            }
            .subhome-banner-viewall {
                text-align: center;
                a {
                    color: #00376C;
                    font: normal bold 14px/15px Gotham-Book, Arial;
                    margin-top: 9px;
                }
            }
            #mob-more-list-container { 
                ul {
                    padding-left: 0px;                        
                    list-style-type: none;
                    overflow: hidden;  
                    display:grid;
                    grid-template-columns: auto auto auto auto;
                                      
                    li {                       
                        // float: left;
                        a {
                            text-align: center;
                            padding: 15px 0px 0 0px;
                            display: block;
                            width: 82px;
                            @include respond-to(360) {
                                width: 80px;                                   
                            }
                            @include respond-to(320) {
                                width: 70px;
                            }  
                            p {
                                color: #333333;
                                font: normal normal 12px/13px Gotham-Book, Arial;
                                margin-top: 7px;;
                            }
                            .img-more-holder {
                                height: 60px;
                                width: 60px;
                                background-color: #F8F8F8;
                                box-shadow: 0px 3px 6px #00000029;
                                border-radius: 20px;
                                padding: 17px 14px 17px 15px;
                                margin: 0 auto;
                            }
                            .logo {
                                width: 31px;
                                height: 25px;
                            }                               
                        }
                    }
                }
            } 
        }       
    }
    #mab-bookingnav {
        &.mobile {
            .enrich-flight-header {
                border-bottom: 1px solid #CCCCCC;
                margin-bottom: 30px;
                h3 {
                    color: #1E4073;
                }
                .close-container {
                    .close-booking {
                        .fa-times-circle-o {
                            color: #E84131;
                            font-size: 24px;
                        }
                        padding: 6px 0px 0px 0px;
                      &.expanded {
                        padding: 6px 0px 0px 0px;
                      }
                    }
                  }
            }
            #mob-checkin--container, #mob-more-links--container, #mob-viewbooking--container, #mob-flightstatus--container, #mob-flightupgrade--container {
                h3 {
                    margin-bottom: 20px;
                }
                .close-container {
                    .close-booking {
                        padding: 0px;
                        .fa-times-circle-o {
                            color: #E84131;
                            font-size: 24px;
                        }
                      &.expanded {
                        padding: 0px;
                      }
                    }
                  }
            }
            #mob-checkin--container, #mob-flightupgrade--container {
                .form-control {
                    @include border-radius(10px);
                }
                .col-lg-3.checkInLastName {
                    width: 100%;
                } 
            }
            .ibe-form--container:not(#mobile-mholidays--container) {
                .payment-type-container {
                    display: none !important;
                }
            }
            .ibe-form--container {
                .ibe-mob-from--location-input, .ibe-mob-to--location-input {
                    border-radius: 10px;
                    .airportselected {
                        color: #1e4073;
                      }
                }
                .booking-form--title {
                    font-weight: bold !important;
                    &.input-filled{
                        margin-top: 0px;
                    }
                }
              
                .selectize-control .selectize-input {
                    border-radius: 10px;
                }
                .selectize-control {               
                    .airportcode {
                        background-color: #0D66B0;
                    }
                    .selectize-dropdown {
                        border-color: #1E4073;
                        .selectize-dropdown-content {
                            color: #1E4073;
                            &::-webkit-scrollbar-thumb {
                                background: #1E4073;
                            }
                        }
                        .option {
                            color: #1E4073;
                            font-weight: bold;
                        }
                        .active {
                            color: #ffffff;
                        }
                    }
                }
                .selectize-input {           
                    border-radius: 10px;
                    div {
                        color: #1E4073;
                        font-weight: bold;
                    } 
                    
                    &.input-active {
                    &.dropdown-active {               
                        border-radius: 10px 10px 0 0;  
                        border-color: #1E4073;               
                    }
                    }        
                
                }        
                .selectize-dropdown {           
                    border-radius: 0 0 10px 10px; 
                    .active {
                        background-color: #1E4073;
                    }
                }
            }
            .search-container, .date-container-enrich-mob {
            
                .book-date--selector {
                
                @include border-radius(10px);
                &.oneWayTrip {       
                    .departureDateMobile{
                    
                    @include border-radius(10px);
                    color: #1E4073;
                    
                    }
                } 
                &.returnTrip {
                    .departureDateMobile{
                        color: #1E4073;
                    }
                    .returnDateMobile{
                        color: #1E4073;
                    }
                }
                }
                .passenger-details,
                #passengar-details--mobile {    
                @include border-radius(10px);
                .title-caption {
                    font-weight: bold;
                }
                .total-passengers {
                    color: #1E4073;
                    font-weight: bold;
                } 
                }    
                .passenger-details-selected { 
                    .title-caption {
                        font-weight: bold;
                    } 
                    .total-passengers {
                        color: #1E4073;
                        font-weight: bold;
                    } 
                @include border-radius(10px);
                .booking-person {
                    button {
                    @include border-radius(10px);    
                    background: #1E4073;    
                    &:disabled {
                        background: #CCCCCC;
                        border-color: #CCCCCC;
                    } 
                    } 
                }
                }   

                .couponcode-input, .hotelInputClass,
                .couponcode-input-mob {
                @include border-radius(10px);
                } 
                .dep-return--title {
                    label {
                    font-weight: bold !important;
                    }
                }
            }
            .trip-container {
                .trip-type--container {
                    li {
                       
                        &.selected {
                            color: #1E4073 !important;
                            a {
                                color: #1E4073 !important;
                            }
                        }
                        a {
                            color: #61666F!important;
                        }
                    }
                }
            } 
            .btn-primary {
                @include border-radius(10px);
                background-color: #1E4073 !important;
            }
            .btn-primary--violetBkg {
                @include border-radius(10px);
            } 
            #mob-more-links--container {
                padding: 20px;
                h6 {
                    font: normal bold 16px/18px Gotham-Book, Arial;
                    margin-bottom: 8px;
                    margin-top: 20px;
                }
                h3 {
                    padding-left: 15px;
                }
                .enrich-flight-header {
                    margin-bottom: 0px;
                }
                .mob-more-list-container { 
                    ul {
                        padding-left: 0px;                        
                        list-style-type: none;
                        overflow: hidden;
                        margin-left: -20px;
                        li {                       
                            float: left;
                            a {
                                text-align: center;
                                padding: 15px 0px 0 0px;
                                display: block;
                                width: 107px;
                                @include respond-to(360) {
                                    width: 102px;                                    
                                }
                                @include respond-to(320) {
                                    width: 90px;
                                    height: 100px;
                                }  
                                p {
                                    color: #333333;
                                    font: normal normal 12px/13px Gotham-Book, Arial;
                                    margin-top: 7px;;
                                }
                                .img-more-holder {
                                    height: 60px;
                                    width: 60px;
                                    background-color: #F8F8F8;
                                    box-shadow: 0px 3px 6px #00000029;
                                    border-radius: 20px;
                                    padding: 17px 14px 17px 15px;
                                    margin: 0 auto;
                                }
                                .logo {
                                    width: 31px;
                                    height: 25px;
                                }                               
                            }
                        }
                    }
                }
            } 
            #mobile-mholidays--container {
                .mlogo {
                    margin-bottom: 30px;
                }
                .close-container {
                    .close-booking {
                        .fa-times-circle-o {
                            color: #ffffff;
                            font-size: 24px;
                        }
                        padding: 18px 20px 5px;
                      &.expanded {
                        padding: 18px 20px 5px;
                      }
                    }
                }
                .mob-hotel-container {
                    .hotelInputClass {
                        @include border-radius(10px);
                    } 
                    .hotel-input-boarder{
                        border-radius: 10px 10px 0px 0px !important;
                      }
                    .hotel-display-tui-container {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                    .enrich-hotel-earn-display-tui-container {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        .hotel-not{
                            padding-left: 5px;
                          }
                    }
                    .enrich-hotel-redeem-display-tui-container {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        .hotel-not{
                            padding-left: 5px;
                          }
                    }
                }
                .payment-method-options {
                    li {
                        margin-right: 10px;
                    }
                    label.active { 
                        background-color: #ffffff;
                        color: #1E4073;
                        border-radius: 10px;                       
                        .logo {
                            height: 30px;                 
                        }
                        .enrich-flight-hotel-icon, .enrich-hotel-icon {                           
                            img {
                                &.logo {
                                    display: none;
                                }
                                &.active {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    label {
                        padding: 13px 0px;
                        border: 1px solid #ffffff;                            
                        color: #ffffff;
                        border-radius: 10px;                       
                        width: 125px;
                        @include respond-to(320) {
                            width: 105px;
                        }
                        text-align: center;
                        .logo {
                            height: 30px;                  
                        }
                        [type=radio] { 
                            position: absolute;
                            opacity: 0;
                            width: 0;
                            height: 0;
                        }
                        .enrich-flight-hotel-icon, .enrich-hotel-icon {
                            width: 100%;
                            text-align: center;
                            float: left;
                            margin-bottom: 5px;
                            img {
                                &.logo {
                                    display: inline-block;
                                }
                                &.active {
                                    display: none;
                                }
                            }
                        }
                        
                    }

                }
                .redemption-payment-methods {
                    text-align: center;
                }

            } 
            #mob-viewbooking--container {
                .booking-methods {
                    padding-left: 0px;
                    li {
                       
                        &.selected {
                            color: #1E4073 !important;
                            a {
                                color: #1E4073 !important;
                            }
                        }
                        a {
                            color: #61666F!important;
                        }
                    }
                }
                .view-booking {
                    .input-lg {
                        @include border-radius(10px);
                    }
                }
                h3 {
                    color: #1E4073;
                }
            }
            #mob-flightstatus--container {
                .form-control {
                    @include border-radius(10px);
                }
                .airportselected, .status-flight--no {
                    color: #1e4073;
                  }
                  li {
                       
                    &.selected {
                        color: #1E4073 !important;
                        a {
                            color: #1E4073 !important;
                        }
                    }
                    a {
                        color: #61666F!important;
                    }
                }
            }           
        } 
        
    }
    .mobilelocation-popup {
        .selectize-control {               
            .airportcode {
                background-color: #0D66B0;
            }
            .selectize-dropdown {
                border-color: #1E4073;
                .selectize-dropdown-content {
                    color: #1E4073;
                    &::-webkit-scrollbar-thumb {
                        background: #1E4073;
                    }
                }
                .option {
                    color: #1E4073;
                    font-weight: bold;
                }
                .active {
                    color: #ffffff;
                }
            }
        }
        .selectize-input {  
            div {
                color: #1E4073;
                font-weight: bold;
            } 
            
            &.input-active {
            &.dropdown-active { 
                border-color: #1E4073;               
            }
            }        
        
        }        
        .selectize-dropdown { 
            .active {
                background-color: #1E4073;
            }
        } 
    }
    #mob-enrich-menu-left-container { 
        ul {
            padding-left: 0px;                        
            list-style-type: none;
            overflow: hidden;                    
            li {                       
                float: left;
                margin-bottom: 21px;
                a {
                    text-align: center;
                    padding: 15px 0px 0 0px;
                    display: block;
                    width: 82px;
                    p {
                        color: #1E4073;
                        font: normal normal 14px/16px Gotham-Book, Arial;
                        margin-top: 7px;;
                    }
                    .img-more-holder {
                        height: 49px;
                        width: 48px;
                        background-color: #ffffff;                        
                        border-radius: 20px;
                        padding: 12px 10px 10px 10px;
                        margin: 0 auto;
                    }
                    .logo {
                        width: 25px;
                        height: 26px;
                    }  
                    .fa.fa-th-large {
                        font-size: 24px;
                        color: #1E4073;
                    }                             
                }
            }
        }
    }
    .slideout-menu {
        width: 100%;
        @include respond-to(mid-screen-768-1200) {
            width: 375px;
        }
    } 
    .mob-enrich-menu-left, .mob-enrich-menu-right {
        padding-right: 0px;
        background-color: #E2E2E2;
        height: 100%;        
    }
    .mob-enrich-menu-left {
        padding-left: 0px;
    }
    .mob-enrich-menu-right {
        background-color: #1E4073;
        padding-left: 0px;
        width: 75% !important;              
        border: 1px solid transparent;
        overflow-y: auto;
        ul {
           background-color: transparent !important;
           padding-left: 0px;
        }
        .enrichclose {
            text-align: right;
            margin-top: 30px;
            margin-bottom: 24px;
            padding-top: 0;
            padding-left: unset;
            width: unset;
            height: unset;
            border-bottom: 1px solid transparent;
            .slide_close {
                i.fa.fa-close {
                    margin-right: 30px;
                    margin-left: 10px;
                    font-size: 20px;
                }               
            }
            .slideOutClose {
                color:#ffffff;
            }
        }
        .sidebar-menu-link {           
            i.fa {
                -webkit-transition: 0.25s all ease-in-out;
                -o-transition: 0.25s all ease-in-out;
                transition: 0.25s all ease-in-out;
                float: right;    
                line-height: 4.375rem;
                font-size: x-large;                
                margin-right: 23px;
                margin-top: -20px;
              }              
            }
        .members__menu {
            &.sidebar-menu--level-1 > .sidebar-menu-item {
                >a {
                    color: #ffffff !important;
                    font: normal normal 16px/18px Gotham-Book,Arial !important;   
                    display: inline-block;       
                    width: 100%;         
                }
            }
            &.sidebar-menu--level-1 {
                background-color: transparent !important;
                border: 0;
                .members__page {
                    line-height: 3;
                    .sidebar-menu-link{ 
                        margin-left: unset;
                    }
                    .sidebar-menu--level-2, .sidebar-menu--level-3 , .sidebar-menu--level-4
                    {
                     background: #ffffff !important;
                     color: #333333;
                     .enborder{                 
                        border-bottom: 1px solid #cccccc;
                        margin-left: unset;
                    }
                    }
                    .alleft {
                        margin-left: 50px !important;
                        color: #333333 !important;
                        font: normal normal 16px/18px Gotham-Book,Arial;
                    }
                }
            }

        } 
        #country-selector--mobile {
            display: none;
            background-color: transparent;
            color: #ffffff;
            .fa.fa-angle-down {
                transform: rotate(-90deg)!important;
            }
            .fa.fa-angle-up {
                transform: rotate(-180deg)!important;
            }
            .fa {
                margin-top: -26px;
                margin-right: 16px;
                font-size: x-large;
            }
            .selectize-input {           
                border-radius: 10px;
                div {
                    color: #1E4073;
                    font-weight: bold;
                } 
                
                &.input-active {
                &.dropdown-active {               
                    border-radius: 10px 10px 0 0;  
                    border-color: #1E4073;               
                }
                }        
            
            }        
            .selectize-dropdown {           
                border-radius: 0 0 10px 10px; 
                .active {
                    background-color: #1E4073;
                }
            }
            .btn-primary {
                @include border-radius(10px);
                background-color: #0D66B0 !important;
            }            
        }
        .search-icon {
            background-color: transparent;
            border: 0;
            .search-input {
                .input-lg {
                    @include border-radius(10px);
                    padding-left: 50px;
                }               
            }
            .fa-search {
                right: unset;
                left: 20px;
            }
        }
    }
    .footer-container {
        .newsletter-container {
            @include respond-to(tablet-portrait){
                padding-left: 0;
                padding-right: 0;
            }
            .newsletter-widget--holder, .newsletter-widget--success, .newsletter-widget--manage, .newsletter-widget--notsubscribed {
                margin-top: 0px;
                .col-lg-12.col-md-12.col-sm-12 {
                    padding-left: 0px;
                    padding-right: 0px;
                    margin-bottom: 10px;
                }
                h1 {
                    font: normal bold 18px/18px Gotham-Book, arial;
                    text-align: left;
                    margin-bottom: 10px;
                    color: #1E4073;
                }
                p {
                    text-align: left;
                }
                .newsletter-form {
                    padding-left: 0px;
                    padding-right: 0px;
                    margin-top: 10px;
                }
                .col-lg-2 {
                    width: 0;
                }
                .existingSubscriber {
                    width: 100%;
                    padding: 0;
                }
                .newsletterTandC {
                    label {
                        padding-left: 0px;
                        display: -webkit-box;
                    }
                }
                .newsletterBtn{
                    @include respond-to(ipad-portrait){
                      width: 100%;
                    }
                  }
            }
            .newsletter-widget--success {
                .email-details {
                    text-align: left;
                }
                #newsletterResend {
                    width: 50%;
                }
            }
            .newsletter-widget--manage {
                .manage-error--validation {
                    width: 100%;
                }
                .error-container {
                    .col-lg-12.col-md-12.col-sm-12 {
                        margin-bottom: 0px;
                    }
                }
                #newsletterManageEmail {
                    @include border-radius(10px);
                }
                #newsletterManageSubmit {
                    width: 50%;
                    float: right;
                }
                .notExistingSubscriber {
                    width: 100%;
                }
                @include respond-to(tablet-portrait) {
                    margin-right: 0px;
                }
                .newsletterManageEmail {
                    @include respond-to(ipad-portrait){
                        width: 100%;
                      }
                }
            }
            .newsletter-error--validation {
                width: 100%;
                .col-lg-11 {
                    width: 100%;
                }
                #newsletter-validator {
                    li {
                        width: 100%;
                    }
                }
            }
            #newsletterEmail {
                @include border-radius(10px);
            }
            .selectize-input {           
                border-radius: 10px;
                div {
                    color: #1E4073;
                    font-weight: bold;
                } 
                
                &.input-active {
                &.dropdown-active {               
                    border-radius: 10px 10px 0 0;  
                    border-color: #1E4073;               
                }
                }        
            
            }        
            .selectize-dropdown {           
                border-radius: 0 0 10px 10px; 
                .active {
                    background-color: #1E4073;
                }
            }
            .btn-primary {
                @include border-radius(10px);
                background-color: #1E4073 !important;
            }
            #newsletterSubmit {
                width: 50%;
                float: right;
            }
        }
    }   
}   
#enrich-point-list {
  float: left;
  padding-left: 10px;
  margin-bottom: 15px;
  height: 20px;
  li {
    @include font-size(14);
    list-style-type: none;
    float: left;
    color: $gray;
    margin-right: 30px;
    &.selected {
      color: $linkblue !important;
      border-bottom: 1px dashed $linkblue;
      a {
        color: $linkblue !important;
        text-decoration: none;
        &:hover,
        &:focus,
        &:active {
          &:before {
            right: 100% !important;
          }
        }
      }
    }
    a {
      color: $gray !important;
      &:hover,
      a:focus {
        color: $linkblue;
        text-decoration: none;
      }
    }
  }
} 
.enrich-revamp-subpage {
    .banner-container {
        .enrich-img-holder {
            .enrich-desktop-imgholder {
                display: none !important;
            }
        }
    }
    #mab-bookingnav {
        margin-top: 0px !important;
        .ibe-form--container {
            max-width: 100% !important;
            width: 100% !important;
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
        .navbar-default {
            max-width: 100% !important;
            width: 100% !important;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }
    }
    @include respond-to(tablet-portrait) {
        .enrich-home-container {
            display: none;
        }
    }
}