#fly-pass-booking---container {
    padding-top: 20px;
    .search-description {
        padding-bottom: 15px;
    }
    #fly-pass--trip {
        .form-group {
            display: inline-block;
            width: 100%;
            &.fly-icon {
                width: 52%;
                position: relative;
                right: -5px;
                top: 30px !important;
                .flight-trip--icon:hover {
                    -webkit-transform: none !important;
                }
            }
            button {
                cursor: pointer;
            }
            #onSearchFlyPass {
                margin-top: 15px;
            }
        }
        .location-container {
            .flight-trip--icon {
                position: absolute !important;
                transition-duration: 0ms !important;
                transition-property: none !important;
            }
            .fly-icon .flight-trip--icon {
                width: 20px; 
                top: -4px;
                left: 60%;
            }
        }
    }
    .recommended-fly-passes {
        padding-top: 20px;
        .all {
            padding-top: 12px;
        }
        #travelPassCode {
            font-weight: 600;
        }
        .iframe-wrapper{
            margin-top: 20px;
            margin-bottom: 50px;
            .responsive-iframe {
                width: 100%;
                height: 500px;
                border: 0px;
                @media screen and (max-width:767px){
                    height:740px;
                }
            }
            
        }
    }
    .hideclass {
        display: none;
    }
    .showclass {
        display: block;
    }
    .error {
        color: #ed1c24;
    }
    .selectize-control .selectize-dropdown .optgroup .optgroup-header {
        background-color: #005aaa !important;
        color: #fff;
    }
}

