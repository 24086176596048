.login-component{
	margin-top: 15px;
	margin-bottom: 15px;
	h1{
		text-align: center;
		line-height: 1em;
	}
	h2{
		line-height: 0.6em;
		color: $gray;
	}
	h3{
		color: $focusblue;
		line-height: 1em;
		margin-bottom: 10px;
		margin-top: 12px;
	}
	img{
		&.logo{
			 margin: 20px auto;
    		display: block;
		}
	}
	p{
		font-family: $font-family-light;
		@include font-size(14);
	}
	a{
		@include font-size(14);
		margin-left: 22px;
	}
	.row{
		margin-top: 10px;
		@include respond-to(tablet-portrait){
			margin-top: 0;
		}
	}
	.form-element{
		display: block;
		margin: 15px 0;
	}
	.checkbox-container{
		display: block;
		margin: 25px 0;
	}
	.booking-form--title{
		position: absolute;
		margin-left: 24px;
		margin-top: 14px;
		color: $darkgray;
		@include font-size(14);
		@include z-index(bookformtitle);
		&.input-filled{
			margin-top: 6px;
			margin-left: 22px;
			@include font-size(12);
		}
		&.has-error{
			color: $errorred;
		}
	}
	input{
		&.input-focused{
			padding: 21px 20px 4px 21px;
		}
		&.remember-id{
			float: left;
			margin-right: 10px;
			height: 16px;
			color: $gray;
		}
	}
	.sep{
		width: 1px;
		height: 135px;
		background: $lightgray;
		display: inline-block;
		text-align: center;
		margin: 15px 49% 0;
	}
	a{
		margin-right: 6px;
		&.btn{
			width: 47%;
			float: left;
			margin-left: 0;
			margin-right: 10px;
			&:last-child{
				margin-right: 0;
				margin-right: 0;
			}
		}
		&:last-child{
			margin-right: 0;
		}
	}
	.login-error--validation{
		border-bottom: 1px solid $errorred;
		margin: 0 0 15px 15px;
		padding: 10px 0;
		display: none;
		width: 95%;
		color: $errorred;
		@include respond-to(tablet-portrait){
			width: 92%;
		}
		.fa {
			color: $errorred;
			@include font-size(18);
			float: left;
		}
		.col-lg-1{
			padding-right: 0;
			width: 5%;
			@include respond-to(tablet-portrait){
				float: left;
				width: 16%;
			}
		}
		.col-lg-11{
			padding-left: 0;
		}
		.error-msgs{
			@include font-size(14);
			color: $errorred;
			ul{
				padding: 0 0 0 15px;
			}
		}
		#result{
			@include font-size(14);
			color: $gray;
		}
		.login-validator{
			margin: 2px 0 0 6px;
			@include font-size(14);
			.validation--error-list {
				li {
					font-weight: bold;
				}
			}
		}
	}
	.login-form{
		width: 50%;
	}
}

@include respond-to(1300){
	.login-component{
		.col-lg-8{
			width: 80%;
		}
		.col-lg-4{
			width: 20%;
		}
	}
}

@include respond-to(1000){
	.login-component{
		.col-lg-8{
			width: 90%;
		}
		.col-lg-4{
			width: 10%;
		}
		.login-form{
			width: 100%;
		}
	}
}

@include respond-to(850){
	.login-component{
		.col-lg-8{
			width: 100%;
		}
		.col-lg-4{
			width: 0%;
		}
	}
}

@include respond-to(ipad-portrait){
	.login-component{
		.col-lg-5{
			width: 45%;
		}
		.col-lg-2{
			width: 10%;
		}
		input{
			&.remember-id{
				padding: 8px;
			}
		}
	}
}
@include respond-to(tablet-portrait){
	.login-component{
		.sep{
			width: 100%;
			height: 1px;
			margin: 25px 0 15px;
		}
		h1{
			@include font-size(36);
			line-height: 1em;
		}
		h2{
			@include font-size(30);
			line-height: 1em;
			margin-bottom: 0;
		}
		h3{
			@include font-size(30);
			line-height: 1em;
		}
		p{
			margin-top: 20px;
		}
		.std-login-form-align-center {
			text-align: center !important;
			a {
				float: none !important;
				margin-left: 22px;
			}
		}
	}
}