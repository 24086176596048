/** Members -- Tab Styles */
.members__content {
  .nav-tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    .nav-tabs--arrow {
      display: none;
      width: 15px;

      @media screen and (max-width: 1200px) {
        display: block;
      }

      &.left {
        margin-right: 5px;
      }

      &.right {
        text-align: right;
        margin-left: 5px;
      }
    }
  }
  .nav-tabs-wrapper {
    @media screen and (max-width: 767px) {
      -webkit-user-select: none;
      display: block;
      height: 51px;
      overflow-y: hidden;
      padding: 0;
      position: relative;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .nav-tabs {
        display: inline-block;
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        li {
          margin-right: 20px;
          display: inline;
          float: none;

          a {
            display: inline-block;
          }
        }

      }
    }

    @media screen and (max-width: 1200px) {
      width: calc(100% - 40px);
    }

    @media screen and (min-width: 1200px) {
      width: 100%;
    }

  }

  .tab-content {
    .tab-pane {
      padding: $content-padding 0;
    }
  }

  .nav-tabs > li.active > a,
  .tab-content .tab-pane {
      border: 0;
    // background-color: $alto;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
      border: 0;
      border-bottom: solid 3px $red;
      color: $countdownblue;
  }

  .nav-tabs > li {
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }

    > a {
      font-size: rem(14);
      font-family: $font-family-medium;
      color: $countdownblue;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom-width: 3px;
      padding-left: 0;
      padding-right: 0;
      text-transform: uppercase;

      &:hover {
        border-color: transparent transparent $red;
        background-color: initial;
      }
    }
  }
}
