.flightstatus-searchresults__title {
  margin-bottom: 20px;

  .title {
    color: $blue;
  }
}

.flight-status__form .form {
  @media screen and (max-width: 767px) {
    .form-group .col-xs-12.flightstatus--depDate,
    .form-group .col-xs-12.flightstatus--destTo,
    .form-group .col-xs-12.flightstatus--flightNo {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .form-group .col-xs-12.flightstatus--depDate,
    .form-group .col-xs-12.flightstatus--destTo,
    .form-group .col-xs-12.flightstatus--flightNo {
      margin-bottom: 10px;
    }
  }

  .flight-submit.form-actions .submit {
    margin-right: 10px;

    @media screen and (max-width: 1199px) {
      min-width: 145px;
      width: auto;
    }

    @media screen and (min-width: 1200px) {
      width: calc(50% - 5px);
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.flightstatus-searchresults__table {
  .state {
    &-ARRIVED, &-DEPARTED, &-ON TIME {
      color: $green;
    }
    &-ON THE WAY {
      color: $countdownyellow;
    }
    &-CANCELLED, &-RETIMED, &-DELAYED {
      color: $red;
    }
  }

  table.dataTable {
    margin-top: 20px;
    margin-bottom: 10px;
    min-width: 100%;
    max-width: 100%;
    overflow-x: auto;
    margin-left: 0;

    @media screen and (min-width: 650px) {
      min-width: 600px;
    }

    @media screen and (max-width: 649px) {
      width: 100% !important;
    }

    &--full-width {
      width: 100%;
    }

    td {
      padding: 20px;
      font-size: rem(14);

      &:first-child {
        padding-left: 0;
      }
    }

    th {
      line-height: 1.2;
      padding: 10px 20px;
      text-align: left;
      font-weight: 600;
      font-family: $font-family-medium;
      text-transform: uppercase;
      vertical-align: bottom;

      &:first-child {
        padding-left: 0;
      }
    }

    tr {
      border-bottom: solid 1px $lightgray;
    }

    tr[role='row'] {
      border-bottom: solid 1px $seashell;

      &.parent {
        border-bottom-color: transparent;
      }

      + .child {
        border-bottom: solid 1px $seashell;
      }
    }

    td.status {
      text-transform: uppercase;
    }

    td.departure,
    td.arrival {
      .location {
        font-family: $font-family-medium;

        @media screen and (max-width: 479px) {
          line-height: 1.325
        }
      }
    }
  }
}
