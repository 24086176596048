.container.container--main-header {
  @include z-index(containermainheader);
  position: relative;
  min-height: 73px; // height of header navbar
}

@media screen and (min-width: 768px) {
  // headroom css
  .headroom {
      will-change: transform;
      transition: transform 200ms linear;
      top: 0;
      &.headroom--not-top {
        box-shadow: 0 -4px 8px 0px $gray;
      }
  }
  .headroom--pinned {
    transform: translateY(0%);
  }
  .headroom--unpinned {
    transform: translateY(-100%);
  }
}

.no-scroll {
  overflow: hidden;
}

// slideout css
.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 310px;
  // min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
  background-color: #fff;

  &::webkit-scrollbar {
    display: block;
  }

  .navbar-nav {
    position: relative;
  }
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  // will-change: transform;
  background-color: #FFF; /* A background-color is required */
  min-height: 100vh;
}

#main-content {
  background-color: #fff;
}

.slideout-sticky {
  will-change: initial !important;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-panel {
  -webkit-filter: brightness(0.35);
}

.slideout-open .slideout-menu {
  display: block;
}

#main-content {
  &.slideout-open {
    &:after {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      z-index: 99999;
    }
  }

  > .container {
    &.container--main-header{
      background: $ibeblue;
    }
    .header-wrapper{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#19286e+0,005aaa+100 */
      background: $blue; /* Old browsers */
      background: -moz-linear-gradient(left, #19286e 0%, #005aaa 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #19286e 0%,#005aaa 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #19286e 0%,#005aaa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19286e', endColorstr='#005aaa',GradientType=1 ); /* IE6-9 */
      @include border-radius(0 0 30px 0);
      height: 73px;
      @include respond-to(tablet-portrait){
        @include border-radius(0);
        height: 55px;
      }
      @include respond-to(ipad-portrait) {
        @include border-radius(0);
      }
    }
  }
}
#mab-header {
  background: transparent;
  width: 100%;
  height: auto;
    z-index: 100;
    @include clear-bs-row;
    > .row {
      position: relative;
    }
    > .row:first-child {
      @media screen and (min-width: 1201px) and (max-width: 1440px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &.alert-exists {
      #country-selector--container {
        top: 76px;
      }
    }
    &.add-bkg {
      background: transparent;
    }
    .logo-section {
      width: 91%;
        @media screen and (max-width: 1650px) {
        width: 90%;
      }
      @media screen and (max-width: 1510px) {
        width: 89%;
      }
          @media screen and (max-width: 1440px) {
        width: 88%;
      }
    }
    .campaign {
      width: 100% !important;
    }
  .navbar {
    margin-bottom: 0;
    &.navbar-default {
      background-color: transparent;
      border-color: transparent;
      padding: 6px 0 15px;
      border: none;
      .navbar-brand {
        padding: 10px 15px 10px 0;
        float: left;
        &.MHLogo {
          margin-left: 15px;
          @media screen and (min-width: 768px)
          {
            margin: 0;
            height: auto;
            padding: 0;
            padding: 10px 0px 0px 0;
          }
          img{
            height: 40px;
          }
        }
        &.EnrichLogo{
          margin-left: 38px;
          @media screen and (min-width: 768px){
              margin: 0;
              height: auto;
              padding: 0px;
              margin-left: 38px;
              padding: 10px 0px 0px 0;
          }
            img{
              height: 40px;
            }
        }
        &.oneWorldLogo {
          margin: 3px 0 0 5px;
          @media screen and (min-width: 768px)
          {
            margin: 0;
            height: auto;
            padding: 0px;
            margin-left: 38px;
            padding: 14px 0px 0px 0;
          }
          img {
            width: 35px;
            height: 35px;
            @media screen and (min-width: 768px)
            {
              width: 36px;
              height: 36px;
            }            
          }
        }
      }
      .navbar-nav {
        li {
          padding: 18px 15px 0;
          a {
            color: $white;
            @include font-size(14);
            padding: 0 0 5px 0;
            &.hvr-underline-from-left {
              &.active:before,
              &:before {
                background: $errorred;
              }

              &.active:before {
                right: 0;
              }
            }
            

            &.active {
              border: none;
            }
          }
        }
      }
      .active {
        a {
          background-color: transparent;
        }
      }
    }
  }

  &.desktop {
    // fixed header styling for desktop
    right: 0;
    left: 0;
    top: 0;
  }
  &.headroom--top {
    position: absolute;
  }
  &.headroom--not-top {
    position: fixed;
    background: $blue; /* Old browsers */
    background: -moz-linear-gradient(left, #19286e 0%, #005aaa 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #19286e 0%,#005aaa 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #19286e 0%,#005aaa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19286e', endColorstr='#005aaa',GradientType=1 ); /* IE6-9 */
    @include border-radius(0 0 30px 0);
    @include respond-to(tablet-portrait) {
      top: 0;
      @include border-radius(0);
    }
    @media screen and (min-width: 768px) {
      max-width: none;
      > .row {
        max-width: 1200px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &.mobile {
    right: 0;
    left: 0;

    @include respond-to(tablet-portrait) {
      // fixed header styling for mobile

      // &.headroom--not-top.headroom--pinned {
      //  position: fixed;
      // }
    }
  }
}
#slidemenu {
  .nav {
    &.navbar-nav {
      float: right;
    }
  }
}

.navbar-header {
  position: relative;
}

.mab-nav--overlay {
  display: none;

  // @include respond-to(1200) {
  //   display: block;
  // }

  &.height--expanded {
    height: 100%;
  }
  .navbar-header {
    .navbar-brand {
      position: relative;

      &.MHLogo {
        width: auto;
        margin-right: 20px;
        padding: 0;
      }
      &.oneWorldLogo {
        width: auto;
        max-width: 35px;
          padding: 0;
          img {
          -webkit-transform: translateY(50%);
          transform: translateY(50%);
          top: -25%;
          }
      }
      img {
        width: 100%;
        position: relative;
      }
    }
  }
  .navbar-nav {
    margin: 0 !important;
    clear: both;
    width: 100%;
    background-color: $seashell;
    @media screen and (max-width: 480px) {
      // min-height: 160%;
    }
    li {
      margin: 0;
      padding: 0;
      background-color: $seashell;
      border-bottom: 1px solid $white;
      border-bottom: 1px solid $lightgray;
      &.booking-nav {
        border-bottom: 1px solid $white;
        background-color: $linkblue;
        a {
          color: $white;
          i {
            &.fa {
              margin-right: 10px;
              @media screen and (max-width: 1200px) {
                width: 16px;
              }
            }
          }
        }
      }
      a {
        color: $gray;
        padding: 8px 16px !important;
        @include font-size(14);
        &:hover {
          background: transparent;
        }

        &.active {
          @media screen and (max-width: 767px) {
            border: none;
            font-weight: 600;
            font-family: $font-family-medium;
          }
        }
      }
    }
  }
  .close-slide {
    @include font-size(20);
    line-height: 1em;
    color: $blue;
    cursor: pointer;
    float: right;
    display: block;
    text-align: right;
    padding: 2% 3% 2% 0;
  }
}

#slide-nav {
  .navbar-toggle {
      cursor: pointer;
      position: relative;
      line-height: 1;
      float: right;
      margin: 0 0 0 5px;
      width: 28px;
      height: 40px;
      border: 0;
      background: transparent;
      @include z-index(navToggle);
      @include font-size(32);
      line-height: 1.3em;
      display: inline-block;
      margin-top: 0;
      padding: 0;
      margin-left: 5px;
      padding-top: 6px;
      padding-left: 10px;
      color: $white;
      @media screen and (min-width: 1201px) {
      display: none;
      }
      i {
        display: inline-block;
      }
      .icon-bar {
        width: 100%;
        display: block;
        height: 3px;
        margin: 5px 0 0 0;
        background-color: #084774;
      }
      &.slide-active {
        .icon-bar {
          background: #084774;
        }
      }
  }
  .enrich-login {
    float: right;
    @include font-size(28);
    line-height: 0.2em;
    color: $white;
    margin-top: 17px;
    padding-right: 20px;
    margin-right: 5px;
    display: none;
    cursor: pointer;
    @include respond-to(large-desktop) {
      display: none;
    }
  }
  .enrich-loggedin {
    float: right;
    @include font-size(28);
    line-height: 0.2em;
    color: #002b5c !important;
    margin-top: 17px;
    padding-right: 20px;
    margin-right: 5px;
    cursor: pointer; 
    @include respond-to(large-desktop) {
      display: none;
    }
  }
  .enrich-menu-hide {
    display:none !important;
  }  
  .enrich-menu-hideborder {
    border-right: none !important;
  }
  .enrich-signup {
    float: right;
    @include font-size(28);
    line-height: 0.2em;
    color: $white;
    margin-top: 17px;
    padding-right: 20px;
    margin-right: 5px;
    display: none;
    cursor: pointer;
    @include respond-to(large-desktop) {
      display: none;
    }
  }
  .enrich-login--success {
    float: right;
    margin-top: 10px;
    // border-right: 1px solid $lightgray;
    padding-right: 12px;
    display: none;
    &.active {
      border-top: none;
      border-bottom: none;
      border-left: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
    .member {
      display: block;
      width: 32px;
      height: 32px;
      @include border-radius(100px);
      @include font-size(12);
      font-family: $font-family-medium;
      background: $lightgray;
      padding: 8px 6px;
      color: $gray;
    }
  }
  /* MABUAT1-2 Fix*/
  /* Update rule */
  > .container {
      width: 100%;
  }
  #userLogin {
    float: right;
    .login-container {
      margin: 15px 15px 12px;
      @include font-size(14);
      .fa-user {
        margin-right: 10px;
      }
    }
  }
}

#slide-nav {
  /* MABUAT1-2 Fix*/
  #bookingNav {
    float: left;
    .navbar-nav {
      li {
        a {
          &.longCharLength {
            line-height: 1rem;
            font-size: .8rem;
            padding: 15px 10px 13px;
            @media screen and (max-width: 991px) and (min-width: 875px) {
              font-size: .75rem;
              padding: 15px 10px 13px;
            }
            @media screen and (max-width: 874px) and (min-width: 768px) {
              font-size: .75rem;
              padding: 15px 10px 13px;
              line-height: .7rem;
            }          
          }
          @include font-size(16);
          -webkit-transition: all 110ms ease-in-out;
          -o-transition: all 110ms ease-in-out;
          transition: all 110ms ease-in-out;
          padding: 15px 20px 13px;
          height: 100%;

          @media screen and (min-width: 768px) and (max-width: 991px) {
            font-size: rem(12);
            padding: 15px 15px 13px;
          }

          &.hvr-overline-from-left:before {
          }

          &:hover,
          &.selected {
            background-color: $white;
            color: $linkblue;
          }

          i.fa {
            margin-right: 8px;
          }
        }
        &.selected {
          background-color: #fff;

          a {
            border-top: 2px solid #ED1C24;
            background-color: $white;
            color: $linkblue;
            padding: 13px 20px;

            &.longCharLength {
              line-height: 1rem;
              padding: 17px 20px;
              @media screen and (max-width: 991px) and (min-width: 875px) {
                font-size: .75rem;
                padding: 13px 10px;
                line-height: 1.5rem;
              }
              @media screen and (max-width: 874px) and (min-width: 768px) {
                font-size: .75rem;
                padding: 13px 15px;
                line-height: .7rem;
              }          
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
              font-size: rem(12);
              padding: 13px 15px;
            }

            &:hover,
            &:focus,
            &:active {
              &:before {
                border: 0 !important;
              }
            }

            &.hvr-overline-from-left:before {
              top: -2px;
            }
          }
        }
      }
    }
  }
}


#slide-nav {
    .login-nav {
      li {
        a {
          @include font-size(16);
          padding: 15px 20px 13px;
          i {
            &.fa {
              margin-right: 8px;
            }
          }
          &:hover, &.selected {
            background-color: $white;
            color: $linkblue;
          }
        }
        &.selected {
          background-color: none;
            border-top: 0;
          a {
              padding: 13px 20px 13px;
              &:hover, &:focus, &:active {
              &:before {
                border: 0 !important;
              }
            }
          }
        }
      }
      .login {
        float: right;

        &.logged-in {display: none;}
      }
      .login-success {
        @include font-size(16);
        display: none;
        color: $white;
        padding: 14px 10px;
        cursor: pointer;
        img {
          width: 32px;
          margin-left: 10px;
        }

        &.logged-in {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          max-width: 100%;

          &:hover {
            color: $blue;
            background-color: $white;
          }
        }
      }
      .selected {
        .logged-in {

          &.active {
            color: $blue;
            background-color: $white;
          }

          &.active:hover {
            &.hvr-overline-from-left:before {
              top: -2px;
            }
          }

          &.active {
            border-top: solid 2px $red;
            padding-top: 13px;
            padding-bottom: 13px;
          }
        }
      }
    }
  .enrich-login--success {
    .member {
      background: $enrich-neutral;
      color: $white;
    }
  }
}

.member--gold {
  #slide-nav {
    .enrich-login--success {
      .member {
        background: $enrich-gold;
        color: $white;
      }
    }
  }
}
.member--silver {
  #slide-nav {
    .enrich-login--success {
      .member {
        background: $enrich-silver;
        color: $white;
      }
    }
  }
}
.member--platinum {
  #slide-nav {
    .enrich-login--success {
      .member {
        background: $enrich-platinum;
        color: $white;
      }
    }
  }
}
.member--blue {
  #slide-nav {
    .enrich-login--success {
      .member {
        background: $enrich-blue;
        color: $white;
      }
    }
  }
}

.cug {
  #slide-nav {
    .enrich-login {
      display: none;
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  #mab-header {
    padding-left: calc((100% - 1200px - 20px - 20px) / 2);
    padding-right: calc((100% - 1200px - 20px - 20px) / 2);
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1441px) {
  #mab-header {
    $sidemargin: 120px;
    width: 100%;
    margin: 0 auto;
    max-width: calc(100% - #{$sidemargin} - #{$sidemargin});
    &.headroom--top {
      max-width: calc(100% - #{$sidemargin} - #{$sidemargin});
    }

    &.headroom--not-top {
      > .row {
        max-width: calc(100% - #{$sidemargin} - #{$sidemargin});
      }
    }
  }
  .slideout-open .slideout-panel {
    -webkit-filter: brightness(1);
  }
  .slideout-open .slideout-menu {
    display: none;
  }
  .slideout-panel.slideout-panel-right {
    // transform: translateX(0) !important;
  }
}
@include respond-to(tablet-portrait) {
  $mainnavheight: 53px;
  #mab-header {
    &.headroom--top {
      position: absolute
    }
    &.fixed {
      position: fixed;
      top:0;
      @include z-index(ibelinks);
      background: $white;
      width: 100%;
    }
      .col-lg-10 {
        width: 100%;
        float: left;
      }
      .col-lg-1 {
        width: 13%;
        float: left;
        &.search-ele {
          width: 9% !important;
        }
      }
    .navbar {
      &.navbar-default {
        padding: 0;
        a {
          &.enrich {
            padding: 14px 8px 14px 18px !important;
            img {
              border-right: 0;
              height: 20px;
            }
          }
        }
        .navbar-brand {
          &.MHLogo {
            margin-right: 0;
            padding-left: 0;
            width: auto;

            img {
              width: auto;
              height: 100%;
            }
          }
          &.EnrichLogo {
            margin-right: 0;
            padding-left: 0;
            width: auto;

            img {
              width: auto;
              height: 100%;
            }
          }
        }
        .navbar-nav {
          li {
            padding: 0;
            width: 97%;
            float: left;
          }
        }
      }
    }
  }
  #slidemenu {
    .nav {
      &.navbar-nav {
        float: left;
        li {
          &.booking-nav {
            border-bottom: 1px solid $white;
            background-color: $linkblue;
            a {
              color: $white;
              padding: 15px 20px;
              i {
                &.fa {
                  margin-right: 10px;
                }
              }
            }
          }
          border-bottom: 1px solid $lightgray;
          a {
            padding: 15px 20px !important;
          }
        }
      }
    }
    &.alert-exists {
        top: 0 !important;
      }
  }
  #slide-nav {
    border-top: 0;
    .container {
        margin: 0;
        padding: 0;
        &.close-container {
          width: 10%;
          float: right;
        }
    }
    .navbar-header {
        margin: 0 auto;
        padding: 2px 0 0;
        background: transparent;
        &.slide-active {
        opacity: 0.1;
        }
    }
    .navbar {
      &.slide-active {
          position: absolute;
          width: 80%;
          top: -1px;
          z-index: 1000;
      }
    }
    #slidemenu {
      background: #fff !important;
        left: 0%;
        width: 100%;
        min-width: 0;
        position: absolute;
        padding-left: 0;
        z-index: 25;
        top: -90px;
        margin: 0;
        overflow: hidden;
        .navbar-nav {
          min-width: 0;
          width: 100%;
          margin: 0 0 0 15px;
          .dropdown-menu {
            li {
              a {
                min-width: 0;
                width: 80%;
                white-space: normal;
              }
            }
          }
      }
    }
    &.navbar-default {
      .navbar-height-col,
      #navbar-height-col {
          background: $white;
          z-index: 23;
          border: 0;
      }
    }
    .navbar-height-col,
    #navbar-height-col {
        position: fixed;
        top: 0;
        height: 100%;
        width:73.8%;
        left: -81%;
        background: $white;
        border-right: 5px solid $blue !important;
    }
    .navbar-form {
        width: 100%;
        margin: 8px 0;
        text-align: center;
        overflow: hidden;
        .form-control {
          text-align: center;
      }
      .btn {
          width: 100%;
      }
    }
  }
  .enrich-login {
    img {
      display: block;
      margin: 0 auto;
    }
    a {
      width: 32.5%;
      margin-left: 20px;
    }
    .remember-my--id {
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  #enrichRegister, #enrichFindMore,
  .enrichRegister, .enrichFindMore {
    width: 49%;
  }
  #enrichRegister, .enrichRegister {
    margin-right: 5px;
  }
}

/* Dark site home page styles */
.home-dark {
  background-color: $gray;
  a {
    color: $gray;
  }
  picture{
    img {
      -webkit-filter: grayscale(100%)!important;
      filter: grayscale(100%)!important;
    }
    -webkit-filter: grayscale(100%)!important;
    filter: grayscale(100%)!important;
  }
  img {
    -webkit-filter: grayscale(100%)!important;
    filter: grayscale(100%)!important;
  }
  .cc-banner {
    background-color: $darkgray !important;
    .cc-btn {
      background-color: $gray;
      &:hover,
      &:focus,
      &:active {
        background-color: $gray !important;
      }
    }
  }
  .btn-primary {
    background: $gray !important;
    &:hover,
    &:focus,
    &:active,
    &:before {
      background: $darkgray !important;
    }
  }
  #main-content {
    background-color: $gray;
    .headroom--not-top {
      background: $darkgray; /* Old browsers */
      background: -moz-linear-gradient(left, #323333 0%, #82888e 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #323333 0%,#82888e 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #323333 0%,#82888e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12 */
    }
    #mab-bookingnav {
      background: $darkgray;
    }
    #alert-container {
      background-color: $gray;
      border: none;
      color: $white;
    }
    .container--main-header {
        background: #82888e;
        .header-wrapper {
          background: linear-gradient(90deg,#323333 0,#82888e);
        }
    }
    .ibe-navigation {
      background-color: $gray;
      #mab-bookingnav {
        background: #82888e;
        #bookingNav {
          .navbar-nav {
            li {
              &.selected {
                a {
                  color: #82888e;
                  border-top: 2px solid #82888e;
                  &:hover, &.active {
                    color: #82888e;
                    border-top: 2px solid #82888e;
                  }
                }
              }
              a {
                border-top: 2px solid #82888e;
                &:hover, &.active {
                  color: #82888e;
                  border-top: 2px solid #82888e;
                }
              }
            }
          }
        }
      }
    }
    .ibe-form--container {
      .trip-container {
        .trip-type--container {
          li {
            &.selected {
              a {
                color: $gray !important;
              }
            }
          }
        }
      }
      .checkin-list {
        li {
          &.selected {
            a {
              color: $gray!important;
            }
          }
        }
      }
      .search-container {
        .passenger-details {
          .total-passengers {
            color: $gray !important;
          }
          .fa-angle-down {
            color: $gray !important;
          }
        }
        .passenger-details-selected {
          border: 1px solid $midgray;
          .total-passengers {
            color: $gray !important;
          }
          .fa-angle-up {
            color: $gray !important;
          }
          .booking-person {
            button {
              background: $midgray;
              border: 1px solid $midgray;
              &:disabled {
                color: $gray !important;
              }
            }
            &.group-booking {
              a {
                color: $gray !important;
              }
            }
          }
        }
        .cabinClass {
          .selectize-input {
            .item {
              color: $gray !important;
            }
          }
        }
        .book-date--selector {
          &.returnTrip {
            .departureDate {
              color: $gray;
            }
            input {
              &.input-lg {
                color: $gray;
              }
            }
          }
        }
      }
      .couponcode-input {
        color: $gray;
        &:focus {
          border: 1px solid $gray;
        }
      }
      .selectize-input {
        input {
          color: $midgray;
        }
      }
      .redemption-login {
        input[type=text],
        input[type=number],
        input[type=password] {
          color: $midgray;
          &:focus {
            border: 1px solid $midgray;
          }
        }
      }
    }
  }
  .footer-container {
    h6 {
      color: $darkgray;
    }
    #back-to--top {
      a {
        color: $white;
        background: $gray;
      }
    }
    ul {
      li {
        a {
          color: $gray;
          &:hover, &:visited {
            color: $gray;
          }
        }
      }
    }
  }
  .hvr-underline-from-left-dashed{
    &:before {
      border-bottom: 1px dashed $gray;
    }
  }
  .selectize-control {
    .selectize-input {
      .item {
        color: $midgray!important;
        &:after {
          color: $midgray!important;
        }
      }
    }
    .selectize-dropdown {
      border: 1px solid $gray;
      .option {
        color: $gray;
      }
      .active {
        background-color: #666;
        color: $white;
      }
      .selectize-dropdown-content {
        &::-webkit-scrollbar-thumb {
          background: $midgray;
        }
      }
    }
    &.single {
      .selectize-input {
        &:after {
          color: $gray;
        }
      }
    }
    .airportcode {
      background: #777 !important;
    }
  }
  .selectize-input {
    &.input-active {
      &.dropdown-active {
        border: 1px solid $gray;
      }
    }
    .item {
      color: $midgray;
    }
    div {
      color: #333 !important;
    }
  }
  .ui-datepicker {
    .ui-datepicker-next-hover,
    .ui-datepicker-prev-hover {
      color: #666!important;
      background:none!important;
    }
    .ui-datepicker-title,
    .ui-datepicker-title-text {
      color: $darkgray;
    }
    .ui-datepicker-today {
      a {
        border-color: $darkgray!important;
      }
    }
    .ui-state-selected {
      background: #666!important;
    }
    .ui-datepicker-current-day {
      a {
        &.ui-state-active {
          background: #666!important;
        }
      }
    }
    a {
      &.ui-state-hover {
        background: #666;
      }
    }
    .ui-datepicker-buttonpane {
      .ui-datepicker-close {
        color: $midgray;
      }
    }
  }
  .mobileapp {
    border-bottom: 2px solid $white;
    background-color: $midgray;
    color: $white;
  }
  .form-control {
    color: $gray;
  }
  .seq {
    .seq-pagination {
      .slick-active {
        button {
          &:before {
            color: $lightgray;
          }
        }
      }
    }
  }
  .deals-popup {
    h3 {
      color: $gray;
    }
    .mfp-close {
      color: $gray;
    }
    .calendar-container {
      .departureDate,
      .returnDate {
        color: $gray;
      }
    }
    #passenger-details--deals {
      .booking-person {
        button {
          background: $gray;
          border: 1px solid $gray;
          &:disabled {
            background: $white;
            border: 1px solid $gray;
          }
        }
      }
    }
  }
  .btn-secondary {
    background: $white;
    &:hover,
    &:focus,
    &:active {
      background: $gray !important;
      color: white;
      border: 1px solid $gray;
      &:before {
        background: $gray !important;  
        -webkit-transform: scale(2);
        transform: scale(2);
      }
    }
    &.btn-disabled{
      background: $midgray;
    }
  }
  .newsletter-popup {
    .mfp-close {
      color: $gray;
    }
    h3{
      color: $gray;
    }
  }
  .mobilelocation-popup {
    .mfp-close {
      color: $gray;
    }
  }
  #slide-nav .login-nav li a:hover {   
    color:#82888e;
  }                  
}

/* Dark site landing page styles */
.dark-site-landing {
  background-color: #DFE2E7;
  .header {
    background: linear-gradient(90deg,#19286e 0,#005aaa);
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    height: 65px;
    .logo {
      float: left;
      margin-left: 20px;
      margin-top: 5px;
      img {
        height: 40px;
      }
      .navbar-brand {
        padding: 0;
        a {
          height: auto;
        }
      }
    }
    .header-links {
      float: right;
      padding-right:25px;
      padding-top: 10px;
      a {
        font-size: 12px;
        color: $white;
        &.disabled {
          padding: 15px 0;
        }
      }
    }
  }
  
  .page-title {
    color: $white;
    background-color:  $ibeblue;
    padding-left: 5px;
    .title {
      color: $white;
      font-size: 17px;
      padding: 15px;
      .titleicon {
        float: left;
        margin-right: 15px;
        img {
          width: 25px;
          height: 25px;
          outline: none;
        }
      }
    }
  }

  .carousel-indicators-numbers {
    li {
      text-indent: 0;
      margin: 0;
      width: 15px;
      height: 20px;
      border: none;
      line-height: 2em;
      color: $white;
      background-color: #999;
      transition: all 0.25s ease;
      border-radius: 0;
      font-size: 10px;
      text-align: center;
      &.active, &:hover {
        margin: 0;
        width: 15px;
        height: 20px;
        background-color: #337ab7;     
        border-radius: 0;
        font-size: 10px;
        text-align: center;
      }
    }
  }
  .left-wrapper {
    padding: 20px 30px 35px 30px;
    .main {
      background-color: #fff;
      border-right: 1px solid #ccc;
      padding: 0;
      .announcements {
        .carousel-indicators {
          bottom: 0;
          margin-bottom: -25px;
          right: 0;
          text-align: right;
          left: auto;
          padding-right: 0px;
        }
        .carousel-control {
          &.left, 
          &.right {
            background-image: none;
          }
        }
        .red-shade {
          width: 0px;
          height: 0px;
          border-left: 18px solid $red;
          border-bottom: 18px solid transparent;
          border-top: 18px solid transparent;
          transform: rotate(225deg);
          margin-top: -12px;
          margin-left: -3px;
          position: absolute;
          z-index: 1;
        }
        .carousel-inner {
          min-height: 400px;
          .item {
            color: #000;
            padding: 0px 40px 20px;
            .content {
              font-size:12px;
            }
            h1 {
              font-size: 17px;
              border-bottom: 1px solid #eee;
              color: #000;
              font-weight: bold;
              padding-bottom: 15px;
              margin: 0 0 15px;
              padding-top: 20px;
            }
          }
        }
      }
    }
  }
  
  .sidebar {
    padding: 0;
    border-left: 1px solid #ccc;
    .contact-info {
      color: $gray;
      border-bottom: 1px solid #ccc;
      padding: 20px 0 20px 25px;
      margin-right: 25px;
      &:last-child {
        border: none;
      }
      p {
        font-size: 12px;
      }
      h4 {
        margin: 0 0 10px;
      }
      .contact-phone {
        font-weight: bold;
        span {
          &.contact-label {
            color: $gray;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@include respond-to(1240) {
  #mab-header {
    .navbar {
      &.navbar-default {
        .navbar-nav {
          li {
            padding: 22px 14px 0;
          }
        }
      }
    }
  }
}
@include respond-to(more-than-1200) {
  #mab-header {
    .select-country, .search-ele {
      display: block;
    }
    .search-ele {
      display: block;
      width: 20px;
      float: right;
    }
  }
  // z-index fixes
  #mab-header {
    .select-country {
      @include z-index(selectcountry);
      width: 105px;
    }
  }
  // z-index fixes
  #slide-nav {
    #slidemenu {
      .navbar-nav {
        &.desktop-nav {
          display: block;
        }
        &.mobile-nav {
          display: none;
        }
      }
    }
  }
}
@include respond-to(mid-screen-768-1200) {
  .enrich-login {
    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 15px;
      width: 120px;
    }
    a {
      width: 32.5%;
      // margin-left: 20px;
      margin: 0;
    }
    .remember-my--id {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 1200px) {
  #mab-header {
    .logo-section {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
    .select-country, .search-ele {
      display: none;
    }
  }
  .navbar-header {
    width: 100%;
  }
  #slide-nav {
    #slidemenu {
      .navbar-nav {
        &.desktop-nav {
          display: none;
        }
        &.mobile-nav {
          display: block;
        }
      }
    }
  }

  #search-container {
    input[type="text"] {
      padding: 0;
    }
  }
  .search-icon {
    display: block;
    clear: both;
    padding: 15px 20px 0 20px !important;
    border-bottom: 1px solid $lightgray;
    .fa {
      position: absolute;
      z-index: 100;
      right: 4%;
      margin-top: 10px;
    }
    #mobile-search {
      position: relative;
      z-index: 99;
      padding-right: 45px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .search-container--mobile {
    display: none;
  }
  #search-container, .seperator {
    display: none !important;
  }
  #mab-bookingnav {
    .navbar {
      &.navbar-default {
        #bookingNav {
          .nav {
            &.navbar-nav {
              width: 100% !important;
            }
            &.login-nav {
              display: none;
            }
          }
        }
      }
    }
  }
  .navbar-toggle {
    display: block;
  }
  #slide-nav {
    .enrich-login {
      display: block;

      &.logged-in {
        display: none;
      }
    }
    .enrich-signup {
      display: block;

      &.logged-in {
        display: none;
      }
    }
    .enrich-login--success {
      display: none;

      &.logged-in {
        display: block;
      }
    }
  }
  .mab-nav--overlay {
    .navbar-nav {
      li {
        width: 100%;
      }
    }
    .navbar-header {
      margin: 0;
      width: 90%;
      padding: 15px 0 15px 20px;
    }

    .enrich-login--overlay {
      float: right;
      display: block !important;
      @include font-size(20);
      line-height: 0.2em;
      color: $blue;
      border-right: 1px solid $lightgray;
      margin-top: 25px;
      padding-right: 20px;
      margin-right: 15px;
      display: none;
      cursor: pointer;
    }
    .search-input {
      position: static;
    }
    .search-icon {
      background-color: $seashell;
      position: relative;
      z-index: 1;
      width: 100%;
      border-top: 1px solid $lightgray;
      > .form-group {
        position: relative;
      }
      .fa {
        position: absolute;
        right: 20px;
      }
      #mobile-search {
        width: 100%;
        &:focus {
          //margin-bottom: 30vh;
        }
      }
    }
    .close-slide {
      margin-top: 10px;
      width: 10%;
      text-align: left;
      padding-left: 6px;
      // padding: 5% 6% 2% 1%;

    }
    .enrich-login--overlay {
      margin-top: 16px;
    }
  }
}

@include respond-to(ipad-portrait) {
  #mab-header {
    .logo-section {
      padding-right: 25px;
    }
  }
  #slide-nav {
    .navbar-toggle {
      margin-top: 5px;
    }
    #bookingNav {
      .navbar-nav {
        li {
          a {
            @include font-size(14);
            padding: 15px 10px 13px;
          }
        }
      }
    }
  }
}

@include respond-to(767) {
  .container {
    &.container--main-header {
      min-height: 55px !important;
    }
  }
}

@include respond-to(600) {
  .mab-nav--overlay {
    // width: 100% !important;
  }
  .enrich-login {
    img {
      width: 120px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    h3 {
      margin-bottom: 5px;
    }
    a {
      width: 44%;
      @include font-size(13);
    }
  }
  #enrichRegister, #enrichFindMore,
  .enrichRegister, .enrichFindMore {
      width: 48%;
  }
}

@include respond-to(475) {
  #mab-header {
    .navbar {
      &.navbar-default {
        .navbar-brand {
          padding: 10px 15px 10px 0px;
          margin:0px;
          &.MHLogo {
            width: auto;
            margin-right: 20px;
            padding-right: 0px;
            margin-left: 0;
          }
          &.EnrichLogo {
            width: auto;
            margin-right: 20px;
            margin-left: 0;
            padding-right: 0px;
          }
          &.oneWorldLogo {
            padding-left: 0;
            margin: 0px;
            padding-top: 14px;
            img {
              width: 26px !important;
              height: 26px !important;
            }
          }
        }
      }
    }
  }
  .navbar-header {
      width: 100%;
      img {
        width: auto !important;
        height: 100% !important;
      }
  }
  #slide-nav {
    .navbar-toggle {
      margin-top: 5px;
      padding-top: 5px;
      @include font-size(20);
    }
    .enrich-login,.enrich-loggedin {
      padding-right: 10px;
      @include font-size(20);
      line-height: 0;
      padding-right: 20px;
      margin-top: 15px;
    }
    .enrich-signup {
      padding-right: 10px;
      @include font-size(20);
      line-height: 0;
      padding-right: 20px;
      margin-top: 15px;
    }
  }
}

@include respond-to(384){
  #slide-nav .enrich-login{
    padding-right: 0px;
  }
}
@include respond-to(400) {
  .enrich-login {
    a {
      @include font-size(14);
    }
  }
}

@include respond-to(360) {
  .enrich-login {
    a {
      @include font-size(13);
      width: 43%;
    }
  }
}

@include respond-to(320) {
  #mab-header {
    .navbar {
      &.navbar-default {
        .navbar-brand {
          padding-right: 0;
          padding-left: 0;
          &.MHLogo {
            width: 100px;
            margin-right: 0px;
            margin-left:0;
          }
          &.EnrichLogo {
            margin-left: 30px;
            margin-right: 10px;
          }
          &.oneWorldLogo {
            padding-left: 0;
            img {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
      }
    }
  }
  #slide-nav {
    .navbar-header {
      padding: 4px 0 0 0 !important;
    }
    .enrich-login {
      margin-top: 14px;
      padding: 0;
    }
  }
  .enrich-login {
    a {
      @include font-size(11);
      width: 42%;
    }
  }
}

#preloader {
   position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fefefe;
  z-index: 99999;
  height: 100%;
  display: none;
}

#status {
    margin-top: 25vh;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-position: center;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 250px;
  .msg {
    @include font-size(14);
    position: absolute;
    bottom: 20%;
  }
}
#mab-bookingnav
{
  .navbar-default {
    .login-nav li:nth-child(1){
    display:none;
  }
  }
}
.mobile-enrich-signup, .enrich-signup{
  display:none !important;
}

.hotel-flight{
vertical-align: baseline;
}

/* Fly malaysia*/
.fly-malaysia-header{
  .header-wrapper{
    background: white !important;
  }

  #mab-header .navbar.navbar-default .navbar-nav li a{
    color: #002b5c !important;
  }
  .fa-search,.selected-country--lang, #slide-nav .enrich-login, #slide-nav .navbar-toggle{
    color: #002b5c !important;
  }
  #mab-bookingnav{
    background-image: url(../images/desktop_nav_bg_1920x52.jpg);
  }
  .mobile-ibe{
    background-image: url(../images/mobile-nav-767x64.jpg);
  }
  #mab-bookingnav{
    background-color:#002b5c !important;
  }
  #main-content>.container.container--main-header {
    background-color:#002b5c !important;
  }
  .ibe-navigation{
    background-color: #fff !important;
  }
  #mab-header.headroom--not-top{
    background: white !important;
  }
}
/* refresh-header*/
.refresh-header{
   background-color: #F8F3ED !important ;
   .main-content{
      div :not(.newsletter-container, .topsixlinks){
    background-color:#F8F3ED !important;
  } 
}
  // .topsixlinks{
  //   background-image: url(../images/quicklink_bg_1788x137.png) !important;
  // }
  .newsletter-container{
    background-image: url(../images/newsletter1_bg_1788x320.png) !important;
  }
  .quick-links-center{
    background-image: url(../images/quicklink1_bg_1788x137.png) !important;
  }
   .white-bkg, .page-wrapper, .scriptexecutor, .signpost-container,.freeform{
    background: #F8F3ED !important;
  }
  .container--main-header{
    background-color: #CEAF87 !important;
  }
  .header-wrapper{
    background: white !important;
  }
  #mab-header .navbar.navbar-default .navbar-nav li a{
    color: #002b5c !important;
  }
  .fa-search,.selected-country--lang, #slide-nav .enrich-login, #slide-nav .navbar-toggle{
    color: #002b5c !important;
  }
  #mab-bookingnav{
    background-image: url(../images/desktop_nav_refresh_bg_1920x52.jpg);
  }
  .mobile-ibe{
    background-image: url(../images/mobile-nav-refresh767x64.jpg);
  }
  #mab-bookingnav{
    background-color: #CEAF87 !important;
  }
  #main-content>.container.container--main-header {
    background-color: #CEAF87 !important;
  }
  .ibe-navigation{
    background-color: #CEAF87 !important;
  }
  #mab-header.headroom--not-top{
    background: #fff !important;
  }

}
.new-item:before {   
  font-style: normal;
  padding: 0px 10px;
  background-color: #ed1c24;
  color: #fff;
  border-radius: 100px;  
  content: "NEW";
  position: absolute;
  left: -22px;
  font-size: 10px;
  top: 7px;
}
.mob-new-item{
    a:after {   
    font-style: normal;
    padding: 2px 10px;
    background-color: #ed1c24;
    color: #fff;
    border-radius: 100px;
    content: "NEW";    
    font-size: 9px;
    margin-left: 5px;
    vertical-align: super;
  }
}
#searchFlightHotel
{
  span.seperator
  {
    display: block !important;
  }
}

.refresh-header-inner{
 .header-wrapper{
   background: white !important;
 }
 #mab-header .navbar.navbar-default .navbar-nav li a{
   color: #002b5c !important;
 }
 .fa-search,.selected-country--lang, #slide-nav .enrich-login, #slide-nav .navbar-toggle{
   color: #002b5c !important;
 }
 #mab-bookingnav{
   background-color: #CEAF87 !important;
   background-image: url(../images/desktop_nav_refresh_bg_1920x52.jpg);
 }
 .ibe-navigation{
  background-color: #CEAF87 !important;
}
#main-content>.container.container--main-header {
  background-color: #CEAF87 !important;
}
#mab-header.headroom--not-top{
  background: #fff !important;
}
.mobile-ibe{
  background-image: url(../images/mobile-nav-refresh767x64.jpg);
}
} 