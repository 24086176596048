.partnerform{
  @media screen and (min-width: 768px)
      {
        .file-upload.col-lg-4 {
            width: 37%;
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and  (-webkit-min-device-pixel-ratio: 2) 
        {
          width: 350px;
        }
        }
      }
      .upload-idcard
      {
      .file-upload{

        @media screen and (max-width: 767px) 
        {
          span.group-span-filestyle
          {
            .btn
            {
              padding-left: 5px !important;
            }
          }
        } 
        .control-label.input-filled
        {
         margin-top: 0px !important;
        }
        }
      }
    .error-validation
    {
      padding-top: 0px !important;
    }
    .upload_msg
    {
        font-family: Gotham-Light,Arial;
        font-style: italic;
        margin-bottom: 5px;
        font-size: 1rem;
        @media screen and (max-width: 767px) 
                {
                  margin-bottom: 0px;
                  margin-top: 19px;
                } 
    }
  .headline{
        
         text-align: center;
             @media screen and(max-width: 320px)
             {
                font-size: 21px;
             } 
            @media screen and (max-width: 767px) 
            {
               font-size: 23px;
            } 
     
             }

    .q1{
        
    text-align: center;
    margin-top: -21px;
     }

    .q2{

      color: rgb(255,255,255);
    }

   .q3{

    border-bottom: 5.0px solid red ;height: 10.0px;margin-top: 5.0px;padding-top: 5.0px;

    }
  .error-validation{
     
       @media screen and (min-width: 1200px) {
         margin:0  ! important;
      } 
      @media screen and (max-width: 767px) {
            margin-top: 0px
            } 
    }
    .error-msgs{
      @media screen and (max-width: 767px) {
           padding-left: 23.5px!important
            } 
    }
    .login-error--validation{
      .fa-exclamation-triangle{
      content: "\f071";
      font-family: FontAwesome;     
      color: #ed1c24;
      margin-top: 3px;
      position: absolute;
      border-bottom: 1px solid #ed1c24;
      width: 100%;
      margin-left: 8px;
      @media screen and (max-width: 767px) {
            position: relative !important;
                margin-top: -23px;
                margin-left: 6px;
                margin-bottom: 27px;
            } 
        }
    }
  .errormessage
   {
       color: red;
       font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 15px;
        margin-top: -9%;
        margin-bottom: -4%;
      } 
   }

   
.mobbotspace{
  @media screen and (max-width: 767px) {
     margin-top: 11px;
      } 
}
.mobheader{
@media screen and (max-width: 767px) {
     font-size: 21px;
      } 
}
.partner-form--company-address{
   @media screen and (min-width: 768px) {
      width: 100%;
      }    
}
.partner--form-name{

    width: 70%;
}
.p{
  font-size: 20px;
}
.partner-form--termsandconditions{
    width: 40%;
}
.partner--form-termsconditions{
    margin-left: -186px;
}

.topcontent{
    text-align: center;
    margin-top:40px;
     @media screen and (min-width: 1200px) {
     font-size: 20px;
      } 
     @media screen and (max-width: 767px) {
     font-size: 14px;
      } 
}
.aboutcont{
    text-align: center;
    margin-top:1px;
     @media screen and (min-width: 1200px) {
     font-size: 20px;
      } 
     @media screen and (max-width: 767px) {
     margin-top: 1px;
     font-size: 17px;
      } 
}

.tcontent{

    margin-top:40px;
     @media screen and (min-width: 1200px) {
     font-size: 20px;
      } 
     @media screen and (max-width: 767px) {
     font-size: 14px;
      } 
      @include respond-to(ipad-portrait)
      {
         font-size: 14px;
      }
}
       
.topcontentheader{  

    @media screen and (min-width: 1200px) {
     margin-top: 53px; 
      } 
    text-align: center !important;
    color: #16b2d6;
     @media screen and (max-width: 767px) {
     font-size: 14px;
      } 
}
.topcontents{
    text-align: center;
    margin-top:20px;
     @media screen and (min-width: 1200px) {
     font-size: 20px;
      } 
       @media screen and (max-width: 767px) {
     font-size: 15px;
      } 
      @include respond-to(ipad-portrait)
      {
         font-size: 14px;
      }
}
 p{
    @include font-size(14);
    text-align: left;
    &.subtitle{
      @include font-size(12);
      line-height: 1em;
      margin: 3px 10px;
    }
  }

.partner-tandc{
    &.has-error{
      label{
        color: $errorred;
      }
    }
  }
.img{

	width: 80px;
	
	}
 
 .forms .forms-group {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
    margin-top: -35px;
}

.col-lg-6 {
    width: 27%;
}

.mand {
    color: #ed1c24;
}

.partner--form-BizConnect-info{
  display: inline-flex;
  @media screen and (min-width: 320px) and (max-width: 414px){
    display:block;
  }
  .checkbox{
    padding-right: 21px;
  }
  label {
    width: max-content
  }
}
.bizConnect-info{
  padding-top: 10px;
 }
.infotext-others{
  display: none;
}
}
.float-none{
  //@media screen and (max-width: 1200px) {
  float: none;
  //}
}
.contact-tracing-form{
  .mobbotspace{
    @media screen and (max-width: 767px) {
      margin-top: 11px;
       } 
  }

  .checkbox{
    span{
      margin-left:10px;
    }
  }
  .phoneinput__countrycode{
    .contact-tracing-form--phone-number-country-code{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .button-align{
    text-align: center;
  }

}
.contact-tracing-booking-data{
  position: relative;
  div{
    label{
     // margin-left:48px!important;
    }
  input{
    //padding-left:45px;
  }
  }
}
.spanMHDisp {
  display: none;
  color: #303030!important;
  text-align: left;
  margin-top: 24px;
  font-size: 12px;
  position: absolute;
  margin-left: 23px;
  z-index: 11;
}
.contact-tracing-form-thank-you{
  h2{
    text-align: center;
    font-size: 2.1rem;
    @media (max-width: 767px){
      font-size: 1.5rem;
    }
  }
}

.contact-forms .form .form-group.partner-state{
  display: none;
}