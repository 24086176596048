.enrich_register,
.enrich_forgotpw,
.enrich_forgotmhnum {
  &__title {
    text-align: center;
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      h1 {
        font-size: rem(36);
        line-height: rem(40);
      }
    }
  }

  &__content {
    margin-top: 30px;
  }

  .enrich-register-form,
  .enrich-forgotpw-form,
  .enrich-forgotmhnum-form {
    &--overview {
      margin: 0 auto;
      max-width: 900px;

      .title {
        color: $black;
        font-family: $font-family;
        font-weight: 400;
        margin-bottom: 0;
        font-size: rem(36);
        line-height: rem(40);
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
          font-size: rem(30);
          line-height: rem(36);
        }
      }

      .subtitle {
        color: $black;
        font-weight: 300;
        font-family: $font-family-light;
        font-size: rem(18);
        line-height: rem(24);
      }
    }
  }

  .form {
    margin: 0 auto;
    margin-top: 30px;

    .member-register--personal-contact-numbers {
      .phoneinput {
        margin-bottom: 10px;
      }
    }
    .login-details, .personal-details{
      legend{
        padding-top: 0;
      }
    }
  }

  .member-register--pref-comms {
    .label {
      font-family: $font-family-medium;
      font-size: rem(14);
      font-weight: 600;
    }
  }

  .member-register--accept-terms {
    margin-bottom: 30px;
  }

  .member-register--submit--add-business-address {
    margin-top: 20px;
    font-size: rem(14);

    i.fa {
      margin-right: 10px;
    }
  }

  .enrich-register-success,
  .enrich-forgotpw-success,
  .enrich-forgotmhnum-success {
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 40px;

    .title {
      margin-bottom: 30px;
    }

    .btn {
      width: auto;
      margin-top: 20px;
    }
  }

  .enrich-register-success {
    .temporary-card {
      margin: 40px 0;
    }

    .membership-number {
      font-family: $font-family-medium;
      font-weight: 600;
    }
  }

  .enrich-forgotpw--note,
  .enrich-forgotmhnum--note {
    margin-top: 15px;
    margin-bottom: 40px;
  }
}
.student-sucess-content{
  margin-top: 10vh;
  margin-bottom: 20vh;
  font-size: 20px;
  color:green;
}
.student-dashboard{
  width:100%;
 // height:9vh;
}
.student-disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: default;
}
.passengerlist-disabled{
  pointer-events: none;
  cursor: default;
}
.student-card-image {
  .card--username{
  color:white !important;
  }
  .card--number{
    color:white !important;
    }
}
.student-enrollment-msg {
  margin-top:20px;
}
.student-enrollment-component
{
    .ref_heading
    {
      margin-bottom:0 !important;
      margin-top:0 !important;
      font-style: italic;
      a{
        margin-left: 0px;
        margin-right: 6px;
        text-decoration: underline;
        color: black;
      }
    }
    .ref_headings
    {
      font-style: italic;
      margin-bottom:0 !important;
    }

    .ref_mhexplor_id{
      padding-left: 45px !important;
    }

    .ref_MHdiv{
      position: relative;
      z-index: 9;
    }
    .ref_spanMH{
    color: #303030 !important;
    text-align: left;
    margin-top: 25px;
    font-size: 12px;
    position: absolute;
    margin-left: 23px;
    }
  .subtitle_enrichid{
  color: #000;
  font-weight: 300;
  font-family: Gotham-Light,Arial;
  margin-bottom: 0 !important;
  margin-top: 20px;
  @media screen and (max-width: 767px)
      {
        .form-validate
        {
          margin-bottom: 0 !important;
        }
      }
  }
    #claim-others-submit
    {
      width: 50%;
      @media screen and (max-width: 767px)
      {
        width: 100%;
      }
    }
    .info-source {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 5px;
    }
    .subtitle_school-location {
      margin-top: 20px;
    }
 }
.mhexplorer-enrollment-page {
  .header-bottom {
    height: 40px;
  }
  #mab-bookingnav {
    border-radius: 30px 0 0 0;
  }
  .header-wrapper {
    border-radius: 0 0 30px 0 !important;
  }
  .bg-enrollment {
    background: url('/content/dam/mas/mhexplorer/signuppage/signup_banner.png') no-repeat top;
    background-size: cover;
    background-color: #fff;
    height: 100%;
    min-height: 720px;      
    max-width: 100%;
    @include respond-to(tablet-portrait) {      
      padding: 0px;
    }
    margin: 0;
    width: 100%;
  }
  .enrollment-container {    
    margin-left: 75px;
    max-width: 950px;
    background-color: #fff;
    margin-top: 10px;    
    padding: 20px 50px 20px 50px;
    @media screen and (min-width: 1440px) {
      margin-left: 120px;
    }
    @media screen and (min-width: 2560px) {   
      margin-left: 220px;
    }
    @include respond-to(tablet-portrait) {
      margin: 0;
      padding: 20px 20px;
    }
    @include respond-to(ipad-portrait) {
      margin-left: 0px;
    }
    @include respond-to(ipad-landscape) {
      margin-left: 0px;
    }
    .file-upload {
      padding-left: 10px;
    }
    .login-component{
      a{        
        &.btn{
          width: 49.7%;
        }
      }
    }
    .referral-padding {
      padding-left: 5px;
    }
    .logo {
      height: 40px;
    }

  }
  .student-sucess-content {    
    font-size: 1rem;
    color: #000;
    .back-home {
      padding-left: 0px;
      margin-top: 30px;
    }
    .mhe-rate {
      float: left;
      height: 46px;
      padding: 0 10px;
      padding-left: 0px;
      &:not(:checked) {
        >input {
          position: absolute;
          visibility: hidden;
        }
        >label {
          float: right;
          width: 1em;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          font-size: 50px;
          color: #ccc;
          line-height: 1em;
          &:before {
            content: '★ ';
          }
          &:hover {
            color: $blue;
            ~ {
              label {
                color: $blue;
              }
            }
          }
        }
      }
      >input {
        &:checked {
          ~ {
            label {
              color: $ibeblue;
              &:hover {
                color: $focusblue;
                ~ {
                  label {
                    color: $focusblue;
                  }
                }
              }
            }
          }
          + {
            label {
              &:hover {
                color: $focusblue;
                ~ {
                  label {
                    color: $focusblue;
                  }
                }
              }
            }
          }
        }
      }
      >label {
        &:hover {
          ~ {
            input {
              &:checked {
                ~ {
                  label {
                    color: $focusblue;
                  }
                }
              }
            }
          }
        }
      }
    }
    .rating-section, .rating-comments {
      padding-left: 0px;
      margin-top: 20px;
    }
    .rating-comments {
      .form-item {
        margin-top: 10px;
        textarea {
          @include border-radius(10px);
        }
        .label-comment {         
          font-style: italic;
        }
      }
    }
    .rating-section {
      .label-rating {        
        font-style: italic;
      }
    }
    .col-xs-12 {
      padding-right: 0px;
    }
    
  }
  .btn-primary {
    border-radius: 10px 10px 10px 10px;
  }
  input {
  border-radius: 10px 10px 10px 10px !important;
  }
  .info-source-select, .school-location-select {
    .selectize-control{	
        border-radius: 10px;
        .selectize-input {			
          border-radius: 10px;		
          &.input-active {
            &.dropdown-active {			
              border-radius: 10px 10px 0 0;					
            }
          }	
          .item {
            padding-bottom: 5px;
          }		
        }
        .selectize-dropdown {		
          border-radius: 0 0 10px 10px;	
      }	
    }
    label {
      &.input-filled {
        display: none;
      }
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5){
    /* ipad pro */
    .col-sm-6 {
      width: 50%;
  }
}
}