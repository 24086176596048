.smartalerts-container {
    padding-left: 0px;
    padding-right: 0px;
    z-index: 1200;
    .smartalerts-form--container {  
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background: #1d3f74;
        text-align: center;
        cursor: pointer;
        position: fixed;
        -webkit-transition: visibility 0s,opacity .2s linear;
        transition: visibility 0s,opacity .2s linear;  
        border-radius: 0;
    }
    @media only screen and (min-width: 480px) {
        .smartalerts-form--container {
            left: 0;
            border-radius: 0 5px 5px 0;
            width: 80px;
            height: 90px;
            margin: 0;
            -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            background: #1d3f74;
            position: fixed;
            top: 80%;
            text-align: center;
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 479px) {
      .smartalerts-form--container {
        position: fixed;
        height: 61px;    
        -webkit-transform: rotate(-90deg);   
        transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        text-align: center;
        right: -49px;
        top: 399px;
        border: 3px solid #1d3f74;
        padding: 2px 5px;       
      }
  }
    .smartalerts-button-wrapper {
        height: inherit;
        width: inherit;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        @media only screen and (max-width: 479px) {
          display: block;
        }
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }   
    @media only screen and (min-width: 480px) {
        .smartalerts-button-wrapper {
            -webkit-transform: rotate(0);
            transform: rotate(0);
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }
   
    .smartalert-button {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background: inherit;
        padding: 0 0;
        margin: 0 5px 0 0;
        border: none;
        height: auto;
        width: auto;
        white-space: normal;
        line-height: normal;
        text-transform: none;
        cursor: pointer;
        @media only screen and (max-width: 479px) {
          border: 3px solid #1d3f74;
        }
    }
    @media only screen and (min-width: 480px) {
        .smartalert-button {
            margin-bottom: 5px;
        }
    }
    .bell-icon {
        width: 25px;
        height: 20px;
        background-repeat: no-repeat;
        vertical-align: middle;
        -ms-flex-item-align: center;
        align-self: center;
    }
  
}
#smart-alerts-popup {
    position: relative;
    background: $white;
    padding: 20px;
    width: 500px;
    margin: 0 auto;     
    @include respond-to(1100) {
        width: auto !important;
    }  
   
    h3 {
      color: $blue;
      border-bottom: 1px solid $lightgray;
      line-height: rem(20);
      padding-bottom: 15px;
    }
    p{
      margin-bottom: 20px;
    }   
    .col-lg-12{
        &.smartalertsOrigin, &.smartalertsDestination {         
          .selectize-control{
            .airportname {
                font-weight: bold;
              }
            
              .airportcode {
                float: right; 
                display: block; 
                background-color: $airportCode; 
                font-weight: bold; 
                color: $white; 
                border-radius: 3px; 
                padding: 4px 6px 2px 6px;
                width: 50px;
                text-align: center;
                margin-top: 0px;
                @include respond-to(tablet-portrait) {
                  margin-top: 6px;
                }
              }
              .selectize-input {
                div {
                    color: $blue;                    
                  }  
              }
          }
        }
      }
    .mfp-close {
      color: $blue;
      opacity: 1;
      @include font-size(32);
      top: 6px;
      right: 8px;
      cursor: pointer;
    }
    .booking-form--title {
      @include font-size(14);
      @include z-index(bookformtitle);
      position: absolute;
      margin-left: 24px;
      margin-top: 14px;
      color: $darkgray;
      transition: all 90ms linear;
      &.input-filled {
        margin-top: 6px;
        margin-left: 22px;
        @include font-size(12);
      }
      &.has-error {
        color: $errorred;
      }
    }
   
    .smartalerts-error--validation {
      border-bottom: 1px solid $errorred;
      margin: 0 15px 15px;
      padding: 10px 0;
      display: none;
      width: 100%;
      color: $errorred;
      .fa-times-circle {
        color: $errorred;
        @include font-size(18);
        float: left;
      }
      .col-lg-1 {
        padding-right: 0;
        width: 5%;
        @include respond-to(tablet-portrait) {
          float: left;
          padding-left: 0;
        }
      }
      .col-lg-11 {
        padding-left: 0;
        @include respond-to(tablet-portrait) {
          padding-left: 25px;
        }
      }
      .error-msgs {
        @include font-size(14);
        color: $gray;
      }
      #result {
        @include font-size(14);
        color: $gray;
      }
      #smartalertsWidget-validator {
        margin: 2px 0 0 6px;
        @include font-size(14);
      }
    }
}