
.qwikcilver--record-style{
    text-align: left;
}
@media  screen and(min-width:768px){
    .contact-forms {
        .qwikcilverForm{
            .btn-contact--submit{
                width: 20%;
                min-width: 0;
            }
        }
    }
}
@media screen and(max-width:768px){
    .contact-forms {
        .qwikcilverForm{
            .search-panel-name{
                .qwikcilver--spacing{
                    margin-bottom: 15px !important;
                }
            }    
        }    
    }  
    .contact-forms {
        .qwikcilverForm{  
            .qwikcilver--btn-style{
                padding-bottom: 15px !important ;
            }
        }
    }
}