.gst-container{		
	display: block;
	width: 100%;
	background: $white;
	color: $gray;
	.gst-form--container{
		margin: 0 9%;
  	width: 82%;
  	.row{
  		margin-bottom: 15px;
  		&.margin-less{
  			margin: 0px;
  		}
  		.rules{
  			margin-top: 10px;
  			p{
  				margin-bottom: 10px;
  			}
  		}
  	}
		h1{
			//color: $blue;
			text-transform: initial;
			text-align: center;
			margin-top: 20px;
			margin-bottom: 0;
		}
		h4{
			font-family: 'MalaysiaAirlines-Medium';
			color: $gray;
			text-transform: initial;
			margin-bottom: 5px;
		}
		p{
			color: $gray;
			//font-size: 1.325em;
			margin-bottom: 0;
		}
		a{
			color: #009AE5;
		}
		.gst-content{
			p{
				margin: 0 0 10px 0;
			}
		}
		input[type='text']{
			border-radius: 50px;
			-webkit-border-radius: 50px;
			border: 1px solid #CCCCCC;
			padding: 13px 20px;
			@include font-size(14);
			width: 100%;
			color: $blue;
			&.input-focused{
				padding: 21px 20px 5px 25px;
			}
			&.has-error{
				border: 1px solid $errorred;
			}
			&:focus{
				border: 1px solid $focusblue;
				box-shadow: none;
			}
		}
		.col-lg-1{
			width: 12%;
			margin: 0;
			padding: 0 0 0 15px;
      @include respond-to(tablet-portrait){
        width: 45%;
      }
      @include respond-to(ipad-portrait){
        width: 48%;
        margin-right: 50%;
      }
		}
		.col-lg-5{
			width: 41.5%;
			padding: 0 0 0 15px;
			&.gst-lastname{
				margin: 0 0 15px 15px;
		    padding: 0;
		    width: 41.5%;
		    float: left;
        .form-title{
          &.input-filled{
            left: 0;
          }
        }
        @include respond-to(ipad-portrait){
          width: 44%;
        }
        @include respond-to(tablet-portrait){
          width: 86% !important;
        }
			}
			&.gst-ticketno{
				width: 43%;
        @include respond-to(ipad-portrait){
          width: 46%;
        }
			}
      @include respond-to(tablet-portrait){
        display: inline-block;
        width: 90% !important;
        float: left;
        &.gst-PNR{
          margin-bottom: 15px !important;
        }
        &.gst-ticketno {
          width: 88% !important;
          margin-bottom: 0 !important;
        }
      }
      @include respond-to(ipad-portrait){
        width: 48%;
      }
      @include respond-to(tablet-portrait){
        width: 88% !important;
      }
		}

    @include respond-to(ipad-portrait){
      .col-lg-6{
        padding-right: 0;
        width: 49%;
      }
    }

		.form-validate{
			margin-bottom: 15px;
		}
		.col-lg-1.remove-passenger{
			margin: 16px 0 0 0;
	    padding: 0;
	    width: 3%;
	    text-align: center;
	    float: left;
	    .fa{
	    	@include font-size(18);
	    	color: $white;
	    }
      @include respond-to(ipad-portrait){
        width: 5%;
        margin: 14px 0 0 0;
      }
      @include respond-to(tablet-portrait){
        width: 8% !important;
        margin: 14px 0 0 0;
      }
		}
		#add-more--names{
			.row-names{
				margin-bottom: 15px;
				clear: both;
				display: inline-block;
				width: 100%;
				height: auto;
 				.col-lg-6{
 					&.campaign-lastname{
 						width: 46%;
 						padding-right: 0;
 					}
 				}
        @include respond-to(tablet-portrait){
          border-top: 1px solid $white;
          padding-top: 15px;
        }
			}
      @include respond-to(ipad-portrait){
        .row-names{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
		}
		#addMoreNames{
		    width: 38%;
		    border-radius: 50px;
		    background: $blue;
		    color: $white;
		    text-transform: initial;
		    padding: 8px 0;
		    border: 0;
		    display: block;
		    margin: 0 auto;
        @include respond-to(tablet-portrait){
          width: 70%;
        }
		    .fa{
		    	margin-right: 10px;
		    }
		}
		#gstConsent, #gstTandC, #gstNlSubscribe{
			float: left;
			height: 18px;
			&.has-error{
				border: 1px solid $errorred;
			}
		}
		.gst-consent, .gst-tandc, .gst-nlSubscribe{
			width: 100%;
			float: left;
			clear: both;
			margin: 10px 0 0;
		}
		.form-title{
			@include font-size(14);
      @include z-index(bookformtitle);
      position: absolute;
      margin-left: 24px;
      margin-top: 14px;
      color: $darkgray;
      -webkit-transition: all 90ms linear;
      -o-transition: all 90ms linear;
      transition: all 90ms linear;
      font-weight: normal;
      font-family: $font-family;
			&.input-filled{
				@include font-size(12);
        top: -5px;
        left: 16px;
			}
			&.has-error{
				color: $errorred;
			}
			.mand{
				color: $errorred;
			}
		}
		.questions{
			font-size: 1.3em;
			margin-bottom: 5px;
			&.has-error{
				color: $errorred;
			}
		}
		.radio-input{
			width: 100%;
			clear: both;
		}
		.gst-state{
			.selectize-control{
				.selectize-input{
					&.select-filled{
						padding: 22px 20px 5px 25px;
					}
					color: $blue;
				}
			}
		}
		a{
			&#gst-btn{
				border-radius: 50px;
				-webkit-border-radius: 50px;
				background: $blue;
		    color: $white;
				width: 20%;
				text-transform: initial;
				@include font-size(14);
				padding: 12px 20px 14px;
				border: 0;
				display: block;
				margin: 0 auto;
        @include respond-to(tablet-portrait){
          width: 100%;
        }
        @include respond-to(ipad-portrait){
          width: 100%;
        }
			}
		}
		.gst-error--validation{
			border: 1px solid $errorred;
			margin: 0 1.5% 1.5% 0;
			padding: 10px 0;
			display: none;
			width: 100%;
			.fa {
				color: $errorred;
				@include font-size(18);
				float: left;
				line-height: 1.2em;
			}
			.col-lg-1{
				padding-right: 5px;
				width: auto;
        margin-top: 5px;
			}
			.col-lg-11{
				padding-left: 0;
			}
			.error-msgs{
				span{
					@include font-size(14);
					color: $gray;
					display: none;
				}
			}
			#result{
				@include font-size(14);
				color: $gray;
			}
			#gst-validator{
				margin: 2px 0 0 6px;
        ul{
          li{
            @include font-size(14);
          }
        }
			}
		}
		.form-success, .form-fail{
			display: none;
			background: $white;
			color: $blue;
			margin: 0 0 2%;
			.confirm-header{
				padding: 10% 30px;
				.success-txt{
					@include font-size(14);
					margin-left: 10px;
				}
				h3{
					text-align: center;
					color: $blue;
					text-transform: none;
					@include font-size(32);
				}
				p{
					clear: both;
					margin-top: 10px;
					color: $blue;
					text-align: center;
				}
				a{
					color: $linkblue;
				}
			}
		}
	}
	.gst-form--content{
		width: 98%;
		background: $countdownblue;
		padding: 10px 30px 20px;
    @include respond-to(tablet-portrait){
      padding: 10px 20px 20px;
    }
		h4, h3, p, label{
			color: $white;
      font-family: $font-family-medium;
      @include font-size(16);
		}
		.tooltip-title {
			color: $white;
			font-size: 11px;
			padding: 10px;
		}
		.tooltip-row {
			margin-bottom: 0px;
		}
		.info-icon {
			font-size: 18px;
			color: $white;
			padding-left: 5px;
			margin-top: 16px;
		}
		.gst-gstid {
			padding-right: 0px;
			width: 48%;
			@include respond-to(ipad-portrait){
				width: 48%;
			}
			@include respond-to(tablet-portrait){
				width: 90%;
				display:	inline-block;
				float: left;
			}
		}	
		.gst-infoicon {
			padding-left: 0px;
			width: 1%;
			float: left;
		}	
	}
}

.tooltip-content {	
	font-family:	$font-family;
	color: $countdownblue;
	font-size: 12px;
	opacity:1 !important;
	background: $white !important;
	padding: 10px;
  position: absolute;
  z-index: 600 !important;
  max-width: 300px; 
	border: 1px solid #ddd;
	@include border-radius(4px);
}

.ui-helper-hidden-accessible {
	border:	0;
	clip:	rect(0 0 0 0);
	height:	1px;
	margin:	-1px;
	overflow:	hidden;
	padding:	0;
	position:	absolute;
	width:	1px;
}

.instant_reward_content{
  border: 1px solid #666;
  margin: 20px auto;
  .welcome_title{
    font-size: 24px;
    text-align: center;
    margin: 2% 0;
    color: #333;
  }
}
.ir_form_container{
  .row_container{
    text-align: center;
    margin-bottom: 20px;
    #report_submit{
      background: $linkblue !important;
      color: $white;
      padding: 15px 20px 15px;
      @include font-size(14);
      line-height: 1rem;
      text-transform: initial;
      border: 0;
      display: inline-block;
      vertical-align: middle;
      box-shadow: 0 0 1px transparent;
      position: relative;
      overflow: hidden;
      @include border-radius(50px);
    }
  }
}