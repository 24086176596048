.icon-text--container{
  .icon-text{
    height: auto;
    overflow:hidden;
    margin-bottom: 15px;
    .method {
      $heading-height: rem(32);
      width: 100%;
      margin-right: 10px;
      margin-bottom: 40px;
      @include respond-to(767) {
        margin-bottom: 20px;
        width: 100%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
      &-heading,
      &-content {
        float: left;
        width: 100%;
      }

      &-icon {
        width: $heading-height;
        height: $heading-height;
        overflow: hidden;
        border-radius: 50%;
        background-color: $blue;
        position: relative;
        float: left;
        margin-right: 10px;

        .fa {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          font-size: rem(14);
          line-height: 1;
          color: $white;
        }
      }

      &-title {
        width: calc(100% - #{$heading-height} - 10px);
        float: left;
        p{
          margin-bottom: 0;
          line-height: 1em;
        }
        h3 {
          margin: 0;
          font-size: #{$heading-height / 2};
          line-height: $heading-height;
          font-family: $font-family-medium;
          color: $gray;
          line-height: 1.3em;
          @include respond-to(ipad-portrait){
            font-size: rem(16) !important;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
