/**
 * mholidays
 * - index
 */

 .mholidays {
   #mholidays-content {
    background-color: $white;
    .mholidays-container {
      width:100%!important;
    }
    #tour{
      .passenger-details-selected {
        width: 95%;
      }
      .travel-input {
        select {
          width:unset;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .rooms-wrapper{
        display: block!important;
      }
      .rooms{
        width: unset!important;
        display: flex;
        height: 40px!important;
    }
    .rooms .control-label{
      width: 155px;
    }
    .selectlist_child{      
      width: 50%!important;
    }
    }
    .selectlist_child{      
      width: 30%;
      float: left;
      padding-bottom: 10px;
    }
    .rooms-wrapper{
      display: flex;
    }
    .rooms{
      height: fit-content;
      width: 32%;
  }
  .rooms #adults_count{
    width: auto;
  }
  .close{
    color:  #13adc5;
    font-size: 1rem;
    opacity: .6;
  }
  .btn-default.ticket-nos-btn{
    color: #fff;
    background-color: #13adc5;
    border-color: #13adc5;
    padding: 0px 5px;
  }
 .form-control.passengersCount{
  border: 0;
  box-shadow: none;
  width: 100%;
  padding: 2px 0 0;
  margin: 0;
  float: left;
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Gotham-Medium,Arial;
  height: 23px;
  margin-right: 5px;
  background: #fff;
 }
  }
  &.mhZeroRightPad {
    padding-right:0!important;
  }

  /**/
  .quicklinks-container {
    .container {
      padding: 0;
      width: 100%;
      position: relative;
    }
  }
  .quick-links--container {
    padding: 70px 6% 40px!important;
  }
  .video {
    .container {
      padding:0!important;
      width: 100%;
    }
  }
  .adbanner {
    .container {
      padding:0!important;
      width: 100%;
    }
  }
  .signpostcontainer {
    .container {
      padding:0!important;
      width: 100%;
    }
    .signpost-container {
      padding: 20px 8%!important;
      width: 100%!important;
      .col-fixed {
        width: calc(33.333% - 20px) !important;
      }
    }
  }
  .imagerichtext {
    .container {
      padding:0!important;
      width: 100%;
    }
    .page-wrapper {
      padding: 0 8%!important;
      background: #fff;
      width: 100%!important;
      .container {
        width: 100%!important;
      }
    }
  }
  .newsletterSignUp {
    .container {
      padding:0!important;
      width: 100%!important;
    }
    .btn-primary {
      &:hover {
        &:before {
          transform: scale(1) !important;
        }
      }
    }
  }
  .masthead {
    .container {
      padding:0!important;
      width: 100%!important;
    }
  }
  /**/

  @media screen and (max-width: 767px) {
    .slideout-menu {
      width: 100%!important;
    }
  }
  /* Date Picker */
  .ui-datepicker {
    border-radius:2px!important;
    .ui-datepicker-buttonpane {
      top:auto!important;
      // bottom:0!important;
      .ui-datepicker-close {
        right:5px;
        .fa-times {
          font-size:20px;
          color:$holidays-form-field-selected-val;
        }
      }
    }
    .ui-datepicker-buttonpane.close_transfer
    {
      top: 0px !important;
      height: 30px;
    }
    .ui-datepicker-title {
      margin-top: 38px;
    }
    .ui-datepicker-next {
      margin-top: 15px;
    }
    .ui-datepicker-prev {
      margin-top: 15px;
    }
  }
 }
 #mholidays-content {
  background-color: $white;
  .mholidays-container {
    width:100%!important;
  }
}