#member-myadd-traveller
 { 
	.custom-file-input{
        display: inline-block;
		//overflow: hidden;
		position: relative;
    }
    .custom-file-input input[type="file"]{
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        zoom: 1;  /* Fix for IE7 */
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
	}
	.info{
		font-size: 13px !important;
		font-family: Gotham-Book,Arial !important;
		color: #14467B !important;
	}
	.hideClass
	{
		display: none;
	}
	.showClass
	{
		display: block;
	}
	.check-box--section{
		// margin-right: 5px !important;
		float: right;
		@media screen and (max-width: 414px) 
		{
			margin-top: -52px;
			margin-right: 26px !important;
		}
		@media screen and (min-width: 415px) and (max-width: 767px) 
		{
			margin-top: -52px;			
			margin-right: 15px !important;
		}
		@media screen and (min-width: 768px) and (max-width: 1023px) 
		{			
			margin-right: 15px !important;
		}
		// @media screen and (min-width: 321px) and(max-width: 413px) 
		// {
		// 	margin-top: -30px;
		// 	margin-right: 26px !important;
		// }
		// @media screen and (min-width: 414px) and (max-width: 567px) 
		// {
		// 	margin-top: -30px;
		// 	margin-right: 26px !important;
		// }
		// @media screen and (min-width: 568px) and (max-width: 639px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 460px !important;
		// }
		// @media screen and (min-width: 640px) and (max-width: 666px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 525px !important;
		// }
		// @media screen and (min-width: 667px) and (max-width: 735px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 555px !important;
		// }
		// @media screen and (min-width: 736px) and (max-width: 767px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 625px !important;
		// }
		// @media screen and (min-width: 768px) and (max-width: 1024px) 
		// {
		// 	//margin-top: -30px;
		// 	//margin-left: 430px !important;
		// }
		
	}
	.nric--IC--number--error,.file-upload--image--error,.file-upload--size--error{
		color: #ed1c24;
		font-size: 14px !important;
	}
	.nric--detail--info, .gts--used--info{
		color: #ed1c24;
		font-size: 14px !important;
		margin-left: 20px;
	}
	.gts-used-info-main {
		display: flex;
	}
	.gts-used-info-section1 {
		float: left;
		margin-right: 5px;
	}
	.gts-used-icon {
		font-size: 18px;
	}
	.gts-used-info-line1 {
		font-weight: bold;
	}
	.select--checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	  }
	  /* Hide the browser's default checkbox */
.select--checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  /* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #ccc;
	border-radius: 10px;
	cursor: pointer;
  }
  /* On mouse-over, add a grey background color */
.select--checkbox:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .select--checkbox input:checked ~ .checkmark {
	background-color: #46a049;
  }
  
  /* Create the checkmark/indicator*/
  .checkmark:after {
	content: "";
	position: absolute;	
  }
  
  

  /* Style the checkmark/indicator */
  .select--checkbox .checkmark:after {	
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	height: 5px;
	left: 5px;
	top: 6px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	width: 10px;
  }
  

				.col-lg-12 {
				padding-left: 0px !important;
				}
				.mytravellererror_message{
				color: red;
				font: normal normal 14px/16px Gotham,Arial;
				margin-left: 12px;
				margin-bottom: 10px;
				}
				h1{
					position: relative;
					margin: 0;
					font-family: $font-family;
					color: $blue;
					line-height: initial;
					margin-bottom: 20px;
					font-size: 2.25rem;
				@media screen and (max-width: 767px) {
						text-align: center;
					  }
				}
				.tagline{
				color: #14467B;
				font: normal normal  14px/16px Gotham,Arial;	
				}
				.traveller_list{
					float: right;
    				margin-right: 21px;
				}
				.tag-lineno
				{
				// padding-left: 455px;
				font: normal bold 21px/23px Gotham,arial;
				color: #14467b;	
					// @media screen and (min-width: 320px) and (max-width: 600px) 
					// {
					// padding-left: 34px;
					// font:normal 700 18px/16px Gotham,arial;
					// } 
					// @media screen and (min-width: 360px) and (max-width: 640px)
					// {
					// padding-left: 74px;
					// } 
					// @media screen and (min-width: 375px) and (max-width: 667px) 
					// {
					// padding-left: 87px;
					// }  
					// @media screen and (min-width: 411px) and (max-width: 731px) 
					// {
					// padding-left: 122px; 
					// }
					// @media screen and (min-width: 414px) and (max-width: 736px) 
					// {
					// padding-left: 128px;
					// }
				}

				.tag-line{
				// margin-left: -4px;
				font: normal bold 21px/23px Gotham,arial;
				color: #14467B;
				}
				.btntop 
				{
				@media screen and (max-width: 767px) 
				{
				width: 100%;
				} 
					margin-top: 30px !important;
					border-radius: 10px;
					width: 220px;
					height: 46px;
					.addtravell{
					margin-left: 10px;
					}
				}	
		.member-profile-edit
			{
				.member-edit-travel-coordinator--submit
				{
				margin-left: 158px;
				@media screen and (max-width: 767px) 
				{
					margin-left: 20px;
				} 
				}
				.datepicker-icon{
					pointer-events: none;
					position: absolute;
					text-align: center;
					right: 20px;
					top: 12.5px;
					z-index: 2;
					color: #666;
				}
				.edit-profile--name{
				margin-top: 10px;
				}
				.tag{
					color: #006DBB;
					margin-left: 10px;
					font: normal normal 14px/16px Gotham,Arial;
				}
				.edit{
					margin-top: -38px;
					font-weight: 600;
					font-family: Gotham-Medium,Arial;
					margin-left: 10px;
					@media screen and (max-width: 768px) {
					margin-left: 71px;
					margin-bottom: 20px;
				}
				}
				.circle-travel{

					padding-top: 6px;
					margin-left: -32px;
					border-radius: 15px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;	
					text-align: center;
				@media screen and (max-width: 768px) {
				margin-left: 21px;
				border-radius: 17px
				}
				}
				.fieldset
				{
				margin-left: 90px !important;
				@media screen and (max-width: 768px) {
				margin-left: 0px !important;
				}
				}

				.add-traveller-first-name
				{
				@media screen and (max-width: 768px) {
				margin-bottom: 14px;
				}
				}
				label{
					position: absolute !important;
					margin-top: -10px !important;
					background-color: white !important;
					margin-left: 20px !important;
					padding-left: 4px !important;
					padding-right: 4px !important;
				}
				select{
				border-radius: 10px 10px 10px 10px;
				}
				.selectize-input{
				border-radius: 10px 10px 10px 10px;
				}
				input{
				border-radius: 10px 10px 10px 10px;
				}
				.distance-margin
				{
				margin-top: 15px;
				}
				.btn-secondary{
					margin-left: 10px;
					border-radius: 10px 10px 10px 10px;
				.addBlueBkg {

				background: white !important;

				}
				}
				.btn-member--cancel{
					min-width: 146px;
					width: auto;
				}
				.btn-primary{
					margin-left: 65px;
					border-radius: 10px 10px 10px 10px;
				@media screen and (max-width: 767px) 
				{
				margin-left: -2px;
				} 
				}
			}
		#member-profile-add
			{
				.member-add-travel-coordinator--submit
				{
				margin-left: 158px;
				@media screen and (max-width: 767px) 
				{
				margin-left: 20px;
				} 
				}
				.datepicker-icon{
					position: absolute;
					text-align: center;
					right: 20px;
					top: 12.5px;
					z-index: 2;
					color: #666;
					pointer-events: none;
				}
				.add-profile--name{
				margin-top: 10px;
				}
				.tag{
				color: #006DBB;
				margin-left: 10px;
				font: normal normal 14px/16px Gotham,Arial;
				}
				color: #006DBB;
				margin-left: 0px;
				font: normal normal 14px/16px Gotham,Arial;

				.addtra{
					margin-top: 30px;
					margin-bottom: 20px;
					font-weight: 600;
					font-family: normal medium 18px/20px Gotham,Arial;
					margin-left: 10px;
					color: #61666F;
				@media screen and (max-width: 767px) 
				{
				margin-top: 6px;
				} 
				}
				.circle-travel{

					padding-top: 6px;
					margin-left: -32px;
					border-radius: 15px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;	
					text-align: center;
				}
				.fieldset
				{
					margin-left: 0px !important;
					@media screen and (max-width: 768px) {
					margin-left: 0px !important;
				}
				}

				.add-traveller-first-name
				{
				@media screen and (max-width: 768px) {
				margin-bottom: 14px;
				}
				}
				.optinal{
				color: #14467B;
				font-family: normal medium 14px/16px Gotham,Arial;
				}
				.loyality{
				color: #61666F;
				font-family: normal medium 14px/16px Gotham,Arial
				}
				label{
					position: absolute !important;
					margin-top: -10px !important;
					background-color: white !important;
					margin-left: 20px !important;
					padding-left: 4px !important;
					padding-right: 4px !important;
				}
				select{
				border-radius: 10px 10px 10px 10px;
				}
				.selectize-input{
				border-radius: 10px 10px 10px 10px;
				}
				input{
				border-radius: 10px 10px 10px 10px;
				}
				.distance-margin
				{
				margin-top: 15px;
				}
				.btn-secondary{
				margin-left: 10px;
				border-radius: 10px 10px 10px 10px;

				}

				.btn-member--cancel{
					width: 31%;
					@media screen and (max-width: 767px) 
					{
					width: 44%;
					} 
					}

				.btn-primary{
					margin-left: 65px;
					border-radius: 10px 10px 10px 10px;
					@media screen and (max-width: 767px) 
					{
					margin-left: -7px;
					} 
				}
			}
		.firsttraveller_0
				{
				.col-lg-4
				{
				height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
				width: 51%;
				}
				}
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}

					height: 35px;
					margin-right: 63px;
				a{	  
					float: right;
				}

				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}

					a {
					color:red;
					text-decoration: none;
					}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
					}
					@media screen and (max-width: 767px) 
					{
					width: 100%;
					} 

				.circle-travel
				{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.row {
				margin-bottom: 7px;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.col-lg-2 
				{
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
					.col-lg-3 {
					width: 6% !important;
					}
				#hidetraveller{
				@media screen and (max-width: 767px) 
				{
					margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;

				}
				p {
					margin: 0 0 3px !important;
				}

				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
					color: #61666F ! important;
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
					@media screen and (max-width: 767px) {
					#traveller-title_0{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
				}


				.firsttraveller_1
				{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 

				.edittraveller-details
				{
				.fa{
				font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
				float: right;
				}
				}


				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}
				a {
				color:red;
				text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}

				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.row {
				margin-bottom: 7px;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_1{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
				left: -25PX;
				padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
				margin-left: -25px;
				}

				}
				.row {
				margin-bottom: 10px;
				}

				#hidetraveller-details_1{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold  14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_1{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
				pointer-events: none;
				}
				}
				#traveller-detail_1{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
					@media screen and (max-width: 767px) {
					#traveller-title_1{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				}
			}

		.firsttraveller_2
			{
				.col-lg-4
				{
				height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
				float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					color: white;
					text-align: center;
				}
				.row {
				margin-bottom: 7px;
				}
				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
					width: 6% !important;
				}
				#hidetraveller_2{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
				left: -25PX;
				padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
				margin-left: -25px;
				}

				}
				.row {
				margin-bottom: 10px;
				}

				#hidetraveller-details_2{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_2{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
				pointer-events: none;
				}
				}
				#traveller-detail_2{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) {
				#traveller-title_2{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
					}
					p {
					margin: 0 0 3px !important;
					}
				}
			}

		.firsttraveller_3
			{
				.col-lg-4
				{
				height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
				width: 51%;
				}
				}

				@media screen and (max-width: 767px) 
				{
				width: 100%;
				}

				.edittraveller-details
				{
					.fa{
					font-size: 21px;
					}
					height: 35px;
					margin-right: 63px;
					a{
					float: right;
					}


				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}


				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				@media screen and (max-width: 767px) 
				{
				width: 100%;
				} 
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
				margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_3{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_3{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_3{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
					margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_3{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_3{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
			}


		.firsttraveller_4
			{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
				font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{		   
					float: right;
				}

				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}


				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
					margin-bottom: 7px;
				}
				.col-lg-2 {
					width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
					width: 6% !important;
				}
				#hidetraveller_4{
				@media screen and (max-width: 767px) 
				{
					margin-left: 77px;
				}
					background: #F1F1F1;
					// height: 298px;
					border: 0px solid #CCCCCC;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_4{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
				font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_4{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_4{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_4{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
			}
		.firsttraveller_5
			{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
					float: right;
				}

				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}
				a {
				color:red;
				text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
				margin-bottom: 7px;
				}
				.col-lg-2 {
					width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_5{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					padding-left: -50px;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_5{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_5{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
				pointer-events: none;
				}
				}
				#traveller-detail_5{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_5{
				height: 17px;
				}
				}
				.traveller-colr{
				color: #006DBB;
				font: normal normal 14px/16px Gotham,Arial;
				font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
			}
        .firsttraveller_6
			{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				.edittraveller-details
				{
				.fa{
				font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
				float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				@media screen and (max-width: 767px) 
				{
				width: 100%;
				} 
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
				margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_6{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
				background: #F1F1F1;
				margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				p {
					margin: 0 0 3px !important;
				}
				.row {
					margin-bottom: 10px;
				}
				#hidetraveller-details_6{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_6{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_6{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_6{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				}
			}


		.firsttraveller_7
			{
				.col-lg-4
				{
					height:40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
					float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
					margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_7{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_7{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_7{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
					margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_7
				{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_7
				{
					height: 17px;
				}
				}
				.traveller-colr
				{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color
				{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}

		}
		.firsttraveller_8
			{
				.col-lg-4
				{
					height:40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
					float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
					margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_8{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_8
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_8{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_8{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
					margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_8
				{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_8
				{
					height: 17px;
				}
				}
				.traveller-colr
				{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color
				{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}

		}
#addtraveller_mobileadd
	{

				@media screen and (max-width: 767px) 
				{
					border-radius:36px;
					width: 50px;
					height: 50px;
					background: #006dbb;
					bottom: 45px;
					right: 25px;
				@media screen and (orientation:landscape){

				bottom: 25px;
				right: 25px;
				}
					font-size: 14px;
					line-height: 28px;  
					-webkit-transform: rotate(-90deg);
					font-weight: 600;
					text-align: center;
					font-size: 17px;
					position: fixed;
				} 

				.fa
				{
					display: none !important;

				@media screen and (max-width: 767px) 
				{
					display: inline-block !important;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: inherit;
					text-rendering: auto;
					color:white;
					-webkit-font-smoothing: antialiased;
					margin-left: -1px;
					margin-top: 18px;
					font-size: 18px;
				@media screen and (max-width:767px) and (orientation:landscape)
				{
					top: 315px  ! important;
				}
				}
				}
	}

	.member-edit-travel-coordinator--submit .col-xs-12{
		display : inline-flex;
	}
	.firsttraveller_0edit-profile--title{
		pointer-events: none;
	
	  .selectize-input{
		background-color : #eee;

	}

}
#member-profile-edit1{
	.selectize-control.single .selectize-input:after {
		background-color: #eee;
		}
}

@media screen and (min-width: 767px) and (max-width: 1024px)
{
	.firsttraveller_0{
		width: unset;
	}
	.firsttraveller_1{
		width: unset;
	}
	.firsttraveller_2{
		width: unset;
	}
	.firsttraveller_3{
		width: unset;
	}
	.firsttraveller_4{
		width: unset;
	}
	.firsttraveller_5{
		width: unset;
	}
	.firsttraveller_6{
		width: unset;
	}
	.firsttraveller_7{
		width: unset;
	}
	.firsttraveller_8{
		width: unset;
	}
	
	
}

}
#mytraveller-popup
	{
				.removename
				{
					font: normal bold 14px/20px Gotham,Arial;
					color: #006DBB;
				}
				.qblack
				{
					color:black;
				}

				.btn-mytraveler--remove
				{
					width: 160px;
					height: 46px;
					margin-left: 10px;
					background: #ED1C24;
					border-radius: 10px;
					color: white;
					margin-top: 10px;
					padding-top: 14px;
					padding-left: 2px;
				}
				.btn-mytraveler--cancel
				{
					width: 160px;
					height: 46px;
					margin-left:10px;
					border-radius: 10px;
					color:black;
					margin-top: 10px;
					border: 1px solid #61666F;
					padding-top: 14px;
					padding-left: 2px;
				}
				.name
				{
					font: normal normal 14px/20px Gotham,Arial;
				}

	}


.white-popupmytraveller 
            {
				position: relative;
				padding-left: 27px;
				background: #FFF;
				padding: 20px;
				width: 356px;
				border-radius: 5px;
				max-width: 500px;
				margin: 20px auto;
			}
#mytraveller-popup-exist-error--msg
{
	.disp--error
		{
			font: normal bold 14px/20px Gotham,Arial;
			color: #006DBB;
		}
	.btn-mytraveler--exist--msg
		{
			width: 160px;
			height: 46px;
			margin-left: 10px;
			background: #ED1C24;
			border-radius: 10px;
			color: white;
			margin-top: 10px;
			padding-top: 14px;
			padding-left: 2px;
		}
		.msg--error
		{
			font: normal normal 14px/20px Gotham,Arial;
		}
}
.white-popupmytraveller--exist-error--msg 
 {
	position: relative;
	padding-left: 27px;
	background: #FFF;
	padding: 20px;
	width: 356px;
	border-radius: 5px;
	max-width: 500px;
	margin: 20px auto;
}

#gts-restrict-child{
	width: unset;
	text-align: center;
	a{
		width: 70%;
	}
	p{
		text-align: center;
		padding: 29px;
	}
	@media screen and (min-width: 768px)
	{
	a{
		width: 250px;
	}
	
	}
}
.user--claimed--msg{
	border-bottom: 1px solid #ed1c24;
    padding: 10px 0;
	color:#ed1c24;
	font-weight: 600;
	margin-bottom: 6px;
}