.sidebannerbuttons {
  &__banners {
    margin-bottom: 20px;
  }

  .banner {
    padding: 0;

    &__item {
      padding: 20px;
      background-color: $blue;

      &.marginTop{
        margin-top: 20px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .title {
      display: block;
      text-align: center;
      font-family: $font-family-light;
      font-size: rem(24);
      color: $white;
      margin-bottom: 20px;
    }
  }
}

@include respond-to(tablet-portrait){
  .sidebannerbuttons {
    &__banners {
      margin: 20px 20px 0;
    }
    .banner{
      &__item{
        &.marginTop{
          margin-top: 0;
        }
      }
    }
  }
}
