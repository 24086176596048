.deals-alert--container{
	display: block;
	width: 100%;
	padding-top: 3%;
	.deals-alert--holder, .deals-alert--success{
		background: $focusblue;
		padding: 2% 0 3% 0;
		.col-lg-2, .col-lg-3{
			padding: 0;
			margin: 0;
			&.preferredCity{
				width: 22%;
				.selectize-control{
					.selectize-input{
						&.select-filled{
							padding: 22px 20px 5px 25px;
						}
					}
				}
			}
			&.dealsAlertEmail{
				width: 27%;
			}
		}
		h1{
			color: $white;
			text-transform: initial;
			@include font-size(48);
			text-align: center;
			margin-bottom: 5px;
		}
		p{
			text-align: center;
			color: $white;
			@include font-size(14);
		}
		.deal-alert--form{
			margin: 35px 0 0;
			.form-group{
				margin-bottom: 0;
			}
			.col-lg-3{
				padding: 0;
				margin: 0 10px 0 0;
			}
		}
		.email-details{
			text-align: center;
			width: 100%;
			@include font-size(14);
			color: $white;
			a{
				color: $white;
			}
			span{
				display: block;
				margin: 15px 0;
			}
		}
	}
	.deals-alert--success{
		display: none;
	}
	.booking-form--title{
		position: absolute;
		left: 24px;
		top: 14px;
		color: $darkgray;
		@include font-size(14);
		z-index: 8;
		pointer-events: none;
		display: block;
		max-width: initial;
		margin-bottom: initial;
		font-weight: initial;
    -webkit-transition: all 90ms linear;
    -o-transition: all 90ms linear;
    transition: all 90ms linear;
		&.input-filled{
			top: 6px;
			left: 27px;
			@include font-size(12);
		}
		&.has-error{
			color: $errorred;
		}
	}
	#dealsAlertEmail{
	    height: 47px;
		color: $blue;
		@include font-size(14);
	    &.input-focused{
			padding: 21px 20px 4px 25px;
		}
		&.has-error{
			border: 1px solid $errorred;
		}
		&:focus{
			border: 1px solid $focusblue;
			box-shadow: none;
		}
	}
	button{
		&#dealsAlertSubmit{
			background: $linkblue;
			width: 100%;
			text-transform: initial;
			@include font-size(14);
			padding: 12px 20px 14px;
			border: 0;
			&:hover{
				background: $blue;
			}
			&:focus{
				box-shadow: none;
				border: 0;
			}
		}
		&#dealsAlertResend{
			width: 10%;
			margin: 25px 45%;
		}
	}
	.dealsalert-error--validation{
		border: 1px solid $errorred;
		margin: 0 1.5% 1.5% 0;
		padding: 10px 0;
		display: none;
		.fa {
			color: $errorred;
			@include font-size(16);
			float: left;
		}
		.col-lg-1{
			padding-right: 0;
			width: 4%;
		}
		.col-lg-11{
			padding-left: 0;
		}
		.error-msgs{
			span{
				@include font-size(14);
				color: $white;
				display: none;
			}
		}
		#result{
			@include font-size(14);
			color: $white;
		}
		#dealsalert-validator{
			margin: 2px 0 0 6px;
			color: $white;
		}
	}
	.dealsalert-error--validation{
		width: 66%;
	}
}

@media (max-width: 1200px) and (min-width: 921px){
	.deals-alert--container{
		.deals-alert--holder{
			.col-lg-2{
				width: 5%;
				&.deals-alert--btn{
					width: 12%;
				}
			}
			.col-lg-3{
				width: 36% !important;
			}
			.col-lg-4{
				width: 42% !important;
			}
		}
	}
}
@media (max-width: 920px) and (min-width: 768px){
	.deals-alert--container{
		.deals-alert--holder{
			.col-lg-2{
				width: 1%;
				&.deals-alert--btn{
					width: 17% !important;
				}
			}
			.col-lg-3{
				width: 36% !important;
			}
			.col-lg-4{
				width: 51% !important;
				&.existingSubscriber{
					width: 40% !important;
				}
			}
		}
	}
}

@include respond-to(tablet-portrait){
	.deals-alert--container{
		padding-top: 5%;
		.deals-alert--holder{
			width: 100%;
			padding: 3% 5% 5%;
		}
		.dealsalert-error--validation{
			width: 100%;
			.col-lg-1{
				width: 8%;
			}
			.col-lg-11{
				padding-left: 15px;
			}
			#dealsalert-validator{
				margin: 0 0 0 25px;
			}
		}
		.col-lg-3, .col-lg-4, .deals-alert--btn{
			width: 100% !important;
			height: auto;
			margin: 0 0 15px !important;
		}
		.col-lg-5{
			width: 100% !important;
			margin-bottom: 15px !important;
			height: auto;
		}
	}
	.deals-alert--success{
		width: 90%;
		margin: 0 5% !important;
		padding: 3% 0;
		h1{
			line-height: 1em !important;
		}
		button{
			&#dealsAlertResend{
				width: 40%;
				margin: 25px 30%;
			}
		}
	}
}

@include respond-to(414){
	.deals-alert--container{
		h1{
			font-size: rem(36) !important;
			line-height: 1.2em !important;
		}
	}
	.dealsalert-error--validation{
		.error-msgs{
			@include font-size(13);
		}
	}
}
