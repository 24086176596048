.container {
  &--search-page {
    max-width: 1180px + 20px;
    @include respond-to(ipad-portrait){
      max-width: 700px + 20px;
    }
  }
}

.searchpagebar {
  $search-height: 48px;
  $search-left-offset: 20.5px;

  margin-bottom: 45px;

  &__title {
    text-align: center;
    margin-top: 30px;

    h1 {
      line-height: 1;
      color: $countdownblue;
    }
  }

  &__form {
    max-width: 856px;
    margin: 0 auto;
    margin-bottom: 15px;

    @include respond-to(#{856 + 20 + 20}) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .form-group {
      display: inline-block;
      width: 100%;
      font-size: 0;
    }
  }

  &__searchitem {
    position: relative;
    width: calc(100% - 146px - 10px);
    margin-right: 10px;
    float: left;

    @include respond-to(767) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__searchbar {
    border-radius: #{$search-height / 2};
    height: $search-height;
    padding-left: $search-left-offset;
    padding-right: $search-height;
    position: relative;
    z-index: 1;
  }

  &__clearbtn {
    $btn-width: $search-height;

    position: absolute;
    right: 0;
    top: 0;
    width: $btn-width;
    height: $btn-width;
    display: none;
    z-index: 2;
    cursor: pointer;

    .fa {
      display: block;
      text-align: center;
      line-height: $btn-width;
    }
  }

  &__searchbtn {
    width: 146px !important;
    float: left;

    @include respond-to(767) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__history {
    float: left;
    width: 100%;
    margin-left: $search-left-offset;

    .title {
      color: $countdownblue;
      font-size: rem(14);
      float: left;
      margin-right: 5px;
      margin-bottom: 0;
    }

    .history {
      padding: 0;
      margin: 0;
      list-style: none;
      float: left;

      &__item {
          float: left;
          margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: rem(12);
          border-radius: 12.5px;
          padding: 4px 16.5px;
          background-color: $alto;
          color: $black;
          display: block;

          &:hover {
            background-color: $lightgray;
          }
        }
      }
    }
  }

}

