.searchresults, a.searchresults {
  @include respond-to(more-than-1200) {
    margin-right: 120px - 15px - 15px; // offset for column
  }

  @include respond-to(767) {
    margin-right: 0;
    margin-left: 0;
  }

  &__title {
    margin-bottom: 30px;

    @include respond-to(767) {
      margin-right: 20px;
      margin-left: 20px;
    }

    h2 {
      line-height: 1;
      color: $countdownblue;
      font-weight: normal;
      font-family: $font-family;

      @include respond-to(767) {
        font-size: rem(30);
        line-height: rem(36);
      }
    }

    .subtitle {
      font-size: rem(16);
      line-height: rem(24);

      strong {
        color: $countdownblue;
      }
    }
  }

  &__item {
    border-bottom: solid 1px $lightgray;
    padding: 20px 0;
    height: auto;
    overflow: hidden;
    display: block;
    @include respond-to(767) {
      margin-right: 20px;
      margin-left: 20px;
    }

    &:first-child,
    &:nth-child(7) {
      border-top: solid 1px $lightgray;
    }

    & a{
      display: block;
    }
  }

  .result, a.result {
    &__title {
      color: $blue;
      font-size: rem(24);
      line-height: rem(30);
      margin-bottom: 0px;
    }

    &__excerpt {
      font-size: rem(16);
      line-height: rem(24);
      color: $gray;
    }
  }

  .banner-informative {
    $bg-intensity: 0.35;
    $bg-intensity-mob: 0.45;

    position: relative;
    height: 300px;
    margin: 30px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      background-image: radial-gradient(closest-side at 48% 112%, rgba($black, $bg-intensity) 47%, rgba($black, $bg-intensity) 100%);
      width: 100%;
      height: 100%;
      content: '';

      @include respond-to(767) {
        background-image: radial-gradient(closest-side at 48% 112%, rgba($black, $bg-intensity) 47%, rgba($black, $bg-intensity-mob) 100%);
      }
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 83%;
    }

    .title {
      font-size: rem(36);
      line-height: rem(40);
      text-align: center;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      color: $white;
    }

    .btn {
      display: block;
      margin: 0 auto;
      width: 146px;
    }
  }

  &__pagination {
    @import "bootstrap/pagination";

    $pagination-color: $black;
    $pagination-bg: transparent;
    $pagination-hover-color: $countdownblue;
    $pagination-hover-bg: transparent;
    $pagination-hover-border: inherit;
    $pagination-active-border: inherit;
    $pagination-active-color: $blue;
    $pagination-active-bg: transparent;

    @include respond-to(767) {
      position: relative;
      width: 100%;
      height: 48px + 40px;
      margin-bottom: 40px;

      @supports(display:flex) {
        width: initial;
        height: initial;
        margin-bottom: initial;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
                align-items: center;
      }
    }

    nav {
      float: right;
      margin-top: 40px;
      margin-right: 25px;
      margin-bottom: 40px;
      border: solid 1px $alto;
      border-radius: 23px;
      overflow: hidden;

    @include respond-to(767) {
      margin-right: 0;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 350px;

      @supports(display:flex) {
        position: static;
        left: initial;
        -webkit-transform: initial;
                transform: initial;
        width: initial;
      }
    }
    }

    .pagination {
      margin: 0;
      float: left;

      > li {
        > a,
        > span {
          font-size: rem(16);
          padding: 12px 16px;
          border: 0;
        }
      }

      > li {
        > a,
        > span {
          color: $pagination-color;
          background-color: $pagination-bg;
        }
        &:first-child {
          > a,
          > span {
            margin-left: 0;
            border-left-radius: initial;
          }
        }
        &:last-child {
          > a,
          > span {
            border-right-radius: initial;
          }
        }
      }

      > li > a,
      > li > span {
        &:hover,
        &:focus {
          color: $pagination-hover-color;
          background-color: $pagination-hover-bg;
          border-color: $pagination-hover-border;
        }
      }

      > .active > a,
      > .active > span {
        &,
        &:hover,
        &:focus {
          color: $pagination-active-color;
          background-color: $pagination-active-bg;
          border-color: $pagination-active-border;
        }
      }

      .pagination__button {
        margin: 9px 12px !important;
        width: 28px;
        height: 28px;
        padding: 0;
        position: relative;
        border-radius: 50% !important;
        background-color: $blue;
        overflow: hidden;

        &:hover {
          background-color: $countdownblue;
        }

        i.fa {
          display: block;
          position: absolute;
          width: 28px;
          font-size: rem(20);
          text-align: center;
          color: $white;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}

.bcs-container-searchbox{
  margin-top: 35px;
  @include border-radius(25px);
  height: 44px !important;
}

.bcs-searchbox{
  padding: 3px 5px 0 5px !important;
  width: calc(100% - 80px) !important;
}

.bcs-searchbox-submit{
  border: 0 !important;
  @include border-radius(0 25px 25px 0);
  width: 50px !important;
  background-image: none !important;
  background-color: #005aaa !important;
  &:after{
    content: "\f002";
    display: inline-block;
    font: normal normal normal 24px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font-size(18);
    line-height: 1em;
    color: $white;
    position: relative;
    top: 1px;
    right: 0;
    left: -2px;
  }
}
#bcs-results-div {
  .bcs-caption {
    @include respond-to(tablet-portrait) {
      display: block !important;
    }
  }
  .bcs-caption-text{
    @include respond-to(tablet-portrait) {
      display: block !important;
    }
  }
  .bcs-caption-image {
    display: none !important;
  }
}
