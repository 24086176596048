$fa-font-path:        "../fonts" !default;
$fa-font-size-base:   14px !default;
$fa-line-height-base: 1 !default;
//$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts" !default; // for referencing Bootstrap CDN font files directly
$fa-css-prefix:       fa !default;
$fa-version:          "4.7.0" !default;
$fa-border-color:     #eee !default;
$fa-inverse:          #fff !default;
$fa-li-width:         (30em / 14) !default;

$fa-youku: "\e901";
$fa-line-messenger: "\e900";
.#{$fa-css-prefix}-youku,
.#{$fa-css-prefix}-line-messenger {
	font-family: 'Custom-FA' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.#{$fa-css-prefix}-youku:before {
	content: $fa-youku;
	font-size: 25px;
  top: 2px;
  position: relative;
}

.#{$fa-css-prefix}-line-messenger:before {
	content: $fa-line-messenger;
	font-size: 18px;
}
