.mholidays__content {
  position: relative;
  z-index: 1000;
  margin: 0 auto;
  /** MHolidays -- Heading Styles */
  > h2.title:first-child {
    margin-top: -6px;
  }

  h2.title {
    position: relative;
    margin: 0;
    font-family: $font-family;
    color: $blue;
    line-height: initial;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  h3 {
    color: $countdownblue;
    &.title {
      font-size: rem(20);
      margin-bottom: 10px;
    }
  }

  .description {
    img {
      margin-bottom: 20px;
    }
  }

  /* search flight/hotel modal */
  .modal {
    position: relative!important;
    // top: -45px!important;
    bottom: auto!important;
    overflow:visible;
    .modal-content {
      .modal-body {
        label:not(.radio-inline) {
          color: $holidays-form-color;
        }
      }
      @media screen and (min-width:768px) {
        .modal-body {
          padding-right:0!important;
          padding-left:0!important;
        }
      }
    }
  }

  @media screen and (min-width:1024px) {
    .modal {
      top:-60px!important;
    }
  }

  .modal-dialog {
    margin:0 auto!important;
  }

  .searchFlightHotel {
    .travel-type {
      .radio-inline {
        font-family: Gotham-Book;
        font-size: 14pt;
        color: #9091a1;
        input[type=radio] {
          width: 12px;
          height: 12px;
          margin-top: 7px;
          cursor: pointer;
        }
      }
    }
    .h-container {
      display: none;
    }
    @media screen and (max-width: 767px) {
      .mholidays-button {
        border-radius: 35px!important;
      }
    }
  }
  
  @media screen and (max-width: 767px) {
    .modal,
    .holiday-content {
      // top:-58px!important;
    }
    .holiday-content {
      top:-60px!important;
      position: relative;
      padding-top: 20px;
    }
    .searchFlightHotel {
      .travel-type {
        text-align:center;
      }
    }
  }

  .modal-lg {
      width:94%!important;
  }
}
#hotel, #tourInputWrap, #transferpickup, #transferdrop{
  #hotelInputWrap{
    width :30% !important;
    @media screen and (max-width: 1024px){
      width :32% !important;
    }
    @media screen and (max-width: 767px){
      width :100% !important;
    }
     
    .hotelInputClass,.pickInputClass {
      @media screen and (max-width:1024px) {
       /// height: 41px;
        }
      @media screen and (max-width:768px) {
      height: 41px;
      max-width: 100%;
      margin: 0 auto;
      }
      @media screen and (max-width:767px) {
        max-width: 90%;
        }
        text-overflow: ellipsis;      
        overflow: hidden;
        white-space: nowrap;
        background-color:#fff !important;
    }
    
  }
  .form-group {
    &.dep-date,
    &.rtn-date,
    &.trv-info,&.cabin-info {
      display: block;
      @media screen and (max-width: 767px){
        display:none;
      }
    }

  }

  .hotel-display-tui-container, .tour-display-tui-container, .from-display-tui-container,.enrich-hotel-earn-display-tui-container,.enrich-hotel-redeem-display-tui-container,.mob-enrich-hotel-earn-display-tui-container,.mob-enrich-hotel-redeem-display-tui-container{
    background: white;
    border: 1px solid #ccc;
    z-index: 100;
    position: absolute;
    top: 65px;
    padding: 10px 0px 10px 0px;
    display:none;
    width: calc(100% - 11px);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border: 0px 1px 1px 1px solid #0393a6;
    border-top-width:0px;

    @media screen and (max-width: 1024px) {      
      top: 81px;
      margin: 0 auto;
      width: 100%;
      max-width: 87%;
      right: 15px;
      left: 15px;
    }
    
    @include respond-to(ipad-portrait) {
      top: 70px;
      max-width: 86.5%;
    }
    @include respond-to(ipad-pro) {
      top: 81px;
      max-width: 89.5%;
    }
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
      top: 81px;
      max-width: 97.5%;
    }
    @include respond-to(ipad-landscape) {
      max-width: 89.5%;
    }   

    @media screen and (max-width: 767px) {
      max-width: 81%;
      margin: 0 auto;
      top: 41px;
    }
    @media screen and (max-width: 767px) and (orientation: landscape) {
      max-width: 86.7%;
      margin: 0 auto;
      top: 41px;
    }
    @media only screen 
    and (device-height : 375px) 
    and (device-width : 812px) 
    and (-webkit-device-pixel-ratio : 3) and (orientation : landscape)  { 
      max-width: 87%;
      margin: 0 auto;      
    }
  }

  .hotel-tui-inner {
    max-height: 255px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 12px;
    }
  
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
        -webkit-border-radius: 0;
        border-radius: 0;
    }
  
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #0393a6;
        -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
    }
  }
  
  .hotel-title{
    position: relative;
    display: block;
    font-size: 0.80rem;
    padding: 5px 20px 5px 0px;
    font-weight:bold;
    span{
      position: relative;
        color: #df2280;
        text-transform: uppercase;
        padding: 0 20px;
        background: white;
    }
  }
  .hotel-title:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: #dfe2e2;
    margin-top: -1px;
  }
  
  .hotel-list{
    display: block;
    font-size: 0.80rem;
    color: #0393a6;
    padding: 5px 20px 5px 20px;
  }
  .hotel-list:hover {
    background-color: #0393a6;
    color:white;
    cursor: pointer;
  }
}