#members-travel--subsidy
{
  .fa-id-card-o {
    color: #55A814;
  }
  .govtandc-link {
    color: #006DBB !important;
    cursor: pointer;
    text-decoration: underline;
  }
  .gts-user-edit {
    height: 15px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
    h1
        {
                font: normal 700 30px/34px Gotham,Arial;
        }
        .col-lg-12
        {
            padding-left: 7% !important;
        }
        .btn-bookflight--submit{
            margin-top: 30px!important;
            border-radius: 10px;
            width: 220px;
            height: 46px;
        }
        .check-box--terms--cond--section{
            margin-top: 10px;
        }
        .select--terms--cond--checkbox {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            //font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          /* Hide the browser's default checkbox */
    .select--terms--cond--checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      /* Create a custom checkbox */
    .terms--cond--checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ccc;
        cursor: pointer;

      }
      .gts-container{
        pointer-events:none;
        opacity:0.5; 
        margin-bottom: 83px;
      }
      /* On mouse-over, add a grey background color */
    .select--terms--cond--checkbox:hover input ~ .terms--cond--checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .select--terms--cond--checkbox input:checked ~ .terms--cond--checkmark {
        background-color: #46a049;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .terms--cond--checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .select--terms--cond--checkbox input:checked ~ .terms--cond--checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .select--terms--cond--checkbox .terms--cond--checkmark:after {       
         border: solid white;
        border-width: 0 3px 3px 0;        
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        height: 5px;
        left: 5px;
        top: 6px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        width: 10px;
      }
      hr{
        border: 1px solid #7F7F7F;
        opacity: 1;
      }
      
.container
{
  z-index:0 !important;
}

#member-profile-edit1{
  .passport-information{
    display:none;
  }
  .contact-information{
    display:none;
  }

}
}
.gts-hide{
  display:none;
}

.gov-username-header{
	color: white;
	margin-left: 1020px;
	padding-top: 13px;
}
#mab-bookingnav.gov-header {
  background-image: url(../images/desktop_nav_bg_1920x52.jpg);
  position: relative;
}
.enrolment-text{
  padding: 17px 77px;
}
.steps-to-book{
  text-align: left;
  font: normal normal 21px/24px Gotham,Arial;
  letter-spacing: 0;
  color: #006DBB;
  opacity: 1;
  p{
    color: #ED1C24;
    font: normal normal 14px/24px Gotham-book,Arial;
  }
  ol{
    font: normal normal 14px/24px Gotham-book,Arial;

  }
}
.note{
  color: #ED1C24;
  font: normal normal 14px/24px Gotham,Arial;
}
ol {
  margin-left: -25px;
  font: normal normal 14px/24px Gotham,Arial;
  
}
ol li{
  line-height: 1.72857;
}
.gov-header-text{
  text-align: left;
  letter-spacing: 0;
  color: #19286e;
  text-shadow: 0px 3px 3px #000000;
  opacity: 1;
  font: normal normal 45px/67px Kaushan Script, arial;
  // margin-top: -120px;
  margin-bottom: 20px;
}
.background-gov-header {
  background-image: url(../images/bg_image.png);
}
@media (max-width: 767px) {
  .enrolment-text{
    padding: 17px 24px;
  }
  .gov-header-text{
    text-align: left;
    letter-spacing: 0;
    color: #19286e;
    text-shadow: 0px 3px 6px #00000066;
    opacity: 1;
    font: normal normal 18px/38px Kaushan Script,arial;
   //margin-top: -83px;
    //margin-bottom: 52px;

  }
    .gov-wrapper
      {
        margin: 0px;
      }
      .gts-container{
        text-align: center;
      }
      .background-gov-header {
        background-size: cover;
        height: 131px;
        width: 434px;
        }


}

  @media (min-width: 767px) {
  .gov-wrapper
    {
    margin:auto;
   
    }
    // .enrolment-text{
    //   margin-top: -496px;
    // }
    .background-gov-header {
      width: 1920px;
      height: 650px;
        }
}

@media (min-width: 768px) and (max-width: 979px)  {

  .gov-wrapper {
    margin: 0 100px;
 }

 .gov-header-text {
  text-align: left;
  letter-spacing: 0;
  color: #19286e;
  text-shadow: 0 3px 3px #000;
  opacity: 1;
  font: normal normal 25px/67px Kaushan Script,arial;
  // margin-bottom: 89px;
}

}
.govt_content{
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5){
  /* ipad pro */
  .col-sm-6 {
     width: unset;
}
  .gov-wrapper {
    margin: 0 100px;
 }

 .gov-header-text {
  text-align: left;
  letter-spacing: 0;
  color: #19286e;
  text-shadow: 0 3px 3px #000;
  opacity: 1;
  font: normal normal 33px/67px Kaushan Script,arial;
 // margin-bottom: 89px;
}
}
}
@media only screen and (min-device-width: 1025px) {
  .gov-wrapper {
    width: 69%;
 }
}

#member-myadd-traveller .select--checkbox {
  position: unset;
}
.govt_content {
  padding-left: 0px !important;
}
@media (min-width: 320px) and (max-width: 568px)  {
  .btn-member--submit, .btn-member--cancel{
    width: 115px!important;
    min-width: 115px!important;
    height: 46px;
  }

  // #member-myadd-traveller .checkmark {
  //   top: -27px;
  //   left: 21px;
  // }
}

@media (min-width: 768px) and (max-width: 1024px){
.col-sm-6 {
     width: 100% !important;
}
}
body{
  background: #fff;
}