/** Members -- Table Styles */
.members__content,
.misctable__content {
  .table-title {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: rem(28);
    font-family: $font-family-medium;
    font-size: rem(20);
  }

  .miles-table {
    margin-top: 20px;
    margin-bottom: 10px;
    min-width: 100%;
    max-width: 100%;
    overflow-x: auto;
    margin-left: 0;

    @media screen and (min-width: 650px) {
      min-width: 600px;
    }

    @media screen and (max-width: 649px) {
      width: 100% !important;
    }

    &--full-width {
      width: 100%;
    }

    td {
      padding: 20px;
      font-size: rem(14);
      //text-align: center;

      &:first-child {
        padding-left: 0;
      }
    }

    th {
      line-height: 1.2;
      padding: 10px 20px;
      text-align: left;
      font-weight: 600;
      font-family: $font-family-medium;
      text-transform: uppercase;
      vertical-align: bottom;

      &:first-child {
        padding-left: 0;
      }
    }

    tr {
      border-bottom: solid 1px $lightgray;
    }
  }

  // datatables stuff
  .dataTables_filter {
    display: none;
  }

  #enrichMyActivity_filter{
    display: none;
  }

 /* #enrichMyActivity_wrapper{
    .dataTables_filter {
      display: block;
    }
  }*/
}
.activity .miles-earned{
  width:20% !important;
}