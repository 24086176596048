.page-title--container{
	padding: 1% 0;
	h1{
	color: $countdownblue;
	text-transform: initial;
	@include font-size(48);
	text-align: center;
	margin-bottom: 5px;
	}
}

@include respond-to(767){
	.page-title--container{
		h1{
			@include font-size(36);
		}
	}
}