/** MHolidays -- Button Styles */
.mholidays__content {
    .mholidays-button {
        background-color:$holidays-form-button-bgcolor!important;
        color: $white!important;
        font-family: Gotham-Book;
        &:hover {
            background-color:$holidays-form-field-selected-val!important;
        }
    }
    @media screen and (max-width:767px) {
        .mholidays-button {
            max-width: 300px;
            margin: 0 auto;
            font-size: 12pt;
            padding:8.5px;
            margin-top:1px;
        }
    }
    @media screen and (max-width:614px) {
        .mholidays-button {
            max-width: 90%!important;
        }
    }
}