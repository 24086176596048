#ibe-stay--container {  

  .container:nth-child(2)>.row {
    margin-left: -11px;
    margin-right: -11px;

    > div,
    iframe {
      min-height: 180px;
    }
  }
  #carrental_container{
    display: none;
    margin-left: 5px;
  } 
}
.hotel-rental {
  float: left;
  padding-left: 0;
  height: 20px;
  li {
    @include font-size(14);
    list-style-type: none;
    float: left;
    color: $gray;
    margin-right: 30px;
    &.selected {
      color: $linkblue !important;
      border-bottom: 1px dashed $linkblue;
      a {
        color: $linkblue !important;
        text-decoration: none;
        &:hover,
        &:focus,
        &:active {
          &:before {
            right: 100% !important;
          }
        }
      }
    }
    a {
      color: $gray !important;
      &:hover,
      a:focus {
        color: $linkblue;
        text-decoration: none;
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  $mainnavheight: 76px;  
  iframe{
    min-height: 280px;
  }
  .mobile-stay{
    //display: none;
  }

}


.miles-calculator--widget{
  .from-location-wrapper{
    cursor: not-allowed;
    pointer-events: none; 
    .selectize-control.single .selectize-input:after
    {
      background-color: #f1f1f1 !important;
    }
   }
  .from--location{
    cursor: not-allowed;
    pointer-events: none; 
    .selectize-input{
      background-color: #f1f1f1;
    }
  
    
  }
}


.miles-calculator--widget, .flight-shedule--widget{
   padding:30px;
   .load-more-shedule{
    text-align: center;
    width: 80%;
    color: #005aaa;
    cursor: pointer;
    display:none;
    @media (max-width: 768px){
      width: 100%;
    }
  }
  table > :nth-child(1)  {
    background-color:#f1f1ef; 
    color: #005aaa;
  }
  tbody{
   background-color:  rgb(241,241,239);
  }
  .miles-error-validation{
    padding-bottom:20px;
  }

  @media (max-width: 768px){
  .col-sm-3, .col-sm-2 {
    width:100%;
  }
  }
  .miles-calculator-btn, .flight-shedule-btn{
    margin-top: 22px;
  }
  .members__content{
    padding-left:0px;
   // @include respond-to(tablet-portrait) {
    margin-left: auto;
    margin-right: auto;
    //}
    .miles-table{
      margin-top: 4%;
      margin-left: 16%;
      @media (max-width: 1300px){
        // margin-left: 3%;
        margin-left: 20%;
       }
      @media (max-width: 769px){
       // margin-left: 3%;
       margin-left: auto;
       margin-right: auto;
      }

       tr{
        font-weight:400;
        @include respond-to(tablet-portrait) {
          font-weight:200;
          padding-left: 0px !important;
          padding-right: 0px !important;
          font-size: 12px;
        }
        td, th{
          font-weight:200;
          font-size: 14px;
          @include respond-to(tablet-portrait) {
          font-size: 12px;
          padding: 15px 8px 15px 5px !important;

         }

        }
      }
      th{
       // padding-left: 5% !important;
        font-weight: 600 !important;
        padding: 20px;
        text-align: center!important;
       }
       td:not(.expiring-miles){
          text-align: center !important;
       }
       td:last-child {
          padding-right:5% !important;
        }

      .expiring-miles{
        font-family: Gotham-Medium,Arial;
        text-transform: uppercase;
        font-weight: 600;
        padding-left: 5% !important;
        @include respond-to(tablet-portrait) {
          padding-right:0%;
         }
      }
    }
  }
}
.flight-shedule--widget{
  .miles-data-display{
    tr{
      display:none;
    }
  }
  .airportcode{
    float: right;
    display: block;
    background-color: #006dbb;
    font-weight: 700;
    color: #fff;
    border-radius: 3px;
    padding: 4px 6px 2px;
    width: 50px;
    text-align: center;
    margin-top: 0;

  }
  tr{
    width: 30%;
  }
  .members__content{
    @media (max-width: 768px){
      padding:0px;
    }
  }
  .miles-table{
    min-width: 69% !important;
    margin-left: 7% !important;
    @media (max-width: 1300px){
      min-width: 69% !important;
      margin-left: 7% !important;
      @media (max-width: 650px){
        margin-left: 0% !important;
      }
    }
  }
  
 .col-sm-2{
    @media (min-width: 768px){
        width: 16.66667% !important;
    }
    @media (max-width: 767px){
      width:100% !important;
    }
  }
  .airportname{
    font-weight: 700;
  }
  .airportline {
    margin-bottom: 0;
  }
}