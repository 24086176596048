.members--mymiles--my-activity {
  + .members--mymiles--my-statement {
    margin-top: 40px;
  }
}
.members--mymiles--my-statement{
  .activity{
    width: 25% !important;
  }
}
.miles-earned{
  width: 25% !important;
}