/**
 * mHolidays
 * - header
 */

// base styles for mholidays
$mholidays-sidebar-width: 300px;

#mholidays-content {
    > .container {
        &.container--main-header{
            /*background: $holiday-bg-color;*/
            width: 100%!important;
            padding: 0!important;
            .banner-container {
                padding: 0;
            }
        }
    }
    .banner-container {
        padding: 0;
        .seq {
            .content-holder {
                margin:0 40px;
                top: 95px;
                @media screen and (max-width: 767px) {
                    margin:0;
                    width: 100%;
                    padding: 0 20px;
                 }
                .featured {
                    color: $white;
                    font-family: Gotham-Book;
                    font-size: 12pt;
                    margin-bottom: 5px;
                }
                .article-title {
                    color: $white;
                    font-family: Gotham-Medium;
                    font-size: 25pt;
                    @media screen and (max-width: 767px) {
                        font-size: 18pt;
                     }
                }
                .line-border {
                    width: 150px;
                    height: 2px;
                    background-color: $white;
                    margin: 10px 0;
                    font-size: 10pt;
                }
                a {
                    color: $white;
                    &.article-read-more {
                        color: $white;
                        font-family: Gotham-Light;
                        font-size: 10pt;
                        @media screen and (max-width: 767px) {
                           font-weight: bold;
                        }
                    }
                }
                
            }
            .seq-pagination {
                bottom:94px;
                @media screen and (min-width: 1200px) {
                    padding-right: 95px;
                }
            }
            .seq-pause {
                bottom: 55px;
                left: 41px;
            }
            @media screen and (max-width: 767px) {
            .seq-pause {
                bottom:57px;
                left: 12px;   
            }
            }
            @media screen and (max-width: 767px) and (orientation: landscape) {
                .seq-pause {
                    bottom:52px;
                    left: 20px;   
            }
            }           
            @include respond-to(ipad-pro) {
                .seq-pause {
                    bottom:54px;
                    left: 31px;   
            }
            }
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1366px) 
            and (-webkit-min-device-pixel-ratio: 2) 
            and (orientation: landscape) {
                .seq-pause {
                    bottom:54px;
                    left: 41px;   
            }
            }
            @include respond-to(ipad-landscape) {
                .seq-pause {
                    bottom:55px;
                    left: 30px;   
            }
            }
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                .seq-pause {
                    bottom: 40px;
                    left: 25px;   
                }
                .seq-pagination {
                    bottom: 78px;
                }
                }
            @media screen and (max-width: 767px) {
                .seq-pagination {
                    bottom: 93px;
                }
            }
            @media screen and (min-width: 768px) {
                .content-holder {
                    top: 95px!important;
                }
            }
            @media screen and (min-width: 769px) {
                .content-holder {
                    .featured {
                        font-size:15pt;
                    }
                    .article-title {
                        font-size:30pt;
                    }
                    a {
                        &.article-read-more {
                            font-size: 12pt;
                        }
                    }
                }
            }
        }
    }
    #m-holidays-header {
        background: transparent;
        width: 100%;
        height: auto;
        z-index: 100;
        position: absolute;
        .m-holidays-header-container {
            //border-bottom: 2px solid $white;
        }
        @include clear-bs-row;
        > .row {
            position: relative;
        }
        > .row:first-child {
            @media screen and (min-width: 1201px) and (max-width: 1440px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        @media screen and (min-width: 1201px) {
            .navbar {
                &.navbar-default {
                    display: inline-block;
                }
            }
        }
        @media screen and (min-width: 768px) {
            .m-holidays-header-container {
                margin:0 35px;
                .navbar {
                    &.navbar-default {
                        min-height:70px;
                        .logo,
                        .navbar-toggle {
                            margin: 0!important;
                        }
                    }
                }
            }
        }
        .navbar {
          margin-bottom: 0;
          @media screen and (max-width: 767px) {
              min-height: 55px;
              &.navbar-default {
                  display: flex;
              }
          }
          &.navbar-default {
            border: none;
            background-color: transparent;
            border-color: transparent;
            padding: 5px 0 0;            
            .navbar-brand {
              padding: 10px 15px 10px 0;
              float: left;
              height: auto;
              &.mHolidaysLogo {
                margin-left: 50px;
                padding-top: 15px;
                img{
                    height: 40px;
                    color: $white;
                }
              }
              @media screen and (max-width: 767px) {
                &.mHolidaysLogo {
                    margin-left: 60px;
                    padding-top: 12px;
                    img {
                        height:28px;
                    } 
                }                    
              }
              @media screen and (min-width: 768px) {
                &.mHolidaysLogo {
                    margin-left: 0!important;
                    padding-top: 15px!important;
                }
              }
            }
            .logo {
                display: inline-block;
                margin: 0 auto;
            }
          }
          @media screen and (min-width:1201px) {
            .navbar-collapse {
                &.collapse {
                    display:inline-block!important;
                    margin-top: 20px;
                }  
            }
          }
        }
        @media screen and (max-width: 767px) {
            .navbar-toggle {
                font-size: 1.25rem!important;
                line-height: 1.875rem!important;
                margin-top: 0!important;
            }
        }
        @media screen and (min-width:1201px) {
            .navbar-toggle {
                display: none!important;
            } 
        }
        .navbar-toggle {
            cursor: pointer;
            position: relative;
            line-height: 1;
            float: right;
            width: 24px;
            height: 19px;
            border: 0;
            background: transparent;
            @include z-index(navToggle);
            @include font-size(32);
            line-height: 1.3em;
            display: inline-block;
            margin-right: 20px;
            color: $white;
            @media screen and (min-width: 1201px) {
                display: none;
            }
            img {
                &.burger {
                    width: 24px;
                    height: 19px;
                    color: $white;
                }
            }
            .icon-bar {
              width: 100%;
              display: block;
              height: 3px;
              margin: 5px 0 0 0;
              background-color: $holiday-bg-color;
            }
            &.slide-active {
              .icon-bar {
                background: $holiday-bg-color;
              }
            }
        }
        @media screen and (max-width: 767px) {
            #slidemenu {
                background: #fff!important;
                left: 0;
                width: 100%;
                min-width: 0;
                position: absolute;
                padding-left: 0;
                z-index: 25;
                top: -90px;
                margin: 0;
                overflow: hidden;
                .navbar-nav {
                    min-width: 0;
                    width: 100%;
                    margin: 0 0 0 15px;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            #slidemenu {
                .desktop-nav {
                    display: none;
                }
            }
        }
        #slidemenu {
            display: inline-block;
            float: right;
            margin-top: 15px;
            .navbar-nav {
                li {
                    a {
                        color: $white;
                        border: 2px solid transparent;
                        font-size: 11pt;
                        font-family: Gotham-Medium;
                        &.nav-item {
                            padding:10px 0 10px 10px;
                            text-transform: uppercase;
                        }
                        &.active,
                        &:hover {
                            border: 2px solid $white;
                            text-decoration:none;
                            background-color: transparent;
                        }
                    }
                }                
            }
            .active {
              a {
                background-color: transparent;
              }
            }
        }
        &.desktop {
          // fixed header styling for desktop
          right: 0;
          left: 0;
          top: 0;
        }
        &.mobile {
          right: 0;
          left: 0;
        }
      }
}