.country-selector{
	float: left;
	width: 100%;
	padding: 10px 1% 12px;
	cursor: pointer;
	border-top: 3px solid transparent;
	border-left: solid 1px transparent;
	border-right: solid 1px transparent;
	.selected-country{
		width: 80%;
		height: auto;
		overflow: hidden;
		float: left;
		@include respond-to(retina){
			width: 82%;
		}
		.selected-country--flag{
			float: left;
			padding-left: 14%;
			padding-top: 12px;
			img{
				width: 24px;
				height: 24px;
				float: left;
				display: inline-block;
			}
		}
		.selected-country--lang{
			float: left;
			margin: 12px 10% 1px 10%;
			@include font-size(14);
			line-height: rem(24);
			color: $white;
      text-transform: capitalize;
		}
	}
	&.selected{
		border-top: 3px solid $errorred;
		margin-top: 10px;
		padding: 0 1% 22px;
		border-left: solid 1px $seashell;
		border-right: solid 1px $seashell;
		@include z-index(languageSelectorSelected);
		background: #fff;
		.selected-country {
			.selected-country--lang {
				color: $black;
			}
		}
	}
	.fa{
		float: left;
		color: $white;
		@include font-size(16);
		margin-top: 10px;
	}
}
.country-selector--container{
	width:45%;
	position: absolute;
	top: 72px;
	background: $white;
	padding: 20px;
	right: 0;
	@include box-shadow(0, 1px, 5px, 0px, rgba(0, 0, 0, 0.4));
	z-index: 700;
	@include clear-bs-row;
	display: none;
	.row{
		margin: 0;
	}
	.col-lg-5{
		width: 39%;
		margin-right: 2%;
	}
	.col-lg-2{
		width: 18%;
		margin-top: 30px;
	}
	label{
		margin-left: 20px;
		@include font-size(14);
	}
	.remember-my--settings{
		display:none !important;
		@include font-size(14);
    display: block;
    margin-top: 10px;
		margin-left: 5px;
    padding-left: 0;

		input[type='checkbox']{
			margin-right: 8px;
		}

    label {
      font-family: $font-family;
      font-weight: 400;
      margin-left: 0;
    }
	}
	.countrySelector{
		+ .selectize-control {
			.items,
			.selectize-dropdown {
				.flag {
					display: inline-block;
					margin-right: 7.5px;
				}
			}

			// displayed item flag
			.items .flag {
				$flagsize: 25px;
				@include flag-size($flagsize);
			}

			// dropdown options flag
			.selectize-dropdown .flag {
				display: none;
				$flagsize: 18px;
				@include flag-size($flagsize);
			}
		}
	}
}

@include respond-to(mid-screen-768-1200){
	#mab-header{
		.country-selector--container{
			display: none !important;
		}
	}
	.country-selector--container{
		width: 92%;
		display: none;
		clear: both;
		margin: 0 2% 0 6%;
		.col-lg-5{
			width: 90%;
			margin: 2% 5%;
			label{
				padding-left: 3%;
			}
		}
		.col-lg-2{
			width: 90%;
			margin: 3% 5% 2%;
			label{
				padding-left: 6%;
			}
		}
	}
}

@include respond-to(1200){
	.country-selector--container{
		display: none;
		position: relative;
		top: 0;
		box-shadow: none;
		background: none;
		padding: 0;
		.remember-my--settings{
			margin-bottom: 10px;
			@include font-size(14);
		}
	}
}

#country-selector--mobile {
  display: none;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    position: relative;
    z-index: 2;
    margin-top: 0;
    padding: 25px 20px 20px 20px;
    display: block;
    background-color: $seashell;
    float: left;
    width: 100%;

    .selected-country {
      width: calc(100% - 12px);
      float: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .selected-country--flag {
        margin-right: 8px;
        img {
          width: 24px;
          display: block;
        }
      }
      .selected-country--lang {
        @include font-size(14);
      }
    }

    .fa {
      width: 12px;
      text-align: right;
      float: right;
      margin-top: 5px;
    }
  }

  @include respond-to(tablet-portrait) {
    padding: 10px 16px !important;
  }
}

#country-selector--container--mobile {
  width: 100%;
  margin: 0;
  padding-top: 12px;
  display: block;
  clear: both;
  @include clear-bs-row;

  @media screen and (max-width: 1200px) {
    display: none;
    width: 100%;
    margin-top: 40px;
    // display: block;
    clear: both;
    > .row {
      margin-left: 0;
      margin-right: 0;
    }
    .col-lg-5 {
      margin-left: 0;
      // width: 40%;
      margin-right: 15px;
      //width: calc(41.66667% - 15px);
    }
    .col-lg-2 {
      margin-left: 0;
      button {
        margin-top: 28px;
      }
    }
  }

  @include respond-to(767) {
    margin-top: 20px;
    .col-lg-2 {
      width: 100%;
      button {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  .select-input,
  .submit-input {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .col-lg-2 {
    margin-left: 0;
  }
}
