.banner-container .content-holder.flip-clock {
  // @media (max-width: 767px) {
  // bottom: 0;
  // }
}

.countdown-timer {
  width: auto !important;
  @media screen and (max-width: 767px) 
  {
        display: flex;
        justify-content: center;
        align-items: center;
  }
}

@mixin flipClockScale($scale) {
  margin-top: #{15px * $scale};
  margin-bottom: #{15px * $scale};

  .offer-ends {
    @include font-size(16 * $scale);
    line-height: #{18px * $scale};
  }

  // clock sizing
  .flip-clock-divider {
    height: #{70px * $scale};

    &.hours .flip-clock-label {
      right: #{-69px * $scale};
    }
    &.minutes .flip-clock-label {
      right: #{-74px * $scale};
    }
    &.seconds .flip-clock-label {
      right: #{-76px * $scale};
    }
  }

  .flip-clock-label {
    @include font-size(10 * $scale);
    line-height: 1;
  }

  .flip-clock-wrapper {
    .inn {
      @include font-size(36 * $scale);
      line-height: #{45px * $scale};
    }
  }

  .flip {
    width: #{40px * $scale};
    height: #{45px * $scale};
    padding: 0;

    li,
    a {
      width: #{40px * $scale};
    }

    .up,
    .down,
    .shadow,
    .in {
      @include font-size(36 * $scale);
      width: #{40px * $scale};
    }
  }
}

.countdown-timer--container {
  @include flipClockScale(1);
  background-color: rgba($black, 0.5); // padding: 16px 20px 38px;
  display: inline-block;
  width: auto;
  position: relative;
  padding: 10px;

  .offer-ends {
    color: $white;
    text-transform: uppercase;
    font-family: $font-family-medium;
    text-align: center;
    margin-bottom: 5px;
  }

  .flip-clock-label {
    color: $white;
    text-transform: uppercase;
    top: initial;
    bottom: 0;
    font-family: $font-family-medium;
  }

  .flip-clock-wrapper {
    .inn {
      font-family: "Impact", Helvetica Neue, Helvetica, sans-serif;
    }
  }

  &.txt-gray {
    .offer-ends {
      color: $gray;
    }

    .flip-clock-divider {
      .flip-clock-label {
        color: $gray;
      }
    }
  }

  &.blue-bkground {
    background-color: rgba($countdownblue, 0.5);
  }

  &.white-bkground {
    background-color: rgba($white, 0.5);
  }

  &.gray-bkground { // this is brown lol
    background-color: rgba(36, 5, 0, 0.5);
  }

  &.red-bkground {
    background-color: rgba(208, 2, 27, 0.5);
  }

  @mixin flipColor($color, $bgcolor) {
    .flip-clock-wrapper {
      .inn {
        color: $color;
        text-shadow: 0 1px 2px $bgcolor;
        background-color: $bgcolor;
      }
    }
  }

  &.blue-flip {
    @include flipColor($white, $countdownblue);
  }

  &.violet-flip {
    @include flipColor($white, $countdownviolet);
  }

  &.yellow-flip {
    @include flipColor($white, $countdownyellow);
  }

  &.green-flip {
    @include flipColor($white, $countdowngreen);
  }
}

@include respond-to(1024) {
  .countdown-timer--container {
    @include flipClockScale(0.9);
  }
}

@include respond-to(991) {
  .countdown-timer--container {
    @include flipClockScale(0.85);
  }
}

@include respond-to(768) {
  .countdown-timer--container {
    @include flipClockScale(0.8);
  }
}

@include respond-to(640) {
  .countdown-timer--container {
    @include flipClockScale(0.8);
  }
}

@include respond-to(480) {
  .countdown-timer--container {
    @include flipClockScale(0.78);
  }
}

// iphone 6 plus
@include respond-to(413) {
  .countdown-timer--container {
    @include flipClockScale(0.72);
  }
}

// iphone 6
@include respond-to(375) {
  .countdown-timer--container {
    @include flipClockScale(0.69);
  }
}

@include respond-to(360) {
  .countdown-timer--container {
    @include flipClockScale(0.64);
  }
}



