/** MHolidays -- Form Styles */
.mholidays__content {
  $form-margin: 10px;
  .searchFlightHotel {
    @media screen and (max-width: 767px) {
      .form {
        margin:0 auto!important;
      }
    }    
    .form {
      margin: 0 15px;
      ::placeholder {
        color: $holidays-form-input-label;
        font-size: 10pt;
        opacity: 1;
        font-family: Gotham-Medium;
      }
      :-webkit-placeholder {
        color: $holidays-form-input-label;
        font-size: 10pt;
        opacity: 1;
        font-family: Gotham-Medium;
      }
      ::-webkit-placeholder {
        color: $holidays-form-input-label;
        font-size: 10pt;
        opacity: 1;
        font-family: Gotham-Medium;
      }
      :-ms-input-placeholder {
       color: $holidays-form-input-label;
       font-size: 10pt;
       font-family: Gotham-Medium;
      }
      ::-ms-input-placeholder {
       color: $holidays-form-input-label;
       font-size: 10pt;
       font-family: Gotham-Medium;
      }
      .form-content {
        text-align:center;
      }
      .nav-pills.isdeals {
        @media screen and (min-width: 493px) and (max-width: 767px) {
          left: 0!important;
           top: -35px;
          width: 100%;
          padding-right: 18px;
          text-align: center;
        }
        @media screen and (max-width: 492px) {
          left: 0!important;
           top: -55px;
          width: 100%;
          text-align: justify;
          padding-right: 18px;
        }
        span.seperator_onoff
        {
          position: absolute;
          margin-left: 4px;
          @media screen and (max-width: 400px)
          {
           display: none;         
          }
          @media screen and (min-width: 500px) and (max-width: 768px)
          {
           display: none;         
          }
        }
      }
      .nav-pills {
        display: inline;
        text-align: justify; 
        margin-bottom: 0;
        left:40%;
        position: absolute;
        top: -18px;
        background-color: #fff;
        padding: 7px 7px;        
        border-top: 1px solid #999;
        border-radius: 6px;
        @media screen and (min-width: 768px) {
          left: 50%;
          transform: translate(-50%,-50%);
        }
        @media screen and (max-width: 767px) 
        {
          top: -35px;
          left: 0!important;
          width: 100%;
          text-align: center;
          padding-right: 18px;
        }
        span.seperator_onoff
        {
          position: absolute;
          margin-left: 4px;
        }
        li {
          display: inline-block;
          // padding:0 3px;
          margin: 0;
          float: none;
          &.seperator {
            padding: 0;
            display: inline-block!important;
          }
          &.seperatoronoff{
            padding: 0;
            display: inline-block;
            @media screen and (min-width:768px)
            {
              margin-left: 4px;
            }
          }
          &.active {
            a {
              border-bottom: 2px solid #ce3b7f;
              color: #0393a6;
              border-radius: 0;
            }
          }
          a {
            padding: 0 0 5px 0;
            background: none;
            font-size: 14px;
            font-weight: bold;
            @media (max-width:329px)
            {
              font-size: 11px;
              margin-left: -5px;
            }
            @media screen and (min-width: 330px) and (max-width: 390px)
            {
              font-size: 12px;
              // margin-left: -5px;
            }
          }
          .seperator,.seperatoronoff {
            color:#cfcfcf;
            font-size: 18px;
            top: -3px;
            display: inline-block;
            a
            {
              margin-left: 5px;
              @media screen and (max-width: 767px) 
              {
                margin-left: 5px;
                span
                {
                margin-left: 5px;
                }
              }
              &:hover,&:focus{
                text-decoration: none;

              }
          }
          }
        }
      }
      .tab-content {
        text-align: initial;
      }
      @media screen and (min-width:615px) and (max-width:767px) {
        .col-xs-12 {
          width: 50%!important;
          padding-left:10px!important;
          padding-right:10px!important;
        }
      }

      @media screen and (max-width: 767px) {
        .col-xs-12 {
          margin-bottom:10px!important;
        }
      }

      @media screen and (min-width:768px) and (max-width:1199px) {
        div[class*=col-sm-],
        div[class*=col-md-] {
          width:33.33333%!important;
        }
      }

      @media screen and (min-width:1200px) {
        div[class*=col-md-],
        div[class*=col-lg-] {
          width:16.66666%!important;
          padding-right:5px!important;
          padding-left:5px!important;
        }
      }

      .mb-0 {
        margin-bottom:0!important;
      }
      @media screen and (min-width: 768px) {
        .mt-md-29 {
          margin-top:29px!important;
        }
      }
      @media screen and (max-width: 1023px) {
        .form-group {
          &.dep-date,
          &.rtn-date,
          &.trv-info, &.cabin-info{
            display: none;
          }
        }
      }
      
      .form-group {
        margin-bottom:0!important;
        .control-label {
          font-size:0.9rem;
        }
        @media screen and (min-width: 769px) {
          .control-label {
            font-size:0.8rem;
          }
        }
        i {
          &.fa-calendar {
            position: absolute;
            right: 10%;
            top: 60%;
            color: #666;
            pointer-events: none;
          }
          @media screen and (min-width:1200px) {
            &.fa-calendar {
              right:15%;
            }
          }
          @media screen and (min-width:992px) and (max-width:1199px) {
            &.fa-calendar {
              right:12%;
            }
          }
          @media screen and (max-width:991px) {
            &.fa-calendar {
              right:12%;
            }         
          }
          @media screen and (max-width:767px) {
            &.fa-calendar {
              top: 30%!important;
            }
          }
          @media screen and (max-width:614px) {
            &.fa-calendar {
              right:10%!important;
            }          
          }
          @media screen and (max-width:525px) {
            &.fa-calendar {
              right: 23%!important;
            }
          }
          @media screen and (max-width:500px) {
            &.fa-calendar {
              right: 21%!important;
            }
          }
          @media screen and (max-width:475px) {
            &.fa-calendar {
              right: 19%!important;
            }
          }
          @media screen and (max-width:450px) {
            &.fa-calendar {
              right: 17%!important;
            }
          }
          @media screen and (max-width:425px) {
            &.fa-calendar {
              right: 15%!important;
            }
          }
          @media screen and (max-width:400px) {
            &.fa-calendar {
              right: 13%!important;
            }
          }
          @media screen and (max-width:325px) {
            &.fa-calendar {
              right: 7%!important;
            }
          }
        }
        @media screen and (min-width: 768px) {
          div[class*='col-md-'] {
            margin-bottom:5px!important;
          }
        }
        @media screen and (min-width:768px) and (max-width:991px) {
          .btn-lg,
          .mholidays-button {
            padding:7.5px 16px!important;            
          }
        }
        @media screen and (min-width:992px) {
          .mholidays-button {
            padding:12.5px 16px!important;
            border-radius: 35px;
          }
        }
        .has-error {
          input {
            border:1px solid $errorred;
          }
        }
      }
      @media screen and (max-width:767px) {
        input {
          &.whereTo, &.hotelInputClass,&.pickInputClass {
            height: 41px;
            max-width: 90%;
            margin: 0 auto;
          }
        }
      }
      input {
        &.whereTo, &.hotelInputClass,&.pickInputClass {
          box-shadow: none;
          border-radius: 25px;
          border: 2px solid #cfcfcf;
          padding: 12px 20px 15px;
          height: 51px;
        }
        &.hasDatepicker {
          padding:8px 15px!important;
          color: $holidays-form-field-selected-val;
          font-size:10pt;
          font-family:Gotham-Medium;
          height:40px;
          border-radius:25px;
          background-color:transparent;
          border: 2px solid $holidays-form-input-label;
        }
        &.departuretransferDate, &.returntransferDate  {
          font-family:Gotham,Arial;
        }
        &.has-empty-error {
          border:1px solid $errorred!important;
        }
        @media screen and (min-width:1200px) {
          &.hasDatepicker {
            font-size: 10pt;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        @media screen and (min-width:992px) {
          &.hasDatepicker {
            box-shadow:none;
            border-radius:25px;
            padding:14px 20px 13px;
            height:51px;
          }
        }
        @media screen and (max-width:991px) {
          &.hasDatepicker {
            padding:8px 15px!important;
            height:41px;
          }
        }
        @media screen and (max-width:767px) {
          &.hasDatepicker {
            max-width: 300px;
            margin: 0 auto;
          }
        }
        @media screen and (max-width:614px) {
          &.hasDatepicker {
            max-width:90%!important;
          }
        }
      }
      .selectize-input {
        color: $holidays-form-input-label;
        font-size: 14pt;
        font-family: Gotham-Medium;
        input {
          color: $holidays-form-input-label;
          font-size: 14pt;
          font-family: Gotham-Medium;
          height: auto !important;
          height: initial !important;
        }
        .parsley-errors-list {
          display: none;
        }
      }
    }
  }

  .form {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    .row {
      margin-left: $form-margin / -2;
      margin-right: $form-margin / -2;
    }

    .mandatory-text {
      color: $red;
      font-size: rem(14);
      line-height: 1;
      margin-bottom: 30px;
    }

    .notice {
      font-size: rem(14);
      font-family: $font-family;
      font-weight: 400;
      max-width: 900px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $holidays-form-color;
      &.notice--fieldset {
        margin-top: 0
      }
      &.notice--form-group {
        color: $gray;
      }
    }

    select {
      padding: 11px 20px 10px;
      border-radius: 50px;
      width: 80%;
      background-color: $white;
      border-color: $lightgray;
    }

    textarea {
      &.input-focused {
        padding-top: 21px;
      }
      &.form-control {
        border-radius: 30px;
      }
    }
    .radio,
    .radio-group,
    .checkbox,
    .checkbox-group {
      font-size: rem(14);
    }
    .radio,
    .checkbox {
      margin-bottom: $form-margin / 2;
      margin-top: $form-margin / 2;
    }
    .error-validation {
      border-bottom: 1px solid $errorred;
      margin:0 20px 10px;
      padding: 10px 0 0;
      display: none;
      width: calc(100% - 40px);
      position: relative;
      &:before {
        display: block;
        position: absolute;
        top: 10px;
        left: 15px;
        width: 14px;
        color: $red;
      }
      p {
        font-size: rem(14);
        margin-bottom: 0;
      }
      a {
        color: $red;
      }
      .validation--error-list {
        li {
          font-size: .8rem;
        }
      }
      .field-name {
        text-transform: capitalize;
        font-family: $font-family-medium;
      }
      .col-left {
        float:left;
        width:5%!important;
        text-align:left;
        margin:0;
      }
      .col-right {
        float:right;
        width:95%;
        margin:0;
        text-align:left;
      }
      @media screen and (max-width:767px) {
        .col-left {
          width:10%!important;
        }
        .col-right {
          width:90%!important;
        }        
      }
    }

    .error_msg {
      color: $red;
      font-size: rem(12);
      margin-top: 2px;
      display: none;
      width: 100%;
    }

    input + .error_msg,
    select + .error_msg,
    .selectize-control + .error_msg,
    .datepicker-icon + .error_msg,
    .phoneinput .error_msg,
    .bootstrap-filestyle + .error_msg {
      padding-left: 20px;
    }

    .has-error,
    .has-sel-error,
    [has-error] {
      .error_msg {
        display: inline-block;
        &.error--country-code {
          width: 40%;
        }
        &.error--phone-number {
          width: 60%;
        }
      }
      .label,
      label {
        color: $red !important;
        a {
          color: $red;
          text-decoration: underline;
        }
      }
    }
    .checkbox,
    .radio {
      .has-error {
        color: $red;
      }
    }
    @media screen and (max-width: 767px) {
      .selectize-control {
        max-width:300px!important;
        margin: 0 auto!important;
      }
    }  
    @media screen and (max-width: 614px) {
      .selectize-control {
        max-width:90%!important;
      }
    } 
    .selectize-control {
      padding: 0;
      border: 0;
      box-shadow: none;
      border-radius: 50px;    
      &.input-lg {
        height: 46px;
      }
      @media screen and (max-width: 767px) {
        height:auto!important;
      }
    
      .form-control {
        border: 0;
      }

      @media screen and (max-width: 991px) {
        .selectize-input {
          padding:8px 15px!important;
        }
      }

      .selectize-input {
        display: inline-block;
        float: left;
        border: 0;
        box-shadow: none;
        border-radius: 25px;
        border:2px solid $holidays-form-input-label;
        padding:12px 20px 15px;
        &.has-error {
          border: 1px solid $errorred;
        }
        &.input-active {
          &.dropdown-active {
            border: 1px solid $holidays-form-color;
            border-radius: 25px 25px 0 0;
            //margin-top: -1px;
          }
        }
    
        &.select-filled {
          padding: 22px 20px 5px;
        }
    
        .item {
          color: $holidays-form-field-selected-val;
          @include font-size(13);
          line-height: rem(18);
          font-size:10pt;
          font-family: Gotham-Medium;          
          white-space: nowrap; 
          width:93%; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media screen and (min-width:1200px) {
          .item {
            font-size:10pt;
          }
        }
        @media screen and (max-width: 767px) {
          .item {
            font-size:9pt;
          }
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          .item {
            font-size:10pt;
          }
        }
      }

      .selectize-dropdown {
        box-shadow: none;
        border-radius: 0 0 25px 25px;
        border: 1px solid $holidays-form-color;
        box-shadow: none;
        height: auto;
        @include font-size(13);
        padding: 0;
        overflow: hidden;
        [data-selectable]{
          @include font-size(13);
          padding: 8px 26px;
          &:last-child{
            border-bottom: 0;
          }
        }
    
        .optgroup-header {
          @include font-size(13);
          padding: 8px 15px;
          border-bottom: 1px solid $lightgray;
        }
    
        .option {
          color: $holidays-form-color;
        }
    
        .active {
          background-color: $holidays-form-color;
          color: $white;
        }
    
        .selectize-dropdown-content {
          max-height: 270px;    
          &::-webkit-scrollbar {
            width: 12px;
          }
    
          &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
              -webkit-border-radius: 0;
              border-radius: 0;
          }
    
          &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              background: $holidays-form-color;
              -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
          }
        }
    
        .optgroup {
          .optgroup-header {
            color: $darkgray;
            text-transform: uppercase;
            font-family: $font-family-medium;
            @include font-size(13);
          }
        }
    
        .selectize-dropdown-content{
          max-height: 255px;    
          @media screen and (max-width: 767px) {
            max-height: 240px;
          }
        }
      }
    
      &.single {
        .selectize-input {
          &:after {
            content: "\f107";
            font-family: 'FontAwesome';
            line-height: 2;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            background-color: $white;
            border: 0;
            @include font-size(16);
            color: $holidays-form-color;
          }
    
          &.dropdown-active {
            &:after {
              content: "\f106";
              font-family: 'FontAwesome';
              background-color: $white;
            }
          }
    
          &.has-error {
            &:after {
              color: $errorred;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .passenger-details {
        padding:8px 15px!important;
        max-width:300px;
        margin:0 auto!important;
        .fa-angle-down {
          top:4px !important;
        }
      }
      .passenger-details-selected {
        border: 1px solid $holidays-form-color !important;
        margin:-41px auto 0 auto!important;
        padding:8px 15px 15px!important;
      }
    }
    @media screen and (max-width:614px) {
      .passenger-details {
        max-width:90%!important;
      }
      .passenger-details-selected {
        max-width:90%!important;
      }
    }

    @media screen and (min-width:992px) {
      .passenger-details {
        height:51px!important;
      }
      .passenger-details-selected {
        margin-top:-51px!important;
      }
    }

    @media screen and (max-width:991px) {
      .passenger-details {
        height:41px!important;
        padding: 8px 15px!important;
        .fa-angle-down {
          top:6px!important;
        }
      }
    }
    .cabin-info{
      .selectize-control 
      {
        .selectize-input.select-filled
        {
          padding: 14px 20px;
          @media screen and (min-width: 992px)
            {
              height: 51px!important;
            }
        }
      }
    }

    .passenger-details {
      text-align: left;
      border:2px solid $holidays-form-input-label;
      height: auto;
      width: 100%;
      overflow: hidden;
      padding:14px 20px;
      position: relative;
      z-index: 7;
      cursor: pointer;
      -webkit-background-clip: padding-box;
      -webkit-border-radius: 25px;
      -moz-background-clip: padding-box;
      border-radius: 25px;
      background-clip: padding-box;
      .fa-angle-down {
        position: absolute;
        top: 11px;
        right: 20px;
        font-size: 18px;
        line-height: 27px;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        color: $holidays-form-color;
      }
      .title-caption {
        color: $holidays-form-field-selected-val;
        line-height: 19.5px;
        line-height: 1.125rem;
        font-size: 10pt;
        font-family: Gotham-Medium;
        .total-nos {
          padding-right:5px;
        }
      }
      @media screen and (min-width:1200px) {
        .title-caption {
          font-size:10pt;
        }
      }
      .total-passengers {
        position: relative;
        top: -2px;
        font-size: 14px;
        line-height: 21px;
        font-size: .875rem;
        line-height: 1.3125rem;
        color: $holidays-form-color;
        padding: 0 20px 0 0;
        display: none;
      }
      &.passenger-focused {
        .title-caption {
          padding: 3px 0 0;
        }
      }
    }

    .passenger-details-selected {
      text-align: left;
      z-index: 9;
      -webkit-background-clip: padding-box;
      -webkit-border-radius: 22px;
      -moz-background-clip: padding-box;
      border-radius: 22px;
      background-clip: padding-box;
      position: absolute;
      border: 1px solid $holidays-form-color;
      height: auto;
      overflow: hidden;
      margin-top: -42px;
      background: #fff;
      display: none;
      //width: 95%;
      width: 425px;
      padding: 8px 15px 15px;
      @media screen and (max-width: 767px) {
        .title-caption {
          font-size:10pt!important;
          padding: 5px 10px!important;
        }
        div[class*=col-md-] {
          margin:2px 0!important;
          label {
            font-size:10pt!important;
            span {
              &.help-text {
                font-size:8pt!important;
              }
            }
          }
        }
      }
      .title-caption {
        color:$holidays-form-field-selected-val;
        line-height:19.5px;
        line-height:1.125rem;
        font-size:10pt;
        font-family:Gotham-Medium;
        padding:2px 10px 8px 1px!important;
        border-bottom:1px solid $holidays-form-input-label;
        margin-bottom:10px;
        .total-nos {
          padding-right:5px;
        }
      }
      .passenger-limit-error {
        display:none;
        color:$red;
        font-size:9pt;
      }
      .travel-input {
        select {
          border-radius: 20px;
          padding: 5px 0 5px 10px;
          font-size: .8rem;
          width: 92px;
        }
      }
      .fa-angle-up {
        position: absolute;
        top: 11px;
        right: 20px;
        color: $holidays-form-color;
        font-size: 18px;
        line-height: 1.6875rem;
        cursor: pointer;
      }
      .total-passengers {
        padding: 0 20px 4px;
        font-size: .875rem;
        line-height: 1.3125rem;
        color: $holidays-form-color;
        border-bottom: 1px solid $holidays-form-input-label;
        position: relative;
        top: -2px;
      }
      div[class*=col-md-] {
        float: left;
        width:100%!important;
        label {
          .help-text {
            color:$holidays-form-field-selected-val;
            font-size:8pt;
            text-align: left;
            display: inline;
          }
        }
        input[type='number'],
        input[type='tel'] {
          max-width: 45px;
          text-align: center;
          padding-left: 5px;
        }
      }
      .col-md-12 {
        padding-left:5px!important;
        padding-right:5px!important;
      }
      .infant-error {
        display:none;
        color:$red;
        font-size:9pt;
      }
      .child-age-section {
        display:none;
        label {
          padding-left:5px!important;
          top: 15px;
          margin-bottom:0!important;
          margin-top: 5px;
          font-size:10px!important;
          color:#000!important;
        }
        .ages {
          display: block;
          margin-bottom:0;
          .age {
            display:block;
            padding-left: 5px;
            padding-right: 5px;
            @media screen and (min-width: 768px) {
              div[class*=col-md-3],
              div[class*=col-lg-3],
              div[class*=col-sm-3] {
                width: 40%!important;
              }
              div[class*=col-md-9],
              div[class*=col-lg-9],
              div[class*=col-sm-9] {
                width: 60%!important;
              }
            }
            @media screen and (max-width: 767px) {
              div[class*=col-md-3],
              div[class*=col-lg-3],
              div[class*=col-sm-3] {
                width: 35%!important;
              }
              div[class*=col-md-9],
              div[class*=col-lg-9],
              div[class*=col-sm-9] {
                width: 65%!important;
              }
            }
            div[class*=col-md-],
            div[class*=col-lg-],
            div[class*=col-sm-],
            div[class*=col-xs-] {
              padding-right: 5px;
              padding-left: 5px;
              label {
                font-size: 10pt!important;
                width:100%!important;
                color:$holidays-form-field-selected-val;
                margin: 0;
                padding-left:0!important;
                margin-top: 0;
              }
              select {
                margin-right: 2px;
                border-radius: 15px;
                padding: 5px 0 5px 10px;
                font-size: .8rem;
                min-width: 80px;
              }
              @media screen and (min-width: 768px) {
                select {
                  min-width: 95px;
                }
              }
            }
          }
          @media screen and (min-width: 768px) {
            .age {
              padding-right: 20px;
            }
          }
        }
      }
    }
    .passenger-info-error {
      border:1px solid $red;
    }
  }
  .has-error{
    border: 1px solid #ed1c24!important;
  }

    #tour
    {
      #tourInputWrap
      {
        width: 30%!important;
        @media screen and (max-width: 767px){
          width: 100%!important;;
        }
      }
    
      #depDate
      {
        width: 20%!important;
        @media screen and (max-width: 767px){
          width: 100%!important;;
        }
      }
      #rtnDate
      {
        width: 20%!important;
        @media screen and (max-width: 767px){
          width: 100%!important;;
        }
      }
  }

  #transfer
  {
    .payment-method-options
    {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-block;
      margin-bottom: 10px;
      li
      {
        margin-right: 15px;
        display: inline-block;
        font-size: .875rem;
      }
      input
      {
        margin-right: 5px;
      }
    }
  }
}

            @media screen and  (max-width: 390px)
            {
              #transfer-options
              {
                label
                {
                  font-size: 12px;
                }
              }
            }