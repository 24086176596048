button{
  &:focus{
    outline: 0 !important;
    border: 0 !important;
  }
}
.btn-primary{
  background: $blue !important;
  color: $white;
  padding: 15px 20px 15px;
  @include font-size(14);
  line-height: 1rem;
  width: 100%;
  text-transform: initial;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  @include border-radius(50px);
  // border: 1px solid $focusblue !important;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $focusblue;
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @include border-radius(50px);
  }
  &:hover,
  &:focus,
  &:active{
      background: $focusblue !important;
      color: white;
      &:before {
      -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 1;
    }
  }
  &.btn-disabled{
    background: $midgray;
  }
  &.small{
    padding: 8px 14px;
  }
}

.btn-primary--blueBkg{
  background: $blue !important;
  color: $white;
  padding: 15px 20px 15px;
  @include font-size(14);
  line-height: 1rem;
  width: 100%;
  text-transform: initial;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  @include border-radius(50px);
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @include border-radius(50px);
  }
  &:hover, &:focus, &:active{
      color: $blue;
      &:before {
      -webkit-transform: scale(2);
  transform: scale(2);
    }
  }
  &.btn-disabled{
    background: $midgray;
  }
  &.small{
    padding: 8px 14px;
  }
}


.btn-secondary{
  background: $white;
  color: $blue;
  padding: 15px 20px 15px;
  @include font-size(14);
  line-height: 1rem;
  width: 100%;
  text-transform: initial;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  @include border-radius(50px);
  border: 1px solid $focusblue !important;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $focusblue;
    border: none;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @include border-radius(50px);
  }
  &:hover, &:focus, &:active{
      color: white;
      border: 1px solid $focusblue !important;
      &:before {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
  }
  &.addBlueBkg{
    background: $focusblue !important;
  }
}

.btn-primary--violetBkg{
  background: $side-trip-violet !important;
  color: $white;
  padding: 15px 20px 15px;
  @include font-size(14);
  line-height: 1rem;
  width: 100%;
  text-transform: initial;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  @include border-radius(50px);
  // border: 1px solid $focusblue !important;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $focusblue;
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @include border-radius(50px);
  }
  &:hover,
  &:focus,
  &:active{
      background: $focusblue !important;
      color: white;
      &:before {
      -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 1;
    }
  }
  &.btn-disabled{
    background: $midgray;
  }
  &.small{
    padding: 8px 14px;
  }
}
