.newsletter-container{
  display: block;
  width: 100%;
  .newsletter-widget--holder, .newsletter-widget--success, .newsletter-widget--manage, .newsletter-widget--notsubscribed{
    margin: 30px 0 35px;
    .col-lg-2, .col-lg-3{
      padding: 0;
      margin: 0 10px 0;
      &.preferredCity{
        width: 22%;
        .selectize-control{
          .selectize-input{
            &.select-filled{
              padding: 22px 20px 5px 25px;
            }
          }
        }
      }
      &.newsletterEmail{
        width: 27%;
      }
    }
    h1{
      color: $countdownblue;
      text-transform: initial;
      @include font-size(48);
      text-align: center;
      margin-bottom: 5px;
    }
    p{
      text-align: center;
      color: $gray;
      @include font-size(14);
    }
    .newsletter-form{
      margin: 35px 0 0;
      .form-group{
        margin-bottom: 0;
      }
      .col-lg-3{
        padding: 0;
        margin: 0 10px 10px 0 !important;
        @include respond-to(ipad-portrait){
          width: 12%;
        }
      }
      .newsletterBtn {
        margin: 0;
        padding: 0;
      }
      @media screen and (max-width:767px) {
        .newsletterBtn {
          clear: both;
        }
      }
      .manageNewsletterBtn {
        clear:none!important;
      }
      .newsletterManageEmail{
        margin: 0 10px 0 0;
        padding: 0;
        @include respond-to(ipad-portrait){
          width: 50%;
        }
      }
      .newsletterBtn{
        @include respond-to(ipad-portrait){
          width: 24%;
        }
      }
      .airportname {
        font-weight: bold;   
      }
      .selectize-input {
        div {
          color: $blue; 
        }
      }
    }
    #newsletterTC{
      box-shadow: none;
      float: left;
      margin-left: 8px;
      margin-right: 10px;
      -webkit-appearance: checkbox !important;
      position: relative;
      @include respond-to(tablet-portrait){
        margin-top: 2px;
      }
      &.form-control{
        width: 20px;
        height: 20px;
        margin-top: 19px;
        padding: 0;
        &:focus{
          box-shadow: none;
        }
      }
    }
    .newsletterTandC{
      display: none;
      float: left;
      width: 100%;
      .container{
        width: 36%;
        margin: 0 32%;
      }
      label{
        @include font-size(14);
        float: left;
        padding: 7px 0 0 10px;
        width: 100%;
        a{
          color: $linkblue;
          &:hover{
            border-bottom: 1px dashed $blue;
          }
        }
      }
      .has-error{
        color: $errorred;
        a{
          color: $errorred;
        }
      }
    }
    .existingSubscriber{
      float: left;
      padding: 20px 0 20px 0;
      text-align: right;
      width: 32%;
      a{
        color: $linkblue;
        @include font-size(14);
        &:hover{
          border-bottom: 1px dashed $blue;
        }
      }
    }
    .notExistingSubscriber{
      float: left;
      padding: 20px 0px 20px 0;
      width: 32%;
      text-align: right;
      a{
        color: $linkblue;
        @include font-size(14);
        &:hover{
          border-bottom: 1px dashed $blue;
        }
      }
      @include respond-to(ipad-portrait){
        width: 35%;
        text-align: left;
      }
    }
    .email-details{
      text-align: center;
      width: 100%;
      @include font-size(14);
      a{
        color: #006DBB;
        @include break-word();
      }
      span{
        display: block;
        margin: 15px 0;
      }
    }
  }
  .newsletter-widget--success, .newsletter-widget--manage{
    display: none;
  }
  .newsletter-widget--manage{
    .booking-form--title{
      left: 25px;
      &.input-filled{
        left: 26px;
      }
    }
  }
  .booking-form--title{
    position: absolute;
    left: 24px;
    top: 14px;
    color: $darkgray;
    @include font-size(14);
    z-index: 8;
    pointer-events: none;
    display: block;
    max-width: initial;
    margin-bottom: initial;
    font-weight: initial;
    -webkit-transition: all 90ms linear;
    -o-transition: all 90ms linear;
    transition: all 90ms linear;
    &.input-filled{
      top: 6px;
      left: 27px;
      @include font-size(12);
    }
    &.has-error{
      color: $errorred;
    }
  }
  #newsletterEmail, #newsletterManageEmail{
      height: 47px;
    color: $blue;
    @include font-size(14);
    display: block;
    max-width: initial;
      margin-bottom: initial;
      font-weight: initial;
      &.input-focused{
      padding: 21px 20px 4px 25px;
    }
    &.has-error{
      border: 1px solid $errorred;
    }
    &:focus{
      border: 1px solid $focusblue;
      box-shadow: none;
    }
  }
  button{
    &#newsletterSubmit, &#newsletterResend, &#newsletterManageSubmit{
      background: $linkblue;
      color: $white;
      width: 100%;
      text-transform: initial;
      @include font-size(14);
      padding: 12px 20px 14px;
      border: 0;
      &:hover{
        background: $blue;
      }
      &:focus{
        box-shadow: none;
        border: 0;
      }
    }
    &#newsletterResend{
      width: 10%;
      margin: 25px 45%;
    }
  }
  .newsletter-error--validation, .manage-error--validation{
    border-bottom: 1px solid $errorred;
    margin: 0 15px 15px 0;
    padding: 10px 0;
    display: none;
    .fa{
      color: $errorred;
      @include font-size(16);
      float: left;
    }
    .col-lg-1{
      padding-right: 0;
      width: 4%;
    }
    .col-lg-11{
      padding-left: 0;
    }
    .error-msgs{
      span{
        @include font-size(14);
        color: $gray;
        display: none;
      }
    }
    #result{
      @include font-size(14);
      color: $gray;
    }
    #newsletter-validator{
      margin: 2px 0 0 6px;
      @include respond-to(ipad-portrait){
        margin: 2px 0 0 10px;
        @include font-size(13);
      }
    }
  }
  .newsletter-error--validation{
    width: 66%;
    @include respond-to(ipad-portrait){
      width: 92%;
      margin: 0 4% 20px 4%;
    }
  }

  .manage-error--validation{
    width: 58%;
    .col-lg-11{
      padding-left: 10px;
      @include respond-to(ipad-portrait){
        padding-left: 15px;
        @include font-size(13);
        line-height: 2em;
      }
    }
    @include respond-to(ipad-portrait){
      width: 75%;
    }
  } 
}

@media (max-width: 1200px) and (min-width: 921px){
  .newsletter-container{
    .newsletter-widget--holder{
      .col-lg-2{
        width: 5%;
        &.newsletterBtn{
          width: 12% !important;
          clear: none;
        }
      }
      .col-lg-3{
        width: 36% !important;
      }
      .col-lg-4{
        width: 42% !important;
      }
    }
  }
}
@media (max-width: 920px) and (min-width: 768px){
  .newsletter-container{
    .newsletter-widget--holder{
      .col-lg-2{
        width: 1%;
        &.newsletterBtn{
          width: 18% !important;
          clear: none;
        }
      }
      .col-lg-3{
        width: 36% !important;
      }
      .col-lg-4{
        width: 51% !important;
        &.existingSubscriber{
          width: 40% !important;
        }
      }
    }
  }
}

@include respond-to(tablet-portrait){
  .newsletter-container{
    padding: 5% 3%;
    .newsletter-widget--holder{
      width: 90%;
      margin: 0 5%;
      .existingSubscriber{
        padding: 0 0 0 18px;
        text-align: left;
      }
    }
    .newsletter-error--validation{
      width: 100%;
      .col-lg-1{
        width: 8%;
      }
      .col-lg-11{
        padding-left: 15px;
      }
      #newsletter-validator{
        margin: 0 0 0 25px;
      }
    }
    .col-lg-3, .col-lg-4{
      width: 100% !important;
      margin-bottom: 15px !important;
      height: auto;
      .newsletterTandC{
        margin-bottom: 0;
      }
    }
    .newsletter-widget--holder, .newsletter-widget--success, .newsletter-widget--manage{
      .newsletter-form{
        margin: 20px 0 0 0;
      }
    }
    .col-lg-5{
      width: 100% !important;
      margin-bottom: 15px !important;
      height: auto;
      .newsletterTandC{
        margin-bottom: 0;
      }
    }
  }
  .newsletter-widget--manage{
    width: 90%;
    margin: 0 5% !important;
    .existingSubscriber{
      padding: 0 0 0 18px;
      text-align: left;
    }
    .col-lg-3{
      display: none !important;
    }
    .notExistingSubscriber{
      padding: 0 20px 0 0 !important;
    }
  }
  .manage-error--validation{
    width: 100% !important;
    .col-lg-1{
      width: 8% !important;
    }
    .col-lg-11{
      padding-left: 15px !important;
    }
    #newsletter-validator--manage{
      margin: 0 0 0 25px;
    }
  }
  .newsletter-widget--success{
    width: 90%;
    margin: 0 5% !important;
    padding: 3% 0;
    h1{
      line-height: 1em !important;
    }
    button{
      &#newsletterResend{
        width: 40%;
        margin: 25px 30%;
      }
    }
  }
}

@include respond-to(414){
  .newsletter-container{
    h1{
      font-size: rem(36) !important;
      line-height: 1.2em !important;
    }
    .newsletter-widget--holder{
      .newsletterTandC{
        label{
          padding: 10px 0 0 10px;
          @include font-size(13);
          a{
            @include font-size(13);
          }
        }
      }
    }
  }
  .newsletter-error--validation, .manage-error--validation{
    .error-msgs{
      @include font-size(13);
    }
  }
}

@include respond-to(374){
  .newsletter-container{
    .newsletter-widget--holder{
      .newsletterTandC{
        label{
          padding: 10px 0 0 10px;
          @include font-size(13);
          a{
            @include font-size(13);
          }
        }
      }
    }
  }
}
