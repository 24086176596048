#member-profile-add
{
	label{
		position: absolute !important;
    	margin-top: -10px !important;
   		background-color: white !important;
    	margin-left: 20px !important;
    	padding-left: 4px !important;
		padding-right: 4px !important;
	}
	select{
		border-radius: 10px 10px 10px 10px;
	}
	.selectize-input{
		border-radius: 10px 10px 10px 10px;
	}
	input{
		border-radius: 10px 10px 10px 10px;
	}
	.distance-margin
	{
		margin-top: 15px;
	}
	.btn-secondary{
		margin-left: 10px;
		border-radius: 10px 10px 10px 10px;
	}
	.btn-primary{
		margin-left: 65px;
		border-radius: 10px 10px 10px 10px;
	}
}
