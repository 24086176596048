/* Error Validation */

.error-validation {
  border-bottom: 1px solid $errorred;
  margin: 20px 20px 0;
  padding: 10px 0;
  display: none;
  width: calc(100% - 40px);
  .fa {
    color: $errorred;
    @include font-size(16);
    float: left;
  }
  .col-lg-1 {
    padding-right: 0;
    padding-left: 0;
    width: 30px;
  }
  .col-lg-11 {
    padding-left: 0;
    width: calc(100% - 30px);
  }
  .error-msgs {
    @include font-size(14);
    color: $errorred;
    span {
      @include font-size(14);
      color: $errorred;
      display: none;
    }
  }
  /* MABUAT1-72 */
  .validation-content span {
    display: inline;

    &:not(:first-child) {
      text-transform: l owercase;
    }
    &:not(:nth-last-child(2)):not(:last-child):after {
      content: ', ';
    }

    &:not(:first-child):last-child:before {
      content: ' & ';
    }
    &:last-child:after {
      content: '.';
    }
  }
  #result {
    @include font-size(14);
    color: $gray;
  }
}

.validation--error-list {
  padding-left: 17px;
  margin-bottom: 0;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    padding-left: 0;
    list-style-type: none;
  }

  li {
    width: 100%;
    color: $errorred;
    line-height: 1.3125rem;

    @media screen and (max-width: 414px) {
      font-size: rem(13);
    }
  }
}

#check-in--container,
#flight-status--container,
#login-container,
#mob-checkin--container,
#mob-flightstatus--container,
#ibe-stay--container,
#mob-stay--container,
#mhupgrade-in--container,
#mob-flightupgrade--container,
#mob-mhupgrade--container {
  .checkin-bookingref--error---validation,
  .checkin-eticketnos--error---validation,
  .status-cities--error---validation,
  .status-flightno--error---validation,
  .login-error---validation,
  .mhupgrade-error---validation {
    border-bottom: 1px solid $errorred;
    margin: 0 20px 20px;
    padding: 10px 0;
    display: none;
    width: 100%;

    .fa {
      color: $errorred;
      @include font-size(16);
      float: left;
    }

    .col-lg-1 {
      padding-left: 0;
      width: 3%;
    }

    .col-lg-11 {
      padding-left: 0;
    }

    .error-msgs {
      @include font-size(14);
      color: $gray;
      span {
        @include font-size(14);
        color: $gray;
        display: none;
      }
    }

    #result {
      @include font-size(14);
      color: $gray;
    }

    #checking-bookingref--validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }

  .login-error---validation,
  .checkin-bookingref--error---validation,
  .checkin-eticketnos--error---validation {
    margin: 0 20px 20px 0;
    .col-lg-1 {
      width: 30px !important;
    }

    .error-msgs {
      line-height: rem(30) !important;
    }
  }

  .checkin-eticketnos--error---validation {
    width: calc(100% - 35px) !important;
  }

  .status-cities--error---validation,
  .status-flightno--error---validation {
    margin: 0 20px 20px 0;
    width: calc(100% - 40px);
    @include respond-to(tablet-portrait){
      width: 100%;
    }
    .col-lg-1 {
      width: 30px !important;
      margin-left: 0;
    }
    .error-msgs {
      line-height: rem(26) !important;
    }
  }

  .mhupgrade-error---validation {
    margin: 0 5px 10px !important;
    width: 88% !important;
    .error-msgs {
      padding: 3px 0 0 10px;
    }
  }
}

#view-booking--container,#view-nfs--container, #view-upgrade {
  .viewbooking-error--validation {
    border-bottom: 1px solid $errorred;
    margin: 0 18px 15px;
    padding: 0 0 10px;
    display: none;
    width: calc(100% - 35px);

    @include respond-to(mid-screen-768-1200) {
    }

    @include respond-to(ipad-landscape) {
      margin: 0 1.5% 1.5% 2%;
    }

    .fa {
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1 {
      padding-left: 0;
      padding-right: 0;
      width: 30px;
      .fa {
        color: $errorred;
      }
    }
    .col-lg-11 {
      padding-left: 0;
    }
    .error-msgs {
      @include font-size(14);
      color: $gray;
      ul {
        padding-left: 17px;
        li {
          color: $errorred;
        }
      }
    }
    #result {
      @include font-size(14);
      color: $gray;
      line-height: 1em;
    }
    #viewbooking-validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }
}

#mob-viewbooking--container,#mob-nfs--container,#mob-flightupgrade--container {
  .viewbooking-error--validation {
    border-bottom: 1px solid $errorred;
    margin: 0 0 20px 0;
    padding: 10px 0;
    display: none;
    width: 100%;
    .fa {
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1 {
      padding: 0;
      width: 31px;
      float: left;
      .fa {
        @include font-size(18);
        color: $errorred;
      }
    }
    .col-lg-11 {
      padding-left: 0;
      width: calc(100% - 30px);
    }
    #result {
      @include font-size(14);
      color: $gray;
    }
    #viewbooking-validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  #check-in--container,
  #login-container {
    .checkin-eticketnos--error---validation {
    }
  }

  #flight-status--container {
    .status-cities--error---validation,
    .status-flightno--error---validation {
      width: 95%;
    }
  }

  .ibe-form--container {
    // .error-validation {
    //   width: 90%;
    //   margin: 1.5% 4% 0;
    // }
  }
}

@include respond-to(ipad-landscape) {
  #check-in--container {
    .checkin-bookingref--error---validation,
    .checkin-eticketnos--error---validation {
      margin: 0 1.5% 1.5% 0;
    }
  }

  #flight-status--container {
    .status-cities--error---validation,
    .status-flightno--error---validation {
      margin: 0 1.5% 1.5% 0;
    }
  }

  .ibe-form--container {
    .error-validation {
      width: 93%;
      margin: 1.5% 3% 0;
    }
  }
}

@include respond-to(414) {
  $mainnavheight: 68px;
  .ibe-form--container {
    .error-validation {
      margin: 0;
      margin-bottom: 20px;
      width: 100%;
    }
    .error-msgs {
      margin-left: 12% !important;
      @include font-size(14);
    }
    .error-container {
      @include font-size(14);
    }
  }
}
