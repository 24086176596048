.member-profile-update
{
    background-color: white;
    padding-left: 17px;
    @media (max-width: 767px)
    {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.lcp-update-profile--submit
{   
    @media (min-width: 767px) {
        &.personal-section {
            margin-left: 35px;
        }
    }
    #claim-others-submit-edit, #mob-claim-others-submit-edit {
        &.btn-primary {
            border-radius: 25px;
            width: 193px;
            margin-bottom: 20px;
        }
    }
    @media (min-width: 767px)
    {
        .col-xs-12
            {
                padding-left: 0px !important;
            }   
    }
    @media (max-width: 768px)
    {
        .col-xs-12
            {
                padding-left: 0px;
                padding-right: 0px;
            }   
    }
    #claim-others-submit, #mob-claim-others-submit-edit
    {
        @media (min-width: 767px)
    {
        margin: 18px;
        margin-left: 0px;
    }
        margin-bottom: 15px;
        border-radius: 10px;
        text-align: center;
        margin-top: 25px;
        width: 300px !important;
        border: 1px solid;
        background: beige;
        background: #14467b!important;
        color: #fff;
        padding: 15px 20px;
        font-size: 14px;
        line-height: 21px;
        font-size: .875rem;
        line-height: 1.3125rem;
        line-height: 1rem;
        text-transform: none;
        border: 0;
        display: inline-block;
        margin-bottom: 70px;
        @media (max-width: 767px)
        {
            margin-bottom: 0px;
            width: 100% !important;
        }
    }
}

#lcp_update_profile_members
{
    .tab_descrp	{
        font-family: 'Gotham-Book', Arial;
        font-size: 13px;    
        @media (max-width: 767px)
        {
            font-size: 11px;    
        }
    }

    h5
    {
        color: #006dbb;
        font-size: 36px;
        padding-top: 10px;
        @media (max-width: 767px)
        {
            font-size: 30px;
            padding-top: 10px;
        }
    }
    .update_tab
    {
        @media (min-width: 768px)
        {
            .col-lg-3
            {
                margin-right: 3px !important;
            }
        }
        margin-top: 20px;

        li.active
        {
            a
            {
                color: #fff;
                background-color: #14467B;
                // border: 1px solid;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                // border: 1px solid #14467B;;
                text-align: center;
                @media (max-width: 767px)
                {
                    font-size: 9px;
                    height: 53px;
                }
            }
            
        }
        a
        {
            background-color: #f1f1f1;
            color: #19191b;
            border-top: -10px;
            border-radius: 12px 12px 0px 0px;
            text-align: center;
            @media (max-width: 767px)
                {
                    height: 53px;
                    font-size: 9px;
                    padding-top: 14px;
                    text-align: center;
                }
        }
        a:hover
            {
                color: #fff;
                border: 1px solid;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                // border: 1px dashed #0158a7;
            }
        .col-lg-3 {
            width: 24%;
            padding: 0px;
            margin: -1px;
        }
        @media (max-width: 767px)
        {
            .col-lg-3 {
                width: 24%;
                margin-right: 4px;
            }
        }
    }
}

#lcp--profile--update {
    padding-top: 0px;
    .update-profile-add-desk
    {
        .subtitle
        {
        color: #006dbb!important;       
        }
    }
    .update-profile--gender .selectize-input.disabled.locked:after{
        background-color: #eee;
    }
    .update-profile-contactno
    {
        color: #006dbb!important;
        margin-bottom: 0px;
        span
        {
            margin-inline-start: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #82868d;
        }
    }
    @media (min-width: 768px)
    {
        .col-sm-5 {
            width: 44%;
        }
        .col-sm-10
        {
            width: 88%;
        }
        .contact-information,.update-profile-add-desk,.travel-info{
            margin-right: 150px;
        }
    }
    .travel-info
    {
        .subtitle
        {
            color: #006dbb;
        }
    }
    .update-profile--nric-number
    {
        label,
        .label {
          &:after {
            content: '*';
            font-family: $font-family;
          }
          &:hover {
            cursor: pointer;
          }
        }
    }
    .progress_desp
    {
        font: normal normal 14px/24px Gotham, Arial;
        color: #006dbb;
    }
    .progress{
        position: relative;
        margin: 4px;
        float:left;
        text-align: center;
      }
      .barOverflow{ /* Wraps the rotating .bar */
        position: relative;
        overflow: hidden; /* Comment this line to understand the trick */
        width: 90px; height: 45px; /* Half circle (overflow) */
      }
      .bar{
        position: absolute;
        top: 0; left: 0;
        width: 90px; height: 90px; /* full circle! */
        border-radius: 50%;
        box-sizing: border-box;
        border: 11px solid #c5d6f5;     /* half gray, */
        border-bottom-color: #006dca;  /* half azure */
        border-right-color:#006dca;
      }
    
      .bar::after{
        position: absolute;
        top: 0; left: 0;
        width: 90px; height: 90px; /* full circle! */
        border-radius: 50%;
        box-sizing: border-box;
        border: 12px solid #eee;     /* half gray, */
        border-bottom-color: #0bf;  /* half azure */
        border-right-color: #0bf;
      }
      .lines > li {
        list-style: none;
        height: 4px;
        background-color: white;
        width: 125px;
        position: absolute;
        /* left: 37px; */
        /* top: calc(50% - 2px); */
        z-index: 9;
      }
      
      .lines > li:nth-child(1) {
        transform: rotate(0deg);
      }
      .lines > li:nth-child(2) {
        transform: rotate(36deg);
      }
      .lines > li:nth-child(3) {
        transform: rotate(-36deg);
      }
      .lines > li:nth-child(4) {
        transform: rotate(72deg);
      }
    .lines > li:nth-child(5) {
      transform: rotate(-72deg);
    }
    .lines {
        margin-top: 45px;
        position: absolute;
        margin-left: -56px;
      }
      @media (max-width: 767px)
      {
        .circle_progreesive
        {
            padding-left: 100px;
            padding-right: 100px;
        }
      }
    @media (min-width: 768px)
    {
        .circle_progreesive
        {
            float: right;
            height: 5px;
        }
        .progress
        {
            padding: 12px;
            width: 115px;
            height: 115px;
            border: 1px dashed #006dcabf;
            border-radius: 55px;
            margin-top: 100px;
            color: #006dcabf;
            font-size: 16px;
        }
    }
    @media (max-width: 767px)
    {
        .progress
        {
            padding: 12px;
            width: 115px;
            height: 115px;
            border: 1px dashed #006dcabf;
            border-radius: 55px;
            color: #006dcabf;
            font-size: 16px;
        }
    }
    .update-profile--personal-contact-numbers,.update-address-item-desk,.update-profile-add-mob
    {
        @media (min-width: 768px)
        {
            .col-sm-3 {
                width: 29%;
            }
            margin-top: 15px;
        }

    }
    .title {
        position: relative;
     margin: 0;
     font-family: $font-family;
     color: $blue;
     line-height: initial;
     margin-bottom: 20px;
     @media screen and (max-width: 767px) {
       text-align: center;
     }
    }
    .description {
     font: normal normal 14px/20px Gotham, Arial;
         color: #61666f;
         p {
             margin-left: 6px;
         }
    }
    .radio {
      label {
      color: #61666f;
      }
    }
    // .subtitle {
    // //   font: normal bold 18px/20px Gotham, Arial !important;
    // //   color: #61666f !important;     
    // //   padding-top: 30px;
    // //   padding-bottom: 10px;
    //   }
      .subtitle-mobile {
        font-size: 1rem;
        line-height: inherit;
        font-weight: 600 !important;
        font-family: Gotham-Medium,Arial;
        //  font: normal bold 18px/20px Gotham, Arial !important;
        color: #006dbb; 
        position: relative;
        margin-left: 0px;
        padding-left: 0px;
        padding-bottom: 30px;
        margin-top: 17px;
         @media screen and (max-width: 767px) {
            // font: normal 700 13px Gotham,Arial!important;
          }
     }
     .rightarrow {
         font-size: 26px;
         float: right;
         line-height: 25px;
         font-weight: bolder;
         margin-top: 17px;
         margin-right: 17px;
         color: #006dbb;
     }
     .update-address-item-mob, .update-interest-item-mob {
     i.fa {
       -webkit-transition: 0.2s all ease-in-out;
       -o-transition: 0.2s all ease-in-out;
       transition: 0.2s all ease-in-out;
 
       float: right;
       line-height: rem(22);
     }
 
     > .address__menu {
       margin-bottom: 10px;
 
       @media screen and (max-width: 767px) {
         margin-bottom: 0;
       }
     }
 
     .update-address-link.collapsed {
       i.fa {
         -webkit-transform:  rotate(-90deg);
         -ms-transform:  rotate(-90deg);
                 transform:  rotate(-90deg);
                 margin-top: 17px;	
                 margin-right: 17px;					
       }
     }
   }
    label{
           position: absolute;
         margin-top: -10px;
            background-color: white;
         margin-left: 20px;
         padding-left: 4px;
           padding-right: 4px;
     }
     
     input{
         border-radius: 10px 10px 10px 10px;
     }
     .checkboxlabel {
         position: relative;
         margin-left: 0px;
         font: normal normal 14px/16px Gotham, Arial;
         span {
             margin-left: 10px;
         }
         @media screen and (max-width: 767px) {
             margin-left: 2px;
             span {
                 margin-left: 5px;
             }
         }
         @media screen and (min-width: 768px) and (max-width: 979px) {
             margin-left: 2px;
             span {
                 margin-left: 5px;
             }
         }
         
     }
     .profilecheckbox {
         border: 1px solid #ccc;
     border-radius: 10px;
     padding-top: 15px;
         padding-bottom: 15px;
         color:#61666f;
         box-sizing: border-box !important;		
         cursor: pointer;	
         margin-top: 5px;	
         margin-bottom: 5px;
     }
     .profilecheckboxInterests {
         border: 1px solid #ccc;
     border-radius: 10px;
     padding-top: 15px;
         padding-bottom: 15px;
         color:#61666f;
         box-sizing: border-box !important;
         cursor: pointer;	
         margin-top: 5px;	
         margin-bottom: 5px;		
         @media screen and (max-width: 767px) {
             height: 63px;		
         }
         @media screen and (min-width: 768px) and (max-width: 979px) {
             height: 63px;			
         }
     }
     .ischecked {
         border: 1px solid #006dbb;
     }
     .distance-margin
     {
         margin-top: 15px;
     }
     .distance-padding
     {
         padding-bottom: 15px;
     }
     .distance-padding-mobile
     {
         @media screen and (max-width: 767px) {
             padding-bottom: 15px;
         }
     }
     .btn-secondary{
         margin-left: 10px;
         border-radius: 10px 10px 10px 10px;
     }
     .btn-primary{		
         border-radius: 10px 10px 10px 10px;
     }
     
     .passportinfo	{
        font: normal normal 13px/16px Gotham,Arial;
        color: #006dbb;
        font-weight: 600;
    }
    .passportinfomat	{
        color: #121313;
        // margin-left: 10px;
        font-family: 'Gotham-Book', Arial;
        font-size: 13px !important; 
        @media screen and (max-width: 767px) {
            font-size: 11px !important; 
        }
    }
     .selectize-control{	
             border-radius: 10px;
             .selectize-input {			
                 border-radius: 10px;		
                 &.input-active {
                     &.dropdown-active {			
                         border-radius: 10px 10px 0 0;					
                     }
                 }			
             }
             .selectize-dropdown {		
                 border-radius: 0 0 10px 10px;	
         }	
     }
     .phoneinput {
         $countrycode-width: 100%;
         $mob-countrycode-width: 100%;
         &__countrycode,
         &__phonenumber {
           display: block;
           float: left;
         }  
         &__countrycode {
           width: $countrycode-width;
   
           @media screen and (max-width: 479px) {
             width: $mob-countrycode-width;
           }
   
           select,
           .selectize-control .selectize-input {
             border-top-right-radius: 10px;
             border-bottom-right-radius: 10px;
           }
         }
   
         &__phonenumber {
           width: 100%;
   
           @media screen and (max-width: 479px) {
             width: 100%;
           }
   
           input {
             border-top-left-radius: 10px !important;
             border-bottom-left-radius: 10px !important;
             border-left-width: 1px;
           }
         }
         }	
         input[type=checkbox] {
             display:none;
         }
         input[type=checkbox] + label
          {				
                  height: 12px;				
                  position: relative;		 
                  display:inline-block;				
                  background: url("../images/uncheck.svg") 0 3px no-repeat;     
                  padding: 0 0 0 21px;
                  margin-top: 0px;
                  margin-left: 11px;
                  margin-bottom: 15px;
                  top: 9px;				
                  @media screen and (max-width: 767px) {
                     margin-left: 4px;
                  }
                  @media screen and (min-width: 768px) and (max-width: 979px) {
                     margin-left: 4px;
                  }
          }
          
          input[type=checkbox]:checked + label
             {
                     background: url("../images/check.svg") 0 3px no-repeat;  
                     height: 12px;						
                     display:inline-block;
                     padding: 0 0 0 21px;
                     position: relative;
                     margin-top: 0px;
                     margin-left: 11px;
                     margin-bottom: 15px;
                     top: 9px;		
                     @media screen and (max-width: 767px) {
                         margin-left: 4px;
                      }
                      @media screen and (min-width: 768px) and (max-width: 979px) {
                         margin-left: 4px;
                      }				
             }		
             .btn-member--submit {
                 @media screen and (max-width: 767px) {
                     width: 100%;
                     margin-top: 20px;
                 }
             }			
 }
 #binfinte_partner
 {
    margin-left: -8px;
     .passportinfo-title
     {
        color: #0065b3;
        margin-left: 10px;
        font-size: 1rem !important;
        line-height: inherit;
        font-weight: 600;
        font-family: Gotham-Medium,Arial;
        margin-left: 10px;
     }
     .mob-marketing
     {
         margin-top: 15px;
     }
    .content_sl
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #14467B;
       
        @media screen and (min-width: 768px){
            width: 350px;
         }	
    }
    .content_enrich
    {
        background-color: #14467B;    
        @media screen and (min-width: 768px){
            width: 150px;
         }	
    }
    .content_enrichpart
    {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #14467B;
        @media screen and (min-width: 768px){
            width: 150px;
         }	
    }
    .content_email
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #e8eff3;

        @media screen and (min-width: 768px){
            text-align: left;
            padding-left: 30px;
         }	
    }
    .content_email-enrich
    {
        background-color: #e8eff3;
    }
    .content_email-enrichpart
    {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #e8eff3;
    }
    .content_sms
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #f3f4f5;
        @media screen and (min-width: 768px){
            text-align: left;
            padding-left: 30px;
         }	
    }
    .content_sms-enrich
    {
        background-color: #f3f4f5;
    }
    .content_sms-enrichpart
    {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #f3f4f5;
    }
        .passportinfo {
            font: normal normal 14px/16px Gotham, Arial;
            color: #f2f4f5;
            margin-left: 10px;
            height: 57px;
        }


        input[type="checkbox"] {
            position: relative;
            width: 50px;
            height: 20px;
            background: #fff;
            -webkit-appearance: none;
            border-radius: 20px;
            outline: none;
            transition: .4s;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            border: 1px solid #14467B;
        }
        .ischecked label{
            color: #fff;
        }

        input[type="checkbox"]:checked {
            background: #14467B;
        }
        input[type="checkbox"]:checked::before {
            background: #fff;
        }
        input[type="checkbox"]::before {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0px;
            bottom: 1px;
            width: 20px;
            height: 17px;
            background: #14467b;
            border-radius: 50%;
            transform: scale(1.1);
            box-shadow: 0 2px 5px rgba(0,0,0,.2);
            transition: .4s;
        }

        input:checked[type="checkbox"]::before {
            left: 28px;
        }

        .toggle {
            position: relative;
            display: inline;
        }
        #update-profile--toggle-switch
        {
            label
            {
                color:white;
            }
            .ofbtn 
            {
                right: 6px;
            }

        }

        label {
            position: absolute;
            color: #14467B;
            font-weight: 600;
            font-size: 7px;
            pointer-events: none;
            margin-top: 5px;
            margin-left: 0px;
            @-moz-document url-prefix() {
                margin-top: -5px;            
            }
        }

        .onbtn {
            left: 7px;
            @media screen and(max-width:979px){
                bottom: -2px;
            }
        }

        .ofbtn {
            right: 7px;
            @media screen and(max-width:979px){
                bottom: -2px;
            }
        }
        #update-profile--toggle-switch{
            padding-left: 5rem;
            @media screen and (min-width:768px) and (max-width:979px){
                padding-left: 9rem;
                width: 20% !important;
            }
            @media screen and (max-width:767px){
                padding-left: 15px;
            }
        }
        th{
            font-weight: normal;
        }
        th + th{
            // padding-left: 12rem;
            padding-bottom: 10px;
            padding-top: 15px;
            @media screen and (max-width:767px){
                padding-left: 4rem;
            }
        }
        // thead{
        //     // border-bottom: 1px solid #ccc ;
        // }
        table,p{
            margin-left: 10px;
            color: #121313;
            font-family: Gotham-Book,Arial;
            font-size: 13px!important;
            @media screen and (max-width:767px){
                font-size: 11px!important;
            }

        }
    }

    #prefrence_lcp
    {
        padding-top: 0px;
        .passportinfomat
        {
            font-family: Gotham-Book,Arial;
            font-size: 13px!important;
            @media screen and (max-width:767px){
                font-size: 11px!important;
            }
        }
        @media screen and (max-width:767px){
            .update-profile-int-mob
            {
                margin-left: 0px;
            }
        }
        .datepicker-icon
        {
            right: 32px !important;
        }
        @media (max-width: 767px)
        {
            #prefrence--enrich-checkbox
            {
                margin-bottom: 50px;
            }
        }
        @media (min-width: 768px)
        {
            .redeem_miles
            {
                float: right;
                border-radius: 20px;
                border: 1px solid #ccc;
                padding: 8px 30px 30px 30px;
                font-size: 13px;
                margin-top: -30px;
                .row
                {
                    border-bottom: 1px solid;
                    margin-bottom: 10px;
                }
            }
            .update-profile--interest-preferences
            {
                margin-left: 0px;
            }
        }
        @media (max-width: 767px)
        {
            .update-profile--interest-preferences
            {
               .col-xs-12
               {
                padding-left: 0px;
                padding-right: 0px;
               }
            }
            .redeem_miles
            {
                .row
                {
                    .col-xs-6
                    {
                        text-align: center;
                    }
                }
                .row_sepatr
                {
                    margin: 10px;
                    border: 1px solid;
                }
            }

        }
        #redeem_now,#payment_now
        {
            font-size: 12px;
            padding: 5px;
            width: 125px;
            border-radius: 7px;    
            margin-left: 10px;   
        }
        @media (min-width: 768px)
        {
          
            .update_address
            {
                .col-lg-4
                {
                    width: 38%;
                }
                .col-lg-3
                {
                    width: 26.33333%;
                }
            }
        }
        @media (max-width: 768px)
        {
          
            .update_address
            {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        .prefe_address
        {
            border: 1px solid #d8c6c6;
            border-radius: 10px;
            span
            {
                margin-left: 10px;
            }
        }
        #preference-profile--address,#preference-profile--travel
        {
            // margin-top: 15px;
            label
            {
                position: absolute;
                margin-top: -10px;
                background-color: #fff;
                margin-left: 20px;
                padding-left: 4px;
                padding-right: 4px;
            }
            input,.selectize-input
            {
                border-radius: 10px 10px 10px 10px;
            }
        }
        #preference-profile--address
        {
            .col-sm-4
            {
                padding-bottom: 19px;
                padding-right: 19px;
                @media (max-width: 768px)
                {
                    padding-right: 0px;
                    padding-left: 1px;
                }
            }

            @media (min-width: 768px)
            {
                .col-lg-6
                {
                    width: 445px;
                }
                .col-lg-3
                {
                    width: 170px;
                }
            }

        }

        .prefrence_card
        {
            border: 1px solid #006dbb;
            margin-top: 30px;
            .ta_head
            {
                text-align: center;
            }
            .th_head
            {
                width: 200px;
            }
            thead
            {
                border: 1px solid #006dbb;
                background: #006dbb;
                th
                {
                    color: white;
                }
            }
            th
            {
                // width: 204px;
                    font-size: 15px;
                @media (max-width: 767px)
                {
                    font-size: 12px;
                }

                    font-weight: 300;
            }
            tr
            {
                border: 1px solid #006dbb;
            }
        }
        #request-card
        {
            margin: 18px;
            margin-bottom: 15px;
            border-radius: 10px;
            text-align: center;
            margin-top: 25px;
            width: 300px !important;
            border: 1px solid;
            background: beige;
            background: #005aaa!important;
            color: #fff;
            padding: 15px 20px;
            font-size: 14px;
            line-height: 21px;
            font-size: .875rem;
            line-height: 1.3125rem;
            line-height: 1rem;
            text-transform: none;
            border: 0;
            display: inline-block;
            margin-bottom: 70px;
            @media (max-width: 767px)
            {
                margin-bottom: 0px;
            }
        }
        @media (max-width: 767px)
            {
                #redeem_now,#payment_now
                {
                    margin: 18px;
                    margin-bottom: 15px;
                    border-radius: 10px;
                    text-align: center;
                    margin-top: 25px;
                    width: 300px !important;
                    border: 1px solid;
                    background: beige;
                    background: #005aaa!important;
                    color: #fff;
                    padding: 15px 20px;
                    font-size: 14px;
                    line-height: 21px;
                    font-size: .875rem;
                    line-height: 1.3125rem;
                    line-height: 1rem;
                    text-transform: none;
                    border: 0;
                    display: inline-block;
                    margin-bottom: 70px;
                    @media (max-width: 767px)
                    {
                        margin-bottom: 0px;
                    }
                }
            }
        .update-profile-int-desk,.update-profile-int-mob,.update-interest-item-mob
        {
            .row
            {
                margin-bottom: 70px;
            @media (max-width: 767px)
            {
                margin-left: 0px;
                margin-right: 0px;
            }
            }   
        }

        .col-sm-4
        {
            padding-right: 0px;
        }
        legend
        {
            border-bottom: 0px;
            color: #006dbb;
        }
        .checkboxlabel.ischecked
        {
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            color: #61666f;
            box-sizing: border-box!important;
            cursor: pointer;
            margin-top: 5px;
            margin-bottom: 5px;
            border: 1px solid;
            width: 96%;
            margin-top: 0px;
            margin-left: 0px;            
            @media (max-width: 767px)
            {
                width: 100%;
                margin-left: 0px;        
            }

                label:before {
                    font-family: FontAwesome;
                    content: "\f00c";
                    display: inline-block;
                    padding-right: 3px;
                    vertical-align: middle;
                 }
            
        }
        .checkboxlabel
        {
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            color: #61666f;
            box-sizing: border-box!important;
            cursor: pointer;
            margin-top: 5px;
            margin-bottom: 5px;
            border: 1px solid;
            width: 96%;
            margin-top: 0px;
            margin-left: 0px; 
            @media (max-width: 767px)
            {
                width: 100%;
                margin-left: 0px;        
            }

                label:before {
                    font-family: FontAwesome;
                    // content: "\f00c";
                    display: inline-block;
                    padding-right: 3px;
                    vertical-align: middle;
                 }
            
        }
        input[type=checkbox]
        {
            display: none;
        }

        // prefrecnce--enrich card
        .prefrence--heading-info{
            font: normal normal 14px/16px Gotham, Arial;
            color: #006dbb;
        }
        .prefrence--buttons{
            margin-top: 5rem;
            @media screen and(max-width: 767px) {
                margin-top: 3rem;
            }
        }
        .prefrence--description{
            @media screen and (max-width:767px){
                font-size: .875rem;
            }
        }
        .preference--card-image{
            margin-top: 20px;
            img{
            @media screen and (max-width:767px){
                width: 12rem !important;
            }
        }
        }
        #prefrence--save-card, #prefrence--print-card
        {
            border-radius: 10px !important;
            margin: 5px !important;
            border: 1px solid#006dbb !important;
            padding: 10px;
            @media screen and (max-width:767px){
                width: 7rem;
                font-size: .875rem;
                float:right;
            }
        }
        .btn-primary::before{
            background:auto !important;
        }
        .prefrence--mobile-link{
            color: #000;
            text-decoration: underline;
        }
        #prefrence--print-card{
            color: #006dbb !important;
            background-color: #fff !important;
        }
        #prefrence--enrich-card-name{
            label{
                margin-top: -10px ;
                margin-left: 10px;
                padding: 0px 10px;
                background-color: #fff;
            }
            input{
                border-radius: 10px;
            }
        }
        #prefrence--checkbox{
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 8px;
            margin: auto;
            padding-right: 20px;
            height:47px;
            @media screen and (max-width: 767px){
                padding-right: 5rem;
            }
            @media screen and (min-width:768px) and (max-width:979px){
                padding-right: 0;
            }
        }
        #preference--enrinch-input-checkbox{
            margin-right: 5px;
        }
        #preference--enrinch-input-checkbox + label
        {
            
            vertical-align: middle !important;
            margin-right: 3rem;
            color: #000;
            margin-top: 0px;
            @media screen and (max-width: 767px){
                margin-right: 2.7rem;               
            }
            @media screen and (min-width:768px) and (max-width:979px){
                margin-right: 2rem;
            }
        
        }
       #prefrence--enrich--checkbox{
       
           label{
            background-color: #fff;
            margin-left: 20px;
            padding-left: 4px;
            padding-right: 4px;
          
            }
            input{
            border-radius: 10px 10px 10px 10px;
            }
            
        }
      
    }

        #lcp-update-others-submit
        {
            border-radius: 10px;
        }



#co-brand_partners{
    .co-brand--roll-number{
       border-radius: 100%;
       border: 1px solid #006dbb;
       text-align: center;
       width: 50px;
       height: 50px;
       padding: 11px;
       color: #fff;
       background: #006dbb;
       margin-top: 15px;
       @media screen and (max-width: 767px) {
           margin-top: 17px;
           font-size: 17px;
           margin-left: 13px;
           width: 40px;
           height: 40px;
           padding: 6px;
       }


    }
    input{
      border-radius: 10px 10px 10px 10px ;
    }
    .co-brand--card-image{
       margin-top: 20px;
       img {
            width: 165px;
            height: 115px;
            @media screen and (max-width: 767px){
                width: 100%;
            }
       }
    }
    img{
        // width: 165px;
        // height: 115px;
        @media screen and (max-width: 767px){
            width: 100%;
        }
    }
    
    .co-brand--card-details{
       margin-top: 20px; 
       @media screen and (max-width: 767px) {
       padding-left: 15px;
       padding-right: 15px; 
       }
    }
    .co-brand{
       background: #ccc;
       width: 49rem;
       margin-left: -2px;
       border-radius: 20px;
       @media screen and (min-width: 768px) and (max-width: 979px) {
        width: 100%;
     }
     @media screen and (max-width: 767px) {
        width: 100%;
        }
    
    }
    
    .form {
        background-clip: padding-box;
        padding: 14px 20px 13px;
        height: 47px;
    }
    .form-group {
        z-index: 1;
        position: relative;
    }
   .form-control {
        color: #4e4e4e ; 
        background: #ccc ;
        font-size: 1.5rem;
        background-clip: padding-box;
        padding: 14px 20px 13px;
        height: 47px;
        border: 1px solid #949494;
        @media screen and (max-width: 767px) {
            font-size: .875rem;
        }
        }
    .row{
        margin-left: -2px;
        padding-left: 10px;
        margin-bottom: 20px;
        padding-bottom: 15px;
        @media screen and (max-width: 767px){
            padding-left: 0;
        }
    
        
    }
    label{
       position: absolute;
       margin-top: -10px;
       background-color: #ccc;
       margin-left: 1px;
       padding-left: 12px;
       padding-right: 15px; 
       @media screen and (max-width: 767px){
        font-size: .75rem;
    }
    }
    .click-text {
        margin-top: 45px;
        cursor: pointer;
    }
    .tap-text {
        text-align: center;
        cursor: pointer;
    }
    .i-pad-tap-text {
        margin-top: 45px;
        cursor: pointer;
    }
}  

#members-index-bodys
{
    @media (max-width: 768px)
    {
        padding-bottom: 15px;
        .container
        {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    @media (min-width: 768px)
    {
    float: right;
    margin-top: 67px;
    margin-right: 50px;
    }
    .miles-name {
        width: 105px;
        margin-top: 104px;
        font-size: 10px;
        position: absolute;
        margin-left: -2px;
    }

        .progress-bar {
            position: relative;
            height: 100px;
            width: 100px;
        }
        
        .progress-bar div {
            position: absolute;
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }
        
        .progress-bar div span {
            position: absolute;
            font-family: Arial;
            font-size: 15px;
            line-height: 75px;
            height: 85px;
            width: 85px;
            left: 8.5px;
            top: 7.5px;
            text-align: center;
            border-radius: 50%;
            background-color: #fff;
        }
        
        .progress-bar .background { background-color: #b3cef6; }
        
        .progress-bar .rotate {
            clip: rect(0 50px 100px 0);
            background-color: #4b86db;
        }
        
        .progress-bar .left {
            clip: rect(0 50px 100px 0);
            opacity: 1;
            background-color: #b3cef6;
        }
        
        .progress-bar .right {
            clip: rect(0 50px 100px 0);
            transform: rotate(180deg);
            opacity: 0;
            background-color: #4b86db;
        }
        
        .miles-points
        {
            height: 85px;
            line-height: 85px;
            text-align: center;
        }
    }


        .member--blue
        {
            .update-profile--interest-preferences
            {
            .ischecked {
                border: 1px solid #00bff3 !important;
                background-color: #00bff3;
                label
                {
                    color: white;
                }

            }
        }
            #members--enrich-status-lcp
            {
                .miles-name
                {
                    color: #00bff3;
                }
            }
        }
        .member--silver
        {
            .update-profile--interest-preferences
            {
            .ischecked {
                border: 1px solid #aaadb0 !important;
                background-color: #aaadb0;
                label
                {
                    color: white;
                }

            }
        }
            #members--enrich-status-lcp
            {
                .miles-name
                {
                    color: #aaadb0;
                }
            }
        }
        .member--platinum
        {
            .update-profile--interest-preferences
            {
                .ischecked {
                    border: 1px solid #506883 !important;
                    background-color: #506883;
                    label
                    {
                        color: white;
                    }
    
                }
            }
            #members--enrich-status-lcp
            {
                .miles-name
                {
                    color: #506883;
                }
            }
        }
        .member--gold
        {
            .update-profile--interest-preferences
            {
            .ischecked {
                border: 1px solid #c8b06f !important;
                background-color: #c8b06f;
                label
                {
                    color: white;
                }

            }
        }
            #members--enrich-status-lcp
            {
                .miles-name
                {
                    color: #c8b06f;
                }
            }
        }
        #lcp--profile--update {
            .hideclass {
                display: none !important;
            }
            .showclass {
                display: block !important;
            }
        }
        #member-personaldetails-update {
            select[disabled='disabled'] {
                background-color: #eee;
                opacity: 1;
            }
            .selectize-control .selectize-input.disabled {
                background-color: #eee;
                opacity: 1;
            }
            .selectize-control.single .selectize-input {
                &.disabled:after {
                    background-color: #eee;
                }
            }
        }
        #claim-others-update, #mob-claim-others-update {
            &.btn-primary {
                border-radius: 25px !important;
                width: 193px !important;
                margin-bottom: 20px;
            }
        }
    .contact-profile-seperator {
        height: 2px;
        background-color: #eee;
        border-color: #eee;
        margin-bottom: 20px;
        margin-top: 20px;
        display: block !important;
        width: 73%;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
.update-profile--personal-phone-mobile {
    position: relative;
    .pencil-icon {
        position: absolute;
        right: 15px;
        top: 12px;
        z-index: 999;
        cursor: pointer;
    }
}