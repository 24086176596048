
$mab-blue: #00447b;
$mab-red: #E21A23;

$animtime: 2.4s;
$animdelay: 0s;
$animstyle: 'linear';

.flight-circle {
  fill: $mab-blue;

  &--top {
    animation: flightcircletop $animtime linear infinite;
  }
}

.flight-path {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  animation: flightpath $animtime $animdelay #{$animstyle} infinite;

  &-top {
    animation: flightpathtop $animtime #{$animstyle} infinite;
  }

  &--inverted {
    animation: flightpathinverted $animtime $animdelay #{$animstyle} infinite;
  }

  &--inverted-top {
    animation: flightpathinvertedtop $animtime #{$animstyle} infinite;
  }
}

$dashoffset-start: 250;
$dashoffset-end: -50;
$dasharray-start: 25;
$dasharray-end: 25;

@keyframes flightpath {
  0% {
    stroke-dasharray: $dasharray-start;
    stroke-dashoffset: $dashoffset-start;
    stroke: $mab-blue;
  }
  65% {
    stroke-dasharray: $dasharray-start;
  }
  100% {
    stroke-dasharray: $dasharray-end;
    stroke-dashoffset: $dashoffset-end;
    stroke: $mab-blue;
  }
}

@keyframes flightpathinverted {
  0% {
    stroke-dasharray: $dasharray-start;
    stroke-dashoffset: $dashoffset-end;
    stroke: $mab-blue;
  }
  65% {
    stroke-dasharray: $dasharray-start;
  }
  100% {
    stroke-dasharray: $dasharray-end;
    stroke-dashoffset: $dashoffset-start;
    stroke: $mab-blue;
  }
}


@keyframes flightpathtop {
  0% {
    stroke-dasharray: $dasharray-start;
    stroke-dashoffset: $dashoffset-start;
    stroke: $mab-blue;
  }
  35% {
    stroke: $mab-red;
  }
  65% {
    stroke-dasharray: $dasharray-start;
  }
  100% {
    stroke-dasharray: $dasharray-end;
    stroke-dashoffset: $dashoffset-end;
    stroke: $mab-blue;
  }
}

@keyframes flightpathinvertedtop {
  0% {
    stroke-dasharray: $dasharray-start;
    stroke-dashoffset: $dashoffset-end;
    stroke: $mab-blue;
  }
  35% {
    stroke: $mab-red;
  }
  65% {
    stroke-dasharray: $dasharray-start;
    stroke: $mab-red;
  }
  100% {
    stroke-dasharray: $dasharray-end;
    stroke-dashoffset: $dashoffset-start;
    stroke: $mab-blue;
  }
}

@keyframes flightcircletop {
  0% {
    fill: $mab-blue;
  }
  35% {
    fill: $mab-red;
  }
  65% {
    fill: $mab-red;
  }
  100% {
    fill: $mab-blue;
  }
}

.loading-banner {
  z-index: 999;
  width: 100%;
  height: 320px;
  // background: #fafafa;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  @media (max-width: 767px) {
    height: 70%;
    padding: 20px;
  }

  body.loading & {
    display: block;
  }
}

.loading-overlay {
  z-index: 998;
  background: rgba($white, 0.94);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  body.loading & {
    display: block;
  }
}

.loading-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.loading-image {
  width: auto;
  min-width: 450px;

  @media (max-width: 767px) {
    width: 100%;
    min-width: initial;
  }

  img,
  svg {
    max-width: 100%;
  }
}

.loading-text {
  width: 220px;

  // @media (max-width: 767px) {
  //   width: 100%;
  //   min-width: initial;
  // }

  span {
    font-family: $font-family-medium;
    font-size: rem(24);
    font-weight: 600;
    color: $countdownblue;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4,end) 900ms infinite;
      animation: ellipsis steps(4,end) 900ms infinite;
      content: "\2026";
      width: 0px;
    }
  }
}


@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
