.members--profile--update {
  .update-profile--submit--add-business-address {
    margin-top: 20px;
    font-size: rem(14);

    i.fa {
      margin-right: 10px;
    }
  }

  .form {
    .update-profile--personal-contact-numbers {
      .phoneinput {
        margin-bottom: 10px;
      }
    }
  }

  .contact-information {
    // margin-top: 20px;
  }

}
