.expandable-img--container{
	display: block;
	height: auto;
	overflow: hidden;
	.img-content--wrapper{
		.img-wrapper{
			position: relative;
			height: auto;
			overflow: hidden;
			display: block;
			.expand-btn{
				position: absolute;
				bottom: 20px;
				margin: 0 50%;
				z-index: 10;
				padding-top: 3px;
				cursor: pointer;
				background: $blue;
				color: $white;
				font-family: $font-family-medium;
				@include font-size(30);
				line-height: 1em;
				@include border-radius(100px);
				width: 36px;
			    height: 36px;
			    text-align: center;
			    a{
			    	color: $white;
			    }
			}
			.img-holder{
				width: 60%;
				float: left;
			}

			.content-holder{
				width: 70%;
				height: auto;
				overflow: hidden;
				position: absolute;
				//bottom: 0;
				right: 0;
				background: rgba(248,80,50,0);
				background: -moz-linear-gradient(left, rgba(248,80,50,0) 0%, rgba(238,238,239,1) 43%);
				background: -webkit-gradient(left top, right top, color-stop(0%, rgba(248,80,50,0)), color-stop(43%, rgba(238,238,239,1)));
				background: -webkit-linear-gradient(left, rgba(248,80,50,0) 0%, rgba(238,238,239,1) 43%);
				background: -o-linear-gradient(left, rgba(248,80,50,0) 0%, rgba(238,238,239,1) 43%);
				background: -ms-linear-gradient(left, rgba(248,80,50,0) 0%, rgba(238,238,239,1) 43%);
				background: linear-gradient(to right, rgba(248,80,50,0) 0%, rgba(238,238,239,1) 43%);
				.expandable-container{
					width: 70%;
					float: right;
					min-height: 720px;
					padding: 15% 0 0;

					@media screen and (min-width: 1200px) {
						padding-right: 120px;
					}

					h1{
						color: $blue;
						line-height: 1em;
            @include font-size(36);
					}
					p{
						clear: both;
					}
					.btn-primary{
						text-align: center;
						width: auto;
						margin-top: 15px;
					}
					.list-container{
						margin-bottom: 15px;
						float: left;
						width: 100%;
						&.half{
							width: 50%;
						}
						.list-item{
							width: 40%;
							float: left;
							margin-right: 10%;
							.list-title{
								margin: 15px 0 10px;
							}
							.item-name{
								@include font-size(48);
								font-family: $font-family-medium;
								line-height: 1em;
							}
							.title{
								@include font-size(14);
							}
							.list-bottom{
								margin: 10px 0 0;
								clear: both;
								float: left;
								text-align: center;
								width: 100%;
							}
						}
					}
					.additional-items{
						width: 100%;
						float: left;
						clear: both;
						margin: 5% 0;
						.img-wrapper{
							float: left;
							width: 15%;
							img{
								width: 85%;
							}
						}
						.content-wrapper{
							width: 75%;
							float: left;
							display: block;
							padding: 0;
							h5{
								@include font-size(18);
								color: $gray;
								margin-bottom: 5px;
							}
							p{
								@include font-size(14);
								color: $gray;
								padding: 10px 0;
							}
						}
					}
					&.more-contents{
						padding: 9% 10%;
					}
				}
			}
			&.white{
				.content-holder{
					background: rgba(250,250,250,0);
					background: -moz-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 43%);
					background: -webkit-gradient(left top, right top, color-stop(0%, rgba(250,250,250,0)), color-stop(43%, rgba(250,250,250,1)));
					background: -webkit-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 43%);
					background: -o-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 43%);
					background: -ms-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 43%);
					background: linear-gradient(to right, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 43%);
				}
			}
			&.black{
				.content-holder{
					background: rgba(250,250,250,0);
					background: -moz-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 43%);
					background: -webkit-gradient(left top, right top, color-stop(0%, rgba(250,250,250,0)), color-stop(43%, rgba(51,51,51,1)));
					background: -webkit-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 43%);
					background: -o-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 43%);
					background: -ms-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 43%);
					background: linear-gradient(to right, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 43%);
					.expandable-container{
						h1{
							color: $white;
						}
						p{
							color: $white;
						}
						.list-container{
							.list-item{
								color: $white;
							}
						}
					}
				}
			}
			&.right{
				.img-holder{
					float: right;
				}
				.content-holder{
					left: 0;
					background: rgba(238,238,239,1);
					background: -moz-linear-gradient(left, rgba(238,238,239,1) 57%, rgba(248,80,50,0) 100%);
					background: -webkit-gradient(left top, right top, color-stop(57%, rgba(238,238,239,1)), color-stop(100%, rgba(248,80,50,0)));
					background: -webkit-linear-gradient(left, rgba(238,238,239,1) 57%, rgba(248,80,50,0) 100%);
					background: -o-linear-gradient(left, rgba(238,238,239,1) 57%, rgba(248,80,50,0) 100%);
					background: -ms-linear-gradient(left, rgba(238,238,239,1) 57%, rgba(248,80,50,0) 100%);
					background: linear-gradient(to right, rgba(238,238,239,1) 57%, rgba(248,80,50,0) 100%);
					.expandable-container{
						float: left;
						padding: 15% 0 0 5%;

						@media screen and (min-width: 1200px) {
							padding-left: 120px;
						}
					}
				}
				&.white{
					.content-holder{
						background: rgba(250,250,250,1);
						background: -moz-linear-gradient(left, rgba(250,250,250,1) 57%, rgba(250,250,250,0) 100%);
						background: -webkit-gradient(left top, right top, color-stop(57%, rgba(250,250,250,1)), color-stop(100%, rgba(250,250,250,0)));
						background: -webkit-linear-gradient(left, rgba(250,250,250,1) 57%, rgba(250,250,250,0) 100%);
						background: -o-linear-gradient(left, rgba(250,250,250,1) 57%, rgba(250,250,250,0) 100%);
						background: -ms-linear-gradient(left, rgba(250,250,250,1) 57%, rgba(250,250,250,0) 100%);
						background: linear-gradient(to right, rgba(250,250,250,1) 57%, rgba(250,250,250,0) 100%);
					}
				}
				&.black{
					.content-holder{
						background: rgba(51,51,51,1);
						background: -moz-linear-gradient(left, rgba(51,51,51,1) 57%, rgba(250,250,250,0) 100%);
						background: -webkit-gradient(left top, right top, color-stop(57%, rgba(51,51,51,1)), color-stop(100%, rgba(250,250,250,0)));
						background: -webkit-linear-gradient(left, rgba(51,51,51,1) 57%, rgba(250,250,250,0) 100%);
						background: -o-linear-gradient(left, rgba(51,51,51,1) 57%, rgba(250,250,250,0) 100%);
						background: -ms-linear-gradient(left, rgba(51,51,51,1) 57%, rgba(250,250,250,0) 100%);
						background: linear-gradient(to right, rgba(51,51,51,1) 57%, rgba(250,250,250,0) 100%);
					}
				}
			}
		}
		.content-wrapper{
			p{
				padding: 30px 8%;
			}
		}
	}
}

@include respond-to(1700){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.content-holder{
					.expandable-container{
						min-height: 640px;
					}
				}
			}
		}
	}
}

@include respond-to(1440){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.content-holder{
					.expandable-container{
						min-height: 560px;
					}
				}
			}
		}
	}
}


@include respond-to(1350){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.content-holder{
					.expandable-container{
						min-height: 500px;
						padding: 12% 0 0;
					}
				}
			}
		}
	}
}

@include respond-to(mid-screen-768-1200){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.content-holder{
					top: 0;
					.expandable-container{
						min-height: 400px;
						padding: 5% 20px 0 70px !important;
						h1{
							@include font-size(30);
							line-height: 1em;
							margin-bottom: 5px;
						}
						p{
							@include font-size(14);
						}
						&.more-contents{
							padding: 0.5% 10%;
							width: 80%;
						}
						.list-container{
							.list-item{
								width: 45%;
								margin-right: 5%;
								.item-name{
									@include font-size(36);
									line-height: 1em;
								}
							}
						}
					}
				}
				.expand-btn{
					.fa{
						@include font-size(28);
					}
				}
			}
		}
	}
}

@include respond-to(ipad-portrait){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.content-holder{
					.expandable-container{
						padding: 4% 20px 0 30px !important;
						width: 85% !important;
						h1{
							line-height: 1em !important;
							font-size: rem(24) !important;
						}
						p{
							font-size: rem(13) !important;
						}
						.btn-primary{
							margin-top: 0;
						}
						.list-container{
							.list-item{
								.list-title{
									margin: 5px 0 10px;
									@include font-size(12);
								}
								.item-name{
									@include font-size(30);
									line-height: 1em;
								}
								.title{
									@include font-size(12);
								}
								.list-bottom{
									@include font-size(12);
								}
							}
						}
						.additional-items{
							margin: 2% 0 1%;
							.content-wrapper{
								h5{
									@include font-size(14);
								}
								p{
									padding: 5px 0;
								}
							}
						}
					}
				}
				.img-holder{
					width: 75%;
				}
				.expand-btn{
					.fa{
						@include font-size(28);
						line-height: 1em;
					}
				}
			}
		}
	}
}

@include respond-to(tablet-portrait){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.img-holder{
					width: 100%;
					 &:before {
					    content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						background-image: none !important;
					}
				}
				.content-holder{
					width: 100%;
					position: relative;
					.expandable-container{
						width: 100%;
						padding: 20px 5% 20% !important;
						min-height: auto !important;
						height: auto;
						h1{
							@include font-size(36);
							line-height: 1em;
						}
						p{
							@include font-size(14);

						}
						&.more-contents{
							padding: 20px 5% 15% !important;
						}
						.list-container{
							width: 100% !important;
							.list-item{
								width: 45%;
								margin-right: 5%;
								.list-title, .list-bottom{
									@include font-size(14);
								}
							}
						}
						.additional-items{
							p{
								padding: 0 0 50px;
							}
						}
					}
				}
				.expand-btn{
					margin: 0 50%;
					width: 30px;
					height: 30px;
					@include font-size(24);
					line-height: 1em;
				}
				&.black{
					.img-holder{
						width: 100%;
						 &:before {
						    content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background-image: rgba(250,250,250,0);
							background-image: -moz-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 50%);
							background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(250,250,250,0)), color-stop(50%, rgba(51,51,51,1)));
							background-image: -webkit-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 50%);
							background-image: -o-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 50%);
							background-image: -ms-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 50%);
							background-image: linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 50%);
						}
					}
					.content-holder{
						background: $gray;
					}
				}
				&.white{
					.img-holder{
						width: 100%;
						 &:before {
						    content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background-image: rgba(250,250,250,0);
							background-image: -moz-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 50%);
							background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(250,250,250,0)), color-stop(50%, rgba(250,250,250,1)));
							background-image: -webkit-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 50%);
							background-image: -o-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 50%);
							background-image: -ms-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 50%);
							background-image: linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 50%);
						}
					}
					.content-holder{
						background: #FAFAFA;
					}
				}
			}
			.content-wrapper{
				p{
					padding: 30px 20px;
				}
			}
		}
	}
}

@include respond-to(414){
	.expandable-img--container{
		.img-content--wrapper{
			.img-wrapper{
				.img-holder{
					 &:before {
					 	background-image: -moz-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(235,235,236,1) 40%);
						background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(250,250,250,0)), color-stop(40%, rgba(235,235,236,1)));
						background-image: -webkit-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(235,235,236,1) 40%);
						background-image: -o-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(235,235,236,1) 40%);
						background-image: -ms-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(235,235,236,1) 40%);
						background-image: linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(235,235,236,1) 40%);
					}
				}
				&.white{
					.img-holder{
						width: 100%;
						&:before {
							background-image: -moz-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 40%);
							background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(250,250,250,0)), color-stop(40%, rgba(250,250,250,1)));
							background-image: -webkit-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 40%);
							background-image: -o-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 40%);
							background-image: -ms-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 40%);
							background-image: linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 40%);
						}
					}
				}
				&.black{
					.img-holder{
						width: 100%;
						&:before {
							background-image: -moz-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 40%);
							background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(250,250,250,0)), color-stop(40%, rgba(51,51,51,1)));
							background-image: -webkit-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 40%);
							background-image: -o-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 40%);
							background-image: -ms-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 40%);
							background-image: linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(51,51,51,1) 40%);
						}
					}
				}
				.content-holder{
					.expandable-container{
						padding: 0 5% 20%;
						min-height: 100% !important;
						.btn-primary{
							width: 50%;
						}
						.list-container{
							.list-item{
								width: 46%;
								margin-right: 8%;
								&:last-child{
									margin-right: 0;
								}
								.list-title{
									text-align: center;
								}
								.list-item{
									width: 45%;
									margin-right: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
