.destionation-search{
	.destination-country{
		padding: 0 10% 20px;
		display: block;
		width: 100%;
		margin: 0;
		.col-lg-5{
			padding: 0 10px 0 0;
			margin: 0 ;
		}
		.col-lg-2{
			padding: 0;
			margin: 0;
		}
		.booking-form--title{
			position: absolute;
			margin-left: 24px;
			margin-top: 14px;
			color: $darkgray;
    -webkit-transition: all 90ms linear;
    -o-transition: all 90ms linear;
    transition: all 90ms linear;
			@include font-size(14);
			@include z-index(bookformtitle);
			&.input-filled{
				margin-top: 6px;
				margin-left: 22px;
				@include font-size(12);
			}
			&.has-error{
				color: $errorred;
			}
		}
	}
}

@include respond-to(ipad-portrait){
	.destionation-search{
		.destination-country{
			padding: 0 5% 20px;
		}
	}
}

@include respond-to(tablet-portrait){
	.destionation-search{
		.destination-country{
			padding: 0;
			margin-bottom: 20px;
			.selectDestCountry,
			.selectDestCity {
				margin-bottom: 10px;
				padding-right: 0;
			}
		}
	}
}
