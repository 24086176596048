.mholidays--carousel,
.mholidays--banner {
  @media screen and (max-width: 767px) {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
}

.mholidays--carousel {
  margin-bottom: 30px;

  .seq {
    $sidebar: 300px;
    $sidebar-margin: 20px;
    $page-margin: 20px;
    $fulloffset: 240px;

    width: calc(100vw - #{$sidebar} - #{$sidebar-margin} -  20px - #{$fulloffset});
    height: calc((100vw - #{$sidebar} - #{$sidebar-margin} -  20px - #{$fulloffset}) * 0.5625);

    @media screen and (min-width: 1441px) {
      max-width: calc(100vw - #{$sidebar} - #{$fulloffset});
      max-height: calc((100vw - #{$sidebar} - #{$fulloffset}) * 0.5625);
    }

    @media screen and (min-width: 1201px) and (max-width: 1440px) {
      width: calc(100vw - #{$sidebar} - #{$page-margin});
      height: calc((100vw - #{$sidebar} - #{$page-margin}) * 0.5625);

      max-width: calc(1200px - #{$sidebar} - #{$page-margin});
      max-height: calc((1200px - #{$sidebar} - #{$page-margin}) * 0.5625);
    }

    @media screen and (max-width: 1200px) {
      width: calc(100vw - #{$page-margin} -  30px);
      height: calc((100vw - #{$page-margin} -  30px) * 0.5625);

      max-width: calc(1200px - #{$page-margin});
      max-height: calc((1200px - #{$page-margin}) * 0.5625);
    }

    @media screen and (max-width: 767px) {
      width: calc(100vw);
      height: calc((100vw) * 0.5625);

      max-width: calc(1200px );
      max-height: calc((1200px ) * 0.5625);
    }

    button {
      display: none;
    }
  }
}