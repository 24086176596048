#members-travel--stimulus
{
	#hidetraveller,#hidetraveller_1,#hidetraveller_2,#hidetraveller_3,#hidetraveller_4,#hidetraveller_5,#hidetraveller_6,#hidetraveller_7,#hidetraveller_8
	{
		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) 
		{
			margin-left: 50px !important;
		}
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px)
		{
			margin-left: 70px !important;
		}
	}
	.submitted_traveller
	{
		margin-top: -36px;
		float: right;
		margin-right: 23px;
		color: #006dbb;
	}
  .fa-id-card-o {
    color: #55A814;
  }
  .govtandc-link {
    color: #006DBB !important;
    cursor: pointer;
    text-decoration: underline;
  }
  .gts-user-edit {
    height: 15px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  @media (min-width: 768px) {
	.col-sm-5 {
		width: 48.66667%;
		margin-bottom: 10px;
	}
	.col-sm-2 {
		width: 20.66667%;
	}
  }
    h1
        {
                font: normal 700 30px/34px Gotham,Arial;
        }
        .col-lg-12
        {
            padding-left: 7% !important;
        }
        .btn-redeem--submit{
            margin-top: 30px!important;
            border-radius: 10px;
            width: 220px;
            height: 46px;
        }
        .check-box--terms--cond--section{
            margin-top: 10px;
        }
        .select--terms--cond--checkbox {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            //font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          /* Hide the browser's default checkbox */
    .select--terms--cond--checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      /* Create a custom checkbox */
    .terms--cond--checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ccc;
        cursor: pointer;

      }
      .gts-container{
        pointer-events:none;
        opacity:0.5; 
        margin-bottom: 83px;
      }
      /* On mouse-over, add a grey background color */
    .select--terms--cond--checkbox:hover input ~ .terms--cond--checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .select--terms--cond--checkbox input:checked ~ .terms--cond--checkmark {
        background-color: #46a049;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .terms--cond--checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .select--terms--cond--checkbox input:checked ~ .terms--cond--checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .select--terms--cond--checkbox .terms--cond--checkmark:after {       
         border: solid white;
        border-width: 0 3px 3px 0;        
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        height: 5px;
        left: 5px;
        top: 6px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        width: 10px;
      }
      hr{
        border: 1px solid #7F7F7F;
        opacity: 1;
      }
      
.container
{
  z-index:0 !important;
}

#member-profile-edit1{
  .passport-information{
    display:none;
  }
  .contact-information{
    display:none;
  }

}

.gts-hide{
  display:none;
}

.gov-username-header{
	color: white;
	margin-left: 1020px;
	padding-top: 13px;
}
#mab-bookingnav.gov-header {
  background-image: url(../images/desktop_nav_bg_1920x52.jpg);
  position: relative;
}
.enrolment-text{
  padding: 17px 77px;
}
.steps-to-book{
  text-align: left;
  font: normal normal 21px/24px Gotham,Arial;
  letter-spacing: 0;
  color: #006DBB;
  opacity: 1;
  p{
    color: #ED1C24;
    font: normal normal 14px/24px Gotham-book,Arial;
  }
  ol{
    font: normal normal 14px/24px Gotham-book,Arial;

  }
}
.note{
  color: #ED1C24;
  font: normal normal 14px/24px Gotham,Arial;
}
ol {
  margin-left: -25px;
  font: normal normal 14px/24px Gotham,Arial;
  
}
ol li{
  line-height: 1.72857;
}
.gov-header-text{
  text-align: left;
  letter-spacing: 0;
  color: #19286e;
  text-shadow: 0px 3px 3px #000000;
  opacity: 1;
  font: normal normal 45px/67px Kaushan Script, arial;
  // margin-top: -120px;
  margin-bottom: 20px;
}
.background-gov-header {
  background-image: url(../images/bg_image.png);
}
@media (max-width: 767px) {
  .enrolment-text{
    padding: 17px 24px;
  }
  .gov-header-text{
    text-align: left;
    letter-spacing: 0;
    color: #19286e;
    text-shadow: 0px 3px 6px #00000066;
    opacity: 1;
    font: normal normal 18px/38px Kaushan Script,arial;
   //margin-top: -83px;
    //margin-bottom: 52px;

  }
    .gov-wrapper
      {
        margin: 0px;
      }
      .gts-container{
        text-align: center;
      }
      .background-gov-header {
        background-size: cover;
        height: 131px;
        width: 434px;
        }


}

  @media (min-width: 767px) {
  .gov-wrapper
    {
    margin:auto;
   
    }
    // .enrolment-text{
    //   margin-top: -496px;
    // }
    .background-gov-header {
      width: 1920px;
      height: 650px;
        }
}

@media (min-width: 768px) and (max-width: 979px)  {

  .gov-wrapper {
    margin: 0 100px;
 }

 .gov-header-text {
  text-align: left;
  letter-spacing: 0;
  color: #19286e;
  text-shadow: 0 3px 3px #000;
  opacity: 1;
  font: normal normal 25px/67px Kaushan Script,arial;
  // margin-bottom: 89px;
}

}
.govt_content{
	@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5){
		/* ipad pro */
		.col-sm-6 {
			width: unset;
		}
		.gov-wrapper {
			margin: 0 100px;
		}

		.gov-header-text {
		text-align: left;
		letter-spacing: 0;
		color: #19286e;
		text-shadow: 0 3px 3px #000;
		opacity: 1;
		font: normal normal 33px/67px Kaushan Script,arial;
		// margin-bottom: 89px;
		}
	}
}
@media only screen and (min-device-width: 1025px) {
  .gov-wrapper {
    width: 69%;
 }
}

#member-gov-stimulustraveler .select--checkbox {
  position: unset;
}
.govt_content {
  padding-left: 0px !important;
}
@media (min-width: 320px) and (max-width: 568px)  {
  .btn-member--submit, .btn-member--cancel{
    width: 115px!important;
    min-width: 115px!important;
    height: 46px;
  }

  // #member-gov-stimulustraveler .checkmark {
  //   top: -27px;
  //   left: 21px;
  // }
}

@media (min-width: 768px) and (max-width: 1024px){
.col-sm-6 {
     width: 100% !important;
}
}
body{
  background: #fff;
}

#member-gov-stimulustraveler
 { 
	.custom-file-input{
        display: inline-block;
		//overflow: hidden;
		position: relative;
    }
    .custom-file-input input[type="file"]{
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        zoom: 1;  /* Fix for IE7 */
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
	}
	.info{
		font-size: 13px !important;
		font-family: Gotham-Book,Arial !important;
		color: #14467B !important;
	}
	.hideClass
	{
		display: none;
	}
	.showClass
	{
		display: block;
	}
	.check-box--section{
		// margin-right: 5px !important;
		float: right;
		@media screen and (max-width: 414px) 
		{
			margin-top: -52px;
			margin-right: 26px !important;
		}
		@media screen and (min-width: 415px) and (max-width: 767px) 
		{
			margin-top: -52px;			
			margin-right: 15px !important;
		}
		@media screen and (min-width: 768px) and (max-width: 1023px) 
		{			
			margin-right: 15px !important;
		}
		// @media screen and (min-width: 321px) and(max-width: 413px) 
		// {
		// 	margin-top: -30px;
		// 	margin-right: 26px !important;
		// }
		// @media screen and (min-width: 414px) and (max-width: 567px) 
		// {
		// 	margin-top: -30px;
		// 	margin-right: 26px !important;
		// }
		// @media screen and (min-width: 568px) and (max-width: 639px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 460px !important;
		// }
		// @media screen and (min-width: 640px) and (max-width: 666px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 525px !important;
		// }
		// @media screen and (min-width: 667px) and (max-width: 735px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 555px !important;
		// }
		// @media screen and (min-width: 736px) and (max-width: 767px) 
		// {
		// 	margin-top: -30px;
		// 	//margin-left: 625px !important;
		// }
		// @media screen and (min-width: 768px) and (max-width: 1024px) 
		// {
		// 	//margin-top: -30px;
		// 	//margin-left: 430px !important;
		// }
		
	}
	.nric--IC--number--error,.file-upload--image--error,.file-upload--size--error{
		color: #ed1c24;
		font-size: 14px !important;
	}
	.nric--detail--info, .gts--used--info{
		color: #ed1c24;
		font-size: 14px !important;
		margin-left: 20px;
	}
	.gts-used-info-main {
		display: flex;
	}
	.gts-used-info-section1 {
		float: left;
		margin-right: 5px;
	}
	.gts-used-icon {
		font-size: 18px;
	}
	.gts-used-info-line1 {
		font-weight: bold;
	}
	.select--checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	  }
	  /* Hide the browser's default checkbox */
.select--checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  /* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #ccc;
	border-radius: 10px;
	cursor: pointer;
  }
  /* On mouse-over, add a grey background color */
.select--checkbox:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .select--checkbox input:checked ~ .checkmark {
	background-color: #46a049;
  }
  
  /* Create the checkmark/indicator*/
  .checkmark:after {
	content: "";
	position: absolute;	
  }
  
  

  /* Style the checkmark/indicator */
  .select--checkbox .checkmark:after {	
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	height: 5px;
	left: 5px;
	top: 6px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	width: 10px;
  }
  

				// .col-lg-12 {
				// padding-left: 0px !important;
				// }
				.mytravellererror_message{
				color: red;
				font: normal normal 14px/16px Gotham,Arial;
				margin-left: 12px;
				margin-bottom: 10px;
				}
				h1{
					position: relative;
					margin: 0;
					font-family: $font-family;
					color: $blue;
					line-height: initial;
					margin-bottom: 20px;
					font-size: 2.25rem;
				@media screen and (max-width: 767px) {
						text-align: center;
					  }
				}
				.tagline{
				color: #14467B;
				font: normal normal  14px/16px Gotham,Arial;	
				}
				.traveller_list{
					float: right;
    				margin-right: 21px;
				}
				.tag-lineno
				{
				// padding-left: 455px;
				font: normal bold 21px/23px Gotham,arial;
				color: #14467b;	
					// @media screen and (min-width: 320px) and (max-width: 600px) 
					// {
					// padding-left: 34px;
					// font:normal 700 18px/16px Gotham,arial;
					// } 
					// @media screen and (min-width: 360px) and (max-width: 640px)
					// {
					// padding-left: 74px;
					// } 
					// @media screen and (min-width: 375px) and (max-width: 667px) 
					// {
					// padding-left: 87px;
					// }  
					// @media screen and (min-width: 411px) and (max-width: 731px) 
					// {
					// padding-left: 122px; 
					// }
					// @media screen and (min-width: 414px) and (max-width: 736px) 
					// {
					// padding-left: 128px;
					// }
				}

				.tag-line{
				// margin-left: -4px;
				font: normal bold 21px/23px Gotham,arial;
				color: #14467B;
				}
				.btntop 
				{
				@media screen and (max-width: 767px) 
				{
				width: 100%;
				} 
					margin-top: 30px !important;
					border-radius: 10px;
					width: 220px;
					height: 46px;
					.addtravell{
					margin-left: 10px;
					}
				}	
		.member-profile-edit
			{
				.member-edit-travel-coordinator--submit
				{
				margin-left: 158px;
				@media screen and (max-width: 767px) 
				{
					margin-left: 20px;
				} 
				}
				.datepicker-icon{
					pointer-events: none;
					position: absolute;
					text-align: center;
					right: 20px;
					top: 12.5px;
					z-index: 2;
					color: #666;
				}
				.edit-profile--name{
				margin-top: 10px;
				}
				.tag{
					color: #006DBB;
					margin-left: 10px;
					font: normal normal 14px/16px Gotham,Arial;
				}
				.edit{
					margin-top: -38px;
					font-weight: 600;
					font-family: Gotham-Medium,Arial;
					margin-left: 10px;
					@media screen and (max-width: 768px) {
					margin-left: 71px;
					margin-bottom: 20px;
				}
				}
				.circle-travel{

					padding-top: 6px;
					margin-left: -32px;
					border-radius: 15px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;	
					text-align: center;
				@media screen and (max-width: 768px) {
				margin-left: 21px;
				border-radius: 17px
				}
				}
				.fieldset
				{
				margin-left: 90px !important;
				@media screen and (max-width: 768px) {
				margin-left: 0px !important;
				}
				}

				.add-traveller-first-name
				{
				@media screen and (max-width: 768px) {
				margin-bottom: 14px;
				}
				}
				label.required
				{
					pointer-events: none;
				}
				label{
					position: absolute !important;
					margin-top: -10px !important;
					background-color: white !important;
					margin-left: 20px !important;
					padding-left: 4px !important;
					padding-right: 4px !important;				}
				select{
				border-radius: 10px 10px 10px 10px;
				}
				.selectize-input{
				border-radius: 10px 10px 10px 10px;
				}
				.selectize-control .selectize-input {
                    border-radius: 10px;
				}
				.selectize-input {           
                    border-radius: 10px;
                   
                    
                    &.input-active {
                    &.dropdown-active {               
                        border-radius: 10px 10px 0 0;  
                                 
                    }
                    }        
                
                }        
                .selectize-dropdown {           
                    border-radius: 0 0 10px 10px; 
                }
            
				input{
				border-radius: 10px 10px 10px 10px;
				}
				.distance-margin
				{
				margin-top: 15px;
				}
				.btn-secondary{
					margin-left: 10px;
					border-radius: 10px 10px 10px 10px;
				.addBlueBkg {

				background: white !important;

				}
				}
				.btn-member--cancel{
					min-width: 146px;
					width: auto;
				}
				.btn-primary{
					margin-left: 65px;
					border-radius: 10px 10px 10px 10px;
				@media screen and (max-width: 767px) 
				{
				margin-left: -2px;
				} 
				}
				.use-primary-email {
					font-size:20px;
					padding-left: 5px; 
					.use-primary-email {
					  vertical-align: middle;
					}
					.primaryEmailText {
						font-size: 15px;
						padding-top: 5px;
						padding-left: 0px;
						color: #006dbb;
						font: normal normal 14px/16px Gotham,Arial;
					}         
					label {
					  display: block;
					  position: relative !important;
					  padding-left: 35px !important;
					  margin-bottom: 12px !important;
					  cursor: pointer;
					  font-size: 22px;
					  -webkit-user-select: none;
					  -moz-user-select: none;
					  -ms-user-select: none;
					  user-select: none;
					  margin-top: 12px !important;					  
					  margin-left: 0px !important;
					  background-color: transparent !important;
					  .use-primary-email {
						  position: absolute;
						  opacity: 0;
						  cursor: pointer;
						  height: 0;
						  width: 0;
					  }
					  .checkmark {
						  position: absolute;
						  top: 0;
						  left: 0;
						  height: 25px;
						  width: 25px;
						  background-color: #eee;                
						  border-radius: 15px;
						  border: 1px solid #ccc;
						}
						.checkmark:after {
						  content: "";
						  position: absolute;
						  display: none;
						}
						&:hover input ~ .checkmark {
						  background-color: #ccc;
						}
						& input:checked ~ .checkmark {
						  background-color: #005aaa;
						}
						& input:checked ~ .checkmark:after {
						  display: block;
						}
						& .checkmark:after {
						  left: 9px;
						  top: 4px;
						  width: 7px;
						  height: 12px;
						  border: solid #fff;
						  border-width: 0 3px 3px 0;
						  transform: rotate(45deg);
						}
		  
					}
				}
			}
		#member-profile-stimulusadd
			{
				.member-add-travel-coordinator--submit
				{
				margin-left: 158px;
				@media screen and (max-width: 767px) 
				{
				margin-left: 20px;
				} 
				}
				.datepicker-icon{
					position: absolute;
					text-align: center;
					right: 20px;
					top: 12.5px;
					z-index: 2;
					color: #666;
					pointer-events: none;
				}
				.add-profile--name, .add-profile--email-address, .add-profile---dob {
				margin-top: 10px;
				margin-bottom: 0;
				}
				.use-primary-email {
					font-size:20px;
					padding-left: 5px; 
					#use-primary-email {
					  vertical-align: middle;
					}
					#primaryEmailText {
						font-size: 15px;
						padding-top: 5px;
						padding-left: 0px;
					}         
					label {
					  display: block;
					  position: relative !important;
					  padding-left: 35px !important;
					  margin-bottom: 12px !important;
					  cursor: pointer;
					  font-size: 22px;
					  -webkit-user-select: none;
					  -moz-user-select: none;
					  -ms-user-select: none;
					  user-select: none;
					  margin-top: 12px !important;					  
					  margin-left: 0px !important;
					  background-color: transparent !important;
					  #use-primary-email {
						  position: absolute;
						  opacity: 0;
						  cursor: pointer;
						  height: 0;
						  width: 0;
					  }
					  .checkmark {
						  position: absolute;
						  top: 0;
						  left: 0;
						  height: 25px;
						  width: 25px;
						  background-color: #eee;                
						  border-radius: 15px;
						  border: 1px solid #ccc;
						}
						.checkmark:after {
						  content: "";
						  position: absolute;
						  display: none;
						}
						&:hover input ~ .checkmark {
						  background-color: #ccc;
						}
						& input:checked ~ .checkmark {
						  background-color: #005aaa;
						}
						& input:checked ~ .checkmark:after {
						  display: block;
						}
						& .checkmark:after {
						  left: 9px;
						  top: 4px;
						  width: 7px;
						  height: 12px;
						  border: solid #fff;
						  border-width: 0 3px 3px 0;
						  transform: rotate(45deg);
						}
		  
					}
				}
				.tag{
				color: #006DBB;
				margin-left: 10px;
				font: normal normal 14px/16px Gotham,Arial;
				}
				color: #006DBB;
				margin-left: 0px;
				font: normal normal 14px/16px Gotham,Arial;

				.addtra{
					margin-top: 30px;
					margin-bottom: 20px;
					font-weight: 600;
					font-family: normal medium 18px/20px Gotham,Arial;
					margin-left: 10px;
					color: #61666F;
				@media screen and (max-width: 767px) 
				{
				margin-top: 6px;
				} 
				}
				.circle-travel{

					padding-top: 6px;
					margin-left: -32px;
					border-radius: 15px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;	
					text-align: center;
				}
				.fieldset
				{
					margin-left: 0px !important;
					@media screen and (max-width: 768px) {
					margin-left: 0px !important;
				}
				}

				.add-traveller-first-name
				{
				@media screen and (max-width: 768px) {
				margin-bottom: 14px;
				}
				}
				.optinal{
				color: #14467B;
				font-family: normal medium 14px/16px Gotham,Arial;
				}
				.loyality{
				color: #61666F;
				font-family: normal medium 14px/16px Gotham,Arial
				}
				label.required
				{
					pointer-events: none;
				}
				label{
					position: absolute !important;
					margin-top: -10px !important;
					background-color: white !important;
					margin-left: 20px !important;
					padding-left: 4px !important;
					padding-right: 4px !important;				}
				select{
				border-radius: 10px 10px 10px 10px;
				}
				.selectize-input{
				border-radius: 10px 10px 10px 10px;
				}
				.selectize-control .selectize-input {
                    border-radius: 10px;
				}
				.selectize-input {           
                    border-radius: 10px;
                   
                    
                    &.input-active {
                    &.dropdown-active {               
                        border-radius: 10px 10px 0 0;  
                                 
                    }
                    }        
                
                }        
                .selectize-dropdown {           
                    border-radius: 0 0 10px 10px; 
                }
				input{
				border-radius: 10px 10px 10px 10px;
				}
				.distance-margin
				{
				margin-top: 15px;
				}
				.btn-secondary{
				margin-left: 10px;
				border-radius: 10px 10px 10px 10px;

				}

				.btn-member--cancel{
					width: 146px;
					@media screen and (max-width: 767px) 
					{
					width: 44%;
					} 
					}

				.btn-primary{
					margin-left: 65px;
					border-radius: 10px 10px 10px 10px;
					@media screen and (max-width: 767px) 
					{
					margin-left: -7px;
					} 
				}
			}
		.firsttraveller_0
				{
				.col-lg-4
				{
				height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
				width: 51%;
				}
				}
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}

					height: 35px;
					margin-right: 63px;
				a{	  
					float: right;
				}

				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}

					a {
					color:red;
					text-decoration: none;
					}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
					}
					@media screen and (max-width: 767px) 
					{
					width: 100%;
					} 

				.circle-travel
				{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.row {
				margin-bottom: 7px;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.col-lg-2 
				{
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
					.col-lg-3 {
					width: 6% !important;
					}
				#hidetraveller{
				@media screen and (max-width: 767px) 
				{
					margin-left: 77px;
				}

					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;

				}
				p {
					margin: 0 0 3px !important;
				}

				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
					color: #61666F ! important;
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
					@media screen and (max-width: 767px) {
					#traveller-title_0{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
				}


				.firsttraveller_1
				{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 

				.edittraveller-details
				{
				.fa{
				font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
				float: right;
				}
				}


				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}
				a {
				color:red;
				text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}

				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.row {
				margin-bottom: 7px;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_1{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
				left: -25PX;
				padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
				margin-left: -25px;
				}

				}
				.row {
				margin-bottom: 10px;
				}

				#hidetraveller-details_1{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold  14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_1{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
				pointer-events: none;
				}
				}
				#traveller-detail_1{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
					@media screen and (max-width: 767px) {
					#traveller-title_1{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				}
			}

		.firsttraveller_2
			{
				.col-lg-4
				{
				height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
				float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					color: white;
					text-align: center;
				}
				.row {
				margin-bottom: 7px;
				}
				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
					width: 6% !important;
				}
				#hidetraveller_2{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
				left: -25PX;
				padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
				margin-left: -25px;
				}

				}
				.row {
				margin-bottom: 10px;
				}

				#hidetraveller-details_2{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_2{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
				pointer-events: none;
				}
				}
				#traveller-detail_2{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) {
				#traveller-title_2{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
					}
					p {
					margin: 0 0 3px !important;
					}
				}
			}

		.firsttraveller_3
			{
				.col-lg-4
				{
				height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
				width: 51%;
				}
				}

				@media screen and (max-width: 767px) 
				{
				width: 100%;
				}

				.edittraveller-details
				{
					.fa{
					font-size: 21px;
					}
					height: 35px;
					margin-right: 63px;
					a{
					float: right;
					}


				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}


				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				@media screen and (max-width: 767px) 
				{
				width: 100%;
				} 
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
				margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_3{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
					.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_3{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_3{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
					margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_3{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_3{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
			}


		.firsttraveller_4
			{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
				font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{		   
					float: right;
				}

				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}


				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
					margin-bottom: 7px;
				}
				.col-lg-2 {
					width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
					width: 6% !important;
				}
				#hidetraveller_4{
				@media screen and (max-width: 767px) 
				{
					margin-left: 77px;
				}
					background: #F1F1F1;
					// height: 298px;
					border: 0px solid #CCCCCC;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_4{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
				font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_4{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_4{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_4{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
			}
		.firsttraveller_5
			{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
					float: right;
				}

				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}
				a {
				color:red;
				text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
				margin-bottom: 7px;
				}
				.col-lg-2 {
					width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_5{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					padding-left: -50px;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_5{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_5{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
				pointer-events: none;
				}
				}
				#traveller-detail_5{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_5{
				height: 17px;
				}
				}
				.traveller-colr{
				color: #006DBB;
				font: normal normal 14px/16px Gotham,Arial;
				font-weight: 600;
				}

				.traveller-color{
				font: normal normal 14px/16px Gotham,Arial;
				color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}
			}
        .firsttraveller_6
			{
				.col-lg-4
				{
					height: 40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				.edittraveller-details
				{
				.fa{
				font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
				float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
				font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				@media screen and (max-width: 767px) 
				{
				width: 100%;
				} 
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}
				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
				margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
				margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_6{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
				background: #F1F1F1;
				margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				p {
					margin: 0 0 3px !important;
				}
				.row {
					margin-bottom: 10px;
				}
				#hidetraveller-details_6{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_6{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
				margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_6{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_6{
					height: 17px;
				}
				}
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
				margin: 0 0 3px !important;
				}
				}
			}


		.firsttraveller_7
			{
				.col-lg-4
				{
					height:40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
					float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
					margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_7{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_1
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_7{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_7{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
					margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_7
				{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_7
				{
					height: 17px;
				}
				}
				.traveller-colr
				{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color
				{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}

		}
		.firsttraveller_8
			{
				.col-lg-4
				{
					height:40px;
				}
				@media screen and (max-width: 767px) 
				{
				.col-xs-6 {
					width: 51%;
				}
				}
				@media screen and (max-width: 767px) 
				{
					width: 100%;
				} 
				.edittraveller-details
				{
				.fa{
					font-size: 21px;
				}
					height: 35px;
					margin-right: 63px;
				a{
					float: right;
				}


				}

				.deletetraveller-details
				{
				.fa{
					font-size: 21px;
				}

				a {
					color:red;
					text-decoration: none;
				}
					margin-top: -36px;
					float: right;
					margin-right: 23px;
				}
				.circle-travel{

					padding-top: 6px;
					border-radius: 19px;
					background: #61666f;
					width: 35px;
					height: 35px;
					/* margin-top: 10px; */
					color: white;
					text-align: center;
				}

				.mob-travellerdet{
				@media screen and (max-width: 767px) 
				{
					margin-top: -37px;
					margin-left: 40px;
				} 
				}

				.mob-travellercont{
					margin-bottom: 12px;
				@media screen and (max-width: 767px) 
				{
					margin-top: -39px;
					margin-left: 94px;
					margin-bottom: 14px;
				} 
				}
				.row {
					margin-bottom: 7px;
				}
				.col-lg-2 {
				width: 7.66667%;
				}
					margin-top: 10px;
					width: 728px;
					background: #F1F1F1;
					border: 1px solid #CCCCCC;
					border-radius: 10px;
					opacity: 1;
				.col-lg-3 {
				width: 6% !important;
				}
				#hidetraveller_8{
				@media screen and (max-width: 767px) 
				{
				margin-left: 77px;
				}
					background: #F1F1F1;
					margin-left: 70px;
				.traveller-colr{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}
				.airline_no_mob
				{
				@media screen and (max-width: 767px) 
				{
					left: -25PX;
					padding-top: 10PX;
				}

				}
				.mob-travellerdet_8
				{
				@media screen and (max-width: 767px) 
				{
					margin-left: -25px;
				}

				}
				.traveller-color{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				.row {
					margin-bottom: 10px;
				}

				#hidetraveller-details_8{
					color: #61666F ;
					font: normal normal 14px/16px Gotham,Arial;
				}

				.traveller-loyality{
					font: normal bold 14px/16px Gotham ,Arial;
					color: #61666F;
				}
				}

				#showtraveller-details_8{
					margin-left: 70px;
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ! important;
				@media screen and (max-width: 767px) 
				{
					margin-left: 52px;
				} 
				.fa{
					pointer-events: none;
				}
				}
				#traveller-detail_8
				{
					margin-top: 15px;
					margin-left: 13px;
					margin-bottom: -7px;
				@media screen and (max-width: 767px) 
				{
				#traveller-title_8
				{
					height: 17px;
				}
				}
				.traveller-colr
				{
					color: #006DBB;
					font: normal normal 14px/16px Gotham,Arial;
					font-weight: 600;
				}

				.traveller-color
				{
					font: normal normal 14px/16px Gotham,Arial;
					color: #61666F ;
				}
				p {
					margin: 0 0 3px !important;
				}
				}

		}
#addtraveller_mobileadd
	{

				@media screen and (max-width: 767px) 
				{
					border-radius:36px;
					width: 50px;
					height: 50px;
					background: #006dbb;
					bottom: 45px;
					right: 25px;
				@media screen and (orientation:landscape){

				bottom: 25px;
				right: 25px;
				}
					font-size: 14px;
					line-height: 28px;  
					-webkit-transform: rotate(-90deg);
					font-weight: 600;
					text-align: center;
					font-size: 17px;
					position: fixed;
				} 

				.fa
				{
					display: none !important;

				@media screen and (max-width: 767px) 
				{
					display: inline-block !important;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: inherit;
					text-rendering: auto;
					color:white;
					-webkit-font-smoothing: antialiased;
					margin-left: -1px;
					margin-top: 18px;
					font-size: 18px;
				@media screen and (max-width:767px) and (orientation:landscape)
				{
					top: 315px  ! important;
				}
				}
				}
	}

	.member-edit-travel-coordinator--submit .col-xs-12{
		display : inline-flex;
	}
// 	.firsttraveller_0edit-profile--title{
// 		pointer-events: none;
	
// 	  .selectize-input{
// 		background-color : #eee;

// 	}

// }
// #member-profile-edit1{
// 	.selectize-control.single .selectize-input:after {
// 		background-color: #eee;
// 		}
// }

@media screen and (min-width: 767px) and (max-width: 1024px)
{
	.firsttraveller_0{
		width: unset;
	}
	.firsttraveller_1{
		width: unset;
	}
	.firsttraveller_2{
		width: unset;
	}
	.firsttraveller_3{
		width: unset;
	}
	.firsttraveller_4{
		width: unset;
	}
	.firsttraveller_5{
		width: unset;
	}
	.firsttraveller_6{
		width: unset;
	}
	.firsttraveller_7{
		width: unset;
	}
	.firsttraveller_8{
		width: unset;
	}
	
	
}

}
#mytraveller-popup
	{
				.removename
				{
					font: normal bold 14px/20px Gotham,Arial;
					color: #006DBB;
				}
				.qblack
				{
					color:black;
				}

				.btn-mytraveler--remove
				{
					width: 160px;
					height: 46px;
					margin-left: 10px;
					background: #ED1C24;
					border-radius: 10px;
					color: white;
					margin-top: 10px;
					padding-top: 14px;
					padding-left: 2px;
				}
				.btn-mytraveler--cancel
				{
					width: 160px;
					height: 46px;
					margin-left:10px;
					border-radius: 10px;
					color:black;
					margin-top: 10px;
					border: 1px solid #61666F;
					padding-top: 14px;
					padding-left: 2px;
				}
				.name
				{
					font: normal normal 14px/20px Gotham,Arial;
				}

	}


.white-popupmytraveller 
            {
				position: relative;
				padding-left: 27px;
				background: #FFF;
				padding: 20px;
				width: 356px;
				border-radius: 5px;
				max-width: 500px;
				margin: 20px auto;
			}
#mytraveller-popup-exist-error--msg
{
	.disp--error
		{
			font: normal bold 14px/20px Gotham,Arial;
			color: #006DBB;
		}
	.btn-mytraveler--exist--msg
		{
			width: 160px;
			height: 46px;
			margin-left: 10px;
			background: #ED1C24;
			border-radius: 10px;
			color: white;
			margin-top: 10px;
			padding-top: 14px;
			padding-left: 2px;
		}
		.msg--error
		{
			font: normal normal 14px/20px Gotham,Arial;
		}
}
.white-popupmytraveller--exist-error--msg 
 {
	position: relative;
	padding-left: 27px;
	background: #FFF;
	padding: 20px;
	width: 356px;
	border-radius: 5px;
	max-width: 500px;
	margin: 20px auto;
}

#gts-restrict-child{
	width: unset;
	text-align: center;
	a{
		width: 70%;
	}
	p{
		text-align: center;
		padding: 29px;
	}
	@media screen and (min-width: 768px)
	{
	a{
		width: 250px;
	}
	
	}
}
.user--claimed--msg, .user--submitted--msg, .user--exceed--msg {
	border-bottom: 1px solid #ed1c24;
    padding: 10px 0;
	color:#ed1c24;
	font-weight: 600;
	margin-bottom: 6px;
}
.govt-stimulus-success-container, .govt-stimulus-failure-container {
	display: none;
	.confirm-header{
		padding: 10% 30px;
		h3{
			text-align: center;
			color: $blue;
			text-transform: none;
			@include font-size(32);
		}
		p.message {
			text-align: center;
			color: $blue;
			text-transform: none;
			@include font-size(16);
		}
		p.back-stimulus {
			text-align: center;
			.back-to-home-stimulus {
				width: 50%;
				@include respond-to(tablet-portrait) {
					width: 100%;
				}
			}
		}
	}
}
.govt-stimulus-exceed-container {
	display: none;
	.confirm-header{
		padding: 10% 30px;
		h3{
			text-align: center;
			color: #ed1c24;
			text-transform: none;
			@include font-size(32);
		}
	}
}

}
.gtstwomain 
{
  .ui-datepicker:not(.ui-datepicker-multi)
  {
  .ui-datepicker-header
	{
		.ui-datepicker-prev
		  {
				 margin-top: 51px;
				  }
	  .ui-datepicker-next
		 {
		   margin-top: 51px;
		 }
	  .ui-datepicker-title
		{
		margin-top: 70px;
		}
	}
  }
}