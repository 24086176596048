.ibe-standalone {
  padding: 15px 0;
  border-bottom: 1px solid $lightgray;

  .ibe-form--container {
    position: relative;
    transform: initial;
    display: block;
    left: auto;
    box-shadow: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin: 0 auto;
    border: solid 1px $lightgray;

    @media screen and (min-width: 1200px) {
      max-width: calc(100vw - 200px);
    }

    @media screen and (min-width: 1200px) and (max-width: 1439px) {
    }

    .search-container {
      padding-bottom: 0;
    }

    .container--redemption-login {
      padding-top: 15px;
    }

    .recent-container,
    .payment-type-container,
    .trip-container,
    .search-container {
      display: block;
    }

    .booking-details-container {
      [data-form-booking--trip] {
        padding-bottom: 15px;
      }
    }
  }

  .mobile-ibe {
    padding: 0 10px;

    .ibe-form--container {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 10px;

      .location-container .toLocation {
        display: block;
      }

      .container--redemption-login .error-validation .error-icon {
        padding-left: 0;
      }

      .container--redemption-login .error-validation .error-msgs {
        margin-left: 8px !important;
      }
    }

    .trip-container {
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    .trip-container,
    .search-container,
    .location-container,
    .payment-type-container {
      padding: 0 15px;
    }

    .error-validation {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 40px);
    }
  }
}
.evo-autocomplete--static, .evo-autocomplete--static-mob{
  background: white;
  border: 1px solid #ccc;
  padding: 5px 0;
  z-index: 100;
  position: absolute;
  top: 67px;
  padding: 5px;
  display:none;
  width: calc(47.125% - 33px);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid #005aaa;
  @media screen and (max-width: 1200px) {
    width: 47%;
  }
  @media screen and (max-width: 768px) {
    width: 46%;
  }
}
.evo-autocomplete--static-mob{
  position: absolute;
  top: 48px;
  width: 100%;
}

.evo-autocomplete--static .recent-container .col-lg-5{
  @media (max-width: 1200px) and (min-width: 768px){
    width:60%
  }
  @media (min-width: 1500px){
    width:30%
  }
}
.recent-container .recent-search--container {
  @media (max-width: 1200px) and (min-width: 768px){
  width: 120% !important;
  }
}
.evo-autocomplete--static-mob .recent-container .col-lg-5{
    width:100%
}
// Hide instant search header
#offerMainTeaser{
  display: none !important;
}
.calender-lowfare-padding{
  padding:4px 14px !important;
  @media screen and (max-width: 768px) { 
    padding:4px !important;
  }
}
.ui-datepicker-row-break{
  width: 100%;
  position: absolute;  
  top:32px;
  color:#005aaa;
  font-style: italic;
  font-size: small;
  text-align: center;
  padding: 0;
  font-weight: normal;
  @media screen and (max-width: 768px) {   
    top: 30px;
    line-height: normal;
  }
}
.lowfare-calender-margin {
  margin-top: 5px;
}
.lowfare-calender-ipad-margin {
  margin-top:22px !important;
}