.miles-redeem-enrich-vouchers {
  .redeem--pay-cash-or-miles {
    .separator {
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;
      line-height: 1;

      span {
        line-height: rem(16);
      }
    }
  }

  .redeem--pay-cash-or-miles-label span {
    @media screen and (min-width: 768px) {
      padding: 12px 0;
      display: inline-block;
    }
  }

  .processing-summary--miles-used {
    input {
      background-color: $white;
    }
  }

  .redeem-payment {
    position: relative;

    &__payment-option,
    &__submit {
      width: auto;
      float: left;
      display: inline-block;
    }

    &__payment-option {
      position: relative;
      transform: translateY(50%);
      margin-right: 20px;

      @media screen and (max-width: 767px) {
        transform: none;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__submit {
      @media screen and (max-width: 767px) {
        float: right;
      }

      .btn-member--submit {
        margin-top: 0;
      }
    }
  }

  .processing-summary {
    margin-top: 20px;

    .breakdown--list {
      padding-left: 17px;

      .breakdown--item {
        position: relative;
        margin-bottom: 5px;

        &:before {
          position: absolute;
          content: '';
          width: 100%;
          border-top: dashed 1px $lightgray;
          top: 50%;
          left: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: -1;
        }

        .description,
        .miles-used {
          display: inline-block;
          background-color: $white;
        }

        .description {
          padding-right: 15px;

          @media screen and (max-width: 767px) {
            padding-right: 10px;
            max-width: 40%;
          }
        }

        .miles-used {
          float: right;
          padding-left: 15px;

          @media screen and (max-width: 767px) {
            padding-left: 10px;
            float: none;
            position: absolute;
            max-width: 40%;
            text-align: right;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .processing-summary--credit-card-used {
    .label {
      margin-right: 10px;

      &:after {
        content: ':';
      }
    }
  }
}
