html {
  -webkit-backface-visibility: hidden;
  height: 100%;
  font-size: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  min-width: $max-container-width;
  max-width: 100%;
  min-height: 100%;
  background: #eee;
  font-family: $font-family;
  @include font-size(16);
  font-weight: normal;
  color: $black;
  position: relative;
  -webkit-backface-visibility: hidden;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.grayscale {
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: gray; /* IE6-9 */
    filter: grayscale(100%);
}

@include font-face('Gotham-Book','#{$font-path}/fonts/Gotham-Book', normal, normal);
@include font-face('Gotham-Book-Italic', '#{$font-path}/fonts/Gotham-BookItalic', normal, italic );
@include font-face('Gotham-Light', '#{$font-path}/fonts/Gotham-Light', normal, normal);
@include font-face('Gotham-Light-Italic', '#{$font-path}/fonts/Gotham-LightItalic', normal, italic);
@include font-face('Gotham-Medium', '#{$font-path}/fonts/Gotham-Medium', normal, normal);
@include font-face('Gotham-Medium-Italic', '#{$font-path}/fonts/Gotham-MediumItalic', normal, italic);

@include font-face('Custom-FA', '#{$font-path}/fonts/customfasocial', normal, normal, $exts: eot woff ttf svg);

* {
  -webkit-font-smoothing: $font-smoothing;
}

.container{
  padding: 0;
  width: 100%;
  position: relative;
}

.white-bkg{
  background: $white;
}

//A better looking default horizontal rule
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $gray;
  margin: 20px 0;
  padding: 0;
  clear: both;
}

//Remove default Paragraph styles.
p {
  margin: 0 0 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

//Remove the gap between images, videos, audio and canvas and the bottom of their containers
audio, canvas, img, svg, video {
  vertical-align: middle;
}

//Remove default fieldset styles.
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
  resize: vertical;
  overflow: auto;
}

//Removing The Dotted Outline
a {
   outline: 0;
   cursor: pointer;
   &:focus{
    outline: 0;
   }
}


/** CQ Resets **/
#CQ * {
  //@include box-sizing(content-box);
  fieldset {
    label {
      //@include inline-block;
    }
  }

  input {
    vertical-align: baseline;
  }
}


@include thailand {
 font-family: Arial Unicode MS, Arial, sans-serif; 
}

@include vietnam {
  font-family: Arial Unicode MS, Arial, sans-serif; 
}

@include korea {
  font-family: Arial Unicode MS, Arial, sans-serif; 
}

@include japan {
  font-family: MS PGothic, sans-serif; 
}

@include china {
  font-family: Arial Unicode MS, Arial, sans-serif;  
}

@include indonesia {
  font-family: 'Gotham-Book', Arial Unicode MS, Arial, sans-serif; 
}

@include malaysia {
  font-family: 'Gotham-Book', Arial Unicode MS, Arial, sans-serif; 
}