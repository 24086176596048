.deals-popup {
  position: relative;
  background: $white;
  padding: 20px;
  width: auto;
  max-width: 100%;
  margin: 20px 10%;

  @include respond-to(ipad-portrait) {
    margin: 20px 5%;
  }

  h3 {
    color: $blue;
    border-bottom: 1px solid $lightgray;
    line-height: rem(20);
    padding-bottom: 15px;
  }

  .mfp-close {
    color: $blue;
    opacity: 1;
    @include font-size(32);
    top: 6px;
    right: 8px;
    cursor: pointer;
  }

  .booking-form--title {
    @include font-size(14);
    font-family: $font-family-medium;
  }

  .date-form--title {
    margin-left: 7px;
    margin-top: 12px;
    color: #61666F;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    z-index: 8;
    pointer-events: none;
    display: block;
    max-width: initial;
    margin-bottom: initial;
    font-weight: initial;
  }

  .city-country--list {
    display: inline-block;
    clear: both;
    margin-bottom: 10px;
    @include font-size(20);

    .city-name {
      float: left;
      margin-right: 5px;
      font-family: $font-family-medium;
    }

    .comma {
      float: left;
      font-family: $font-family-medium;
      margin-right: 5px;
    }

    .iata {
      float: left;
      font-family: $font-family-medium;
    }

    .country-name {
      float: none;
      display: block;
      width: auto;
      font-family: $font-family;
      clear: both;
    }
  }

  $travelcostwidth-md: 30%;
  $travelcostwidth-lg: 50%;
  $traveldirectionwidth: 100px;

  .travel-location-from {
    @media screen and (min-width: 768px) {
      padding-right: 0;
    }
  }

  .travel-location-to {
    @media screen and (min-width: 768px) {
      padding-left: 0;
    }
  }

  .travel-location-from,
  .travel-location-to {
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  .travel-direction {
    position: relative;
    text-align: center;
    margin-top: 35px;
    @include respond-to(ipad-portrait) {
      margin-top: 25px;
    }
    .flight-trip--icon {
      width: 30px;
      height: 30px;
    }
    @media screen and (min-width: 768px) {
      width: $traveldirectionwidth;
    }
  }
  .travel-cost {
    @media screen and (min-width: 768px) {
      width: auto;
    }
    float: right;
  }
  .currency-price {
    display: block;
    clear: both;
    @include font-size(32);
    text-align: right;
    padding-top: 6px;
    .currency-name {
      display: inline-block;
      font-family: $font-family-medium;
    }
    .price-amount {
      display: inline-block;
      font-family: $font-family-medium;
      margin-left: 0;
    }
  }
  .cabin-class--type,
  .trip-type {
    display: inline-block;
    font-family: $font-family-light;
  }
  .calendar-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    position: relative;

    .calendar-icon--container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    .departure-date--icon,
    .return-date--icon {
      position: relative;
      height: 100%;
    }

    .fa-calendar {
      position: absolute;
      right: 20px;
      top: 15px;
      z-index: 8;
      pointer-events: none;
      display: block;
      max-width: initial;
      margin-bottom: initial;
      font-weight: initial;
      cursor: pointer;
      color: $midgray;
    }

    .dep-return--title {
      position: absolute;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: #61666F;
      z-index: 7;
      pointer-events: none;
      display: block;
      max-width: initial;
      margin-bottom: initial;
      font-weight: initial;
      width: calc(100% - 20px);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .dep-title,
      .one-dep-title {
        position: relative;
        width: 53.5%;
        padding-left: 15px;
        @include respond-to(1440) {
          width: 54%;
        }
        &.input-filled {
          margin-top: 5px;
          @include font-size(12);
        }
      }
      .ret-title {
        margin-left: 6px;
        position: relative;
        &.input-filled {
          margin-top: 5px;
          @include font-size(12);
        }
      }
    }
    &.has-error {
      .dep-return--title {
        .date-form--title {
          color: $errorred;
        }
      }
      .form-control {
        border: 1px solid $errorred;
      }
    }

    .departureDate,
    .returnDate {
      color: $blue;
      box-shadow: none;
      background: #fff;
      text-align: left;
    }

    &.returnTrip {
      .departure-date--icon,
      .return-date--icon {
        width: 50%;
      }

      .departureDate,
      .returnDate {
        width: 50%;
      }

      .departureDate {
        padding: 16px 0 0 20px;
        border-right: 1px solid $white;
        @include border-radius(50px 0 0 50px);
      }

      .returnDate {
        padding: 16px 0 0 16px;
        border-left: 1px solid $lightgray;
        @include border-radius(0 50px 50px 0);
        cursor: pointer;
      }
    }

    &.oneWayTrip {
      .departure-date--icon {
        width: 100%;;
      }

      .return-date--icon {
        display: none;
      }

      .departureDate {
        padding: 16px 0 0 20px;
        width: 100%;
      }
    }
  }
  #passenger-details--deals {
    display: block;
    width: 98.6%;
    float: left;
    margin: 15px 0 0 15px;
    .col-lg-4 {
      padding: 0;
      width: 21%;
      margin-right: 10%;
      @include respond-to(ipad-portrait) {
        margin-right: 0 !important;
        margin-bottom: 15px !important;
      }
      &:last-child {
        margin-right: 0;
      }
      .col-lg-12 {
        padding: 0;
        .col-lg-7 {
          padding: 0;
          width: 62%;
          @include respond-to(1410) {
            width: 58%;
          }
          @include respond-to(1240) {
            width: 56%;
          }
        }
      }
    }
    .booking-category {
      @include font-size(16);
    }
    .booking-category--type {
      @include font-size(12);
    }
    .booking-person {
      $button-size: 32px;
      padding: 0;
      height: 60px;
      .col-lg-7 {
        width: calc(100% - 10% - #{$button-size} - #{$button-size});
        padding: 0 0 0 5px;
      }
      .col-lg-1 {
        width: 10%;
        padding: 0;
        margin-top: 5px;
        input {
          text-align: center;
        }
      }
      .col-lg-2 {
        width: $button-size;
        padding: 0;
        top: 5px;
        &.btn-right {
          .input-group--btn {
            float: right;
          }
        }
      }
      button {
        @include border-radius(50px);
        width: $button-size;
        height: $button-size;
        padding: 0;
        margin: 0;
        background: $linkblue;
        border: 1px solid $linkblue;
        color: $white;
        &:disabled {
          background: $white;
          border: 1px solid $focusblue;
          color: $focusblue;
          opacity: 1;
        }
      }
      .booking-category {
        @include font-size(16);
        color: $gray;
        input {
          border: 0;
          box-shadow: none;
          width: 100%;
          padding: 2px 0 0;
          margin: 3px 0 0;
          float: left;
          color: $gray;
          @include font-size(16);
          font-family: $font-family-medium;
          height: 23px;
          margin-right: 5px;
          background: $white;
        }
      }
      .booking-category--type {
        @include font-size(12);
        color: #797979;
      }
    }
  }
  .deals-book--now {
    width: 20%;
    float: right;
  }
  .infant-booking--error,
  .adult-booking--error {
    display: none;
    color: $errorred;
    @include font-size(12);
  }
  .deals-error--validation {
    border-bottom: 1px solid $errorred;
    margin: 0 15px 15px;
    padding: 10px 0;
    display: none;
    width: 100%;
    color: $errorred;
    .fa {
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1 {
      padding-right: 0;
      width: 5%;
      @include respond-to(tablet-portrait) {
        float: left;
        padding-left: 0;
      }
    }
    .col-lg-11 {
      padding-left: 0;
      @include respond-to(tablet-portrait) {
        padding-left: 25px;
      }
    }
    .error-msgs {
      @include font-size(14);
      color: $gray;
    }
    #result {
      @include font-size(14);
      color: $gray;
    }
    .deals-validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }
}

.terms-and-conditons {
  margin: 20px 0 10px;
  .col-lg-12 {
    padding: 0;
  }
  h6 {
    @include font-size(18);
    text-transform: initial;
    border-top: 1px solid $lightgray;
    border-bottom: 1px solid $lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    .fa {
      &.fa-angle-down,
      &.fa-angle-up {
        position: absolute;
        right: 20px;
        color: $blue;
        top: 15px;
      }
      &.fa-angle-up {
        display: none;
      }
    }
  }
  p,
  li {
    margin: 5px 0;
    font-size: 1.165em;
    color: $gray;
  }
  .tc-content {
    display: none;

    p {
      @include font-size(14);
    }

    table {
      font-size: 12px;
      width: 100%;

      td {
        padding-bottom: 5px;

        &:first-child {
          // font-weight: bold;
          // font-family: 'Gotham-Medium';
          line-height: 1.2;
          // padding-right: 5px;
          // text-align: right;
        }

        &:not(:first-child) {
          // padding-left: 15px;
        }
      }
    }
  }
}

@include respond-to(1200) {
  .deals-container {
    &.detailed-container {
      .deals-content--container {
        .col-lg-6 {
          width: 50%;
        }
        .deals-price {
          .deals-price--content {
            padding: 5% 10%;
          }
        }
      }
    }
    .deals-show--more {
      padding: 2% 36% 1% !important;
    }
  }
}

@include respond-to(1250) {
  .deals-popup {
    .col-lg-4 {
      width: 40% !important;
    }
    .col-lg-2 {
      &.last-item {
        width: 0 !important;
      }
    }
    .city-country--list {
      @include font-size(16);
    }
    .currency-price {
      @include font-size(28);
    }
    .cabin-class--type,
    .trip-type {
      @include font-size(14);
    }
    #passenger-details--deals {
      .col-lg-3 {
        width: 45% !important;
        margin-bottom: 15px;
      }
    }
  }
}

@include respond-to(1140) {
  .deals-popup {
    .col-lg-4 {
      width: 40% !important;
    }
    .col-lg-2 {
      &.last-item {
        width: 0 !important;
      }
    }
  }
}

@include respond-to(1050) {
  .deals-popup {
    .city-country--list {
      @include font-size(14);
    }
    .currency-price {
      @include font-size(24);
    }
    #passenger-details--deals {
      .col-lg-3 {
        width: 45% !important;
        margin-bottom: 15px;
      }
    }
  }
}

@include respond-to(980) {
  .deals-popup {
    .col-lg-3 {
      &.travel-direction {
        text-align: center;
        padding: 0;
      }
      &:last-child {
        .currency-price {
          padding: 0 0 15px 0;
        }
      }
    }
    .col-lg-4 {
      width: 50% !important;
    }
    .col-lg-2 {
      &.last-item {
        width: 0 !important;
      }
    }
  }
}

@include respond-to(880) {
  .deals-container {
    &.detailed-container {
      .more-deals--container {
        .deals-price--container {
          .deals-list--items {
            .more-deals {
              .btn-secondary {
                padding: 12px 12px 14px !important;
              }
            }
          }
        }
      }
    }
    .deals-content--container {
      .btn-secondary {
        @include font-size(12);
      }
    }
  }
}

@include respond-to(800) {
  .deals-popup {
    .col-lg-2 {
      width: 25%;
    }
  }
}

@include respond-to(tablet-portrait) {
  .deals-popup {
    margin: 20px 5%;
    .col-lg-4 {
      width: 100% !important;
    }
    .col-lg-3 {
      width: 50%;
      float: left;
      &.travel-direction {
        display: none;
      }
      &.travel-cost {
        width: 100%;
      }
      .city-country--list {
        margin-bottom: 0;
      }
      .currency-price {
        padding: 0 0 15px 0;
      }
    }
    .col-lg-2 {
      width: 47%;
      float: left;
      border-top: 1px solid $lightgray;
      border-bottom: 1px solid $lightgray;
      padding: 10px 15px;
      &:first-child {
        margin-left: 15px;
        padding-left: 0;
      }
      &:nth-child(2) {
        margin-right: 15px;
      }
      &.no-border {
        border: 0;
      }
    }
    .calendar-container {
      .dep-return--title {
        .dep-title {
          width: 55%;
        }
      }
    }
    #passenger-details--deals {
      .col-lg-4 {
        width: 45% !important;
        float: left;
        margin-bottom: 15px;
        height: 100px;
        &:nth-child(even) {
          margin-right: 0;
        }
        .col-lg-12 {
          .col-lg-7 {
            width: 100%;
          }
          .col-lg-2 {
            float: left;
            border: 0;
            &.btn-left {
              margin-right: 0;
            }
          }
          .col-lg-1 {
            float: left;
          }
        }
      }
    }
    .deals-book--now {
      width: 30%;
    }
  }
  .deals-mobile {
    display: block !important;
  }
}

@include respond-to(650) {
  .deals-popup {
    .col-lg-2 {
      width: 46%;
    }
  }
}

@include respond-to(500) {
  .deals-popup {
    .col-lg-2 {
      width: 45%;
    }
    #passenger-details--deals {
      .col-lg-4 {
        .col-lg-2 {
          margin-right: 5px !important;
        }
        .col-lg-1 {
          margin-right: 5px !important;
        }
      }
    }
    .deals-book--now {
      width: 45%;
    }
  }
}

@include respond-to(475) {
  .deals-popup {
    #passenger-details--deals {
      .booking-person {
        .booking-category {
          @include font-size(14);
        }
      }
    }
    .terms-and-conditons {
      h6 {
        @include font-size(14);
      }
    }
  }
}

@include respond-to(410) {
  .deals-popup {
    .col-lg-2 {
      width: 44%;
    }
  }
}

@include respond-to(320) {
  .deals-popup {
    margin: 20px 0;
    h3 {
      @include font-size(26);
    }
    .col-lg-2 {
      width: 42%;
      padding: 10px;
    }
    .booking-form--title {
      @include font-size(12);
    }
    .deals-book--now {
      width: 50%;
    }
  }
}
