.mobileapp {
  width: 100%;
  padding: 10px;
  border-bottom: 2px solid $blue;
  display: none;
  .mobile-app--close{
    float: left;
    width: 7%;
    padding: 15px 0;
    color: $gray;
    @include font-size(20);
    @include respond-to(ipad-pro){
      padding: 20px 20px 0 !important;
      @include font-size(30);
    }
    @include respond-to(ipad-portrait){
      padding: 30px 10px 0;
      @include font-size(30);
    }
    @include respond-to(360){
      padding: 10px 0;
    }
    @include respond-to(320){
      @include font-size(16);
      padding: 10px 0;
    }
  }
  .mobile-app--logo{
    float: left;
    width: 15%;
    margin-right: 15px;
    img{
      width: 100%;
      @include respond-to(ipad-pro){
        width: 60%;
      }
    }
  }
  .mobile-app-content{
    width: 40%;
    float: left;
    margin-right: 15px;
    margin-top: 10px;
    @include font-size(14);
    @include respond-to(ipad-pro){
      margin-top: 40px;
    }
    @include respond-to(ipad-portrait){
      margin-top: 30px;
    }
    @include respond-to(375){
      width: 39%;
    }
    @include respond-to(360){
      margin-top: 8px;
    }
    @include respond-to(320){
      margin-top: 5px;
      @include font-size(13);
      line-height: 14px;
      width: 35%;
    }
  }
  .mobile-app--button{
    width: 30%;
    float: left;
    margin-top: 7px;
    @include respond-to(ipad-pro){
      margin-top: 22px !important;
    }
    @include respond-to(ipad-portrait){
      margin-top: 30px;
    }
    @include respond-to(360){
      margin-top: 3px;
    }
    @include respond-to(320){
      margin-top: 5px;
      width: 32%;
      a{
        @include font-size(12);
        &.btn-primary{
          padding: 10px 20px;
        }
      }
    }
  }
}
//-  Task 8590 code starts here
#mobileapp-popup{
  background: white;
  padding: 16px;
  display: none;
  .mobile-app-popup-content p
  {
    padding-top: 12px;
  }
  .mobile-app-popup-content a{
    width: 147px;
    margin-left: 8px;
  }
}
//-  Task 8590 code ends here

