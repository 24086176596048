.enrich_benefits_banner {
  $bg-intensity: 0.45;
  $bg-intensity-mob: 0.55;

  position: relative;
  padding: 95px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $lightgray;

  @include respond-to(767) {
    padding: 60px 0;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    background-image: radial-gradient(closest-side at 49% 112%, rgba($black, $bg-intensity) 47%, rgba($black, 0.00) 100%);
    width: 100%;
    height: 100%;
    content: '';

    @include respond-to(767) {
      background-image: radial-gradient(closest-side at 49% 112%, rgba($black, $bg-intensity-mob) 47%, rgba($black, 0.00) 100%);
    }
  }

  .banner-content {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 600px;
  }

  .heading {
    text-align: center;
    margin-bottom: 30px;

    .title {
      margin-bottom: 10px;
      color: $white;
      font-family: $font-family-light;
      font-size: rem(36);
      line-height: rem(40);
      font-weight: 400;

      @include respond-to(767) {
        font-size: rem(30);
        line-height: rem(36);
      }
    }

    .subtitle {
      margin-bottom: 0;
      color: $white;
      font-family: $font-family-light;
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 400;
    }
  }

  .actions {
    text-align: center;
    a[class*='btn-enrich'] {
      margin-right: 10px;
      display: inline-block;
      width: auto;

      &:last-child {
        margin-right: 0;
      }
    }
    .columncontrol{
      .col-sm-6{
        width: 47%;
        float: left;
        padding: 0 5px;
        &:first-child{
          margin: 0 0 0 8px !important;
        }
        .cta-button{
          .btn{
            width: 100% !important;
          }
        }
      }
    }
  }
  .banner-cta{
      text-align: center;
      position: relative;
      top: 80px;
      a.btn.btn-primary{
        width: auto;
      }
      @media screen and (max-width: 767px){
        top:60px;
        padding-top: 30px;
        a.btn.btn-primary{
          padding: 10px 15px;
        }
      }
  }
}

