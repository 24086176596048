// temp note
.members-tempnote {
  $icon-width: 41px;
  $note-color: $blue;

  width: 100%;
  border: solid 1px $note-color;
  display: inline-block;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 40px;

  &__icon {
    width: $icon-width;
    float: left;
    padding-right: 20px;

    .fa {
      font-size: rem(24);
      color: $note-color;
    }
  }

  &__content {
    width: calc(100% - #{$icon-width});
    float: left;
    color: $note-color;

    .title {
      color: $note-color;
      margin-top: -7px;
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
        font-size: rem(21);
        line-height: 1.2
      }
    }

    .description {
      @media screen and (max-width: 767px) {
        font-size: rem(14);
      }
    }

  }

  @mixin tempnote-colors($note-color) {
    border: solid 1px $note-color;

    .members-tempnote__icon .fa {
      color: $note-color;
    }

    .members-tempnote__content {
      color: $note-color;

      .title {
        color: $note-color;
      }
    }
  }

  &--warning {
    $note-color: $darkgray;

    @include tempnote-colors($note-color);

    background-color: $seashell;
  }

  &--error {
    $note-color: $red;

    @include tempnote-colors($note-color);

    background-color: $white;
  }


  &--success {
    $note-color: $green;

    @include tempnote-colors($note-color);
  }
}
