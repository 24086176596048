/** Contact -- Form Styles */
.contact-forms {
  $form-margin: 15px;
  margin-top: $form-margin;
  .contact-form
  {
    padding-right: 0px;
    #product-service
    {
      .row
      {
        margin-right: -17px;
      }
      
    }
  }
  iframe {
    @include respond-to(tablet-portrait) {
      height: 1560px;
    }
  }
  .contact-product--recaptcha {
    padding-left: 0;
  }
  h3, h4{
    font-family: $font-family-medium;
    line-height: 1em;
    margin-bottom: 5px;
    em{
      @include font-size(13);
    }
  }
  h5{
    font-family: $font-family-medium;
    @include font-size(16);
    line-height: 1em;
    color: $gray;
    text-transform: initial;
    margin-top: 20px;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    clear: both;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
    .mand{
      color: $errorred;
    }
  }
  p{
    @include font-size(14);
    text-align: left;
    &.subtitle{
      @include font-size(12);
      line-height: 1em;
      margin: 3px 10px;
    }
  }
  ul{
    li{
      @include font-size(14);
    }
  }
  a{
    &.submit{
      width: auto;
      min-width: 30%;
      @media screen and (max-width: 767px) {
        display: block;
        margin-top: 20px;
      }
    }
  }
  .g-recaptcha{
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .form {
    padding: 0;
    //margin-top: 20px;
    margin-bottom: 40px;

    // grid column padding fix
    .row {
      margin-left: $form-margin / -2;
      margin-right: $form-margin / -2;
    }

    // set to 10px padding size only
    @for $i from 1 through 12 {;
      .col-xs-#{$i},
      .col-sm-#{$i},
      .col-md-#{$i},
      .col-lg-#{$i}, {
        padding-left: $form-margin / 2;
        padding-right: $form-margin / 2;
      }
    }

    label.required,
    .label.required {
      &:after {
        content: '*';
        font-family: $font-family;
        color: $errorred;
      }
    }

    div:not(.checkbox):not(.radio):not(.radio-group):not(.file-upload):not([class*="dataTables_"]) > label {
      @include font-size(14);
      position: absolute;
      z-index: 2;
      margin-left: 20px;
      margin-top: 14px;
      color: $darkgray;
      -webkit-transition: all 90ms ease-in-out;
      -o-transition: all 90ms ease-in-out;
      transition: all 90ms ease-in-out;


      @media screen and (max-width: 767px) {
        font-size: rem(12);
      }

      &.input-filled {
        margin-top: 6px;
        margin-left: 22px;
        @include font-size(12);

        @media screen and (max-width: 767px) {
          font-size: rem(11);
        }
      }
    }

    input:not([type='checkbox']):not([type='radio']):not([type='file']):not([type='range']) {
      @include border-radius(50px);
      // position: relative;
      // z-index: 1;
      padding: 14px 20px 13px;
      height: 47px;
      color: $blue;

      &:focus {
        border: 1px solid $blue;
        box-shadow: none;
        outline: none;
      }

      &[readonly] {
        background: $white;
      }

      &.input-focused {
        padding: 21px 20px 4px 21px;

        &.input--with-prefix {
          padding-left: 45px;
        }
      }
    }

    input[type='file'] {
      line-height: 1;
    }

    textarea {
      &.input-focused {
        padding-top: 21px;
      }

      &.form-control {
        border-radius: 20px;
      }
    }

    .bootstrap-filestyle.input-group {
      $inputwidth: 70%;

      input[type='text'] {
        width: $inputwidth;
        float: left;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      span.group-span-filestyle {
        width: 100% - $inputwidth;
        float: left;

        .btn {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          padding: 14px 20px;
        }
      }
    }

    .radio,
    .radio-group,
    .checkbox,
    .checkbox-group {
      font-size: rem(14);
    }

    .radio,
    .checkbox {
      margin-bottom: $form-margin / 2;
      margin-top: $form-margin / 2;
    }

    .form-group {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-bottom: $form-margin;
      float: left;
      @media screen and (max-width: 767px) {
        // margin-bottom: 25px;
        display: block;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.input-focused span.prefix,
      > .row > .input-focused span.prefix {
        transform: translateY(8px);
        opacity: 1;
      }

      > .row > .col-xs-12 {
        span.prefix {
          left: $form-margin / 2;
        }
      }

      .col-lg-6 {
        @media screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      input:not([type='radio']):not([type='checkbox']) {
        z-index: 1;
        position: relative;
      }

      .radio input[type="radio"],
      .radio-inline input[type="radio"] {
        //top: 2px;
      }

      .checkbox input[type="checkbox"],
      .checkbox-inline input[type="checkbox"] {
        //top: 1px;
        margin-left: -22px;
        width: 20px;
        height: 20px;
      }

      .datepicker[readonly='readonly'] {
        position: relative;
        background-color: $white;
        z-index: 1;

        &:hover {
          cursor: pointer;
        }
      }

      .datepicker-icon {
        position: absolute;
        text-align: center;
        right: 20px;
        top: (47px - 22px) / 2;
        z-index: 2;
        color: $midgray;
      }

      [class*='col-'] > .datepicker-icon {
        right: #{$form-margin / 2} + 20px;
      }

      .prefix {
        position: absolute;
        padding: 22px 0 5px 21px;
        left: 0;
        top: 0;
        opacity: 0;
        color: $blue;
        font-size: rem(14);
        z-index: 3;
      }

      .input-focused .prefix {
        opacity: 1;
      }

      .helptext {
        font-size: rem(12);
        color: $blue;
        padding-left: 20px;
      }

      .row {
        margin-bottom: $form-margin;
        .contact--file-upload {
          margin-top:10px;
          &:nth-child(1),
          &:nth-child(2) {
            margin-top:0;
          }
          input[class='error-validation'] {
            padding-top: 2px;
            padding-bottom: 2px;
            &:before {
              top: 10px;
            }
          }
          .error-validation {
            margin-top:0!important;
            &:before {
              top: 10px;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .incrementer-group {
        @supports (display: flex) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
        }

        .label {
          width: auto;
          display: inline-block;
          float: left;
          margin-right: 20px;
          margin-bottom: 20px;

          @supports (display: flex) {
            float: none;
            margin-bottom: 0;
          }

          @media screen and (max-width: 479px) {
            min-width: calc(100% - 94px);
            margin-right: 0;
          }
        }

        .incrementer {
            float: left;

          @supports (display: flex) {
            float: none;
          }
        }
      }

      .incrementer {
        $incrementer-size: 32px;
        min-width: #{$incrementer-size + $incrementer-size + 30px};

        display: inline-block;

        @supports (display: flex) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__btn {
          display: block;
          -webkit-transition: all 0.25s ease-in-out;
          -o-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out;
          border-radius: 50%;
          background-color: $blue;
          color: $white;
          border: solid 1px $blue;
          width: $incrementer-size;
          height: $incrementer-size;
          text-align: center;
          float: left;

          @supports (display: flex) {
            float: none;
          }

          &:hover,
          &:active,
          &:focus {
            background-color: $countdownblue;
          }

          i {
            line-height: $incrementer-size;
          }
        }

        &__display {
          display: block;
          min-width: 30px;
          text-align: center;
          line-height: $incrementer-size;
          color: $black;
          font-size: rem(14);
          float: left;
          padding-left: 3px;
          padding-right: 3px;

          @supports (display: flex) {
            float: none;
          }
        }
      }
      .selectize-control{
        height: 48px;
        @include respond-to(tablet-portrait){
          margin-bottom: 15px;
        }
      }
      > .selectize-control,
      > .row > [class*='col-'] > .selectize-control,
      .phoneinput,
      .phoneinput__countrycode > .selectize-control {
        margin-bottom: -6px; // offset amount for weird float on selectize element
      }
    }

    .contact--documents-upload {
      .error_msg {
        padding-left: 20px;
      }
    }

    fieldset {
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }

      legend {
        border-bottom: 0;
        color: $blue;
        font-size: rem(16);
        line-height: inherit;
        font-weight: 600;
        font-family: $font-family-medium;
        color: $black;
      }

      h4 {
        font-size: rem(21);
        color: $blue;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: inherit;
        display: inline-block;
        clear: both;
        width: 100%;
      }

      h4.subtitle {
        font-size: rem(14);
        font-weight: 600;
        font-family: $font-family-medium;
        color: $black;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    .fieldset--confirmation-checkbox {
      margin-bottom: 10px;
    }

    .phoneinput {
      $countrycode-width: 40%;
      position: relative;
      display: inline-block;
      width: 100%;

      &__countrycode,
      &__phonenumber {
        display: block;
        float: left;
      }

      &__countrycode {
        width: $countrycode-width;

        .selectize-control .selectize-input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__phonenumber {
        width: 100% - $countrycode-width;

        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0;
        }
      }
    }

    .form-value-slider {
      .rangeslider__handle {
        box-shadow: none;
      }

      .rangeslider,
      .rangeslider__fill {
        border-radius: 0;
        border: 0;
        box-shadow: none;
      }

      .rangeslider {
        margin-top: 15px;
        margin-bottom: 20px;
        background-color: $seashell;
        border-radius: 20px;
      }

      .rangeslider__fill {
        background-color: $blue;
        border-radius: 20px;
      }
    }

    .form-footnotes {
      font-size: rem(12);

      .notes {
        padding-left: 25px;

        li {
          padding-left: 10px;
          margin-bottom: 3px;
        }
      }
    }

    .error-validation {
      margin-left: 0;
      margin-right: 0;
      position: relative;
      padding-left: 15px + 14px + 10px;
      padding-right: 20px;
      color: $errorred;
      width: 100%;
      &:before {
        content: '\f071';
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 10px;
        left: 15px;
        width: 14px;
        color: $red;
      }

      p {
        font-size: rem(14);
      }

      a {
        color: $errorred;
      }

      .field-name {

        text-transform: capitalize;
        font-family: $font-family-medium;
      }
    }

    .error_msg {
      color: $red;
      font-size: rem(12);
      margin-top: 2px;
      // display: inline-block;
      display: none;
      width: 100%;
    }

    input + .error_msg,
    select + .error_msg,
    .selectize-control + .error_msg,
    .datepicker-icon + .error_msg,
    .phoneinput .error_msg,
    .bootstrap-filestyle + .error_msg {
      padding-left: 20px;
    }

    .selectize-input {
      input {
        height: auto !important;
        height: initial !important;
      }
    }

    select {
      -webkit-appearance: none;
      background-clip: padding-box;
      padding: 14px 20px 13px;
      height: 47px;
      color: #006DBB;
      width: 100%;
      background-color: $white;
      border-color: $lightgray;
      border-radius: 25px;
    }

    .has-error,
    [has-error] {
      .error_msg {
        display: inline-block;
      }

      .selectize-control .selectize-input,
      .form-control {
        border-color: $red;
      }

      .label,
      label {
        color: $red !important;

        a {
          color: $red;
          text-decoration: underline;
        }
      }

      .parsley-errors-list {
        padding-left: 15px;
        display: none;
      }
    }

    .checkbox,
    .radio {
      .has-error {
        color: $red;
      }
    }
  }

  .form-actions {
    display: inline-block;
    width: 100%;

    .btn-member--cta,
    .btn-member--submit {
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .country-code--number{
    @include respond-to(tablet-portrait){
      width: 100%;
      display: block;
      clear: both;
      height: auto;
      //overflow: hidden;
      margin-bottom: 0 !important;
    }
    .col-lg-6{
      padding: 0;
      @include respond-to(tablet-portrait){
        width: 50%;
        float: left;
        margin-bottom: 0 !important;
      }
      select,
      .selectize-input{
        @include border-radius(25px 0 0 25px);
        &.dropdown-active{
          border-radius: 25px 0 0 0;
        }
      }
      input[type='text']{
        border-radius: 0 50px 50px 0 !important;
        -webkit-border-radius: 0 50px 50px 0 !important;
        -moz-background-clip: padding-box;
        -moz-border-radius: 50 50px 50px 0 !important;
        border-left: 0;
      }
    }
  }
  .form-submission{
    margin: 35px 0;
    h3, p{
      text-align: center;
    }
    .form-submission--success {
      h3, p{
        text-align: left!important;
      }
    }
    .form-submission--success, .form-submission--fail{
      display: none;
    }
  }
  .parsley-errors-list {
    display: none;
  }
}

#contact-reservation-form-submit-theIframe{
  margin-top: 25px;
}

.ui-datepicker{
  &.contact-date{
   .ui-datepicker-prev, .ui-datepicker-next{
    top: 20px;
   }
  }
}

.contact-form--container{
  width: 100%;
  //padding: 0 20% 0 0;
  @include respond-to(tablet-portrait){
    padding: 0;
  }
  h1{
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  $sec-nav-bg: $white;
  $sec-nav-menu-width: auto;
  .sec-navigation-wrapper {
    flex-grow: 1;
    display: flex;
    padding: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
	align-items: center;
	justify-content: center;
	
    @media screen and (min-width: 1200px) {
      width: $sec-nav-menu-width;
    }
    .sec-navigation {
      display: block;
      padding: 0;
      position: relative;
      overflow-x: auto;
      //text-align: center;
      // margin-bottom: -5px;

      &::-webkit-scrollbar { display: none; }

      @media screen and (max-width: 1199px) {
        overflow-x: scroll;
        -webkit-user-select: none;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
      }

      ul {
        position: relative;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 100%;
        @include respond-to(1200){
          width: auto;
        }
        // prevent selection
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */

        &:hover,
        &:focus {
          cursor: pointer;
        }

        li {
          list-style-type: none;
          display: inline-block;
          margin: 0;
          padding: 0 20px 0 20px;
          text-align: center;
          vertical-align: middle;
          // margin-bottom: -2px;
          &:first-child {
            padding-left: 15px;
            @media screen and (max-width: 479px) {
              padding-left: 30px;
            }
          }

          &:last-child {
            padding-right: 30px;
          }

          a {
            float: left;
            @include font-size(16);
            font-family: $font-family-medium;
            text-align: center;
            &:hover {
              color: $errorred;
            }
          }

          .btn-primary {
            text-align: center;
            margin-top: 0;
            font-family: $font-family;
            padding: 12px 30px 14px;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
  ul{
    &.nav-tabs{
      padding: 0 6% 1px 8% !important;
      //margin-bottom: 30px;
      li{
        float: none;
        a{
          font-family: $font-family-medium;
          color: $countdownblue;
          @include font-size(14);
          padding: 15px 14px;
          &:hover{
            background: transparent;
            border-top: 1px solid $white;
            border-left: 1px solid $white;
            border-right: 1px solid $white;
          }
        }
        &.active{
          margin-top: 3px;
          a{
            border: 0;
            color: $errorred;
            padding: 13px 15px;
            border-bottom: 3px solid $errorred;
            &:before{
              height: 0;
            }
          }
        }
        &:before{
          background: $errorred;
        }
      }
    }
  }
  #corporate-travel--form{
    textarea{
      height: 80px;
    }
  }
  .contact--mice-business-event, .contact-forms{
    h5 {
      margin-top: 15px;
      @include font-size(14);
      margin-bottom: 15px;
      &.info {
        color: $linkblue !important;
        margin:0!important;
      }
    }
  }
  table{
    width: 100%;
    border: 1px solid $lightgray;
    border-top: none;
    thead{
      th{
        &:first-child{
          width: 50%;
        }
        background: $blue;
        font-family: $font-family-medium;
        color: $white;
        border-bottom: 1px solid $white;
        border-right: 1px solid $white;
        padding: 10px;
      }
    }
    tbody{
      tr{
        td{
          text-align: center;
          padding: 10px;
          border: 1px solid $lightgray;
          input{
            width: 60%;
            margin: 0 auto;
            text-align: center;
          }
          &:first-child{
            text-align: left;
            font-family: $font-family-medium;
            @include font-size(14);
          }
        }
      }
    }
  }
  .organizer-request{
    table{
      tr{
        td{
          vertical-align: bottom;
          &:first-child{
            width: 70%;
          }
          &.subtitle{
            font-family: $font-family;
          }
          &:last-child{
            width: 30%;
            //text-align: center;
            input{
              margin: 0 auto;
              width: auto;
            }
          }
        }
      }
    }
  }
  .contact-publicity--others, .contact-returns--others, .other-details{
    display: none;
    input{
      width: 100% !important;
    }
  }
  .contact--mice-business-totalvalue{
    .contact--mice-totalvalue{
      input{
        float: left;
        width: 50%;
      }
    }
  }
  .separator{
    width: 100%;
    display: block;
    height: 30px;
    float: left;
  }
  .contact--mice-business-event, .contact--mice-held{
    h5{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@include respond-to(tablet-portrait) {
  .contact-forms{
    .table-responsive{
      input{
        &.form-control{
          padding: 14px 15px 13px !important;
          border-radius: 20px !important;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .contact--mice-speakers-delegates{
    .col-lg-4{
      margin-bottom: 15px;
    }
  }
  .contact--reservation-seatno, .contact--corporate-companysize, .contact--flightsimulator-interested{
    margin-bottom: 15px;
  }
}

// for iframe contact form
.scriptexecutor iframe {
    overflow: visible;

    @include respond-to(1200) {
      height: 1900px;
    }

    @include respond-to(tablet-portrait) {
      height: 2220px;
    }
}

#reservation-form
{
  .form-submission--fail,.form-submission--success
  {
    display:block;
  }
}
#member-contact-form {
  .member--message {
    margin-top: 12px;
  }
  .member--upload {
    margin-top: 12px;
  }
  .form-group.contact-form--submit {
    float: right;
  }
  .file-upload {
    input {
      width: 53%;
      background-color: #fff;
    }
    .group-span-filestyle {
      label {
        margin-bottom: 0;
        width: 27%;
        margin-top: 0px;
      }
    }
    .file-extenstion {
      font-size: 11px !important;
      font-style: italic !important;;
      color:  #61666f !important;;
      clear: both !important;;
      padding-left: 28px !important;;
    }
    .file-extenstion-error, .max-file-error {
        font-size: 12px !important;;
        padding-left: 25px !important;;
        color: #ed1c24 !important;;
    }
  }
}

@media (max-width: 767px)
{
  #member-contact-form
  {
    .file-upload 
    {
      .group-span-filestyle 
      {
        label 
        {
        width: 39%;
        }
      }
      input
      {
        width: 60%;
      }
    } 
  }
}