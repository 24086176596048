/**
 * Members Portal
 * - header
 */

// base styles for member portal
$members-sidebar-width: 300px;
$content-padding: 20px;
$navbar-height: 102px;

$nav-toggle-width: 46px; // nav toggle margin: 15px;
$nav-width-more-than-1200: calc(100% - #{120px + 120px});
$nav-left-margin-more-than-1200: 120px;
$nav-width-less-than-1200: 1200px;

.member-nav {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  float: left;
  border: 0;

  &__left,
  &__right {
    float: left;
  }

  &__left {
    width: 65%;

    @media screen and (max-width: 1200px) {
      width: calc(65% - #{$nav-toggle-width} - 20px);
    }

    // @media screen and (max-width: 479px) {
    //   width: calc(100% - #{$nav-toggle-width} - 20px);
    //   padding-right: 20px;
    // }
  }

  &__right {
    width: 35%;
    text-align: right;
    padding-right: 12.5px;

    // @media screen and (max-width: 479px) {
    //   width: 100%;
    //   padding-left: 20px;
    //   padding-right: 20px;
    // }
  }

  &__toggle-btn {
    border: 0;
    float: left;
    margin-right: 10px;
    margin-left: 10px;

    @media screen and (max-width: 767px) {
      padding-top: 6px;
      margin-top: 0;
      padding-bottom: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    &.navbar-toggle {
      .icon-bar {
        height: 3px;
        background-color: $enrich-neutral;

        .member--gold & {
          background-color: $enrich-gold;
        }

        .member--silver & {
          background-color: $enrich-silver;
        }

        .member--platinum & {
          background-color: $enrich-platinum;
        }

        .member--blue & {
          background-color: $enrich-blue;
        }
      }
    }
  }

  .navbar-header {
    margin-bottom: 10px;
    width: 100%;

    @media screen and (max-width: 767px) {
      padding: 10px 0;
      border-top: solid 1px $lightgray;
      border-bottom: solid 1px $lightgray;
      margin-bottom: 0;
    }
  }

  .sidebar-menu {
    @respond-to(1200) {
      width: 100%;
      float: left;
    }

    @media screen and (min-width: 1201px) {
      position: absolute;
      top: $navbar-height - 10px - 10px - 4px - 10px;
      left: 0;
      width: $members-sidebar-width;
    }

    &.navbar-collapse {
      padding-left: 0;
      padding-right: 0;

      &.collapse:not(.in) {
        @media screen and (min-width: 768px) {
          display: none !important;
          overflow: hidden !important;
        }
      }
    }

    &-item {
      &.active {
        a {
          font-family: $font-family-medium;
        }

        span {
          display: inline-block;
          padding-bottom: 2px;
          border-bottom: dashed 1px $white;
        }
      }

      .sidebar-menu-link {
        display: block;
        color: $white;
      }
    }
  }

  .member-nav--user-info {
    span {
      display: inline-block;
      float: left;
    }

    .user-name--member-type,
    .user-member-number {
      display: block;
      float: left;
      width: 100%;
    }

    .username {
      color: $gray;
      font-family: $font-family-medium;
      font-size: rem(20);
      line-height: rem(32);
      font-weight: 600;

      @media screen and (max-width: 1200px) {
        display: block;
        float: none;
      }

      @media screen and (max-width: 767px) {
        font-size: rem(14);
        line-height: rem(22);
      }
    }

    .user-member-number {
      color: $gray;
      font-family: $font-family;
      font-weight: 400;

      @media screen and (max-width: 767px) {
        font-size: rem(12);
      }

      .member-type-label:after {
        display: inline-block;
        margin-right: 3px;
        content: ':';
      }

      .member-number-value {
        display: inline-block;
        text-transform: uppercase;
        line-height: rem(24);
        font-size: rem(16);


      @media screen and (max-width: 767px) {
        font-size: rem(12);
        line-height: 1.42857;
      }
      }
    }
  }

  .member-nav--miles-info {
    margin-top: #{$navbar-height - 74px};
    color: $gray;

    @media screen and (max-width: 767px) {
      margin-top: 3px;
    }

    .miles-value {
      font-size: rem(20);
      line-height: rem(24);
      font-family: $font-family-bold;

      @media screen and (max-width: 767px) {
        display: block;
        font-size: rem(18);
        line-height: rem(21);
      }
    }

    .miles-label {
      margin-left: 6px;
      font-size: rem(20);
      line-height: rem(24);

      @media screen and (max-width: 767px) {
        display: block;
        font-size: rem(14);
        line-height: rem(22);
      }
    }
  }
}

.members__menu {
  list-style: none;
  padding-left: 20px;
  background-color: $enrich-neutral;

  &.sidebar-menu--level-1 > .sidebar-menu-item {
    border-top: solid 1px $white;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    > a {
      padding: 20px 0;
      font-family: $font-family-medium;
      font-weight: 600;

      @media screen and (max-width: 1200px) {
        padding: 20px;
      }

      @media screen and (max-width: 767px) {
        padding: 8px 16px;
        font-size: rem(13);
      }
    }
  }

  &:not(.sidebar-menu--level-1) > .sidebar-menu-item {

    a {
      position: relative;
      padding: 10px 0;

      @media screen and (max-width: 1200px) {
        padding-right: 20px;
      }

      @media screen and (max-width: 767px) {
        padding: 8px 16px;
        font-size: rem(13);
      }

      &:before {
        position: absolute;
        left: -20px;
        content: '-';
        width: 20px;
        text-align: center;
      }

      + ul {
        margin-top: 5px;
      }
    }
  }

  .member--gold & {
    background-color: #9f874a;
  }

  .member--silver & {
    background-color: #81858a;
  }

  .member--platinum & {
    background-color: #353335;
  }

  .member--blue & {
    background-color: #0065b3;
  }

  .sidebar-menu-item {
    i.fa {
      -webkit-transition: 0.25s all ease-in-out;
      -o-transition: 0.25s all ease-in-out;
      transition: 0.25s all ease-in-out;

      float: right;
      line-height: rem(22);
    }

    > .members__menu {
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    .sidebar-menu-link.collapsed {
      i.fa {
        -webkit-transform: scaleY(-1);
        -ms-transform: scaleY(-1);
        transform: scaleY(-1);
      }
    }
  }


  .sidebar-menu > & {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }
}

.members__nav {
  position: relative;
  z-index: 2;

  @media screen and (min-width: 768px) {
    margin-top: 40px !important;
  }

  @include respond-to(1440) {
    width: 100%;
    max-width: $nav-width-less-than-1200;
    margin: 0 auto;
  }

  @media screen and (min-width: 1441px) {
    width: $nav-width-more-than-1200;
    margin-left: $nav-left-margin-more-than-1200;
    margin-right: $nav-left-margin-more-than-1200;
  }
}

.member-type {
  margin-left: 5px;
  margin-top: 4px;
  margin-bottom: 4px;

  @media screen and (max-width: 1200px) {
    display: block;
    float: none;
    margin-left: 0;
    margin-top: 0;
  }

  label {
    font-family: $font-family-bold;
    font-weight: bold;
    padding: 3px 5px;
    font-size: rem(10);
    text-transform: uppercase;
    color: $white;
    line-height: rem(14);
    margin-top: 1px;
    margin-bottom: 1px;
    display: inline-block;
    background-color: $enrich-neutral;

    @media screen and (max-width: 767px) {
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;

    }

    .member--gold & {
      background-color: $enrich-gold;
    }

    .member--silver & {
      background-color: $enrich-silver;
    }

    .member--platinum & {
      background-color: $enrich-platinum;
    }

    .member--blue & {
      background-color: $enrich-blue;
    }
  }

}
