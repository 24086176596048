#container-message_board
{
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait)
{
    h4
    {
        font-size: 1.5rem!important;
        line-height: 36px!important;
    }
}
    .login-validator,.login-validator-success,.login-validator-failure,.login-validator-msgfailure
    {
        div
        {
            color: red;
        }
    }
    .form-content--wrapper
    {
        margin-right: 20px;
        margin-left: 20px;
    }
    #message-form-message
    {
        border-radius: 25px;
    }
    #message-form--submit
    {
        height: 15px;
        line-height: 0rem;
    }
    .camp-messages
    {
        margin-top: 15px;
        div
        {
            color:grey;
            // font-weight: 600;          
            text-align: justify;
            font-family: Gotham-Book,Arial;
            margin-top: -2px;
            font-size: 15px;

            span
            {
                color: black;
                font-weight: 400;
            }
        }
    }
    .message-down_arrow
    {
        text-align: center;
        height: 60px;
        .fa
        {
            padding-top: 15px;
            font-size: xx-large;
        }
    }
    .message-board-title
    {
        h3
        {
            border-bottom: 2px solid grey;          
            padding-left: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    @media (min-width: 768px)
    {
        width: 600px;  
    }
    border-radius: 5px;
    margin-bottom: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: white;
    .message_heading
    {
        background-image: linear-gradient(90deg,#557cc1,#e2738d) !important;   
        border-radius: 4px;     
        h4
        {
            color: white;
            margin: 0px;
            font-size: 20px;
        }
    }
    .message-heading--title
    {
        // border-bottom: 1px solid;
        text-align: center;
    }
    .message_title
    {
        font-size: 14px;
        span
        {
            font-size: 15px;
            font-family: 'Gotham-Book', Arial;
        }
    }
    .message-form--submit
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        #message-form--submit
        {
            background-image: linear-gradient(90deg,#557cc1,#e2738d) !important;   
        }
        @media (max-width: 768px)
        {
            #message-form--submit
            {
               width: 100% !important;
    
            }        }
    }
    .message-board--content
    {
        border-radius: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        margin-right: 20px;
        margin-left: 20px;
    }
    #message-board--messages
    {
        overflow-y: scroll;
        height: 800px;
    }
    #message-board--messages::-webkit-scrollbar {
        display: none;
    }
}