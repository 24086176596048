.backbutton
    {
        font-size: 14px;
	    color: #9ba3ab;
        @media screen and (max-width: 480px)
        {
            font-size: 11px;
        }
    }
#binfinitepoints-exchange-miles-form
{
    background: $white;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 10px; 
    .points-exchange-form-display {
        padding-left: 30px;
        @include respond-to(tablet-portrait) {
            padding-left: 0px;
        }
    }   
    .terms_partner
    {
        @media screen and (min-width: 768px)
        {
            margin-top: 25px;
        }
        li.active
        {
            border-bottom: 3px solid #ed1c24;
        }
        li
        {
            a
            {
                color: #005aaa;
                background-color: white;
            }
        }
    }
    .title_desp
    {
        margin-top: 15px;
        color: #0065b3;
        @media screen and (max-width: 767px)
        {
              margin-top:0px;
              float: right;
        }
    }
    .milesheading
    {
        width: 125px;
    }
    #bInfConvMilesTable, #sunwaypauls-ConvMilesTable
    {
        padding: 10px;
        margin-left: 50px;
        width: 55%;
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
        {
            padding: 10px;
            width: 55%;            
        }
        tbody
        {
            background: #fff;            
            display: inline-block;           
            height: auto;
            position: absolute;
            border-collapse: separate;
            border-spacing: 0 5px;           
            width: 70%;
            .milesheading {               
                border-radius: 10px;
                background: #0065b3;
                color: #fff;
                text-align: center;
                padding-left: 0px;
                width: 150px;
            } 
            .infiniterow {
                .milesheading, .milesdada {
                    border-radius: 10px;
                    background: #eee;
                    color: #000;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    .fa.fa-angle-right {
                        margin-right: 20px;
                        font-size: 18px;
                        font-weight: bold;
                        float: right;
                    }
                }
                .infiniteheading, .infinitydata {
                    background: #eee;
                    border-radius: 10px;
                    color: #000;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }         
        
            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
            {   
                background: #fff;                
                display: inline-block; 
                height: 200px;
                width: 235px;
            }
        }

    }
    #mob-bInfConvMilesTable, #mob-sunwaypauls-ConvMilesTable
    {

        tbody
        {
            background: #fff;            
            display: inline-block; 
            border-collapse: separate;
            border-spacing: 0 5px;           
            width: 100%;
            .mob-milesheading {               
                border-radius: 10px;
                background: #0065b3;
                color: #fff;
                text-align: center;
                padding-left: 0px;                
            } 
            .mob-infiniterow {
                .mob-milesheading, .mob-milesdada {
                    border-radius: 10px;
                    background: #eee;
                    color: #000;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    .fa.fa-angle-right {                        
                        font-size: 18px;
                        font-weight: bold;
                        float: right;
                    }
                }
                .infiniteheading, .mob-infinitydata {
                    background: #eee;
                    border-radius: 10px;
                    color: #000;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
                
            }         
            
        }
    }
    #mob-bInfConvMilesTable, #mob-sunwaypauls-ConvMilesTable {
        margin-top: 20px;
        @media screen and (max-width:767px){
            .mob-infiniterow .infiniteheading{
                padding:0 13vw;
                white-space: nowrap;
            }
        }
    }
    .binfinte_points
    {
        // margin-left: 250px;
        float: right;
        @media screen and (max-width: 767px)
        {
            float: right;
            margin-left: 0px;
        }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
        {
            // margin-left: 160px;
        }
    }
    .binfinte_points,.binfinte_acc,.enrich-miles-field
    {
        label{
            position: absolute;
            margin-top: -10px;
            background-color: #fff;
            margin-left: 20px;
            padding-left: 4px;
            padding-right: 4px;
            font-size: small;
            }
    }


    #partner-card-number,#mob-partner-card-number,#enrich-miles-field,#b-infinity-field,#sunwaypauls-partner-card-number,#sunwaypauls-enrich-miles-field, #sunwaypauls-field,#mob-sunwaypauls-partner-card-number
   {
        border-radius: 10px 10px 10px 10px;
    }
    .binifite_desp
    {
        background: #ccc;
        border-radius: 10px;
        padding: 20px;        
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;       
        b
        {
            margin-bottom: 0px;
        }
        img
        {
            width: 150px;
            @media screen and (max-width: 767px)
            {
            width: 100px;
            }
        }
        p
        {
            font-size: 14px;
            font-family: Gotham-Book,Arial;
            @media screen and (max-width: 767px)
            {
                font-size: 11px;
            }
        }
        @include respond-to(tablet-portrait) {
            text-align: center;
        }
    }
   /* partner-card-selctImage{
        img{
            width: 100%;
        }
    }*/
   /* .partner-crad-exchange{
        p {
            padding-top: 12px;
            text-align: center;
            font: bold;
        }
        img{
            width: 100%;
        }
    }*/
    .hideclass{
        display: none ;
    }
    .showclass{
        display: block ;
    }
    .partner-cardform-banner{
        img{
            width: 100%;
            //height: 160px;
        }
    }
    .points-exchange-form-step1, .Sunwaypauls-points-exchange-form-step1{
        margin-top: 40px;
    }
    .points-exchange-form-step2{
        margin-top: 15px;
    }
    .points-exchange-form-confirm-step3
    {
        margin-top: 15px;
    }
    table{
        padding: 10px;
        //border: 1px solid black; 
        margin-left: 100px;
        width: 55%;
    }
    thead{
        //border: 1px solid black;
        background-color: #006dbb;
        font: normal 15px/16px Gotham-Book,Arial;
        color: #fff;
    }
    tbody{
        //border: 1px solid black;
        background-color: #eee;
        font: normal 15px/16px Gotham-Book,Arial;
    }
    tr{
        height: 40px;
    }
    .milesheading{
        padding-left: 20px;
    }
    .mob-milesheading{
        width: 50%;
        padding-left: 20px;
        @media screen and (max-width:767px){
            width:100%;
        }
    }
    .infiniteheading{
        text-align: center;
    }
    .mob-infiniteheading{
        width: 50%;
        text-align: center;
        //padding-right: 50px;
    }
    .milesdada{
        padding-left: 40px;
    }
    .infinitydata{
        padding-left: 0px;        
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .points-exchange-column, .sunwaypauls-points-exchange-column {
            float: right;
            margin-right: 58px;
        }
    }
    .mob-milesdada {
        //padding-left: 30px;
        text-align: center;
    }
    .mob-infinitydata{
        //padding-left: 22px;
        text-align: center;
    }
    .heading-steps{
        font: normal 18px/30px Gotham-Book,Arial;
        font-weight: 600;
    }
    .incrementer-group.form-item{
        padding-top: 14px;
        font-family: Gotham-Book,Arial;
    }
    .drag-or-insert-div
    {
        margin-top: 15px;
    }
    .enrich-range-row {
        width: 65%;
        @include respond-to(tablet-portrait) {
            width: 100%;
        }
    }
    .points-exc-confirm--div
    {
        margin-top: 5px;
    }
    .mail-check-security-div--row{
        margin-top: 15px;
    }
    .rangecaldiv{
        margin-top: 20px;
        margin-left:-40px !important;
    }
    #label--points-exc-enrichmiles--lable, #label--points-exc-Binfinity--lable, #minmaxpoints {
        font-size: 15px;
    }
    #minmaxpoints1,#sunwaypaul-minmaxpoints1{
        padding-left: 33px;
        font-size: 15px;
    }
    .range-slider {
        -webkit-appearance: slider-horizontal;
        width: 100%;
        height: 12px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        margin-bottom: 30px;
      }
      
      .range-slider:hover {
        opacity: 1;
      }
      
      .range-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: #0065b3;
        cursor: pointer;
      }
      
      .range-slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #0065b3;
        cursor: pointer;
      }
      .points-exc-terms-conditions{
          font-size:20px;
          padding-left: 5px;          
          @include respond-to(ipad-portrait) {
              width: 65%;
          }
          @include respond-to(ipad-landscape) {
            width: 70%;
          }
          @include respond-to(ipad-pro) {
            width: 70%;
          }
          #points-exc--miles-terms-and-conditions {
            vertical-align: middle;
          }
          #termscondiText{
              font-size: 17px;
              padding-top: 5px;
              padding-left: 0px;
          }         
          label {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            #points-exc--miles-terms-and-conditions {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;                
                border-radius: 15px;
                border: 1px solid #ccc;
              }
              .checkmark:after {
                content: "";
                position: absolute;
                display: none;
              }
              &:hover input ~ .checkmark {
                background-color: #ccc;
              }
              & input:checked ~ .checkmark {
                background-color: #14467B;
              }
              & input:checked ~ .checkmark:after {
                display: block;
              }
              & .checkmark:after {
                left: 9px;
                top: 4px;
                width: 7px;
                height: 12px;
                border: solid #fff;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
              }

          }
      }
      .incresheight{
          height: 220px;
      }
      .disablee{
        pointer-events:none;
        cursor:default;
        opacity: 0.6;
      }
      #timerdisp{
       display: inline !important;
      }
      .accounterror, .rangeerror, .radioErrMsg, .securityCodeerror,.maxErrorMsg, .cardStatusError,.mailOTPError,.cardnumberformatErrMsg,.securityvalidCodeerror,.api-err-disp-class{
        color: #ed1c24;
        font-family: Gotham-Book,Arial;
        font-size: 17px !important;
      }
      /*.partner-crad-exchange{
        #SunwaypalsId{
            img{
                width:95% !important;
                margin-top: 15px;
            }
            p{
                padding-top: 28px;
            }
        }
      }*/
      .sunwaypals-step2{
          margin-top: -60px;
      }
     /* #sunwaypauls-partner-card-number{
          margin-top: 28px;
      }*/
      @media screen and (min-width: 481px) and (max-width: 767px) {
        table{
            padding: 0px;
            //border: 1px solid black; 
            margin-left: 0px;
            width: 100%;
        }
        .heading-steps{
            font: normal 17px/30px Gotham-Book,Arial;
            font-weight: 600;
        }
        .accounterror, .rangeerror, .radioErrMsg, .securityCodeerror,.maxErrorMsg,.cardStatusError,.mailOTPError {
            color: #ed1c24;
            font-family: Gotham-Book,Arial;
            font-size: 15px !important;
          }
        .incrementer-group.form-item{
            padding-top: 0px;
            font-family: Gotham-Book,Arial;
        }
        .points-exc-confirm--div
        {
            margin-top: 10px;
        }
        .points-miles-deduct--confirm{
            padding-top: 14px !important;
        }
        .mobile-points-exc-confirm--div{
            margin-top: 15px !important;
        }
      }
      @media screen and(max-width: 480px){
        table{
            padding: 0px;
            //border: 1px solid black; 
            margin-left: 0px;
            width: 100%;
        }
        .heading-steps{
            font: normal 16px/30px Gotham-Book,Arial;
            font-weight: 600;
        }
        .accounterror, .rangeerror, .radioErrMsg, .securityCodeerror, .maxErrorMsg,.cardStatusError,.mailOTPError{
            color: #ed1c24;
            font-family: Gotham-Book,Arial;
            font-size: 15px !important;
          }
        .incrementer-group.form-item{
            padding-top: 0px;
            font-family: Gotham-Book,Arial;
        }
        .points-exc-confirm--div
        {
            margin-top: 10px;
        }
        .points-miles-deduct--confirm{
            padding-top: 14px !important;
        }
        .mobile-points-exc-confirm--div{
            margin-top: 15px !important;
        }
        .b-infinite-acct-number--confirm,.total-miles-deduct--confirm,.total-binfinite-recieve--confirm{
            font-size: 10px !important;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
          table{
            width: 100%;
          }
      }
    
    .btn-inline{
        display: inline-flex;
      }
    .btn-member--cancel{
        min-width: 146px;
        width: auto;
        margin-left: 10px;
    }
    .btn-primary, .btn-secondary {
        background-color: #14467B !important;
        color: $white;
        @media screen and (max-width:767px){
            min-width: 0!important;
            flex: 1;

            &.btn-points--check--req-again{
                width: auto!important;
                margin-left:0
            }
            &.btn-points--check--confirm--submit{
                width: 100%!important;
                min-width: 68px!important;
            }
        }
        @media screen and (min-width:767px){
            &.btn-points--check--confirm--submit{
                width: 100%!important;
                min-width: 118px;
            }
            &.btn-points--check--req-again{
                margin-left: 1rem;
            }
        }
    }
    .terms-conditions-row {
        margin-top: 50px;
        @include respond-to(tablet-portrait) {
            margin-top: 20px;
        }
    }
    
}


.binfinte_pagnation
{
    float: right;
    margin-top: 50px;
    ul.simple-pagination {
        list-style: none;
    }
    
    .simple-pagination {
        display: block;
        overflow: hidden;
        padding: 0 5px 5px 0;
        margin: 0;
    }
    
    .simple-pagination ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .simple-pagination li {
        list-style: none;
        padding: 0;
        margin: 0;
        float: left;
    }
    span.ellipse.clickable {
        cursor: pointer;
    }
    
    .ellipse input {
        width: 3em;
    }
    
    
    
    .light-theme span {
        cursor:pointer;
    }
    
    .light-theme a, .light-theme span {
        float: left;
        color: #666;
        font-size:14px;
        line-height:24px;
        font-weight: normal;
        text-align: center;
        border: 1px solid #BBB;
        min-width: 14px;
        padding: 0 7px;
        margin: 0 5px 0 0;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.2);
        background: #efefef; /* Old browsers */
        background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#efefef)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #ffffff 0%,#efefef 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, #ffffff 0%,#efefef 100%); /* Opera11.10+ */
        background: -ms-linear-gradient(top, #ffffff 0%,#efefef 100%); /* IE10+ */
        background: linear-gradient(top, #ffffff 0%,#efefef 100%); /* W3C */
    }
    
    .light-theme a:hover, .light-theme li:not(.disabled):not(.active) span:hover {
        text-decoration: none;
        background: #FCFCFC;
    }
    
    .light-theme .current {
        background: #666;
        color: #FFF;
        border-color: #444;
        box-shadow: 0 1px 0 rgba(255,255,255,1), 0 0 2px rgba(0, 0, 0, 0.3) inset;
        cursor: default;
    }
    
    .light-theme .ellipse {
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        font-weight: bold;
        cursor: default;
    }
        
}

.rangecaldiv
{
  output {
    display: inline-block;
    transform: rotateZ(45deg);
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -42px;
    cursor: pointer;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transform-origin: center;
    border: 0.05rem solid darkgray;
    border-radius: 50% 50% 0.2rem 50%;
    background: #635454;
    transition: all 250ms ease-in-out;
  
    &::before,
    &::after {
      position: absolute;
      content: '';
      border-radius: 50%;
    }
  
    &::before {
      top: 0.25rem;
      left: 0.25rem;
      width: 0.7rem;
      height: 0.7rem;
      background: white;
      border: 0.125rem solid gainsboro;
      box-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.25);
      pointer-events: all;
      transition: all 250ms ease-in-out;
  }
  
    &::after {
      width: 2rem;
      height: 0.67rem;
      top: 2.19rem;
      left: 1.5rem;
      background: gainsboro;
      opacity: 0.25;
      transform-origin: right middle;
      transform: rotateZ(-45deg);
      overflow: visible;
      background: radial-gradient(ellipse at center, gainsboro 0%, transparent 50%);
      transition: height 1s ease, opacity 1s ease;
    }
  }
}
#partner-redeem-voucher-form
{
    background: #fff;
    padding-right: 5px;
    padding-left: 3px;
    // padding-top: 15px;
    border-radius: 10px;
    .partner-voucher-to-use,.partner-redemption
    {
        padding-left: 30px;
        
    }
    .partner-voucher-to-use{
        @media screen and (max-width: 767px){
            padding: 0 30px;
        }
    }
    .current--voucher-points
    {
        background-color: #00afaf;
        color: #fff;
        border-radius: 10px;
    }
    .label--partner-redeem--voucher-current-bal-lable
    {
        border-right: 2px solid #fff;
        padding: 12px;
        @media screen and (max-width: 767px) {
            font-size: 11px;
        }
    }
    .partner-redeem--voucher-current-balance
    {
        text-align: center;
        padding: 12px;
        @media screen and (max-width: 767px) {
            font-size: 11px;
        }
    }
    .title_desp
    {
        margin-top: 15px;
        color: #0065b3;
        @media screen and (max-width: 767px)
        {
              margin-top:0px;
              float: right;
        }
    }
    .voucher_desp
    {
        background: #ccc;
        border-radius: 10px;
        padding: 20px;        
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;       
        b
        {
            margin-bottom: 0px;
        }
        img
        {
            width: 150px;
            @media screen and (max-width: 767px)
            {
            width: 100px;
            }
        }
        p
        {
            font-size: 14px;
            font-family: Gotham-Book,Arial;
            @media screen and (max-width: 767px)
            {
                font-size: 11px;
            }
        }
        @include respond-to(tablet-portrait) {
            text-align: center;
        }
    }
}