.video-component{
	position: relative;
	//@include z-index(videoComponent);
	.video-component--overlay{
		position: absolute;
		//@include z-index(videoText);
		left: 30px;
		top: 20px;
		@include respond-to(ipad-portrait){
			left: 40px;
		}
		h1{
			@include font-size(18);
			color: $white;
		}
		&.alignRight{
			right: 40px;
			left: auto;
			@include respond-to(ipad-portrait){
				right: 70px;
			}
		}
	}
	.html5-embed{
		display: block;
	}
	.iframe-embed{
		display: none;
	}
	&.html5-video{
		.html5-embed{
			display: block;
		}
		.iframe-embed{
			display: none;
		}
	}
	&.embed-video{
		.html5-embed{
			display: none;
		}
		.iframe-embed{
			display: block;
		}
		.video-component--overlay{
			display: none;
		}
	}
	.playpause {
	    background-image:url(../images/play.png);
	    background-repeat:no-repeat;
	    width:20%;
	    height:20%;
	    position:absolute;
	    left:0%;
	    right:0%;
	    top:0%;
	    bottom:0%;
	    margin:auto;
	    background-size:contain;
	    background-position: center;
	}
}