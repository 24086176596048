#login-container {
  .enrich-login--container {
    margin: 0;
  }
  .col-lg-1 {
    padding-top: 35px;
  }
  .col-lg-7 {
    padding: 0 15px;
  }
  .login-error---validation {
    .col-lg-1 {
      padding-top: 0;
    }
  }
  a {
    &.btn {
      // width: 45%;
      width: auto;
      min-width: 145px;
      margin-right: 10px;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

#logged-in-user-menu,#mobmh-logged-in-user-menu,#mob_mh-logged-in-user-menu,#mobenrich-logged-in-user-menu,#mob_enrich-logged-in-user-menu
#mob-logged-in-user-menu{
  display: block;

  .menu {
    // border: solid 1px $lightgray;
    @include box-shadow(2px, 4px, 5px, 0, rgba(0, 0, 0, 0.25));
    border-top: 0;
    position: absolute;
    right: 0;
    display: block;
    width: 300px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-right: -1px;
    background-color: $white;

    .menu-item {
      border-bottom: solid 1px $seashell;

      &:hover {
        background-color: $seashell;
      }

      &:last-child {
        border-bottom: 0;
      }

      a {
        display: block;
        color: $gray;
        width: 100%;
        padding: 15px 40px;
      }
    }
   .menu-items {
      border-bottom: solid 1px $seashell;
      .enrich-collapse
      {
        transform: rotate(180deg);
      }
      &:hover {
        background-color: $seashell;
      }
      .fa-angle-down
      {
        float:right;
        margin-right: 14px;   
        font: normal normal normal 18px/1 FontAwesome;
        font-size: x-large;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
      &:last-child {
        border-bottom: 0;
      }

      a {
        display: block;
        color: $gray;
        width: 100%;
        padding: 15px 20px;
      }
    }
  }
}

#loggedmh-in-user-menu {

  @media screen and (min-width: 1200px) {
    position: absolute;
  }

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    width: 100%;
    max-width: $ibe-width-less-than-1200;
    margin: 0 auto;
    transform: translateX(-50%);
    left: 50%;
  }
  @media screen and (min-width: 1440px) {
    width: $ibe-width-more-than-1200;
    margin-left: $ibe-left-margin-more-than-1200;
  }
  
  .menu {
    // border: solid 1px $lightgray;
    @include box-shadow(2px, 4px, 5px, 0, rgba(0, 0, 0, 0.25));
    border-top: 0;
    position: absolute;
    right: 0;
    display: block;
    width: 300px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-right: -1px;
    background-color: $white;

    .menu-item,.menu-items {
      border-bottom: solid 1px $seashell;
      border-left: solid 1px $seashell;

      &:hover {
        background-color: #fff;
        color: #005aaa;
      }

      &:last-child {
        border-bottom: 0;
      }

      a {
        display: block;
        color: $gray;
        width: 100%;
        padding: 15px 20px;
        &:hover {
          background-color: #fff;
          color: #005aaa;
        }
      }
    }
  }
}
#mob-login--container,#mobmh-login--container ,#mobenrich-login--container,#mob_enrich-login--container,#mob_mh-login--container{
  display: none;
  width: 100%;
  padding: 20px;
  @include z-index(alertWindow); // margin-top: -5px;

  &.ibe-form--container {
    border-top: solid 1px $lightgray;
    padding: 20px;

    button {
      clear: both; //margin-bottom: 15px;
    }
    .input-lg {
      padding: 20px 20px 8px 22px;
    }
    input[type="checkbox"] {
      margin-right: 10px;
    }
  }

  > div:nth-child(2) >.row > div:nth-child(1) >.row > div,
  .enrich--login-form >.row > div {
    padding: 0;
  }

  @respond-to(tablet-portrait) {
    > div:nth-child(2) >.row > div:nth-child(3),
    .enrich--signup-form {
      margin-top: 30px;
    }
  }

  .close-container {
    display: none;
  }

  .login-error---validation {
    border-bottom: 1px solid $errorred;
    padding: 10px 0;
    display: none;
    width: 100%;
    float: left;
    margin: 15px 0;

    .fa {
      color: $errorred;
      @include font-size(18);
      float: left;
    }

    > div:first-child, // error icon
    .error-icon {
      width: 15px;
      float: left;
      padding-left: 0;
    }

    .error-msgs {
      float: left;
      width: calc(100% - 15px - 15px);
      @include font-size(14);
      margin-left: initial !important;
      margin-left: unset !important;
      color: $errorred;
      line-height: rem(26);

      span {
        @include font-size(14);
        color: $gray;
        display: none;
      }

      .validation--error-list {
        padding: 0 0 0 15px;
      }
    }

    #result {
      @include font-size(14);
      color: $gray;
    }

    #checking-bookingref--validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
    .fa {
      color: $errorred;
    }
  }

  >.container,
  [class*='col-'] {
    @include clear-bs-row;
  }

  [data-enrich-login] {
      margin-bottom: 15px;

    > div {
      padding: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include respond-to(tablet-portrait) {
    // $mainnavheight: 53px;
    width: 100%;
    padding: 20px;
    box-shadow: none;
    // top: $mainnavheight;
  }

  @include respond-to(more-than-1200) {
    display: none;
  }

  @include respond-to(mid-screen-768-1200) {
    color: $gray;
    h3 {
      color: $blue;
      @include font-size(24);
      line-height: 1em;
      margin-bottom: 5px;
    }
    .col-lg-12 {
      padding: 0;
    }
    .enrich-login {
      margin: 0 5px;
    }
    [data-enrich-login] {
      .col-lg-12 {
        width: 32%;
        margin-right: 2%;
        padding: 0;
        float: left;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .separator {
      height: 1px;
      background: $lightgray;
      display: block;
      clear: both;
      margin: 20px 0;
    }
    #enrichRegister,
    #enrichFindMore {
      width: 49%;
      float: left;
      clear: none !important;
    }
    #enrichFindMore {
      margin-left: 2%;
    }
  }

  @include respond-to(600) {
    h3 {
      margin-bottom: 5px;
    }
    .separator {
      background: $lightgray;
      display: block;
    }
  }

  @include respond-to(400) {
    h3 {
      @include font-size(24);
    }
  }

  @include respond-to(320) {
    h3 {
      @include font-size(20);
    }
    p {
      @include font-size(14);
    }
  }

  .enrich-login {
    a {
      width: calc(100% - 20px - 20px);
      margin-right: 20px;
    }

    .remember-my--id {
      width: calc(100% - 20px - 20px);
      margin-right: 20px;
    }
  }
}

.mfp-content {
  #mob-login--container,#mobmh-login--container,#mob_mh-login--container,#mobenrich-login--container,#mob_enrich-login--container {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

#mob-logged-in-user-menu {
  padding: 0;
  left: 0;
  top: 52px;

  @media screen and (min-width: 1200px) {
    display:none !important;
  }

  .menu {
    position: relative;
    width: 100%;
    margin: 0;
    border-left: 0;
    border-right: 0;
    border-top: solid 1px $lightgray;
  }
}
.enrich-revamp{
  #mobenrich-logged-in-user-menu .menu,#mobmh-logged-in-user-menu .menu{
  position: fixed!important;
  top: 52px;
}}

#mobmh-logged-in-user-menu,#mob_mh-logged-in-user-menu,#mobenrich-logged-in-user-menu,#mob_enrich-logged-in-user-menu {
  padding: 0;
  left: 0;
  top: 52px;

  @media screen and (min-width: 1200px) {
    display:none !important;
  }

  .menu {
    box-shadow: 2px 4px 5px 0 rgba(0,0,0,.25);
    border-top: 0;
    position: absolute;
    right: 0;
    display: block;
    width: 100%;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    /* margin-right: -1px; */
    background-color: #fff;
      .menu-item
      {
        border-bottom: 1px solid #f1f1f1;
        border-left: 1px solid #f1f1f1;
        a
        {
          display: block;
          color: #333;
          width: 100%;
          padding: 15px 20px;
        }
      }
      .menu-items {
        border-bottom: solid 1px $seashell;
        .enrich-collapse
        {
          transform: rotate(180deg);
        }
      .fa-angle-down
      {
        float:right;
        margin-right: 14px;   
        font: normal normal normal 18px/1 FontAwesome;
        font-size: x-large;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
        &:hover {
          background-color: $seashell;
        }
  
        &:last-child {
          border-bottom: 0;
        }
  
        a {
          display: block;
          color: $gray;
          width: 100%;
          padding: 15px 20px;
        }
      }
    }
  }
  #mobenrich-login--container,#mob_enrich-login--container,#mob-enrich-login--container{
    padding: 0;
    left: 0;
    top: 52px;
  
    @media screen and (min-width: 1200px) {
      display:none !important;
    }
  
    .menu {
      box-shadow: 2px 4px 5px 0 rgba(0,0,0,.25);
      border-top: 0;
      position: absolute;
      right: 0;
      display: block;
      width: 100%;
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      /* margin-right: -1px; */
      background-color: #fff;
        .menu-item
        {
          border-bottom: 1px solid #f1f1f1;
          border-left: 1px solid #f1f1f1;
          a
          {
            display: block;
            color: #333;
            width: 100%;
            padding: 15px 40px;
          }
        }
        .menu-items {
          border-bottom: solid 1px $seashell;
    
          &:hover {
            background-color: $seashell;
          }
    
          &:last-child {
            border-bottom: 0;
          }
    
          a {
            display: block;
            color: $gray;
            width: 100%;
            padding: 15px 20px;
          }
        }
      }
    }
  #mobmh-login--container,#mob_mh-logged-in-user-menu,#mobenrich-login--container,#mob_enrich-login--container,#mob_mh-login--container
  {
    display: block; 
    border: none; 
    padding: 0px;
    width: calc(100% + 30px); 
     left: -15px;
     #mobmh-logged-in-user-menu,#mob_mh-logged-in-user-menu,#mob-mh-logged-in-user-menu,#mobenrich-logged-in-user-menu,#mob_enrich-logged-in-user-menu
     {
      top: 0px;
       width: 100%; 
       position: absolute; 
       z-index: 999;
     }    
  }
  #mob_mh-logged-in-user-menu
  {
    left: -1px !important;
  }
  #bookingNav
{
  .selected
  {
    .login-mh
    {
      border-top: 2px solid #ed1c24;
      padding-top: 13px !important;
      padding-bottom: 13px  !important;
    }
  }

}  