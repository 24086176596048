.ibe-form--container {
  @mixin textInput() {
    height: 48px;
    color: $blue;
    &:focus {
      border: 1px solid $blue;
    }
    &.input-focused {
      padding: 21px 20px 4px 21px;
    }
  }

  @mixin textLabel() {
    position: absolute;
    margin-left: 24px;
    margin-top: 14px;
    color: $darkgray;
    @include font-size(14);
    @include z-index(bookformtitle);
    &.input-filled {
      margin-top: 6px;
      margin-left: 22px;
      @include font-size(12);
    }
    &.has-error {
      color: $errorred;
    }
  }

  .redemption-login {
    @media screen and (min-width: 768px) {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .redemption-other-links {
    padding: 0;
    margin: 0;
    margin-right: 20px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: middle;
    -ms-flex-align: middle;
    align-items: middle;

    li {
      font-size: rem(14);
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  .payment-type-container {
    // float: left;
    width: 100%;
    padding: 0 20px;
    padding-top: 10px;
    display: none;

    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }

    &:after {
      $offset: 20px;
      content: '';
      position: absolute;
      border-bottom: solid 1px $lightgray;
      width: calc(100% - #{$offset * 2});
      height: 0px;
      bottom: 0;
      left: $offset;

      @media screen and (max-width: 767px) {
        border-bottom: 0;
      }
    }

    +.single-cityTrip--Container {
      display: inline-block;
      width: 100%;

      @media screen and (max-width: 767px) {
        display: block;
        float: left;
      }
    }

    .redemption-payment-methods {
      float: none;
      float: unset;

      @media screen and (min-width: 768px) {
        padding: 0;
      }
    }

    .title {
      font-weight: 400;
      font-family: $font-family;
      font-size: rem(14);
      display: inline-block;
      margin-right: 10px;
      color: $gray;
    }

    .payment-method-options {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-block;
      margin-bottom: 10px;

      li {
        margin-right: 15px;
        display: inline-block;
        font-size: rem(14);

        label,
        input {
          &:hover {
            cursor: pointer;
          }
        }
      }

      input {
        margin-right: 5px;
      }
    }

    .redemption-other-links {
      margin-right: 20px + 22px;
    }
  }

  .redemption-login {
    input {
      &:focus {
        outline: none;
      }
    }

    input[type=text],
    input[type=number],
    input[type=password] {
      @include textInput();
    }

    .booking-form--title {
      @include textLabel();
    }

    .form-group {
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    .redemption-login--remember {
      margin-top: 5px;
      font-size: rem(14);

      label,
      input {
        &:hover {
          cursor: pointer;
        }
      }

      input {
        margin-right: 5px;
      }
    }

    .has-error {
      .form-control {
        border-color: $red;
      }
    }
  }

  .container--redemption-login {
    // z-index: 1;
    &.multiCityActive {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .link {
      font-size: rem(14);
    }

    .form-group {
      .link {
        margin-left: 24px;

        @media screen and (max-width: 767px) {
          color: $blue !important;
        }
      }
    }

    .title {
      font-size: rem(16);
      font-weight: 400;
      font-family: $font-family;
      line-height: rem(20);
      margin-bottom: 10px;
    }

    .error-validation {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 15px;
      float: left;
      width: 100%;

      .error-icon {
        width: 30px;
        padding-right: 0;
        float: left;
        text-align: left;

        @media screen and (max-width: 767px) {
          padding-left: 15px;
          width: auto;
        }

        i.fa {
          display: inline-block;
          float: none;
        }
      }

      .error-msgs {
        width: calc(100% - 50px);
        float: left;

        @media screen and (max-width: 767px) {
          margin-left: 0 !important;
        }

        span {
          @media screen and (max-width: 767px) {
            font-size: rem(13);
          }
        }
      }
    }

    .redemption-submit {
      text-align: right;
    }

    .btn.redemption--bookflight {
      // margin-top: 10px;
      width: auto;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 0;
      }
    }
  }
}
