/** Members -- Form Styles */
.members__content .form {
    max-width: 900px;
}

.members__content,
.miscform__content {
    $form-margin: 10px;

  .form {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;

    // grid column padding fix
    .row {
      margin-left: $form-margin / -2;
      margin-right: $form-margin / -2;
    }

    .mandatory-text {
      color: $red;
      font-size: rem(14);
      line-height: 1;
      margin-bottom: 30px;
    }

    .notice {
      font-size: rem(14);
      font-family: $font-family;
      font-weight: 400;
      max-width: 900px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $blue;

      &.notice--fieldset {
        margin-top: 0
      }

      &.notice--form-group {
        color: $gray;
      }
    }

    select {
      -webkit-appearance: none;
      padding: 11px 20px 10px;
      border-radius: 50px;
      width: 100%;
      background-color: $white;
      border-color: $lightgray;
    }

    // set to 10px padding size only
    @for $i from 1 through 12 {;
      .col-xs-#{$i},
      .col-sm-#{$i},
      .col-md-#{$i},
      .col-lg-#{$i}, {
        padding-left: $form-margin / 2;
        padding-right: $form-margin / 2;
      }
    }

    label.required,
    .label.required {
      &:after {
        content: '*';
        font-family: $font-family;
      }
      &:hover {
        cursor: pointer;
      }
    }

    div:not(.checkbox):not(.radio):not(.radio-group):not(.file-upload):not([class*="dataTables_"]) > label {
      @include font-size(14);
      position: absolute;
      z-index: 2;
      margin-left: 20px;
      margin-top: 14px;
      color: $darkgray;
      -webkit-transition: all 90ms ease-in-out;
      -o-transition: all 90ms ease-in-out;
      transition: all 90ms ease-in-out;


      @media screen and (max-width: 767px) {
        font-size: rem(12);
      }

      &.input-filled {
        margin-top: 6px;
        margin-left: 22px;
        @include font-size(12);

        @media screen and (max-width: 767px) {
          font-size: rem(11);
        }
      }
    }

    input:not([type='checkbox']):not([type='radio']):not([type='file']):not([type='range']) {
      @include border-radius(50px);
      // position: relative;
      // z-index: 1;
      padding: 14px 20px 13px;
      height: 47px;
      color: $blue;

      &:focus {
        border: 1px solid $blue;
        box-shadow: none;
        outline: none;
      }

      &[readonly] {
        // background: $white;
      }

      &.input-focused {
        padding: 21px 20px 4px 21px;

        &.input--with-prefix {
          padding-left: 45px;
        }
      }
    }

    input[type='file'] {
      line-height: 1;
    }

    textarea {
      &.input-focused {
        padding-top: 21px;
      }

      &.form-control {
        border-radius: 30px;
      }
    }

    .bootstrap-filestyle.input-group {
      $inputwidth: 70%;

      input[type='text'] {
        width: $inputwidth;
        float: left;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        font-size: rem(14);
        text-overflow: ellipsis;

        @media screen and (max-width: 767px) {
          width: 100% - 40%;
        }
      }

      span.group-span-filestyle {
        width: 100% - $inputwidth;
        float: left;

        @media screen and (max-width: 767px) {
          width: 40%;
        }

        .btn {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          padding: 14px 20px;
        }
      }
    }

    .radio,
    .radio-group,
    .checkbox,
    .checkbox-group {
      font-size: rem(14);
    }

    .radio,
    .checkbox {
      margin-bottom: $form-margin / 2;
      margin-top: $form-margin / 2;
    }

    .form-group {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-bottom: $form-margin;
      float: left;

      @media screen and (max-width: 767px) {
        // margin-bottom: 25px;
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.input-focused span.prefix,
      > .row > .input-focused span.prefix {
        transform: translateY(8px);
        opacity: 1;
      }

      > .row > .col-xs-12 {
        span.prefix {
          left: $form-margin / 2;
        }
      }

      .col-xs-12 {
        @media screen and (max-width: 767px) {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 5px;
          }
        }
      }

      input:not([type='radio']):not([type='checkbox']) {
        z-index: 1;
        position: relative;
      }

      .radio input[type="radio"],
      .radio-inline input[type="radio"] {
        top: 2px;
      }

      .checkbox input[type="checkbox"],
      .checkbox-inline input[type="checkbox"] {
        top: 1px;
        margin-left: -22px;
        width: 20px;
        height: 20px;

        @media screen and (max-width: 767px) {
          margin-left: -27px;
        }
      }

      .datepicker[readonly='readonly'] {
        position: relative;
        background-color: $white;
        z-index: 1;

        &:disabled {
          background-color: #eee;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .datepicker-icon {
        position: absolute;
        text-align: center;
        right: 20px;
        top: (47px - 22px) / 2;
        z-index: 2;
        color: $midgray;
      }

      [class*='col-'] > .datepicker-icon {
        right: #{$form-margin / 2} + 20px;
      }

      .prefix {
        position: absolute;
        padding: 22px 0 5px 21px;
        left: 0;
        top: 0;
        opacity: 0;
        color: $blue;
        font-size: rem(14);
        z-index: 3;
      }

      .input-focused .prefix {
        opacity: 1;
      }

      .helptext {
        font-size: rem(12);
        color: $blue;
        padding-left: 20px;
      }

      .row {
        margin-bottom: $form-margin;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .incrementer-group {
        @supports (display: flex) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
        }

        .label {
          width: auto;
          display: inline-block;
          float: left;
          margin-right: 20px;
          margin-bottom: 20px;

          @supports (display: flex) {
            float: none;
            margin-bottom: 0;
          }

          @media screen and (max-width: 479px) {
            min-width: calc(100% - 94px);
            margin-right: 0;
          }
        }

        .incrementer {
            float: left;

          @supports (display: flex) {
            float: none;
          }
        }
      }

      .incrementer {
        $incrementer-size: rem(32);
        min-width: #{$incrementer-size + $incrementer-size + rem(30)};

        display: inline-block;

        @supports (display: flex) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__btn {
          display: block;
          -webkit-transition: all 0.25s ease-in-out;
          -o-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out;
          border-radius: 50%;
          background-color: $blue;
          color: $white;
          border: solid 1px $blue;
          width: $incrementer-size;
          height: $incrementer-size;
          text-align: center;
          float: left;

          @supports (display: flex) {
            float: none;
          }

          &:hover,
          &:active,
          &:focus {
            background-color: $countdownblue;
          }

          i {
            line-height: $incrementer-size;
          }
        }

        &__display {
          display: block;
          min-width: 30px;
          text-align: center;
          line-height: $incrementer-size;
          color: $black;
          font-size: rem(14);
          float: left;
          padding-left: 3px;
          padding-right: 3px;

          @supports (display: flex) {
            float: none;
          }
        }
      }

      > .selectize-control,
      > .row > [class*='col-'] > .selectize-control,
      .phoneinput,
      .phoneinput__countrycode > .selectize-control {
        margin-bottom: -6px; // offset amount for weird float on selectize element
      }
    }

    fieldset {
      // margin-bottom: 35px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      legend {
        padding-top: 20px;
        border-bottom: 0;
        color: $blue;
        font-size: rem(16);
        line-height: inherit;
        font-weight: 600;
        font-family: $font-family-medium;
        color: $black;
      }

      h4 {
        font-size: rem(21);
        color: $blue;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: inherit;
      }

      h4.subtitle {
        font-size: rem(14);
        font-weight: 600;
        font-family: $font-family-medium;
        color: $black;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    .fieldset--confirmation-checkbox {
      margin-bottom: 10px;
    }

    .phoneinput {
      $countrycode-width: 40%;
      $mob-countrycode-width: 50%;
      position: relative;
      display: inline-block;
      width: 100%;

      &__countrycode,
      &__phonenumber {
        display: block;
        float: left;
      }

      &__countrycode {
        width: $countrycode-width;

        @media screen and (max-width: 479px) {
          width: $mob-countrycode-width;
        }

        select,
        .selectize-control .selectize-input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__phonenumber {
        width: 100% - $countrycode-width;

        @media screen and (max-width: 479px) {
          width: 100% - $mob-countrycode-width;
        }

        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0;
        }
      }
    }

    .form-value-slider {
      .rangeslider__handle {
        box-shadow: none;
      }

      .rangeslider,
      .rangeslider__fill {
        border-radius: 0;
        border: 0;
        box-shadow: none;
      }

      .rangeslider {
        margin-top: 15px;
        margin-bottom: 20px;
        background-color: $seashell;
        border-radius: 20px;
      }

      .rangeslider__fill {
        background-color: $blue;
        border-radius: 20px;
      }
    }

    .form-value-slider .rangeslider__fill {
      background-color: $enrich-neutral;
    }

    .member--gold & {
      .form-value-slider .rangeslider__fill {
          background-color: $enrich-gold;
        }
    }

    .member--silver & {
      .form-value-slider .rangeslider__fill {
          background-color: $enrich-silver;
        }
    }

    .member--platinum & {
      .form-value-slider .rangeslider__fill {
          background-color: $enrich-platinum;
        }
    }

    .member--blue & {
      .form-value-slider .rangeslider__fill {
          background-color: $enrich-blue;
        }
    }

    .form-footnotes {
      font-size: rem(12);

      .notes {
        padding-left: 25px;

        li {
          padding-left: 10px;
          margin-bottom: 3px;
        }
      }
    }

    .error-validation {
      margin-left: 0;
      margin-right: 0;
      position: relative;
      padding-left: 15px + 14px + 10px;
      padding-right: 20px;

      &:before {
        content: '\f057';
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 10px;
        left: 15px;
        width: 14px;
        color: $red;
      }

      p {
        font-size: rem(14);
        margin-bottom: 0;
      }

      a {
        color: $red;
      }

      .field-name {

        text-transform: capitalize;
        font-family: $font-family-medium;
      }
    }

    .error_msg {
      color: $red;
      font-size: rem(12);
      margin-top: 2px;
      // display: inline-block;
      display: none;
      width: 100%;
    }

    input + .error_msg,
    select + .error_msg,
    .selectize-control + .error_msg,
    .datepicker-icon + .error_msg,
    .phoneinput .error_msg,
    .bootstrap-filestyle + .error_msg {
      padding-left: 20px;
    }

    .selectize-input {
      input {
        height: auto !important;
        height: initial !important;
      }

      .parsley-errors-list {
        display: none;
      }
    }

    .has-error,
    .has-sel-error,
    [has-error] {
      .error_msg {
        display: inline-block;

        &.error--country-code {
          width: 40%;
        }

        &.error--phone-number {
          width: 60%;
        }
      }

      .selectize-control .selectize-input,
      .form-control {
        border-color: $red;
      }

      .label,
      label {
        color: $red !important;

        a {
          color: $red;
          text-decoration: underline;
        }
      }

      .parsley-errors-list {
        padding-left: 15px;
      }
    }

    .checkbox,
    .radio {
      .has-error {
        color: $red;
      }
    }
  }

  .form-actions {
    display: inline-block;
    width: 100%;

    .btn-member--cta,
    .btn-member--submit {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .recaptcha {
    margin-bottom: 20px;
  }
}

table.dataTable {
  border-collapse: collapse;

  &.dtr-inline.collapsed > tbody > tr {
    > td:first-child:before,
    > th:first-child:before,
    &.parent > td:first-child:before,
    &.parent > th:first-child:before {
        color: $black;
        border: none;
        border-radius: 14px;
        box-shadow: none;
        box-sizing: content-box;
        text-align: left;
        line-height: rem(14);
        background-color: transparent;
        width: 11px;
    }

    > td:first-child:before,
    > th:first-child:before {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
        font-family: 'FontAwesome';
        content: '\f067';
    }

    &.parent > td:first-child:before,
    &.parent > th:first-child:before {
        font-family: 'FontAwesome';
        content: '\f068';
    }

    > td.child,
    > th.child,
    > td.dataTables_empty {
      padding: 0;
    }
  }

  thead {

    th {
      position: relative;
      font-size: rem(14);
      padding: 10px;
    }

    .sorting,
    .sorting_asc,
    .sorting_desc {
      padding-right: 10px + 15px;
      background-image: none;

      &:after {
        @include font-size(23);
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: inline-block;
        font: normal normal normal 24px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-left: 10px;
        color: $linkblue;
        vertical-align: middle;
        width: 15px;

        @media screen and (max-width: 767px) {
          right: 0;
        }
      }
    }

    .sorting {
      &:after {
        content: "\f107";
        line-height: rem(15);
      }
    }
    .sorting_asc {
      &:after {
        content: "\f106";
        line-height: rem(15);
      }
    }
    .sorting_desc {
      &:after {
        content: "\f107";
        line-height: rem(15);
      }
    }
  }

  > tbody > tr.child {
    padding: 0;

    span.dtr-title {
      font-weight: 600;
      font-family: $font-family-medium;
      float: left;
    }

    span.dtr-data {
      float: right;
    }

    ul.dtr-details {
      width: 100%;
      float: left;

      li {
        float: left;
        width: 100%;
        padding: 10px 0;

        &:first-child {
          padding-top: 10px;
        }
      }
    }
  }

  > tbody > tr.child ul.dtr-details {
    @media screen and (max-width: 767px) {
      padding-left: 30px;
    }
  }

  thead th,
  tbody td {
    @media screen and (max-width: 767px) {
      font-size: rem(14);
      padding: 5px 8px;
    }

    @media screen and (max-width: 479px) {
      font-size: rem(13);
    }
  }

  thead th,
  thead td,
  tfoot th,
  tfoot td,
  .no-footer {
    border-color: $lightgray;
  }
}

/* ENRICH DATATABLES CSS */
.dataTables_wrapper {

  .dataTables_paginate {
    margin-bottom: 20px;

    .paginate_button {
      border: 0 !important;
      font-size: rem(14);

      @media screen and (max-width: 767px) {
        font-size: rem(12);
      }

      &.previous,
      &.next {
        //@extend .btn-primary;

        width: auto;
        color: $blue !important;
        background: $blue !important;
        @include border-radius(50px);
        width: 32px;
        height: 32px;
        font-size: 0;
        &:hover,
        &:active,
        &:focus {
          color: $white !important;
        }

        &.disabled {
          opacity: 0.6;
        }

      }

      &.previous{
        &:before{
          content: "\f104";
          display: inline-block;
          font: normal normal normal 24px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          @include font-size(20);
          color: $white;
          opacity: 1 !important;
        }
      }
      &.next{
        &:before{
          content: "\f105";
          display: inline-block;
          font: normal normal normal 24px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          @include font-size(20);
          color: $white;
          opacity: 1 !important;
        }
      }

      &:hover,
      &:active,
      &:focus {
        border-color: $blue;
        border: 0 !important;
        border-color: transparent;
        background: none;
        color: $countdownblue !important;
        box-shadow: none;
      }

      &.current,
      &.current:hover {
        border-color: transparent;
        box-shadow: none;
        font-weight: 600;
        font-family: $font-family-medium;
        background: transparent;
        color: $blue !important;
      }
    }
  }

  .dataTables_length {
    padding-top: 4px;
    margin-top: 8px;
    font-size: rem(14);

    @media screen and (max-width: 767px) {
        font-size: rem(12);
        margin-top: 0;
    }
  }
}

#error--member-register--accept-terms{
  a{
    span{
      text-transform: initial;
    }
  }
}

.parsley-errors-list {
    display: none;
}
/* ENRICH DATATABLES CSS */

