.video-repository{
	.head-wrapper{
		text-align: center;
		width: 80%;
		margin: 0 auto 30px;
		h1{
			line-height: 1em;
		}
	}
	.thumbnail-wrapper{
		display: block;
		width: 100%;
		clear: both;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-bottom: 20px;
		float: left;
		border-bottom: 1px solid $lightgray;
		.img-wrapper{
			width: 40%;
			float: left;
			margin-right: 20px;
			position: relative;
			cursor: pointer;
			&:before{
		        content: '';
		        position: absolute;
		        left: 0;
		        bottom: -1px;
		        right: 0;
		        height: 80%;
		        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
		        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
		        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
		        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
		        background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
		        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
		    }
		}
		.content-wrapper{
			width: 50%;
			float: left;
			.title{
				@include font-size(11);
				font-family: $font-family-medium;
			}
			.sep{
				height: 2px;
				background: $errorred;
				width: 20%;
				display: block;
				clear: both;
				margin: 10px 0;
			}
			.description{
				@include font-size(14);
			}
		}
	}
	.video-embed--wrapper{
		width: 100%;
		padding: 0;
		margin: 0;
		height: auto;
		overflow: hidden;
		&.gradient{
			&:before{
		        content: '';
		        position: absolute;
		        left: 0;
		        bottom: -1px;
		        right: 0;
		        height: 40%;
		        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
		        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
		        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
		        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
		        background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
		        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
		    }
		}
	}
	.playpause {
	    background-image:url(../images/play.png);
	    background-repeat:no-repeat;
	    width:30%;
	    height:30%;
	    position:absolute;
	    left:0%;
	    right:0%;
	    top:0%;
	    bottom:0%;
	    margin:auto;
	    background-size:contain;
	    background-position: center;
	    cursor: pointer;
	}
	.overlay-content{
		position: absolute;
		bottom: 10%;
		left: 5%;
		color: $white;
		.title{
			@include font-size(12);
			font-family: $font-family-medium;
		}
		.sep{
			height: 2px;
			background: $errorred;
			width: 20%;
			display: block;
			clear: both;
			margin: 10px 0;
		}
		.description{
			@include font-size(20);
		}	
	}
	.view-more--link{
		a{
			width: 100%;
			float: right;
			text-align: right;
    		padding-right: 20px;
    		padding-top: 2px;
		}
	}
	.slider-elements{
		width: 100%;
		height: auto;
		overflow: hidden;
		.slider{
			padding: 15px 25px;
			.slick-slide{
				a{
					p{
						color: $blue;
						@include font-size(16);
						text-align: center;
						margin-top: 15px;
					}
				}
			}
		}
		button{
			@include border-radius(100px);
			border: 1px solid $blue;
			padding: 20px;
			&:before{
				color: $blue;
				opacity: 1;
				line-height: 0;
				position: relative;
				left: -9px;
				top: -1px;
			}
			&.slick-prev{
				left: 0px;
				
			}
			&.slick-next{
				right: 0px;
				&:before{
					left: -6px;
				}
			}
			&:hover{
				background: $focusblue;
				&:before{
					color: $white;
				}
			}
		}
		.img-wrapper{
			width: 100%;
			float: left;
			margin-right: 20px;
			position: relative;
			cursor: pointer;
			&:before{
		        content: '';
		        position: absolute;
		        left: 0;
		        bottom: -1px;
		        right: 0;
		        height: 80%;
		        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
		        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
		        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
		        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
		        background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
		        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
		    }
		}
		.content-wrapper{
			width: 100%;
			float: left;
			margin-top: 20px;
			.title{
				@include font-size(12);
				font-family: $font-family-medium;
				color: $gray;
			}
			.sep{
				height: 2px;
				background: $errorred;
				width: 10%;
				display: block;
				clear: both;
				margin: 10px 0;
			}
			.description{
				@include font-size(18);
				color: $gray;
			}
		}
	}
	.slick-dots{
		margin: 0;
		border-top: 0;
		bottom: -15px;
		padding: 0;
		width: 88%;
		li{
			button{
				padding: 0;
				width: 16px;
				height: 16px;
				&:before{
					opacity: 0;
				}
			}
			&.slick-active{
				button{
					background: $blue;
				}
			}
		}
	}
	.btn{
		width: 50%;
		display: inherit;
		margin: 0 auto;
	}
}