/** CO13 - Ad banner */
/* MABUAT1-29 */
/* Ad banner bg image fix */

.adbanner-container {
  .adbanner-content {
    position: relative;
    min-height: 360px;
    background-color: $seashell;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 40px;

    @include respond-to(tablet-portrait) {
      padding: 20px;
      min-height: 450px;
    }

    @include respond-to(414) {
      min-height: 360px;
    }

    .banner-img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      overflow: hidden;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        transform: translateX(-50%) translateY(-50%);

        @media (min-width: 1200px) {
          height: auto;
          width: 100%;
        }

        @include respond-to(tablet-portrait) {
          height: auto;
          width: 100%;
        }
      }
    }

    .banner-text {
      position: relative;
      z-index: 2;
      width: 50%;

      @include respond-to(tablet-portrait) {
        width: 100%;
      }

      p {
        @include font-size(36);
        line-height: 1.25em;
        color: $focusblue;
        margin-bottom: 40px;

        @include respond-to(tablet-portrait) {
          @include font-size(24);
        }
      }
    }

    .img-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include respond-to(tablet-portrait) {
        justify-content: center;
      }

      a {
        display: block;
        margin-right: 10px;

        @include respond-to(tablet-portrait) {
          max-width: calc(50% - 5px);
        }

        &:last-child {
          margin-right: 0;
        }
      }
      img {
        max-width: 100%;

      }
    }

    // color modifiers
    &.whiteTxt .banner-text {
      p {
        color: $white;
      }
    }
    &.blackTxt .banner-text {
      p {
        color: $black;
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  .adbanner-container {
    width: 100%;
    text-align: center;
    min-height: 360px;
    clear: both;
    background: $seashell;

    .adbanner-content {

      img {
        &.img-responsive {
          display: none;
        }
      }
    }
  }
}
