$ibe-width-more-than-1200: calc(100% - #{120px + 120px - 20px - 20px});
$ibe-left-margin-more-than-1200: #{120px - 20px};
$ibe-width-less-than-1200: #{1200px + 20px + 20px};

.container {
  &.container--booking-ibe {
    @include z-index(containerbookingibe);
    position: relative;
  }
}

@import './c005-bookingnav';

@import './C005-cug';
@import './C005-bookflight';
@import './C005-checkin';
@import './C005-stay';
@import './C005-login';
@import './C005-viewbooking';
@import './C005-flightstatus';
@import './C005-mhupgrade';

@import './C005-errorvalidation';
@import './C005-ibe-cta-btn';

@import './C005-ibe-standalone';
@import './C005-mholidays';