
    .card_image
    {
        text-align: center;
        border: 1px solid;
        img
        {
            width: 150px;
            height: 100px;
        }
        @media screen and (min-width: 768px)
        {
            width: 302px;
            height:314px;
        }
        @media screen and (max-width: 767px)
        {
            width: 100px;
            height: 115px;
            img
            {
                width: 50px;
                height: 50px;
            }
        }
        
    }
    .catalogue-main-container {        
        .enrich-catalogue-outer {
            //height: 384px;
            background-color: #fff;
            text-align: center;
            border-radius: 10px;
            margin-top: 30px;
            padding: 10px;
            border: 1px solid transparent;
            //width: 265px;
            @include respond-to(tablet-portrait) {
                width: 100%;   
                //height: 235px;
                padding: 7px;           
            }
            @include respond-to(320) {
                width: 100%;
            }
            @include respond-to(ipad-portrait) {
                //width: 235px;
            }
            .enrich-catalogue-image-section {
                //height: 270px;
                text-align: center;
                vertical-align: middle;
                //display: table-cell;
                //width: 250px;               
                margin-bottom: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                img {
                    margin: auto;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                } 
                @include respond-to(tablet-portrait) {
                    width: 100%;    
                    display: inline-block;     
                    //height: 150px;
                    margin-bottom: 5px;     
                }               
            }
            .enrich-catalogue-description {
                font: normal 700 13px/12px Gotham-Book,Arial;
                color: #000;
                padding: 10px 5px;
                margin-top: 10px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;                
                height: 85px;
                @include respond-to(tablet-portrait) {
                    height: 70px;
                    padding: 5px 5px;
                    margin-top: 0px;
                    font: normal 700 11px/12px Gotham-Book,Arial;
                }       
                @media screen and (max-width:767px) {
                    p:first-child {
                        margin-bottom: 0px;
                    }
                    p:nth-child(2){
                        line-height: 13px;
                    }                    
                }
            }
        }
        .padding-space {
            padding-left: 5px;
            padding-right: 5px;
        }
        @media (min-width: 1700px) {
            .col-lg-4 {
                width: 25%;
            }
        }
        a:hover {
            .enrich-catalogue-outer {
                border: 1px solid $midgray;
            }           
        }
    }
    .member--gold, .member--GOLD {
        #member-enrich-catalogue {
            .catalogue-main-container {
                a:hover {                   
                    .enrich-catalogue-description {
                        background-color: #c8b06f;
                        color: #fff;
                    }
                }
            }
        }        
    }
    .member--platinum, .member--PLAT, .member--PLATP {
        #member-enrich-catalogue {
            .catalogue-main-container {
                a:hover {                   
                    .enrich-catalogue-description {
                        background-color: #506883;
                        color: #fff;
                    }
                }
            }
        }        
    }
    .member--silver, .member--SILV {
        #member-enrich-catalogue {
            .catalogue-main-container {
                a:hover {                   
                    .enrich-catalogue-description {
                        background-color: #aaadb0;
                        color: #fff;
                    }
                }
            }
        }        
    }
    .member--blue, .member--BLUE {
        #member-enrich-catalogue {
            .catalogue-main-container {
                a:hover {                   
                    .enrich-catalogue-description {
                        background-color: #00bff3;
                        color: #fff;
                    }
                }
            }
        }        
    }
    
    
    .card_descrip
    {
        text-align: center;
        border: 1px solid;
        font-size: 11px;
        @media screen and (min-width: 767px)
        {
            width: 302px;
            height:70px;
            font-size: 11px;
        }
        @media screen and (max-width: 768px)
        {
            font-size: 10px;
        }
        .enrich-catalogue-desc-outer {
            width: 270px;
            height:70px;
        }
    }

    #enrich_category
    {
        width: 100%;
        margin: 30px 0 0;
        font-size: 1.875rem;
        color: #005aaa;
        background-color: #fff;
        padding: 5px 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 1.5;
        @media screen and (max-width: 768px)
        {            
            margin-bottom: 0px;
            font-size: 1.5rem;
        } 
        @include respond-to(320) {
            font-size: 1.2rem;
        }       
    }
    #enrich_category:focus {
        outline: none;
    }
    .enrich-catalogue-category-box:after {
        content: "\f107";
        display: inline-block;
        font: normal normal normal 25px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 40px;
        line-height: 60px;
        font-size: 3em;
        line-height: 3.75rem;       
        line-height: 1em;
        color: #cccccc;       
        position: absolute;
        right: 20px;
        margin-top: 34px;
        pointer-events: none;
        @include respond-to(tablet-portrait) {
            right: 40px;
            margin-top: 29px;
        }
        @include respond-to(ipad-portrait) {
            right: 35px;
            margin-top: 30px;
        }
        @include respond-to(ipad-landscape) {
            right: 35px;            
        }
    }    
    .visiblile_false
    {
        visibility: hidden;
    }
    .enrich-top-title-box {
        padding: 15px 20px;
        background-color: #ffffff;
        border-radius: 10px;
        border: 1px solid #cccccc;        
        h3 {
            margin-bottom: 10px;
            color: #005aaa;
            font-size: 36px;
            @include respond-to(tablet-portrait) {
                font-size: 30px;
            }
        }
        p {
            @include respond-to(tablet-portrait) {
                font-size: 11px;
            }
            font-size: 14px;
        }
    }
    .enrich-catalogue-page {
        float: unset;
        #pagination {
            display: flex;
            justify-content: center;
            span.current.prev, a.page-link.prev {
               width: 32px;
               height: 32px;
               color: transparent;
               background-color: #005aaa; 
               border-radius: 50px;
               border: none;
               box-shadow: none;
               padding: unset;
                &:before {
                    display: inline-block;
                    font: normal normal normal 24px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 20px;
                    line-height: 30px;
                    font-size: 1.25rem;
                    line-height: 1.875rem;
                    color: #fff;
                    opacity: 1!important;
                    content: "\f104";
                }
            }
            .disabled {
                span.current.prev {
                    opacity: .6;
                }
            }
            span.current.next, a.page-link.next {
                width: 32px;
                height: 32px;
                color: transparent;
                background-color: #005aaa; 
                border-radius: 50px;
                border: none;
                box-shadow: none;
                padding: unset;
                 &:before {
                     display: inline-block;
                     font: normal normal normal 24px/1 FontAwesome;
                     font-size: inherit;
                     text-rendering: auto;
                     -webkit-font-smoothing: antialiased;
                     -moz-osx-font-smoothing: grayscale;
                     font-size: 20px;
                     line-height: 30px;
                     font-size: 1.25rem;
                     line-height: 1.875rem;
                     color: #fff;
                     opacity: 1!important;
                     content: "\f105";
                 }
             }
             .disabled {
                 span.current.next {
                     opacity: .6;
                 }
             }
             .active {
                 .current {
                    color: #005aaa;
                    border: none;
                    background: transparent;
                    box-shadow: none;
                    font-weight: 600;
                    font-family: Gotham-Medium,Arial;
                    padding: .5em 1em;
                 }
             }
             .page-link {               
                border: none;
                background: transparent;
                box-shadow: none;
                font-weight: 600;
                font-family: Gotham-Medium,Arial;
                padding: .5em 1em;
             }
            
        }
    }