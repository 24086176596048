.rich-text--editor{
  margin: 0;
  padding: 15px;
  p{
    font-family: $font-family;
    @include font-size(14);
    color: $gray;
  }
  &.justify{
  	p{
  		text-align: justify;
    }
  }
}