// table css using lcp-benefit.scss
#members--messages {
    .title {
        float: left;
    }
    .clear_link {
        padding: 12px;
        float: right;
        text-decoration: underline;
        color: $blue;
        font-weight: 400;
        cursor: pointer;
    }
    .clear_both {
        clear: both;
    }
    .fa {
        cursor: pointer;
    }
}
// pop up  css 
.mfp-inline-holder {
    .mfp-content {
        #messages--delete-popup {
            max-width: 400px;
            text-align: center;
            margin: 0 auto;
            background-color: $white;
            border-radius: 5px;
            .title {
                padding: 25px;
            }
            .body {
                padding: 5px 30px 30px
            }
        }
    }
}
.message-btn {
    width: 35%;
}
.message-btn-primary {
    background: #6EAFD7 !important;
    padding: 6px 0px;
    border: none;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
}
.msg-btn-all {
    padding: 6px;
}
#member-contact {
    background: white;
    padding-left: 17px;
    padding-right: 17px;
    .bold {
        font-weight: 600;
    }
    .contact-info {
        padding-bottom: 30px;
    }
    .form-group {
        &.member--contact-upload {
            width: 80%
        }
        &.contact-form--submit {
            width: 39%;
        }
    }
}
#member-contact-form {

    .selectize-control
    {
        .selectize-input
        {
            border-radius: 10px;
        }
    } 
    textarea,input,#contact-form--submit
    {
        border-radius: 10px;
    }
    .file-upload
    {
        input,label
        {
            border-radius: 10px;
        }

    }
    label {
        position: absolute;
        margin-top: -10px;
        background-color: $white;
        padding-left: 5px;
        padding-right: 6px;
        &.attachment {
            left: 9px;
        }
    }
    .member--contact-upload {
        .group-span-filestyle {
            label {
                position: relative;
            }
        }
        .group-span-filestyle  {
            label {
                width: 93px;
            }
        }
        .form-control {
            background: $white;
        }
    }
}
.hideclass {
    display: none;
}
.showclass {
    display: block;
}
// media queries
@media screen and (max-width: 1023px) {
    #members--messages {
        .title {
            padding-top: 13px;
        }
    }
    #member-contact-form {
        .form-group {
            .member--subject {
                margin-bottom: 12px;
            }
        }
    }
}
@media screen and (max-width: 1366px) {
    #member-contact-form {
        .col-lg-6 {
            width: 50% !important;
        }
    }
}
@media screen and (max-width: 991px) {
    #member-contact-form {
        .col-md-6 {
            width: 50% !important;
        }
    }
}
@media screen and (min-width: 200px) and (max-width: 500px) {
    #member-contact-form {
        .col-md-6 {
            width: 100% !important;
        }
        .form-group.contact-form--submit {
            float: left;
        }
    }
}
    
