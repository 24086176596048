/**
 * Members Portal
 * - travel coordinators
 */

.miles-coordinator-list {
  tbody > tr:first-child > td {
    border-top: solid 1px $lightgray;
  }

  .actions {
    text-align: right !important;

    @media screen and (min-width: 1200px) {
      width: 15%;
    }

    .link {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.add-travcord-relation-to-member{
  .relation-head{
    float: left;
    color: $darkgray;
    font-size: rem(14);
    margin: 0 0 0 10px;
  }
}

.add-travcord--relation-to-member{
  // float: left;
  // margin: 0 0 0 10px;
  padding: 0;
  li{
    list-style-type: none;
    float: left;
    margin-right: 25px;
    color: $darkgray;
    font-size: rem(14);
    input{
      margin-right: 10px;
    }
  }
}

.members__content .form .form-group.add-travcord--personal-contact-numbers .phoneinput{
  margin-bottom: 5px !important;
}
