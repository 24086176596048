.image-list--container{
  background: $white;
  //float: left;
  margin-bottom: 30px;
  &:nth-child(3n){
    margin-right: 0;
  }
  &.full-width{
    width: 100%;
    .img-wrapper{
      width: 65%;
      float: left;
      height: auto;
      margin-right: 30px;
      @include respond-to(ipad-portrait){
        width: 60%;
      }
    }
    .image-list{
      width: 32%;
      float: left;
      .image-list--container{
        margin-right: 0;
      }
    }
  }
  img{
    margin-bottom: 15px;
  }
  .image-list{
    h2{
      padding-bottom: 15px;
      border-bottom: 1px solid $lightgray;
      margin-bottom: 0;
      line-height: 1em;
      @include respond-to(ipad-portrait){
          padding-bottom: 5px;
      }
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style-type: none;
        padding: 15px 10px 15px 15px;
        border-bottom: 1px solid $lightgray;
        width: 100%;
        @include respond-to(ipad-portrait){
          padding: 10px 10px 10px 15px;
        }
        a{
          color: $gray;
          @include font-size(18);
          width: 100%;
          height: auto;
          overflow: hidden;
          display: block;
          &:hover{
            color: $linkblue;
          }
        }
      }
    }
  }
}
.image-component{
  img{
    margin-bottom: 15px;
  }
}


@include respond-to(tablet-portrait){
  .image-list--container{
    //margin-bottom: 30px;
    width: 100%;
    &.full-width{
      width: 100%;
      .img-wrapper{
        width: 100%;
      }
      .image-list{
        width: 100%;
      }
    }
    h2{
      @include font-size(30);
    }
  }
}

