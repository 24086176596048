.sidebannerfaqs {
  background-color: $countdownblue;
  padding: 20px;

  &__title {
    padding-bottom: 21px;
    margin-bottom: 10px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 40px;
      height: 2px;
      background-color: $red;
    }

    h2 {
      font-family: $font-family-medium;
      font-size: rem(16);
      line-height: 1.2;
      margin-bottom: 0;
      color: $white;
    }
  }

  &__questions {
    padding-left: 0;
    list-style: none;
    margin-bottom: 25px;
  }

  .question {
    &__item {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: solid 1px rgba($lightgray, 0.6);

      a {
        color: white;
        @include font-size(14);
        &:hover{
          border-bottom: 1px dashed $white;
        }
      }
    }
  }

  .btn {
    width: 146px;
  }
  &.marginTop{
    margin-top: 20px;
  }
}

@include respond-to(tablet-portrait){
  .sidebannerfaqs {
    margin: 20px;
  }
}
