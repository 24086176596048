#members--login--details{
	padding-bottom: 125px;
.connected-account{
    border: 1px solid #CCCCCC;
    border-radius: 10px;
	opacity: 1;
	// width: 693px;
	 height: 112px;
	margin-top: 10px;

}
@media (min-width: 320px) and (max-width: 568px)  {
	.connected-account{
	height: 131px;
	}
}
.connected-account-details
{
	text-align: left;
	font: normal normal 14px/16px Gotham;
	letter-spacing: 0;
	color: #61666F;
	opacity: 1;
	padding: 30px 10px;

}
.btn-disconnect
{
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #ED1C24;
	color: #ED1C24;
	border-radius: 10px;
	opacity: 1;
	width: 150px;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	float: right;
	
}
b
{
	margin-left: 15px;
}
.connected-account-text{
	text-align: left;
	font: normal normal 14px/16px Gotham-Book;
	letter-spacing: 0;
	color: #61666F;
	opacity: 1;
}
.user-details{
	margin-top: 15px;
	b{
		margin-left: 0px;
	}
}
.connect-btn{
	color: #FAFAFA;
    width: 150px;
    height: 46px;
    background: #006dbb 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
	padding: 13px 0px;
	float: right;
	cursor: pointer;
}
.change-email-btn{
	color: #006dbb;
    width: 150px;
    height: 46px;
    background: #FAFAFA 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
	padding: 13px 0px;
	border: 1px solid rgb(192, 189, 189);
}
input.form-control{
	border-radius: 10px;  
	width: 60%;
	color: #61666f;
	height: 40px;
}
.change-pwd-btn{
	color: #FAFAFA;
    width: 150px;
    background: #006dbb 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
	padding: 13px 0px;
	margin-right: 10px;

}
@media (min-width: 320px) and (max-width: 568px)  {
	.icon-fb{
		width: 27px;
		border-radius: 13px;
	}
	.user-details {
		margin-top: 3px;
		padding: 0;
		margin-top: 6px;
		height: 32px;
		span{
			font-size: 12px;
		}
	}
	b
	{
		margin-left: 9px;
		font-size: 12px;
		margin-top: 7px;
	}
	.connected-account-details{
		padding: 17px 5px;

	}
	.connected-account {
	background: #fafafa 0 0 no-repeat padding-box;
	}
	.btn-disconnect {
		margin-top: 13px;
		width: 129px;
		float: right;
		border-radius: 25px;
	}
	.icon-google{
		padding: 4px;
		width: 31px;
		border-radius: 15px;
	}
	.connect-btn{
		margin-top: 13px;
		width: 129px;
		float: right;
		cursor: pointer;
		border-radius: 25px;
	}
	@media (width: 360px) and (height: 640px)  {
		.connect-btn{
		    margin-right: 12px;
		}
		.btn-disconnect{
			margin-right: 12px;
			
		}
	}

}
.login-details{
	margin-right: 6px;
}
}