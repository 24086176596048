/* C020 - Secondary Navigation */
/* MABUAT1-77 */
$sec-nav-height: auto;
.secondary-nav--container {
  $sec-nav-bg: $seashell;
  margin: 0;
  padding: 15px 0;
  display: block;
  height: auto;
  background-color: $seashell;

  // override parent container wrapping and set to
  // full width even though nested.
  @media screen and (min-width: 1201px) {
    width: calc(100 / 84 * 100%);
    margin-left: calc(((#{100 - 84} / 84) / -2) * 100%);
    padding-left: calc((100 / 84 * 100%) * 8 / 100);
    padding-right: calc((100 / 84 * 100%) * 8 / 100);
  }

  @media screen and (max-width: 1200px) {
    width: calc(100 / 90 * 100%);
    margin-left: calc(((#{100 - 90} / 90) / -2) * 100%);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: calc((100 / 90 * 100%) * 5 / 100);
    padding-right: calc((100 / 90 * 100%) * 5 / 100);
  }

  @media screen and (max-width: 1200px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  $sidemargin: 120px;
  &.headroom--not-top {
    .row {
      max-width: calc(100% - #{$sidemargin} - #{$sidemargin});
    }
  }

  &.headroom--not-top {
    position: fixed;
    z-index: 10;
    background: #fff;
    left: 0;
    padding: 15px 0;
    border-bottom: solid 1px $sec-nav-bg;
    &.headroom--unpinned {
      top: 133px;
    }

    &.headroom--pinned {
      top: 125px;
    }
  }

  .row {
    margin: 0;
  }

  > .row {
    max-width: 100%;
    margin: 0 auto;
  }

  $sec-nav-title-width: 26%;
  $sec-nav-cta-width: auto;
  $sec-nav-menu-width: auto;

  .secondary-nav {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .business-type {
      width: $sec-nav-title-width;
      flex-grow: 1;

      h2 {
        margin-bottom: 0;
        line-height: 1;
        // padding-top: 5px;
      }
    }
    

    .sec-navigation-wrapper {
      flex-grow: 1;
      display: block;
      padding: 0;
      position: relative;
      overflow-x: hidden;
      z-index: 1;

      @media screen and (min-width: 100px) {
        width: $sec-nav-menu-width;
      }

      // overflow scroll hiding effect
      @mixin sec-nav-overflow-bg($direction: 90deg,$defaultColor:yes) {
        @if $defaultColor == 'yes' {
          background-image: -webkit-gradient(linear, left top, left bottom, from($direction), color-stop(30%, rgba($sec-nav-bg, 1)), to(rgba($white, 0)));
          background-image: -webkit-linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
          background-image: -o-linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
          background-image: linear-gradient($direction, rgba($sec-nav-bg, 1) 30%, rgba($white, 0) 100%);
        }
        @else {
          background-image: -webkit-gradient(linear, left top, left bottom, from($direction), color-stop(0%, rgba($sec-nav-bg, 1)), to(rgba($white, 0)));
          background-image: -webkit-linear-gradient($direction, rgba($sec-nav-bg, 1) 0%, rgba($white, 0) 0%);
          background-image: -o-linear-gradient($direction, rgba($sec-nav-bg, 1) 0%, rgba($white, 0) 0%);
          background-image: linear-gradient($direction, rgba($sec-nav-bg, 1) 0%, rgba($white, 0) 0%);
        }
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        width: 30px;
        height: 100%;
        top: 0;
        z-index: 2;
      }

      &:before {
        @include sec-nav-overflow-bg(90deg);
        left: -15px;
      }

      &:after {
        @include sec-nav-overflow-bg(270deg);
        right: 0;
      }

      &.img-icon{
        &:before{
          @include sec-nav-overflow-bg(90deg,no);
          left: -15px;
        }
        &:after {
          @include sec-nav-overflow-bg(270deg,no);
          right: 0;
        }
      }
      
    }


    .sec-navigation {
      display: block;
      padding: 0;
      position: relative;
      overflow-x: auto;
      text-align: center;
      height: $sec-nav-height;
      &::-webkit-scrollbar { display: none; }

    


      @media screen and (max-width: 1199px) {
        overflow-x: scroll;
        -webkit-user-select: none;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
      }


      ul {
        display: inline-block;
        position: relative;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0;
        float: left;
        // prevent selection
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */
        // margin-top: 10px;
        height: $sec-nav-height;
        &:hover,
        &:focus {
          cursor: pointer;
        }

        li {
          list-style-type: none;
          display: inline-block;
          margin: 0;
          padding: 0 20px 0 20px;
          text-align: center;
          vertical-align: middle;
          height: $sec-nav-height;
          &:first-child {
            padding-left: 20px;

            @media screen and (max-width: 479px) {
              padding-left: 20px;
            }
          }

          &:last-child {
            padding-right: 30px;
          }

          a {
            float: left;
            @include font-size(16);
            font-family: $font-family-medium;
            text-align:  center;
            // margin-top: 8px;
            &:hover {
              color: $linkblue;
            }
          }

          .hvr-underline-from-left {
            &:before {
              background: $errorred;
            }
          }
          .hvr-underline-active {
            border-color: $red;
            border-width: 2px;
            border-bottom-style: solid;
          }

          .btn-primary {
            text-align: center;
            margin-top: 0;
            font-family: $font-family;
            padding: 6px 30px 8px;
            position: relative;
            //margin-top: -30px;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
    .sec-nav--arrow{
      display: none;
      position: absolute;
      height: 100%;
      width: 25px;
      @include font-size(24);
      @include opacity(0.2);
      @include z-index(passengarDetails);
      &.left {
        left: -5px;
        top: 0;
      }
      &.right {
        right: -5px;
        top: 0;
      }
      i.fa {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
  &.fixed {
    position: fixed;
    top: 52px;
    left: 0;
    width: 100%;
    padding: 10px 7%;
    background: $white;
    @include z-index(secNav);
  }
}

.secondary-nav--container {
  .secondary-nav {
    .sec-navigation {
      ul {
        li {
          @include respond-to(tablet-portrait) {
            padding: 0 15px;
          }
          @include respond-to(ipad-portrait){
            padding: 5px 15px 10px 15px;
          }
        }
      }
    }
  }
}

@include respond-to(767){
  .secondary-nav--container{
    .secondary-nav{
      .sec-navigation{
        ul{
          li{
            padding: 0 10px;
          }
        }
      }
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  .secondary-nav--container {
    &.headroom--top {
      // margin: 0 7%;
      width: 100%;
    }
    .secondary-nav {
      .business-type {
        display: none;
      }
      .sec-navigation {
        ul {
          height: $sec-nav-height;
          li {
            &:first-child{
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

}

@include respond-to(tablet-portrait) {
  .secondary-nav--container {
    &.headroom--top {
      margin: 0;
    }
    &.headroom--not-top {
      &.headroom--pinned {
        top: 140px;
      }
      &.headroom--unpinned {
        top: 135px;
      }
      .row {
        max-width: 100%;
        margin: 0 2.5%;
      }
    }
    .secondary-nav {
      .sec-navigation {
        ul {
          margin-bottom: 0;
          line-height: 2.5em;
          //padding: 5px 0 10px;
          height: 42px;
          li {
            &:first-child{
              padding-left: 20px;
            }
            a {
              font-size: rem(14);
              //line-height: 1em;
              // padding-bottom: 6px;
            }
            .btn-primary {
              padding: 6px 20px 8px;
            }
          }
        }
      }
      .business-type {
        display: none;
      }
    }
    .sec-nav--arrow{
      display: block !important;
      position: absolute;
      @include font-size(24);
      @include opacity(0.2);
      @include z-index(passengarDetails);
      &.left{
        left: 0;
        top: 2px;
      }
      &.right{
        right: 0;
        top: 2px;
      }
    }
  }
}
/* C020 - Secondary Navigation */
