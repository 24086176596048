.helpcontact {
  $container-width: 1180px;
  $content-padding: 20px;

  background-color: $seashell;
  padding-top: 40px;
  padding-bottom: 40px;

  @include respond-to(767) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }

  @media screen and (min-width: 769px) and (max-width: #{$container-width - 1px + 20px + 20px}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      color: $countdownblue;
      line-height: 1;
      margin: 0;
    }
  }

  &__content {
    max-width: $container-width;
    margin: 0 auto;
  }

  &__separator {
    border-top-color: $lightgray;
    margin: 30px 0;

    @include respond-to(767) {
      margin: 18px 0;
    }
  }

  &__form {
    display: inline-block;
    width: 100%;

    @supports(display: flex) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
              align-items: center;
    }

    @include respond-to(767) {
      @supports(display: flex) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
      }
    }

    .label,
    .input-group,
    .submit {
      display: block;
      float: left;
      margin-right: 10px;

      @include respond-to(767) {
        float: none;
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
        @include respond-to(ipad-portrait){
          margin-right: 15px;
        }
      }

      @supports(display: flex) {
        float: none;
      }
    }

    .submit {
      width: 146px;

      @include respond-to(767) {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .input-group {
      // width: 60%;
      width: calc(100% - 240px - 146px - 20px);

      @include respond-to(767) {
        width: 100%;
      }

      .input-item {
        width: calc(50% - 5px);
        float: left;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        @include respond-to(767) {
          //float: none;
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .booking-form--title {
        @include font-size(14);
        @include z-index(bookformtitle);

        position: absolute;
        margin-left: 24px;
        margin-top: 14px;
        color: $darkgray;

        &.input-filled {
          margin-top: 6px;
          margin-left: 22px;
          @include font-size(12);
        }

        &.has-error {
          color: $errorred;
        }
      }

      select {
        border-radius: 24px;
        height: 48px;
        padding-left: 20.5px;
        padding-right: 48px;
        position: relative;
        z-index: 1;
        width: 100%;
        background-color: $white;
        border-color: $lightgray;
        -webkit-appearance: none;
      }

      .selectize-dropdown {
        overflow: hidden;
      }
    }

    .label {
      width: auto;
      max-width: 240px;
      margin-top: 11px;
      margin-bottom: 11px;
      @include respond-to(ipad-portrait){
        @include font-size(12);
        margin-left: 15px;
      }
      @include respond-to(767) {
        max-width: initial;
        text-align: center;
        margin-top: 0;
        margin-bottom: 25px;
      }
    }
  }

  &__contactinfo {
    .contact__methods {
      display: block;
      width: 100%;
      margin: 0 0 30px;
    }

    .method {
      $heading-height: rem(32);

      width: 100%;
      margin-right: 0;
      padding-right: 10px;
      margin-bottom: 40px;

      @include respond-to(767) {
        margin-bottom: 20px;
        width: 100%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }


      &-heading,
      &-content {
        float: left;
        width: 100%;
      }

      &-icon {
        width: $heading-height;
        height: $heading-height;
        overflow: hidden;
        border-radius: 50%;
        background-color: $blue;
        position: relative;
        float: left;
        margin-right: 10px;

        .fa {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          font-size: rem(14);
          line-height: 1;
          color: $white;
        }
      }

      &-title {
        width: calc(100% - #{$heading-height} - #{rem(10)});
        float: left;

        h3 {
          margin: 0;
          font-size: #{$heading-height / 2};
          line-height: $heading-height;
          font-family: $font-family-medium;
          color: $countdownblue;
        }
      }

      &-content {
        margin-left: #{$heading-height + rem(10)};
        width: calc(100% - #{$heading-height + rem(10)});

        h4,
        p,
        span {
          font-size: rem(14);
          line-height: rem(24);
        }

        h4 {
          font-family: $font-family-medium;
          margin: 0;
        }

        .office-address {
          float: left;
          width: 100%;
        }

        a{
          @include break-word();
        }
      }

      .link {
        &--phone {
          color: $black;
        }

        .icon {
          margin-left: 10px;
        }
      }
      &.method--map{
        width: 100%;
      }
      iframe{
        border: 0;
      }
    }
  }

  &__contactinfo {
    ul{
      &.contact__methods {
        display: flex;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        align-items: flex-start;
        -webkit-align-items: flex-start;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        margin: 0;
        margin-right: #{146px + 10px};
        margin-bottom: -40px;
        padding: 0;
        list-style: none;
        @include respond-to(767) {
          margin-right: 0;
        }
      }

      li{
        &.method {
        $heading-height: rem(32);

        width: calc(#{100% / 3} - #{10px * 2 / 3});
        padding-right: 10px;
        margin-right: 0;
        margin-bottom: 40px;

        @include respond-to(767) {
          margin-bottom: 20px;
          width: 100%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }


        &-heading,
        &-content {
          float: left;
          width: 100%;
        }

        &-icon {
          width: $heading-height;
          height: $heading-height;
          overflow: hidden;
          border-radius: 50%;
          background-color: $blue;
          position: relative;
          float: left;
          margin-right: 10px;

          .fa {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            font-size: rem(14);
            line-height: 1;
            color: $white;
          }
        }

        &-title {
          width: calc(100% - #{$heading-height} - 10px);
          float: left;

          h3 {
            margin: 0;
            font-size: #{$heading-height / 2};
            line-height: $heading-height;
            font-family: $font-family-medium;
            color: $countdownblue;
          }
        }

        &-content {
          margin-left: #{$heading-height + rem(10)};
          width: calc(100% - #{$heading-height + rem(10)});

          h4,
          p,
          span {
            font-size: rem(14);
            line-height: rem(24);
          }

          h4 {
            font-family: $font-family-medium;
            margin: 0;
          }

          .office-address {
            float: left;
            width: 100%;
          }
        }

        .link {
          &--phone {
            color: $black;
          }

          .icon {
            margin-left: 10px;
          }
        }
        &.method--map{
          width: calc(#{100% / 1.8} - #{10px * 2 / 3});
        }
        iframe{
          border: 0;
        }
        }
      }
    }
  }


}
