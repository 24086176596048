table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

.dataTable {
	.dataTables_empty {
		padding: 14px 0;
		text-align: center;
	}
}

@media (min-width: 992px) {
  .table-fixed {
      table-layout: fixed;

    // helper classes for table column widths
    // ie. .tcol-20
    @for $i from 1 through 20 {
      .tcol-#{$i * 5} {
        width: 5% * $i !important;
      }
    }
  }
}
