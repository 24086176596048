#checkout-online-services {
    .header {
        width: 100%;
        height: 100px;
        text-align: center;
        background: #14467B;
        color: white;
    }
    
    .header img {
        display: inline-block;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
    }
    
    .footer {
        width: 100%;
    }
    
    .heading {
        padding: 10px;
        padding-left: 20px !important;
    }
    
    .heading .row {
        padding: 10px;
    }
    
    .heading .row div {
        margin: auto 0;
    }
    
    .heading .row div:nth-child(2) {
        margin-bottom: 20px;
        margin-left: 5px;
    }
    
    .heading .row div img {
        margin-right: 5px;
    }
    
    .amountbox {
        background-color: #F0F3F5;
        padding: 0px;
        max-width: 960px;
        width: 100%;
        margin: auto;
    }
    
    .amountbox .amount-total {
        margin: auto;
        -ms-flex: 60%;
        flex: 60%;
        padding: 10px;
        flex-direction: column;
        padding-left: 20px;
    }
    
    .amount-total div {
        /* padding: 5px */
        font-size: 12px;
    }
    
    .amount-total div.price {
        font-size: 24px;
    }
    
    .amountbox .amount-pay {
        -ms-flex: 40%;
        flex: 40%;
        padding: 10px;
        text-align: center;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    
    .amount-pay div {
        margin: auto;
    }
    
    .row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
        padding: 0px;
    }
    
    .content {
        max-width: 960px;
        margin: auto;
    }
    
    .container {
        background-color: #f2f2f2;
    }
    
    #mablogo {
        width: 172px;
        position: relative;
        top: 5px;
        left: 7px;
    }
    
    input[type=text] {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 3px;
    }
    
    /* label {
        margin-bottom: 10px;
        display: block;
    } */
    
    .icon-container {
        margin-bottom: 20px;
        padding: 7px 0;
        font-size: 24px;
    }
    
    .btn-pay {
        background-color: #D21432;
        color: white;
        padding: 12px;
        margin: 10px 0;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 17px;
        width: 250px;
        outline: none;
        -webkit-appearance: none;
    }
    
    // .btn-pay:hover {
    //     /* background-color: #D2143299; */
    //     /* box-shadow: 0 5px 15px #D2143299; */
    // }
    
    .btn-pay:disabled {
        background: #dddddd;
    }
    
    .btn-cancel {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #333333;
        text-decoration: none;
        padding-bottom: 2px;
        /* border-bottom: solid 1px #d8292f; */
    }
    
    .btn-cancel:hover,
    .btn-cancel:focus {
        text-decoration: none;
    }
    
    a {
        color: #2196F3;
    }
    
    #payment-form-container {
        padding-bottom: 20px;
    }
    
    #overlay {
        margin-top: 74px;
        .img-wrapper {
            text-align: center;
        }
        .loader-desc {
            text-align: center;
        }
        .stay-tuned {
            text-align: center;
            padding-bottom: 20px;
        }
    }
    
    .loader {
        margin: auto;
        border: 8px solid #f3f3f3;
        border-top: 8px solid #005aaa;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        animation: spin 1.2s linear infinite;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
    
        100% {
            transform: rotate(360deg);
        }
    }
    .ama-checkout-ccn-field {
        width: calc(94% - 16px) !important;
    }
    
    .ama-checkout-holdername-field {
        width: calc(94% - 16px) !important;
    }
    
    .ama-checkout-exp-field,
    .ama-checkout-cvv-field {
        width: calc(46% - 16px)!important;
        margin-right: 18px;
    }
    
    #payment-form-container>amadeus-checkout-sdk-mop>ngco-card-form>div.ama-checkout-cc-container {
        padding: 0;
    }
    
    /* accordion */
    .accordion {
        width: 100%;
        overflow: hidden;
        background-color: #fff;
    }
    
    .accordion__label {
        display: flex;
        color: #333333;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        transition: background 0.1s;
        border: 3px solid #EFF3F5;
        border-bottom: 0px;
        /* align-items: center; */
        padding: 14px 10px;
    }
    
    .accordion__content {
        background: #ffffff;
        line-height: 1.6;
        font-size: 0.85em;
        display: none;
        padding: 5px;
        padding-left: 40px;
    }
    
    .accordion__input {
        display: none;
    }
    
    .accordion__input:checked~.accordion__content {
        display: block;
        border: 3px solid #EFF3F5;
        border-top: 0;
        border-bottom: 0;
    }
    
    .accordion__input:checked~.accordion__label {
        border-bottom: 0px;
    }
    
    .accordion__content .desc {
        color: #888888;
    }
    
    .accordion__radio {
        width: 1.25em;
        height: 1.25em;
        border: 2px solid #d8e4e2;
        border-radius: 50%;
        margin-right: 10px;
        box-sizing: border-box;
        padding: 2px;
    }
    
    .accordion__radio::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: #1088CA;
        border-radius: 50%;
    
        transform: scale(0);
        transition: transform 0.15s;
    }
    
    .accordion__input:checked~.accordion__label .accordion__radio::after {
        transform: scale(1);
    }
    
    .accordion__input:checked~.accordion__label .accordion__radio {
        border-color: #1088CA;
    }
    
    .accordion__icon {
        margin-right: 5px;
        width: 30px;
        text-align: center;
    }
    
    .accordion__icon:empty {
        margin-right: 0;
        width: 0;
    }
    
    #paypal+.accordion__label .accordion__icon {
        width: 80px;
    }
    
    #paypal+.accordion__label .accordion__icon:empty {
        width: 0;
    }
    
    /* Inner Radio Selection */
    .radio {
        display: inline-flex;
        overflow: hidden;
        width: 100%;
        /* margin-left: 20px; */
        flex-wrap: wrap;
    }
    
    .radio__input {
        display: none;
    }
    
    .radio__label {
        display: flex;
        border: 2px solid #EFF3F5;
        font-size: 14px;
        width: 131px;
        height: 77px;
        margin: 20px 0px;
        cursor: pointer;
        border-radius: 10px;
        text-align: center;
        margin-right: 30px;
    }
    
    .radio__label img {
        margin: auto;
        width: 100px;
    }
    
    .radio__input:checked+.radio__label {
        border: 4px solid #1088CA;
    }
    
    #amop_container {
        display: none;
    }
    .browser-notice {
        width: 100%;
    }
    label {
        margin-bottom: 0px;
    }
    /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
    @media (max-width: 815px) {
        .row {
            flex-direction: column;
        }
        .heading {
            .row {
                margin: 0 auto;
            }
        }
    
        .amountbox {
            padding: 0 10px;
        }
    
        .amountbox .amount-total {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 10px;
            padding-top: 20px;
            -ms-flex: none;
            flex: none;
        }
    
        .amount-total div {
            font-size: 12px;
        }
    
        .amount-total div.price {
            font-size: 20px;
        }
    
        .amountbox .amount-pay {
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex: none;
            flex: none;
            padding-bottom: 40px;
        }
    
        .amount-pay div {
            width: 100%;
        }
    
        .heading .row {
            flex-direction: row;
        }
    
        .btn-pay {
            width: 100%;
        }
    
        #mablogo {
            width: 141px;
        }
    
        body {
            padding: 0px;
        }
    
        .content {
            padding: 0px;
        }
        .ama-checkout-exp-field,
        .ama-checkout-cvv-field {
            width: calc(43% - 16px)!important;
            margin-right: 20px;
        }
    }
    @media (max-width: 767px) {
        iframe {
            min-height: 0
        }
        .ama-checkout-exp-field,
        .ama-checkout-cvv-field {
            width: calc(44% - 16px)!important;
            margin-right: 14px;
        }
    }
}
#checkout-retry-modal {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    height: 100%;
    .modal {
        width: 500px;
        margin: 400px auto;
        border-radius: 5px;
        padding: 24px;
        background-color: white;
    }
    .modal-body {
        text-align: center;
        #payment-status {
            padding-bottom: 5px;
        }
    }
    .modal-footer {
        padding-top: 35px;
        text-align: center;
        button {
            width: 28%;
        }
    }
    @media (max-width: 550px) {
        .modal {
            width: 350px;
            margin: 420px auto;
        }
        .modal-footer {
            button {
                width: 48%;
            }
        }
    }
}
.body-class {
    height: 100%;
    overflow: hidden;
}
.sidebar-none-events {
    pointer-events: none;
}