.latest-news{
  .news-main--content{
    border-top: 1px solid $lightgray;
    border-bottom: 1px solid $lightgray;
    margin: 15px 0 0 !important;
    .content-holder{
      padding: 20px 0 0 0;
      width: 50% !important;
      label{
        clear: both;
        display: block;
        @include font-size(14);
        font-family: $font-family-medium;
        line-height: 1em;
      }
      p{
        padding: 0;
      }
    }
  }
  .booking-form--title{
    position: absolute;
    left: 22px;
    top: 14px;
    color: #666;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    @include z-index(bookformtitle);
    pointer-events: none;
    display: block;
    max-width: initial;
    margin-bottom: initial;
    font-weight: initial;
    -webkit-transition: all 90ms linear;
    -o-transition: all 90ms linear;
    transition: all 90ms linear;
    &.input-filled{
      top: 6px;
    }
  }
  ul{
    &.nav-tabs{
      padding: 0 18% 0 8%;
      margin-bottom: 20px;
      li{
        float: left;
        a{
          font-family: $font-family-medium;
          color: $countdownblue;
          @include font-size(14);
          padding: 15px 14px;
          &:hover{
            background: transparent;
            border-top: 1px solid $white;
            border-left: 1px solid $white;
            border-right: 1px solid $white;
          }
        }
        &.active{
          margin-top: 3px;
          a{
            border: 0;
            color: $errorred;
            padding: 13px 15px;
            border-bottom: 2px solid $errorred;
            &:before{
              height: 0;
            }
          }
        }
        &:before{
          background: $errorred;
        }
      }
    }
  }
  .tab-content{
    .tab-pane{
      padding: 0 8%;
      .row{
        padding: 0;
          margin: 0;
        .col-lg-12{
          padding: 0;
          margin: 15px 0;
        }
      }
    }
  }
  .select-filter{
    margin-top: 30px !important;
    margin-bottom: 15px !important;
    .col-lg-3{
      padding: 0;
      margin: 0 15px 0 0;
    }
  }
}
.news-feed--container{
  margin: 0 !important;
  @media screen and (min-width:1024px) {
    .latest-news-wrap {
      white-space: nowrap;
    }
  }
}
.news-feed--container{
  .dataTables_wrapper{
    .dataTables_filter {
      display: none !important;
      visibility: hidden !important;
    }
    thead{
      th{
        display: none;
      }
    }
    tbody{
      tr{
        border-bottom: 1px solid $lightgray;
        td{
          padding: 30px 0 30px 15px;
          label{
            font-family: $font-family-medium;
            @include font-size(14);
          }
          p{
            font-family: $font-family;
            @include font-size(14);
            a{
              &:hover{
                color: $focusblue;
                border-bottom: 1px dashed $focusblue;
              }
            }
          }
        }
      }
    }
    .dataTables_info{
      float: left;
      text-align: left;
      padding-left: 0;
      clear: both;
      width: 50%;
      @include font-size(14);
      color: $darkgray;
      margin-top: 30px;
      line-height: 1em;
    }
    .dataTables_paginate {
      float: right;
      text-align: right;
      padding-top: 2em;
      width: 50%;
    }
  }
}

@include respond-to(tablet-portrait){
  .latest-news{
    .select-filter{
      .filterByYear{
        margin-bottom: 15px;
      }
    }
    .news-main--content{
      .content-holder{
        padding: 10px 0 0;
        width: 100% !important;
      }
    }
  }
}
