.members--mymiles--contact {
  $icon-size: rem(32);

  .miles-contact__type {
    margin-bottom: 20px;
  }

  .title {

    display: inline-block;
    margin-bottom: 3px;

    &__icon {
      display: block;
      width: $icon-size;
      height: $icon-size;
      background-color: $countdownblue;
      color: $white;
      border-radius: 50%;
      font-size: rem(14);
      line-height: $icon-size;
      text-align: center;
      margin-right: 10px;
      float: left;
    }

    span {
      display: block;
      width: calc(100% - #{$icon-size} - 10px);
      float: left;
      font-size: rem(16);
      line-height: 1;
      padding-top: (#{$icon-size} - #{rem(16)}) / 2;
      padding-bottom: (#{$icon-size} - #{rem(16)}) / 2;
      font-weight: 600;
      font-family: $font-family-medium;
    }
  }

  .contact__details,
  .contact__list {
    list-style: none;
  }

  .contact__list,
  .offices__note {
    padding-left: #{$icon-size + rem(10)};
  }

  .contact__label {
    font-weight: 600;
    font-family: $font-family-medium;
    font-size: rem(16);
    line-height: rem(24);
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  .contact__details,
  .offices__note {
    font-size: rem(14);
    line-height: rem(24);
  }

  .contact__list {
    .contact__item {
      margin-bottom: 20px;
    }
  }

  .contact__details {
    padding-left: 0;

    li {
      margin-bottom: 3px;
    }
  }
}
