.deals-sidebar{
	.deals-side--content{
		background: $seashell;
		padding: 25px;
		margin-bottom: 25px;
		.from-title{
			color: $gray;
			@include font-size(14);
		}
		h3{
			color: $focusblue;
			@include font-size(30);
			line-height: 1em;
			margin-bottom: 0;
		}
		.trip-type{
			ul{
				width: 100%;
				clear: both;
				display: block;
				margin: 0;
				padding: 0;
				li{
					list-style-type: none;
					float: left;
					color: $midgray;
					@include font-size(12);
					padding: 0 5px 0 0;
					.fa{
						font-size: rem(6);
						color: $midgray;
					}
				}
			}
		}
		.currency-type{
			width: 100%;
			display: inline-block;
			clear: both;
			color: $midgray;
			@include font-size(12);
			margin-top: 15px;
		}
		.amount{
			color: $focusblue;
			@include font-size(30);
			line-height: 1em;
			font-family: $font-family-medium;
			margin-bottom: 15px;
		}
		.btn-primary, .btn-secondary{
			width: auto;
			padding: 15px 30px;
		}
	}
	.deals-img--container{
		position: relative;
		&.display-ppl--view{
			.ppl-view{
				display: block;
			}
		}
		.ppl-view{
			position: absolute;
			background: $red;
			bottom: 0;
			right: 0;
			padding: 10px;
			display: none;
			.view-icon{
				float: left;
				background: $white;
				@include border-radius(100px);
				width: 28px;
				height: 28px;
				padding: 1px 0 0 7px;
				.fa{
					color: $red;
					@include font-size(14);
					line-height: 1em;
				}
			}
			span{
				color: $white;
				@include font-size(14);
				padding-left: 10px;
			}
		}
	}
}
