.miles-extend-find-out-more {
  border-top: solid 1px $lightgray;
  border-bottom: solid 1px $lightgray;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }

  .panel-title a {
    color: $gray;
    display: block;
    position: relative;

    font-weight: 600;
    font-family: $font-family-medium;

    span,
    i {
      font-size: rem(16);
      line-height: rem(26);
    }

    span {
      width: calc(100% - 15px);
      display: inline-block;
    }

    .panel-toggle {
      display: block;
      width: 15px;
      text-align: right;
      float: right;
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transition: 0.25s all ease-in-out;
      -o-transition: 0.25s all ease-in-out;
      transition: 0.25s all ease-in-out;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &[aria-expanded='true'] .panel-toggle:before {
      content: '\f068';
    }
  }

  .panel-body {
    padding-top: 20px;
  }
}
