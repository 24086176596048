.mholidays {
    .slideout-menu {
        background: rgb(206,59,127);
        background: linear-gradient(1800deg, rgba(206,59,127,1) 50%, rgba(241,149,90,1) 100%);
        ul {
            margin: auto!important;
            top:5%;
            max-width: 80%;
            &.mobile-nav {
                background-color: transparent!important;
            }
            li {
                border-color: $white;
                background-color: transparent!important;
                min-height: 45px;
                a {
                    color: $white!important;
                    margin-top: 10px;
                    &:focus, &:hover, &:visited {
                        color: $white!important;
                        background-color: transparent!important;
                   }
               }
            }
            @media screen and (min-width: 768px) {
                li {
                    margin-left: 28px;
                }
            }
        }
        a {
            &.slideOutClose {
                color: $white;
                font-size: 35px;
                margin: 0 20px 20px;
                right: 0;
                position: absolute;
                bottom: 0;
            }
        }
        .fa-close {
            font-size: 25px;
        }
        .logo {
            margin: 0 auto;
            position: relative;
            text-align: center;
            img {
                width: 131px;
                height: 23px;
                margin-top: 30px;
            }
        }
   }
   @media screen and (min-width: 768px) and (max-width: 1200px) {
        .mab-nav--overlay {
            display: none;  
            &.height--expanded {
                height: 100%;
            }
            .navbar-header {
                .navbar-brand {
                    position: relative;
                }
            }
            .navbar-nav {
                margin: 0 !important;
                clear: both;
                width: 100%;
                background-color: $seashell;
            }
            .close-slide {
                @include font-size(20);
                line-height: 1em;
                color: $blue;
                cursor: pointer;
                float: right;
                display: block;
                text-align: right;
                padding: 2% 3% 2% 0;
            }
        }
        .slideout-menu-right {
            right: 0;
        }
        .slideout-menu {
            position: fixed;
            top: 0;
            bottom: 0;
            width: 310px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            z-index: 0;
            display: none;
            background-color: $white;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .slideout-open {
        .slideout-menu {
            display: block;
        }
    }
}