.partner-offer--logofilter{
  background: $seashell;
  h1{
    text-align: center;
    line-height: 1em;
    margin-top: 15px;
  }
  .sort-button-group, .sub-group{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $lightgray;
    a{
      display: inline-block;
      margin: 0 25px;
      padding-bottom: 10px;
      .fa{
        clear: both;
        text-align: center;
        width: 100%;
      }
      &.hvr-underline-from-left{
        &:before{
          background: $errorred;
        }
        &.is-checked{
          color: $gray;
          &:focus, &:active{
            &:before{
              background: $red;
            }
          }
          &:before{
            background: $red;
            right: 0;
          }
        }
        &:focus, &:active{
          &:before{
            background: transparent;
          }
        }
      }
    }
  }
  .sub-group{
    display: none;
    border-bottom: 0;
    margin-top: 35px;
  }
  .partner-logo--grid{
    padding: 0 5% 0 8%;
    margin: 30px 0;

    .grid {
      &:not(.isotope-init) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .partner-logo--items {
          margin-right: 20px;
        }
      }
    }
  }
  .show-partnerlogo--items{
    display: block;
    text-align: center;
    margin-bottom: 30px;
    div{
      display: inline-block;
      @include font-size(14);
    }
    .showing, .total-destinations{
      font-family: $font-family-medium;
    }
  }
  .showmore-partners--logo{
    margin: 30px auto;
      margin-top: 0;
      display: block;
      min-width: 12%;
      width: auto;
      max-width: 22%;
      text-align: center;
      @include respond-to(ipad-portrait){
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    .partner-sublogo-mob {
      display: none !important;
    }
  }
}

.logo-container{
  width: 200px;
  height: auto;
  border: 1px solid $lightgray;
  margin-bottom: 20px;
  background: $white;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 300px;
  }

  img{
    text-align: center;
    display: block;
    margin: 0 auto;
    vertical-align: middle;
    padding: 20px 0;
    width: 100%;
    height: 100%;
  }

  p{
    display: none;
    // border-top: 1px solid $lightgray;
    // padding: 15px 20px;
  } 
  .miles-banner {
    display: none;
  } 
}
.display-label-display {
  .logo-container{
    .miles-banner {
      width: 150px;
      height: 122px;
      overflow: hidden;
      position: absolute;
      display: block;
    }
   
    .miles-banner span {
      position: absolute;  
      width: 219px;
      padding: 2px 0;      
      color: #fff;    
      text-align: center;
      font-size: 12px;    
      right: 8px;
      top: 24px;
      transform: rotate(-45deg);
      &.blue {
        background-color: $blue;
      }
      &.green {
        background-color: #20B2AA;
      }
    } 
  }
}

.show-partner--items{
  display: none;
}

.partner-logo--gutter {
  width: 20px;
}

@include respond-to(1920){
  .partner-offer--logofilter{
    .partner-logo--grid{
      padding: 0;
    }
  }
}

@include respond-to(1440) {
  .partner-offer--logofilter {
    .partner-logo--grid {
      padding: 0;
    }
  }
}

@include respond-to(767) {
  .partner-offer--logofilter {
    .partner-logo--grid {
      padding: 0;
    }

    .partner-logo--gutter {
      width: 0;
    }
  }
}

@include respond-to(tablet-portrait){
  .partner-offer--logofilter{
    padding: 5px 10%;
    h1{
      @include font-size(36);
    }
    .selectize-control{
      &.input-lg{
        margin-bottom: 15px;
      }
    }
    .logo-container{
      margin: 0 auto 20px;
    }
    .showmore-partners--logo{
      width: 60%;
    }
  }
  .show-partner--items{
    display: block;
    text-align: center;
    div{
      display: inline-block;
      @include font-size(14);
    }
    .showing, .total-destinations{
      font-family: $font-family-medium;
    }
    .show-more--partnersLogo{
      margin-top: 15px;
      margin-bottom: 25px;
      width: 50%;
    }
  }
}
.enrich-partners-main {
  background-color: $white;
  .enrich-partners-container {
    .partners-heading {
      h3 {
          width: fit-content;
          margin: auto;
          background-color: #9f874a;
          color: $white;
          text-align: center;
          border-radius: 10px;
          padding: 5px 20px;
      }
    }
    .searchName {
      .fa-search {
        float: left;
        margin-right: 10px;
        margin-top: 3px;
      }
      #searchName {
        border-radius: 10px;
      }
    }
    .searchPartnersSubmit, .clearPartnerSearch {
      border-radius: 10px;
    }
    .searchCategory {
      .selectize-input {           
        border-radius: 10px;
        div {          
            font-weight: bold;
        } 
        
        &.input-active {
        &.dropdown-active {              
            border-radius: 10px 10px 0 0; 
        }
        }        
    
      }        
      .selectize-dropdown {           
          border-radius: 0 0 10px 10px; 
          .option {
            font-weight: bold;
          }
      }
    }
    .partner-details-container {
      margin-top: 40px;
      padding: 7px;
      background-color: #f1f1f1;
      border-radius: 10px;
      #partnersDetailsNav {
        ul#navbarNav {
          display: flex;         
          @include respond-to(tablet-portrait) {
            list-style: none;
            padding: 0;
            display: -ms-flexbox;
            display: flex;
            overflow-x: scroll;
            -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
            border-top-left-radius: 10px;
            margin: 0px;
          }
          li {
            a {
              @include respond-to(ipad-portrait) {
                font-size: 12px;
              }
              display: block;
              justify-content: center;
              align-items: center;
              height: 100%;
              flex-wrap: wrap;
              text-align: center;
              i.fa.fa-plane {
                width: 100%;
                margin: 0 auto;
                display: block;
                text-align: center;
                font-size: 70px;
              }
              @include respond-to(tablet-portrait) {
                padding: 8px 0px;
                font-size: 14px;
              }
              img {      
                @include respond-to(tablet-landscape-desktop) {
                  width: 100%;
                }         
                margin: 0 auto;               
                text-align: center;
                &.logo {
                    display: block;
                    @media screen and (min-width:1024px) {
                      max-width: 100%;
                    }                    
                }
                &.active {
                    display: none;
                }
              }
            }
            &.selected {
              background-color: $white;        
              img {
                &.logo {
                    display: none;
                }
                &.active {
                    display: block;
                }
            }     
            }
            &:first-child.selected {
              border-top-left-radius: 10px;
            }
            &:last-child.selected {
              border-top-right-radius: 10px;
            }
          }                 
        }
        ul#navbarNav::-webkit-scrollbar {
          display: none;
        }
        ul#navbarNav {
          -ms-overflow-style: none; 
          scrollbar-width: none; 
        }
        .seq-next, .seq-sub-next {
          position: absolute;
          top: 55px;
          padding: 7px;
          transform: translateY(-50%);
          background: rgba(0,0,0,.4);         
          border: none;
          color: $white;
          z-index: 5; 
          right: 0;
        }
        .seq-sub-next {
          top: 30px;
        }
        .seq-prev, .seq-sub-prev {
          position: absolute;
          top: 55px;
          padding: 7px;
          transform: translateY(-50%);         
          border: none;
          color: $white;
          background: rgba(0,0,0,.4);
          z-index: 5;  
          left: 0; 
          display: none;
        }
        .seq-sub-prev {
          top: 30px;
        }
        .navbar-nav {
          float: none;
          > li {            
            width: 14.28%;
            background-color: #f1f1f1;
            @include respond-to(tablet-portrait) {
              width: 100%;
              background-color: #f1f1f1;            
              scroll-snap-align: start;
            }
          }         
        }
        .show-partnerlogo--items{
          display: block;
          text-align: center;
          margin-bottom: 30px;
          div{
            display: inline-block;
            @include font-size(14);
          }
          .showing, .total-destinations{
            font-family: $font-family-medium;
          }
        }
        .btn-showmore-partners--logo{
          margin: 30px auto;
            margin-top: 0;
            display: block;
            min-width: 12%;
            width: auto;
            max-width: 22%;
            text-align: center;
            @include border-radius(10px);
            @include respond-to(ipad-portrait){
          }
          @media screen and (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
      .partners-nav {
        border: none;
        margin-bottom: 0px;
        background-color: transparent;
      }
      .partnerWrapper {
        background-color: $white;
        padding-top: 30px;
        @media screen and (min-width: 1200px) {
          .col-lg-2 {
            width: 20%;
          }
        }
        .padding-space {
          padding-left: 5px;
          padding-right: 5px;
        }
        .imagelink {
          &:hover {
            .imagerichtext {
              background-color: #f1f1f1;
            }
            .image-richtext--editor {
              background-color: $white;
              border: none;
              .points-tag {
                background-color: #005aaa;
              }
            }
          }
        }
        .imagerichtext {
          border: 1px solid $enrich-silver;          
          margin-bottom: 30px;
          border-radius: 10px;
          padding: 7px;
          @include respond-to(tablet-portrait) {
            padding: 5px 13px;
          }
          @include respond-to(ipad-portrait) {
            padding: 5px 13px;
          }
          .img-responsive {
              display: block;
              max-width: 100%;
              height: auto;
              padding: 5px;
              margin: 20px auto 0 auto;
          } 
          .page-wrapper {
            background: transparent;
          }          
        }
        .image-richtext--editor {
          margin: 0;
          padding: 10px;
          border-radius: 10px;          
          height: fit-content;
          margin-bottom: 10px;
          .img-content--holder {
            display: block;
            height: 100%;
            text-align: center;
            margin: 0 auto;
          }
          .points-tag {
            position: absolute;
            top: 10px;
            width: 70px;
            font-size: 11px;            
            background-color: $red;
            line-height: 1.2em;
            text-align: center;
            padding: 5px 12px 5px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            color: $white;
            height: fit-content;
            label {
              vertical-align: -webkit-baseline-middle;
              margin-bottom: 0px;
            }
          }
        }
        .retailer-title, .retailer-offer-text {
          text-align: center;
          font: normal bold 13px/14px Gotham-book, Arial;
          @include respond-to(ipad-portrait) {
              font: normal bold 12px/14px Gotham-book, Arial;
          }
          color: #404040;
          width: 100%;    
          display: block;
          line-height: 1.2;
          height: 60px;           
          width: 100%;          
          @include respond-to(tablet-portrait) {
              padding: 0px;
              height: 70px; 
          }
          overflow: hidden; 
        }
        .retailer-title {
          height: 60px;
          @include respond-to(tablet-portrait) {
            height: 60px;
          }
        }
        .retailer-offer-text {
          font-weight: normal;
        }  
        .retailer-content-description{
          height: 130px;
        }      
        .sub-group {
          border-bottom: 0;            
          width: 100%;
          margin-bottom: 30px;
          a {
            display: inline-block;
            margin: 0 12px;
            padding-bottom: 10px;
            background-color: #f1f1f1;
            padding: 5px 35px;
            border-radius: 10px;
            color: #777;
            font-weight: 700;   
            @include respond-to(tablet-portrait) {
              display: flex;
              align-items: center;
              text-align: center;
              padding: 3px 10px;
              font-size: 14px;    
              margin: 0 9px;
              line-height: 19px;
            }  
            @include respond-to(ipad-portrait){
              display: flex;
              align-items: center;
              text-align: center;
            }        
            &.is-checked {
              background-color: #005aaa;
              color: $white;
            }
          }
        }          
        .button-group.sub-group {
          @include respond-to(tablet-portrait) {
            display: flex;
            overflow-x: scroll;
            -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
          }
          @include respond-to(ipad-portrait){
            display: flex;
            overflow-x: scroll;
            -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
          }
        }
        .button-group.sub-group::-webkit-scrollbar {
          display: none;
        }
      }
      .partner-noresults {
        color: #61666f;
        font: normal bold 24px/26px Gotham, Arial;
        text-align: center;
        margin: 30px 0px;
    }
    }
  }
}

