#members--profile--update {
   .title {
		position: relative;
    margin: 0;
    font-family: $font-family;
    color: $blue;
    line-height: initial;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
   }
   .description {
    font: normal normal 14px/20px Gotham, Arial;
		color: #61666f;
		p {
			margin-left: 6px;
		}
   }
   .radio {
     label {
     color: #61666f;
     }
   }
   .subtitle {
     font: normal bold 18px/20px Gotham, Arial !important;
     color: #61666f !important;     
     padding-top: 30px;
     padding-bottom: 10px;
	 }
	 .subtitle-mobile {
		font: normal bold 18px/20px Gotham, Arial !important;
		color: #61666f !important;  
		position: relative;
		margin-left: 0px;
		padding-left: 0px;
		padding-bottom: 30px;
		margin-top: 17px;
	}
	.rightarrow {
		font-size: 26px;
		float: right;
		line-height: 25px;
		font-weight: bolder;
		margin-top: 17px;
		margin-right: 17px;
		color: #006dbb;
	}
	.update-address-item-mob, .update-interest-item-mob {
    i.fa {
      -webkit-transition: 0.2s all ease-in-out;
      -o-transition: 0.2s all ease-in-out;
      transition: 0.2s all ease-in-out;

      float: right;
      line-height: rem(22);
    }

    > .address__menu {
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    .update-address-link.collapsed {
      i.fa {
        -webkit-transform:  rotate(-90deg);
        -ms-transform:  rotate(-90deg);
				transform:  rotate(-90deg);
				margin-top: 17px;	
				margin-right: 17px;					
      }
    }
  }
   label{
		  position: absolute;
    	margin-top: -10px;
   		background-color: white;
    	margin-left: 20px;
    	padding-left: 4px;
		  padding-right: 4px;
	}
	
	input{
		border-radius: 10px 10px 10px 10px;
	}
	.checkboxlabel {
		position: relative;
		margin-left: 0px;
		font: normal normal 14px/16px Gotham, Arial;
		span {
			margin-left: 10px;
		}
		@media screen and (max-width: 767px) {
			margin-left: 2px;
			span {
				margin-left: 5px;
			}
		}
		@media screen and (min-width: 768px) and (max-width: 979px) {
			margin-left: 2px;
			span {
				margin-left: 5px;
			}
		}
		
	}
	.profilecheckbox {
		border: 1px solid #ccc;
    border-radius: 10px;
    padding-top: 15px;
		padding-bottom: 15px;
		color:#61666f;
		box-sizing: border-box !important;		
		cursor: pointer;	
		margin-top: 5px;	
		margin-bottom: 5px;
	}
	.profilecheckboxInterests {
		border: 1px solid #ccc;
    border-radius: 10px;
    padding-top: 15px;
		padding-bottom: 15px;
		color:#61666f;
		box-sizing: border-box !important;
		cursor: pointer;	
		margin-top: 5px;	
		margin-bottom: 5px;		
		@media screen and (max-width: 767px) {
			height: 63px;		
		}
		@media screen and (min-width: 768px) and (max-width: 979px) {
			height: 63px;			
		}
	}
	.ischecked {
		border: 1px solid #006dbb;
	}
	.distance-margin
	{
		margin-top: 15px;
	}
	.distance-padding
	{
		padding-bottom: 15px;
	}
	.distance-padding-mobile
	{
		@media screen and (max-width: 767px) {
			padding-bottom: 15px;
		}
	}
	.btn-secondary{
		margin-left: 10px;
		border-radius: 10px 10px 10px 10px;
	}
	.btn-primary{		
		border-radius: 10px 10px 10px 10px;
	}
	
	.passportinfo	{
		font: normal normal 14px/16px Gotham, Arial;
		color: #006dbb;
		margin-left: 10px;
	}
	.selectize-control{	
			border-radius: 10px;
			.selectize-input {			
				border-radius: 10px;		
				&.input-active {
					&.dropdown-active {			
						border-radius: 10px 10px 0 0;					
					}
				}			
			}
			.selectize-dropdown {		
				border-radius: 0 0 10px 10px;	
		}	
	}
	.phoneinput {
		$countrycode-width: 100%;
		$mob-countrycode-width: 100%;
		&__countrycode,
		&__phonenumber {
		  display: block;
		  float: left;
		}  
		&__countrycode {
		  width: $countrycode-width;
  
		  @media screen and (max-width: 479px) {
			width: $mob-countrycode-width;
		  }
  
		  select,
		  .selectize-control .selectize-input {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		  }
		}
  
		&__phonenumber {
		  width: 100%;
  
		  @media screen and (max-width: 479px) {
			width: 100%;
		  }
  
		  input {
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			border-left-width: 1px;
		  }
		}
		}	
		input[type=checkbox] {
			display:none;
		}
		input[type=checkbox] + label
		 {				
				 height: 12px;				
				 position: relative;		 
				 display:inline-block;				
				 background: url("../images/uncheck.svg") 0 3px no-repeat;     
				 padding: 0 0 0 21px;
				 margin-top: 0px;
				 margin-left: 11px;
				 margin-bottom: 15px;
				 top: 9px;				
				 @media screen and (max-width: 767px) {
					margin-left: 4px;
				 }
				 @media screen and (min-width: 768px) and (max-width: 979px) {
					margin-left: 4px;
				 }
		 }
		 
		 input[type=checkbox]:checked + label
			{
					background: url("../images/check.svg") 0 3px no-repeat;  
					height: 12px;						
					display:inline-block;
					padding: 0 0 0 21px;
					position: relative;
					margin-top: 0px;
					margin-left: 11px;
					margin-bottom: 15px;
					top: 9px;		
					@media screen and (max-width: 767px) {
						margin-left: 4px;
					 }
					 @media screen and (min-width: 768px) and (max-width: 979px) {
						margin-left: 4px;
					 }				
			}		
			.btn-member--submit {
				@media screen and (max-width: 767px) {
					width: 100%;
					margin-top: 20px;
				}
			}			
}
