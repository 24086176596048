// Recent Search Container

.recent-container {
  padding: 10px 20px 0;

  >[class*="col-"] {
    padding: 0;
  }

  .col-lg-11 {
    width: 99%;
  }

  .recent-search--container {
    width: 100%;
    border-bottom: 1px solid $lightgray;
    height: auto;
    overflow: hidden;
    padding-bottom: 10px;
    .title {
      @include font-size(14);
      color: $blue;
      float: left;
      margin-right: 10px;
    }
    .search-content {
      float: left;
      margin-top: 10px;
      // background: $alto;
      @include border-radius(50px);
      padding: 3px 10px;
      margin-right: 5px;
      cursor: pointer;
      transition: all 100ms ease-in-out;
      clear: both;
      div {
        float: left;
        @include font-size(12);
      }
      .sep {
        margin: 0 2px;
      }
      .location {
        margin-right: 5px;
        font-family: $font-family-medium;
      }
      .close-recent--item {
        float: left;
        padding: 0 0 0 8px;
        height: 14px;
        .fa {
          position: relative;
          top: -6px;
          @include font-size(12);
        }
      }
      &:hover {
        background: $linkblue;
        color: $white;
        .fa {
          color: $white;
        }
      }
    }
  }
}


// Book Flight

.ibe-form--container,.ibe-freetrip{
  // @include z-index(bookflight);
  @include box-shadow(2px, 4px, 5px, 0, rgba(0, 0, 0, 0.4));
  background-color: $white;
  height: auto;
  position: relative;
  display: none;
@media (min-width: 768px)
{
      .ibe-sidetrippanel{
            margin-left: 342px;
            padding-right:none !important;

      }
      .ibe_freetrip
      {
        margin-top: 10px;
        padding-right:none !important;
        text-align: center;
        .row
        {
          display: flex; 
          justify-content: center; 
        }
        .ibe_sidetrippanel-title
        {
              font-size: 14px;
        }
        .ibe-sidetrippanel-highlight{
          font-weight: 700;
          color: #60348d;
        }
      }
}
  @media screen and (min-width: 1200px) {
    position: absolute;
  }

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    width: 100%;
    max-width: $ibe-width-less-than-1200;
    margin: 0 auto;
    transform: translateX(-50%);
    left: 50%;
  }
  @media screen and (min-width: 1440px) {
    width: $ibe-width-more-than-1200;
    margin-left: $ibe-left-margin-more-than-1200;
  }

  [class*='multicity-from--'],
  [class*='multicity-to--'],
  [class*='ibe-from--'],
  [class*='ibe-to--'],
  .fromLocation,
  .toLocation {
    .selectize-input {
      .item {
        position: relative; // top: 6px;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .booking-form--title {
    @include font-size(14);
    @include z-index(bookformtitle);
    position: absolute;
    margin-left: 24px;
    margin-top: 14px;
    color: $darkgray;
    transition: all 90ms linear;
    &.input-filled {
      margin-top: 6px;
      margin-left: 22px;
      @include font-size(12);
    }
    &.has-error {
      color: $errorred;
    }
  }

  .date-form--title {
    @include font-size(14);
    @include z-index(bookformtitle);
    position: absolute;
    margin-top: 12px;
    color: $darkgray;
    pointer-events: none;
    display: block;
    max-width: initial;
    margin-bottom: initial;
    font-weight: initial;
    padding-left: 20px;
    &.input-filled {
      @include font-size(12);
      margin-top: 5px;
    }
    &.has-error {
      color: $errorred;
    }
  }

  .container {
    width: 100%;
    // z-index: 1;

    + .recent-container,
    &.recent-container {
      position: relative;
      z-index: 1;
      display: none;
    }

    + .close-container,
    &.close-container {
      z-index: 2;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      .close-booking {
        padding: 2px 6px;
        position: relative;
        display: block;
        float: right;
        &.expanded {
          padding: 10px 15px;
        }
      }
    }

    .fa-times {
      @include font-size(18);
      color: $darkgray;
    }
  }

  .trip-container {
    padding: 10px 20px;
    display: none;
    >[class*="col-"] {
      padding: 0;
    }
    .trip-type--container {
      width: calc(100% - 20px);
      float: left;
      padding-left: 0;
      margin-bottom: 0;
      @include respond-to(tablet-portrait) {
        margin-bottom: 5px;
      }
      li {
        @include font-size(14);
        list-style-type: none;
        float: left;
        color: $gray;
        margin-right: 20px;
        &.selected {
          color: $linkblue !important;
          border-bottom: 1px dashed $linkblue;
          a {
            color: $linkblue !important;
            text-decoration: none;
            &:hover,
            &:focus,
            &:active {
              &:before {
                right: 100% !important;
              }
            }
          }
        }
        a {
          color: $gray !important;
          &:hover,
          a:focus {
            color: $linkblue;
            text-decoration: none;
          }
        }
      }
      .sideTrip,.ibe_sideTrip {
        a {
           color: #60348D !important;
         &:hover,
         a:focus {
           color: #60348D !important;
           text-decoration: none;
         }
        }
        &.selected {
         color: #60348D !important;
         border-bottom: 1px dashed #60348D !important;
         a {
           color: #60348D !important;
         }
     }
    }
    }
  }

  &.cug {
    .trip-container {
      .trip-type--container {
        margin-bottom: 10px;
      }
    }
  }

  .form-control {
    &:focus {
      box-shadow: none;
    }
  }

  .location-container,
  .search-container, .search-container-sidetrip, .date-container-enrich {
    select {
      @include font-size(14);
      &.form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http: //www.w3.org/2000/svg' xmlns: xlink='http: //www.w3.org/1999/xlink' width='18' height='18' viewBox='0 0 24 24'><path fill='$linkblue' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") $white;
        background-position: 98% 50%;
        background-repeat: no-repeat;
        border: 1px solid $lightgray;
        color: $linkblue;
      }
    }
    input {
      @include font-size(14);
      // MABUAT1-72
      // MABUAT1-72
    }
    button {
      &#multiSearchForm {
        display: none;
      }
    }
  }

  [data-form-booking--trip] {
    padding: 0;

    &.expanded {
      padding-bottom: 10px;

      >.location-container {
        padding-bottom: 10px;

        &.multi-cityTrip--Container {
          padding-bottom: 0;
        }
      }
    }

    >.location-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .location-container {
    padding: 0 20px;
    // z-index: 3;
    .col-lg-5 {
      width: 47.175%;
      &.fromLocation {
        padding-left: 0;
      }
      &.toLocation {
        padding-right: 0;
      }
    }
    .col-lg-2 {
      width: 5.6%;
      &.bi-direction {
        display: block;
        text-align: center;
        padding: 10px 0 0;
        cursor: pointer;
      }
      &.one-direction {
        display: none;
        text-align: center;
        padding: 10px 0 0;
        cursor: pointer;
      }
      .flight-trip--icon {
        transition-duration: 0.5s;
        transition-property: transform;
        width: 20px;
      }
    }
    &.multi-cityTrip--Container, &.mobile-multi-cityTrip--Container {
      display: none;
      padding-bottom: 0;
      .multicity-form--title {
        $label-left-offset: 20px;
        @include font-size(14);
        @include z-index(bookformtitle);
        position: absolute;
        left: 30px;
        top: 14px;
        color: $darkgray;
        pointer-events: none;
        display: block;
        max-width: initial;
        margin-bottom: initial;
        font-weight: initial;
        &.input-filled {
          @include font-size(12);
          top: 6px;
          left: $label-left-offset;
        }
        &.has-error {
          color: $errorred;
        }
      }
      .fa-times {
        float: left;
        margin-right: 4px;
        &:before {
          top: 2px;
          position: relative;
        }
      }
      .row {
        margin-left: 0;
        margin-right: 0;
        margin-top: 8px;
        position: relative;
        &:first-child {
          margin-top: 0;
        }
        &.add-another--flight {
          margin-top: 0;

          .col-lg-12 {
            padding-left: 0;
            padding-right: 0;
          }
          .addAnotherFlight {
            width: 100%;
            @include border-radius(50px);
            background: $linkblue;
            color: $white;
            text-transform: initial;
            padding: 6px 0;
            margin: 20px 0 0;
            border: 0;

            @media screen and (min-width: 1201px) {
              margin: 20px 0 10px;
            }
            .fa-plus-circle {
              margin-right: 10px;
            }
          }
        }
        &.multi-city--hide {
          display: none;
        }
      }
      .col-lg-4 {
        padding-right: 0;
        padding-left: 0;
        width: 35.65%;
        &.multicity-fromlocation--one,
        &.multicity-fromlocation--two,
        &.multicity-fromlocation--three,
        &.multicity-fromlocation--four,
        &.multicity-fromlocation--five,
        &.multicity-fromlocation--six {
          padding-left: 0;
        }
      }
      .one-direction {
        width: 5.8%;
        text-align: center;
        padding: 10px 0 0;
        img {
          width: 18px;
        }
      }
      .remove-flight {
        padding: 0;
        width: 7%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
          color: $darkgray;
          @include font-size(12);
        }
        i {
          line-height: 1;
          margin-top: -2px;
        }
      }
      .col-lg-2 {
        width: 15.9%;
        padding-left: 10px;
        padding-right: 10px;
      }
      .book-date--selector {
        @include border-radius(50px);
        border: 1px solid $lightgray;
        height: auto;
        width: 100%;
        overflow: hidden;
        &.has-error {
          border: 1px solid $errorred;
          .date-form--title {
            color: $errorred;
          }
          .col-lg-2,
          .multi-dep-return--title {
            color: $errorred;
          }
        }
        &.datepicker-selected {
          border: 1px solid $linkblue;
        }
        input {
          @include border-radius(50px);
          border: 0;
          padding: 15px 0 0 20px;
          background: $white;
        }
        .col-lg-12 {
          width: 100%;
          border: 0;
          padding: 0;
        }
        .multi-location {
          @include border-radius(50px);
          color: $blue;
          box-shadow: none;
          background: #fff;
          cursor: pointer;
          &.date-filled {
            position: relative;
            @include z-index(filledin);
            top: 8px;
          }
        }
        i {
          &.fa {
            &.fa-calendar {
              position: absolute;
              right: 16px;
              top: 15px;
              @include z-index(bookformtitle);
              pointer-events: none;
              display: block;
              max-width: initial;
              margin-bottom: initial;
              font-weight: initial;
              cursor: pointer;
              color: $midgray;
            }
          }
        }
      }
    }
    &.mobile-multi-cityTrip--Container {     
      .mob-add-flight {        
        .fa-plus-circle {
          margin-right: 10px;
        }
      }
      .mob-remove-flight { 
        .btn-secondary {          
          border:1px solid $red !important;
          color: $red;
          &:before {
            background: $red;
          }
          &.addBlueBkg{
            background: $red !important;
            color: $white !important;
          }
        }


        .fa-minus-circle {
          margin-right: 10px;
        }
      }
      .multi-city--destination-group {
        &.multi-city--hide {
          display: none;
        }
      }
      .flightcount {
        font: normal bold 16px/18px Gotham, Arial;    
        color: $blue;          
        margin-top: 24px;
        margin-bottom: 13px;  
        .fa-plane {
          margin-right: 10px;
        }
      }
      .remove-flight-trash {
        margin-top: 24px;
        margin-bottom: 13px;
        float: right;
        color: $blue !important;
        font: normal bold 22px/23px Gotham, Arial;
      }
    }
    .recent-container {
      padding-left: 0;
      .recent-search--container {
        border-bottom: none;
      }
    }
  }

  .search-container, .search-container-sidetrip, .mc-mob-container, .date-container-enrich {
    // z-index: 2;
    padding: 0 20px;
    padding-bottom: 15px;
    display: none;
    .book-date--selector {
      border: 1px solid $lightgray;
      height: auto;
      width: 100%;
      overflow: hidden;
      @include border-radius(50px);
      &.datepicker-selected {
        border: 1px solid $linkblue;
      }
      &.has-error {
        border: 1px solid $errorred;
        color: $errorred;
        .col-lg-2 {
          color: $errorred;
        }
        label {
          color: $errorred;
        }
      }
      input {
        @include border-radius(0);
        border: 0;
        padding: 5px;
        &.hasDatepicker {
          @include font-size(12);
          &.oneway-trip {
            width: 100% !important;
          }
        }
      }
      .col-lg-12 {
        width: 100%;
        border: 0;
        padding-right: 0;
        padding-left: 0;
        i {
          &.fa-calendar {
            @include z-index(bookformtitle);
            position: absolute;
            right: 16px;
            top: 15px;
            pointer-events: none;
            display: block;
            max-width: initial;
            margin-bottom: initial;
            font-weight: initial;
            cursor: pointer;
            color: $midgray;
          }
        }
        &.has-error {
          border: 1px solid $errorred;
        }
      }
      &.returnTrip, &.returnsideTrip {
        input {
          &.input-lg {
            width: 50%;
            float: left;
            cursor: pointer;
          }
        }
        .departureDate,.departureDateSideTrip {
          color: $blue;
          box-shadow: none;
          background: $white;
          padding: 16px 0 0 20px;
          text-align: left;
          width: calc(45% + 10px);
          &.date-filled {
            position: relative;
            @include z-index(filledin);
            top: 0;
            padding-top: 20px;
            text-align: left;
          }
        }
        .returnDate,.returnDateSideTrip {
          border-left: 1px solid $lightgray;
          color: $blue;
          box-shadow: none;
          background: $white;
          cursor: pointer;
          padding: 16px 0 0 20px;
          text-align: left;
          &.date-filled {
            position: relative;
            @include z-index(filledin);
            top: 0;
            padding-left: 18px;
            padding-top: 20px;
            text-align: left;
          }
        }
      }
      &.checkinCheckout {
        input {
          &.input-lg {
            width: 50%;
            float: left;
            cursor: pointer;
          }
        }
        .departureDate {
          color: $blue;
          box-shadow: none;
          background: $white;
          padding: 16px 0 0 20px;
          text-align: left;
          width: calc(45% + 10px);
          &.date-filled {
            position: relative;
            @include z-index(filledin);
            top: 0;
            padding-top: 20px;
            text-align: left;
          }
        }
        .returnDate {
          border-left: 1px solid $lightgray;
          color: $blue;
          box-shadow: none;
          background: $white;
          cursor: pointer;
          padding: 16px 0 0 20px;
          text-align: left;
          &.date-filled {
            position: relative;
            @include z-index(filledin);
            top: 0;
            padding-left: 18px;
            padding-top: 20px;
            text-align: left;
          }
        }
      }
      &.oneWayTrip, &.oneWaysideTrip {
        display: none;
        .one-dep-title {
          padding-left: 20px;
          position: relative;
          &.input-filled {
            @include font-size(12); //top: 5px;
          }
        }
        .departureDate{
          color: $blue;
          box-shadow: none;
          cursor: pointer;
          background: $white;
          padding: 16px 0 0 20px;
          text-align: left;
          @include border-radius(50px);
          &.date-filled {
            position: relative;
            @include z-index(filledin);
            top: 8px;
          }
        }
      }
      .dep-return--title {
        @include z-index(ibetitle);
        @include font-size(14);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        color: $darkgray;
        pointer-events: none;
        max-width: initial;
        margin-bottom: initial;
        font-weight: initial;
        width: 100%;
        .dep-title {
          position: relative;
          width: 50%;
          &.input-filled {
            @include font-size(12);
          }
        }
        .ret-title {
          width: 50%;
          position: relative;
          &.input-filled {
            @include font-size(12);
          }
        }
      }

      .calendar-icon--container {
        width: 100%;
      }
      .departure-date--icon {
        width: 50%;
        position: absolute;
      }
    }
    .returnsideTrip {
      .ret-title {
        @media screen and (min-width: 768px) {
          padding-left: 15px !important;          
        }       
      }
    }
    .col-lg-4 {
      width: 25%;
      padding-right: 0;
      padding-left: 10px;
    }
    .col-lg-3 { // date field
      width: 25%;
      padding-right: 0;
      padding-left: 0;
      .selectize-input {
        padding: 14px 20px;
        &.select-filled {
          padding: 23px 20px 5px 25px;
        }
      }
    }    
    .col-lg-6 {
      padding-left: 0;
    }
    .col-lg-2 {
      width: 20%;
      padding-right: 0;
      padding-left: 10px;
      &.couponCode {
        width: 15%;
        &.has-error {
          .couponcode-input {
            border: 1px solid $errorred;
          }
          label {
            color: $errorred;
          }
        }
      }
    }
    .cabinClass {
      padding-left: 0;
      .selectize-input {
        .item {
          color: $blue;
          @include font-size(13);
        }
      }
    }
    .col-lg-1 { // submit button
      width: 15%;
      padding-right: 0;
      padding-left: 10px;
    }
    .passenger-details,
    #passengar-details--mobile {
      border: 1px solid $lightgray;
      height: auto;
      width: 100%;
      overflow: hidden;
      padding: 13px 20px;
      position: relative;
      @include z-index(ibetitle);
      cursor: pointer;
      @include border-radius(25px);
      .fa-angle-down {
        position: absolute;
        top: 11px;
        right: 20px;
        @include font-size(18);
        color: $linkblue;
      }
      .title-caption {
        @include font-size(12);
        color: $darkgray;
      }
      .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip  {
        position: relative;
        top: -2px;
        @include font-size(14);
        color: $blue;
        padding: 0 20px 0 0;
        display: none;
        .total-nos, .total-nos-oneway, .total-nos-return, .total-nos-sidetrip {
          float: left;
          margin-right: 5px;
        }
      }
      &.passenger-focused {
        padding: 2px 20px 3px 20px;
        .title-caption {
          //@include font-size(12);
          padding: 3px 0 0;
        }
        &.has-errors {
          border: 1px solid $errorred;
          .title-caption,
          .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip {
            color: $errorred;
          }
        }
      }
      &.has-error {
        border: 1px solid $errorred;
        .title-caption,
        .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip,
        .fa-angle-down {
          color: $errorred;
        }
      }
    }
    .passenger-details {
      width: 100%;

      @media screen and (min-width: 1201px) {
        width: calc(100% - 15px);
      }
    }
    .passenger-details-selected {
      @include z-index(passengarDetails);
      @include border-radius(25px);
      position: absolute;
      border: 1px solid $blue;
      height: auto;
      overflow: hidden;
      margin-top: -48px;
      background: $white;
      display: none;
      padding-bottom: 15px;
      .fa-angle-up {
        position: absolute;
        top: 11px;
        right: 20px;
        color: $linkblue;
        @include font-size(18);
      }
      .title-caption {
        @include font-size(12);
        color: $darkgray;
      }
      .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip {
        @include font-size(14);
        color: $blue;
        border-bottom: 1px solid $lightgray;
        position: relative;
        top: -2px;
        .total-nos, .total-nos-oneway, .total-nos-return, .total-nos-sidetrip {
          float: left;
          margin-right: 5px;
        }
      }
      .booking-person {
        $button-size: 32px;
        margin: 15px 0 0;
        padding: 0;
        .age-label {
          font-size: 13px;
          margin-bottom: 10px;
        }
        [id*='child-age-col'] {
          margin-top:0!important;
        }
        .input-group {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .col-lg-7 {
          width: calc(100% - 10% - #{$button-size} - #{$button-size});
          padding: 0;
        }
        .col-lg-1 {
          width: 10%;
          padding: 0;
          input {
            text-align: center;
          }
        }
        .col-lg-2 {
          width: $button-size;
          padding: 0;
          &.btn-right {
            .input-group--btn {
              float: right;
            }
          }
        }
        button {
          @include border-radius(50px);
          width: $button-size;
          height: $button-size;
          padding: 0;
          margin: 0;
          background: $linkblue;
          border: 1px solid $linkblue;
          color: $white;
          &:disabled {
            background: $white;
            border: 1px solid $focusblue;
            color: $focusblue;
            opacity: 1;
          }
        }
        .booking-category {
          @include font-size(16);
          color: $gray;
          input {
            border: 0;
            box-shadow: none;
            width: 100%;
            padding: 2px 0 0;
            margin: 0;
            float: left;
            color: $gray;
            @include font-size(16);
            font-family: $font-family-medium;
            height: 23px;
            margin-right: 5px;
            background: $white;
          }
        }
        .booking-category--type {
          @include font-size(12);
          color: #797979;
        }
        .infant-booking--error, .infant-booking--error---sidetrip, .infant-booking--error---stbooking,
        .adult-booking--error, .adult-booking--error---sidetrip, .adult-booking--error---stbooking {
          display: none;
          color: $errorred;
          @include font-size(12);
        }
        &.group-booking, &.group-booking-oneway, &.group-booking-return {
          margin: 10px 0 0;
          padding: 0 15px;
          a {
            color: $focusblue !important;
            @include font-size(14);
          }
        }
      }

      &.has-error {
        border: 1px solid $errorred;
        .title-caption,
        .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip,
        .fa-angle-up {
          color: $errorred;
        }
      }
    }
    .passenger-details-selected {
      width: 100%;

      @media screen and (min-width: 1201px) {
        width: calc(100% - 25px);
      }
      .title-caption {
        padding: 5px 20px 0;
      }
      .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip {
        padding: 0 20px 4px;
      }

    }

    .couponcode-input,
    .couponcode-input-mob {
      @include border-radius(50px);
    }
    &.multiCityActive {
      padding: 12px 20px;
      .col-lg-4 {
        padding-left: 0;
        width: 36%;
      }
      .col-lg-2 {
        width: 23%;
      }
      .col-lg-1 {
        width: 18%;
      }
      .col-lg-3 {
        display: none;

        &.cabinClass {
          display: block;
        }
      }
      .passenger-details,
      .passenger-details-selected {
        width: calc(100% - 10px);

        @media screen and (min-width: 1201px) {
          width: calc(100% - 15px);
        }
      }
      .passenger-details-selected {
        .col-lg-3 {
          display: block !important;
        }
      }
    }
  }

  .mc-mob-container {
    .col-lg-12 {
      margin-bottom: 0px !important;
    }
    input {
      background: $white;
      padding: 22px 20px 5px !important;
    }
  }

  .mobile-ibe {
  .search-container, .search-container-sidetrip, .date-container-enrich {
    .passenger-details-selected {
      .booking-person {
        padding: 0 20px;
      }
      .title-caption {
        padding: 5px 20px 0 22px;
      }
      .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip {
        padding: 0 20px 4px 22px;
      }
    }
  }

  }


  // .eticket-nos--container {
  //   padding: 1px 0 0;
  // }

  // .booking-ref--container {
  //   margin: 1px 0 0;
  // }

  .selectize-input {
    padding: 14px 20px;
    &.not-full {
      padding: 17px 20px 11px;
    }
    &.full {
      padding: 23px 20px 5px;
    }
    input {
      top: 6px;
      color: $blue;
      width:100% !important;
    }

    .item + input {
      top: 0;
    }
    div {
      color: $blue; 
      width: 95%;
    }  
  }

  .selectize-control {
    .airportname {
      font-weight: bold;
    }
  
    .airportcode {
      float: right; 
      display: block; 
      background-color: $airportCode; 
      font-weight: bold; 
      color: $white; 
      border-radius: 3px; 
      padding: 4px 6px 2px 6px;
      width: 50px;
      text-align: center;
      margin-top: 0px;
      @include respond-to(tablet-portrait) {
        margin-top: 6px;
      }
    }

    .recentdate {
      font-size: 12px;
      i {
        vertical-align: text-top;
      }
    }

    .airportline {
      margin-bottom: 0px;
    }
    .airportselected {
      font-size: 14px;
    }
    .selectize-dropdown {
      .optgroup {
             .optgroup-header {         
               text-transform: none;
               font-weight: bold;         
               @include font-size(14);       
               padding-left: 20px;       
             }
           }
     }
  }

  input[name="couponcode"],
  .couponcode-input {
    height: 48px;
    color: $blue;
    &:focus {
      border: 1px solid $blue;
      outline: none;
    }
    &.input-focused {
      padding: 21px 20px 4px 21px;
    }
    &:read-only { 
      background-color: $white !important;
    }
  }

}

#mholiday-ibe--container,
#mobile-mholidays--container {
  .fromLocation,
  .toLocation {
    .selectize-input {
      .item {
              text-overflow: ellipsis;
              max-width: 94%;
              overflow: hidden;
              white-space: nowrap;
      }
    }
  }
}

.mobile-ibe {
  .ibe-form--container .col-lg-12.hidden-ticket--value {
    display: none;
  }
}

.mobilelocation-popup {
  position: relative;
  background: $white;
  padding: 0px;
  width: auto;
  max-width: 100%;
  overflow-y: auto;
  // margin: 20px 10%;
  height: 100vh;

  @include respond-to(ipad-portrait) {
    // margin: 20px 5%;
  }

  h3 {
    color: $blue;
    border-bottom: 1px solid $lightgray;
    line-height: rem(20);
    padding-bottom: 15px;
  }

  .mfp-close {
    color: $blue;
    opacity: 1;
    @include font-size(32);
    top: -3px;
    right: 8px;
    cursor: pointer;
  }

  .fromLocation, .statusFromLocation {
    margin-top: 30px;
  }

  .toLocation, .statusToLocation {
    margin-top: 30px;
  }
  .mob-booking-placeholder {
    @include font-size(18);
    @include z-index(bookformtitle);
    position: absolute;
    margin-top: 27px;
    color: $darkgray;
    pointer-events: none;
    padding-left: 12px;
  }
  .plane-landing {
    margin-right: 10px;
    vertical-align: baseline;
  }
  .plane-departure {
    margin-right: 10px;
  }

  .selectize-input {
    padding: 14px 20px;
    &.not-full {
      padding: 22px 20px 11px 10px;
    }
    &.full {
      padding: 22px 20px 5px;
    }
    input {
      top: 6px;
      color: $blue;
      width:100% !important;
      font-size: 18px;
    }

    .item + input {
      top: 0;
    }

    div {
      color: $blue; 
      width: 95%;
    }    
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
    &.input-active {
      &.dropdown-active {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-right: 0px;
        border-left: 0px;
        border-radius: 0px;
      }
    }   
  } 

  .form-control {
    border-radius: 0px;
  }
  
  .selectize-control {
    &.single {
      .selectize-input {
        &:after {
          display: none;
        }
      }
    }

    .selectize-dropdown {      
      border-top: 1px solid $lightgray;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $lightgray;
      border-radius: 0px;
      .optgroup-header {       
        border-top: 1px solid $lightgray;
      }
      @media screen and (max-width: 767px) {
        [data-selectable]{       
          padding: 8px 10px;        
        }
      }
      .optgroup {
        .optgroup-header {         
          text-transform: none;
          font-weight: bold;         
          @include font-size(14);
          padding-left: 10px;
        }
      }
    }

    .selectize-dropdown-content{
      @media screen and (max-width: 767px) {
        max-height: 100vh;
      }
    }

    .airportname {
      font-weight: bold;
    }
  
    .airportcode {
      float: right; 
      display: block; 
      background-color: $airportCode; 
      font-weight: bold; 
      color: $white; 
      border-radius: 3px; 
      padding: 4px 6px 2px 6px;
      width: 50px;
      text-align:center;
      margin-top: 6px;
    }

    .recentdate {
      font-size: 12px;
      i {
        vertical-align: text-top;
      }
    }
    .airportselected {
      font-size: 14px;
    }

    .airportline {
      margin-bottom: 0px;
    }
  } 
  
  .deals-error--validation {
    border-bottom: 1px solid $errorred;
    margin: 0 15px 15px;
    padding: 10px 0;
    display: none;
    width: 100%;
    color: $errorred;
    .fa {
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1 {
      padding-right: 0;
      width: 5%;
      @include respond-to(tablet-portrait) {
        float: left;
        padding-left: 0;
      }
    }
    .col-lg-11 {
      padding-left: 0;
      @include respond-to(tablet-portrait) {
        padding-left: 25px;
      }
    }
    .error-msgs {
      @include font-size(14);
      color: $gray;
    }
    #result {
      @include font-size(14);
      color: $gray;
    }
    .deals-validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }
}

.ibe-mob-from--location-input, .status-from--location-input, .multi-location-input {
  height: 51px;
  div {
    margin-top: 8px;
  }
  .airportname {
    font-weight: bold;
  }

  .airportcode {
    float: right; 
    display: block; 
    background-color: $airportCode; 
    font-weight: bold; 
    color: $white; 
    border-radius: 3px; 
    padding: 4px 6px 2px 6px;
    width: 50px;
    text-align: center;
    margin-top: 6px;
  }
  .airportline {
    margin-bottom: 0px;
  }
  .airportselected {
    font-size: 14px;
  }
}

.ibe-mob-to--location-input, .status-to--location-input {
  height: 51px;
  div {
    margin-top: 8px;
  }
  .airportname {
    font-weight: bold;
  }

  .airportcode {
    float: right; 
    display: block; 
    background-color: $airportCode; 
    font-weight: bold; 
    color: $white; 
    border-radius: 3px; 
    padding: 4px 6px 2px 6px;
    width: 50px;
    text-align: center;
    margin-top: 6px;
  }
  .airportline {
    margin-bottom: 0px;
  }
  .airportselected {
    font-size: 14px;
  }
}

#infant-alert--container,
#infant-mobalert--container {
  position: relative;
  background: $white;
  padding: 20px;
  width: auto;
  max-width: 50%;
  margin: 20px 25%;
  text-align: center;
  @include respond-to(tablet-portrait) {
    max-width: 100%;
    margin: 20px 5%;
  }
  p {
    //color: $blue;
    @include font-size(18);
    border-bottom: 1px solid $lightgray;
    line-height: rem(20);
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  .mfp-close {
    color: $blue;
    opacity: 1;
    @include font-size(32);
    top: 6px;
    right: 8px;
    cursor: pointer;
    display: none;
  }
  button {
    &.btn {
      width: 30%;
      &.ibe-cancel {
        margin-left: 20px;
      }
      @include respond-to(tablet-portrait) {
        width: 45%;
      }
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  .ibe-form--container {
    width: 100%;
    .container {
      &.trip-container {
        .trip-type--container {
          padding-left: 0;
        }
      }
    }
    &#book-flight--container {
      .single-cityTrip--Container, .single-sideTrip--Container {
        .col-lg-5 {
          width: 50%;
          &.fromLocation {
            padding: 0 10px 0 0;
          }
          &.toLocation {
            padding: 0;
          }
        }
        .col-lg-2 {
          display: none !important;
        }
      }
      .multi-cityTrip--Container {
        .col-lg-4 {
          width: 35%;
          padding-left: 0;
          padding-right: 10px;
        }
        .col-lg-2 {
          width: 27%;
          padding-left: 0;
        }
        .col-lg-1 {
          &.one-direction {
            display: none !important;
          }
          &.remove-flight {
            width: 3%;
            padding-left: 0;
            a {
              span {
                display: none;
              }
            }
          }
        }
        $label-left-offset: 20px;
        .multicity-form--title {
          left: $label-left-offset;
        }
        // .book-date--selector {
        //   input {
        //     padding: none;
        //   }
        // }
      
      }
      .location-container {
        $label-left-offset: 21px;
        padding: 10px 20px;
      }
      .search-container, .search-container-sidetrip, .date-container-enrich {
        // MABUAT1-69
        .col-lg-3 {
          width: calc(50% - 10px);
          padding-left: 0;
          margin-right: 10px;
        }
        .col-lg-4 {
          width: 50%;
          padding-right: 0;
          padding-left: 0;
        }
        .col-lg-2 {
          width: 33.3%; // margin-top: 10px;
          padding-top: 10px;
          padding-right: 0;
        }
        .col-lg-1 { // submit button
          width: 33.4%;
          margin-top: 10px;
        }
        &.multiCityActive {
          $mc-label-focus-top-offset: 7px;
          $mc-label-focus-left-offset: 21px;
          $mc-label-top-offset: 14px;
          .col-lg-4 {
            width: 38%;
            margin-left: 0;
          }
          .col-lg-2 {
            padding-top: 0;
            padding-left: 0;
            padding-right: 10px;
            width: 21%;
            margin-top: 0;
          }
          .col-lg-1 {
            width: 20%;
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
          }

          .passenger-details,
          .passenger-details-selected {
            .title-caption {
              top: 1px;
              position: relative;
            }
          }
        }
        .passenger-details-selected {
          $button-size: 32px;
          .booking-person {
            .col-lg-7 {
              width: calc(100% - 10% - #{$button-size} - #{$button-size});
            }
            .col-lg-1 {
              width: 10%;
            }
            .booking-category {
              input {
                padding: 0 4px;
                background: $white;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(tablet-portrait) { // 767px
  $mainnavheight: 76px;
  .mobile-ibe {
    .ibe-form--container {
      margin-left: 0;
      width: 100%;
      padding: 10px 20px 2px; // top: $mainnavheight !important;
      &.expanded {
        padding: 10px 20px;
      }
      .child-age-drp {
        .child-age-label {
          font-size:15px;
          margin-bottom:5px;
        }
        div[id*='child-age-select'] {
          margin-top: 10px;
          div[class*='col-'] {
            display:inline-block;
            label {
              min-width:60px;
            }
          }
          .col-md-5,
          .col-lg-5,
          .col-sm-5,
          .col-xs-5 {
            width:55%!important;
          }
          .col-md-6,
          .col-lg-6,
          .col-sm-6,
          .col-xs-6 {
            width:45%!important;
            text-align: right;
          }
        }
      }
      .mrg-b-0 {
        margin-bottom:0!important;
      }
      @include z-index(mobileIBEForm);
      .col-lg-12 {
        width: 100%;
        display: block;
        clear: both;
        margin-bottom: 10px;
        padding: 0;
      }
      .selectize-control {
        .selectize-input {
          &.select-filled {
            // padding: 22px 20px 5px;
          }
        }
      }
      .container {
        // &.trip-container {
        //   @include z-index(mobileIBE);
        // }
        &.close-container {
          .close-booking {
            padding: 2px 5px;
            &.expanded {
              padding: 10px 15px;
            }
          }
        }
      }
      .trip-container {
        margin-bottom: 10px;
        clear: both;
      }
      .location-container {
        display: block;
      }
      .mobile-multi-cityTrip--Container {
        display: none;
      }
      .search-container, .search-container-sidetrip, .date-container-enrich {
        display: none;
        .book-date--selector {
          .dep-return--title {
            width: 100%;
          }
          input {
            @include border-radius(50px);
            padding: 22px 20px 5px;
            width: 50% !important;
            background: $white;
            &.returnDateMobile,  &.returnDateSideTrip {
              border-left: 1px solid $lightgray;
              @include border-radius(0 50px 50px 0);
            }
          }
          &.oneWayTrip, &.oneWaysideTrip {
            .col-lg-12 {
              margin-bottom: 0 !important;
            }
          }
        }
        .couponCode {
          &.has-error {
            input {
              border: 1px solid $errorred;
            }

            label {
              color: $errorred;
            }
          }
        }
        .couponcode-input-mob {
          padding: 20px 20px 5px 22px;
        }
        .passenger-details-selected {
          width: 100%;
          z-index: 9;
          .booking-person {
            height: auto;
            overflow: hidden;
            padding: 0 20px;

            &.group-booking, &.group-booking-oneway, &.group-booking-return {
              margin: 0;
              padding: 0 20px;
              cursor: pointer;
            }
            .col-lg-1 {
              width: 15%;
            }
          }
          &.passenger-focused,
          .title-caption,
          .total-passengers, .total-passengers-oneway, .total-passengers-return, .total-passengers-sidetrip {
            // padding: 5px 20px 0 22px !important;
          }
        }
        .sidetrippanel {
          display: none;
        }
        .sidetrippanel-highlight {
          font-weight: bold;
          color: $side-trip-violet;
        }
        .sidetrippanel-link {
          color: #0057A6 !important;
          cursor: pointer;
          text-decoration: underline;
        }
        .sidetrippanel-title {
          font-size: 14px;
          text-align: center;
          font:Gotham, Arial;
        }
      }
      .separator {
        height: 1px !important;
        width: 100% !important;
        min-height: 1px !important;
      }
      .booking-ref--container,
      .eticket-nos--container,
      .enrich-member,
      .status-cities--container,
      .status-flightno--container {
        width: 100% !important;
        padding: 2% 0 !important;
        margin-left: 0 !important;

       
      }
      .booking-ref--container,
      .eticket-nos--container {
        a {
          color: $gray !important;
        }
        .col-lg-12 {
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
      .enrich-member {
        margin-bottom: 0 !important;
        button {
          width: 100%;
          display: block;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .infant-booking--error---mobile, .infant-booking--error---sidetrip,
      .adult-booking--error---mobile, .adult-booking--error---sidetrip {
        display: none;
        color: $errorred;
        @include font-size(12);
      }
      &.has-error {
        border: 1px solid $errorred;
      }
      .error-msgs {
        margin-left: 6% !important;
      }
      .mobile-multi-city--bookflight {
        margin-top: 28px;
        margin-bottom: 12px;
      }
    }
  }
}

@include respond-to(475) {
  .mobile-ibe {
    .ibe-form--container {
      .search-container, .search-container-sidetrip, .date-container-enrich {
        .passenger-details-selected {
          .booking-person {
            .col-lg-7 {
              width: 60%;
            }
            .col-lg-1 {
              width: 8% !important;
            }
            .booking-category {
              input {
                padding: 0 5px !important;
                margin-top: 3px;
                background: $white;
              }
            }
          }
        }
      }
      .trip-container {
        margin-bottom: 10px;
        clear: both;
      }
    }
  }
}

.multicity-promo-text{
  display:none;
  h2{
    font-size: 15.6px !important;
    color : #19286e;
    font-weight: 600;
    padding-left:1%;
  }
}

.container--side-trip, .container--book-sidetrip {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 999;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  .sidetrip-eligibility-error {
    width: 100%;
    color: #ed1c24;
    line-height: 1.3125rem;
    margin-bottom: 10px;
    display: none;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }  
  .sidetrip-container, .book--sidetrip-container {     
    .sidetrip-from {
      margin-top: 12px;
      background: #61666E 0% 0% no-repeat padding-box;
      padding: 10px 10px 10px 31px;
      color: $white;
      p {
        font: normal normal 14px/24px Gotham, Arial;
        margin-bottom: 0px;
      }    
    }    
    .oneway-origin, .oneway-destination, .oneway-hub, .visitloc {
      font: normal normal 21px/24px Gotham, Arial;    
    }
    .black {
      color: #37383B;
    }
    .sidetrip-to {
      margin-top: 10px;
      background: #61666E 0% 0% no-repeat padding-box;
      padding: 10px 10px 10px 31px;
      color: $white;
      p {
        font: normal normal 14px/24px Gotham, Arial;
        margin-bottom: 0px;      
      }  
    }
    .sidetrip-or {
      font: normal bold 14px/24px Gotham, Arial;
      padding-top: 19px;
      padding-bottom: 18px;
      text-align: center;     
    }
    .sidetrip-continue {
      margin-top: 10px;
      button {
        &:disabled {
          background:#61666E 0% 0% no-repeat padding-box !important;
          opacity: 1 !important;
        }
      }
    }
    .return-origin, .return-destination {
      font: normal normal 21px/24px Gotham, Arial;
    }   
    .sidetrip-title {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/side-trip-header.png') no-repeat;
      background-size: cover;
      padding: 47px 10px 15px 20px;
      color: $white;
      font: normal bold 16px/18px Gotham, Arial;
      .close-sidetrip {
        float: right;
        margin-top: -40px;
        margin-right: 18px;
        font: normal normal 14px/16px Gotham, Arial;
        i {
          margin-right: 3px;
        }
      }
    }
    .book-sidetrip-title {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/side-trip-header.png') no-repeat;
      background-size: cover;
      padding: 89px 10px 18px 20px;
      color: $white;
      font: normal bold 21px/23px Gotham, Arial;
      .close-book-sidetrip {
        float: right;
        margin-top: -76px;
        margin-right: 18px;
        font: normal normal 14px/16px Gotham, Arial;
        i {
          margin-right: 3px;
        }
      }
    }
    .ibe-sidetrip-container {
      padding: 20px 20px;
      .trip-sidetrip-container {
        padding: 10px 20px; 
        margin-bottom: 28px !important; 
        margin-top: 10px !important;      
        >[class*="col-"] {
          padding: 0;
        }
        .trip-type--container {
          width: calc(100% - 20px);
          float: left;
          padding-left: 0;
          margin-bottom: 0;
          li {
            @include font-size(14);
            list-style-type: none;
            float: left;            
            margin-right: 5px !important;
            font: normal bold 14px/16px Gotham, Arial;
            &.selected {                           
              a {
                background: $blue !important;
                color: $white !important;
                padding: 10px 28px;
                text-decoration: none;
                @include border-radius(20px);
                &:hover,
                &:focus,
                &:active {
                  &:before {
                    right: 100% !important;
                  }
                }
              }
            }
            a {
              background: #d6d6d6 !important;
              color: #61666E !important;
              text-decoration: none;
              padding: 10px 28px;
              @include border-radius(20px);
              &:hover,
              a:focus {
                color: $linkblue;
                text-decoration: none;
              }
            }
          }          
        }
      }
    }
    .book-sidetrip-flight-select-title {
      background: transparent linear-gradient(90deg, #25367B 0%, #007AC4 100%) 0% 0% no-repeat padding-box;;
      padding: 25px 10px 17px 31px;
      color: $white;
      font: normal normal 16px/24px Gotham, Arial;
    }
    .planeicon {
      padding: 0 12px 0 12px;
      vertical-align: baseline;
    }
    .hubstartdate {
      height: 14px;
    }
    .planeicon-domestic {
      padding: 0 5.42px 0 3.42px;
      vertical-align: baseline;
    }
    .itinerarydet {
      margin-top: 5px;
    }
    .rightarrow {
      font-size: 26px;
      float: right;
      line-height: 25px;
      font-weight: bolder;
      margin-top: 0px;
      margin-right: 17px;	    
    }
    .rightarrow-domestic {
      font-size: 26px;
      float: right;
      line-height: 25px;
      font-weight: bolder;
      margin-top: 0px;        
    }
    .sidetrip-oneway-details, .sidetrip-return-details, .sidetrip-domestic-details
    {
      i.fa {
        -webkit-transition: 0.3s all ease-out;
        -o-transition: 0.3s all ease-out;
        transition: 0.3s all ease-out;     
      }

      > .sidetrip__menu {
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }
    }
    .sidetrip-oneway-details.collapsed, .sidetrip-return-details.collapsed {
      i.fa {
        -webkit-transform:  rotate(-90deg);
        -ms-transform:  rotate(-90deg);
        transform:  rotate(-90deg);
        margin-top: 0px;	
        margin-right: 17px;					
      }
    }
    .sidetrip-domestic-details.collapsed {
        i.fa {
          -webkit-transform:  rotate(-90deg);
          -ms-transform:  rotate(-90deg);
          transform:  rotate(-90deg);
          margin-top: 0px;      					
        }
    }
    #sidetrip-oneway-details-expanded, #sidetrip-return-details-expanded {
        background: #d6d6d6;
        padding: 19px 0px 16px 30px;      
        h3 {
          color: #0057A6;
          font: normal bold 16px/24px gotham, Arial;
        }
        .sidetrip-oneway-remove-btn, .sidetrip-return-remove-btn {
          display: none;
          border-radius: 5px 5px 5px 5px;
          width: fit-content;
          background: #D00000 !important;
          float: right;
          margin-top: 10px;
          &:before {
            background: #D00000 !important;
          }
        }
        .removesign {
          margin-left: 10px;
        }
        .sidetrip-itinerary-outer {
          border-left: 2px solid #707070;
          padding-left: 11.5px;
          p {
            font: normal normal 14px/24px Gotham, Arial;
            margin-bottom: 0px;
          }
          .sidetrip-itinerary-oneway-content {
            padding-left: 14.5px;         
          }          
          .sidetrip-itinerary-select-domestic-content {
            border-radius: 20px 20px 20px 20px;
            background: #ebebeb; 
            padding: 14.42px 22px 14.42px 14.5px;          
            margin: 15px 10px 16px 0px;
            .domesticflight-space {
              margin-top: 10px;
            }
            .sidetrip-domestic-from {
              border-radius: 50px;
              background: #61666E;
              font: normal normal 12px/13px Gotham, Arial;           
              height: 53px;            
              margin-top: 20px;
            } 
            .domestic-from-text{            
              padding-top: 10px;
              padding-left: 25px;
              color:  #D6D6D6;
              font: normal normal 12px/13px Gotham, Arial;  
            }   
            .domestic-from-location {            
              padding-left: 25px;
              padding-top: 6px;
              color:  #D6D6D6;
              font: normal normal 12px/13px Gotham, Arial;  
            }
            .margin-space {
              margin-bottom: 10px;
              clear: both;
            } 
            i.fa-calendar {
              z-index: 8;
              position: absolute;
              right: 29px;
              top: 15px;
              pointer-events: none;
              display: block;
              max-width: none;
              margin-bottom: 0;
              font-weight: 400;
              cursor: pointer;
              color: #666;
            }
            .sideTripDate, .sideTripReturnDate {
              background: $white;
              padding: 22px 20px 5px;
              width: 100% !important;
            } 
            .book-date--selector {
              &.oneWayTrip, &.oneWaysideTrip {
                display: block !important;
              }
            }
            .passenger-details-selected {
              width: 90% !important;
              background: $white;
              .booking-ticket--nos {
                padding-left: 0px !important;
                padding-right: 0px !important;
              }           
              
            }  
            .passenger-details {
              background: $white;            
            }     
          }
          .selectize-control {
            .airportname {
              font-size: 12px;
            }
          
            .airportcode {
              font-size: 12px;
              margin-top: 10px;
            }    
            .airportselected {
              font-size: 12px;
            }  
            .airportline {
              margin-bottom: 0px;
            }  
            .airportline2 {
              font: normal normal 12px/20px Gotham, Arial;
            }          
          }        
        }
        .sidetrip-itinerary-outer::before {
          background-color: #d6d6d6;
          content: "";
          display: block;
          height: 11px;
          position: absolute;
          width: 4px;
          margin-left: -14px;
        }   
        .sidetrip-itinerary-outer::after {
          background-color: #d6d6d6;
          content: "";
          display: block;
          height: 35px;
          position: absolute;
          width: 4px;
          margin-left: -14px;
          margin-top: -35px;
        }      
        .startcircle {
          position: absolute;
          margin: 4px 0px 0px -19.5px;
          z-index: 1;
        }
        .midcircle {
          position: absolute;
          margin: 32px 0px 0px -19.5px;
          z-index: 1;
        }
        .endcircle {
          position: absolute;
          margin: 4px 0px 0px -19.5px;
          z-index: 1;
        }

    }
  }
}
  @media (min-width: 768px)
{

  .ibe-freetrip
  {
    display: block !important;
  }
  .ibe-freesidetrip_popup{
    width: 61%;
    min-height: 43em;
    margin: 1.5% auto;
    transform: none !important;
    left: none !important;
    position: unset !important;
    overflow-x: hidden;
    overflow-y: auto !important ;
  }
#ibe_book_sidetrip
{
      .error-validation{
        max-width: 300px;
        margin: 0px;
      }

        .book-sidetrip-title
        {
          background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(../images/side-trip-header.png) no-repeat;
          background-size: cover;
          padding: 47px 10px 15px 20px !important;
          color: #fff;
          font: normal normal 16px/18px Gotham,Arial;
        }
        .book-sidetrip-flight-select-title{
          background: transparent linear-gradient(90deg,#25367b,#007ac4) 0 0 no-repeat padding-box;
          padding: 25px 10px 17px 31px;
          color: #fff;
          font: normal normal 16px/24px Gotham,Arial;
        }
        .ibe-sidetrip-container{
              background-color: white;  
              width: 100%;
              display: grid;
              align-items: initial;
              justify-content: center;

              @media only screen and (min-device-width : 768px) {
                .passenger-details-selected {
                  margin-bottom: 50px;
                }
              }
        }
        .book--sidetrip-container .ibe-sidetrip-container .trip-sidetrip-container .trip-type--container li a
        {
        background: #d6d6d6!important;
            color: #61666e!important;
            text-decoration: none;
            padding: 10px 28px;
            -webkit-background-clip: padding-box;
            -webkit-border-radius: 20px;
            -moz-background-clip: padding-box;
            border-radius: 20px;
            background-clip: padding-box;
        }
        .single-sideTrip--Container,.search-container, .date-container-enrich
        {
          padding: initial;
          display: block;
        }
      .book--sidetrip-container .ibe-sidetrip-container .trip-sidetrip-container .trip-type--container li.selected a
        {
        background: #005aaa!important;
            color: #fff!important;
            padding: 10px 28px;
            text-decoration: none;
            -moz-background-clip: padding-box;
            border-radius: 20px;
            background-clip: padding-box;
        }
        .book--sidetrip-container .ibe-sidetrip-container .trip-sidetrip-container .trip-type--container li
        {
        font-size: .875rem;
        line-height: 1.3125rem;
        list-style-type: none;
        float: left;
        margin-right: 5px!important;
        font: normal 700 14px/16px Gotham,Arial;
        }
        .trip-sidetrip-container{
          margin-bottom: 28px!important;
          // margin-top: 29px!important;
        }
    .form-group
      {
        width: 370px;
        height: 53px;
        margin-bottom: 10px;
      }
      .close-book-sidetrip_ibe{
        float: right;
        margin-top: -40px !important;
        margin-right: 18px;
        font: normal normal 14px/16px Gotham,Arial;
        color: #fff;
      }
      .passenger-details
      {
        width: auto !important;
      }
      .cabinClass {
        padding-left: 15px !important;
      }
      .departureDateSideTrip {
        color: $blue;
        box-shadow: none;
        background: $white;
        padding: 16px 0 0 20px;
        text-align: left;
        width: calc(45% + 10px);
        &.date-filled {
          position: relative;
          @include z-index(filledin);
          top: 0;
          padding-top: 20px;
          text-align: left;
        }
        }

    }
    #ibe_sidetrip
    {
      .sidetrip-from
      {
        .sidetrip-itinerary-outer::before{
             margin-left: -14px !important;
              margin-top: -5px;

        }
      }
      .sidetrip-container{
        @media (min-width: 1200px)
        {
        .col-lg-5{
          width: 45.8% ;
        }
        }
      }
      .sidetrip-oneway-details,.sidetrip-return-details
      {
        color: white
      }
      .sidetrip-form-continue{
        width: 67%;
        margin-bottom: 2%;       
      }
      .sidetrip-continue{
        .col-xs-12{
          text-align: center;
        }
      }
      .close-sidetrip_ibe{
        float: right;
        margin-top: -40px;
        margin-right: 18px;
        color: #fff;
        font: normal normal 14px/16px Gotham, Arial;
        i {
          margin-right: 3px;
        }
      }
    #onewayTripDateSelectorMob,#returnTripDateSelectorMob
      {
        .form-validate{
              padding: inherit;
        }

      }
      .has-error .form-control 
          {
          border-color: #ed1c24 !important;
          }
}
} 
.ibe_freesidetrip
{
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  and (-webkit-min-device-pixel-ratio: 2)
    {
      
      .col-lg-4{
        width: 35% !important;
        padding-right: 10px !important;
      }
    }
}

.sidetrip-calender-align {  
  @media only screen and (min-device-width : 768px) {  
    left: 50% !important;
    margin-left: -27.5em !important;
    top: 50% !important; 
    margin-top: -15.5em !important;
        &:after {
          display: none !important;
        }
        &:before {
          display: none !important;
        }
     }   
}

.cug-container
{
  .cta-button
  {
  padding-top: 5px;
  padding-bottom: 10px;
  }
}
.status-flight--no{
   padding-left: 45px !important;
}
.MHdiv{
  position: relative;
}
.spanMH{
color: #303030 !important;
text-align: left;
margin-top: 25px;
font-size: 12px;
position: absolute;
margin-left: 23px;
}

@media (min-width: 768px)
{
  .ibe-freesidetrip_deskp
  {
      .validation--error-list
      {
          width: max-content;
      }
      .airportcode {
        margin-top: 6px !important;
      }
  }
}

#book-flight--container
{
  .location-container
  {
    .selectize-control 
    {
      .selectize-dropdown 
      {
        .selectize-dropdown-content 
        {
          max-height: 400px;
        }
      }
    }
  }
}

#ibe-standalone-iframe {
  .trip-type--container {
    @include respond-to(tablet-portrait) {
      margin-bottom: 10px;
    }    
  }
  .ibe-form--container {
    max-width: 100vw;
    border: none;
  }
  .mobile-ibe {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.iframe-calendar {
  z-index: 1000 !important;
}

.iframe-banner-container {
  padding: 5px 20px;  
    .iframe-banner-image {
      max-width: 100%;
      max-height: 100%;
      display: block;
  }
}
@media screen and (min-width: 601px) {
  .grabMobileImage{
      display:none;
  }
}

@media only screen and (max-width: 600px) {

  .grabDesktopImage
  {
      display:none;
  }

}
.ui-datepicker td a:after, .ui-datepicker td span:after
{
    content: "";
    display: block;
    text-align: center;
    color: Blue;
    font-size: small;
    font-weight: bold;
}

.ui-datepicker td a:after, .ui-datepicker td span:after {
  content: "";
  display: block;
  text-align: center;
  color:#19286e;
  font-size: small;
  font-weight: 700;
  font-size: 10px !important;
  // @media screen and (min-width: 1500px){
  //   font-size: 13px !important;
  // }
  // @include respond-to(tablet-portrait) {
  //   font-size: 8px !important;
  // }
}
.ui-datepicker table tr td a, .ui-datepicker table tr td span {
  @media screen and (min-width: 1500px){
  padding: 18px 18px;
  }
}
.ui-datepicker.ui-datepicker-multi-2 {
  @media screen and (min-width: 1500px){
    width: 926px!important;
  }
}

#mholiday-ibe--container
    {
      .search-container 
      {
        .passenger-details-selected
          {
            .room_sep
            {
              border-top: 1px solid #ccc;
            }
            .close-bookingroom3,.close-bookingroom2,.close-bookingroomhotel2,.close-bookingroom_hotel3
            {
              float: right;
              color:red;
            }
            width: 425px;
            .label_room
            {
              font-weight: 600!important;
            }
            .passenger_room
            {
              margin-top: 0px !important;
            }
            .booking-person 
            {
              margin:8px 0 0;
              .col-lg-1
              {
                width: 16%;
              }
            }
        }
      }
    }
    #mobile-mholidays--container
    {
      .search-container 
      {
        .passenger-details-selected
          {
            .room_sep
            {
              border-top: 1px solid #ccc;
            }
            .close-bookingroom3,.close-bookingroom2,.close-bookingroom_hotelmob3,.close-bookingroom_hotelmob2
            {
              float: right;
              color:red;
              padding-right: 20px;
            }
            .label_room
            {
              font-weight: 600!important;
            }
            .passenger_room
            {
              margin-top: 0px !important;
            }
            .booking-person 
            {
              margin:8px 0 0;
              .col-lg-1
              {
                width: 16%;
              }
            }
        }
      }
    }

