.miles-replacement-card,
.miles-temporary-card {
  h3.title {
    position: relative;
    margin: 0;
    font-family: $font-family;
    color: #00437D;
    margin-top: 0;
    line-height: initial;
    margin-bottom: 20px;
    font-size: rem(20);
  }
}
