/* Check-in */
#check-in--container,
#flight-status--container,
#login-container,
#view-booking--container,
#view-upgrade,
#mob-checkin--container,
#mob-viewbooking--container,
#mob-flightstatus--container,
#mob-flightupgrade--container
{
  .col-lg-11,
  .col-lg-12,
  .col-lg-5 {
    padding: 0;
    margin: 0;
  }
  .col-lg-4 {
    width: 31%;
    margin-top: 3%;
    button {
      width: 40%;
      float: left;
      margin-right: 4%;
    }
    .input-lg {
      &.input-focused {
        padding: 21px 20px 4px 21px;
      }
    }
  }
  .col-lg-3 {
    padding: 0 0 0 10px;
    width: 22%;
    @include respond-to(1100){
      width: 99%;
      margin-bottom: 15px;
      &.eTicketLastName{
        width: 99%;
      }
      &.checkInLastName{
        width: 20%;
      }
    }
    @include respond-to(tablet-portrait){
      &.checkInLastName, &.eTicketLastName{
        width: 99%;
      }
    }
    .input-lg {
      &.input-focused {
        padding: 21px 20px 4px 21px;
      }
    }
  }
  .col-lg-2 {
    padding: 0 0 0 10px;
    width: 15%;
    @include respond-to(mid-screen-768-1200)
   {
    width: 19%;
   }
    @include respond-to(1100){
      &.checkInDateBook{
        width: 99%;
        margin-bottom: 15px;
      }
      &.checkInSubmitBtn{
        width: 18%;
      }
      .col-sm-1{
        width: 99%;
      }
    }
  }
  @include respond-to(1100){
    .col-sm-1{
      width: 99%;
    }
  }
  .col-lg-1 {
    padding: 0 0 0 10px; // width: 11%;
  }
  .form-group {
    margin-bottom: 0;
    .col-lg-5 {
      padding: 0;
      width: 260px;
      &.checkInBookRefLastName,
      &.checkInEticketLastName,
      &.loginPassword,
      &.mhUpgradeLastName {
        margin-left: 15px;
      }
      &.lastName {
        padding: 0 0 0 10px;
      }
    }
    .col-lg-2 {
      &.checkInHour,
      &.checkInMin {
        display: none;
      }
    }
  }
  .booking-merged-container {
    // float: left;
    width: 100%;
    padding-top: 10px;

    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }

    .booking-methods {
      float: none;
      float: unset;

      @media screen and (min-width: 768px) {
        padding: 0;
      }
    }
    .booking-options {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-block;
      margin-bottom: 10px;

      li {
        margin-right: 15px;
        display: inline-block;
        font-size: rem(14);

        label,
        input {
          &:hover {
            cursor: pointer;
          }
        }
      }

      input {
        margin-right: 5px;
      }
    }
  }
}

#check-in--container,
#flight-status--container,
#view-booking--container,
#view-upgrade,
#login-container,
#mob-checkin--container,
#mob-viewbooking--container,
#mob-flightstatus--container,
#mob-flightupgrade--container
{
  @include z-index(bookflight);
  @include box-shadow(2px, 4px, 5px, 0, rgba(0, 0, 0, 0.4));
  background-color: $white;
  height: auto;
  // position: relative;
  display: none;
  padding: 20px;

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    width: 100%;
    max-width: $ibe-width-less-than-1200;
    margin: 0 auto;
  }
  @media screen and (min-width: 1440px) {
    width: $ibe-width-more-than-1200;
    margin-left: $ibe-left-margin-more-than-1200;
  }

  .eticket-nos--container,
  .status-flightno--container {
    display: none;
  }
  .booking-ref--container,
  .eticket-nos--container,
  .enrich-login {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  }
  .check-in--text {
    margin: 5px 15px;
    width: 97%;
  }
  .checkin-list,
  .flightstatus-list {
    width: calc(100% - 20px);
  }
  .checkin-list,
  .flightstatus-list {
    float: left;
    padding-left: 0;
    margin-bottom: 15px;
    height: 20px;
    li {
      @include font-size(14);
      list-style-type: none;
      float: left;
      color: $gray;
      margin-right: 30px;
      &.selected {
        color: $linkblue !important;
        border-bottom: 1px dashed $linkblue;
        a {
          color: $linkblue !important;
          text-decoration: none;
          &:hover,
          &:focus,
          &:active {
            &:before {
              right: 100% !important;
            }
          }
        }
      }
      a {
        color: $gray !important;
        &:hover,
        a:focus {
          color: $linkblue;
          text-decoration: none;
        }
      }
    }
  }
  .container {
    width: 100%;

    + .close-container,
    &.close-container {
      z-index: 2;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
    }

    .fa-times {
      @include font-size(18);
      color: $darkgray;
    }
  }
  .form-control {
    &:focus {
      box-shadow: none;
    }
  }
  p {
    @include font-size(14);
    color: $darkgray;
    padding-bottom: 10px;
  }
  a {
    &.sub-link {
      margin-left: 24px;
      @include font-size(14); // &.password {
      //   margin-left: 20%;
      // }
      &:hover {
        color: $linkblue;
      }
    }
  }
  h3 {
    color: $blue;
    margin-bottom: 10px;
    line-height: 1em;
  }
  .ibe-input {
    @include border-radius(50px);
    height: 48px;
    color: $blue;
    @include font-size(14);
    &.input-focused {
      padding: 21px 20px 4px 21px;
    }
    &.has-error {
      border: 1px solid $errorred;
    }
    &:focus {
      border: 1px solid $blue;
      box-shadow: none;
    }
  }
  .separator {
    width: 1px;
    height: auto;
    background: $lightgray;
    display: block;
    overflow: hidden;
    min-height: 150px;
    margin: 2px auto 0;
  }
  .remember-my--id {
    @include font-size(14);
    color: $gray;
    margin: 10px 0 0 0px;
    input {
      margin-right: 5px;
    }
  }
}

#check-in--container {
  .col-lg-4 {
    margin-top: 0 !important;
  }
  .disableEle {
    display: none;
  }
  .view-booking--containertab{
    display: none;
  }
}

.booking-ref--container {
  @media screen and (max-width: 325px) {
    .booking-form--title {
      font-size: .825rem;
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  #check-in--container,
  #login-container {
    .col-lg-7,
    .col-lg-4 {
      width: 100%;
      margin-top: 0;
    }
    .col-lg-1 {
      width: 100%;
      .separator {
        width: 100%;
        height: 1px;
        min-height: 1px;
        margin: 3% 0 2%;
      }
    }
  }
  #check-in--container{
    .col-lg-4 {
      width: 45% !important;
    }
  }
}

@include respond-to(tablet-portrait) {
  $mainnavheight: 76px;
  #mob-checkin--container {
    .checkin-list {
      margin-bottom: 0 !important;
    }
    .mobile-checkin {
      margin: 0px 0 5px;
    }
    .col-lg-4,
    .col-lg-3,
    .col-lg-2 {
      width: 100%;
      margin: 0 0 10px 0;
      padding: 0;
    }
    .col-lg-2 {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .input-lg {
      &.input-focused {
        padding: 21px 20px 4px 21px;
      }
    }
    .check-in--text {
      margin: 0px 0 15px 0;
      width: 100%;
      .info,
      p {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    .disableEle {
      display: none;
    }
    &.webCheckin{
      top: 0 !important;
    }
  }
}

@include respond-to(414) {
  #mob-checkin--container {
    .separator {
      margin: 0 auto;
    }
    .checkin-list {
      li {
        margin-right: 13px !important;
      }
    }
  }
}

@include respond-to(375) {
  #mob-checkin--container {
    .checkin-list {
      height: 45px;
    }
  }
}
@include respond-to(375) {
 #mob-flightupgrade--container {
   .form-control{
    padding-top: 30px !important;
   }
 }
}
#mob-flightupgrade--container{
  li{
    margin-right: 4% !important;
  }
  ul{
    width: calc(148% - 20px) !important;
  }
}