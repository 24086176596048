.download-container{
	border: 1px solid $lightgray;
	background: $seashell;
	padding: 25px;
	margin-bottom: 25px;
	h4{
		color: $gray;
		@include font-size(24);
		margin-bottom: 10px;
	}
	h5{
		color: $blue;
		@include font-size(18);
		line-height: 1em;
		margin-bottom: 12px;
	}
	p{
		color: $gray;
		@include font-size(18);
		line-height: 1em;
		&.file-size{
			text-transform: uppercase;
			margin-bottom: 30px;
		}
	}
	.sep{
		border: 1px solid $errorred;
		width: 60px;
		margin-bottom: 25px;
	}
	.fa{
		margin-right: 5px;
	}
}