$z-index: (
  modal: 200,
  mobileIBEForm: 150,
  selectcountry: 103,
  navToggle: 102,
  languageSelectorSelected: 101,
  searchcontainer: 104,
  alertWindow: 100,
  languageSelector: 98,
  searchinputiconfocus: 97,
  searchinputfocus: 96,
  searchinput: 95,
  ibenav: 93,
  ibewindow: 91,
  viewbooking: 90,
  secNav: 32,
  columnContent: 31,
  columnImage: 30,
  hotSpotTxt: 26,
  hotSportImg: 25,
  videoText: 24,
  videoComponent: 23,
  mobileIBE: 22,
  bookflight:21,
  ibelinks: 20,
  bannerbg: 19,
  quicklinksIcon: 18,
  quicklinksBg: 17,
  dealssearchicon: 16,
  dealsearchinput: 15,
  dealsimgtxt:14,
  carouselIndex: 10,
  passengarDetails: 9,
  bookformtitle: 8,
  ibetitle: 7,
  filledin: 6,
  containermainheader: 999,
  containerbookingibe: 201
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}