.miles-claim-missing {
}

.miles-sample-dialog {
  max-width: 1024px;
  background-color: $white;
  padding: 20px;
  margin: 0 auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;

  img {
    max-width: 100%;
    margin: 0 auto;
  }

  .carousel {
    width: 100%;
    padding-left: 0;
  }

  .slick-slide {
    position: relative;
  }


  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;

    &:before {
      font-size: rem(36);
    }
  }

  .slick-prev {
    left: -20px;
  }

  .slick-next {
    right: -20px;
  }

  .slick-dots {
    border-top: 0;
    margin: 0;
    padding: 0;
    bottom: initial;

    li button {
      &:before {
        margin: 5px;
        width: 12px;
        height: 12px;
        content: '';
        background: $black;
        border-radius: 50%;
      }
    }
  }
}
