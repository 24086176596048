.quick-links--container {
  padding: 70px 6% 40px;
  width: 100%;

  @include respond-to(420) {
    padding: 30px;

  }

  .col-fixed {
    @include respond-to(tablet-portrait) {
      width: 50%;
    }
  }
  .col-lg-3{
    padding-left: 0px;
    padding-right: 0px;
  }
  .col-centered {
    text-align: center;

    @include respond-to(tablet-portrait) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .links-icon--container {
    text-align: center;
    width: 160px;
    height: 160px;
    margin: 0 auto 10px;

    @include respond-to(tablet-portrait) {
      margin: 0 auto;
    }

    @include respond-to(420) {
      width: 120px;
      height: 120px;
    }

    img {
      position: relative;
      @include z-index(passengarDetails);

      @include respond-to(420) {
        width: 90%;
      }
    }
  }
  .links-content--container {
    text-align: center;
    h4 {
      color: $gray;
      margin-bottom: 0;

      @include respond-to(420) {
        @include font-size(18);
      }
    }
    p {
      @include font-size(14);
      padding: 0 10%;
    }
  }

  a {
    p {
      color: $gray;
    }
  }
}

.quick-links-center{
  display: flex;
  justify-content: center;
}
#quick-links-small--container{
.slick-track{
     display: flex;
    justify-content: center;
    width: unset !important;
}
  padding:30px 45px 0px 45px; 
  width:88%;
  @media screen and (max-width:1200px) {
    width:94%;
  //  margin-right:35px;     // background fix change 
  }
  @media screen and (max-width: 767px){
    padding: 30px;
  }
  @include respond-to(tablet-portrait) {
    width:94%;
    margin-right:20px;
  }
  .col-fixed {
    width: 16.6%;
    @include respond-to(tablet-portrait) {
      width: 25%;
    }
  }

    .links-icon--container {
        width: 75px;
      @include respond-to(tablet-portrait) {
        width: 40px;
      }
      height: auto;
      @include respond-to(420) {
        width: 40px;
      }
    }
    img {
      width: 100%;
    }
    .links-content--container h4 {
      font-size: 1rem;
      line-height: 1.3875rem;
      @include respond-to(tablet-portrait) {
        font-size: .8rem;
        line-height: 1.1875rem;
      }
      @include respond-to(420) {
        font-size: .7rem;
      }
    }
    .slick-next, .slick-prev{
      @include respond-to(420) {
      top:30%;
      }
    }

}


