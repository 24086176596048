/** Members -- Button Styles */
.members__content {
  .btn-member--cta,
  .btn-member--submit {
    min-width: 146px;
    width: auto;
    margin-top: 20px;
  }

  .btn-member--submit {
    margin-top: 0;
    // float: left;
  }

  // print-card
  .btn-member--miles-card {
    min-width: 300px;
    width: auto;
    margin-top: 10px;
  }


  .btn-member--download-btn {
    &:hover,
    &:active,
    &:focus {
      color: $countdownblue;
      box-shadow: none;
    }

    i.fa {
      margin-right: 10px;
    }
  }
  // print-card
}
.btn-reservation--download-div{
  margin-top: 27px;
  text-align: center;
  .btn-reservation--download-btn {
    &:hover,
    &:active,
    &:focus {
      color: $countdownblue;
      box-shadow: none;
    }
    width: auto;
    min-width: 30%;
    i.fa {
      margin-right: 10px;
    }
  }
}