#members-lcpindex-body
{
  form {
    width: 100%;
  }
  b {
    font-weight: 600;
    font-family: Gotham-Medium,Arial;
  }
  .allg_table
  {
    text-align: center;
    color: #094995;
  }
      .transform_benefit
      {
        transform: rotate(180deg); 
      }
      .member-lcp-points{
        background: $seashell 0% 0% no-repeat padding-box;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 10px;
    }
      h5{
        color: $countdownblue;
      }
      table{
        
        padding: $table-cell-padding
      }
  .member-lcp-points{
        background: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 25px;
        padding: 25px 20px;
        margin-bottom: 30px;
        font-family: Gotham-Medium,Arial;
        @media (max-width: 767px)
        {
          font-size: 11px;
        }
        .row
        {
          margin: 0px;
        }
        .life-time-span-div {
          margin-top: 15px;
        }
        .lifetime-points-button{
          border-radius: 5px;
          padding: 6px 25px;
          border: 1px solid #e5e5e5;
        }
  }
  .member-lcp-points-expiry{
    background: #fff;
    border: 1px solid #e5e5e5;
        border-radius: 25px;
        padding: 25px 20px;
        margin-bottom: 30px;
        font-family: Gotham-Book;
        @media (max-width: 767px)
        {
          font-size: 11px;
        }
        .row
        {
          margin: 0px;
        }
        p {
          display: inline;
        }
        a
        {
          color: #242222;
          text-decoration: underline;
        }
  }
        .right-align
        {
          text-align:right;
        }
        .center-align
        {
          text-align:center;
        }
        h5{
          color: $countdownblue;
        }
        .lifetime-points
        {
          .row
          {
            margin: 0px;
          }
          @include font-size(13);
          font-family: FontAwesome;
          @media (max-width: 767px)
              {
                font-size: 11px;
              }
        }
        .points-expiry
        {
          @include font-size(13);
          font-family: FontAwesome;
          @media (max-width: 767px)
              {
                font-size: 11px;
              }
        }
      .lifetime-points
      {
        @include font-size(13);
        font-family: FontAwesome;
      }
      table{
        width: 100%;
        margin-bottom: 30px;
      }
      h4
      {
        color: #006dbb;
      }
      .enrich-status-benefit
      {
        padding-left: 30px;
        @media (max-width: 767px)
        {
          padding-left: 30px;
          font-size: 13px;
        }
        background: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 25px;
        padding: 25px 20px;
        margin-bottom: 30px;
        @media (max-width: 767px)
          {
            font-size: 11px;
          }
        table {
          border-collapse: collapse;
        }
        table td {
          width: 270px;
          background: #eee;
          text-align: center;
          border-radius: 5px;
          @media (min-width: 768px)
          {
            width: 50px;
          }
          @media (max-width: 767px)
          {
            border: 1px solid black;
          }
        }
        .enrich_benefit {
          .heading {
            background-color: #f1f1f1;
            border-radius: 5px;
            padding: 5px 15px;
            font-weight: 600;
            font-family: Gotham-Medium,Arial
          }
          .data {
            padding: 5px 15px;
          }
          .main-heading {
            padding-bottom: 15px;
          }
        }
        .accordHeader{
          color: #006dbb;
        }
        .accordHeaderHightOne{
          padding-top:15px;
        }
        .accordHeaderHightTwo{
          padding-top:10px;
        }
        .main-heading {
          margin-bottom: 15px;
        }
      }
     
      .enrich_elitetext
      {
        position: absolute;
        margin-top: -6px;
        padding-left: 7px;
        font-size: 11px;
        width: 63px;
        @media (max-width: 767px)
        {
          margin-top: 10px;
          margin-left: -57px;
          position: absolute;
        }
      }
    .marker_position
      {
        margin-top: -48PX;
      }
      .marker_values
      {
        margin-top: -25px;
      }

      .enrich_statusmsg {
        margin-top: 32px;
        font-size: 14px;
        .enrich-points-number, .enrich-status-date {
          display: inline-block;
          padding: 9px;
          height: 36px;
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          background: #eee;
        }
        p {
          margin: 0px;
          padding: 4px 0px;
        }  
      }
      .members--enrich--progress
      {
        background: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 25px;
        font-size: 14px;
        padding: 25px 30px 23px 22px;
        margin-bottom: 30px;
        @media (max-width: 767px)
        {
          font-size: 11px;
        }
        .row
        {
          margin: 0px;
        }
      }
        
        .rangecaldiv{
            margin-top: 20px;
            margin-right:0px ;
            margin-left:0px !important;
        }
        .range-slider {
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 100%;
            height: 10px;
            background: #e5e5e5;
            outline: none;
            opacity: 0.7;
            -webkit-transition: .2s;
            transition: opacity .2s;
            border-radius: 25px;
          }
          .range-slider::-webkit-slider-runnable-track {
            overflow: hidden;
          }
        
          .range-slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 10px;
            height: 10px;
            background:  #9f874a;
            cursor: pointer;
            border-radius: 25px;
          }
          // .range-slider::-moz-range-thumb {
          //   width: 25px;
          //   height: 15px;
          //   background:  #9f874a;
          //   cursor: pointer;
          //   display: none;
          // }
          // .range-slider::-moz-range-progress {
          //   background:  #868684;
          // }
          .range-slider::-moz-range-track {
            background: #e5e5e5;
            border: none;
          }
          .range-slider::-moz-range-thumb {
            border: none;
            width: 10px;
            height: 10px;
            border-radius: 25px;
            background:  #9f874a;
            cursor: pointer;
          }
          .range-slider::-moz-range-progress {
            background: #9f874a;
            width: 10px;
            height: 10px;
          }
        
    .rangecaldiv {
      margin-left: 0.5%;
      position: relative;
      .enrich_points {
        width: 14px;
        text-align: center;
        position: absolute;
        top: -14px;
      }
      .fa-caret-down {
        font-size: 25px;
      }
    }
    .bg-color {
      background-color: #f1f1f1;
      height: 30px;
      height: 35px;
      padding: 8px 10px;
      border-radius: 5px;
      .points {
        text-align: right;
      }
    }
    .bg-color:nth-child(even) {
      background-color: #fff;
    }
    td {
      padding: 9px;
    }

    .radio {
      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: 0.5em;
      font-size: 2.25rem;
      color: #F4F4F4;
      &:focus-within {
        .radio__label {
          transform: scale(1.05);
          opacity: 1;
        }
      }
    }
    .radio__label {
      line-height: 1;
      transition: 180ms all ease-in-out;
      opacity: 0.8;
    }
    .radio__input {
      display: flex;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      input:focus {
        outline: none;
      }
    }
    .radio-before {
      position: absolute;
      top: -6px;
      left: 1px;
      .radio__control {
        display: grid;
        place-items: center;
      }
    
      .active {
        &.one::before {
          content: "";
          width: 12px;
          height: 12px;
          box-shadow: inset 0.5em 0.5em #58cdf5;
          border-radius: 50%;
          transition: 180ms transform ease-in-out;
        }
        &.two::before {
          content: "";
          width: 12px;
          height: 12px;
          box-shadow: inset 0.5em 0.5em #9ba3ab;
          border-radius: 50%;
          transition: 180ms transform ease-in-out;
        }
        &.three::before {
          content: "";
          width: 12px;
          height: 12px;
          box-shadow: inset 0.5em 0.5em #c3a451;
          border-radius: 50%;
          transition: 180ms transform ease-in-out;
        }
        &.four::before {
          content: "";
          width: 12px;
          height: 12px;
          box-shadow: inset 0.5em 0.5em #515356;
          border-radius: 50%;
          transition: 180ms transform ease-in-out;
        }
      }
      .no-active::before {
        content: "";
        width: 12px;
        height: 12px;
        // /box-shadow: inset 0.5em 0.5em #58cdf5;
        border-radius: 50%;
        transition: 180ms transform ease-in-out;
      }
    
      input:checked + .radio__control::before {
        transform: scale(1);
      }
    }
    .radio__control {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #F4F4F4;
      transform: translateY(-.05em);
      background-color: white;
    }
    .range__field {
      position: relative;
    }
    .card-images {
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      top: 14px;
      color: #000;
      font-size: 12px;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .icon-style {
      position: relative;
    }
}
.pt-14 {
  padding-top: 14px;
}
.p-0 {
  padding: 0px;
}
.configure-component {
  margin-top: 14px;
  padding-left: 5px;
  font-family: Gotham-Book,Arial;
  padding-bottom: 10px;
}
.members--enrich--progress, #status_benefit, .member-lcp-points, .member-lcp-points-expiry {
  font-size: 14px;
  h4 {
    span {
      font-size: 36px;
    }
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  #members-lcpindex-body  {
    .radio-before:last-child {
      left: 100% !important;
    }
    .bg-color {
      background-color: #f1f1f1;
    }
    td {
      padding: 9px;
    .enrich_statusmsg {
      padding: 0px;
      font-size: 11px;
      p {
        padding: 4px 4px 0px;
      }
      .enrich-points-number, .enrich-status-date {
        padding: 4px;
        height: 26px;
      }
    }
    .member-lcp-points-expiry {
      font-size: 14px;
      padding: 25px 15px;
    }
    .member-lcp-points {
      font-size: 11px;
    }
    .lifetime-points-div {
      width: 58%;
      display: inline-block;
      padding-left: 1px;
      font-size: 11px;
    }
    .configure-component {
      font-size: 11px;
      padding-bottom: 10px;
      padding-left: 8px;
    }
    .bg-color {
      font-size: 11px;
    }
  }
  .members--enrich--progress, #status_benefit, .member-lcp-points, .member-lcp-points-expiry {
    font-size: 14px;
    h4 {
      text-align: center;
      span {
        font-size: 30px;
      }
    }
  }
}
  h4 {
    padding: 0px;
    margin: 0px;
  }
  .main-heading {
    margin-top: 12px;
  }
  .life-time-span-div {
    font-size: 11px;
    margin-top: 15px;
    text-align: center;
  }
}
#members-lcpindex-body {
  .range-slider::-webkit-slider-thumb {
    box-shadow: -100em 0 0 99.9em #9f874a;
  }
}
@media (min-width: 768px) and (max-width: 1024px){
  .col-sm-6 {
       width: 50% !important;
  }
}