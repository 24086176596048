.signpost-container {
  padding: 20px 8%;
  width: 100%;
  @include respond-to(767) {
    padding: 20px 0% !important;
  }
  .col-fixed {
    width: calc(33.333% - 20px);
    margin-bottom: 30px !important;
    margin-right: 20px !important;
    @include respond-to(991) {
      width: calc(50% - 20px);
      &:nth-child(2) {
        margin-right: 0 !important;
      }
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
  .col-centered {
    text-align: center;

    [class*='col-'] {
      display: inline-block;
      float: none;
      margin: 0;
      padding: 0;
    }
  }

  .signpost-content--container {
    z-index: 18;
    position: absolute;
    width: 70%;
    bottom: 20px;
    left: 20px;
    text-align: left;
    @include respond-to(ipad-portrait) {
      width: 80%;
    }
    &.bottomPos{
      bottom: 20px;
      h4{
        margin-bottom: 45px;
      }
      p{
        margin-bottom: 40px;
      }
    }
    h4 {
      color: $white;
      @include font-size(30);
      margin-bottom: 10px;
      line-height: 1.25em;
    }
    p {
      @include font-size(16);
      color: $white;
      margin-bottom: 15px;
      @include respond-to(ipad-portrait) {
        line-height: 1em;
      }
    }
    .btn-primary {
      width: auto;
      text-align: center;
    }
  }

  .singpost-img--wrap {
    position: relative;
    display: block;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      right: 0;
      height: 80%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    }
  }
}

@include respond-to(tablet-portrait) {
  .signpost-container {
    padding-top: 0;
    padding-bottom: 50px;

    .seq {
      overflow: visible;

      .seq-screen {
        position: relative;
        height: 100%;
      }

      .slick-track {
        height: 100%;
      }

      .signpost {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .seq-mob-cta {
        position: relative;
        height: 100%;
        padding: 15px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .seq-step {
        position: relative;
        height: 100%;
      }

      .seq-title {
        position: relative;
        z-index: 2;
        height: auto;
        margin-left: 25px;
        margin-top:30%;
        h4 {
          color: $white;
          line-height: 1em;
          margin-bottom: 10px;
        }

        .btn-primary {
          width: auto;
        }
      }

      .seq-pagination {
        bottom: 10px;
      }

      .seq-canvas {
        position: relative;
        height: 100%;

        img {
          z-index: 1;
          min-height: auto !important;
          position: absolute;
          height: 100%;
          width: auto;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}

@include respond-to(500) {
  .signpost-container {
    .seq {
      height: 290px;
    }
  }
}
