// colors

$white: #fff;
$black: #000;
$gray: #333;
$midgray: #666;
$darkgray: #61666F;
$lightgray: #ccc;
$blue: #005AAA;
$ibeblue: #007DC8;
$focusblue: #19286E;
$green: #3E810C;
$red: #ED1C24;
$errorred: $red;
$seashell: #F1F1F1;
$linkblue: $blue;
$alertred: $red;
$orange: #FF7600;
$alertorange: $orange;
$calendartoday: #D8EDF9;
$calendarSelected: #00A9E5;
$airportCode: #006DBB;
$footercolor: #FAFAFA;
$dealsred: $red;
$dealsprice: $gray;
$countdownblue: #00437D;
$countdownviolet: #8430A6;
$countdownyellow: #CEA200;
$countdowngreen: #7ED321;

$dealgray: #D8D8D8; // not in style-guide
$alto: #dddddd; // not in style-guide
$wildsand: #f6f6f6; // not in style-guide
$jumbo: #88888c; // not in style-guide
$jumbodark: #77777c; // not in style-guide
$mabBlue: #0393a6; // not in style-guide
$mabBlueLight: #29A1D6; // not in style-guide
$pink: #cc073c; // not in style-guide

$base-bg-color: $white;

// Enrich colors
$enrich-neutral: $mabBlue;
$enrich-gold: #B0925E;
$enrich-silver: #C0C0C0;
$enrich-platinum: #231F20;
$enrich-blue: #24C6F5;

//mholidays
$holiday-bg-color:#B0B0B0;
$holidays-form-color: #13adc5;
$holidays-form-button-bgcolor: #1acaec;
$holidays-form-input-label: #cfcfcf;
$holidays-form-field-selected-val: #2d2d2d;

// Font-stack -----------------------------------------------------------------

//free-side-trip
$side-trip-violet: #60348D;

$em-base: 16px;

$font-family: 'Gotham-Book', Arial !default;
$font-family-italic: 'Gotham-Book-Italic', Arial !default !default;
$font-family-light: 'Gotham-Light', Arial !default !default;
$font-family-lightitalic: 'Gotham-Light-Italic', Arial !default !default;
$font-family-medium: 'Gotham-Medium', Arial !default !default;
$font-family-mediumitalic: 'Gotham-Medium-Italic', Arial !default !default;
$font-family-bold: 'Gotham-Bold', Arial !default !default;
$letter-space: normal;
$font-smoothing: subpixel-antialiased !default;
$font-path:"https://digital.malaysiaairlines.com";


// Webkit tap highlight color for mobile
$webkit-tap-highlight-color: rgba(0, 0, 0, .13);

/*
 *  Links color
 */
$link-color: $mabBlue;
$link-color-hover: $mabBlueLight;
$link-color-visited: $mabBlue;
$link-color-focus: $mabBlue;
$link-hover-effect: .2s;

$base-font-size: 16px;
$base-line-height: 1.5;
$total-columns: 12;
$default-gutter-width: 5% !default;
$default-column-breakpoint: 650px;
$max-container-width: 100%;
$component-content-width: 930px;
$min-page-padding: 4%;

//benefits table
$benefit-table-border: #4472C4;
$benefit-table-odd-row: #DAE3F3;