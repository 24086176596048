.enrich-header{
position: relative;
width: 100%;
.background-blue{
top: 124px;
left: 1px;
width: 100%;
height: 120px;
opacity: 1;
img {
  width: 100%;
  position: absolute;
  height: 140px;
  @media screen and (max-width: 767px)
  {
    height: 115px;
  }
 
     
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  height: 184px;
}
}
.background-card{
    width: 240px;
    height: 150px;
    opacity: 1;
    left: 33.8%;
    top: 32px;
    position: absolute;
    z-index: 9;
     @media screen and (max-width: 767px)
     {
    width: 267px;
    height: 150px;
    opacity: 1;
    left: 20px;
    top: 36px;
    z-index: 9;
    position: absolute;
    width: 60%;
    height: 101px;
    img{
    @media screen and (max-width: 767px)
     {
      width: 82%;
      height: 111px;
     }
     @media screen and(max-width: 320px)
     {
      width: 78%;
      height: 109px;
     }
    }
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      left: 20px;
    }   
    @media screen and (min-width: 1440px) {   
      left: 34.8%;
    }
    @media screen and (min-width: 1550px) {   
      left: 32%;
    }
    @media screen and (min-width: 1660px) {   
      left: 30%;
    }
    @media screen and (min-width: 1750px) {   
      left: 28.7%;
    }
    @media screen and (min-width: 2560px) {   
      left: 19.5%;
    }
  
     }


.card--details{
  position: absolute;
  bottom: 0px;
  color: #FFFFFF;
  left: 52.3%;
   @media screen and (max-width: 767px)
     {    
    position: absolute;  
    color: #fff;
    left: 55%;
    top: 38px;
     }
     @media screen and(max-width: 320px)
     {
      left: 54%;
      top: 35px;
     }
     @media screen and (min-width: 768px) and (max-width: 1200px) {
      left: 271px;     
      top: 46px;
    }

    @media screen and (min-width: 1440px) {   
      left: 52.3%;
    }
    @media screen and (min-width: 1550px) {   
      left: 48.3%;
    }
    @media screen and (min-width: 1660px) {   
      left: 45.3%;
    } 
    @media screen and (min-width: 1750px) {   
      left: 43%;
    }
    @media screen and (min-width: 2560px) {   
      left: 29.3%;
    }

}
  .card--enrichnumber , .card--cardusername , .card--cardvalidtill
{    
    font: normal normal 12px/13px Gotham,Arial;
    bottom: 43px;
    margin-left: 15px;
    color: white;
    z-index: 9;   
    position: relative;
    @media screen and (max-width: 767px)
     {      
      bottom: 46px;
      z-index: 99;     
      font-size: 8px;
      font-size: 8px;
     }

     @media screen and (min-width: 768px) and (max-width: 1200px) {
      left: 42px;
      margin-left: 0px;
    }
}
.enrich-gomuser
{
  .card--enrichnumber , .card--cardvalidtill

  {
    bottom: 55px;
    @media screen and (max-width: 767px)
    {
      bottom: 52px;
      font: normal normal 10px/11px Gotham,Arial;
    }
  }
 
}
.card--gomuser
{    
    font: normal normal 9px Gotham,Arial;
    bottom: 55px;
    margin-left: 15px;
    color: white;
    z-index: 9;   
    position: relative;
    @media screen and (max-width: 767px)
     {      
      font: normal normal 7px Gotham,Arial;
      bottom: 52px;
      z-index: 99; 
      font-size: 8px;    
     }

     @media screen and (min-width: 768px) and (max-width: 1200px) {
      left: 42px;
      margin-left: 0px;
    }
}
.card--earnedmiles{
  position: absolute;
  right: 125px;
  bottom: 43px;
  color: #FFFFFF;
  @media screen and (max-width: 767px)
{
    right: 17px;
    top: 10px;
    font: normal normal 18px/21px Gotham,Arial;
}
@media screen and (min-width: 768px) and (max-width: 1200px) { 
  top: 46px;
  right: 17px;  
}
}

#member-profile-enrichcard {
  .card-width{
    padding:10px;
    .members--card-image{
      position: relative;
      display: inline-block;
      width: 100%;
      .card--details{
        bottom:20px !important;
        position: absolute;
        left: 23px;
        width: calc(100% - 10px - 36px);
        .card-cardusername{
          font: normal normal 12px/13px Gotham,Arial;
          bottom: 43px;
          margin-left: 15px;
          color: #fff;
          z-index: 9;
          position: relative;
        }
      }

    } 
  }
}

.card--username
{
  font: normal normal 18px/20px Gotham,Arial;
  padding-bottom: 5px;
@media screen and (max-width: 767px)
{
      font: normal normal 12px/21px Gotham,Arial;
      padding-bottom: 0px;
}
}

.card--number
{
  font: normal normal 18px/20px Gotham,Arial;
  padding-bottom: 5px;
@media screen and (max-width: 767px)
{
      margin-top: -4px;
      padding-bottom: 0px;
      font: normal normal 12px/21px Gotham,Arial;
}
}


.one-world-content
{
  border-radius: 100px;
padding: 6px 14px 6px 16px;
width: fit-content;
font: normal normal 14px/16px Gotham,Arial;
@media screen and (max-width: 767px)
{
      font: normal normal 10px/16px Gotham,Arial;
      padding: 2px 5px 2px 5px;
}

}
.one-blue {
  background: #4391da 0% 0% no-repeat padding-box;
}
.one-green {
  background: #2aa15e 0% 0% no-repeat padding-box;
}
.one-red {
  background: #e81a43 0% 0% no-repeat padding-box;
}


.card--miles{
text-align: right;
font: normal normal 30px/34px Gotham ,Arial;;
letter-spacing: 0;
color: #FFFFFF;
opacity: 1;
@media screen and (max-width: 767px)
{
  font: normal normal 18px/20px Gotham ,Arial;;  
}
}
.card--cardusername{
  bottom: 75px;
  @media screen and (max-width: 767px)
{
      font: normal normal 12px/21px Gotham,Arial;
      bottom: 50px;
      font-size: 11px;
}
}
}
