.pagebody{
  &#pagebody-page, &#pagebody-page2, &#pagebody-page22, &#pagebody-page3{
    display: inline;
    float: left;
    width: 60%;
    margin: 30px 20%;
    @include respond-to(1300){
      width: 80%;
      margin: 30px 10%; 
    }
    @include respond-to(1000){
      width: 90%;
      margin: 30px 5%; 
    }
    h2{
      text-align: center;
      margin-bottom: 0;
      font-size: 3em;
      color: #006DBB;
      width: 100%;
      @include respond-to(1000){
        font-size: 2.5em;
      }
    }
    .perheader {
      text-align: center;
      margin-bottom: 15px;
      width: 100%;
    }
  }
  table {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    tr{
      td{
        div{
          label{
            float: left;
            width: 30%;
            padding-top: 10px;
            vertical-align: middle;
            font-size: 14px;
            @include respond-to(tablet-portrait){
              width: 100%;
            }
          }
        }
        input, .selectize-input{
          width: 70%;
          float: left;
          font-size: 14px !important;
          margin-bottom: 10px;
          border-radius: 50px;
          -webkit-border-radius: 50px;
          padding: 10px 20px;
          box-shadow: none;
          border: 1px solid #CCCCCC;
          @include respond-to(tablet-portrait){
            width: 100%;
            margin-bottom: 0;
          }
        }
        .checkbox{
          input[type="checkbox"]{
            margin-left: 0;
            margin-right: 10px;
            margin-top: 7px;
          }
          label{
            padding-left: 25px;
          }
        }
      }
    }
  }
  #recipient__birthDate-cell{
    .selectize-input{
      width: 22%;
      margin-right: 12px;
      @include respond-to(tablet-portrait){
        width: 32%;
      }
    }
    .selectize-control{
      &:last-child{
        .selectize-input{
          width: 22% !important;
          margin-right: 0;
          @include respond-to(tablet-portrait){
            width: 32% !important;
          }
        }
      }
    }
  }
}

.navigation{
  &#navigation-page, &#navigation-page2, &#navigation-page22, &#navigation-page3{
    width: 73% !important;
    height: auto;
    overflow: hidden;
    margin: 0% 6% !important;
    padding: 2% 0 2% !important;
    @include respond-to(tablet-portrait){
      width: 88% !important;
    }
    ul{
      li{
        list-style-type: none;
        float: right;
        a{
          background: #006DBB;
          color: #fff;
          padding: 16px 38px;
          border-radius: 50px;
        }
      }
    }
  }
}
