#travel_pass
{	background-color: white;
	padding: 20px;
    h1
    {
        font: normal 700 21px/23px Gotham, Arial;
    }
    h5
    {
        font: normal 700 14px/16px Gotham, Arial;
		color: #333333;
		margin-left: 10px;
    }
	.form-group{
		float:none;
	}
	.travel-pass--id, .travel-pass--details {
    label{
		position: absolute !important;
    	margin-top: -10px !important;
   		background-color: white !important;
    	margin-left: 20px !important;
    	padding-left: 4px !important;
		padding-right: 4px !important;
	}
	select{
		border-radius: 10px 10px 10px 10px;
	}
	.selectize-input{
		border-radius: 10px 10px 10px 10px;
		&.input-active {
			&.dropdown-active {			  
			  border-radius: 10px 10px 0 0;			  
		  }
		}
	}
	input{
		border-radius: 10px 10px 10px 10px;
		padding-left: 3px !important;
	}
	
	.distance-margin
	{
		margin-top: 15px;
	}
	.btn-secondary{
		margin-left: 10px;
		border-radius: 10px 10px 10px 10px;
	}
	.btn-primary{
		margin-left: 65px;
		border-radius: 10px 10px 10px 10px;
	}
	.travelpassnameselected{
		margin-bottom: 3px !important;		
		.travelpass_points
		{
			font: normal normal 14px/16px Gotham, Arial;
			letter-spacing: 0px;
			color: #61666f;
		}
		.travelpasslenselected {
			font: normal bold 14px/16px Gotham, Arial;
			color: #14467B;					
			letter-spacing: .5px;			
		}
	}
	.travelpassname{
		margin-bottom: 3px !important;		
		.travelpasslen
		{			
			font: normal 400 14px/16px Gotham, Arial;
			color: #14467B;
			letter-spacing: .5px;
			padding: 3px 0 !important;
		}
		.travelpass_points
		{
			font: normal normal 14px/16px Gotham, Arial;
			letter-spacing: 0px;
			color: #61666f;
		}		
	}
	.selectize-control 
	{
		.selectize-dropdown 
			{
				[data-selectable] 
				{
				padding: 8px 20px;
				}
				.active
				{
					.travelpasslen,.travelpass_points
					{
						color:white;
					}
				}
			}
		.selectize-input.select-filled
				{
					padding: 12px 20px 10px;
				}
	}
	.travel-pass_details 
	{
		margin-left: 10px;
		margin-top: 15px;
		.travelpassname_title
		{
			color: #61666F;
			font: normal normal 14px/16px Gotham, Arial;			
		}
		.travelpassexpiry_title
		{
			color: #61666F;
			font: normal normal 14px/16px Gotham, Arial;			
		}
		.travelpassnbalance_title
		{
			margin-bottom: 0px;
			color: #61666F;
			font: normal normal 14px/16px Gotham, Arial;			
			@media screen and (max-width: 767px)
			  {
				margin-bottom: 10px;				
			  }
		}
		.travelpassname_desp
		{
			font: normal 700 14px/30px Gotham, Arial;
			color: #14467B;			
			height: 30px;
			@media screen and (max-width: 767px)
			  {				
				height: 40px;
				font: normal 700 13px/55px Gotham, Arial;
			  }

		}
		.travelpassnbalance_desp
		{
			font: normal 700 36px/40px Gotham, Arial;
			color: #14467B;
		}
		.travelpassexpiry_desp
		{
			font: normal 700 14px/30px Gotham, Arial;
			color: #14467B;
			height: 30px;
			@media screen and (max-width: 767px)
			  {				
				height: 40px;
				font: normal 700 13px/55px Gotham, Arial;
			  }
		}
		.travel_detail
		{
			border-bottom: 1px solid #CCCCCC;
			margin-right: 20px;
		}
		.travel_points
		{
			border-bottom: 1px solid #CCCCCC;
		}
	}
	.travel_know
	{
		margin-left: 10px;
		margin-top: 30px;
		@media screen and (max-width: 767px)
			  {
				margin-top: 20px;
			  }
		.col-sm-1 
		{
			width: 20px;
		}
		.fa{
			color: #14467B;
		}
		p
		{
			font: normal normal 14px/16px Gotham, Arial;
			color: #14467B;
			padding: 2px;
			a
			{
				text-decoration: underline;
			}
			margin-bottom: 28px;
		}
	}

}
.travel-pass--id {
	@media screen and (min-width: 768px)
	{
		.col-sm-6
		{
			width: 50% !important;
		}
	}
}
	#history.tab-pane
	{
		// margin-bottom: 300px;
		@media screen and (min-width: 768px)
		{
			.col-sm-6
			{
				width: 50%!important;
			}
		}

		.travel_passhistory
		{
			background: #FAFAFA 0% 0% no-repeat padding-box;
			border: 1px solid #CCCCCC;
			border-radius: 10px;
			// width: 783px;
			padding: 15px;
			margin-top: 25px;
			.row
			{
				margin-bottom: 15px;
			}

		}
		.airport_desption 
		{
			margin-bottom: 0px!important;
			@media screen and (max-width: 767px)
			  {
				.col-xs-1 
				{
					width: 35px;
				}
				.col-xs-10 
				{
					width: 276px;
				}
			  }
		}
			.history_titlefrom,.history_titleto,.history_titletraveldate,.history_titlepnr,.history_titletriptype,.history_titletktno,.history_titlereturndate,.history_titlestatus,.history_titlebookingdate,.history_titleptredeemed


			{
				font: normal normal 14px/16px Gotham, Arial;
				letter-spacing: 0px;
				color: #61666F;
			}
			.history-from,.history-to,.history-pnr,.history-traveldate,.history_tktno,.history-triptype,.history_returndate,.history_status,.history-bookingdate,.history_ptredeemed

			{
				padding-left: 4px;
				font: normal 700 14px/16px Gotham, Arial;
				color: #14467B;

			}
			.history-fromdesp,.history-todesp
			{
				font: normal normal 14px/16px Gotham, Arial;
				letter-spacing: 0px;
				color: #333333;
				padding-left: 5px;
			}
			.history_status
			{
				color: #55A814;
			}
			.history_ptsreintatno
			{
				color: #55A814;
			}
			.history_ptsreintat
			{
				color: #F7A827 !important;
			}
			.travel-pass_history
			{
				.row
				{
					margin-bottom: 15px;
				}
			}
			.showtraveller-histroy,.hidetraveller-histroy
			{
				text-align: left;
				text-decoration: underline;
				font: normal normal 14px/16px Gotham, Arial;
				letter-spacing: 0px;
				color: #14467B;
			}
	}
	
	.nav-pills
	{
		li {
			width: 24.7%;
			border: 1px solid #cccccc;
			border-bottom: 0px;	
			border-radius: 10px 0px 0px 0px;	
			@include respond-to(tablet-portrait) {
				width: 50%;
			}
			a {				
				text-align: center;
				padding-top: 19px;
				padding-bottom: 18px;
				color: #61666f;
				background: #cccccc 0% 0% no-repeat padding-box;
				border-radius: 10px 0px 0px 0px;
				height: 100%;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-pack: center;
				justify-content: center;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				line-height: 1.375rem;
				font: normal normal 14px/16px Gotham, Arial;
				@include respond-to(tablet-portrait) {
					font: normal normal 12px/14px Gotham, Arial;
				}
				p {
					width: 100%;
					margin: 0 auto;
					img {
						margin-bottom: 5px;
						width: 33px;
						height: 22px;
						&.redeem-fight-img-active {
							display: none;
						}
						&.redeem-fight-img {
							display: inline-block;
						}
					}
				}
				.fa {
                    width: 100%;
                    margin: 0 auto;  
					padding-top: 2px;
					margin-bottom: 6px;
    				font-size: 20px;
					@include respond-to(tablet-portrait) {
						padding-top: 1px;
					}
				  }				   
			}		
		&.active > a {
			background: $white 0% 0% no-repeat padding-box;
			color:#14467b;
			p {
				img {
					&.redeem-fight-img-active {
						display: inline-block;
					}
					&.redeem-fight-img {
						display: none;
					}
				}
			}
		}
		+ li {
			margin-left: 0px;
			border-left: 0px;
			border-radius: 0px 10px 0px 0px;
			a {
				border-radius: 0px 10px 0px 0px;  
			  }
		  }
		}
	}	

	#redeemflight {
		margin-bottom: 300px;
		#ibe-standalone-travelpass {
			.redemption-payment-methods{
				display: none !important;
			}
			.payment-type-container {
				&:after {
					border-bottom: 0px;
				}
			}
			.trip-container {
				.trip-type--container {
					.multiCityTrip, .roundTheWorldTrip, .ibe_sideTrip, .sideTrip  {
						display: none !important;
					}
					margin-bottom: 15px;
				}
				@include respond-to(tablet-portrait) {
					padding-bottom: 20px;
				}
			}
			.couponCode {
				display: none !important;
			}
			.cabinClass {
				pointer-events: none;
				.fa-angle-down {
					display: none !important;
				}
			}
			.passenger-details {
				.fa-angle-down {
					display: none !important;
				}
			}
			.passenger-details-selected {
				display: none !important;
			}	
			.ibe-form--container {
				.search-container {
					.col-lg-3 {
						width: 45%;						
					}
					.col-lg-2 {
						width: 24.3%;
						@media (max-width: 1200px) and (min-width: 768px) {
							padding-top: 0px;
							width: 23%;
						}
					}
					.col-lg-4 {
						width: 27%;
						@media (max-width: 1200px) and (min-width: 768px) {
							width: 23%;
						}
					}
					.col-lg-1 {
						float: right;
						margin-right: 27px;
						margin-top: 16px;
						width: 27%;
						@media (max-width: 1200px) and (min-width: 768px) {
							margin-right: 61px;
						}
					}
					.book-date--selector {
						.date-form--title {
							position: relative !important;	
							padding-left: 0px !important;
						}
						.oneWayTrip {
							.one-dep-title {
								padding-left: 0px !important;
							}
						} 
						&.returnTrip {
							.departureDate {
								padding: 16px 0 0 20px !important;
								border-bottom-right-radius: 0px;
    							border-top-right-radius: 0px;								
							}
							.returnDate {
								padding: 16px 0 0 20px !important;
								border-bottom-left-radius: 0px;
    							border-top-left-radius: 0px;	
							}
						}
						&.oneWayTrip {
							.departureDate {
								padding: 16px 0 0 20px !important;
							}
						} 
						@media (max-width: 767px) {
							input {
								padding: 22px 20px 5px !important;
								&.returnDateMobile {
									border-bottom-left-radius: 0px;
    								border-top-left-radius: 0px;
								}
								&.departureDateMobile {
									border-bottom-left-radius: 0px;
    								border-top-left-radius: 0px;
								}
							}
							
						}

					}  
				}
				@media screen and (min-width: 768px) {
					.location-container {
						padding-bottom: 16px;
					}
				}
				.selectize-input {
					input {
						border-radius: 0px;
					}
				}
			}
			#book-flight--container {
				.flights-hotel-wrap {
					.col-lg-5 {
						width: 45%;
						@media (max-width: 1200px) and (min-width: 768px) {
							width: 46%;
						}
					}
				}
			}
			.mobile-ibe { 
				.error-validation {
					padding-right: 0px;
					width:	100%;
				}
			}
			.mobile-ibe {
				padding-left: 0px;
				padding-right: 0px;
				.trip-container {
					padding-left: 0px;
					padding-right: 0px;
				}
				.location-container {
					padding-right: 0px;
				}
				.search-container {
					padding-right: 0px;
				}
			} 
			.ibe-form--container {
				.trip-container {
					padding-left: 0px;
				}
				.location-container {
					padding-left: 0px;
				}
				.search-container {
					padding-left: 0px;
					.col-lg-3 {
						padding-left: 0px;
					}
				}
			} 		
		}
		.ibe-standalone {
			border-bottom: 0px;
			padding-top: 3px;
			.ibe-form--container {
				border: 0px;
			}
		}
		.noquota, .expiredtp, .passedtraveldate,.passedstatus,.passedstatus_refund {
			color: $red;
			font: normal normal 16px/18px Gotham, Arial;	
			@include respond-to(tablet-portrait) {
				margin-bottom: 100px;
			}		
		}
		
	}
	.disablecontent {
		pointer-events: none;
		opacity: 0.5;
	}
	.purchase-travelpass {
		font: normal 400 14px/16px Gotham, Arial;
    	color: #61666f;
		.description {
			opacity: 0.5;
		}
	}
	.restrict-travelpass {
		font: normal 400 14px/16px Gotham, Arial;
    	color: #61666f;
	}
	.travel-pass-info-oneway, .travel-pass-info-return {
		font: normal 400 14px/16px Gotham, Arial;
		color: #14467b;		
	}
	.cabinClass 
	{
		.cabin-class,.cabin-class-mob
		{
			.selectize-input::after {
				content: ' ' !important;
			}
		}
	}
	.transpass-infoicon
	{
		float:right;
		margin-top: -10px;
	}

	.travel-pass--redeem
	{
		padding-bottom: 150px;
	}
	.transpass-infoicon
	{
		@media (max-width: 767px)
		{
			width: 12% !important;
		}

		.fa-info-circle
		{
			font-size: 20px;
			color: #005aaa!important;
			padding: 5px;
		}
	}
}