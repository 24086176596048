.topfaq {
  $content-padding: 20px;

  background-color: $seashell;
  padding-top: 40px;
  padding-bottom: 40px;

  @include respond-to(767) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width: 769px) and (max-width: #{1179px + 20px + 20px}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      color: $countdownblue;
      line-height: 1;
      margin: 0;
    }
  }

  &__accordion {
    max-width: #{1180px + 20px + 20px};
    margin: 0 auto;
  }

  &__item {
    background-color: $white;
    margin-bottom: 10px;
    border-top: solid 1px $lightgray;
    border-bottom: solid 1px $lightgray;
  }

  &__questiontitle {
    margin: 0;

    .link {
      display: inline-block;
      padding: $content-padding;
      width: 100%;
      line-height: rem(26);
      font-size: rem(18);
      position: relative;
      font-family: $font-family-medium;
      span {
        color: $black;
        display: inline-block;
        width: calc(100% - 25px);
      }
    }
  }

  &__toggle {
    position: absolute;
    display: block;
    right: $content-padding;
    top: 50%;
    font-size: rem(14);
    line-height: 1;
    transform: translateY(-50%);
  }

  &__content {
    padding: 0 50px 40px 20px;

    @include respond-to(767) {
      padding: 9px 50px 30px 20px;
    }

    p {
      margin-bottom: 20px;
    }
  }

  &__answerfeedback {
    display: inline-block;
    width: 100%;

    .label,
    .response {
      display: inline-block;
      width: auto;
    }

    .label {
      @include respond-to(767) {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .response {
      margin-left: $content-padding;

      @include respond-to(767) {
        margin-left: 0;
      }

      .link:not(:last-child) {
        margin-right: 15px;

        @include respond-to(767) {
          margin-right: 20px;
        }
      }

      &__icon {
        margin-left: 10px;
      }
    }
  }
}
