.image-hotspot{
	h2{
		position: absolute;
		@include z-index(hotSpotTxt);
		text-align: center;
		margin: 40px 38%;
	}
	img{
		position: relative;
		@include z-index(hotSportImg);
	}
}

@include respond-to(1430){
	.image-hotspot{
		h2{
			margin: 40px 35%;
		}
	}
}

@include respond-to(1100){
	.image-hotspot{
		h2{
			margin: 40px 32%;
		}
	}
}

@include respond-to(900){
	.image-hotspot{
		h2{
			margin: 40px 28%;
			@include font-size(30);
		}
	}
}

@include respond-to(800){
	.image-hotspot{
		h2{
			margin: 40px 25%;
			@include font-size(26);
		}
	}
}


@include respond-to(tablet-portrait){
	.image-hotspot{
		h2{
			position: relative;
			margin: 0 auto;
		}
	}
}