.members--mymiles--my-statement {
  .miles-table-options {
    display: inline-block;
    width: 100%;
    position: relative;
  }

  .miles-period-select,
  .miles-export-action {
    float: left;
    width: auto;
  }

  .form-group.miles-period-select {
    width: 30%;
    margin-right: 15px;

    @media screen and (max-width: 767px) {
      width: calc(100% - 122px - 15px);
    }

  }

  .print-only {
    display: none;
  }

  .miles-export-action {
    // padding: #{(47px - 33px) / 2} 0; // selectize height - btn height / 2

    .btn {
      padding-left: 35px;
      padding-right: 35px;

      &:hover {
        color: $white;
      }
    }
  }
}

#members-print-status_wrapper,
#members-account-balance-table_wrapper,
#members-expiring-miles-table_wrapper,
#members-elite-status-progress-table_wrapper {
  display: none;
}

#members-print-status,
#members-account-balance-table,
#members-expiring-miles-table,
#members-elite-status-progress-table {
  display: none;
}

// @media print {
  $print-font-size: rem(17);

  body.members--print-statement {
    background-color: $white;
    padding-top: 30px;
    padding-right: 60px;
    padding-left: 60px;

    > .slideout-panel > .container:not(.members__nav):not(.members__content) {
      display: none;
    }

    #mab-header,
    #mab-bookingnav,
    .member-nav__toggle-btn,
    .sidebar-menu,
    .dataTables_paginate,
    .dataTables_length {
      display: none;
    }

    .members__nav {
      margin: 0 !important;
      max-width: none;
    }

    .members__content {
      padding-left: 0;
      max-width: none;
    }

    .member-nav {
      border: solid 1px $black;
      padding: 10px;
      border-radius: 0;
      margin-bottom: 15px;
      background-color: lighten($focusblue, 60%);

      @media print {
        background-color: $seashell;
      }

      .username {
        display: block;
        float: none;
      }

      .navbar-header {
        margin-bottom: 0;
      }

      .member-nav--miles-info {
        display: none;
      }

      .username,
      .member-type label,
      .user-member-number,
      .member-type-label,
      .member-number-value {
       font-size: #{$print-font-size + rem(1)} !important;
       font-weight: 400 !important;
       font-family: $font-family !important;
       line-height: 1.4321 !important;
       text-transform: initial;
     }

     .member-type {
      display: block;
      float: none;
      margin: 0;

      label {
        display: block;
        background-color: transparent;
        color: $gray;
        padding: 0;
      }
    }
  }


  #members-print-status,
  #members-account-balance-table,
  #members-expiring-miles-table,
  #members-elite-status-progress-table {
    display: table;
  }

  #members-print-status_wrapper,
  #members-account-balance-table_wrapper,
  #members-expiring-miles-table_wrapper,
  #members-elite-status-progress-table_wrapper {
    display: inline-block;
    width: 100%;
  }

  .print-only {
    display: initial;
  }

  .print-status {
    label,
    .value {
      display: inline;
    }

    label {
      font-family: $font-family-medium;
      font-weight: 600;
      line-height: 1;
      margin-right: 5px;

      &:after {
        content: ':';
      }
    }

    .value {
      line-height: 1;
    }
  }

  .members__overview,
  .miles-table-navbar {
    display: none;
  }

  .print-heading {
    display: block;
    font-size: #{$print-font-size + rem(2)};
    font-family: $font-family-medium;
    font-weight: 600;
    color: $gray;
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    line-height: 1;
    border: solid 1px $black;
    background-color: $focusblue;
    color: $white;

    @media print {
      background-color: $seashell;
      color: $gray;
    }
  }

  .members--mymiles--my-statement .miles-table-options {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .members__content .miles-table {
    border: solid 1px $lightgray;
    margin-top: 0;
    margin-bottom: 30px;
    width: 100% !important;

    th {
      padding: 10px 7px;
      font-family: $font-family-medium;
      font-weight: 600;
      text-transform: capitalize;
      background-color: lighten($gray, 20%);
      color: $white;

      @media print {
        background-color: $seashell;
        color: $gray;
      }

      &:after {
        display: none;
      }
    }

    td {
      padding: 3px 7px;
    }

    th,
    td {
      border: solid 1px $black;
      font-size: $print-font-size;
    }

  }
}
// }
