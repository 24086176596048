.ibe-navigation{
  background-color: $focusblue;
}
#mab-bookingnav {
  @include clear-bs-row;
  @include z-index(ibenav);
  background-color: $ibeblue;
  width: 100%;
  @include border-radius(30px 0 0 0);
  @include respond-to(tablet-portrait) {
    @include border-radius(0);
  }
  &.fixed {
    @include z-index(ibelinks);
    position: fixed;
    top: 0;
    background: $ibeblue;
    width: 100%;
  }

  &.desktop {
    @media screen and (min-width: 768px) {
      $mainnavheight: 73px;
      &.headroom--not-top {
        position: fixed;
        top: $mainnavheight;
      }
      &.headroom--unpinned {
        transform: translateY(-#{$mainnavheight});
      }
    }
  }

  >.row>[class*='col-'] {
    padding: 0;
  }

  .navbar {
    $mainnavheight: 65px;
    margin-bottom: 0;
    &.navbar-default {
      @include z-index(ibelinks);
      background-color: transparent;
      border-color: transparent;
      padding: 0;
      border: 0;
      position: relative;
      @media screen and (min-width: 1200px) and (max-width: 1439px) {
        width: 100%;
        max-width: $ibe-width-less-than-1200;
        margin: 0 auto;
      }
      @media screen and (min-width: 1440px) {
        width: $ibe-width-more-than-1200;
        margin-left: $ibe-left-margin-more-than-1200;
      }
      @include respond-to(ipad-portrait) {
        min-height: 49px;
      }
      a {
        color: $white;
      }
      .active {
        a {
          background-color: transparent;
        }
      }
      #bookingNav {
        width: 100%;
        .nav {
          &.navbar-nav {
            // float: left;
            width: 80%;
            // max-height: $mainnavheight;
            overflow: hidden;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: nowrap;
          }
          &.login-nav {
            float: left;
            width: 20%;
            text-align: right;
          }
        }
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  $mainnavheight: 75px;
  #mab-bookingnav {
    &.desktop {
      display: none;
    }
    &.mobile {
      // transition:all 2.0s ease-in;
      display: block; //margin-top: 4px;
      &.fixed {
        position: fixed;
        @include z-index(ibelinks);
        background: $linkblue;
        width: 100%;
      }
      &.headroom--not-top {
        position: fixed;
        top: $mainnavheight;
      }
      &.headroom--unpinned {
        transform: translateY(-#{$mainnavheight});
      }
    }
    .navbar {
      &.navbar-default {
        padding: 0 0 0 0;
        #bookingNav {
          width: 100%;
          .ibe-form--container {
            // top: $mainnavheight - 23;
          }
          .nav {
            &.navbar-nav {
              width: 100%;
              margin: 0;
              li {
                width: 25%;
                // float: left;

                &.selected a {
                  // padding: 12px 0;
                }
                a {
                  text-align: center;
                  @include font-size(12);
                  line-height: 1;
                  padding: 14px 10px 12px;
                  .fa {
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(620) {
  #mab-bookingnav {
    .navbar {
      &.navbar-default {
        #bookingNav {
          .nav {
            &.navbar-nav {
              li {
                a {
                  //@include font-size(0);
                  i {
                    @include font-size(16);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(414) {
  #mab-bookingnav {
    .navbar {
      &.navbar-default {
        #bookingNav {
          .nav {
            &.navbar-nav {
              li {
                a {
                  height: 100%;
                  padding: 12px 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: wrap;
                }

                &.selected a {
                  // padding: 12px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(375) {
  .mobile-ibe {
    #bookingNav {
      .navbar-nav {
        li {
          a {
            padding: 5px !important;
          }
        }
      }
    }
  }

  #mab-bookingnav {
    .navbar {
      &.navbar-default {
        #bookingNav {
          .nav {
            &.navbar-nav {
              li {
                a {
                  @include font-size(10);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(360) {
  .mobile-ibe {
    #bookingNav {
      .navbar-nav {
        li {
          a {
            padding: 5px 3px !important;
          }
        }
      }
    }
  }
}

@include respond-to(320) {
  $mainnavheight: 63px;
  .mobile-ibe {
    #bookingNav {
      .navbar-nav {
        li {
          a {
            padding: 5px 15px !important;
            line-height: rem(14) !important;
          }
        }
      }
    }
  }
}
