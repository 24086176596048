/**
 * Members Portal
 * - header
 */

// base styles for member portal
$members-sidebar-width: 300px;
$content-padding: 20px;
$navbar-height: 102px;

$nav-toggle-width: 46px; // nav toggle margin: 15px;
$nav-width-more-than-1200: calc(100% - #{120px + 120px});
$nav-left-margin-more-than-1200: 120px;
$nav-width-less-than-1200: 1200px;
.membersmob-nav{   
    margin-bottom: 0px !important;
.navbar-toggle{
    float: right!important;
    color: #333333;
    position: relative;
    padding-bottom: 0px;
}

.icon-bar {
    
       background-color: #333333 !important;
      
   }

   
.slideout-panel {
    position: relative;
    z-index: 1;
     min-height: none!important
}


.member--gold, .member--platinum, .member--silver, .member--blue, .member--BLUE, .member--SILV, .member--GOLD, .member--PLAT,.member--PLATP
{
.members__nav{
#enrich-content {
    .slideout-panel {   
     min-height: 0vh !important;
}
    
}
    }
} 

#enrich-content {
    .slideout-panel {   
     min-height: 0vh !important;
}
}
}
.mabe-nav--overlay {
//   top:100%;
  position: absolute;
  transition: all .3s ease-out;
//   transition-delay: 3s;
//   background: #428bca;
//   height: 100%;
}