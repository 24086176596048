.contact-form{
  padding: 0px 10%;
  margin: 10px 0 0;
  &.seashell{
    background: $seashell;
  }
  .contact-form--container{
    padding: 0;
  }
  h1{
    text-align: center;
    line-height: 1em;
    margin-bottom: 5px;
    &.marginBottom{
      margin-bottom: 30px;
    }
  }
  h2{
    text-align: center;
  }
  p{
    text-align: center;
    margin-bottom: 30px;
  }
  .booking-form--title{
    position: absolute;
    left: 26px;
    top: 14px;
    color: #666;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    @include z-index(bookformtitle);
    pointer-events: none;
    display: block;
    max-width: initial;
    margin-bottom: initial;
    font-weight: initial;
    -webkit-transition: all 90ms linear;
    -o-transition: all 90ms linear;
    transition: all 90ms linear;
    &.input-filled{
      top: 6px;
    }
    &.has-error{
      color: $errorred;
    }
  }
  .col-lg-6, .col-lg-12{
    margin: 0;
    padding: 0 5px;
    .contactCountryCode{
      padding: 0;
      select,
      .selectize-input{
        @include border-radius(25px 0 0 25px);
        padding: 22px 22px 4px;
        &.dropdown-active{
          border-radius: 25px 0 0 0;
        }
      }
    }
    .contactPhone{
      padding: 0;
      input{
        @include border-radius(0 50px 50px 0);
        border-left: 0;
        &.input-focused{
          padding: 21px 20px 4px 25px;
        }
      }
    }
    .col-lg-6{
      margin-bottom: 0;
    }
  }
  .dropzone {
    margin-bottom: 20px;
    border: solid 1px $lightgray;
    @include border-radius(20px);

    &.dz-clickable .dz-message,
    &.dz-clickable .dz-message * {
      color: $midgray;
     }

     .dz-preview.dz-error .dz-error-mark svg > g > g {
        fill: $red;
     }

     .dz-preview.dz-success .dz-success-mark svg > g > path {
        fill: $green;
     }

     .dz-preview .dz-error-message {
        top: 150px;
     }
  }
  .form-validate{
    margin-bottom: 15px;
    .input-focused{
            padding: 21px 20px 4px 21px;
    }
    textarea{
      @include border-radius(20px);
    }
    &.btn-holder{
      text-align: center;
    }
  }
  .contact-submit--btn{
    width: 15%;
    margin-top: 15px;
    @include respond-to(ipad-portrait){
      width: 20%;
    }
  }
  .contact-error--validation{
    border-bottom: 1px solid $errorred;
    margin: 0 15px 20px;
    padding: 10px 0;
    display: none;
    width: 97%;
    @include respond-to(tablet-portrait){
      margin: 0 15px 20px 7px;
    }
    .fa{
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1{
      padding-right: 0;
      width: 40px;
    }
    .col-lg-11{
      padding-left: 0;
    }
    .error-msgs{
      @include font-size(14);
      color: $gray;
    }
    #result{
      @include font-size(14);
      color: $gray;
    }
    .contact-validator{
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }
  .form-success, .form-fail{
    display: none;
  }
  h3{
    text-align: center;
  }
}

@include respond-to(tablet-portrait){
  .contact-form{
    h1{
      @include font-size(36);
    }
    .contact-submit--btn{
      width: 30%;
    }
    .contact-error--validation{
      .col-lg-1{
        padding-right: 0;
        width: 12%;
        float: left;
      }
    }
  }
  .country-code--phoneno{
    width: 100%;
    height: auto;
    margin-bottom: 10px !important;
    display: inline-block;
    //overflow: hidden;
    .contactCountryCode, .contactPhone{
      width: 50%;
      float: left;
      margin-bottom: 0;
    }
  }
  // .validation--error-list {
  //   padding-left: 38px;
  // }
}
