.travel-advisory-search--holder{ 
    @media only screen and (min-width: 768px) {  
      height: auto;
      padding-bottom: 20px
    }
    background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-bottom: 40px;
    margin-left: 9%;
    margin-right: 9%;
    @media only screen and (max-width: 1200px) {
      margin-left: 5%;
      margin-right: 5%;
    }
    h5{      
      font-weight: 550;
      color: #00437d;
      padding-top: 38px;
      padding-left: 5px;
      height: 21px;
      text-align: left;      
        @include respond-to(tablet-portrait) {
            padding-top: 0px;
            margin-bottom: 25px;
        }
    }
    p{
      padding-left: 5px;
      padding-bottom: 10px;
      padding-top: 38px;
      text-align: left;      
      letter-spacing: 0px;
      color: #1E4073;
      opacity: 1;
      @include respond-to(tablet-portrait) {
      margin-top: 35px;
      }
    }
    select, .selectize-input{
      padding-top: 24px;
      padding-bottom: 7px;     
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #1E4073;
      border-radius: 10px;
      opacity: 1;
      padding-left: 58px;
      @include respond-to(tablet-portrait) {
        padding-left: 50px;
      }
      font-weight: bold;
      color: #1E4073;
      &.input-active {
        &.dropdown-active {
          border: 1px solid #1E4073;
          border-radius: 10px 10px 0 0;
        }
      }          
    }
    button{
      padding: 8px 12px;
      background: #1E4073 0% 0% no-repeat padding-box !important;
      border-radius: 10px;
      opacity: 1;
      @include respond-to(tablet-portrait) {
        margin-top: 19px;
      }
    }
    .selectize-control.single .selectize-input:after{
      color: #1E4073;
    }
    .selectize-control {
      border-radius: 0px;
      .selectize-dropdown {
        border-radius: 0 0 10px 10px;
        color: #1E4073;
        font-weight: bold;
        [data-selectable] {
          padding: 8px 55px;   
          @include respond-to(tablet-portrait) {
            padding: 8px 50px;  
          }       
        }
      }
    }
    .flag img{
      display:none;
      width:20px;
      height:20px;
      margin-right:20px;
    }
    .booking-form--title {
      position: absolute;
      display: block;
      z-index: 8;    
      position: absolute;    
      color: $darkgray;  
      pointer-events: none;
      display: block;
      max-width: initial;
      margin-bottom: initial;
      font-weight: initial;
      top: 9px;
      left: 75px;
      @include respond-to(tablet-portrait) {
        left: 65px;
      }
      @include font-size(12);
    }
    .map-pin {
      position: absolute;
      z-index: 8;
      width: 23px;
      height: 23px;
      top: 13px;
      left: 30px;
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      .col-sm-6 {
        width: 50% !important;
      }
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-device-width: 1366px) and (min-device-width: 1024px) {
      .col-sm-6 {
        width: 50% !important;
      }
    }
    .col-lg-1, .col-md-1, .col-sm-1 {
        width: 5.33%;
    }

    .row .areaOptions{
      font-size: 22px;
      padding: 15px 0 0 0; 
       input{
         width: 20px;
         height: 20px
       }
    }
    .row .smartvel-search .smt-cwidget {
    
    .smt-combobox-input{
      background: #fff 0 0 no-repeat padding-box;
      border: 1px solid #1e4073;
      padding-left:20px;
      border-radius: 10px 0 0 10px; 
      font-size: 13px;
      padding-top:15px;
      padding-bottom: 15px;
    }

    .smt-cwidget-description-customcontainer{
      margin-left: 0px;
      @media only screen and (max-width: 767px){
      & .smt-cwidget-selector{
        width: 100%;
      }
    }
    }
    .smt-cwidget-description-customcontainer .smt-combobox-container{
      padding-right: 15px;
      color: #1e4073;
      @media only screen and (max-width: 767px){
        &{
          padding-right:0px;
        }
      }
      .smt-combobox-result{
        font-size: .8125rem;
        line-height: 1.21875rem;
        padding: 8px 55px;
        font-weight: 700;
        color: #1e4073;
        &:hover{
          background: #005aaa;
          color: white; 
        }
      }
      .smt-combobox-results{
        min-width: 80%;
        width: calc(100% - 15px);
        max-height: 255px;
      }
      .smt-combobox-results::-webkit-scrollbar {
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #006dbb;
        // -webkit-box-shadow: inset 0 0 1px rgb(0 0 0 / 50%);
      }
    }
    #smt-cwidget-flyto {
      height: 100%;
    }
    .smt-cwidget-content {
      background-color: #F8F8F8;
    }
    .smt-cwidget-content .smt-button{
      font-size: .875rem;
      line-height: 1rem;
      height: 100%;
      padding: 17px 20px!important;
      border-radius: 10px!important;
      margin-left: 15px;

      @media only screen and (max-width: 768px){
        width:100%
      }
    }
    .smt-combobox-list-toggle svg {
        padding:0!important;
      }
       .smt-combobox button{
      border-radius: 0 10px 10px 0;
      border-color: #1e4073!important;
      background-color: white!important;
      padding-top: 5px;
      padding-right: 20px;
        
      
      &:active , &:focus{
        border-color: #1e4073!important;
        border: 1px solid!important;
        border-left: 0!important;
      }
    }

    @media (max-width: 767px){
      .smt-combobox button{
        margin-top:0px;
      }
    }
  }
  a *, a, button.smt-cwidget-alldescription {
    color: #fff
  }
  .smt-cwidget-navtab-tab {
    padding: 0px 1px !important;
  }

  }
  .smt-cwidget-flipgroup input:checked + label:after {
    top: 4px !important;
  }
  .traveladvisory {
    .traveladvisoryframe {
      @media only screen and (max-width: 1200px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  #iframeTravelAdvisory {
    height: 2500px;
    width: 100%;
  }
  