.enrich_promotions__select {
  margin-top: 40px;
  .enrich_promotions__dropdown{
    margin: 0 auto 30px auto;
    display: block;
    width: 10%;
    .selectize-control{
      display: inline-block;
      width: 100%;
      .selectize-input{
        padding: 14px 25px 13px;
      }
    }
  }
  .selected-content {
    max-width: 1180px + 20px;
    margin: 0 auto;

    @include respond-to(1180 + 20 + 20 + 20) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

@include respond-to(1200){
  .enrich_promotions__select {
    .enrich_promotions__dropdown{
      width: 15%;
    }
  }
}

@include respond-to(tablet-portrait){
  .enrich_promotions__select {
    .enrich_promotions__dropdown{
      width: 30%;
    }
  }
}

@include respond-to(475){
  .enrich_promotions__select {
    .enrich_promotions__dropdown{
      width: 40%;
    }
  }
}
