.experience-entertainment{
	.title-description{
		width: 100%;
		padding: 0 20% 30px;
		text-align: center;
	}
	.entertainment-container{
		border-top: 1px solid $lightgray;
		height: auto;
		overflow: hidden;
		display: block;
		padding-top: 2%;
		h2{
			text-align: center;
			margin-bottom: 0;
		}
		p{
			@include font-size(16);
			color: $gray;
			&.align-center{
				text-align: center;
				line-height: 1em;
				margin-bottom: 5px;
			}
		}
		.download{
			clear: both;
			width: 100%;
			text-align: center;
			.fa{
				color: $blue;
				padding-right: 5px;
			}
			span{
				color: $blue;
				@include font-size(14);
			}
		}
		.entertainment-category{
			border-bottom: 1px solid $lightgray;
			margin: 20px 8% 30px;
			padding-bottom: 30px;
			.slider-elements{
				width: 100%;
				height: auto;
				overflow: hidden;
				.slider{
					padding: 15px 40px 0 54px;

          &:not(.slick-initialized) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }

          > div:not(.slick-list) {
            width: calc(25% - 20px);
            margin-right: 20px;
            margin-bottom: 20px;

            p {
              color: $blue;
            }
          }

					.slick-slide{
						margin-right: 20px;
						a{
							p{
								color: $blue;
								@include font-size(16);
								text-align: center;
								margin-top: 15px;
							}
							&:active, &:visited, &:focus{
								outline: 0;
								box-shadow: none;
							}
						}
						&:focus{
							outline: 0;
						}
					}
				}
				button{
					@include border-radius(100px);
					border: 1px solid $blue;
					padding: 20px;
					&:before{
						color: $blue;
						opacity: 1;
						line-height: 0;
						position: relative;
						left: -9px;
						top: -1px;
					}
					&.slick-prev{
						left: 0px;

					}
					&.slick-next{
						right: 0px;
						&:before{
							left: -6px;
						}
					}
					&:hover{
						background: $focusblue;
						&:before{
							color: $white;
						}
					}
				}
			}
			.btn-secondary{
				margin: 30px 43% 0;
			    display: inline-block;
			    width: 14%;
			}
			.slick-prev, .slick-next{
				&:before{
					@include font-size(42);
				}
			}
			.slick-prev, .slick-next{
				&:hover, &:focus{
					border: 1px solid $blue !important;
				}
			}
			&:last-child{
				border-bottom: 0;
			}
			.slick-dots{
				margin: 0;
				border-top: 0;
				bottom: -30px;
				padding: 0;
				width: 92%;
				li{
					button{
						padding: 0;
						width: 16px;
						height: 16px;
						&:before{
							opacity: 0;
						}
					}
					&.slick-active{
						button{
							background: $blue;
						}
					}
				}
			}
		}
	}
	.entertainment-detailed--container{
		text-align: center;
		margin: 30px 0;
		h1{
			color: $countdownblue;
			line-height: 1em;
			margin-bottom: 10px;
		}
		.fa{
			color: $blue;
			padding-right: 5px;
		}
		span{
			color: $blue;
			@include font-size(14);
		}
		.btn-secondary{
			&.back-btn{
				position: absolute;
				left: 15px;
				width: 15%;
				margin-top: -50px;
			}
		}
	}
	#list-container{
		clear: both;
		width: 100%;
		height: auto;
		overflow: hidden;
		ul{
			margin: 0;
			padding: 0;
			display: flex;
		    justify-content: flex-start;
		    -webkit-justify-content: flex-start;
		    align-items: flex-start;
		    -webkit-align-items: flex-start;
		    flex-wrap: wrap;
		    -webkit-flex-wrap: wrap;
			li{
				list-style-type: none;
				width: 24%;
				margin-right: 1.25%;
				margin-bottom: 40px;
				&:nth-child(4n){
					margin-right: 0;
				}
				p{
					text-align: center;
					margin-top: 20px;
				}
				a{
					cursor: pointer;
					p{
						color: $blue;
					}
				}
			}
		}
	}
	#pagination{
		margin: 0 0 40px !important;
	    display: flex;
	    justify-content: center;
	    width: auto;
		ul{
			padding: 10px 12px 10px;
			border: 1px solid $lightgray;
	    	@include border-radius(50px);
			li{
				list-style-type: none;
				float: left;
				padding: 0 10px;
				@include font-size(16);
				line-height: rem(28);
				.current{
					color: $blue;
				}
				a{
					color: $gray;
				}
				&.disabled{
					.current{
						color: $lightgray;
					}
				}
				&:first-child{
					background: $linkblue;
					@include border-radius(100px);
					position: relative;
					cursor: pointer;
					line-height: 1.6em;
					.prev{
						display: none;
					}
					&:after{
			            content: "\f104";
			            display: inline-block;
			            font: normal normal normal 24px/1 FontAwesome;
			            font-size: inherit;
			            text-rendering: auto;
			            -webkit-font-smoothing: antialiased;
			            -moz-osx-font-smoothing: grayscale;
			            @include font-size(24);
			            line-height: 1em;
			            color: $white;
			            position: relative;
			            right: 0;
			            left: -1px;
			        }
				}
				&:last-child{
					background: $linkblue;
					@include border-radius(100px);
					position: relative;
					cursor: pointer;
					line-height: 1.6em;
					.next{
						display: none;
					}
					&:after{
			            content: "\f105";
			            display: inline-block;
			            font: normal normal normal 24px/1 FontAwesome;
			            font-size: inherit;
			            text-rendering: auto;
			            -webkit-font-smoothing: antialiased;
			            -moz-osx-font-smoothing: grayscale;
			            @include font-size(24);
			            line-height: 1em;
			            color: $white;
			            position: relative;
			            right: 0;
			            left: 2px;
			        }
				}
			}
		}
	}
}

@include respond-to(1000){
	.experience-entertainment{
		.entertainment-container{
			.entertainment-category{
				.btn-secondary{
					width: 18%;
					margin: 30px 40% 0;
				}
			}
		}
		.entertainment-detailed--container{
			.btn-secondary{
				&.back-btn{
					width: 20%;
				}
			}
		}
	}
}

@include respond-to(tablet-portrait){
	.experience-entertainment{
		.entertainment-container{
			.entertainment-category{
				margin: 20px 20px 30px;
				.btn-secondary{
					width: 40%;
					margin: 15px 30% 0;
				}
				.slider-elements{
					.slider{
						margin-bottom: 48px !important;
					}
				}
				.slick-dots{
					position: relative;
					bottom: 0;
				}
			}
		}
		.entertainment-detailed--container{
			.btn-secondary{
				&.back-btn{
					width: 25%;
				}
			}
		}
		#list-container{
			ul{
				li{
					width: 48%;
					margin-right: 2%;
					&:nth-child(2n){
						margin-right: 0;
					}
				}
			}
		}
	}
	.entertainment-detailed--container{
		h1{
			@include font-size(36);
		}
	}
}

@include respond-to(500){
	.experience-entertainment{
		.entertainment-container{
			.entertainment-category{
				margin: 20px 20px 30px;
				.btn-secondary{
					width: 40%;
					margin: 0 30% 0;
				}
				.slider-elements{
					.slider{
						margin-bottom: 28px !important;
					}
				}
			}
		}
		.entertainment-detailed--container{
			.btn-secondary{
				&.back-btn{
					width: 25%;
					margin-top: -70px;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
		}
		#list-container{
			ul{
				li{
					width: 100%;
				}
			}
		}
	}
}


@include respond-to(320){
	.experience-entertainment{
		.entertainment-container{
			.entertainment-category{
				.slider-elements{
					.slick-prev, .slick-next{
						top: 40%;
					}
					.slider{
						margin-bottom: 20px !important;
						padding: 15px 25px 0 54px;
					}
				}
				.btn-secondary{
					width: 60%;
					margin: 15px 28% 0 22%;
				}
			}
		}
		.entertainment-detailed--container{
			.btn-secondary{
				&.back-btn{
					width: 22%;
					margin-top: -70px;
					padding: 10px;
				}
			}
		}
	}
}
