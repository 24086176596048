.newsletter-popup, .newsletter-popup--success, .newsletter-popup--notsuccess {
  position: relative;
  background: $white;
  padding: 20px;
  width: 1000px;
  margin: 20px 22%;
  background: $white url('/content/dam/mas/loader/sitetakeover.png');
  background-size: cover;
  @include respond-to(1800) {
    margin: 20px 20%;
  }
  @include respond-to(1600) {
    margin: 20px 18%;
  }
  @include respond-to(1440) {
    margin: 20px 15%;
  }
  @include respond-to(1366) {
    margin: 20px 12%;
  }
  @include respond-to(1250) {
    margin: 20px 9%;
  }
  @include respond-to(1200) {
    margin: 20px 6%;
  }
  @include respond-to(1100) {
    width: auto !important;
  }
  h3 {
    color: $blue;
    border-bottom: 1px solid $lightgray;
    line-height: rem(20);
    padding-bottom: 15px;
  }
  p{
    margin-bottom: 20px;
  }
  .col-lg-4{
    &.newsletterPreferredCity{
      padding: 0;
      .selectize-control{
        .selectize-input{
          &.select-filled{
            padding: 22px 20px 5px 20px;
          }
        }
      }
    }
  }
  .mfp-close {
    color: $blue;
    opacity: 1;
    @include font-size(32);
    top: 6px;
    right: 8px;
    cursor: pointer;
  }
  .booking-form--title {
    @include font-size(14);
    @include z-index(bookformtitle);
    position: absolute;
    margin-left: 24px;
    margin-top: 14px;
    color: $darkgray;
    transition: all 90ms linear;
    &.input-filled {
      margin-top: 6px;
      margin-left: 22px;
      @include font-size(12);
    }
    &.has-error {
      color: $errorred;
    }
  }
  .newsletteremail-input {
    height: 48px;
    color: $blue;
    @include respond-to(767) {
      margin-bottom: 15px;
    }
    &:focus {
      border: 1px solid $blue;
      outline: none;
    }
    &.input-focused {
      padding: 21px 20px 4px 21px;
    }
  }
  .newsletteremail-error--validation {
    border-bottom: 1px solid $errorred;
    margin: 0 15px 15px;
    padding: 10px 0;
    display: none;
    width: 100%;
    color: $errorred;
    .fa-times-circle {
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1 {
      padding-right: 0;
      width: 5%;
      @include respond-to(tablet-portrait) {
        float: left;
        padding-left: 0;
      }
    }
    .col-lg-11 {
      padding-left: 0;
      @include respond-to(tablet-portrait) {
        padding-left: 25px;
      }
    }
    .error-msgs {
      @include font-size(14);
      color: $gray;
    }
    #result {
      @include font-size(14);
      color: $gray;
    }
    .deals-validator {
      margin: 2px 0 0 6px;
      @include font-size(14);
    }
  }
  .newsletter-tandc{
    margin: 0 20px;
  }
}

@include respond-to(1000) {
  .newsletter-popup{
    .col-lg-4{
      &.newsletterPreferredCity{
        padding: 0 15px;
        margin-top: 15px;
      }
    }
  }
}

@include respond-to(767) {
  .newsletter-popup, .newsletter-popup--success, .newsletter-popup--notsuccess{
    margin: 20px 5%;
    width: auto !important;
    h1{
      margin-top: 15px;
      @include font-size(30);
      line-height: 35px;
    }
    p{
      margin-top: 15px;
    }
  }
}

#newsletter-popup
{
  .mfp-close
  {
    width: 15px !important ;
  }

  h3{
    line-height: 1.9rem !important;
  }
}