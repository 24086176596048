.search-icon{
	padding: 23px 0 0 0;
  display: block;
  overflow: hidden;
  .fa-search{
    @include font-size(18);
    color: $lightgray;
    float: right;
  }

  input {
    cursor: pointer;
  }
}
#search-container{
	width: 100%;

  @media screen and (min-width: 1201px) and (max-width: 1440px) {
    width: calc(100% - 40px);
  }
	position: absolute;
	@include z-index(searchcontainer);
	margin-top: 14px;
	.top-search--icon{
		position:absolute;
		right: 20px;
		top: 13px;
		display: none;
		@include z-index(searchinputiconfocus);
		&.fa{
			@include font-size(16);
			color: $midgray;
			line-height: rem(16);
			cursor: pointer;
		}
	}
	input[type="text"] {
	  display: inline-block;
	  outline: none;
	  width: 0px;
	  position: absolute;
	  top: 0;
	  right: 0;
	  border: 0;
	  background: none;
	  transition: width .5s cubic-bezier(0.000, 0.795, 0.000, 1.000);
	  cursor: pointer;
	  @include z-index(searchinput);
	}

	input[type="text"]:focus {
	  width: 100%;
	  @include z-index(searchinputfocus);
	  cursor: text;
	  border: 1px solid $linkblue;
	  background: $white;
	}
	.col-lg-6{
		//width: 59%;
		&.no-padding--margin{
			width: 70%;
			float: right;
		}
	}
}

@include respond-to(more-than-1200) {
	.search-icon{
		.fa-search{
			@include font-size(18);
			color: $white;
			float: right;
		}
	}
}

@include respond-to(1450){
	#search-container{
		.col-lg-5{
			width: 33%;
		}
		.col-lg-6{
			//width: 59%;
		}
	}
}

@include respond-to(tablet-portrait){
	.search-icon{
		padding: 38% 20% 0;
	}
	#search-container{
		width: 92%;
		margin-top: 0;
		margin-left: 6%;
		margin-right: 2%;
	}
}
