#new-deals{
  max-width: 100vw;
  background-color: #F1F1F1;
  padding: 0;

#top-deals-section{
    margin: 40px auto;
    margin-bottom: 0;
    padding: 0px 15px 24px 15px;

  .top-deals{
    .wrappercolumn{
      .deal-card{
        display: none;
        padding: 0 13px;
      }
    }

    max-width: 1358px;
    margin: auto;
    min-width: 1274px;
  }
  .section{
    margin-bottom: 20px;
    width: 320px;
    max-width: 100%;
    max-height: 410px;

    
  }
  @media (min-width: 767px) and (max-width: 992px){
    .section{
      a{
        display: block;
        height: 0;
        overflow: hidden;
        width: 100%;
        padding-bottom: 80.5%;
        
      }
      .offer-circle{
        top:35%; 
      }

    }
  }
  @media (min-width: 767px){
    
    .see-more-btn{
      width: 320px !important;
    }
    .top-deals{
    min-width: unset;
    }
    
  }
  .topdeals-title{
    text-align: center;
    font: normal 30px/34px Gotham-Medium;
    p{
      font: normal 18px/20px Gotham-Book;
      margin-top: 10px;
    }
    span{
      color: #EE408A;
    }
  }
  .deals-content{
    display: inline-flex;
  }
  .deals-box{
    width: min-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #CCCCCC54;
  }
  .deals-img{
    max-width: 100%;
    min-width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .box-content{
    background-color: white;
    padding: 16px 13px 11px 17px;
    color: #61666F;
    height: 210px;
    svg + span{
      margin-left:-8px;
    }
    span{
      font: normal 12px/13px Gotham-Book;
      color: rgba(97, 102, 111, 1);
  
    }
    h5{
      font: normal 14px/16px Gotham-Medium;
      margin-bottom: 0px;
      
    }
    p{
      text-align: right;
      font: normal 14px/16px Gotham-Book;
      margin: 0px;
      white-space: nowrap;
      span{
        font: normal 24px/27px Gotham-Medium;
        color: #EE408A;
      }

    }
    
    .deal-package{
      margin-top:55px;
    }
  }
  .offer-circle{

    right: 25px;
    background: #FAA21B 0% 0% no-repeat;
    color: white;
    border-radius: 35px;
    width: 70px;
    height: 70px;
    text-align: center;
    font: normal 21px/23px Gotham-Medium;
    position: absolute;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 200px;
    transform: translateY(-50%);
  }
  .fa-star.fill{
    color: #FAA21B
  }
  .fa-star{
    color: #fff;
  }
  .fa-star-o{
    color: #CCCCCC;
  }
  .top-deals-text{
    position: absolute;
    background: #00B1A9 0% 0% no-repeat padding-box;
    font: normal 12px/13px Gotham-Medium;
    color: #FAFAFA;
    padding: 10px;
    height: 30px;
  }
  .see-more-btn{
      font: normal 14px/16px Gotham-Medium;
      color: #ee408a;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #EE408A;
      margin: 25px auto;
      margin-top: 0;
      width: unset;
      height: 45px;
      text-align: center;
      padding: 14px;
      cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 568px)  {
    .offer-circle {
      right: 28px;
    }
    .top-deals{
      min-width: unset;
      }
      width: 78%;
      padding:0;
    .section{
      width: 100%;
    }
    .topdeals-title span:after {
      content: '\A';
      white-space: pre;
    }
  }
  @media (min-width: 568px) and (max-width: 767px){
    .top-deals{
      min-width: unset;
    }
    .section{
      margin-left:auto;
      margin-right: auto;
      position: relative;
    }
    .see-more-btn{
      max-width: 320px;
    }
  } 
  @media (min-width: 768px) and (max-width: 1024px){
    .offer-circle {
      bottom: 321px;
      right: 16px;
      width: 50px;
      height: 50px;
      font: normal 18px/23px Gotham-Medium;
    }
    .top-deals{
      min-width: unset;
      }
      width: 98%;
     

      .box-content{
        .deal-package{
          margin-top: unset;
          white-space: normal;
        }
        .deal-price{
          white-space: unset;
          span{
            white-space: nowrap;
          }
        }

        .deal-description{
          position: absolute;
          bottom: 25px;
          right: 28px;
        }
      }
    }
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5){
      .offer-circle {
        bottom: 257px;
        right: 24px;
      }
    }
  
  }
}