.deals-container {
  height: auto;
  display: none;
  &.shown {
    display: block;
  }
  // overflow: hidden;
  .deals-selector {
    display: block;
    text-align: center;
    margin: 1% auto 0.6%;
    .deals-from {
      @include font-size(32);
      color: $gray;
      display: inline-block;
      position: relative;
      top: -12px;
      width: 40%;
      text-align: right;
      margin-top: 22px;
    }
    .deals-city {
      display: inline-block;
      margin-left: 15px;
      text-align: left;
      margin: 10px;
      width: calc(60% - 30px);
      .deals-city-select-wrapper {
        width: auto;
        display: inline-block;
        min-width: 230px;
        select,
        .selectize-control {
          .selectize-input {
            .item {
              min-width: 230px;
            }
          }
        }
      }
      select,
      .selectize-control .selectize-input {
        padding: 0;
        border: 0;
        border-radius: 0;
        &.not-full {
          top: 9px;
        }
        .item,
        input {
          @include font-size(32);
          color: $blue; //border-bottom: 1px dashed $blue;
          &:after {
            content: "\f107";
            display: inline-block;
            font: normal normal normal 24px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            @include font-size(40);
            margin-left: 20px;
            margin-top: 5px;
            line-height: 1em;
            color: $linkblue;
            float: right;
          }
        }
        &:after {
          display: none;
        }
        &.input-active {
          .item {
            color: $lightgray;
            &:after {
              content: "\f106";
              display: inline-block;
              font: normal normal normal 24px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              @include font-size(40);
              margin-left: 20px;
              line-height: 1em;
              color: $linkblue;
            }
          }
        }
      }
      // select,
      a {
        @include font-size(36);
      }
      select {
        @include font-size(16);
        margin: 0 auto;
        width: auto;
        height: initial;
        height: unset;
      }
    }
  }
  .deals-content--container,
  .deals-content--container--mobile {
    .deals-wrapper {
      width: 100%;
      -webkit-padding-start: 0;
      padding: 0;
      list-style: none;
      display: block;
      float: left;

      .loading--bg-effect {
        // animation: loadingcolors 2s ease infinite;
      }

      .loading {
        .loading--bg-effect {
          // background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, $midgray 100%, rgba(0, 0, 0, 0) 0%);
          // background-position: 0% 50%;
          // background-size: 800% 100%;
          // color: transparent !important;
          color: transparent !important;
          background: transparent;
        }

        .promo-item,
        .deals-price--content {
          opacity: 0;
        }

        img {
          opacity: 0;
        }

        &.big,
        &.small {
          background-color: $seashell;
        }
      }

      // @keyframes loadingcolors {
      //   0% {
      //     background-position: 0% 50%
      //   }
      //   50% {
      //     background-position: 100% 50%
      //   }
      //   100% {
      //     background-position: 0% 50%
      //   }
      // }
    }
  }
  .deals-content--container {
    .deals-wrapper {
      > .big,
      > .small {
        margin: 0;
        padding: 0;
      }
    }
    .promo-item {
      position: absolute;
      border-radius: 0 0 5px 5px;
      background: $orange;
      padding: 5px 10px 10px;
      color: $white;
      left: 5%;
      .price {
        font-family: $font-family-medium;
        line-height: 1em;
        @include font-size(16);
      }
      .offer-details {
        text-align: center;
        line-height: rem(10) !important;
        @include font-size(12);
      }
    }
    .small {
      width: 25%;
      @media screen and (min-width: 1201px) {
        min-height: 25vw;
      }
    }
    .big {
      @media screen and (min-width: 1201px) {
        min-height: 50vw;
      }
      .deals-offer--date {
        left: 25px;
      }
    }
    img {
      width: 100%;
      z-index: 1;
    }
    .deals-wrapper {
      min-height: 200px;
      width: 100%;
    }
    .deals-btn--holder {
      display: block;
      height: auto;
      overflow: hidden;
      clear: both;
      margin: 30px 0 0;
    }
    .btn-secondary {
      margin: 30px auto 0;
      display: block;
      width: 10%;
      text-align: center;
    }
    .deals-price {
      position: absolute;
      width: 60%; //border: 1px solid $white;
      top: 22%;
      left: 20%;
      min-height: 50%;
      z-index: 2;
      &.big {
        .deals-price--content {
          .city-name {
            @include font-size(48);
            line-height: 1em;
            color: $white;
          }
          .country-name {
            display: none;
          }
          .trip-cabin--container {
            @include font-size(20);
            color: $white;
            width: 100%;
            clear: both;
            height: auto;
            overflow: hidden;
            div {
              float: left;
              margin-right: 5px;
            }
          }
          .currency-type {
            @include font-size(18);
            line-height: 1em;
            margin-top: 25px;
            color: $white;
          }
          .amount {
            @include font-size(36);
            line-height: 1em;
            color: $white;
          }
        }
      }
      &.small {
        .deals-price--content {
          .city-name {
            @include font-size(24);
            line-height: 1em;
            color: $white;
          }
          .trip-cabin--container {
            @include font-size(12);
            color: $white;
            width: 100%;
            clear: both;
            height: auto;
            overflow: hidden;
            div {
              float: left;
              margin-right: 5px;
            }
          }
          .country-name {
            display: none;
          }
          .currency-type {
            @include font-size(14);
            line-height: 1em;
            margin-top: 20px;
            color: $white;
          }
          .amount {
            @include font-size(24);
            line-height: 1em;
            color: $white;
          }
        }
      }


    .deals-price--content {
        padding: 25% 10%;
        background-color: rgba(0, 0, 0, 0.3);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
        color: $white;
        cursor: pointer;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;

        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 25%;
          left: 25%;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.9);
          width: 50%;
          height: 50%;
          -webkit-transform: scale(0);
          transform: scale(0);
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
        
          .city-name {
            color: $gray;
          }
          .trip-cabin--container {
            color: $dealsred;
          }
          .currency-type {
            color: $gray;
          }
          .amount {
            color: $gray;
          }
          &:before {
            -webkit-transform: scale(2);
            transform: scale(2);
          }
        }
      }
    }
    .deals-offer--date {
      position: absolute;
      bottom: 6px;
      left: 10px;
      color: $white;
      z-index: 2;
      @include font-size(12);
      div {
        float: left;
        padding-right: 4px;
      }
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  .deals-container {
    .deals-selector {
      .deals-city {
        .deals-city-select-wrapper {
          .selectize-control {
            .selectize-input {
              .item {
                min-width: 353px;
              }
              input {
                &:after {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
    .deals-content--container {
      .big {
        width: 100%;
        min-height: 100vw;
      }
      .deals-wrapper > .small {
        width: 50% !important;
        min-height: 50vw;
      }
      .deals-price {
        width: 60%;
        left: 20%;
        top: 25%;
        .deals-price--content {
          padding: 30% 10%;
        }
      }
      .btn-secondary {
        width: 40%;
      }
    }
  }
}

@include respond-to(more-than-1200) {
  .deals-container {
    .deals-selector {
      .deals-city {
        .deals-city-select-wrapper {
          .selectize-control {
            .selectize-input {
              .item {
                min-width: 353px;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(ipad-landscape) {
  .deals-container {
    .deals-selector {
      margin: 1% auto 2% !important;
      .deals-from {
        top: 0;
      }
    }
  }
}

@include respond-to(ipad-portrait) {
  .deals-container {
    .deals-selector {
      margin: 1% auto 2% !important;
      .deals-from {
        //top: 0;
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  .deals-container {
    // min-height: 300px;
    .row {
      margin: 0 20px;
    }
    .deals-content--container {
      //margin-bottom: 5% !important;
      .deals-price {
        width: 60%;
        left: 20%;
        top: 25%;
      }
      .btn-secondary {
        width: 40%;
        margin: 8% auto 0;
      }
      .mobile-container {
        display: block;
      }
    }
    .deals-selector {
      margin: 3% auto 1% !important;
      .deals-from {
        @include font-size(24);
        top: -5px;
      }
      .deals-city {
        select {
          @include font-size(24);
        }
        .selectize-control {
          .selectize-input {
            border: 0;
            padding: 0;
            input {
              // display: none !important;
            }
            .item {
              @include font-size(24);
              color: $blue;
              &:after {
                font: normal normal normal 24px/1 FontAwesome !important;
              }
            }
            &:after {
              top: 10px;
            }
          }
        }
        a {
          @include font-size(24);
        }
      }
    }
  }
  .deals-content--container--mobile {
    padding-bottom: 10px !important;
    .deals-banner-item {
      margin: 0;
      padding: 0;
      border: 1px solid $lightgray;
      height: auto;
      overflow: hidden;
      &.visible-xs.hidden {
        display: none !important;
      }
      &.mobile-container {
        border: 0;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
      }
      .deals-banner-item__image,
      .deals-banner-item__desc {
        margin: 0;
        padding: 0;
      }
      .deals-banner-item__image {
        width: 50%;
        position: relative;
        background-color: $lightgray;
      }
    }
    .deals-mobile-item.mobile-container {
      .promo-item {
        display: none;
      }
    }
    .promo-item {
      position: absolute;
      border-radius: 0 0 5px 5px;
      background: $orange;
      padding: 6px 10px 5px;
      color: $white;
      left: 20px;
      top: 0;
      @include z-index(passengarDetails);
      .price {
        font-family: $font-family-medium;
        font-size: rem(16) !important;
      }
      .offer-details {
        text-align: center;
        font-size: rem(12) !important;
        line-height: rem(12);
      }
    }
    .deals-price--content {
      width: 50%;
      padding-left: 10%;
      .city-name {
        @include font-size(24);
        line-height: 1em;
        color: $gray;
      }
      .trip-cabin--container {
        @include font-size(24);
        font-family: $font-family-medium;
        color: $gray;
        display: none;
      }
      .currency-type {
        @include font-size(18);
        line-height: 1em;
        margin-top: 50px;
        color: $gray;
      }
      .amount {
        @include font-size(24);
        line-height: 1em;
        font-family: $font-family-medium;
        color: $gray;
      }
    }
    .mobile-container {
      margin: 0;
      padding: 0;
      .deals-promo-content {
        width: 100%;
        clear: both;
        display: block;
        border-bottom: 1px solid $dealgray;
        padding: 2% 0;
        height: auto;
        overflow: hidden;
        .city-name {
          float: left;
          color: $blue;
          @include font-size(16);
          margin-right: 10px;
        }
        .trip-cabin--container {
          float: left;
          color: $gray;
          @include font-size(14);
          padding-top: 2px;
        }
        .country-name {
          display: none;
        }
        .currency-type {
          float: right;
          color: $darkgray;
          @include font-size(12);
          padding: 5px 10px 0 0;
        }
        .amount {
          float: right;
          color: $blue;
          @include font-size(16);
          font-family: $font-family-medium;
          min-width: 45px;
          text-align: right;
        }
      }
    }
    .btn-secondary {
      width: 40%;
      margin: 20px 30%;
      display: inline-block;
      text-align: center;
    }
  }
}

@include respond-to(500) {
  .deals-container {
    .deals-content--container--mobile {
      .deals-price--content {
        padding: 0 20px;
        width: 50%;
        .city-name {
          @include font-size(24);
          line-height: 1em;
        }
        .country-name {
          display: none;
        }
        .currency-type {
          margin-top: 18px;
          @include font-size(14);
        }
        .amount {
          @include font-size(24);
          font-family: $font-family-medium;
          line-height: 1em;
        }
      }
    }
  }
}

@include respond-to(414) {
  .deals-container {
    .deals-content--container {
      .deals-price {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        .deals-price--content {
          padding: 16% 10%;
        }
      }
      .btn-secondary {
        width: 50%;
      }
    }
    .deals-selector {
      margin: 5% 0 0 0 !important;
      padding: 0 !important;
      .deals-from {
        top: 0;
        margin-top: 0;
        @include font-size(16);
      }
      .deals-city {
        width: auto;
        select {
          @include font-size(16);
        }
        .selectize-control {
          .selectize-input {
            .item {
              width: 100%;
              display: inline-block;
              @include font-size(16);
              &:after {
                font: normal normal normal 18px/1 FontAwesome !important;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(375) {
  .deals-container {
    .deals-content--container--mobile {
      .deals-price--content {
        padding: 12% 20px 0;
        .city-name {
          @include font-size(20);
          line-height: 1.1em;
        }
      }
    }
  }
}

@include respond-to(320) {
  .deals-container {
    .deals-content--container--mobile {
      padding-bottom: 15px !important;
      .deals-price--content {
        padding: 10% 20px 0;
        .city-name {
          @include font-size(16);
          line-height: 1em;
        }
        .currency-type {
          margin-top: 20px;
          @include font-size(12);
        }
        .amount {
          @include font-size(24);
          line-height: 1em;
        }
      }
      .btn-secondary {
        width: 60%;
        margin: 20px 20%;
      }
    }
  }
}
.fly-malaysia-header
{
  .deals-container{
  .deals-price--content {
    padding: 25% 10%;
    background-color:none;
    color:none;
    cursor: pointer;
    -webkit-transform:none;
    transform: none !important;
    -webkit-transition-property: none;
    transition-property: none;
    -webkit-transition-duration: none;
    transition-duration: none;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 25%;
      left: 25%;
      right: 0;
      bottom: 0;
      background: none !important;
      width: 50%;
      height: 50%;
      -webkit-transform: none;
      transform: none !important;
      -webkit-transition-property: none;
      transition-property: none;
      -webkit-transition-duration: none;
      transition-duration:none;
      -webkit-transition-timing-function:none;
      transition-timing-function:none;
    }
    &:hover,
    &:focus,
    &:active {
      background-image: url(../images/desktop-deal-hover.jpg);
      background-size: contain;
      background-position: right bottom;
      background-color: #fff !important;
      background-repeat: no-repeat;
      .city-name {
        color: $gray;
      }
      .trip-cabin--container {
        color: $dealsred;
      }
      .currency-type {
        color: $gray;
      }
      .amount {
        color: $gray;
      }
      &:before {
        -webkit-transform: scale(2);
        transform: scale(2);
      }
    }
  }
}
}