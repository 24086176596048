.enrich_featured_partners{
	&__carousel {
	    $card-gutter: 20px;
	    $banner-height: 158px;

	    display: block;
	    float: left;
	    //margin-bottom: $card-gutter;
	    position: relative;
	    background-color: $seashell;

	    @supports(display: flex) {
	      display: -webkit-box;
	      display: -ms-flexbox;
	      display: flex;
	      -ms-flex-wrap: wrap;
	          flex-wrap: wrap;
	    }
		&.slick-slider{
			width: 100%;
			margin-right: 0;
		}
	    @include respond-to(more-than-1200) {
	      $card-per-row: 4;
	      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
	      margin-right: $card-gutter;
	      margin-bottom: $card-gutter;

	      &:nth-child(4n) {
	        margin-right: 0;
	      }
	    }

	    @include respond-to(mid-screen-768-1200) {
	      $card-per-row: 3;
	      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
	      margin-right: $card-gutter;
	      margin-bottom: $card-gutter;
	      &:nth-child(3n) {
	        margin-right: 0;
	      }
	    }

	    @media screen and (min-width: 600px) and (max-width: 767px) {
	      $card-per-row: 2;
	      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
	      margin-right: $card-gutter;
	      margin-bottom: $card-gutter;
	      &:nth-child(2n) {
	        margin-right: 0;
	      }
	    }

	    @include respond-to(599) {
	      width: 100%;
	      margin-bottom: $card-gutter;
	    }

	    &__banner {
	      background-size: cover;
	      background-repeat: no-repeat;
	      background-position: center;
	      height: $banner-height;
	      position: relative;
	      z-index: 1;
	      width: 100%;
	    }

	    &__logo {
	      position: absolute;
	      width: 150px;
	      height: 85px;
	      z-index: 3;
	      left: 20px;
	      top: 107px;
	      background-color: $white;

	      img {
	        width: 100%;
	        padding: 0 8px;
	        display: block;
	        position: absolute;
	        top: 50%;
	        left: 50%;
	        -webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	      }
	    }

	    &__content {
	      position: relative;
	      z-index: 2;
	      padding-top: 44px;
	      padding-right: 20px;
	      padding-bottom: 52px;
	      padding-left: 20px;
	      min-height: 180px;
	      // max height set assuming content > 122 characters

	      @supports (display: flex) {
	        height: calc(100% - #{$banner-height});
	      }

	      .promotion_info,
	      .link {
	        font-size: rem(14);
	        line-height: rem(22);
	      }

	      .link {
	        display: inline-block;
	        position: absolute;
	        bottom: 20px;
	        margin-top: 10px;

	        i.fa {
	          margin-left: 10px;
	        }
	      }
	    }
	}
}
.enrich_featured_partners{
	border-bottom: 1px solid $lightgray;
	margin: 20px 0 30px;
	padding-bottom: 30px;
	.slider-elements{
		width: 100%;
		height: auto;
		overflow: hidden;
		.slider{
      &:not(.slick-initialized) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }

      > div:not(.slick-list) {
        width: 320px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
			.slick-list{
				margin: 15px 40px 0 56px;
				@include respond-to(tablet-portrait){
					margin: 15px 60px;
				}
			}
			.slick-track{
				margin: auto !important;
			}
			.slick-slide{
				margin-right: 20px;
				@include respond-to(tablet-portrait){
					margin-right: 0;
				}
				width: 100%;
				background-color: $seashell;
				a{
					p{
						color: $blue;
						@include font-size(16);
						text-align: center;
						margin-top: 15px;
					}
				}
				&:focus{
					outline: 0;
				}
			}
		}
		button{
			@include border-radius(100px);
			border: 1px solid $blue;
			padding: 20px;
			&:before{
				color: $blue;
				opacity: 1;
				line-height: 0;
				position: relative;
				left: -9px;
				top: -1px;
			}
			&.slick-prev{
				left: 0px;

			}
			&.slick-next{
				right: 0px;
				&:before{
					left: -6px;
				}
			}
			&:hover{
				background: $focusblue;
				&:before{
					color: $white;
				}
			}
		}
	}
	.btn-secondary{
		margin: 30px 43% 0;
	    display: inline-block;
	    width: 14%;
	}
	.slick-prev, .slick-next{
		&:before{
			@include font-size(42);
		}
	}
	.slick-prev, .slick-next{
		&:hover, &:focus{
			border: 1px solid $blue !important;
		}
	}
	&:last-child{
		border-bottom: 0;
	}
	.slick-dots{
		margin: 0;
		border-top: 0;
		bottom: -40px;
		padding: 0;
		width: 100%;
		li{
			width: 10px;
			button{
				padding: 0;
				width: 10px;
				height: 10px;
				&:before{
					opacity: 0;
				}
			}
			&.slick-active{
				button{
					background: $blue;
				}
			}
		}
	}
}

@include respond-to(tablet-portrait){
	.enrich_featured_partners{
		padding-bottom: 0;
		.btn-secondary{
			width: 40%;
			margin: 10px 30% 0;
		}
	}
}
