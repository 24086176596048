.enrich_featured_partners {
  margin-top: 40px;

  &__title {
    text-align: center;

    @include respond-to(1180 + 20) {
      padding-right: 20px;
      padding-left: 20px;
    }

    h2 {
      line-height: 1;
      color: $countdownblue;
    }

    .subtitle {
      font-size: rem(16);
      line-height: rem(24);
      display: block;
      max-width: 750px;
      margin: 0 auto;
    }

  }

  &__content {
    max-width: 1180px + 20px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;

    @include respond-to(1180 + 20) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__cards {
    list-style: none;
    display: inline-block;
    padding: 0;
    width: 100%;

    @supports(display: flex) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

  }

  &__card {
    $card-gutter: 20px;
    $banner-height: 158px;

    display: block;
    float: left;
    //margin-bottom: $card-gutter;
    position: relative;
    background-color: $seashell;

    @supports(display: flex) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    @include respond-to(more-than-1200) {
      $card-per-row: 4;
      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
      margin-right: $card-gutter;
      margin-bottom: $card-gutter;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    @include respond-to(mid-screen-768-1200) {
      $card-per-row: 3;
      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
      margin-right: $card-gutter;
      margin-bottom: $card-gutter;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: 600px) and (max-width: 767px) {
      $card-per-row: 2;
      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
      margin-right: $card-gutter;
      margin-bottom: $card-gutter;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include respond-to(599) {
      width: 100%;
      margin-bottom: $card-gutter;
    }

    &__banner {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: $banner-height;
      position: relative;
      z-index: 1;
      width: 100%;
    }

    &__logo {
      position: absolute;
      width: 150px;
      height: 85px;
      z-index: 3;
      left: 20px;
      top: 107px;
      background-color: $white;

      img {
        width: 100%;
        padding: 0 8px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
    }

    &__content {
      position: relative;
      z-index: 2;
      padding-top: 44px;
      padding-right: 20px;
      padding-bottom: 52px;
      padding-left: 20px;
      min-height: 180px;
      // max height set assuming content > 122 characters

      @supports (display: flex) {
        height: calc(100% - #{$banner-height});
      }

      .promotion_info,
      .link {
        font-size: rem(14);
        line-height: rem(22);
      }

      .link {
        display: inline-block;
        position: absolute;
        bottom: 20px;
        margin-top: 10px;

        i.fa {
          margin-left: 10px;
        }
      }
    }
  }
}

@include respond-to(tablet-portrait){
  .enrich_featured_partners__card__content{
    min-height: 100px;
  }
  .enrich_featured_partners {
    &__card{
      display: none;
    }
  }
  .related-partners{
    .enrich_featured_partners{
      .slick-dots{
        width: 80% !important;
      }
    }
  }
  .show-morepartners--content{
    .total-deals{
      text-align: center;
      clear: both;
      width: 100%;
      display: block;
      margin: 2% 0 0;
      label{
        font-family: $font-family;
        color: $gray;
        @include font-size(14);
        padding-left: 0;
      }
      .no-of--items, .total-items{
        font-family: $font-family-medium;
        @include font-size(14);
        color: $dealsprice;
        display: inline-block;
      }
    }
  }
  .partner-show--btn{
    padding: 2% 2% 1%;
    a{
      width: 100%;
      text-align: center;
    }
  }
}

@include respond-to(450){
  .related-partners{
    .enrich_featured_partners{
      .slick-dots{
        width: 72% !important;
      }
    }
  }
}
