.video-container {
  $vidminheight: 380px;
  $vidmaxheight: 90vh;

  @media screen and (min-width: 768px) {
    max-height: $vidmaxheight;
    min-height: $vidminheight + 1px;
  }

  @media screen and (max-width: 767px) {
    min-height: $vidminheight;
  }

  .video-holder {
    @include z-index(quicklinksIcon);
    position: relative;
    background-color: $black;

    @media screen and (min-width: 768px) {
      // max-height: $vidmaxheight;
      min-height: $vidminheight + 1px;
      height: 400px;
      overflow: hidden;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      min-height: $vidminheight;
    }

    video {
      width: 100%;
      @media screen and (min-width: 660px) {
        // max-height: $vidmaxheight;
        min-height: $vidminheight + 1px;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        left: 50%;
      }

      @media screen and (max-width: 659px) {
        min-height: $vidminheight;
        width: auto;
        height: 100%;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
  .playpause {
    display: none;
    @include respond-to(tablet-portrait){
      display: block;
      background-image:url(../images/play.png);
      background-repeat:no-repeat;
      width:20%;
      height:20%;
      position:absolute;
      left:0%;
      right:0%;
      top:0%;
      bottom:0%;
      margin:auto;
      background-size:contain;
      background-position: center;
      z-index: 20;
    }
  }

 // .content-holder {
 //    @include z-index(bannerbg);
 //    position: absolute;
 //    bottom: 40px;
 //    width: 60%;
 //    margin: 0 10%;
 //    color: $white;

 //    @include respond-to(1270) {
 //      width: 65%;
 //    }

 //    @include respond-to(mid-screen-768-1200) {
 //      width: 80%;
 //    }

 //    @include respond-to(1100) {
 //      width: 65%;
 //    }

 //    @include respond-to(1000) {
 //      width: 65%;
 //    }

 //    @include respond-to(900) {
 //      width: 65%;
 //    }

 //    @include respond-to(tablet-portrait) {
 //      width: auto;
 //      margin: 20px;
 //      top: initial;
 //      bottom: 20px;
 //    }

 //    @include respond-to(600) {
 //      width: auto;
 //      top: initial;
 //      bottom: 20px;
 //    }

 //    @include respond-to(500) {
 //      top: initial;
 //      bottom: 20px;
 //    }

 //    @include respond-to(414) {
 //      width: 80%;
 //      top: initial;
 //      bottom: 20px;
 //    }

 //    @include respond-to(375) {
 //      width: 80%;
 //      top: initial;
 //      bottom: 20px;
 //    }

 //    @include respond-to(320) {
 //      top: initial;
 //      bottom: 20px;
 //    }

 //    &.flip-clock {
 //      h1 {
 //        @include respond-to(375) {
 //          margin-bottom: 5px;
 //        }

 //        @include respond-to(320) {
 //          margin-bottom: 5px;
 //        }
 //      }
 //    }

 //    h1 {
 //      color: $white;
 //      line-height: 1em;
 //      text-align: left;
 //      margin-bottom: 0;

 //      @media screen and (min-width: 1201px) and (max-width: 1440px) {
 //        @include font-size(36);
 //        line-height: 1.2em;
 //        margin-bottom: 0;
 //      }

 //      @include respond-to(mid-screen-768-1200) {
 //        @include font-size(32);
 //        line-height: 1.2em;
 //        margin-bottom: 0;
 //      }

 //      @include respond-to(1100) {
 //        @include font-size(30);
 //        line-height: 1.2em;
 //        margin-bottom: 10px;
 //      }

 //      @include respond-to(1000) {
 //        @include font-size(26);
 //        line-height: 1.2em;
 //        margin-bottom: 5px;
 //      }

 //      @include respond-to(900) {
 //        @include font-size(20);
 //        line-height: 1.2em;
 //        margin-bottom: 5px;
 //      }

 //      @include respond-to(tablet-portrait) {
 //        @include font-size(24);
 //        line-height: 1em;
 //        margin-bottom: 10px;
 //      }

 //      @include respond-to(600) {
 //        @include font-size(24);
 //      }

 //      @include respond-to(414) {
 //        line-height: 1em;
 //      }

 //      @include respond-to(320) {
 //        @include font-size(20);
 //        line-height: 1em;
 //        margin-bottom: 5px;
 //      }

 //      &.medium {
 //        font-family: $font-family-medium;
 //      }
 //    }

 //    p {
 //      @include font-size(16);
 //      color: $white;

 //      @include respond-to(320) {
 //        color: $white;
 //        @include font-size(14);
 //      }
 //    }

 //    a {
 //      &.btn {
 //        @include border-radius(50px);
 //        margin-top: 15px;
 //        background: $linkblue;
 //        color: $white;
 //        border: 0;
 //        padding: 15px 40px;
 //        text-transform: initial;
 //        width: 20%;
 //        min-width: 146px;

 //        @include respond-to(900) {
 //          margin-top: 5px;
 //        }

 //        @include respond-to(tablet-portrait) {
 //          display: none;
 //        }

 //        @include respond-to(600) {
 //          width: 50%;
 //        }

 //        @include respond-to(320) {
 //          width: 50%;
 //        }

 //        &:hover {
 //          background: $blue;
 //        }
 //      }
 //    }

 //    .class-type {
 //      @include font-size(24);
 //      font-family: $font-family-medium;

 //      @include respond-to(1100) {
 //        line-height: 1em;
 //      }

 //      @include respond-to(1000) {
 //        @include font-size(20);
 //        line-height: 1em;
 //      }

 //      @include respond-to(900) {
 //        @include font-size(16);
 //        line-height: 1em;
 //      }

 //      @include respond-to(tablet-portrait) {
 //        @include font-size(16);
 //      }

 //      @include respond-to(320) {
 //        @include font-size(14);
 //        font-family: $font-family-medium;
 //      }
 //    }

 //    .price {
 //      @include font-size(48);
 //      font-family: $font-family-medium;
 //      line-height: 1em;

 //      @media screen and (min-width: 1201px) and (max-width: 1440px) {
 //        @include font-size(36);
 //        line-height: 1em;
 //      }

 //      @include respond-to(1200) {
 //        @include font-size(36);
 //        line-height: 1em;
 //      }

 //      @include respond-to(1000) {
 //        @include font-size(30);
 //        line-height: 1em;
 //      }

 //      @include respond-to(900) {
 //        @include font-size(22);
 //        line-height: 1em;
 //      }

 //      @include respond-to(tablet-portrait) {
 //        @include font-size(40);
 //        line-height: 1em;
 //        margin-bottom: 10px;
 //      }

 //      @include respond-to(375) {
 //        @include font-size(30);
 //        line-height: 1em;
 //        margin-bottom: 5px;
 //      }

 //      @include respond-to(320) {
 //        @include font-size(30);
 //        font-family: $font-family-medium;
 //        line-height: 1em;
 //        margin-bottom: 5px;
 //      }

 //      @include respond-to(320) {
 //          @include font-size(24);
 //          line-height: 1em;
 //          margin-bottom: 5px;
 //        }
 //    }

 //    .book-by {
 //      @include font-size(16);

 //      @include respond-to(tablet-portrait) {
 //        @include font-size(12);
 //      }

 //      @include respond-to(320) {
 //        @include font-size(12);
 //      }
 //    }

 //    .mobile-version {
 //      @include respond-to(tablet-portrait) {
 //        img {
 //          width: 100%;
 //          height: auto;
 //        }
 //      }
 //    }
 //  }

  // .content-holder css
  @import 'C006-007-008-content-holder';
}
