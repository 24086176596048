.online-services{
    #online-services--elite-points
    {
        @media screen and (max-width:767px)
        {
            .textboxchanges
            {
                padding-left: 0px !important;
            }
        }
    }
    .textboxchanges
    {
        padding-right: 0px !important
    }
    .table-items-bg-dark
    {
        font-size: 14px;
    }
    #online-services--buy-points
    {
        .table-items-bg-dark
        {
            font-size: 13px !important;
        }
        @media screen and (max-width:767px)
        {
            .textboxchanges
            {
                padding-left: 0px !important;
            }
        }
    }
    // max-width: 918px !important;
    background-color: #fff;
    padding: 35px;
    margin: 0px -45px 20% -45px;
    @media screen and (max-width:767px){
        margin: 0 -35px 20%;
     } 
     @media screen and (min-width:768px) and (max-width:1024px){
        margin: 0 -30px 20%;
    }
    
        .total{
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        margin-bottom: 5px;
        }
        #payment-proceed{
         background-color: #808080;
         pointer-events: none;
         opacity: 0.5;    
        }
        .button-proceed{
            text-align: center;
        }
        .font-size-mob{
            @media screen and (max-width:767px){
               font-size: 12px;
            }   
        }
        .txt-center-mob{
            @media screen and (max-width:767px){
                text-align: center;
             }
        }
        #online-services-gift{
            border: 1px solid #ccc;
            border-radius: 10px;
            height: 30px;
            vertical-align: baseline;
            padding-top: 0px;
            padding-bottom: 0px;
            text-align:center;
            color:#000;
            width: 108px;
        }
        .online-services-elite-mobile, .online-services-elite-desktop{
            border-radius: 10px !important;
            height: 57px !important;
            color: #00afaf;
            border-color: #00afaf;
            // width: 206px;
            @media screen and (min-width:768px) and (max-width:1024px){
                // width: 94px;
                height: 48px;
            }
             @media screen and (max-width:767px){
                width: 100%;
                height: 24px;
                border-radius: 5px !important;
                margin-top: 16px;
            } 
        }
        .online-services-extend-desktop, .online-services-extend-mobile{
            
            color: #00afaf;
            width: 260px;
            border-radius: 10px !important;
            height: 57px !important;
            width: 102%;
            @media screen and (max-width:767px){
                border-radius: 5px !important;
                // margin-top: 16px;
                height: 40px!important;
                width: 101%;
            } 
            
        }
        .online-services-reactivate-desktop,.online-services-reactivate-mobile{
            color: #00afaf;
            border-radius: 10px !important;
            height: 57px !important; 
            width: 102%;
            // @media screen and (min-width:768px) and (max-width:1024px){
            //     width: 106%;
            // }
            @media screen and (min-width:768px){
                margin-top: -6px;
            } 
            @media screen and (max-width:767px){
                border-radius: 5px !important;
                    margin: -16px 0 0 0;
                    height: 40px!important;
                    width: 101%;
            } 
        }
        .online-services-buy-desktop-three, .online-services-buy-desktop-four,.online-services-buy-desktop-one, .online-services-buy-desktop-two,.online-services-buy-desktop-max{
            border-radius: 10px !important;
            height: 57px !important;
            color: #00afaf;
            border-color: #00afaf;
            // width: 206px;
            @media screen and (max-width:767px){
                border-radius: 5px !important;
                margin-top: 16px;
                height: 57px !important;
                width: 100%;
                
            } 
            @media screen and (min-width: 768px)  and (max-width: 1599px){
                height: 82px !important;
            }
        }
        .online-services-gift-desktop,#online-services-transfer-desktop{
            border-radius: 10px !important;
            height: 57px !important;
            color: #00afaf;
            border-color: #00afaf;
            // width: 206px;
            @media screen and (max-width:767px){
                border-radius: 5px !important;
                margin-top: 16px;
                width: 101%;
            } 
        }
        .pad-none{
            @media screen  and (max-width:767px){
                padding: 0;
            } 
        }
        .height-mobile{
            height: 52px;
        }
        .left-align{
            text-align: left;
        }
        #online-services-gift-recipet-id, .selectize-input, #online-services-transfer-recipient-id{
            border: 1px solid #ccc;
            border-radius: 10px;
            height: 55px;
            vertical-align: baseline;
            padding: 4px 14px;
            color: #000;
            width: 22rem;
            
            @media screen and (min-width: 768px) and (max-width: 1024px){
                width: 40%;
            }
            @media screen and (max-width: 767px){
                width: 80%;
                height: 550x;
                border-radius: 5px;
            }
        }
        .has-error{
            color: #ed1c24;
            
            border: 1px solid #ed1c24;
        }
    
        .buy-label{ 
            position: absolute;
            margin-top: -5px !important;
            // margin-left: 20px;
            padding-left: 4px;
            padding-right: 4px;
            color:#61666f;
            background-color: #fff;
            font-size: 13px;
        }
        .transfer-label{ 
            position: absolute;
            margin-top: -5px !important;
            // margin-left: 20px;
            padding-left: 4px;
            padding-right: 4px;
            color:#61666f;
            background-color: #fff;
            font-size: 13px;
        }
        .buy-spacing {
            margin-top: 20px;
        }
        #dropdown-spacing .form-item{
            display: inline-block !important;
        }
        .eligible-points{
            background: #00afaf;
            margin-right: .3px;
            color: #fff;
            padding: 12px;
            font-size: 13px;
            height: 42px;
        }
        .position-right{
            text-align: right;
            padding-right: 5px;
        }
        .position-fixing{
            padding-bottom: 2px;
            padding-left: 5px;
        }
        .extend-spacing{
            padding-top: 10px;
            padding-left: 14px;
        }
        .extend-pad-rgt{
            padding: 0;
            padding-right: 10px !important;
        }
        .eligible-points-value{
            background: #00afaf;
            color: #fff;
            padding: 11px;
            text-align: center;
            height: 42px;
            @media screen and (max-width: 767px){
               padding: .54rem;
            }
        }
        .eligible-points-value-reactivate{
            background: #00afaf;
            color: #fff;
            padding: 12px;
            text-align: center;
            height: 42px;
        }
        .position-right{
            text-align: right;
            padding-right: 5px;
        }
        .position-fixing{
            padding-bottom: 2px;
            padding-left: 5px;
        }
        .reactivate-spacing{
        padding-top: 10px;
        padding-left: 14px;
        }
        .reactivate-pad-rgt{
            padding-right: 0;
        }
        .mar-spacing{
            margin: 0 !important;
        }
        .transfer-spacing {
            margin-top: 20px;
        }
        .nav-bar{   
            border: 1px solid #005aaa;
            padding: 11px 23px;
            border-radius: 8px 8px 0px 0px;
        }
        .buy-points-mob{
            .display-blk{
                .row{
                    display: block;
                }
            }
        }
        .d-blk{
            display: block;
        }
        .nav-bar a :active{
            color: #fff;
            background-color: #005aaa;
        }
    
        .online_tab{
            display: flex !important;
            margin-bottom:40px;
        }
        .mar-rgt-spacing{
            margin-left: -13px;
        }
        .mar-btm-spacing{
            margin-bottom: 30px;
            h2{
                color:#005aaa;
            }
        }
        .marError-btm-spacing, .marError-btm-spacing-nok
        {
            margin-bottom: 30px;
            color: red;
        }
        .disabled-mob{
            @media screen and (max-width: 767px){
                display: none;
            }
        }
        .disabled-desktop{
            @media screen and (min-width: 768px){
                display: none;
            }
        }
        .pad-btm-spacing{
            padding-bottom: 30px;
            padding-right: 5px;
        }
        .txt-rgt{
                text-align: end;
                @media screen and (min-width:767px) and (max-width:1024px){
                    padding-left: 0 !important;
                }
        }
        .online-services--checkbox{
            text-align: left;
            padding-top: 5px;
            // margin-top: 5rem;
    
            @media screen and (min-width:768px){
                // margin: 3rem 13px;
            }
            @media screen and (min-width:768px) and (max-width:1024px){
                // width: 50% !important;
                font-size: 15px;
            }
            @media screen and (max-width:767px){
                // margin: 13px;
                margin-left: 15px;
                width: 92%;
                margin: 0 0 0 20%;
            }
        }
        #terms-checkbox +label{
    
            margin-left: 10px !important;
            margin-top: 0 !important;
          
            
        }
        .textboxchanges .form-item .label{
            @media screen and (min-width:768px){
                display: none !important;
            }
            
            &::after {
                content : none;
            
            }

        }

        .textbox-tend .form-item .label{
            @media screen and (min-width:768px){
                display: none !important;
            }

            &::after {
                content : none;
            }
        }
        #online-service-check{
            @media screen and (min-width:768px) and (max-width:1024px){
                // width: 50% !important;
            }
        }
        #online-services--dropdown{   
            border: 1px solid #ccc;
            border-radius: 9px;
            width: 60px;
            height: 28px;
            background-color: #fff;
        }
        .terms-title{
            background: #005aaa!important;
            color: #fff;
            padding: 15px 20px;
            border-radius: 30px;
            margin-top: 10%;
            text-align: center;
            @media screen  and (max-width:767px){
                margin-left: 12px;
            }
            @media screen and (min-width:768px) and (max-width:1024px){
                margin-left: 15px;
            }
        }
        @media screen and (min-width: 768px){
            #online-services-width{
                width: 700px !important;
            }
        }
        .pad-lft-spacing{
            padding-left: 0;
        }
        .my-order-content-font-size{
            font-size: 14px;
            clear: both;
        }
    
        
        .table-titles{
            background-color: #005aaa;
            color: #fff;
            font-size: 13px;
            text-align: center;
            border: 2px solid #005aaa;
            margin-bottom: 5px;
            padding: 15px 0px;
            border-radius: 10px;
            @media screen and (max-width: 767px){
                font-size: 12px;
                padding: 5px 0px;
                border-radius: 5px;
            }
        }
        .table-items{
            color: #000;
            font-size: 13px;
            text-align: center;
            border-radius: 10px;
            margin-bottom:5px;
            @media screen and (max-width: 767px){
                font-size: 12px;
                border-radius: 5px;
            }
        }
        .table-items-bg-light{
            background-color: #f1f1f1;
            border: 2px solid #f1f1f1;
            padding: 15px 0px;
            @media screen and (max-width: 767px){
                padding: 5px 0px;
            }
        }
        .table-items-bg-dark{
            background-color: #beb9b9;
            border: 2px solid #beb9b9;
            padding: 15px 0px;
            font-size: 14px;
            @media screen and (max-width: 767px){
                padding: 5px 0px;
            }
        }
        .table-items-border-left{
            border-radius: 10px 0px 0px 10px;
            @media screen and (max-width: 767px){
                border-radius: 5px 0px 0px 5px;
            }
        }
        .table-items-border-right{
            border-radius: 0px 10px 10px 0px;
            @media screen and (max-width: 767px){
                border-radius: 0px 5px 5px 0px;
            }
        }
        .table-items-border{
            border-radius: 10px;
            @media screen and (max-width: 767px){
                border-radius:5px;
            }
        }
        .my-order--content{
            margin-bottom: 0;
            font-size: 14px;
        }
        .pad-space{
            padding: 0;
        }
        .pad-space--buy-points{
            padding-left: 0px;
            padding-right: 0px;
        }
        .current--elite-points{
            background-color: #00afaf;
            color: #fff;
            border-radius: 10px;
            @media screen and (max-width: 767px){
                border-radius:5px;
            }
        }
        .current--elite-points-number{
            text-align: center;
            padding: 12px;
        }
        .current--elite-points-title{
            border-right: 2px solid #fff;
            padding: 12px;
        }
        #error-text{
            color: #f00;
        }
        .hide{
            display: none;
        }
        .dis-flex{
            display: flex;
        }
        .item-padd{
            padding: 12px 0px;
        }
        .mar-alignment{
            margin: 0px 0px 10px 15px;
        }
        .pad-space-align{
            padding-top: 5px;
        }
        .vali-alignment{
            text-align: left;
            padding-left: 7% !important;
        }
        .onlineServiceBuypoints .members__content .form .form-group .row{
           
            margin-bottom: 0 !important;
        }
        .textboxchanges .form-item .label {
            background: #fff;
            // font-size: 9px;
            margin-left: -85px !important;
            vertical-align: text-bottom;
            margin-top: -8px !important;
            font-size: 12px !important;
            @media screen and (max-width: 767px){
                margin-left: -125px !important;
            }
        }
        .textbox-tend .form-item .label {
            background: #fff;
            font-size: 12px !important;
            margin-left: -158px!important;
            vertical-align: text-bottom;
            margin-top: -12px !important;
           
        }
     

        .textbox-activate .form-item .label {
                background: #fff;
                font-size: 12px !important;
                margin-left: -50px!important;
                vertical-align: text-bottom;
                margin-top: 0px !important;
                @media screen and (max-width: 767px){
                    margin-left: -120px !important;
                    margin-top: 30px;
                }
            @media screen and (min-width: 768px){
                display: none;
            }
        }
         .textbox-activate{
            @media screen and (max-width: 767px){
                margin-top: 16px;
                padding: 0 !important;
            }
        }
        .textbox-tend{
            @media screen and (max-width: 767px){
                padding: 0 !important;
                margin-top: 16px;
            }
        }
        .my-order .table-titles{
            margin-bottom: 3px !important;
            margin-top: 50px;
            @media screen and (max-width: 767px){
                margin-top: 36px;
            }
        }
        .my-order .table-titles .total {
            padding: 3px 0px !important;
        }
        .total-height{
            padding: 3px 0;
        }
        .vali--align{
            text-align: left;
            padding-left: 90px !important;
        }
        .mar-btm-none{
            margin-bottom: 0;
        }
        .btn-wdth{
            @media screen and (max-width: 767px){
                width: 180px !important;
            }
        }
        .terms-con-mob{
            color:#00afaf;
            padding: 6px 15px;
            border-top: 1px solid #00afaf;
            border-bottom: 1px solid #00afaf;
        }
        .points-alignment{
            padding: 0;
            margin-left: -5px;
        }
        .mar-btm{
            margin-bottom: 10px;
        }
        .mar-top-textbox{
            margin-top: 7px;
            padding-right: 5px !important;
            // @media screen and (min-width: 768px){
            // padding-right: 5px !important;
            // }
        }
        .buy-points-height-mob{
            @media screen and (max-width: 767px){
                height: 62px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
        }
        .buy-points-height-desktop{
            @media screen and (min-width: 1200px) and (max-width: 1599px){
                height: 82px !important;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .buy-points-height-tab{
            @media screen and (min-width: 768px) and (max-width: 1199px){
                height: 82px !important;
            }
        }
        // .buy-points-height-mob-extra{
        //     @media screen and (max-width: 767px){
        //         height: 86px;
        //     }
        // }
        // .buy-points-padd-top{
        //     // padding-top: 25px;
        //     @media screen and (max-width: 767px){
        //         padding-top: 0px;
        //     }
        // }
    }
    .payment-error, .nominee-error{
        color: red;
        overflow: hidden;
    }
    .error-description {
        color: red; 
    }
    .success-description {
        color: green;
    }
.strike-through {
    text-decoration: line-through;
    padding-right: 5px;
}
.red-in-color {
    color: red;
}
.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}
    
    