.image-text--container{
  .col-lg-12,.col-lg-6, .col-lg-3{
    margin: 0;
    padding: 0;
  }
  .deals-image--holder{
    position: relative;
    @include z-index(passengarDetails);

    img {
      //height: 25vw;
      width: 100%;

      @include respond-to(1200) {
        //height: 50vw;
      }
    }

    .big-image {
      img {
        //height: 50vw;
        @include respond-to(1200) {
          //height: 100vw;
        }
      }
    }
    .deals-price{
      position: absolute;
      width: 60%;
      top: 25%;
      left: 20%;
      min-height: 50%;
      .deals-price--content{
        .continent{
          @include font-size(14);
          line-height: 1em;
          color: $white;
          text-align: center;
          margin-top: 20px;
        }
        .separator{
          border: 1px solid $errorred;
          clear: both;
          margin: 20px 40% 15px;
        }
        .country-name{
          @include font-size(24);
          line-height: 1.25em;
          color: $white;
          text-align: center;
        }
      }
      padding: 10% 6%;
      background-color: rgba(0, 0, 0, 0.3);
      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
      color: $white;
      cursor: pointer;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      -webkit-transition-property: color;
      transition-property: color;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 25%;
        left: 25%;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.9);
        width: 50%;
        height: 50%;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover, &:focus, &:active{
        //background: $blue;
          .continent{
            color: $gray;
          }
          .country-name{
            color: $focusblue;
          }
          &:before {
          -webkit-transform: scale(2);
          transform: scale(2);
        }
      }
      &.big{
        .deals-price--content{
          .continent{
            @include font-size(24);
            margin-top: 20px;
          }
          .separator{
            margin: 30px 40% 10px;
          }
          .country-name{
            @include font-size(48);
            line-height: 1em;
          }
        }
      }
    }
  }
}

.destination-container{
  margin-bottom: 35px;
  .show-more--destinations{
    display: none;
  }

  .showing-txt{
    margin: 30px auto;
    text-align: center;
    div{
      display: inline;
    }
    .showing, .total-destinations{
      font-family: $font-family-medium;
    }
  }

  .btn-secondary{
    margin: 0 45%;
    width: 156px;
    text-align: center;
    display: inline-block;
  }

  .deals-content{
    display: none;
  }

  .image-text--container {
    .deals-image--holder img {
      @include respond-to(phone) {
        height: 100vw;
      }
    }
  }
}

@include respond-to(1200){
  .image-text--container{
    .btn-secondary{
      margin: 0 42%;
    }
    .deals-image--holder{
      .deals-price{
        &.big{
          .deals-price--content{
            .separator{
              margin: 10px 40%;
            }
            .country-name{
              @include font-size(32);
              line-height: 1em;
            }
          }
        }
      }
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 42%;
    }
  }
}

@include respond-to(ipad-portrait){
  .image-text--container{
    .deals-image--holder{
      .deals-price{
        &.big{
          .deals-price--content{
            .country-name{
              @include font-size(36);
              line-height: 1em;
            }
          }
        }
      }
    }
  }
}

@include respond-to(850){
  .image-text--container{
    .btn-secondary{
      margin: 0 40%;
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 40%;
    }
  }
}

@include respond-to(650){
  .image-text--container{
    .deals-image--holder{
      .deals-price{
        &.big{
          .deals-price--content{
            .continent{
              @include font-size(14);
              line-height: 1em;
            }
            .country-name{
              @include font-size(24);
              line-height: 1em;
            }
          }
        }
      }
    }
    .btn-secondary{
      margin: 0 37%;
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 37%;
    }
  }
}

@include respond-to(550){
  .image-text--container{
    .btn-secondary{
      margin: 0 36%;
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 36%;
    }
  }
}

@include respond-to(500){
  .image-text--container{
    .btn-secondary{
      margin: 0 34%;
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 34%;
    }
  }
}

@include respond-to(414){
  .image-text--container{
    .btn-secondary{
      margin: 0 32%;
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 32%;
    }
  }
}

@include respond-to(375){
  .image-text--container{
    .btn-secondary{
      margin: 0 30%;
    }
    .deals-image--holder{
      .deals-price{
        &.big{
          .deals-price--content{
            .separator{
              margin: 10px 40%;
            }
            .country-name{
              @include font-size(28);
              line-height: 1em;
            }
          }
        }
      }
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 30%;
    }
  }
}

@include respond-to(320){
  .image-text--container{
    .btn-secondary{
      margin: 0 25%;
    }
    .deals-image--holder{
      .deals-price{
        &.big{
          .deals-price--content{
            .country-name{
              @include font-size(24);
              line-height: 1em;
            }
          }
        }
      }
    }
  }
  .destination-container{
    .btn-secondary{
      margin: 0 25%;
    }
  }
}