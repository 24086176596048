
#book-flight--container{
  .flights-hotel-wrap{
    .btn-primary  ,  .btn-primary:hover {
      width: auto;
      padding-left: 35px;
      padding-right: 35px;
      background-image: linear-gradient(#f1955a,#ce3b7f) !important;
    }
    .col-lg-5 {
      width:39%;
    }
    .flights-hotel-btn{
      display: none;
    }
  }
}
.child-age-grp input {
  width:100%;
  padding-left:12px;
  border:1px solid #ccc;
  @media (max-width: 1200px){
      width: 69%;
    }
}
.child-age-grp select {
  width: 100px;
  height: 25px;
  border-radius: 15px;
  padding: 0 5px;
}
.child-age-grp label {
  font-size: 1rem;
}
#mholiday-ibe--container, #mobile-mholidays--container{
  display: none;
  background-image:  linear-gradient(#f1955a, #ce3b7f);
  .mand{
    color: $errorred;
    padding-left: 2px;
  }
  .mob-hotel-container{
    display:none;
  }
  .payment-type-container, .trip-type--container li.selected,.trip-type--container li a, .fa-times{
    color: #ffffff !important;
  }
  .passenger-details{
    background-color:  #ffffff;
  }
  .hotelInputClass,.pickInputClass{
    @media (max-width: 790px){
    padding-bottom :2px;
    }    
      text-overflow: ellipsis;      
      overflow: hidden;
      height: 48px;
      white-space: nowrap;
      background-color:#fff !important;
  }
  .trip-container{
    margin: 6px;
    color: white;
    font-size: 15px;
    width:95%;
    .mlogo {
      text-align:center;
      .logo {
        height: 30px;
        width: 130px;
      }
    }
    .mhlogo {
      display:inline-block;
      width :120px;  
      .logo {
        height:18px;
      }
    }
    .mhlogo-text{
      display: inline;
    }
  }
  .redemption-payment-methods{
    ul li{
      color:white;
    }
  }
  .location-container{
    width: 100%;
    float: left;
    padding-right:2px;
    @media (max-width: 1200px){
      width: 100%;
      }
    .col-lg-5{
      width: 49.175%;
    }
    .col-lg-5.toLocation{
      padding-left: 4px;
    }
    .col-lg-5.fromLocation{
      padding-right: 4px;
    }    
  }
  .search-container{
    padding-top :10px;
    .col-lg-3 , .col-lg-4 {
      width : 22%;
      @media (max-width: 1200px){
        width: 34%;
        }
    }
    .col-lg-1{
      @media (max-width: 1200px){
        width: 25%;
        }
    }
  }
  .selectize-input.full {
    padding: 23px 20px 1px;
    height: 48px;
    @media (max-width: 790px){
    padding: 23px 20px 5px;
    }
  }
  .passenger-details-selected, .passenger-details{
   // width:100%;
  }
  .passenger-details-selected {
    .booking-person {
      .isnt-uk {
        display:none;
      }
      .child-age-select {
        width: 100%!important;
        margin: 0!important;
        margin-bottom: 10px!important;
        .col-lg-6 {
          padding-left: 0;
          padding-right: 0;          
        }
        .childAgeInputSelect {
          text-align: right;
        }
      }
    }
  }
}
#mobile-mholidays--container{
    .trip-container{
        font-size: 12px;
        margin-bottom: 0px;
    }
}
.hotel-container{
  padding: 0px 20px 15px 20px; 
  display: none;
  .search-container{
    padding-left: 0px !important;
    .ret-title {
      padding-left: 10px !important;
    }
   }
   .hotel-not{
    padding: 20px;
    font-size: 0.85rem;
   }
   .col-lg-1{
    @media (max-width: 1200px){
      width: 16% !important;
      }
      @media (max-width: 1000px){
        width :50% !important;
        margin-top: 16px;
        }
    }
    .col-lg-3{
      @media (max-width: 1200px){
        width: 27% !important;
        }
        @media (max-width: 1000px){
          width :50% !important;
          }
      }
    .col-lg-4{
      @media (max-width: 1200px){
        width: 25% !important;
      }
      @media (max-width: 1000px){
         width :50% !important;
        }
        .passenger-details{
          @media (max-width: 1000px){
            margin-top: 16px;
            margin-left: -7px;
           }
        }

  }

  }
.hotel-input{
  width:32% !important;
  padding-left:0px !important;
  padding-right:10px !important;
}
.hotel-input .form-control{
  padding-top:28px !important;
}
.hotel-input-boarder{
  border-radius: 25px 25px 0px 0px !important;
}
.hotel-display-tui-container, .from-display-tui-container,.tour-display-tui-container,.enrich-hotel-earn-display-tui-container,.enrich-hotel-redeem-display-tui-container,.mob-enrich-hotel-earn-display-tui-container,.mob-enrich-hotel-redeem-display-tui-container{  
  overflow: hidden;
  background: white;
  border: 1px solid #ccc;
  z-index: 100;
  position: absolute;
  top: 46px;
  padding: 10px 0px 10px 0px;
  display:none;
  width: calc(100% - 11px);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 0px 1px 1px 1px solid #005aaa;
  border-top-width:0px;
  @media screen and (max-width: 767px) {
    width :100%;
  }
  em{
    font-size: 11px;
    line-height: 14px;
    font-style: normal;
    color: #999;
  }
 
}
#transferdrop,#tourInputWrap, #transferpickup{
  .hotel-display-tui-container, .from-display-tui-container,.tour-display-tui-container,.enrich-hotel-display-tui-container{
    top: 60px;
   }

}
.hotel-tui-inner {
  max-height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
      -webkit-border-radius: 0;
      border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(0,109,187,1);
      -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
  }
}

.hotel-title{
  position: relative;
  display: block;
  font-size: 0.80rem;
  padding: 5px 20px 5px 0px;
  font-weight:bold;
  span{
    position: relative;
      color: #df2280;
      text-transform: uppercase;
      padding: 0 20px;
      background: white;
  }
}
.hotel-title:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #dfe2e2;
  margin-top: -1px;
}

.hotel-list{
  display: block;
  font-size: 0.80rem;
  color: #005aaa;
  padding: 5px 20px 5px 20px;
}
.hotel-list:hover {
  background-color: #005aaa;
  color:white;
  cursor: pointer;
  em{
    color:white;
  }
}
#slide-nav #bookingNav .navbar-nav li.mholiday-tab.selected a {
  background-color: #f1955a;
  color: #fff;

}
#slide-nav #bookingNav .navbar-nav li.mob-mholiday-tab.selected a{
  border-top: 2px solid #ed1c24;
  background-color: #f1955a;
  color: #fff;
}
#slide-nav #bookingNav .navbar-nav li.mholiday-tab a:hover{
  background-color: #f1955a;
  color: #fff;
}
.mholiday-tab a .logo{
  height:16px;
  float:left;
  padding-right:7px;
  margin-top:3px;
  @media screen and (max-width: 1200px) {
    height:14px; 
  }
  @media screen and (max-width: 768px) {
    height:13px; 
  }
}
.mob-mholiday-tab a .logo {
  height:12px;
}
.mob-mholiday-tab {
  a {
    .hotel-flight-icon {
      float:left;
      width:100%;
      text-align:center;
      img {
        &.hotel-flight {
          height: 24px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
#mobile-mholidays--container{
  .location-container{
    padding-top:8px;
  }
  #returnTripDateSelectorMob{
    background-color: #ffff;
  }
} 
.mob-hotel-container
{
  .hotelInputtoVal
  {
    display: none;
  }
}