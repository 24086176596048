
.rangeslider__handle {
    background-image: linear-gradient($seashell, $seashell);

  &:active,
  .rangeslider--active & {
    background-image: linear-gradient(shade($seashell, 0.1), shade($seashell, 0.1));
  }
}

.rangeslider__handle:after {
  background-image: linear-gradient($enrich-neutral, $enrich-neutral);

  .member--gold & {
    background-image: linear-gradient($enrich-gold, $enrich-gold);
  }

  .member--silver & {
    background-image: linear-gradient($enrich-silver, $enrich-silver);
  }

  .member--platinum & {
    background-image: linear-gradient($enrich-platinum, $enrich-platinum);
  }

  .member--blue & {
    background-image: linear-gradient($enrich-blue, $enrich-blue);
  }
}
