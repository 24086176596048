.infographic{
	h1{
		text-align: center;
	}
	.sort-button-group{
		width: 100%;
		text-align: center;
		border-bottom: 1px solid $lightgray;
		a{
			display: inline-block;
			margin: 0 25px;
			padding-bottom: 10px;
      @include respond-to(ipad-portrait){
        margin: 0 10px;
      }
			.fa{
				clear: both;
				text-align: center;
				width: 100%;
			}
			&.hvr-underline-from-left{
				&:before{
					background: $errorred;
				}
			}
		}
	}
	.infograph-grid{
		padding: 0 8%;
		margin: 30px 0;

    .grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
      -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
	}
}

.redeem-content{
	width: 276px;
	height: auto;
	border: 1px solid $lightgray;
	margin-right: 20px;
	margin-bottom: 20px;
	padding: 20px;
  @include respond-to(320){
    width: 250px;
  }
	.step-no{
		@include font-size(36);
		color: $focusblue;
	}
	.sep{
		width: 50px;
		height: 2px;
		background: $errorred;
	}
	.title{
		color: $focusblue;
		@include font-size(24);
		line-height: 1.2em;
		margin: 15px 0;
	}
	img{
		width: 100%;
	}
}

.step-to--redeem{
	&.mobile{
		padding: 0 10%;
		.redeem-content{
			margin: 0 auto;
		}
		.redeem-steps--selector{
			.slider-elements{
				width: 100%;
				height: auto;
				overflow: hidden;
				.slider{
					padding: 15px 0 0;
					.slick-slide{
						margin-bottom: 10px;
						margin-right: 0;
						a{
							p{
								color: $blue;
								@include font-size(16);
								text-align: center;
								margin-top: 15px;
							}
						}
						.img-wrapper{
							border: 1px solid $lightgray;
							background: $white;
						}
						&:focus{
							outline: 0;
						}
					}
				}
				button{
					@include border-radius(100px);
					border: 1px solid $blue;
					padding: 8px;
					&:before{
						color: $blue;
						opacity: 0;
						line-height: 0;
						position: relative;
						left: -9px;
						top: -1px;
					}
					&:hover{
						background: $focusblue;
						&:before{
							color: $white;
						}
					}
				}
			}
			.slick-dots{
				margin: 0 auto;
				border-top: 0;
				bottom: -30px;
				padding: 0;
				width: 100%;
        @include respond-to(320){
          bottom: -10px;
        }
				li{
					button{
						padding: 0;
						width: 16px;
						height: 16px;
						&:before{
							opacity: 0;
						}
					}
					&.slick-active{
						button{
							background: $blue;
						}
					}
				}
			}
		}
	}
}

@include respond-to(1920){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 18%;
		}
	}
}

@include respond-to(1800){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 17%;
		}
	}
}

@include respond-to(1750){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 16%;
		}
	}
}

@include respond-to(1700){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 15%;
		}
	}
}

@include respond-to(1700){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 14%;
		}
	}
}

@include respond-to(1600){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 13%;
		}
	}
}

@include respond-to(1550){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 12%;
		}
	}
}

@include respond-to(1500){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 11%;
		}
	}
}

@include respond-to(1440){
	.infographic{
		.infograph-grid{
			padding: 0 7% 0 9%;
		}
	}
}

@include respond-to(1400){
	.infographic{
		.infograph-grid{
			padding: 0 6% 0 8%;
		}
	}
}

@include respond-to(1350){
	.infographic{
		.infograph-grid{
			padding: 0 5% 0 6%;
		}
	}
}

@include respond-to(1280){
	.infographic{
		.infograph-grid{
			padding: 0 2% 0 4%;
		}
	}
}

@include respond-to(1240){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 13%;
		}
	}
}

@include respond-to(1200){
	.infographic{
		.infograph-grid{
			padding: 0 10% 0 12%;
		}
	}
}

@include respond-to(1100){
	.infographic{
		.infograph-grid{
			padding: 0 9%;
		}
	}
}

@include respond-to(1000){
	.infographic{
		.infograph-grid{
			padding: 0 6%;
		}
	}
}

@include respond-to(ipad-portrait){
	.infographic{
		.infograph-grid{
			padding: 0 11%;
		}
	}
}

@include respond-to(ipad-pro){
  .infographic{
    .infograph-grid{
      padding: 0 6% 0 7%;
    }
  }
}
