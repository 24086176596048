.enrich_promotionstoggle {
  margin-top: 40px;

  &__tab-wrapper {
    width: 300px;
    display: block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  &__tabs {
    padding: 0;
    display: inline-block;
    width: 100%;
    li{
      &.active{
        box-shadow: none;
      }
    }
  }

  &__tab {
    display: block;
    width: 50%;
    padding: 0;
    background-color: $white;
    border-radius: 50px;
    overflow: hidden;
    border: 1px solid $blue !important;

    a {
      display: block;
      background-clip: padding-box;
      vertical-align: middle;
      padding: 15px 20px 15px;
      font-size: 0.875rem;
      line-height: 1rem;
      -webkit-transform: perspective(1px) translateZ(0);
              transform: perspective(1px) translateZ(0);
      -webkit-transition-property: color;
      transition-property: color;
      -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
      color: $blue;
    }

    &.active {
        background-color: $blue;

      a {
        color: $white;
      }
    }
  }

  &__content {
    padding-bottom: 60px;
  }

  .tab-content {
    max-width: 1180px + 20px;
    margin: 0 auto;

    @include respond-to(1180 + 20 + 20 + 20) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

// possibly another component, extract to new file if yes
.enrichpromotions {
  &__items {
    list-style: none;
    display: inline-block;
    padding: 0;
    width: 100%;

    @supports (display: flex) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
  }

  &__item {
    $card-gutter: 20px;

    display: block;
    float: left;
    margin-bottom: $card-gutter;
    position: relative;
    background-color: $seashell;

    @supports(display: flex) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    @media screen and (min-width: 1025px) {
      $card-per-row: 3;
      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
      margin-right: $card-gutter;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      $card-per-row: 2;
      width: calc(#{100% / $card-per-row} - #{20px * ($card-per-row - 1) / $card-per-row});
      margin-right: $card-gutter;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include respond-to(767) {
      width: 100%;
    }
  }

  .promotion_item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 30px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.8) 80%);
      z-index: 1;
    }

    &__banner-cover {
      width: 100%;
      height: 245px;
    }

    &__content {
      position: relative;
      z-index: 2;
      .title,
      .excerpt {
        color: $white;
      }

      .title {
        font-size: rem(30);
        line-height: rem(36);
        font-family: $font-family;
        font-weight: normal;
        margin-bottom: 5px;
      }

      .excerpt {
        font-size: rem(16);
        line-height: rem(24);
        margin-bottom: 10px;
      }

      .btn-primary {
        width: 150px;
        display: block;
        &.enrich{
          background: $red !important;
        }
      }
    }
  }

  &__results {
    .results {
      display: block;
      position: relative;
      text-align: center;
    }

    .btn {
      display: block;
      position: relative;
      margin: 0 auto;
      margin-top: 30px;
      width: 150px;

      i.fa {
        margin-right: 10px;
      }
    }
  }
}
