.mhupgrade-component{
	margin: 20px 0 0 0;
	h1, h2{
		line-height: 1em;
	}
	.booking-form--title{
		position: absolute;
		margin-left: 24px;
		margin-top: 14px;
		color: $darkgray;
		@include font-size(14);
		@include z-index(bookformtitle);
    -webkit-transition: all 90ms linear;
    -o-transition: all 90ms linear;
    transition: all 90ms linear;
		&.input-filled{
			margin-top: 6px;
			margin-left: 22px;
			@include font-size(12);
		}
	}
	input{
		&.input-focused{
			padding: 21px 20px 4px 21px;
		}
    text-transform: uppercase;
	}
	.col-lg-12{
		margin: 0 0 15px 0;
		padding: 0;
	}
	form{
		margin-top: 20px;
	}
	.row{
		margin: 0;
	}
}
