#members--enrich-status-new, #members--enrich-status-mob-new, #members--my-activity-section {
    .title {
        position: relative;
        margin: 0;
        font-family: $font-family;
        color: $blue;
        line-height: initial;
        margin-bottom: 20px;
    
        // @include respond-to(tablet-portrait) {
        //     font: normal bold 21px/23px Gotham,Arial !important;
        //   }  
        @media screen and (max-width: 767px) {
            text-align: center;
          }   
    }    
    .member-summary{
        background: $seashell 0% 0% no-repeat padding-box;
        border: 1px solid #ccc;
        border-radius: 10px;        
    } 
    .distance-padding {
        padding-bottom: 20px;
    }  
    .summary-subtitle {
        font: normal bold 14px/21px Gotham,Arial !important;
        color: $gray;
        margin: 18px 0px 15px 35px;
        @include respond-to(tablet-portrait) {
            margin: 18px 0px 15px 21px;
          }  
    }
    .summary-section {
        font: normal normal 14px/21px Gotham,Arial;
        color: $gray;
        margin: 0px 0px 15px 43px;
        @include respond-to(tablet-portrait) {
            margin: 18px 0px 15px 29px;
          } 
    }    
    .miles-value {
        font: normal bold 14px/21px Gotham,Arial;
        color: $gray;
    }
    .miles-expiry {
        font: normal bold 14px/21px Gotham,Arial;
        color: $gray;
    }
    .next-card-type {
        font: normal bold 14px/21px Gotham,Arial;
        color: $gray;
    }
    .member-progress {
        background: $seashell 0% 0% no-repeat padding-box;
        border: 1px solid #ccc;
        border-radius: 10px; 
        margin-top: 10px;
        margin-bottom: 25px;
        @include respond-to(tablet-portrait) {
            margin-bottom: 15px;
        }
    }
    .miles-value-progress {        
        font: normal bold 14px/21px Gotham,Arial;        
        color: #006dbb;
    }
    .progress-section {
        font: normal normal 14px/21px Gotham,Arial;
        color: $gray;
        margin: 0px 0px 45px 46px;
        @include respond-to(tablet-portrait) {
            margin: 30px 0px 0px 0px;
            text-align: center;
            padding:30px 0px 13px 0px;
          }  
    }
    .miles-sector {
        font: normal bold 14px/21px Gotham,Arial;
        color: $gray;
    }
    .enrich-progress-container {
        height: 300px;    
        .progress-label {
        text-align: center;
        font: normal bold 14px/21px Gotham, Arial;
        color: #006dbb;
        margin-top: 101px;
        @include respond-to(tablet-portrait) {
            margin-top: 29px;
          }   
        }
        .nanobar {
        margin: auto;
        height: 12px;
        width: 300px;
        border-radius: 100px;
        overflow: hidden;
        background: #9b9b9b 0% 0% no-repeat padding-box;
        border: 1px solid #ccc;  
        .bar {
          background: transparent linear-gradient(90deg, #1858a6 0%, #1b286e 100%) 0% 0% no-repeat padding-box;;
          border-radius: 100px;
        }
      }
      .miles-sector-values {
        margin: auto;        
        width: 300px;        
        overflow: hidden;
      }
      .progress-initial_value {
        text-align: center;
        font: normal normal 14px/21px Gotham,Arial;       
        float: left;    
        color: $gray;       
      }
      .progress-mid_value {
        text-align: center;
        font: normal normal 14px/21px Gotham,Arial;
        // margin-left: 18%;       
        color: $gray;       
        float: right;
      }     
      .progress-max_value {
        text-align: center;        
        font: normal normal 14px/21px Gotham,Arial;            
        color: $gray;
        float: right;
      }
        .position {
            float: none;            
        }
        
        .progress-bar {
            position: relative;
            height: 200px;
            width: 200px;
            margin-top:41px;
            margin-left: 53px;
            @include respond-to(tablet-portrait) {
                margin-left: auto;
                margin-right: auto;
              } 
        }
        
        .progress-bar div {
            position: absolute;
            height: 200px;
            width: 200px;
            border-radius: 50%;
        }
        
        .progress-bar div span {
            position: absolute;
            font-family: Arial;
            font-size: 25px;
            line-height: 175px;
            height: 175px;
            width: 175px;
            left: 12.5px;
            top: 12.5px;
            text-align: center;
            border-radius: 50%;
            background-color:#ecf0f1;
        }
        
        .progress-bar .background { background-color: #ccc; }
        
        .progress-bar .rotate {
            clip: rect(0 100px 200px 0);            
            background: transparent linear-gradient(90deg, #1b286e 0%, #1b286e 100%) 0% 0% no-repeat padding-box;;
        }
        
        .progress-bar .left {
            clip: rect(0 100px 200px 0);
            opacity: 1;
            background-color: #b3cef6;
        }
        
        .progress-bar .right {
            clip: rect(0 100px 200px 0);
            transform: rotate(180deg);
            opacity: 0;           
            background: transparent linear-gradient(90deg, #1b286e 0%, #1b286e 100%) 0% 0% no-repeat padding-box;;
        }
        @keyframes 
        toggle {  0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
        }
        .miles-name {
            text-align: center;
            font: normal bold 14px/21px Arial;
            letter-spacing: 0;
            color: #006DBB;
            margin-top: 50px;
            margin-bottom: 0px;
        }
        
        .miles-points {
            text-align: center;
            font: normal bold 35px/21px Arial;
            letter-spacing: 0;
            color: #006DBB;
            margin-top: 10px;
            margin-bottom: 0px;
        }
        .miles-total {
        text-align: center;
        font: normal normal 14px/21px Arial;
        letter-spacing: 0;
        color: #333333;
        opacity: 1;
        margin-top: 8px;  
        }
    }
    .member-points-history {
        background: $seashell 0% 0% no-repeat padding-box;
        border: 1px solid #ccc;
        border-radius: 10px;   
        margin-top: 7px;
        .points-history-subtitle {
            font: normal bold 14px/21px Gotham,Arial !important;
            color: $gray;
            margin: 18px 0px 15px 54px;
            @include respond-to(tablet-portrait) {
            margin: 18px 0px 15px 21px;
          }  
        }
        .oddrow {
            background: #fafafa 0% 0% no-repeat padding-box;
            margin-left: 0px;
            margin-right: 0px;
            line-height: 30px;
        }
        .evenrow {
            background: $seashell 0% 0% no-repeat padding-box;
            margin-left: 0px;
            margin-right: 0px;
            line-height: 30px;
        }
        .lastrow {
            border-radius: 10px;   
        }
        .col-xs-6 {
            left: 13%;
            @include respond-to(tablet-portrait) {
                left: 2.5%;
            }
            [class*='history-miles-'], [class*='history-date-'] {
                font: normal normal 12px/21px Gotham,Arial;
            }
            [class*='miles-value-'] {
                font: normal bold 12px/21px Gotham,Arial;
            }
        }
    }    
}
.member-summary-expiring-points{
    table.dataTable thead .sorting:after {
        //background-image: none !important;
        content: none !important;
      }
      table.dataTable thead .sorting_asc:after {
        content: none !important;
      }
      table.dataTable thead .sorting_desc:after {
        content: none !important;
      }
}