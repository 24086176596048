#lcp_mytraveller_profile_members
{
    
        #spouse
        {
            input.primarymember-profile--enrich-id,input.spouse-profile--enrich-id
            {
                padding-left: 44px;
            }
            .MHspousediv
            {
                position: relative;
                .spanMHspouse
                {
                    color: #948888!important;
                    text-align: left;
                    margin-top: 16px;
                    font-size: 13px;
                    position: absolute;
                    margin-left: 23px;
                    z-index: 22;
                }
            }
            h2
            {
                font-size: 1.51rem;
                @media screen and (max-width: 767px) 
                {
                    font-size: 1.3rem;
                }
            }
            input,label
            {
                pointer-events: none;
            }
            .terms_conditions
            {
                border-bottom: 1px solid red;
                padding: 15px;
            }
            .terms_conditions-desp
            {
                padding-top: 10px;
                margin: 15px;
            }
            .description
            {
                margin-bottom: 15px;
            }
        }
    background-color: white;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 100px;
    h5:first-of-type {
        font-size: 2.25rem;
        padding-top: 15px;
      }
    @media screen and (max-width: 767px) 
    {
        .mytraveler-table
        {
            min-width: -webkit-fill-available;
            border-top: 0px solid #ccc !important; 
            border-bottom: 0px solid #ccc !important;
        }
    }
    @media screen and (max-width: 767px) 
    {
        .col-xs-5,.col-xs-10 
        {
            padding-right: 0px;
            padding-left: 13px;
            margin-left: 0px !important;
        }
        .col-xs-5
        {
            margin-left: 0px !important;
        }
    } 
    .addtra
    {
        margin-top: 30px;
        margin-bottom: 20px;
        font-weight: 600;
        font-family: normal medium 18px/20px Gotham,Arial;
        margin-left: 10px;
        color: #61666F;
            @media screen and (max-width: 767px) 
            {
            margin-top: 6px;
            } 
    }
    .member-add-travel-coordinator--submit
    {
        @media screen and (min-width: 768px) 
        {
            margin-left: 158px;
        }
        margin-bottom: 20px;
    }
    .traveller-loyality
    {
        font: normal 700 14px/16px Gotham,Arial;
        color: #61666f;
    }
    .tagline 
    {
        color: #14467b;
        font: normal normal 14px/16px Gotham,Arial;
    }
    .tag-line 
    {
        font: normal 700 21px/23px Gotham,arial;
        color: #14467b;
    }
    .mytravellererror_message
    {
        color: red;
        font: normal normal 14px/16px Gotham,Arial;
        margin-left: 12px;
        margin-bottom: 10px;
    }
    #member-myadd-lcptraveller
    {
        h1
        {
            position: relative;
            margin-top: 20px;
            font-family: Gotham-Book,Arial;
            color: #005aaa;
            line-height: normal;
            margin-bottom: 20px;
            font-size: 1.6rem;
        }
    }

    #member-profile-lcpadd 
    {
        .loyality {
            color: #61666f;
            font-family: normal medium 14px,16px Gotham,Arial;
        }
        .optinal {
            color: #14467b;
            font-family: normal medium 14px,16px Gotham,Arial;
        }
        .tag {
            color: #006dbb;
            margin-left: 10px;
            font: normal normal 14px/16px Gotham,Arial;
        }
        .btn-primary, .btn-secondary
        {
            margin-left: 0px; 
            border-radius: 10px 10px 10px 10px;
            width: 90%;
         }
            select{
                border-radius: 10px 10px 10px 10px;
                }
            .selectize-input{
            border-radius: 10px 10px 10px 10px;
            }
            input{
            border-radius: 10px 10px 10px 10px;
            }
            label{
                position: absolute !important;
                margin-top: -10px !important;
                background-color: white !important;
                margin-left: 20px !important;
                padding-left: 4px !important;
                padding-right: 4px !important;
            }
    }
    .member-profile-edit 
    {
        .btn-primary, .btn-secondary
        {
            width: 90%;
            border-radius: 10px;
         }
         select{
            border-radius: 10px 10px 10px 10px;
            }
        .selectize-input{
        border-radius: 10px 10px 10px 10px;
        }
        input{
        border-radius: 10px 10px 10px 10px !important;
        }
        label{
            position: absolute !important;
            margin-top: -10px !important;
            background-color: white !important;
            margin-left: 20px !important;
            padding-left: 4px !important;
            padding-right: 4px !important;
        }
    }
    #addtraveller_mobileadd
	{

				@media screen and (max-width: 767px) 
				{
					border-radius:36px;
					width: 50px;
					height: 50px;
					background: #006dbb;
					bottom: 45px;
					right: 25px;
				@media screen and (orientation:landscape){

				bottom: 25px;
				right: 25px;
				}
					font-size: 14px;
					line-height: 28px;  
					-webkit-transform: rotate(-90deg);
					font-weight: 600;
					text-align: center;
					font-size: 17px;
					position: fixed;
				} 

				.fa
				{
					display: none !important;

				@media screen and (max-width: 767px) 
				{
					display: inline-block !important;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: inherit;
					text-rendering: auto;
					color:white;
					-webkit-font-smoothing: antialiased;
					margin-left: -1px;
					margin-top: 18px;
					font-size: 18px;
				@media screen and (max-width:767px) and (orientation:landscape)
				{
					top: 315px  ! important;
				}
				}
				}
	}
    @media screen and (min-width: 767px)
    {
        .edit_form
        {
            margin-left: 80px;
        }
    }
    .circle-travel{

        padding-top: 6px;
        margin-left: -32px;
        border-radius: 15px;
        background: #61666f;
        width: 35px;
        height: 35px;
        /* margin-top: 10px; */
        color: white;	
        text-align: center;
        @media screen and (max-width: 768px)
         {
        margin-left: 21px;
        border-radius: 17px
        }
    }
    .edit {
        margin-top: -38px;
        font-weight: 600;
        font-family: Gotham-Medium,Arial;
        margin-left: 10px;
        @media screen and (max-width: 768px)
        {
            margin-left: 64px;
            margin-bottom: 12px;
       }
    }
    select{
        border-radius: 10px 10px 10px 10px;
        }
        .selectize-input{
        border-radius: 10px 10px 10px 10px;
        }
        input{
        border-radius: 10px 10px 10px 10px;
        }
        label{
            position: absolute !important;
            margin-top: -10px !important;
            background-color: white !important;
            margin-left: 20px !important;
            padding-left: 4px !important;
            padding-right: 4px !important;
        }
    .traveller_list
    {
        float: right;
    }
    .btn-memberadd--submit
    {
        margin-top: 30px!important;
        border-radius: 10px;
        width: 220px;
        height: 46px;
        margin-bottom: 15px;
    }
    h5
    {
        color: #006dbb;
    }
    .mytraveler_tab
    {

           
        .col-lg-3 
        {
            margin-right: 3px!important;
        }
        li.active
        {
            a
            {
                color: #fff;
                background-color: #14467b;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                // border: 1px solid #006dbb;
                text-align: center;
                @media (max-width: 767px)
                {
                    font-size: 13px;
                    // height: 53px;
                }
            }
            a:hover
            {
                color: #fff;
                border: 1px solid;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                border: 1px dashed #0158a7;
                // background-color: #0082d4;
            }
            
        }
        li
        {
            a
            {
                // border: 1px solid;
                color: #19191b;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                text-align: center;
                background-color: #f1f1f1;
                @media (max-width: 767px)
                    {
                        // height: 53px;
                        font-size: 13px;
                        padding-top: 14px;
                        text-align: center;
                    }
            }
            a:hover
            {
                color: #fff;
                border: 1px solid;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                border: 1px dashed #0158a7;
            }
            
        }
        .col-lg-3 {
            width: 30%;
            padding: 0px;
            margin: -1px;
        }
        @media (max-width: 767px)
        {
            .col-lg-3 {
                width: 32%;
            }
        }
    }
 
      table {
        border-collapse: separate;
        border-spacing: 0;
        // min-width: 350px;
      }
      table tr th,
      table tr td {
        border-right: 1px solid #bbb;
        // border-bottom: 1px solid #bbb;
        padding: 5px;
      }
      table tr th:first-child,
      table tr td:first-child {
        border-left: 1px solid #bbb;
      }
      table tr th {
        background: #eee;
        // border-top: 1px solid #bbb;
        text-align: left;
      }
      
      /* top-left border-radius */
      table tr:first-child th:first-child {
        border-top-left-radius: 6px;
        border-top: 1px solid #bbb;
      }
      
      /* top-right border-radius */
      table tr:first-child th:last-child {
        border-top-right-radius: 6px;
      }

      table tr:last-child  th{
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom: 1px solid #bbb;
      }
    #my-traveler
    {
        font-size: 13px;
        margin-bottom: 10px;
        @media screen and (min-width: 767px)
        {
            width: 90%;
            width: 728px !important;
            margin: 10px;
        }
        tr
        {
             border-bottom: 0px solid #ccc;
             text-align: left;
             th
             {
                padding: 5px;
             }
        }
        p
        {
            margin: 5px;
        }
        .circle-travel
        {
            padding-top: 10px;
            border-radius: 19px;
            background: #61666f;
            width: 35px;
            height: 35px;
            color: #fff;
            text-align: center;
        }
        .traveller-heading
        {
            color: #006dbb;
            font: normal normal 14px/16px Gotham,Arial;
            font-weight: 600;
        }
        .traveller-heading--des
        {
            font: normal normal 14px/16px Gotham,Arial;
            color: #61666f;
        }
        
    }
    #nominee{
        
        .title{
            font-size: 1.51rem;
                @media screen and (max-width: 767px) 
                {   text-align: left ;
                    font-size: 1.3rem;
                }
        }
        .description{
            margin-bottom: 15px;
        }
        .member-nominees-container {
            @media screen and (max-width:767px) {
                overflow-x: scroll;
            }
            &::-webkit-scrollbar{
                display: none;
            }
        }
        table{
            border-collapse: collapse;
            th{
                background-color: #2c5dac;
                border-radius: 0;
                color: #fff;
                border: none;
            }
            th,td{
                border-right: 2px solid #fff;
                border-left: none;
                text-align: center;
                &:last-child{
                    border: none;
                    width: 128px;
                    text-align: left;
                }
                &:nth-child(2){   
                    width: 30%;
                    white-space: nowrap;
                }
            }
            td:nth-child(2){
                text-align: left;
            }
            tr{
                background-color: #e5e5e5;
                border-bottom: 3px solid white;
            }
        }
        select.nominee-status-deactivate {
            & + .selectize-control{
                .selectize-input{
                    padding: 0px;
                    position: absolute;
                    bottom: 2px;
                    right: 0px;
                    background: transparent;
                    border: none;
                    .item{
                        color: black;
                        background-color: #e5e5e5;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                    }
                    &::after{
                        right: 0px;
                        background-color: transparent;
                    }
                }
                .selectize-dropdown{
                    border: none;
                    border-radius: 0;
                    top: 5px!important;
                    margin-left: -5px;
                    width: calc(100% + 10px)!important
                }
                .option{
                    border-radius: 0;
                    background-color: #ccc;
                    color: black;
                    padding:5px;
                    font-size:16px;
                }
            }
        }
        .btn-addnominee--submit{
            margin-top: 30px!important;
            border-radius: 10px;
            width: 220px;
            height: 46px;
            margin-bottom: 15px;
        }
        @media screen and (max-width: 767px) {
            .members--nominee--form > div{
                padding:0;
            }
        }
    }
}
#nominee-deactivate-popup, #nominee-activate-popup{
    width: 356px;
    background-color: #fff;
    text-align:center;
    margin: 20px auto;
    padding: 20px;
    button{
        margin-top:10px;
    }
}