#mhupgrade-in--container {
  .mh-upgrade--container {
    // h3, p {
    //   margin-left: 5px;
    // }
    h3 {
      font-size: rem(24);
    }

    .AirlineBookingPNR {
      padding-right: 15px;
    }

    .mhUpgradeLastName {
      padding-left: 0;
      @media (max-width: 1024px) and (min-width: 768px) {
        padding-left: 15px;
      }
    }

    .mhUpgradeSubmitContainer {
      padding-left: 0;
    }
    input{
      text-transform: uppercase;
    }
  }
}

@include respond-to(tablet-portrait) {
  $mainnavheight: 76px;
  #mob-mhupgrade--container {
    padding: 0 12px 0 15px !important;
    p {
      width: 100%;
    }
    .col-lg-5,
    .col-lg-2 {
      width: 99% !important;
      margin: 0 0 15px 0 !important;
      padding: 0 !important;
    }
    .error-container {
      .col-lg-2 {       
        margin: 0 !important;        
      }
    }
  }
}
