.column-splitter--container{
  padding: 20px 6% 20px 8%;
  width: 100%;
  .row{
    .col-lg-4{
      width: 33.333%;
      &.col-fixed{
        width: 380px;
        margin-bottom: 30px !important;
        margin-right: 20px !important;
        &:last-child{
          margin-right: 0 !important;
        }
      }
    }
    &.col-centered{
      text-align: center;
      [class*='col-']{
        display: inline-block;
        float: none;
        margin: 0;
        padding: 0;
      }
    }
  }
  .signpost-col--splitter{
    margin: 0;
    padding: 0;
  }
  .signpost{
    .col-content--container{
      position: absolute;
      @include z-index(quicklinksIcon);
      width: 70%;
      bottom: 20px;
      left: 20px;
      text-align: left;
      h4{
        color: $white;
        @include font-size(30);
        margin-bottom: 10px;
        line-height: 1.25em;
      }
      p{
        @include font-size(16);
        color: $white;
        margin-bottom: 15px;
      }
      .btn-primary{
        width: auto;
        text-align: center;
      }
    }
    .singpost-img--wrap{
      position: relative;
      display: block;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        right: 0;
        height: 80%;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
      }
    }
  }
}

@include respond-to(tablet-portrait){
  .column-splitter--container{
    margin: 0 auto;
    padding: 0;
    .row{
      .col-lg-4{
        &.col-fixed{
          margin-right: 0 !important;
          &:last-child{
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

@include respond-to(375){
  .column-splitter--container{
    margin: 0 auto;
    padding: 0 10px;
    .row{
      .col-lg-4{
        &.col-fixed{
          margin: 0 0 20px !important;
        }
      }
    }
  }
}

@include respond-to(360){
  .column-splitter--container{
    .row{
      .col-lg-4{
        &.col-fixed{
          margin: 0 0 20px !important;
        }
      }
    }
  }
}
