.cug {
  #mab-bookingnav{
    display: none;
  }
  .cuglogin-component{
    .cug-logo{
      margin-bottom: 15px;
      width: auto;
      height: 60px;
    }
  }
}

#bookingNav{
  &.cug{
    .login-nav{
      width: 40% !important;
      li{
        float: right;
        img{
          display: none;
        }
      }
    }
    .navbar-nav{
      width: 60% !important;
    }
  }
}

[data-ibe--book-flight] {
  &.cug{
    .evo-autocomplete--static{
      display: none !important;
    }
    .recent-container{
      display: none !important;
    }
    .search-container {
      .col-lg-3 {
        width: 32%;
      }
      .col-lg-4 {
        width: 31%;
      }
      .col-lg-2,
      .col-lg-12 {
        // padding-left: 2px;
        //width: 22%;
        &.couponCode {
          display: none !important;
        }
      }
      .col-lg-1 {
        width: 17%;
      }
    }
  }
}

#mobile-bookflight--container,
[data-mob-ibe--book-flight] {
  &.cug{
    .search-container {
      .col-lg-12 {
        &.couponCode {
          display: none !important;
        }
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  $mainnavheight: 76px;
  #mab-bookingnav {
    .navbar {
      &.navbar-default {
        #bookingNav {
          .nav {
            &.navbar-nav {
              &.cug{
                li {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
