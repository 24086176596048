.enrich-revamp{
.banner-container-enrich
{
    z-index: 19;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    .content-holder-enrich
    {
        bottom: 48px;
        @media (max-width: 768px)
        {
            bottom: 25px;
        }
        margin: 0 8%;
        color: unset;
        z-index: 20;
        color: #fff;
        position: absolute;
        h1
        {
            color: #fff;
            font-family: Gotham-Book,Arial;
            font-size: 1.5rem;
            line-height: 2.25rem;

        }

    }
}
.enrich-about--links
{
    text-align: center;
    background: #f1f1f1;
    margin-bottom: 50px;
    .hvr-underline-from-left
    {
        padding: 10px;
    }
    @media (max-width: 768px)
    {
        margin-bottom: 0px;
        .filters
        {
            font-size: x-small;
        }
    }
}

.enrrich-about--content
{
    .btn-primary
    {
        width: auto;
        @media (max-width: 768px)
        {
            margin-left: 100px;
            padding-top: 5px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 11px;
            height: 26px;
            width: 85px;
        }
    }
    .enrich-point_leftcontent
    {
        // height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        p
        {
            font-family: Gotham-Book,Arial;
            color: black;
        }
        h5
        {
            margin: 0 0 0px;
            color: #005aaa;
        }
        @media (max-width: 768px)
        {
            margin: auto;
            width: 300px;
            padding: 10px 0px 10px 0px;
        }
    }
    .enrich-point_rightcontent
    {
        // height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: right;
        p
        {
            font-family: Gotham-Book,Arial;
            color: black;
        }
        h5
        {
            margin: 0 0 0px;
            color: #005aaa;
        }
    }
    table
    {
            white-space: initial !important; 
            border-collapse: collapse;
            font-family: arial;
            font-size: 11px;
            margin-top: 20px;
            // height: -webkit-fill-available;
            .enrich_leftallign
            {
                padding:0px 10px 0px 40px
            }
        @media (min-width: 767px)
        {
            width: 900px;
            .image-allignleft
            {
                vertical-align: middle; 
                margin: 0 0 30px 0;
                background: white;
                white-space: nowrap;
                padding-left: 20px;      
            }
            img
            {
                width: 250px;
                height: 250px;
            }
            p
            {
                font-size: 14px;
                margin-top: 10px;
            }
            .enrich_rightallign
            {
                padding:0px 40px 0px 10px
            }
            .image-allignright
            {
                padding-right: 20px; 
            }
        }
        @media (max-width: 768px)
        {
            .enrich_allign_mob
            {
                text-align: center;
            }
            .image-allignmob
            {
              padding:0px 40px 0px 10px  
            }
            h5
            {
                font-size: 12px;
            }
            p
            {
                font-size: 10px;
            }
            img
            {
                width: 300px;
                height: 300px;
                margin-top: 8px;
            }
        }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
        {
            width: 700px;
        }
        @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px)
        {
            width: 900px;
        }
    }
}
}