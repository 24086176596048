.rebate-mango-stores, .rebate-mango-stores-detail {
    .rebate-mango-container, .rebate-mango-store-container { 
        padding: 0 5%;  
        .image-richtext--editor {
            margin: 0;
            padding: 10px;
        }
        .retailer-title {
            text-align: center;
            font: normal bold 13px/14px Gotham-book, Arial;
            @include respond-to(ipad-portrait) {
                font: normal bold 12px/14px Gotham-book, Arial;
            }
            color: #404040;
            width: 100%;    
            display: block;   
            margin-bottom: 8px;
            line-height: 1.2;
            height: 37px;           
            width: 100%;
            padding: 0 10px;
            @include respond-to(tablet-portrait) {
                padding: 0px;
            }
            overflow: hidden; 
        }
        .retailer-offer-text {
            text-align: center;
            font: normal bold 12px/14px Gotham-book, Arial;
            @include respond-to(ipad-portrait) {
                font: normal bold 12px/14px Gotham-book, Arial;
            }
            color: #005aaa;
            width: 100%;    
            display: block;   
            margin-bottom: 8px;
            line-height: 1.2;
            height: 37px;           
            width: 100%;
            padding: 0 5px;
            @include respond-to(tablet-portrait) {
                padding: 0px;
            }
            overflow: hidden; 
        }
        .retailer-title-store {
            text-align: center;
            font: normal bold 16px/18px Gotham-book, Arial;
            @include respond-to(ipad-portrait) {
                font: normal bold 14px/16px Gotham-book, Arial;
            }
            color: #404040;
            width: 100%;    
            display: table;   
            margin-bottom: 8px;
            line-height: 1.2;
            height: 35px;           
            width: 100%;
            padding: 0 5px;
            @include respond-to(tablet-portrait) {
                padding: 0px;
            }
            overflow: hidden; 
        }
        .retailer-offer-text-store {
            text-align: center;
            font: normal bold 14px/16px Gotham-book, Arial;
            @include respond-to(ipad-portrait) {
                font: normal bold 12px/14px Gotham-book, Arial;
            }
            color: #005aaa;
            width: 100%;    
            display: table;   
            margin-bottom: 8px;
            line-height: 1.2;
            height: 35px;           
            width: 100%;
            padding: 15px 5px 0px 5px;
            @include respond-to(tablet-portrait) {
                padding: 10px 0px 0px 0px;
            }
            overflow: hidden; 
        }
        .imagerichtext {
            border: 1px solid $enrich-silver;
            margin-bottom: 30px;
            .img-responsive {
                display: block;
                max-width: 100%;
                height: auto;
                padding: 5px;
            }           
        }
        .imagerichtext-store {           
            margin-bottom: 30px;  
            @include respond-to(tablet-portrait) {
                margin-bottom: 10px; 
            }         
            .img-responsive-store {
                display: block;
                max-width: 100%;
                height: auto;
                padding: 5px;
                margin: 0 auto;
            }
        }
        .headertitle {
            margin: 0 0 20px;
            font: normal 700 20px/22px Gotham,Arial;
            padding-left: 15px;
        }
        .leftpanel {            
            position: fixed;
            z-index: 1000; 
            @include respond-to(tablet-portrait) {
                z-index: 95;
            }   
            overflow-x: hidden;
            padding: 8px 0;
            background: $white;
            min-width: 280px;
            top: 180px;
            border: 1px solid $enrich-silver; 
            @include respond-to(tablet-portrait) {
                position: relative; 
                margin-top: -280px;
            } 
            @media screen and (orientation:landscape) and (max-height: 480px) {
                position: relative; 
                margin-top: -280px;
            }                
        }
        .retailer-content {
            .retailer-desc {
                white-space: pre-line;
            }
            .retailer-tnc {
                white-space: pre-line;
            }
            .retailer-how-to-use {
                white-space: pre-line;
            }
        }
        @include respond-to(tablet-portrait) {
            .margin-space {
                margin-top: 180px;
            }
        }       
        .btn-login-retailer {
            padding: 20px;
        }
        .form-group {
            margin-bottom: 30px !important;
        }
        .rebatemango-noresults {
            color: #61666f;
            font: normal bold 24px/26px Gotham, Arial;
            text-align: center;
        }
        .padding-space {
            padding-left: 5px;
            padding-right: 5px;
        }
        .retailer-content{
            padding:30px;
            @include respond-to(tablet-portrait) {
                padding: 0px;
            }
          
           table > :nth-child(1)  {
             background-color:#f1f1ef; 
             color: #005aaa;
           }
           tbody{
            background-color:  rgb(241,241,239);
           }           
          
           .members__content{
             padding-left:0px;
             @include respond-to(tablet-portrait) {
                 padding-right: 0px;
             }
            // @include respond-to(tablet-portrait) {
             margin-left: auto;
             margin-right: auto;
             //}
             .miles-table{                 
                margin-top: 0;
                min-width: 100%;
                tr{
                 font-weight:400;
                 @include respond-to(tablet-portrait) {
                   font-weight:200;
                   padding-left: 0px !important;
                   padding-right: 0px !important;
                   font-size: 12px;
                 }
                 td, th{
                   font-weight:200;
                   font-size: 14px;
                   line-height: 1.4;
                   @include respond-to(tablet-portrait) {
                   font-size: 12px;
                   padding: 15px 8px 15px 5px !important;
         
                  }
         
                 }
                 td:first-child {
                     padding-left: 20px;
                 }
               }
               th{                
                 font-weight: 600 !important;
                 padding: 20px;                 
                 text-transform: none;
                 vertical-align: top;
                }  
             }
           }
         }
    }
    .rebatemango__results {
        margin-top: 20px;
        .results {
            display: block;
            position: relative;
            text-align: center;
        }
        .btn {
            display: block;
            position: relative;
            margin: 0 auto;
            margin-top: 30px;
            width: 150px;
    
            i.fa {
            margin-right: 10px;
            }
        }
    }
}
#rm-disclaimer-popup {
    position: relative;
    background: $white;           
    width: auto;
    max-width: 100%;
    padding: 20px 20px;
    margin: auto 5%;    
    .margin-space {
        margin-top: 30px;
        padding-left: 0px;
    }            
} 
