.banner-container {
  $bannerminheight: 400px;
  $bannermaxheight: 90vh;

  @include z-index(bannerbg);
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;

  @include respond-to(ipad-portrait){
    max-height: $bannermaxheight;
    min-height: auto !important;
  }

  @media screen and (max-width: 767px) {
    //min-height: $bannerminheight;
    height: calc(100vw / 750 * 562);
    &.slider{
      height: auto;
    }
  }

  .img-holder {
    @include z-index(bannerbg);
    position: relative;

    @include respond-to(ipad-portrait){
      max-height: $bannermaxheight;
      min-height: auto !important;
    }

    @media screen and (max-width: 767px) {
      min-height: $bannerminheight;

      .mobile-version {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

    a {
      display: block;
      height: 100%;
    }

      }
    }

    .img-responsive {

      @include respond-to(ipad-portrait){
        max-height: $bannermaxheight;
        min-height: auto;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }
  }

  // .content-holder css
  @import 'C006-007-008-content-holder';
}

