.white-popuplogin
{	
	position: relative;
    padding-left: 27px;
    background: #fff;
    padding: 20px;
    width: 356px;
    border-radius: 5px;
    max-width: 500px;
    margin: 20px auto;
	.btn-loginacc--remove,.btn-loginacc--cancel,.btn-loginacc--link
	{
		width: 160px;
		height: 46px;
		margin-left: 10px;
		margin-top: 10px;
		padding-top: 14px;
		padding-left: 2px;
	}
	.btn-loginacc--remove,.btn-loginacc--link
	{
		background: #ed1c24;
		border-radius: 10px;
		color: #fff;
	}
	.btn-loginacc--cancel
	{
		border-radius: 10px;
		color: #000;
		border: 1px solid #61666f;
	}
}

#members--lcplogin--details{
	padding-bottom: 125px;
.lcpconnected-account{
	opacity: 1;
	 height: 112px;
    margin-top: 10px;
    .logged-details
    {
        height: 46px;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: 460px;
    }

}
@media (min-width: 320px) and (max-width: 568px)  {
	.lcpconnected-account{
	height: 131px;
	}
}
.lcpconnected-account-details
{
	text-align: left;
	font: normal normal 14px/16px Gotham;
	letter-spacing: 0;
	color: #61666F;
	opacity: 1;
	
	@media (max-width: 768px) 
		{
			height: 55px;
		}
		.accountholder_mail
		{
			padding-top: 18px;
			display: inline-block;
			@media (max-width: 767px) 
			{
				padding-top: 6px;
			}
		}
}
.btn-disconnect
{
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #ED1C24;
	color: #ED1C24;
	border-radius: 30px;
	opacity: 1;
	width: 150px;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	float: right;
	
}
b
{
	margin-left: 15px;
}
.lcpconnected-account-text{
	text-align: left;
	font: normal normal 14px/16px Gotham-Book;
	letter-spacing: 0;
	color: #61666F;
	opacity: 1;
}
.user-details{
	margin-top: 15px;
	font-style: italic;
	b{
		margin-left: 0px;
	}
	@media (min-width: 768px) 
		{
			text-align: right;
		}
}
.connect-btn{
	color: #FAFAFA;
    width: 150px;
    height: 46px;
    background: #14467b 0 0 no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    text-align: center;
	padding: 13px 0px;
	float: right;
	cursor: pointer;
}
.change-email-btn{
	color: #006dbb;
    width: 150px;
    height: 46px;
    background: #FAFAFA 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
	padding: 13px 0px;
	border: 1px solid rgb(192, 189, 189);
}
input.form-control{
	border-radius: 10px;  
	width: 60%;
	color: #61666f;
	height: 40px;
}
.change-pwd-btn{
	color: #FAFAFA;
    width: 150px;
    background: #006dbb 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
	padding: 13px 0px;
	margin-right: 10px;

}
@media (min-width: 320px) and (max-width: 568px)  {
	.icon-fb{
		width: 27px;
		border-radius: 13px;
	}
	.user-details {
		margin-top: 3px;
		padding: 0;
		margin-top: 6px;
		height: 32px;
		span{
			font-size: 12px;
		}
		@media (max-width: 768px) 
		{
			text-align: right;
		}
	}
	b
	{
		margin-left: 9px;
		font-size: 12px;
		margin-top: 7px;
	}
	// .lcpconnected-account-details{
	// 	padding: 17px 5px;

	// }
	.lcpconnected-account {
	background: #fafafa 0 0 no-repeat padding-box;
	}
	.btn-disconnect {
		margin-top: 13px;
		width: 129px;
		float: right;
		border-radius: 25px;
	}
	.icon-google{
		padding: 4px;
		width: 31px;
		border-radius: 15px;
	}
	.connect-btn{
		margin-top: 13px;
		width: 129px;
		float: right;
		cursor: pointer;
		border-radius: 25px;
	}
	@media (width: 360px) and (height: 640px)  {
		.connect-btn{
		    margin-right: 12px;
		}
		.btn-disconnect{
			margin-right: 12px;
			
		}
	}

}
.login-details{
	margin-right: 6px;
}
.link_program,.login-detail
	{
		h3
		{
			color: #0157a7;
			font-size: 36px;
			@media (max-width: 767px) 
			{
				font-size: 30px;
			}
		}
        img
        {
            padding: 2px;
        }
        .row
        {
            margin-bottom: 15px;
			margin-left: 0px;
			@media (max-width: 767px) 
			{
				margin-bottom: 40px;
				margin-right: 0px;
			}
        }
        .col-lg-8,.col-md-7
        {
            padding-left: 5px!important;
        }
        .col-lg-8
        {
            border: 1px solid #ccc;
			border-radius: 10px;
			@media (min-width: 768px) 
			{
				height: 55px;
			}

		}
		.col-xs-10
        {
            border: 1px solid #ccc;
            border-radius: 10px;
        }
		h5
		{
			margin-top: 20px;
			color: #0157a7;
		}
		.fa-check
		{
            padding-top: 18px;
			padding-left: 10px;
			float: right;
			COLOR: #b1b3b8;
			@media (max-width: 768px) 
			{
				padding-top: 10px;
			}

		}
		.connect-btn,.btn-disconnect
				{
					font-size: 14px;
				}
		@media (max-width: 768px) 
			{
				.text_mob
				{
				 padding-top: 5px;
				}
				.connect-btn,.btn-disconnect
				{
					margin-top: 8px;
					width: 120px;
					float: left;
					cursor: pointer;
					border-radius: 25px;
					font-size: 14px;
					height: 30px;
					padding: 0;
					span
					{
						font-size: 10px;
						// vertical-align: super;
					}
				}
				.col-xs-6
				{
					padding: 0;
					padding-top: 10px;
					@media (min-width: 768px) and  (max-width: 1024px)
					{
						padding-top: 0px;
					}
				}
			}
	label
	{
		font-weight: 400!important;
		position: absolute;
		margin-top: -8px;
		background-color: #fff;
		margin-left: 14px;
		padding-left: 4px;
		padding-right: 4px;
	}
	}
	.lcp--acclogin-details
	{
		background-color: white;
    	padding-left: 17px;
		// padding-left: 0px;
		padding-bottom: 10px;
		padding-right: 25px;
		@media (max-width: 767px)
		{
			padding-bottom: 10px;
			padding-right: 17px;
		}
	}
	@media (max-width: 767px)
	{
		.accbutton_mob
		{
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	
}