// consolidated content holder css for these 3 components
// 1. image masthead
// 2. video masthead
// 3. slider masthead (image)
// to ensure content holder css is consolidateed throughou these 3 components

.content-holder {
  @mixin text-shadow-style {
    text-shadow: 0 1px 5px rgba($black, 0.30);
  }

  z-index: 20;
	width: 60%;
	margin: 0 10%;
	color: $white;
  position: absolute;
  bottom: 40px;

	@include respond-to(1270) {
		width: 65%;
	}

	@include respond-to(mid-screen-768-1200) {
		width: 80%;
	}

	@include respond-to(tablet-portrait) {
		width: auto;
		margin: 20px;
		top: initial;
		bottom: 20px;
	}

	@include respond-to(600) {
		width: auto;
		top: initial;
		bottom: 20px;
	}

	@include respond-to(500) {
		top: initial;
		bottom: 20px;
	}

	@include respond-to(414) {
		width: 80%;
		top: initial;
		bottom: 20px;
	}

	@include respond-to(375) {
		width: calc(100% - 40px - 30px); // -20px * 2 side margin and -15px * 2 .row margin
		top: initial;
		bottom: 20px;
	}

	@include respond-to(320) {
		width: calc(100% - 40px - 30px); // -20px * 2 side margin and -15px * 2 .row margin
		top: initial;
		bottom: 20px;
	}

	&.flip-clock {
		h1 {
			@include respond-to(375) {
				margin-bottom: 5px;
			}

			@include respond-to(320) {
				margin-bottom: 5px;
			}
		}
	}

	h1 {
		color: $white;
		line-height: 1em;
		text-align: left;
		margin-bottom: 0;

		@media screen and (min-width: 1201px) and (max-width: 1440px) {
			@include font-size(36);
			line-height: 1.2em;
			margin-bottom: 0;
		}

		@include respond-to(mid-screen-768-1200) {
			@include font-size(32);
			line-height: 1.2em;
			margin-bottom: 0;
		}

		@include respond-to(1100) {
			@include font-size(30);
			line-height: 1.2em;
			margin-bottom: 10px;
		}

		@include respond-to(1000) {
			@include font-size(26);
			line-height: 1.2em;
			margin-bottom: 5px;
		}

		@include respond-to(900) {
			@include font-size(20);
			line-height: 1.2em;
			margin-bottom: 5px;
		}

		@include respond-to(ipad-portrait){
			font-size: rem(24) !important;
			line-height: 1.2em !important;
			margin-bottom: 10px !important;
		}

		@include respond-to(tablet-portrait) {
			@include font-size(24);
			line-height: 1em;
			margin-bottom: 10px;
		}

		@include respond-to(600) {
			@include font-size(24);
		}

		@include respond-to(414) {
			line-height: 1em;
		}

		@include respond-to(320) {
			@include font-size(20);
			line-height: 1em;
			margin-bottom: 5px;
		}

		&.medium {
			font-family: $font-family-medium;
		}
	}

	p {
		@include font-size(16);
		color: $white;

		@include respond-to(320) {
			color: $white;
			@include font-size(14);
		}
	}

  h1,
  .class-type,
  .price,
  .book-by {
    @include text-shadow-style();
  }

	&.gray{
		h1, .class-type, .price, .book-by{
			color: $gray;
		}
	}

	&.blue{
		h1, .class-type, .price, .book-by{
			color: $blue;
		}
	}

	a {
		&.btn {
			@include border-radius(50px);
			margin-top: 15px;
			background: $linkblue;
			color: $white;
			border: 0;
			padding: 15px 40px;
			text-transform: initial;
			width: auto;
			min-width: 146px;

			@include respond-to(900) {
				margin-top: 5px;
			}

			@include respond-to(tablet-portrait) {
				display: none;
			}

			@include respond-to(600) {
				width: 50%;
			}

			@include respond-to(320) {
				width: 50%;
			}

			&:hover {
				background: $blue;
			}
		}
	}

	.class-type {
    @include font-size(24);
		font-family: $font-family-medium;

		@include respond-to(1100) {
			line-height: 1em;
		}

		@include respond-to(1000) {
			@include font-size(20);
			line-height: 1em;
		}

		@include respond-to(900) {
			@include font-size(16);
			line-height: 1em;
		}

		@include respond-to(tablet-portrait) {
			@include font-size(16);
		}

		@include respond-to(320) {
			@include font-size(14);
			font-family: $font-family-medium;
		}
	}

	.price {
		@include font-size(48);
		font-family: $font-family-medium;
		line-height: 1em;

		@media screen and (min-width: 1201px) and (max-width: 1440px) {
			@include font-size(36);
			line-height: 1em;
		}

		@include respond-to(1200) {
			@include font-size(36);
			line-height: 1em;
		}

		@include respond-to(1000) {
			@include font-size(30);
			line-height: 1em;
		}

		@include respond-to(900) {
			@include font-size(22);
			line-height: 1em;
		}

		@include respond-to(tablet-portrait) {
			@include font-size(40);
			line-height: 1em;
			margin-bottom: 10px;
		}

		@include respond-to(375) {
			@include font-size(30);
			line-height: 1em;
			margin-bottom: 5px;
		}

		@include respond-to(320) {
			@include font-size(30);
			font-family: $font-family-medium;
			line-height: 1em;
			margin-bottom: 5px;
		}

		@include respond-to(320) {
			@include font-size(24);
			line-height: 1em;
			margin-bottom: 5px;
		}
	}

	.book-by {
		@include font-size(16);

		@include respond-to(tablet-portrait) {
			@include font-size(12);
		}

		@include respond-to(320) {
			@include font-size(12);
		}
		@include respond-to(ipad-portrait){
			margin-bottom: 15px;
		}
	}

	.mobile-version {
		@include respond-to(tablet-portrait) {
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
