/**
 * typography.scss
*/
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 20px;
    color: $gray; // Default color
    font-family: $font-family;
    font-weight: normal;
    &.bold {
        font-family: $font-family-medium;
    }
}

h1 {
    font-family: $font-family;
    @include font-size(48);
    // color: $gray;
    color: $focusblue;
    @include respond-to(tablet-portrait){
        @include font-size(36);
    }
    @include respond-to(ipad-portrait){
        font-size: rem(36) !important;
        line-height: 1.5em !important;
    }
}

h2 {
    font-family: $font-family;
    @include font-size(36);
    color: $focusblue;
    @include respond-to(tablet-portrait){
        @include font-size(30);
    }
    @include respond-to(ipad-portrait){
        font-size: rem(30) !important;
        line-height: 1.5em !important;
    }
}

h3 {
    font-family: $font-family;
    @include font-size(30);
    color: $gray;
    @include respond-to(tablet-portrait){
        @include font-size(24);
    }
    @include respond-to(ipad-portrait){
        font-size: rem(24) !important;
        line-height: 1.2em !important;
    }
}

h4 {
    font-family: $font-family;
    @include font-size(24);
    color: $gray;
    @include respond-to(tablet-portrait){
        @include font-size(18);
    }
    @include respond-to(ipad-portrait){
        font-size: rem(18) !important;
        line-height: 1em !important;
    }
}

h5 {
    font-family: $font-family;
    @include font-size(18);
    color: $gray;
    @include respond-to(tablet-portrait){
        @include font-size(16);
    }
}

h6 {
    font-family: $font-family;
    @include font-size(16);
    color: $gray;
    @include respond-to(tablet-portrait){
        @include font-size(14);
    }
}

small {
  font-family: $font-family;
  font-size: 1em;
  line-height: (12/10);
}

p, span, li {
    line-height: (20/14);
    &.bold, strong {
        font-family: $font-family-medium;
    }
    // li {
    //     font-size: rem(14/16);
    //     line-height: (18/14);
    // }
}

strong {
    font-family: $font-family-medium
}

blockquote{
    width: 350px;
    // max-height: 217px;
    margin: 0;
    padding: 30px 0 0 0;
    font-style: italic;
    font-size: rem(16);
    color: #666;
    text-align: center;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%2292%22%20viewBox%3D%220%200%2092%2092%22%3E%3Cpath%20fill%3D%22%23E3E3E3%22%20d%3D%22M32.906%2025.511c-2.364%201.954-4.313%204.266-5.854%206.933-1.541%202.672-2.773%205.599-3.697%208.781-.924%203.187-1.801%206.422-2.619%209.705h12.94l-7.395%2032.352h-25.881l10.167-42.827c2.465-9.652%206.21-16.739%2011.246-21.26%205.031-4.516%2012.579-8.011%2022.646-10.476l-2.465%2012.324c-3.697%201.03-6.729%202.518-9.088%204.468zm47.141%200c-2.365%201.954-4.314%204.266-5.855%206.933-1.539%202.672-2.771%205.599-3.697%208.781-.924%203.187-1.801%206.422-2.619%209.705h12.941l-7.395%2032.352h-25.881l10.168-42.827c2.465-9.652%206.209-16.739%2011.246-21.26%205.029-4.516%2012.578-8.011%2022.645-10.476l-2.465%2012.324c-3.697%201.03-6.731%202.518-9.088%204.468z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-size: 30px;
    span{
        padding: 0 40px 30px;
        display: block;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%2292%22%20viewBox%3D%220%200%2092%2092%22%3E%3Cpath%20fill%3D%22%23E3E3E3%22%20d%3D%22M59.094%2066.489c2.364-1.954%204.314-4.266%205.854-6.933%201.54-2.672%202.772-5.599%203.697-8.781.925-3.187%201.801-6.422%202.619-9.705h-12.94l7.395-32.352h25.881l-10.168%2042.828c-2.465%209.652-6.21%2016.739-11.246%2021.26-5.03%204.516-12.578%208.011-22.645%2010.476l2.465-12.324c3.697-1.031%206.729-2.519%209.088-4.469zm-47.141%200c2.365-1.954%204.314-4.266%205.855-6.933%201.539-2.672%202.771-5.599%203.697-8.781.924-3.187%201.801-6.422%202.619-9.705h-12.94l7.395-32.352h25.881l-10.169%2042.828c-2.465%209.652-6.209%2016.739-11.246%2021.26-5.029%204.515-12.578%208.01-22.645%2010.475l2.465-12.324c3.697-1.03%206.731-2.518%209.088-4.468z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 30px;
    }

}

.text-center{
    text-align: center;
}
.text-right {
    text-align: right;
}

a{
    color: $linkblue;
    text-decoration: none;
    &.active{
        border: 1px dashed $linkblue;
    }
}

/** Text font colours **/

@include thailand {
  h1, h2, h3, h4, h5, h6 {
    font-family: Arial Unicode MS, Arial, sans-serif;
  }
}

@include vietnam {
  h1, h2, h3, h4, h5, h6 {
    font-family: Arial Unicode MS, Arial, sans-serif;
  }
}

@include korea {
  h1, h2, h3, h4, h5, h6 {
    font-family: Arial Unicode MS, Arial, sans-serif; 
  }
}

@include japan {
  h1, h2, h3, h4, h5, h6 {
    font-family: MS PGothic, sans-serif;
  }
}

@include china {
  h1, h2, h3, h4, h5, h6 {
    font-family: Arial Unicode MS, Arial, sans-serif;
  }
}
