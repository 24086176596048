/**
 * Members Portal
 * - index
 */
#members-index-body {
  @media screen and (max-width: 767px) {
    margin-top: -20px;
  }
}

.members--enrich-status {
  // margin-top: 30px;

  .members--enrich--status-card,
  .members--enrich--progress {
    margin-bottom: 20px;
  }

  .members--enrich--status-card {
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .title {
    font-family: $font-family-medium;
    font-weight: 600;
    font-size: rem(20);
    line-height: 1;
    color: $gray;
    margin-bottom: 5px;
  }

  .member-type {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .member-card {
    margin-top: 20px;

    $card-image-size: 350px;
    $card-image-margin: 30px;

    .card-item {
      @media screen and (min-width: 768px) {
        width: $card-image-size;
        margin-right: $card-image-margin;
        float: left;

        img {
          width: 100%;
        }
      }
    }

    .card-detail-container {
      @media screen and (min-width: 768px) {
        width: calc(100% - (#{$card-image-size + $card-image-margin}));
        float: left;
      }
    }
  }

  .card-details {
    list-style: none;
    padding-left: 0;

    margin-top: 10px;
    @media screen and (max-width: 767px) {
    }

    .detail {
      font-size: rem(14);

      label {
        font-family: $font-family-medium;
        font-weight: 600;
        margin-right: 5px;

        &:after {
          content: ":";
        }
      }
    }
  }

  .progress-overview {
    .description {
      max-width: 420px;
      margin-bottom: 15px;
      font-size: rem(14);
    }

    .btn-member--cta {
      margin-top: 0;
    }
  }

  .progress-details {
    margin-top: 15px;
    padding-left: 0;
    list-style: none;

    .progress-item--info,
    progress {
      display: block;
      width: 100%;
    }

    progress {
      height: 16px;
    }

    .progress-item--info {
      @supports(display: flex) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }

      .progress-label,
      .progress-value {
        font-size: rem(14);
      }

      .progress-value {
        float: right;

        @supports(display: flex) {
          float: none;
        }
      }
    }
  }

  .progress-item--container {
    margin-top: 5px;
    margin-bottom: 20px;

    .nanobar {
      height: 20px;
      border-radius: 20px;
      overflow: hidden;
      background-color: $seashell;

      .bar {
        background-color: $blue;
      }
    }
  }

  .progress-item--container .nanobar .bar {
    background-color: $enrich-neutral;
  }

  .member--gold & {
    .progress-item--container .nanobar .bar {
      background-color: $enrich-gold;
    }
  }

  .member--silver & {
    .progress-item--container .nanobar .bar {
      background-color: $enrich-silver;
    }
  }

  .member--platinum & {
    .progress-item--container .nanobar .bar {
      background-color: $enrich-platinum;
    }
  }

  .member--blue & {
    .progress-item--container .nanobar .bar {
      background-color: $enrich-blue;
    }
  }
}

.members--recent-activity {
  margin-top: 20px;
  margin-bottom: 30px;

  .title {
    margin-top: 0;
  }

  .members-recent-activity-table {
    .flight-info .flight-id {
      font-size: rem(24);
      color: $countdownblue;
      text-transform: uppercase;
      margin-right: 4px;
    }
  }
}

.members-recent-activity-actions {
  margin-top: -10px;

  .btn-member--cta {
    margin-top: 0;
  }
}

.members--carousel,
.members--banner {
  @media screen and (max-width: 767px) {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
}

.members--carousel {
  margin-bottom: 30px;

  .seq {
    $sidebar: 300px;
    $sidebar-margin: 20px;
    $page-margin: 20px;
    $fulloffset: 240px;

    width: calc(100vw - #{$sidebar} - #{$sidebar-margin} -  20px - #{$fulloffset});
    height: calc((100vw - #{$sidebar} - #{$sidebar-margin} -  20px - #{$fulloffset}) * 0.5625);

    @media screen and (min-width: 1441px) {
      max-width: calc(100vw - #{$sidebar} - #{$fulloffset});
      max-height: calc((100vw - #{$sidebar} - #{$fulloffset}) * 0.5625);
    }

    @media screen and (min-width: 1201px) and (max-width: 1440px) {
      width: calc(100vw - #{$sidebar} - #{$page-margin});
      height: calc((100vw - #{$sidebar} - #{$page-margin}) * 0.5625);

      max-width: calc(1200px - #{$sidebar} - #{$page-margin});
      max-height: calc((1200px - #{$sidebar} - #{$page-margin}) * 0.5625);
    }

    @media screen and (max-width: 1200px) {
      width: calc(100vw - #{$page-margin} -  30px);
      height: calc((100vw - #{$page-margin} -  30px) * 0.5625);

      max-width: calc(1200px - #{$page-margin});
      max-height: calc((1200px - #{$page-margin}) * 0.5625);
    }

    @media screen and (max-width: 767px) {
      width: calc(100vw);
      height: calc((100vw) * 0.5625);

      max-width: calc(1200px );
      max-height: calc((1200px ) * 0.5625);
    }

    button {
      display: none;
    }
  }
}
