.component-library--container{
	margin: 30px 0;
	.batch-container{
		display: block;
		clear: both;
		margin-bottom: 30px;
		h4{
			margin-bottom: 0;
		}
		.batch-content--title{
			width: 100%;
		    display: block;
		    height: auto;
		    overflow: hidden;
		    background: $blue;
		    color: $white;
			.batch-head {
				float: left;
				border-right: 1px solid $white;
				padding: 10px 20px;
				width: 33.3333%;
			}
		}
		.batch-content--content{
			width: 100%;
		    display: block;
		    height: auto;
		    overflow: hidden;
		    .batch-pages{
		    	background: $seashell;
		    	width: 100%;
			    display: block;
			    height: auto;
			    overflow: hidden;
			    &:nth-of-type(even){
			    	background: $lightgray;
			    }
			    .batch-body {
					float: left;
					border-right: 1px solid $white;
					padding: 10px 20px;
					width: 33.3333%;
					ul{
						margin: 0;
						padding: 0;
						li{
							list-style-type: none;
							float: left;
							margin-right: 10px;
							width: 58px;
						}
					}
					&:nth-child(1),&:nth-child(2){
						padding: 10px 20px 30px 20px;
					}
				}
			}
		}
	}
}