/**
 * Members Portal
 * - transfer miles
 */

.members--mymiles--transfer-miles {
  .outstanding-miles {
    &.form-group {
      margin-bottom: 30px;
    }

    .label {
      margin-right: 10px;

      &:after {
        content: ':';
      }
    }

    .value {
      font-family: $font-family-medium;
      font-weight: 600;
    }

    .label,
    .value {
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }

  #miles-to-transfer {
    background-color: $white;
  }

  #miles-to-transfer-slider-label {
    font-weight: 600;
    font-family: $font-family-medium;
  }

  .miles-transfer-summary {
    .outstanding-miles {
    .label {
      margin-right: 20px;

      &:after {
        content: ':';
      }
    }

    .value {
      font-family: $font-family-medium;
      font-weight: 600;
    }
  }

  .miles-table {

    .miles-description {
      .label {
        display: block;
        font-size: rem(12);
      }
    }

    .unit-cost {
      text-align: center;
    }

    .subtotal {
      text-align: right;
    }
  }
  }
}
