@import 'variables';

// customizations for slick carousel
$slick-prev-character: '\f104';
$slick-next-character: '\f105';
$slick-arrow-color: $gray;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-arrow-size: 24px;
/* Arrows */

.slick-prev,
.slick-next {
    display: block;
    position: absolute;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    outline: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    width: $slick-arrow-size;
    height: $slick-arrow-size;
    line-height: 0;
    color: transparent;
    font-size: 0;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        opacity: $slick-opacity-default;
        line-height: 1;
        color: $slick-arrow-color !important;
        font-family: $slick-font-family;
        font-size: $slick-arrow-size;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
}

.slick-prev {
    &:before {
        font-family: 'FontAwesome';
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    &:before {
        font-family: 'FontAwesome';
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}
