a{
	text-decoration: none;
	&:hover, &:focus, &:active{
		text-decoration: none;
	}
}

/* Hover Underline from left */
.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		right: 100%;
		bottom: 0;
		background: $red;
		height: 2px;
		-webkit-transition-property: right;
		transition-property: right;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}
	&:hover, &:focus, &:active{
		&:before{
			right: 0;
		}
	}

}

/* Hover Underline from left - Dashed */
.hvr-underline-from-left-dashed {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		right: 100%;
		bottom: 0;
		border-bottom: 1px dashed $linkblue;
		-webkit-transition-property: right;
		transition-property: right;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}
	&:hover, &:focus, &:active{
		&:before{
			right: 0;
		}
	}
}

/* Overline From Left */
.hvr-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  &:before {
	  content: "";
	  position: absolute;
	  z-index: -1;
	  left: 0;
	  right: 100%;
	  top: 0;
	  border-top: 2px solid $red;
	  -webkit-transition-property: right;
	  transition-property: right;
	  -webkit-transition-duration: 0.3s;
	  transition-duration: 0.3s;
	  -webkit-transition-timing-function: ease-out;
	  transition-timing-function: ease-out;
	}
	&:hover, &:focus, &:active{
		&:before{
			right: 0;
		}
	}
}

/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border-radius: 50px !important;
  	&:before {
	  content: "";
	  position: absolute;
	  z-index: -1;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  background: $red;
	  -webkit-transform: scaleX(0);
	  transform: scaleX(0);
	  -webkit-transform-origin: 0 50%;
	  transform-origin: 0 50%;
	  -webkit-transition-property: transform;
	  transition-property: transform;
	  -webkit-transition-duration: 0.3s;
	  transition-duration: 0.3s;
	  -webkit-transition-timing-function: ease-out;
	  transition-timing-function: ease-out;
	  border-radius: 50px !important;
	}
	&:hover, &:focus, &:active{
  		color: white;
  		&:before {
		  -webkit-transform: scaleX(1);
		  transform: scaleX(1);
		}
  	}
}

/* Rectangle Out */
.hvr-rectangle-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  	&:before {
	  content: "";
	  position: absolute;
	  z-index: -1;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  background: #2098D1;
	  -webkit-transform: scale(0);
	  transform: scale(0);
	  -webkit-transition-property: transform;
	  transition-property: transform;
	  -webkit-transition-duration: 0.3s;
	  transition-duration: 0.3s;
	  -webkit-transition-timing-function: ease-out;
	  transition-timing-function: ease-out;
	}
	&:hover, &:focus, &:active{
  		color: white;
  		&:before {
		  -webkit-transform: scale(1);
		  transform: scale(1);
		}
  	}
}

/* Icon Forward */
.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  	&:before {
	  top: 2px;
	  right: 0;
	  content: "\f105";
	  position: absolute;
	  right: 5px;
	  padding: 0 1px;
	  font-family: FontAwesome;
	  @include font-size(24);
	  line-height: 1em;
	  color: $linkblue;
	  -webkit-transform: translateZ(0);
	  transform: translateZ(0);
	  -webkit-transition-duration: 0.1s;
	  transition-duration: 0.1s;
	  -webkit-transition-property: transform;
	  transition-property: transform;
	  -webkit-transition-timing-function: ease-out;
	  transition-timing-function: ease-out;
	}
	&:hover, &:focus, &:active{
		&:before{
			-webkit-transform: translateX(5px);
  			transform: translateX(5px);
		}
	}
}

.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $blue;
		border-radius: 100%;
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	  &:hover, &:focus, &:active{
	  	color: white;
	  	&:before{
	  	  -webkit-transform: scale(2);
	  	  transform: scale(2);
	  	}
	  }
	}
}
