.helptopic {
  $container-width: 1180px + 15px + 15px;

  @mixin help-heading-style {
      color: $white;
      font-size: rem(24);
      line-height: rem(28);
  }

  max-width: $container-width;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (min-width: 769px) and (max-width: #{$container-width - 1px + 20px + 20px}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include respond-to(767) {
    padding-bottom: 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      color: $countdownblue;
      line-height: 1;
      margin: 0;
    }
  }

  &__card {
    margin-bottom: 40px;
    display: block;
    width: 100%;
    float: left;

    @include respond-to(767) {
      margin-bottom: 1px;
    }
  }

  &__heading {
    $icon-width: rem(48);

    display: block;
    background-color: $blue;
    padding: 10px 20px;
    position: relative;
    width: 100%;
    float: left;
    min-height: #{$icon-width + rem(20) + rem(20)};

    @supports(display: flex) {
      float: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
              align-items: center;
      min-height: #{64px + 20px + 10px};
    }

    .icon {
      display: block;
      position: absolute;
      float: left;
      width: $icon-width;
      height: $icon-width;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;

      @supports(display: flex) {
        position: relative;
        float: none;
        margin-right: 20px;
        top: initial;
        transform: translateY(0);
      }

      i.fa {
        line-height: 1;
        font-size: $icon-width;
        color: $white;
      }
    }

    .title {
      @include help-heading-style;

      display: block;
      float: left;
      width: calc(100% - #{rem(30)} - #{$icon-width} - #{rem(20)});
      font-family: $font-family-light;
      margin: 0;
      margin-left: #{rem(30) + $icon-width};

      @supports(display: flex) {
        margin-left: 0;
      }
    }

    .topic__toggle {
      @include help-heading-style;

      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
      width: 20px;
      text-align: right;
      transform: translateY(-50%);
    }
  }

  &__content {
    float: left;
    width: 100%;

    @include respond-to(767) {
      display: none;
    }
  }

  &__subtopics {
    list-style: none;
    margin: 0;
    padding: 0;

    .subtopic__item {
      position: relative;
      border-bottom: solid 1px $lightgray;

      a {
        padding: 20px;
        display: block;
        width: 96%;
        &.hvr-icon-forward{
          &:before{
            top: 18px;
          }
        }
      }

      .text {
        display: inline-block;
        width: calc(100% - 20px);
        color: $black;
      }
    }

    .subtopic__toggle {
      position: absolute;
      right: 20px;
      width: 20px;
      top: 50%;
      text-align: right;
      transform: translateY(-50%);
    }
  }
}
