.form-control{
  border-radius: 50px;
  color: $blue;
  box-shadow: none;
  background: #fff;
  cursor: pointer;
  @include font-size(13);
  padding: 14px 20px 13px;
  box-shadow: none;
  &.has-error{
    border: 1px solid $errorred;
  }
  &:focus{
    border: 1px solid $blue;
  }
}
select.form-control {
  -webkit-appearance: none;
}
.selectize-control{
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 50px;

  &.input-lg {
    height: 46px;
  }

  .form-control{
    border: 0;
  }

  .selectize-input {
    display: inline-block;
    float: left;
    border: 0;
    box-shadow: none;
    border-radius: 25px;
    border: 1px solid $lightgray;
    padding: 14px 20px 13px;

    &.has-error {
      border: 1px solid $errorred;
    }

    &.input-active {
      &.dropdown-active {
        border: 1px solid $blue;
        border-radius: 25px 25px 0 0;
        //margin-top: -1px;
      }
    }

    &.select-filled {
      padding: 22px 20px 5px;
    }

    .item {
      color: $blue;
      @include font-size(13);
      line-height: rem(18);

    }
  }

  .selectize-dropdown {
    box-shadow: none;
    border-radius: 0 0 25px 25px;
    border: 1px solid $blue;
    box-shadow: none;
    height: auto;
    @include font-size(13);
    padding: 0;
    overflow: hidden;
    [data-selectable]{
      @include font-size(13);
      padding: 8px 26px;
      &:last-child{
        border-bottom: 0;
      }
    }

    .optgroup-header {
      @include font-size(13);
      padding: 8px 15px;
      border-bottom: 1px solid $lightgray;
    }

    .option {
      color: $blue;
    }

    .active {
      background-color: $blue;
      color: $white;
    }

    .selectize-dropdown-content {
      max-height: 270px;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
          -webkit-border-radius: 0;
          border-radius: 0;
      }

      &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 10px;
          border-radius: 10px;
          background: rgba(0,109,187,1);
          -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
      }
    }

    .optgroup {
      .optgroup-header {
        color: $darkgray;
        text-transform: uppercase;
        font-family: $font-family-medium;
        @include font-size(13);
      }
    }

    .selectize-dropdown-content{
      max-height: 255px;

      @media screen and (max-width: 767px) {
        max-height: 240px;
      }
    }
  }

  &.single {
    .selectize-input {
      &:after {
        content: "\f107";
        font-family: 'FontAwesome';
        line-height: 2;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        background-color: $white;
        // padding-right: 2px;
        border: 0;
        @include font-size(16);
        color: $blue;
      }

      &.dropdown-active {
        &:after {
          content: "\f106";
          font-family: 'FontAwesome';
          background-color: $white;
          // padding-right: 2px;
        }
      }

      &.has-error {
        &:after {
          color: $errorred;
        }
      }
    }
  }
}

.ibe-form--container {
  .selectize-dropdown {
    [data-selectable]{
      padding: 8px 20px;
    }
  }

  .selectize-control {
    &.input-lg {
      height: 48px;
    }
  }
}

.ui-datepicker {
  @include font-size(14);
  border: 1px solid $lightgray;
  background-color: lighten($seashell, 3.6%);
  box-shadow: none;
  padding-bottom: 25px;
  display: none;
  margin-top: 10px;
  @include border-radius(30px);
  @media screen and (max-width: 479px) {
    border-radius: 0 !important;
  }

  &:before{
    content: " ";
    position: absolute;
    display: inline-block;
    top: -11px;
    left: 75px;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    border-bottom: 11px solid $lightgray;
  }

  &:after {
    content: " ";
    position: absolute;
    display: inline-block;
    top: -9px;
    left: 76px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid lighten($seashell, 3.6%);
  }

  &.ui-datepicker-multi-2 {
    @media screen and (min-width: 767px) {
      width: 765px !important;
    }
  }

  &:not([class*='ui-datepicker-multi-']) {
    .ui-datepicker-calendar {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &.multicityTrip {
    &:before {
      content: " ";
      position: absolute;
      display: inline-block;
      top: -11px;
      right: 369px;
      left: 369px;
      border-right: 11px solid transparent;
      border-left: 11px solid transparent;
      border-bottom: 11px solid $lightgray;
    }
    &:after {
      content: " ";
      position: absolute;
      display: inline-block;
      top: -9px;
      left: 370px;
      right: 370px;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid $white;
    }
  }
  .ui-datepicker-current { display: none; }
  .ui-datepicker-title-text {
    padding: 10px 0;
    text-align: center;
    font-weight: 600;
    font-size: rem(14);
    font-family: $font-family-medium;
    color: $blue;
  }
  .ui-datepicker-buttonpane {
    z-index: 12;
    position: absolute;
    top: 0;
    height: 41px;
    left: 0;
    width: 100%;

    .ui-datepicker-close {
      background-color: transparent;
      border-color: transparent;
      color: $countdownblue;
      position: absolute;
      top: 5.5px;
      right: 10px;
      border: 0;
      padding: 4px 8px 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  table{
    tr{
      th{
        border-bottom: 1px solid $lightgray;
        color: $gray;
        padding-bottom: 5px;
      }

      td {
        border-radius: 0;
        border: 1px solid #FAFAFA;
        background: $white;
        border-radius: 0px !important;
        text-align: center;
        padding: 0;
        &.range {
          background: $calendarSelected !important;
          border: 1px solid $white !important;
          color: #fff !important;
        }

        &.selected {
          background: $calendarSelected !important;
          border: 1px solid $white !important;
        }

        &.active {
          background: $calendarSelected !important;
          border: 1px solid $white !important;
        }

        span,  a {
          color: $gray;
          display: block;
          padding: 10px 14px;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  .ui-datepicker-calendar {
    tbody {
      margin-top: 5px;
    }
  }

  .ui-datepicker-month {
    margin-right: 5px;
  }

  .ui-datepicker-group {
    float: left;
    margin-left: 30px;

    &.ui-datepicker-group-last {
      margin-left: 40px;
    }
  }

  .ui-datepicker-title {
    font-family: $font-family-medium;
    color: $blue;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 18px;
    /* MABUAT1-69 */
    width: calc(100% - 15px);
    /* MABUAT1-69 */
  }

  .ui-datepicker-other-month {
    &.ui-datepicker-unselectable {
      &.ui-state-disabled {
        background: #FAFAFA !important;
      }
    }
  }

  .ui-state-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  td.ui-state-disabled {
    background: $alto;
  }

  .ui-datepicker-today a {
    border: solid 3px $countdownblue !important;
    padding: 7px 11px;
  }

  .ui-datepicker-next {
    position: absolute;
    right: 20px;
    top: 10px;
    @media all and (device-width: 768px) and (device-height: 1024px) 
    {
        top: 51px;
    }
    @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (-webkit-min-device-pixel-ratio: 1.5)
    {
      top: 51px;
    }
    cursor: pointer;
    font-size: 0;
    span{
      &:before{
        content: "\f138" !important;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include font-size(32);
      }
    }
  }

  .ui-datepicker-prev {
    position: absolute;
    left: 20px;
    top: 10px;
    @media all and (min-device-width: 768px) and (max-device-width: 1024px) 
    {
        top: 51px;
    }
    @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (-webkit-min-device-pixel-ratio: 1.5)
    {
      top: 51px;
    }
    cursor: pointer;
    font-size: 0;
    span{
      &:before{
        content: "\f137" !important;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include font-size(32);
      }
    }
  }

  &.ui-datepicker-title-text {
    .ui-datepicker-next,
    .ui-datepicker-prev {
      top: 41px + 10px;
    }
  }

  .ui-datepicker-title-text {
    + .ui-datepicker-group + .ui-datepicker-group .ui-datepicker-next,
    + .ui-datepicker-group .ui-datepicker-prev {
      top: 41px + 10px;
    }
  }

  .ui-state-selected,
  .highlight {
    background: $calendarSelected !important;
    border: 1px solid $white !important;
    color: #fff !important;

    a {
      color: #fff !important;
    }
  }

  a.ui-state-hover,
  .ui-datepicker-current-day a.ui-state-active {
    background: $calendarSelected;
    color: #fff;

    a {
      color: #fff !important;
    }
  }

  .ui-datepicker-prev-hover,
  .ui-datepicker-next-hover {
    background: none !important;
    color: $blue !important;
  }
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-control{
  &:focus{
    outline: 0 !important;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $blue;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}

label{
  font-weight: normal !important;
}

.textareaHide{
  display: none !important;
}

@include respond-to(tablet-portrait) {
  .ui-datepicker {
    width: 100vw !important;
    height: 100%;
    left: 0 !important;
    top: -10px !important;
    z-index: 2000 !important;
    // MABUAT1-69
    position: fixed !important;
    overflow-y: auto;
    // MABUAT1-69
    &:before,
    &:after {
      display: none;
    }
    .ui-datepicker-group {
      width: calc(100% - 15px);
      margin-left: 15px !important;
      .ui-datepicker-calendar {
        width: calc(100% - 15px);
      }
      &.ui-datepicker-group-last {
        margin-top: 15px;
      }
    }
  }
}

@include respond-to(320) {
  .ui-datepicker {
    height: 100%;
    left: 0 !important;
    z-index: 300 !important;
    &:before,
    &:after {
      display: none;
    }
    table {
      tr {
        td {
          span {
            padding: 6px 12px;
          }
          a {
            padding: 6px 12px;
          }
        }
      }
    }
    .ui-datepicker-group {
      // MABUAT1-69
      width: calc(100% - 8px);
      // MABUAT1-69
      margin-left: 8px !important;
      .ui-datepicker-calendar {
        // MABUAT1-69
        width: calc(100% - 8px);
        // MABUAT1-69
      }
      &.ui-datepicker-group-last {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ui-datepicker {
    z-index: 1044!important;
  }
}  


.member--gold, .member--platinum, .member--silver, .member--blue, .member--BLUE, .member--SILV, .member--GOLD, .member--PLAT,.member--PLATP 
{
  .ui-datepicker:not(.ui-datepicker-multi)
  {
    .ui-datepicker-header
      {
          .ui-datepicker-prev
            {
		           margin-top: 51px;
				    }
        .ui-datepicker-next
           {
             margin-top: 51px;
           }
        .ui-datepicker-title
          {
          margin-top: 70px;
          }
      }
    }

    background-image: url('https://www.malaysiaairlines.com/content/dam/mas/enrich/lcp/LCP-flower-bg-1920x1390.png')!important;   
    #main-content {
      background-color: unset;
    }
  }

