// table css using lcp-benefit.scss
#members--messages {
    .title {
        float: left;
    }
    .clear_link {
        padding: 12px;
        float: right;
        text-decoration: underline;
        color: $blue;
        font-weight: 400;
        cursor: pointer;
    }
    .clear_both {
        clear: both;
    }
    .fa {
        cursor: pointer;
    }
}
// pop up  css 
.mfp-inline-holder {
    .mfp-content {
        #messages--delete-popup {
            max-width: 400px;
            text-align: center;
            margin: 0 auto;
            background-color: $white;
            border-radius: 5px;
            .title {
                padding: 25px;
            }
            .body {
                padding: 5px 30px 30px
            }
        }
    }
}
.message-btn {
    width: 35%;
}
.message-btn-primary {
    background: #6EAFD7 !important;
    padding: 6px 0px;
    border: none;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
}
.msg-btn-all {
    padding: 6px;
}
#member-contact {
    .bold {
        font-weight: 600;
    }
    .contact-info {
        padding-bottom: 30px;
    }
}
#member-contact-form {
    label {
        position: absolute;
        margin-top: -10px;
        background-color: $white;
        padding-left: 5px;
        padding-right: 6px;
    }
    .form-group {
        &.member--contact-subject {
            width: 58%;
            margin-right: 1%;
        }
        &.member--contact-category {
            width: 41%;
        }
        &.member--contact-upload {
            width: 80%
        }
        &.contact-form--submit {
            width: 20%
        }
    }
    .member--contact-upload {
        .group-span-filestyle {
            label {
                position: relative;
            }
        }
        .group-span-filestyle  {
            margin-top: 10px;
        }
        .form-control {
            background: $white;
        }
    }
}
// media queries
@media screen and (max-width: 767px) {
    #members--messages {
        .title {
            padding-top: 13px;
        }
    }
}