.feedback-form--container{

.feedback-button {
  position: fixed;
  height: 61px;
  border: solid 3px #662E87;
  // background: #3E810C;
  background: #662E87;  
  width: 100px;
  line-height: 37px;
  -webkit-transform: rotate(-90deg);
  font-weight: 600;
  color: white;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  text-align: center;
  font-size: 17px;
  position: fixed;
  top:550px ! important;
  @media screen and (max-width: 767px) 
    {
      @media screen and (max-width:767px) and (orientation:landscape){
        top: 315px  ! important;
      }
      width: 90px ! important;
      font-size: 14px;
      line-height: 28px;
      top: 500px  ! important;
    } 

  right: -40px;
  top: 200px;
  z-index:100;
}


}

