.drop-shadow {
  box-shadow: 0 0 4px rgba(0,0,0,0.3);
}
ul.no-bullets{
  list-style: none;
  padding: 0;
}
.title-style {
  text-align: center;
  text-transform: uppercase;
}
.margin-right{
  margin-right: 20px;
}
.margin-top{
  margin-top: 20px;
}
.margin-bottom{
  margin-bottom: 20px;
}
.margin-left{
  margin-left: 20px;
}
.clearfix {
  @include clearit;
}
.center-text{
  text-align: center;
}
.left-text{
  text-align: left;
}
.right-text{
  text-align: right;
}
.no-padding--margin{
  margin: 0;
  padding: 0;
}
.no-padding{
  padding: 0;
}

.no-margin{
  margin: 0;
}
.row-space{
  width: 100%;
  display: block;
  clear: both;
  height: auto;
  padding: 0 0 15px 0;
}
.page-wrapper{
  padding: 0 8%;
  background: $white;
}

.column-control{
  .col-sm-*{
    padding-left: 8px;
    padding-right: 7px;
  }
}

@include respond-to(1200){
   .page-wrapper{
    padding: 0 5%;
  }
}
@include respond-to(tablet-portrait){
  #overlay-container{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    &.add-bkg{
      background: $black;
      z-index: 100;
      opacity: 0.8;
    }
  }
}
