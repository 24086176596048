#group-booking--form---container{
  width: 100%;
  background-color: $white;
  height: auto;
  padding: 10px 10% 0;
  .group-recaptcha {
    display: inline;
    .has-error {      
        border: 1px solid #ed1c24;
        .rc-anchor-light {
          &.rc-anchor-normal {
            border: none!important;
          }       
      }
    }
  }
  /*.has-error {
    input[type=checkbox] {
      outline: 1px solid #ed1c24;
    }
  }*/
  .form-group{
    margin-bottom: 0;
    &.group-tandc{
      display: inline-block;
      margin: 15px 0 10px;
    }
  }
  .form-validate{
    &.has-error{
      .booking-form--title{
        color: $errorred;
      }
      .hasDatepicker {
        border: 1px solid $errorred!important;
      }
      .form-control {
        border-color: $errorred;
      }
      input {
        &#groupReturnTripReturn {
          border-color: $errorred;
        }
        &#groupReturnTripDepart {
          border-color: $errorred;
          border-right: none!important;
        }
      }
    }
  }



  .group-date--selector{
    &.has-error{
      .date-form--title{
        color: $errorred;
      }
      input {
        #groupReturnTripDepart {
          border-color: $errorred!important;
          border-right: none!important;
        }
        #groupReturnTripReturn {
          border-color: $errorred!important;
        }
      }
    }
  }
  .group-tandc{
    &.has-error{
      label{
        color: $errorred;
      }
    }
  }
  .container{
    width: 100%;
    position: inherit;
    &.group-booking--header{
      h1{
        color: $focusblue;
        text-transform: initial;
        text-align: center;
        margin-bottom: 5px;
      }
      .breadcrumb{
        width: 100%;
        float: left;
        clear: both;
        text-align: center;
        ul{
          text-align: center;
          li{
            list-style-type: none;
            display: inline-block;
            color: $gray;
            @include font-size(14);
            padding: 0 0 0 5px;
            &:after{
              content: '>';
              @include font-size(14);
              display: inline-block;
            }
            &:last-child{
              &:after{
                content: '';
              }
            }
            a{
              color: $gray;
              padding: 0 10px;
              @include font-size(14);
            }
          }
        }
      }
      .header-text{
        width: 100%;
        float: left;
        clear: both;
        margin-top: 10px;
        p{
          @include font-size(16);
          color: $gray;
        }
        .mandatory-text{
          color: $errorred;
          @include font-size(14);
        }
      }
    }
    &.group-booking--tripcontainer{
      padding: 35px 0;
      width: 82%;
      float: left;
      @include z-index(columnImage);
      .col-lg-12{
        padding-left: 0;
      }
      .trip-type--container{
        width: 98.8%;
        float: left;
        padding-left: 0;
        margin-bottom: 0;
        li{
          list-style-type: none;
          float: left;
          @include font-size(14);
          color: $gray;
          margin-right: 30px;
          &.selected{
            color: $linkblue;
            border-bottom: 1px dashed $linkblue;
            a{
              color: $linkblue;
              text-decoration: none;
            }
          }
          a{
            color: $gray;
            &:hover, a:focus{
              text-decoration: none;
            }
          }
        }
      }
    }
    .fa-times{
      @include font-size(14);
      color: $darkgray;
    }
  }
  .form-control {
    &:focus{
      box-shadow: none;
    }
  }
  .group-form--header{
    margin-top: 20px;
    .col-lg-12{
      padding: 0;
      margin: 0;
      h5{
        font-family: $font-family-medium;
        @include font-size(16);
        color: $gray;
        text-transform: initial;
        margin-bottom: 15px;
      }
    }
  }
  .group-form--passengers{
    margin-bottom: 35px;
    .col-lg-12{
      padding: 0;
      margin: 0;
      .col-lg-4{
        padding: 0;
        margin: 0 10px 0 0;
        width: 32.6%;
        &:last-child{
          margin: 0 !important;
        }
        .input-lg{
          padding: 22px 20px 5px 20px;
        }
      }
    }
  }
  .group-form--contact{
    margin-bottom: 35px;
    .col-lg-12{
      padding: 0;
      margin: 0 0 15px 0;
      &.groupSubmitBtn{
        margin-top: 15px;
      }
      .col-lg-6{
        padding: 0;
        margin: 0 1.5% 0 0;
        width: 48.5%;
        &:last-child{
          margin: 0 !important;
        }
        input{
          &.input-lg{
            padding: 22px 20px 5px 20px;
          }
        }
        &.country-phone--code{
          margin: 0 1.5% 0 0;
          .col-lg-6{
            padding: 0;
            margin: 0;
            width: 50%;
            #groupCountryCode{
              @include border-radius(50px 0 0 50px);
            }
            #groupPhoneNumber{
              @include border-radius(0 50px 50px 0);
              border-left: 0;
            }
          }
          .selectize-input{
            border-radius: 25px 0 0 25px;
            padding: 22px 22px 4px 20px;
            &.select-filled{
             padding: 22px 22px 4px 20px;
            }
            &.dropdown-active{
              border-radius: 25px 0 0 0;
            }
          }
        }
      }
    }
  }
  .group-tandc{
    label{
      @include font-size(14);
      float: left;
      padding: 0 0 0 10px;
      color: $gray;
      margin: 0;
      a{
        color: $linkblue;
      }
    }
    #groupTC, #groupTnC {
      box-shadow: none;
      float: left;
      -webkit-appearance: checkbox !important;
      &.form-control{
        width: 20px;
        height: 20px;
        margin-top: 19px;
        padding: 0;
        &:focus{
          box-shadow: none;
        }
      }
    }
  }
  .location-container, .search-container, .group-form--passengers, .group-form--contact{
    button{
      &#groupSubmitBtn{
        width: 20%;
      }
    }
    .booking-form--title{
      position: absolute;
      left: 20px;
      top: 13px;
      color: $darkgray;
      @include font-size(14);
      @include z-index(bookformtitle);
      pointer-events: none;
      display: block;
      max-width: initial;
      margin-bottom: initial;
      font-weight: initial;
      -webkit-transition: all 90ms linear;
      -o-transition: all 90ms linear;
      transition: all 90ms linear;
      &.input-filled{
        top: 6px;
        left: 20px;
        @include font-size(12);
      }
      &.has-error{
        color: $errorred;
      }
    }
  }
  [data-form-booking--trip] {
    padding: 0 0 10px;
  }
  .location-container{
    .col-lg-5{
      width: 47.175%;
      padding-left: 0;
      &:last-child{
        padding-right: 0;
      }
    }
    .col-lg-2{
      width: 5.6%;
    }
    .bi-direction{
      display: block;
      text-align: center;
      padding: 10px 0 0 0;
      cursor: pointer;
    }
    .one-direction{
      display: none;
      text-align: center;
      padding: 10px 0 0 0;
      cursor: pointer;
    }
    .flight-trip--icon{
      -webkit-transition-duration: .5s;
      -moz-transition-duration: .5s;
      -o-transition-duration: .5s;
      transition-duration: .5s;
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
      width: 20px;
      &:hover{
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
      }
    }
  }
  .group-date--picker{
    &.col-lg-6{
      margin: 15px 0;
      padding: 0;
      width: 47.175%;
    }
    .group-date--selector{
      @include border-radius(50px);
      height: auto;
      width: 100%;
      overflow: hidden;
      position: relative;
      &.datepicker-selected{
        border: 1px solid $linkblue;
      }
      input{
        @include border-radius(50px);
        border: 0;
        padding: 5px;
      }
      .col-lg-10{
        width: 86%;
        border: 0;
        padding-right: 0
      }
      .col-lg-2{
        width: 8%;
          padding-left: 0;
          padding-top: 13px;
          @include font-size(14);
          color: $midgray;
          right: -30px;
      }
      &.group-return--trip{
        input{
          &.input-lg{
            width: 50%;
            float: left;
          }
        }
        #groupReturnTripDepart{
          color: $blue;
          box-shadow: none;
          @include border-radius(50px 0 0 50px);
          padding: 16px 0 0 20px;
          background-color: $white;
          border: 1px solid $lightgray;
          border-right: 0;
        }
        #groupReturnTripReturn{
          border-left: 1px solid $lightgray;
          @include border-radius(0 50px 50px 0);
          padding-left: 5px;
          color: $blue;
          box-shadow: none;
          padding: 16px 0 0 20px;
          background-color: $white;
          border: 1px solid $lightgray;
        }
      }
      &.group-oneway--trip{
        display: none;
        .one-dep-title{
          float: left;
          padding-right: 20px;
          position: relative;
          &.input-filled{
            font-size: .75rem;
            line-height: 1.125rem;
          }
        }
        #groupOneWayTripDepart{
          color: $blue;
          box-shadow: none;
          padding: 16px 0 0 20px;
          background-color: $white;
          border: 1px solid $lightgray;
        }
      }

      .date-form--title {
        @include font-size(14);
        @include z-index(bookformtitle);
        position: absolute;
        margin-top: 12px;
        color: $darkgray;
        pointer-events: none;
        display: block;
        max-width: initial;
        margin-bottom: initial;
        font-weight: initial;
        padding-left: 20px;
        &.input-filled {
          @include font-size(12);
          margin-top: 5px;
        }
        &.has-error {
          color: $errorred;
        }
      }
      .dep-return--title, .dep-return---title {
        @include z-index(ibetitle);
        @include font-size(14);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        color: $darkgray;
        pointer-events: none;
        max-width: initial;
        margin-bottom: initial;
        font-weight: initial;
        width: 100%;
        height: 100%;
        .dep-title {
          position: relative;
          width: 50%;
          &.input-filled {
            @include font-size(12);
          }
        }
        .ret-title {
          width: 50%;
          position: relative;
          &.input-filled {
            @include font-size(12);
          }
        }
      }
      // .dep-return---title{
      //   position: absolute;
      //   @include font-size(14);
      //   color: $darkgray;
      //   @include z-index(ibetitle);
      //   pointer-events: none;
      //   display: block;
      //   max-width: initial;
      //   margin-bottom: initial;
      //   font-weight: initial;
      //   width: calc(100% - 20px - 16px - 16px);
      //   font-weight: normal;
      //   margin-left: 20px;
      //   top: 35%;
      //   .dep-title{
      //     float: left;
      //     position: relative;
      //     width: calc(50% + 10px);
      //     padding-left: 0;
      //     font-size: 0.86em;
      //     top: -8px;
      //     margin-top: 5px;
      //     &.input-filled{
      //       font-size: 0.86em;
      //       top: -8px;
      //     }
      //   }
      //   .ret-title{
      //     float: left;
      //     position: relative;
      //     font-size: 0.86em;
      //     top: -14px;
      //     &.input-filled{
      //       font-size: 0.86em;
      //       top: -8px;
      //     }
      //   }
      // }
      .fa-calendar{
        position: absolute;
        right: 30px;
        top: 16px;
        z-index: 8;
        pointer-events: none;
        display: block;
        max-width: initial;
        margin-bottom: initial;
        font-weight: initial;
        cursor: pointer;
        color: #999;
      }
      .departure{
        right: calc(50% + 30px);
      }
    }
    &.has-error{
      .group-date--selector{
        border: 1px solid $errorred;
        .date-form--title{
          color: $errorred;
        }
      }
    }
  }
  .group-error--validation{
    border-bottom: 1px solid $errorred;
    margin: 15px 15px 0 0;
    padding: 10px 0;
    display: none;
    width: 100%;
    .fa{
      color: $errorred;
      @include font-size(18);
      float: left;
    }
    .col-lg-1{
      padding-right: 0;
      width: 4%;
    }
    .col-lg-11{
      padding-left: 0;
    }
    .group-error--msgs{
      @include font-size(14);
      color: $gray;
    }
    #result{
      @include font-size(14);
      color: $gray;
    }
  }
  .selectize-dropdown {
    [data-selectable] {
      padding: 8px 20px;
    }
  }
}

.group-booking--success, .group-booking--unsuccess{
  margin-bottom: 40px;
  display: none;
  h1{
    text-align: center;
    padding-top: 40px;
  }
  .email-details{
    text-align: center;
  }
}

@include respond-to(mid-screen-768-1200){
  #group-booking--form---container{
    padding: 3% 5% 0;
    .group-error--validation{
      .group-error--msgs{
        width: 95%;
        float: right;
      }
    }
    .group-form--container{
      width: 100% !important;
    }
    .group-form--passengers{
      .col-lg-12{
        .col-lg-4{
          width: 32.3%;
        }
      }
    }
    .group-form--contact{
      .col-lg-12{
        .col-lg-6{
          width: 49%;
        }
      }
    }
  }
}

@include respond-to(tablet-portrait){
    #group-booking--form---container{
    padding: 3% 5% 0;
    .group-form--header{
      clear: both;
    }
    .group-error--validation{
      height: auto;
      overflow: hidden;
      .group-error--msgs{
        width: 90%;
        float: right;
      }
    }
    .group-form--container{
      width: 100% !important;
      .location-container{
        .col-lg-5{
          width: 100% !important;
          &.toLocation{
            margin-top: 15px !important;
          }
        }
      }
    }
    .group-date--picker{
      &.col-lg-6{
        width: 100%;
      }
      .group-date--selector{
        .group-dep--return---title{
          .dep-title{
            padding-right: 38%;
          }
        }
      }
    }
    .group-form--passengers{
      .col-lg-12{
        .col-lg-4{
          width: 32%;
          float: left;
        }
      }
    }
    .group-form--contact{
      .col-lg-12{
        clear: both;
        .col-lg-6{
          width: 100%;
          float: left;
          &.groupFamilyName{
            margin-top: 15px !important;
          }
          &.country-phone--code{
            margin-top: 15px !important;
            margin-bottom: 15px !important;
          }
        }
      }
    }
  }
}

@include respond-to(570){
  #group-booking--form---container{
    .group-form--passengers{
      .col-lg-12{
        .col-lg-4{
          width: 31.2%;
        }
      }
    }
  }
}

@include respond-to(475){
  #group-booking--form---container{
    .group-booking--header{
      .breadcrumb{
        ul{
          margin: 0 12%;
          float: left;
        }
      }
    }
    .group-error--validation{
      height: auto;
      overflow: hidden;
      .group-error--msgs{
        width: 88%;
        float: right;
      }
    }
    .group-form--container{
      width: 100% !important;
      .location-container{
        .col-lg-5{
          width: 100% !important;
        }
      }
    }
    .group-date--picker{
      &.col-lg-6{
        width: 100%;
      }
      .group-date--selector{
        .group-dep--return---title{
          .dep-title{
            padding-right: 25%;
          }
        }
      }
    }
    .group-form--passengers{
      .col-lg-12{
        .col-lg-4{
          width: 31%;
          float: left;
        }
      }
    }
    .group-form--contact{
      .col-lg-12{
        clear: both;
        .col-lg-6{
          width: 100%;
          float: left;
          &:last-child{
            margin-bottom: 15px !important;
          }
          &.country-phone--code{
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
        }
      }
      #groupSubmitBtn{
        width: 50% !important;
      }
    }
    .group-tandc{
      #groupTC, #groupTnC {
        &.form-control{
          margin-top: 3px;
          margin-right: 10px;
        }
      }
      label{
        float: none;
        padding: 2px 0 0 30px;
      }
    }
  }
}

@include respond-to(320){
  #group-booking--form---container{
    .group-booking--header{
      h1{
        @include font-size(24);
      }
      .breadcrumb{
        ul{
          margin: 0 5%;
          float: left;
        }
      }
    }
    .group-error--validation{
      height: auto;
      overflow: hidden;
      .group-error--msgs{
        width: 86%;
        float: right;
      }
    }
  }
}
