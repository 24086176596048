$sequenceWidth: 1920px;
$sequenceHeight: calc((100vw - 16px) * 562 / 750);

.seq {
  position: relative;

  &:hover {
    .seq-prev,
    .seq-next {
      opacity: 1;
      background: rgba(0, 0, 0, 0.3);

      .fa {
        opacity: 1;
      }
    }

    .seq-pause,
    .seq-autoplay {
      background: transparent;

      .fa {
        opacity: 1;
      }
    }
  }

  // hide slide > 1st on load before slick inits
  .seq-canvas:not(.slick-initialized) {
    .seq-step:nth-child(n+2) {
      display: none;
    }
  }

  .seq-step {
    position: relative;
  }

  .seq-prev,
  .seq-next {
    transition: 0.25s all ease-in-out;
  }

  .seq-pagination {
    position: absolute;
    z-index: 1;
    -webkit-appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    bottom: 10px;

    li {
      margin: 0;
    }

    button {
      &:before {
        color: $white;
        opacity: 1;
        font-size: 40px;
        font-family: sans-serif;
      }

    }

    .slick-active {
      button {
        &:before {
          color: $linkblue;
        }
      }
    }

    @media screen and (max-width: 767px) {
      bottom: 15px;
      li {
        a {
          font-size: 0 !important;
        }
      }
    }

    @media screen and (max-width: 479px) {
      line-height: rem(10);
      bottom: 5px;
    }
  }

  .seq-screen {
    z-index: 1;
  }

  .seq-pause {
    z-index: 2;
    position: absolute;
    cursor: pointer;
    bottom: 5px;
    left: 10px;

    @include respond-to(tablet-portrait) {
      left: 5px;
    }
  }

  .seq-canvas {
    height: 100%;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    overflow: hidden;

    .slick-list {
      height: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .seq-title {
    display: block;
    position: absolute;
    z-index: 3;
    height: 100%;
    padding: 0;
    line-height: 1.2;
    color: $white;
  }

  button {
    @include z-index(bookflight);
    // position: absolute;
    background: transparent;
    border: 0;
    padding: 0;
    color: $white;
    cursor: pointer;
    text-align: center;

    .fa {
      @include font-size(28);

      @media screen and (max-width: 479px) {
        font-size: rem(20);
        line-height: rem(20);
      }
    }

    &.seq-prev,
    &.seq-next {
      position: absolute;
      top: 50%;
      padding: 12px;
      transform: translateY(-50%);
      opacity: 0;

      &:hover,
      &:active {
        background: rgba($black, 0.5);
      }
    }

    &.seq-prev {
      left: 0;
    }

    &.seq-next {
      right: 0;
    }
  }

  // .content-holder css
  @import 'C006-007-008-content-holder';

  @media only screen and (min-width: 768px) {
    $sequenceHeight: 400px;
  }
}

  @media only screen and (min-width: 768px) {
    .seq-title {
      width: 100%;
      left: 0;
      margin-left: auto;

      h2 {
        margin: .4em 0 .6em 0;
        padding: .4em;
        text-align: left;
        color: $white;
      }
    }

    small {
      top: auto;
      bottom: 10px;
    }

    &.seq-active {
      small {
        bottom: 70px;
      }
    }
  }

  @include respond-to(ipad-portrait){
      .seq-canvas {
        img {
          min-height: auto !important;
        }
      }
      .seq-title {
        width: 80% !important;
     }
  }

  @media only screen and (min-width: 768px) and (max-width: 980px) {
      .seq-title {
        width: 80% !important;
     }
  }

  @include respond-to(tablet-portrait) {
    .seq-title {
      pointer-events: none;
      width: 100%;
      margin-left: 0;
      padding: 0;
      left: 0;

      .content-holder {
        width: calc(100% - 40px); // - 20px * 2 side margin
      }
    }
  }

  @include respond-to(ipad-portrait) {
    .seq {
      .content-holder {
        width: 80% !important;

        &.flip-clock {
          .countdown-timer--container {
            margin-bottom: 4px !important;
          }
          .book-by {
            margin-bottom: 10px;
          }
          a {
            &.btn {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .seq {
      .content-holder {
        margin-bottom: 0;
        bottom: 30px;
      }
    }
  }
  .black-text-overlay{
    background: rgba(0, 0, 0, 0.5);
    padding: 1%;
    @media only screen and (max-width: 500px) {
      padding: 3%;
    }
  }
  .white-text-overlay{
    background: rgba(255, 255, 255, 0.5);
    padding: 1%;
    @media only screen and (max-width: 500px) {
      padding: 3%;
    }
  }
