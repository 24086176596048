.members--mymiles--my-activity {
    +.members--mymiles--my-statement {
        margin-top: 40px;
    }
}

.period_sort{
    .miles-table-options {
        display: inline-block;
        width: 100%;
        position: relative;
        float: right;
        margin-bottom: 10px;
        margin-top:0px;
    }

    .miles-period-select,
    .miles-export-action {
        float: left;
        width: auto;
    }

    .form-group.miles-period-select {
        width: 30%;
        margin-right: 15px;
        float: right; //10532

        @media screen and (max-width: 767px) {
        width: 100%;
        float: left;
        }

    }

    .print-only {
        display: none;
    }

    .miles-export-action {
        // padding: #{(47px - 33px) / 2} 0; // selectize height - btn height / 2

        .btn {
        padding-left: 35px;
        padding-right: 35px;

        &:hover {
            color: $white;
        }
        }
    }
    
}

#members_activity_conetnt {
    //10532--
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    
    @media (max-width: 767px) {
      padding: 10px;
      .title{
        text-align: center;
    }
    }
    //----
    h5 {
        color: #006dbb;
        color: #295cab;//10532
    }
   
    .description{
        margin-bottom: 32px;
        font: normal normal 14px/21px Gotham,Arial;
    }
    .jumbotron.members__overview{
        display: none;
    }
    .update_tab, .miles-claim-missing ul {
        li.active {
            a {
                color: #fff;
                background-color: #006dbb;
                background-color: #14467b;//10532
                border: 1px solid;
                border-top: -10px;
                border-radius: 12px 12px 0px 0px;
                border: 1px solid #006dbb;
                border: 1px solid #14467b;//10532
                
                text-align: center;
                @media (max-width: 767px) {
                    border-radius: 5px;
                }
            }
        }
        a {
            border: 1px solid #006dbb;
            border: 1px solid #e5e5e5;//10532
            background-color: #e5e5e5;
            color:#575757;
            border-top: -10px;
            border-radius: 12px 12px 0px 0px;
            text-align: center;
            white-space: nowrap;
            @media (max-width: 767px) {
                height: 100%;
                min-height: 40px;
                font-size: 11px;
                padding-top: 14px;
                text-align: center;
                padding-right: 0;
                padding-left: 0;
            }
        }
        a:hover {
            color: #14467b;            
        }
        .col-lg-3 {
            width: 24%;
            padding: 0px;
            
        }
        @media (max-width: 767px) {
            display: flex;
            li.col-lg-3{
                width: 25%;
                width:auto;
                flex:1;
            }
            a{
                white-space: normal;
                padding: 5% 10%;
                border-radius: 5px;
                font-size: 13px;
            }
        }
        @media screen and (min-width:768px) and (max-width:1048px){
        
            a[href="#retro-claim"]{
            white-space: nowrap;
        }
            li.col-lg-3{
                min-width: 24%;
                width: auto;
            }
        }

    }


    .miles-claim-missing{
        @media screen and (max-width:767px) {
            margin-top: 0;
            .nav-tabs--arrow{
                display: none;
            }
            .nav-tabs-wrapper{
                height:auto;
                flex: 1;
            }
            &>.description{
                display: none;
            }
            .dataTables_info,.dataTables_paginate{
                position: sticky;
                left: 0;
            }
        }
        @media screen and (min-width:768px) and (max-width:1048px){
            .miles-table th{
                padding:10px
            }

            // .form-group { 
                
            //     div.claim-miles--flight-date {
            //     margin-bottom: 15px;
            // }
            //     &.claim-miles--flight-origin-destination{
            //     margin-bottom: 5px;
            //     }
            // }
        }
        .nav-tabs{
            border-bottom: none;
        }
        ul {
            li{
            width:20%;
            margin-right:20px;
        }
        a,li.active a{
            border-radius: 20px;
            text-transform: capitalize;
        }
        @media screen and (max-width:767px){
            flex-wrap: wrap;
            li{
                width:auto;
                min-width: 35%;
                flex:1;
                margin:0px;
                margin-top:10px;
                &:nth-child(odd){
                    margin-right:10px;
                }
            }
            a, li.active a{
                width: 100%;
                padding:10px;
            }
        }
        @media screen and (width:768px){
            &.nav-tabs{
                padding: 0 3.9%;
                li{
                    width:auto;
                }
                li:last-child{
                    margin-right: 0px;
                }
                a{
                    padding: 10px 10px;
                }
            }
            
        }
        }
        #claim-miles--submit:hover{
            background-color: #14467b!important;
        }
        @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5){
            .col-sm-6{
                width: 50%;
            }
        }
    }
    .dataTables_wrapper {
        .dataTables_length {
            float: right;
        }
        @media screen and (max-width:810px) {
            overflow-x: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none; 
            &::-webkit-scrollbar {
                display: none;
            }

        }
    }
    table {
        border-bottom: 1px solid #ccc;
        width: 100% !important;
        // &.dataTable {
        //     &.display {
        //         thead {
        //             tr th {
        //                 text-align: left;
        //             }
        //         }
        //         tbody {
        //             td {
        //                 border-top: 1px solid;
        //                 font-size: 13px;
        //             }
        //             tr {
        //                 &.odd:hover {
        //                     background-color: white;
        //                 }
        //                 &.even:hover {
        //                     background-color: white;
        //                     >.sorting_1 {
        //                         background-color: white;
        //                     }
        //                 }
        //                 .date:focus {
        //                     outline: none;
        //                 }
        //             }
        //         }
        //     }
        // }
        // &.dataTable {
        //     thead {
        //         // tr th {
        //         .sorting_asc {
        //             &:after {
        //                 content: "" !important;
        //             }
        //         }
        //         // }
        //     }
        // }
        tbody {
            .odd {
                .sorting_1 {
                    background-color: inherit;
                }
            }
        }
        &.display {
            tbody {
                tr.odd {
                    // background-color: white;
                    background-color: #e5e5e5;//10352
                }
            }
        }
    }
    .miles-sample-dialog {
        max-width: 1024px;
        background-color: $white;
        padding: 20px;
        margin: 0 auto;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        img {
            max-width: 100%;
            margin: 0 auto;
        }
        .carousel {
            width: 100%;
            padding-left: 0;
        }
        .slick-slide {
            position: relative;
        }
        .slick-prev,
        .slick-next {
            width: 48px;
            height: 48px;
            &:before {
                font-size: rem(36);
            }
        }
        .slick-prev {
            left: -20px;
        }
        .slick-next {
            right: -20px;
        }
        .slick-dots {
            border-top: 0;
            margin: 0;
            padding: 0;
            bottom: initial;
            li button {
                &:before {
                    margin: 5px;
                    width: 12px;
                    height: 12px;
                    content: '';
                    background: $black;
                    border-radius: 50%;
                }
            }
        }
    }
    .disable_html {
        pointer-events: none;
        opacity: 0.4;
    }
//10532
#transaction .miles-table{
    @media screen and (max-width:767px){

        th:nth-child(2){
            min-width: 250px;
        }
    }
}
.miles-table{
    @media screen and (max-width:767px) {
        margin-top:0px;
        &::after{
            content:"Scroll left to view more.";
            font-style: oblique;
            font-size: 11px;
            white-space: nowrap;
            position: sticky;
            left: 0;
        }

    }
    th{
        background-color: #2C5DAC;
        color: #fff;
        padding: 20px;
        text-transform: capitalize;
        vertical-align: top;
        @media screen and (max-width:767px){
            min-width: 84px;
        }
    }
    tr{
        border-bottom: 3px solid #fff;
        td{
            padding: 20px;
        }
        @media screen and (max-width:767px) {
            td{
                padding: 5px 20px;
                font-size: 11px;
            }
        }
        
    }
    tr td:nth-child(1),tr th:nth-child(1){
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        white-space: nowrap; 
    }
    tr td:last-child,tr th:last-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    tr.even{
        background-color: #f1f1f1;
        .sorting_1{
            background-color: #f1f1f1 ;
        }
    }

}
}
