// base styles for mholidays

.mholidays__content {
    @import '../../vendor/bootstrap-sass/assets/stylesheets/bootstrap';
}
@import 'mholidays/molecules/header';
@import 'mholidays/molecules/content';
@import 'mholidays/molecules/buttons';
@import 'mholidays/molecules/form';
@import 'mholidays/molecules/footer';

@import 'mholidays/components/index';
@import 'mholidays/components/_slideout-menu';
@import 'mholidays/components/_carousel';
@import 'mholidays/components/_new-deals';
