$slick-dot-color: $white !default;
$slick-dot-color-active: $linkblue !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: rem(48) !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;

.fullwidth-img--4cols {
  .column-container {
    position: absolute;
    @include z-index(columnContent);
    width: 80%;
    height: auto;
    text-align: center;
    margin: 35px 0 0 10%;
    h1 {
      color: $white;
      line-height: 1em;
    }
    p {
      color: $white;
    }
    .btn-primary {
      width: auto;
      min-width: 15%;
    }
  }
  .column-contents {
    margin: 35px 0;
    h4 {
      color: $white;
      margin-bottom: 5px;
    }
    .slick-slide {
      padding: 0 3%;
      border-right: 1px solid $darkgray;
      &:last-child {
        border-right: 0;
      }
    }
  }
  .img-holder {
    position: relative;
    @include z-index(columnImage);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
    }
    .desktop-version {
      height: 440px;
      overflow: hidden;
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    border-top: 1px solid $darkgray;
    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: $slick-opacity-on-hover;
          }
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: $slick-dot-character;
          width: 20px;
          height: 20px;
          font-size: $slick-dot-size;
          line-height: rem(20);
          text-align: center;
          color: $slick-dot-color;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
      &.slick-active button:before {
        color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
    li {
      margin: 0;
      button {
        &:before {
          @include font-size(14);
          color: $white;
          opacity: 1;
        }
      }
      &.slick-active {
        button {
          &:before {
            color: $blue;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1920px) and (min-width: 1450px) {
  .fullwidth-img--4cols {
    .column-contents {
      .slick-slide {
        min-height: 150px;
      }
    }
  }
}

@include respond-to(1440) {
  .fullwidth-img--4cols {
    .column-container {
      margin: 35px 0 0 10%;
    }
    .column-contents {
      .slick-slide {
        min-height: 150px;
      }
    }
  }
}

@include respond-to(1250) {
  .fullwidth-img--4cols {
    .column-container {
      margin: 20px 0 0 10%;
    }
  }
}

@include respond-to(mid-screen-768-1200) {
  .fullwidth-img--4cols {
    .column-container {
      width: 90%;
      margin: 20px 0 0 5%;
    }
    .img-holder {
      .desktop-version {
        height: auto;
      }
    }
    .column-contents {
      margin: 4% 0;
      .slick-slide {
        min-height: 80px;
        h4 {
          @include font-size(18);
        }
        p {
          @include font-size(14);
        }
      }
    }
    .slick-dotted {
      &.slick-slider {
        margin-bottom: 60px !important;
      }
    }
    .slick-dots {
      bottom: -50px;
      margin: 0 0 10px;
    }
  }
}

@include respond-to(980) {
  .fullwidth-img--4cols {
    .column-container {
      width: 96%;
      margin: 10px 0 0 2%;
      h1 {
        @include font-size(36);
        line-height: 1em;
        margin-bottom: 10px;
      }
      .btn-primary {
        width: 25%;
      }
    }
    .column-contents {
      margin: 3% 0;
      .slick-slide {
        min-height: 80px;
        h4 {
          @include font-size(14);
        }
        p {
          @include font-size(12);
        }
      }
    }
  }
}

@include respond-to(880) {
  .fullwidth-img--4cols {
    .column-container {
      margin: 10px 0 0 2%;
    }
    .column-contents {
      margin: 3% 0;
      .slick-slide {
        min-height: 60px;
      }
    }
  }
}

@include respond-to(ipad-portrait) {
  .fullwidth-img--4cols {
    .column-container {
      width: 92%;
    }
    .column-contents {
      margin: 2% 0 50px !important;
    }
  }
}

@include respond-to(tablet-portrait) {
  .fullwidth-img--4cols {
    .column-container {
      margin: 40% 0 0 10%;
      width: 75%;
      .btn-primary {
        margin-top: 3%;
        width: 30%;
      }
    }
    .column-contents {
      margin: 8% 0;
      .slick-slide {
        width: 25% !important;
        border-right: 0;
        outline: 0;
        h4 {
          @include font-size(18);
        }
        p {
          @include font-size(14);
        }
        &:focus {
          outline: 0;
        }
      }
    }
    .img-holder {
      &:before {
        height: auto;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(51, 51, 51, 1) 60%);
      }
    }
  }
}

@include respond-to(600) {
  .fullwidth-img--4cols {
    .column-container {
      margin: 30% 0 0 10%;
      .btn-primary {
        width: 50%;
      }
    }
    .slick-dots {
      width: 40%;
      margin: 0 0 0 30%;
      bottom: -25px;
    }
  }
}

@include respond-to(500) {
  .fullwidth-img--4cols {
    .column-container {
      margin: 15% 0 0 10%;
      .btn-primary {
        width: 50%;
        margin-top: 8% !important;
      }
    }
    .slick-dots {
      width: 40%;
      margin: 0 0 0 30%;
      bottom: -35px;
    }
  }
}

@include respond-to(414) {
  .fullwidth-img--4cols {
    background: $gray;
    .column-container {
      margin: 65% 0 0 7%;
      .btn-primary {
        width: 50%;
        margin-top: 8% !important;
      }
    }
    .img-holder {
      height: 680px;
      &:before {
        height: auto;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
      }
    }
    .slick-dots {
      width: 40%;
      margin: 0 0 0 30%;
      bottom: -35px;
    }
  }
}

@include respond-to(375) {
  .fullwidth-img--4cols {
    background: $gray;
    .column-container {
      margin: 68% 0 0 7%;
    }
    .slick-dots {
      width: 40%;
      margin: 0 0 0 30%;
      bottom: -40px;
    }
  }
}

@include respond-to(320) {
  .fullwidth-img--4cols {
    background: $gray;
    .column-container {
      margin: 55% 0 0 7%;
    }
    .img-holder {
      height: 600px;
    }
    .slick-dots {
      width: 40%;
      margin: 0 0 0 30%;
      bottom: -40px;
    }
  }
}
