.members--mymiles--points--exchange {
    partner-card-selctImage{
        img{
            width: 100%;
        }
    }
    .partner-crad-exchange{
        p {
            padding-top: 12px;
            text-align: center;
            font: bold;
        }
        img{
            width: 100%;
        }
    }
    .hideclass{
        display: none !important;
    }
    .showclass{
        display: block ;
    }
    .partner-cardform-banner{
        img{
            width: 100%;
            //height: 160px;
        }
    }
    .points-exchange-form-step1{
        margin-top: 15px;
    }
    .points-exchange-form-step2{
        margin-top: 15px;
    }
    .points-exchange-form-confirm-step3
    {
        margin-top: 15px;
    }
    table{
        padding: 10px;
        //border: 1px solid black; 
        margin-left: 100px;
        width: 55%;
    }
    thead{
        //border: 1px solid black;
        background-color: #006dbb;
        font: normal 15px/16px Gotham-Book,Arial;
        color: #fff;
    }
    tbody{
        //border: 1px solid black;
        background-color: #eee;
        font: normal 15px/16px Gotham-Book,Arial;
    }
    tr{
        height: 40px;
    }
    .milesheading{
        padding-left: 20px;
        .greator-arrow {
            padding-left: 8px
        }
    }
    .mob-milesheading{
        width: 50%;
        //padding-left: 70px;
        text-align: center;
    }
    .infiniteheading{
        padding-right: 15px;
    }
    .mob-infiniteheading{
        width: 50%;
        text-align: center;
        //padding-right: 50px;
    }
    .milesdada{
        padding-left: 48px;
        .greator-arrow {
            padding-left: 56px;
        }
    }
    .infinitydata{
        padding-left: 5px;
    }
    .mob-milesdada {
        //padding-left: 30px;
        text-align: center;
    }
    .mob-infinitydata{
        //padding-left: 22px;
        text-align: center;
    }
    .heading-steps{
        font: normal 18px/30px Gotham-Book,Arial;
        font-weight: 600;
    }
    .incrementer-group.form-item{
        padding-top: 14px;
        font-family: Gotham-Book,Arial;
    }
    .drag-or-insert-div
    {
        margin-top: 15px;
    }
    .points-exc-confirm--div
    {
        margin-top: 5px;
    }
    .mail-check-security-div--row{
        margin-top: 15px;
    }
    .rangecaldiv{
        margin-top: 20px;
        margin-left:-40px !important;
    }
    #label--points-exc-enrichmiles--lable, #label--points-exc-Binfinity--lable, #minmaxpoints {
        font-size: 15px;
    }
    #minmaxpoints1,#sunwaypaul-minmaxpoints1{
        padding-left: 33px;
        font-size: 15px;
    }
    .range-slider {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        border-radius: 8px;
        margin-top:8px;
        background: #d3d3d3;
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
      }
      
      .range-slider:hover {
        opacity: 1;
      }
      
      .range-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border-color:#000;
        background: #eee;
        cursor: pointer;
      }
      
      .range-slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border-color:#000;
        background: #eee;
        cursor: pointer;
      }
      .points-exc-terms-conditions{
          font-size:20px;
          #points-exc--miles-terms-and-conditions{
              width:20px;
              height:20px;
          }
          #termscondiText{
              font-size: 17px;
              padding-top: 5px;
              padding-left: 6px;
          }
      }
      .incresheight{
          height: 220px;
      }
      .disablee{
        pointer-events:none;
        cursor:default;
        opacity: 0.6;
      }
      #timerdisp{
       display: inline !important;
      }
      .accounterror, .rangeerror, .radioErrMsg, .securityCodeerror,.maxErrorMsg, .cardStatusError,.mailOTPError,.cardnumberformatErrMsg,.securityvalidCodeerror,.api-err-disp-class{
        color: #ed1c24;
        font-family: Gotham-Book,Arial;
        font-size: 17px !important;
      }
      .partner-crad-exchange{
        #SunwaypalsId{
            img{
                width:95% !important;
                margin-top: 15px;
            }
            p{
                padding-top: 28px;
            }
        }
      }
      .sunwaypals-step2{
          margin-top: -60px;
      }
      #sunwaypauls-partner-card-number{
          margin-top: 28px;
      }
      @media screen and (min-width: 481px) and (max-width: 767px) {
        table{
            padding: 0px;
            //border: 1px solid black; 
            margin-left: 0px;
            width: 100%;
        }
        .heading-steps{
            font: normal 17px/30px Gotham-Book,Arial;
            font-weight: 600;
        }
        .accounterror, .rangeerror, .radioErrMsg, .securityCodeerror,.maxErrorMsg,.cardStatusError,.mailOTPError {
            color: #ed1c24;
            font-family: Gotham-Book,Arial;
            font-size: 15px !important;
          }
        .incrementer-group.form-item{
            padding-top: 0px;
            font-family: Gotham-Book,Arial;
        }
        .points-exc-confirm--div
        {
            margin-top: 10px;
        }
        .points-miles-deduct--confirm{
            padding-top: 14px !important;
        }
        .mobile-points-exc-confirm--div{
            margin-top: 15px !important;
        }
      }
      @media screen and(max-width: 480px){
        table{
            padding: 0px;
            //border: 1px solid black; 
            margin-left: 0px;
            width: 100%;
        }
        .heading-steps{
            font: normal 16px/30px Gotham-Book,Arial;
            font-weight: 600;
        }
        .accounterror, .rangeerror, .radioErrMsg, .securityCodeerror, .maxErrorMsg,.cardStatusError,.mailOTPError{
            color: #ed1c24;
            font-family: Gotham-Book,Arial;
            font-size: 15px !important;
          }
        .incrementer-group.form-item{
            padding-top: 0px;
            font-family: Gotham-Book,Arial;
        }
        .points-exc-confirm--div
        {
            margin-top: 10px;
        }
        .points-miles-deduct--confirm{
            padding-top: 14px !important;
        }
        .mobile-points-exc-confirm--div{
            margin-top: 15px !important;
        }
        .b-infinite-acct-number--confirm,.total-miles-deduct--confirm,.total-binfinite-recieve--confirm{
            font-size: 10px !important;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
          table{
            width: 100%;
          }
      }
      @media screen and (min-width: 320px) and (max-width: 568px) {
        .btn-points--check--req-again{
            width: 170px;
            margin-left: -28px;
        }
    }
    
    .btn-inline{
        display: inline-flex;
      }
    .btn-member--cancel{
        min-width: 146px;
        width: auto;
        margin-left: 10px;
    }
    


}