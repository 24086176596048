/* C001 - Alert */
.alert-row-container {
  margin-left: 0;
  margin-right: 0;

  > div[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}


#alert-container, #alert-container-ieVersion {
  background: $white;
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: 1px solid $lightgray;
  position: relative;

  .alert-content-wrapper, .alert-content-wrapper-ieVersion {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .alert-close {
    @include font-size(14);
    @include z-index(alertWindow);
    width: 25px;
    height: 25px;
    text-align: center;
    position: absolute;
    top: 5px;
    cursor: pointer;
    color: $darkgray;
    right: 0;
    float: none;

    @media screen and (min-width: 1281px) and (max-width: 1439px) {
      right: calc(((100% - 1200px - 20px - 20px) / 2));
    }

    @media screen and (min-width: 1440px) {
      right: 100px;
    }

  }

  .alert-content, .alert-content-ieVersion {
    @include font-size(14);
    line-height: 1.25;
    width: calc(100% - 32px);
    max-width: 1200px - 25px;
    display: block;
    padding: 10px;
    height: auto;
    overflow: hidden;

    @include respond-to(475) {
      @include font-size(12);
    }

    @include respond-to(tablet-portrait) {
      width: calc(100% - 20px - 20px - 15px);
    }

    @media screen and (max-width: 1200px) {
      width: calc(100% - 25px);
    }

    @media screen and (min-width: 1201px) and (max-width: 1439px) {
      margin-left: calc((100% - 1200px - 10px) / 2);
    }

    @media screen and (min-width: 1220px) and (max-width: 1439px) {
      padding: 10px;
    }

    @media screen and (min-width: 1201px) and (max-width: 1219px) {
      padding: 10px 20px;
    }

    @media screen and (min-width: 1440px) {
      margin: 0 110px;
    }

    .msg-content {
      display: inline;
      padding-right: 4px;
    }

    strong,
    .alert-type-label {
      padding-right: 4px;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    a,
    .alert-read-more {
      line-height: 1.25;
      display: inline;
      position: relative;
      top: -1px;

      @media screen and (min-width: 768px) {
        top: -1px;
      }
    }

    &:not(:first-child) {
      display: none;
    }

    .fa-info-circle,
    .fa-exclamation-triangle {
      color: $alertred;
      margin-top: 3px;
      margin-right: 5px;
      float: left;
      width: 15px;
      text-align: left;
    }

    @include respond-to(475) {
      @include font-size(12);
    }
  }

  i.fa {
    text-align: center;
  }

  span.alertHeading,
  span[for='alertHeading'] {
    float: left;
    display: inline-block;
    width: calc(100% - 14px - 6px);
  }

  ul {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height: auto;
    list-style: none;
    li {
      position: relative;
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}

@include respond-to(320) {
  #alert-container {
    .alert-content {
      @include font-size(10);
      a {
        @include font-size(10);
      }
    }
  }
}

#ieVersion-checker-popup-blocker,#disableieVersion-checker-popup-blocker
{
    background-color: white;
    padding: 50px;
    width: 500px;
	margin: auto;
	.mfp-close{
		right: 467px;
	}

}
p.ieLessThanTen {
	margin: 0px;
}
p.ieLessThanEleven {
	margin:0px;
}
/* C001 - Alert */
