.members--card-image {
  position: relative;
  display: inline-block;
  width: 100%;

  img {
    max-width: 350px;
    width: 100%;
  }

  .card--details {
    position: absolute;
    left: 23px;
    bottom: 69px;
    width: calc(100% - 10px - 36px);
    @include respond-to(375){
      bottom: 65px; 
    }
    @include respond-to(360){
      bottom: 60px; 
    }
    @include respond-to(320){
      bottom: 53px; 
    }
  }

  .card--username,
  .card--number,
  .card--expiry {
    color: $black;
    font-size: rem(12);
    line-height: 1;
    margin-bottom: 7px;
    padding: 5px;
    @include respond-to(320){
      padding: 4px 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .card--username {
    font-weight: 600;
    font-family: $font-family-medium;
    font-size: rem(16);
    margin-bottom: 6px;
    text-transform: capitalize;
    @include respond-to(375){
      font-size: rem(14);
      margin-bottom: 5px;
    }
    @include respond-to(320){
      font-size: rem(12);
      margin-bottom: 0px;
    }
  }
}

.member--silver, .member--gold{
  .members--card-image{
    .card--username,
    .card--number,
    .card--expiry {
      @include respond-to(320){
        padding: 0px 5px 4px;
        line-height: 10px;
      }
      &:last-child {
        margin-bottom: 0;
        @include respond-to(320){
          margin-bottom: 1px;
        }
      }
    }
    .card--username {
      @include respond-to(320){
        font-size: rem(12);
        margin-bottom: 9px;
      }
    }
  }
}

.member--blue {
  .members--card-image {
    .card--details {
      bottom: 61px;
    }

    .card--username {
      margin-bottom: 22px;
      @include respond-to(320){
        margin-bottom: 18px;
      }
    }

    .card--number {
      margin-bottom: 0;
      @include respond-to(375){
        margin-bottom: -4px;
      }
      @include respond-to(320){
        margin-bottom: -15px;
      }
    }

    .card--expiry {
      display: none;
    }
  }
}

.member--platinum{
  .card--username {
    @include respond-to(320){
      padding: 7px 5px;
      margin-bottom: 1px;
    }
  }
  .card--number{
    line-height: 12px;
  }
}
