.members--redeem--partner-redeem--voucher {
    #label--partner-redeem--voucher-current-bal-lable{
            padding-top: 14px;
    }
    .partner-redem-shoppingCart{
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
	.partner-redem-brands{
        border: 1px solid #eeeeee;
	border-right: none;
	padding: 10px 0;
    //border-bottom: none;
    text-align: center;
    p {
        padding-top: 15px;
    }
    img {
        width: 85%;
    }
}
.backbutton
    {
        font-size: 14px;
	    color: #9ba3ab;
        @media screen and (max-width: 480px)
        {
            font-size: 11px;
        }
    }
.partner-redem-brands {
	&:last-child {
        border-right: 1px solid #eeeeee;
    }
   /* #BOOST {
        img{
            height: 50px;
            width:120px !important;
        } 
    }*/
}
.partner-redeem--voucher-to-use {
	padding-top: 20px;
	border: 1px solid #eeeeee;
	//margin-left: -5px;
	margin-top: -10px;
    padding-left: 6px;
    .lineclass
    {
        border: 0.5px solid black;
        margin-right: 30px;
        margin-left: 30px;
    }
    .partner-redeem--total-points
    {
        padding-bottom:15px;
        padding-top:2px;
        /*border-top: 1px solid #070707;*/
        
    }
    .row{
        padding-left: 30px;
        .incrementer{
            padding-left: 30px;
        }
    }
    .label
    {
        width:12% !important;
    }
    .partner-redeem--total-points{
        .label{
            width:100% !important;
            padding-top: 10px;
        }
        .col-xs-6.col-sm-1{
            margin-top: 10px;
        }
    }
}
.partner-redeem-error-validations{
    .errorDiv{
        padding-left: 5px;
        .max4errorMsg{
            color: #C82613;
        }
        .rmMaxErrorMsg1{
            color: #C82613;
        }
        .rmMaxErrorMsg2{
            color: #C82613;
        }
        .rmMaxErrorMsg3{
            color: #C82613;
        }
        .rmMaxErrorMsg4{
            color: #C82613;
        }
        .voucherEligibity{
            color: #C82613;
        }
    }
}
.disablee{
    pointer-events:none;
    cursor:default;
    opacity: 0.6;
}
.active{
    //background-color:#005aaa;
    border: 1px solid #eeeeee;
}
.inactive{
    //padding-bottom: 15px;
    opacity: 0.4;
}
.inactive:hover{
    opacity: 1.0;
}
.hideclass{
    display: none;
}
.showclass{
    display: block;
}
.partner-redem-brands.active{
    &:last-child{
        border: 1px solid #eeeeee;
    }
}
.successMsg{
    padding-top: 20px;
    padding-left: 6px;
    margin-top: 20px;
    white-space: pre-wrap;
    .succMsg{
        color: #0C882A;
    }
}
.errorMsg{
    padding-top: 20px;
    padding-left: 6px;
    margin-top: 20px;
    white-space: pre-wrap;
    .errMsg{
        color: #C82613;
    }
}
.onload_errorMsg{
    padding-top: 20px;
    padding-left: 6px;
    margin-top: 20px;
    white-space: pre-wrap;
    .onload_errMsg{
        color: #C82613;
    }
}
.below_shopping_cart_description,.bottom_TNC_display{
       margin-top: 20px;
       margin-bottom: 20px;
       font-family: Gotham-Book,Arial;
}
.partner-redem-brands {
    #NewShopee {
        p{
            font-size: 15px;
        } 
    }
    #ExistShopee {
        p{
            font-size: 15px;
            height: 30px;
        } 
    }
}
@media screen and (max-width: 480px){
    .partner-redem-brands{
        border-right: 1px solid #eeeeee;
        /*#BOOST {
            img{
                height: 150px;
                width:220px !important;
            } 
        }*/
    }
    .partner-redem-brands.active{
        border: 1px solid #eeeeee;
    }
    .partner-redeem--voucher-to-use {
        padding-left: 2px !important;
        padding-right: 2px !important;
        .row{
            padding-left: 0px !important;
            .incrementer{
                padding-left: 0px !important;
            }
        }
        .lineclass{
            padding-left: 20px !important;
            padding-right: 15px !important;
        }
        .partner-redeem--total-points{
            .col-xs-6.col-sm-3{
                width:30% !important;
                padding-left: 5px !important;
            }
            .col-xs-6.col-sm-2{
                /*width:38%;*/
                padding-top: 5px !important;
                padding-left: 15px !important;
            }
            .col-xs-6.col-sm-1{
                width:14% !important;
                padding-top: 4px !important;
            }
            .col-xs-6.col-sm-6{
                padding-left: 15px;
                padding-top: 15px;
            }
        }
    }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
    .partner-redem-brands{
        border-right: 1px solid #eeeeee;
        /*#BOOST {
            img{
                height: 150px;
                width:220px !important;
            } 
        }*/
    }
    .partner-redem-brands.active{
        border: 1px solid #eeeeee;
    }
    .partner-redeem--total-points{
        .col-xs-6.col-sm-3{
            width:35% !important;
            /*padding-left: 5px !important;*/
        }
        .col-xs-6.col-sm-2{
            /*width:38%;*/
            padding-top: 5px !important;
            padding-left: 15px !important;
        }
        .col-xs-6.col-sm-1{
            width:14% !important;
            padding-top: 4px !important;
        }
        .col-xs-6.col-sm-6{
            padding-left: 15px;
            padding-top: 15px;
        }
    }
}
@media screen and (min-width: 768px){
    .partner-redem-brands {
        height: 210px;
        width: 180px;
        /*#Shopee {
            img{
                height: 50px;
            } 
        }*/
    }
}
}
.members--redeem--partner-redeem--vouchercatalogue{
    #label--partner-redeem--voucher-current-bal-lable{
        padding-top: 14px;
}
.partner-redem-shoppingCart{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.partner-redem-brands{
    border: 1px solid #eeeeee;
border-right: none;
padding: 10px 10px;
//border-bottom: none;
text-align: center;
p {
    padding-top: 15px;
}
img {
    width: 85%;
}
}
.partner-redem-brands {
&:last-child {
    border-right: 1px solid #eeeeee;
}
/* #BOOST {
    img{
        height: 50px;
        width:120px !important;
    } 
}*/
}
.partner-redeem--voucher-to-use {
padding-top: 20px;
border: 1px solid #eeeeee;
//margin-left: -5px;
margin-top: -10px;
padding-left: 6px;
.lineclass
{
    border: 0.5px solid black;
    margin-right: 30px;
    margin-left: 30px;
}
.partner-redeem--total-points
{
    padding-bottom:15px;
    padding-top:2px;
    /*border-top: 1px solid #070707;*/
    
}
.row{
    padding-left: 30px;
    .incrementer{
        padding-left: 30px;
    }
}
.label
{
    width:12% !important;
}
.partner-redeem--total-points{
    .label{
        width:100% !important;
        padding-top: 10px;
    }
    .col-xs-6.col-sm-1{
        margin-top: 10px;
    }
}
}
.partner-redeem-error-validations{
.errorDiv{
    padding-left: 5px;
    .max4errorMsg{
        color: #C82613;
    }
    .rmMaxErrorMsg1{
        color: #C82613;
    }
    .rmMaxErrorMsg2{
        color: #C82613;
    }
    .rmMaxErrorMsg3{
        color: #C82613;
    }
    .rmMaxErrorMsg4{
        color: #C82613;
    }
    .voucherEligibity{
        color: #C82613;
    }
}
}
.disablee{
pointer-events:none;
cursor:default;
opacity: 0.6;
}
.active{
//background-color:#005aaa;
border: 1px solid #eeeeee;
}
.inactive{
//padding-bottom: 15px;
opacity: 0.4;
}
.inactive:hover{
opacity: 1.0;
}
.hideclass{
display: none;
}
.showclass{
display: block;
}
.partner-redem-brands.active{
&:last-child{
    border: 1px solid #eeeeee;
}
}
.successMsg{
padding-top: 20px;
padding-left: 6px;
margin-top: 20px;
white-space: pre-wrap;
.succMsg{
    color: #0C882A;
}
}
.errorMsg{
padding-top: 20px;
padding-left: 6px;
margin-top: 20px;
white-space: pre-wrap;
.errMsg{
    color: #C82613;
}
}
.onload_errorMsg{
padding-top: 20px;
padding-left: 6px;
margin-top: 20px;
white-space: pre-wrap;
.onload_errMsg{
    color: #C82613;
}
}
.below_shopping_cart_description,.bottom_TNC_display{
   margin-top: 20px;
   margin-bottom: 20px;
   font-family: Gotham-Book,Arial;
}
.partner-redem-brands {
#NewShopee {
    p{
        font-size: 15px;
    } 
}
#ExistShopee {
    p{
        font-size: 15px;
        height: 30px;
    } 
}
}
@media screen and (max-width: 480px){
.partner-redem-brands{
    border-right: 1px solid #eeeeee;
    /*#BOOST {
        img{
            height: 150px;
            width:220px !important;
        } 
    }*/
}
.partner-redem-brands.active{
    border: 1px solid #eeeeee;
}
.partner-redeem--voucher-to-use {
    padding-left: 2px !important;
    padding-right: 2px !important;
    .row{
        padding-left: 0px !important;
        .incrementer{
            padding-left: 0px !important;
        }
    }
    .lineclass{
        padding-left: 20px !important;
        padding-right: 15px !important;
    }
    .partner-redeem--total-points{
        .col-xs-6.col-sm-3{
            width:30% !important;
            padding-left: 5px !important;
        }
        .col-xs-6.col-sm-2{
            /*width:38%;*/
            padding-top: 5px !important;
            padding-left: 15px !important;
        }
        .col-xs-6.col-sm-1{
            width:14% !important;
            padding-top: 4px !important;
        }
        .col-xs-6.col-sm-6{
            padding-left: 15px;
            padding-top: 15px;
        }
    }
}
}
@media screen and (min-width: 481px) and (max-width: 767px) {
.partner-redem-brands{
    border-right: 1px solid #eeeeee;
    /*#BOOST {
        img{
            height: 150px;
            width:220px !important;
        } 
    }*/
}
.partner-redem-brands.active{
    border: 1px solid #eeeeee;
}
.partner-redeem--total-points{
    .col-xs-6.col-sm-3{
        width:35% !important;
        /*padding-left: 5px !important;*/
    }
    .col-xs-6.col-sm-2{
        /*width:38%;*/
        padding-top: 5px !important;
        padding-left: 15px !important;
    }
    .col-xs-6.col-sm-1{
        width:14% !important;
        padding-top: 4px !important;
    }
    .col-xs-6.col-sm-6{
        padding-left: 15px;
        padding-top: 15px;
    }
}
}
@media screen and (min-width: 768px){
.partner-redem-brands {
    height: 210px;
    width: 180px;
    /*#Shopee {
        img{
            height: 50px;
        } 
    }*/
}
}  
}