.qcvoucherform
{
    .qcvoucher--form-clear input:not([type=checkbox])
    {
        pointer-events: none;
    }
    @media screen and (min-width: 767px)
    {       
            #qcvoucherform
            {
                margin-left: 25%;
                margin-right: 25%;
            }
    }
    @media screen and (max-width: 768px)
    {
        .row
        {
            margin-bottom: 10px !important;
            font-size: 13px;
        }
    }
    .voucher-form-submit
    {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .disable_convert
    {
        opacity: 0.5;
        pointer-events: none;
        background-color: #1b1616 !important;
    }
    .submit
    {
        width: -webkit-fill-available !important;
    }
    .qcvoucher--form-convert
    {
        display: none !important;
    }
}