.enrich-benfit-desktop{
    @include respond-to(768) {
        display: none;
    }
}
.enrich-benfit-ipad{
    display: none;
    @include respond-to(1200) {
        display: block;
    }
}
.enrich_benefits {
    $tab-padding: 30px;
    $content-padding: 20px;
    $border-color: $lightgray;
    @mixin c056heading {
        font-family: $font-family-medium;
        color: $blue;
        font-size: rem(18);
        margin: 0;
        line-height: 1;
    }
    @mixin c056headlink($tab-padding, $content-padding, $border-color) {
      border-radius: 0;
      padding-top: $tab-padding;
      padding-right: $tab-padding;
      padding-bottom: 0;
      padding-left: $tab-padding;
      @include respond-to(1200) {
        padding: $content-padding;
        border-top: solid 1px $border-color;
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        &:hover, &:focus, &:active {
          border-left-width: 0;
          border-right-width: 0;
          border-bottom-width: 0;
        }
      }
    }

    .nav-tabs-wrapper {
        border-bottom: solid 1px $border-color;
        background-color: $seashell;
        padding-top: 40px;
        @include respond-to(1200) {
            border-bottom-color: transparent;
            background-color: $white;
        }
    }

    .nav-tabs {
        position: relative;
        top: 1px;
        max-width: 1240px;
        @include respond-to(1200) {
            top: 0;
            border-bottom: 0;
        }
        > li {
            margin-left: $tab-padding;
            @include respond-to(1200) {
                margin-left: 0;
                padding-right: $content-padding;
                padding-left: $content-padding;
                width: 100%;
            }
            &:first-child {
                margin-left: 0;
            }
            &.active {
                .label__img {
                    color: $red;
                }
            }
            > a {
              @include c056headlink($tab-padding, $content-padding, $border-color);
            }
        }
        // slick settings
        &.slick-slider {
            @media screen and (min-width: 1200px) {
                border-bottom: 0;
                max-width: 1080px;
                .slick-slide {
                    position: relative;
                    margin-right: $tab-padding;

                    &:focus {
                        outline: none;
                    }
                    &.active {
                        a {
                            border-top: solid 1px $border-color;
                            border-right: solid 1px $border-color;
                            border-left: solid 1px $border-color;
                            background-color: $white;
                            .label__img {
                                color: $red;
                            }
                            .card__img,
                            .label__img {
                                position: relative;
                            }
                        }
                    }
                    &:not(.active) {
                        a:hover {
                            background-color: #eee;
                            position: relative;
                            top: -1px;
                            .card__img, .label__img {
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                    a {
                        border-top: solid 1px transparent;
                        border-right: solid 1px transparent;
                        border-left: solid 1px transparent;
                        display: block;
                        &:focus {
                            outline: none;
                        }
                    }
                    img {
                        margin: 0 auto;
                    }
                }
                .nav-tabs__card {
                    text-align: center;
                    padding-top: $tab-padding;
                    padding-left: $tab-padding;
                    padding-right: $tab-padding;
                }
            }
        }
    }
    .nav-tabs,
    .nav-pills {
        text-align: center;
        > li {
            display: inline-block;
            float: none;
            zoom: 1; // hasLayout ie7 trigger
        }
    }
    .nav-tabs,
    .tab-content {
        margin: 0 auto;
        max-width: 1240px;
    }
    .nav-tabs__card {
        padding-bottom: $content-padding;
        @include respond-to(1200) {
            padding-bottom: 0;
        }
        img {
            max-width: 180px;
        }
        .label__img {
            font-family: $font-family-medium;
            padding-top: 10px;
            text-align: center;
            text-transform: uppercase;
            @include respond-to(1200) {
                font-family: $font-family;
                padding-top: 0;
                text-align: left;
                font-size: rem(18);
                i.fa {
                    text-align: center;
                    width: 14px;
                }
                span {
                    display: inline-block;
                    width: calc(100% - 14px - 0.3em);
                }
            }
        }
        .card__img {
            @include respond-to(1200) {
                display: none;
            }
        }
    }
    .tab-pane {
        padding-top: 40px;
        @include respond-to(1200) {
            padding-top: 10px;
        }
        /*@media screen and (min-width: 768px) and (max-width: 1199px) {
            padding-left: $content-padding;
            padding-right: $content-padding;
        }*/
    }
    .tab-content-wrapper {
        padding-bottom: 40px;
    }
    .tab-content {
        max-width: #{1240px - 140px};
    }
    &.enrich_benefits_mob {
      .title {
        margin-top: 30px;
      }
    }
    .mob-accordion {
      padding: 0 20px;

      > .panel-heading .panel-title {
        margin-bottom: 0;

          a {
          @include c056headlink($tab-padding, $content-padding, $border-color);
          display: block;
        }
      }

      .panel-body {
        margin-bottom: 40px;
      }
    }
    .card__info {
        &.card--basic-info {
            padding-bottom: $content-padding;
        }
        @include respond-to(767) {
            padding-bottom: $content-padding;
        }
    }
    .highlightborder
    {
        border: 3px solid #006DBB;
        border-right: none;
        border-left: none;
        padding-top: 10px;
    }
    .highlightborder {
        &:first-child {
            border-left: 3px solid #006DBB;
        }
    }
    .highlightborder {
        &:last-child {
            border-right: 3px solid #006DBB;
        }
    }
    .card__overview {
        border-bottom: solid 1px $border-color;
        padding-bottom: $tab-padding;
        margin-left: 0;
        margin-right: 0;
        @include respond-to(767) {
            border-bottom: 0;
            // offset bootstrap padding (15px)
            .card__info {
                padding-left: #{$content-padding - 15px};
                padding-right: #{$content-padding - 15px};
            }
        }
    }
    .card__content-img {
        @include respond-to(767) {
            text-align: center;
            margin-bottom: 35px;
        }
        img {
            padding-right: 5px;
            width: 100%;
            @include respond-to(767) {
                padding-right: 0;
                padding-left: 0;
                width: 80%;
            }
        }
    }
    .card__details {
        @include respond-to(767) {
            > .row:last-child > .col-xs-12:last-child .card__info {
                padding-bottom: 0;
            }
        }
        .title {
            @include c056heading;
            padding-bottom: 5px;
            text-transform: capitalize;
        }
        .desc {
            font-size: rem(14);
        }
    }
    .card__moreinfo {
        .ipad-mob_view{
            display: none;
        }
        .title {
            @include c056heading;
            padding-top: $content-padding;
            padding-bottom: $content-padding;
        }
        .desc {
            font-size: rem(14);
        }
        @include respond-to(767) {
            div:first-child .card__info .title {
                border-top: solid 1px $border-color;
            }
            .card__info {
                padding-left: $content-padding;
                padding-right: $content-padding;
            }
        }
    }
    .card__showmore {
        padding-top: 30px;
        .btn-show-more {
            width: 146px;
            display: none;
            @include respond-to(1024) {
                display: inline-block;
            }
            span {
                padding-left: 10px;
            }
        }
    }
    .card-info-accordion {
        .panel {
            border-top: solid 1px $border-color;
            &:last-child {
                border-bottom: solid 1px $border-color;
            }
        }
        .panel-title {
            margin: 0;
            line-height: rem(22);
            font-size: rem(16);
            a {
                display: inline-block;
                width: 100%;
                padding-top: $content-padding;
                padding-bottom: $content-padding;
                &:hover, &:focus, &:active {
                    color: $blue;
                }
            }
            i.fa {
                width: 14px;
                text-align: right;
            }
            span {
                display: inline-block;
                width: calc(100% - 14px - 0.3em);
            }
        }
        .panel-body {
            position: relative;
            top: -5px;
            padding-bottom: $content-padding;
        }
        .desc {
            font-size: rem(14);
            line-height: rem(22);
        }
    }
    @media screen and (max-width: 767px) {
        .highlightborder
        {
            border: 3px solid #006DBB;
            border-bottom: none;
            border-top: none;
            padding-top: 10px;
        }
        .highlightborder {
            &:first-child {
                border-top: 3px solid #006DBB;
            }
        }
        .highlightborder {
            &:last-child {
                border-bottom: 3px solid #006DBB;
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .highlightborder
        {
            border: 3px solid #006DBB;
           // border-right: none;
            //border-left: none;
            padding-top: 10px;
        }
        .highlightborder {
            &:first-child {
                border-bottom: none;
            }
        }
        .highlightborder {
            &:last-child {
                border-top: none;
            }
        }
    }
}
