.interactive-video{
	position: relative;
	//height: 600px;
	.content-holder{
		position: absolute;
		width: 40%;
		margin: 6% 30% 0;
		img{
			margin: 0 auto;
			display: block;
      margin-bottom: 15px;
		}
		p{
			color: $white;
			@include font-size(16);
			text-align: center;
			margin: 5% 0;
		}
		.btn-primary{
			width: 20%;
			margin: 0 auto;
			display: block;
			padding: 8px 15px 10px;
			.fa-play-circle{
				margin-right: 6px;
				@include font-size(26);
				line-height: 0.9em;
				margin-top: 5px;
			}
			span{
				position: relative;
				top: -3px;
			}
		}
	}
}

.mfp-content .interactive-holder{
	height: auto;
	min-height: 600px;
	overflow: hidden;
	display: block;

	@media screen and (max-width: 767px) {
		min-height: calc(100vh - 70px);
	}
}

@include respond-to(1500){
	.interactive-video{
		.content-holder{
			.btn-primary{
				width: 24%;
			}
		}
	}
}

@include respond-to(1300){
	.interactive-video{
		.content-holder{
			.btn-primary{
				width: 26%;
			}
		}
	}
}

@include respond-to(mid-screen-768-1200){
	.interactive-video{
		.content-holder{
			margin: 5% 30% 0;
			img{
				width: 15%;
			}
			.btn-primary{
				width: 32%;
			}
		}
	}
}

@include respond-to(1000){
	.interactive-video{
		.content-holder{
			width: 50%;
			margin: 3% 25% 0;
			.btn-primary{
				width: 30%;
			}
		}
	}
}

@include respond-to(ipad-portrait){
	.interactive-video{
		.content-holder{
			width: 50%;
			margin: 2% 25% 0;
			.btn-primary{
				width: 33%;
			}
		}
	}
}

@include respond-to(tablet-portrait){
	.interactive-video{
		.content-holder{
			width: 50%;
			margin: 15% 25% 0;
			.btn-primary{
				width: 45%;
			}
		}
	}
}

@include respond-to(600){
	.interactive-video{
		.content-holder{
			margin: 15% 20% !important;
			width: 60%;
			img{
				width: 25%;
			}
			.btn-primary{
				width: 40%;
			}
		}
	}
}

@include respond-to(500){
	.interactive-video{
		.content-holder{
			width: 70%;
			margin: 5% 10% !important;
			img{
				width: 25%;
			}
			.btn-primary{
				width: 45%;
			}
		}
	}
}

@include respond-to(440){
	.interactive-video{
		.content-holder{
			margin: 15% 6% !important;
			width: 80%;
			img{
				width: 15%;
			}
			p{
				@include font-size(14);
			}
			.btn-primary{
				width: 40%;
			}
		}
	}
}

@include respond-to(320){
	.interactive-video{
		.content-holder{
			.btn-primary{
				width: 50%;
			}
		}
	}
}

.interactive-holder{
  display: none;
  height: 365px;
  clear: both;
  overflow: hidden;
}
