#flight-status--container {
  .col-lg-4 {
    margin-top: 0 !important;
    width: 34.5% !important;
  }

  .status-flight__search-results {
    margin-top: 30px;

    .title {
      font-size: rem(20);
      padding-top: 20px;
      border-top: solid 1px $lightgray;
    }

    .subtitle {
      font-size: rem(14);
    }
  }
}

#mob-flightstatus--container, #mob-flightupgrade--container {
  .col-lg-4 {
    margin-top: 0;
  }

  .status-flight__search-results {

    .title {
      font-size: rem(20);
      padding-top: 20px;
      border-top: solid 1px $lightgray;
    }

    .subtitle {
      font-size: rem(14);
    }
  }
}

.status-cities--container,
.status-flightno--container {
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);

  .flight-direction {
    width: 20px !important;
    text-align: center;
    padding: 10px 0 0 !important;
  }
  .col-lg-1 {
    width: 11.5%;
    padding: 0 !important;
  }
  .col-lg-2 {
    padding: 0 !important;
  }
  .col-lg-4 {
    width: 33% !important;
  }
}

.status-flight__search-results {
  .dataTables_filter {
    display: none;
  }

  .flightstatus-searchresults__table {
    margin-bottom: 30px;
  }
}

@include respond-to(mid-screen-768-1200) {
  #flight-status--container {
    .flight-direction {
      width: 2%;
      clear: none;
      margin-top: 0;
    }
    .col-lg-1 {
      clear: both;
      display: block;
      width: 100%;
      #statusCitiesSubmit {
        margin-top: 15px;
      }
      button {
        width: 100%;
      }
    }
    .col-lg-2 {
      width: calc(28% - 20px) !important;
    }
    .col-lg-4 {
      width: 36% !important;

      &.statusToLocation {
        padding-right: 0;
      }
    }
    .status-cities--container {
      button {
        margin-top: 20px;
      }
    }
    .status-flightno--container {
      .col-lg-1 {
        clear: none;
        width: 20%;
        margin-top: 0;
      }
    }
  }
}

@include respond-to(tablet-portrait) {
  $mainnavheight: 76px;
  .mobile-ibe {
    #mob-flightstatus--container, #mob-flightupgrade--container {
      padding: 20px 20px 0 20px;
      .mobile-flightstatus {
        margin: 0;
      }
      .flightstatus-list {
        margin-bottom: 0;
        top: -10px;
        position: relative;
      }
      .statusFlightDate,
      .statusFromLocation,
      .statusToLocation,
      .statusFlightNoDate,
      .statusFromNoLocation {
        margin-bottom: 10px;
      }

      .status-cities--container {
        // padding: 2% 2% 0 !important;
      }
      .status-flightno--container {
        // padding: 2% 2% 0 !important;s
      }
    }
  }
}

@include respond-to(414) {
  .mobile-ibe {
    #mob-flightstatus--container, #mob-flightupgrade--container {
      /* MABUAT1-2 Fix */
      /* Class name change */
      >.container:not(.close-container) {
        padding: 0;
      }
      /* MABUAT1-2 Fix */
      .container>.row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
.flight-schedule-widget {
  @media screen and (min-width: 768px) {
    padding-bottom: 200px;
  }
  .ibe-form--container {
    position: relative;
    display: block;
    box-shadow: none;
    .search-container {
      display: block;
    }
  }
  #flight-schedule--container {    
    .trip-container {
      display: block;
    }
    h3 {
      color: #005aaa;
      margin-bottom: 10px;
      line-height: 1em;
      font-size: 1.5rem;
    }
    .fsch-description {
      font-size: .875rem;
      line-height: 1.3125rem;
      color: #61666f;
      padding-bottom: 15px;
    }
    padding: 30px;
    @include respond-to(tablet-portrait) {
      padding: 30px 0px;
      .col-xs-12 {
        margin-bottom: 10px;
        padding-left: 0px;
        padding-right: 0px;
        width: 100% !important;
      }
    }
    .search-container {
      .col-lg-4, .col-md-4, .col-sm-4 {
        width: 33.33333%;
        padding-left: 0;
      }
      .col-lg-1, .col-md-1, .col-sm-1 {
        width: 15.5%;        
      }
    }
    .fromfschLocation {
      padding-left: 0px;      
    }
    .location-container {
      padding-top: 10px;
      padding-bottom: 10px;
      @include respond-to(tablet-portrait) {
        padding-bottom: 0px;
      }
    }
  }
}

