#footer {
    margin-left: 0;
    margin-right: 0;
  }
  
  .footer-container {
    padding: 30px 8% 15px!important;
    background: $footercolor;
    position: relative!important;
    @include respond-to(ipad-portrait) {
      padding: 30px 6% 15px;
    }
    h6 {
      color: $holidays-form-color;
      @include font-size(14);
      font-family: $font-family-medium;
      margin-bottom: 10px !important;
      &.add-header {
        margin-top: 40px;
        @include respond-to(mid-screen-768-1200) {
          margin-top: 25px;
        }
      }
    }
  
    #accordion {
      li {
        width: 75%;
  
        img {
          width: 50%;
        }
      }
    }
  
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        padding: 5px 0;
        a {
          color: $gray !important;
          @include font-size(14);
          @include respond-to(ipad-portrait) {
            @include font-size(13);
          }
          &.blue-link {
            color: $linkblue !important;
            &:after {
              content: "\f105";
              display: inline-block;
              font: normal normal normal 24px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              @include font-size(14);
              line-height: 1em;
              color: $linkblue;
              position: relative;
              top: 1px;
              right: 0;
              left: 5px;
            }
          }
          &:hover {
            color: $linkblue !important;
            border-bottom: 1px dashed $linkblue;
          }
        }
      }
    }
    .event-date {
      font-family: $font-family-medium;
      @include font-size(14);
    }
    .logo-social--container {
      border-top: 1px solid $lightgray;
      border-bottom: 1px solid $lightgray;
      padding: 1% 0;
      margin: 2% 0 1%;
      .col-lg-4 {
        margin: 0;
        padding: 0;
        @include respond-to(ipad-portrait) {
          width: 50%;
        }
      }
      .partner-logo {
        li {
          &:hover {
            background: none;
          }
          a {
            &:hover {
              border: none;
            }
          }
        }
      }
      ul {
        width: 100%;
        li {
          float: left;
          width: auto;
          img {
            -webkit-transition: all 90ms ease-in-out;
            -o-transition: all 90ms ease-in-out;
            transition: all 90ms ease-in-out;
            height: 24px;
            margin-right: 15px;
            -webkit-filter: grayscale(0%);
            /* Safari 6.0 - 9.0 */
            -moz-filter: grayscale(0%);
            -ms-filter: grayscale(0%);
            -o-filter: grayscale(0%);
            filter: grayscale(0%);
          }
          &:hover {
            img {
              -webkit-filter: grayscale(100%);
              /* Safari 6.0 - 9.0 */
              -moz-filter: grayscale(100%);
              -ms-filter: grayscale(100%);
              -o-filter: grayscale(100%);
              filter: grayscale(100%);
            }
          }
          &:hover {
            background: $linkblue;
          }
        }
      }
      .social-media {
        text-align: right;
        li {
          -webkit-transition: all 90ms ease-in-out;
          -o-transition: all 90ms ease-in-out;
          transition: all 90ms ease-in-out;
          border: 1px solid $lightgray;
          @include border-radius(100px);
          width: 36px;
          height: 36px;
          display: block;
          margin-right: 5px;
          text-align: center;
          float: right;
          a {
            display: block;
            padding: 0;
            @include font-size(18);
          }
          &:hover a {
            color: $white !important;
            border-bottom: none;
          }
        }
      }
    }
    .footer-content--text {
      margin-top: 10px;
      @include respond-to(ipad-portrait) {
        width: 100%;
        padding: 0;
      }
      p {
        float: left;
        color: $gray;
        border-right: 1px solid $lightgray;
        padding-right: 20px;
        @include font-size(14);
        @include respond-to(ipad-portrait) {
          @include font-size(13);
          margin-left: 15px;
          padding-right: 15px;
        }
  
        span {
          display: inline-block;
        }
      }
      ul {
        float: left;
        padding-left: 20px;
        li {
          float: left;
          list-style-type: none;
          padding: 0 20px 0 0;
          width: auto;
          @include font-size(14);
          a {
            color: $gray;
          }
        }
      }
    }
    @include respond-to(ipad-portrait) {
      .col-lg-1 {
        display: none;
      }
    }
    #accordion {
      &.mobile-layout {
        .accordion-content {
          display: block;
          &.default {
            display: block;
          }
        }
      }
    }
    .desktop-footer {
      img {
        max-width: 100%;
      }
      .col-lg-1 {
        text-align: right;
        img {
          width: 47px;
          height: 47px;
        }
      }
    }
    .mobile-footer {
      display: none;
    }
    .accordion-container {
      .accordion-toggle {
        pointer-events: none;
        display: block;
        max-width: initial;
        margin-bottom: initial;
        font-weight: 600;
        .fa {
          display: none;
        }
      }
    }
    #back-to--top {
      position: absolute;
      right: 30px;
      z-index: 10;
      margin-top: -30px;
      a {
        background: $blue;
        display: block;
        padding: 3px 10px 25px;
        text-align: center;
        color: $white;
        p {
          width: 100%;
          float: left;
          clear: both;
          color: $white;
          font-size: rem(12) !important;
        }
      }
    }
  }
  
  @include respond-to(mid-screen-768-1200) {
    .footer-container {
      .col-sm-12.col-md-6.col-lg-3 {
        width: 50%;
        margin-bottom: 30px;
      }
    }
  }
  
  @include respond-to(tablet-portrait) {
    .footer-container {
      h6 {
        cursor: pointer;
        margin-bottom: 0 !important;
        &.add-header {
          margin-top: 0;
        }
        i {
          &.fa {
            float: right;
            @include font-size(16);
            line-height: rem(16);
          }
        }
      }
      .accordion-container {
        border-bottom: 1px solid $lightgray;
        padding: 12px 0 10px;
        .accordion-toggle {
          pointer-events: auto;
          .fa {
            display: block;
            margin-top: 4px;
          }
        }
      }
      #accordion {
        &.mobile-layout {
          margin-top: 10px;
          .accordion-content {
            display: none;
            &.default {
              display: block;
            }
          }
        }
      }
      .footer-content--text {
        //margin-top: 20px;
        p {
          display: none;
        }
        ul {
          margin: 10px 0 0;
          padding: 0;
          li {
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
      img {
        &.desktop {
          display: none;
        }
      }
      .mobile-footer {
        display: block !important;
        .footer-content--text {
          border-top: 0;
          width: 90%;
          display: block;
          height: auto;
          overflow: hidden;
          float: left;
          p {
            display: block !important;
            border: 0;
          }
        }
        .col-lg-1 {
          width: 10%;
          float: left;
          padding: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    .logo-social--container {
      display: none;
    }
  }
  
  @include respond-to(414) {
    .footer-container {
      .footer-content--text {
        ul {
          li {
            padding: 0 20px 0 0;
            a {
              @include font-size(13);
            }
          }
        }
      }
      .mobile-footer {
        width: 96%;
        margin: 0;
        padding: 0;
        .footer-content--text {
          margin: 10px 0 0 0;
          padding: 0;
          p {
            @include font-size(13);
            padding-right: 0;
          }
        }
      }
      #back-to--top {
        a {
          padding: 0 10px 22px;
        }
      }
    }
  }
  
  @include respond-to(320) {
    .footer-container {
      .footer-content--text {
        ul {
          li {
            a {
              @include font-size(12);
            }
          }
        }
      }
      .mobile-footer {
        .footer-content--text {
          width: 100%;
          p {
            @include font-size(12);
          }
        }
      }
    }
  }
  