
.members__content {
  position: relative;
  z-index: 1;
  margin: 0 auto;

  @include respond-to(1440) {
    width: 100%;
    max-width: $nav-width-less-than-1200;
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 20px;
    margin-top: 0px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;
    margin-top: 0px;
  }

  @media screen and (min-width: 1201px) {
    padding-left: $members-sidebar-width + 82px;
  }

  @media screen and (min-width: 1441px) {
    width: $nav-width-more-than-1200;
    margin-left: $nav-left-margin-more-than-1200;
    margin-right: $nav-left-margin-more-than-1200;
  }

  &--full-width {
    // width: 100%;
    @media screen and (min-width: 1201px) {
      padding-left: 0;
    }
  }

  /** Members -- Heading Styles */
  > h2.title:first-child {
    margin-top: -6px;
  }

  .members__overview.title {
    position: relative;
    margin: 0;
    font-family: $font-family;
    color: $blue !important;
    line-height: initial;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  h2.title {
    position: relative;
    margin: 0;
    font-family: $font-family;
    color: $blue;
    line-height: initial;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  h3 {
    color: $countdownblue;

    &.title {
      font-size: rem(20);
      margin-bottom: 10px;
    }
  }

  .description {
    img {
      margin-bottom: 20px;
    }
  }

}
