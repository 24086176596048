.members__content {
  /** Members -- Well Styles */
  .members-content-container,
  .well {
    margin-top: 20px;
  }

  .well {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}
