.image-richtext--editor{
  margin: 0;
  padding: 15px;
  p{
    font-family: $font-family;
    @include font-size(16);
    color: $gray;
    padding: 5px 0;
  }
  .img-content--holder{
    .img-holder{
      width:38%;
      float:left; 
      display: inline-block;
      margin-right: 2%;
    }
    .content-holder{
      width:60%;
      display:inline-block;
      margin-bottom:5px;
    }
    &.right-align{
      .img-holder{
        float: right;
        max-width: 38%;
        margin-left: 2% !important;
        margin-right: 0 !important;
      }
      .content-holder{
        float: right;
      }
    }
    &.logo-holder{
      .img-holder{
        max-width: 13%;
      }
      .content-holder{
        max-width:85%;
      }
    }
    &.center-align{
      .img-holder{
        img{
          text-align: center;
          margin: 0 auto 20px;
          display: block;
        }
      }
    }
    &.full-width{
      .img-holder, .content-holder{
        width: 100%;
      }
    }
    &.img-left{
      img{
        float: left;
        width: 40%;
        margin-right: 30px;
        margin-bottom: 15px;
        @include respond-to(1200){
          width: 100%;
        }
      }
    }
    &.img-right{
      img{
        float: right;
        width: 40%;
        margin-left: 30px;
        margin-bottom: 15px;
        @include respond-to(1200){
          width: 100%;
        }
      }
    }
  }
}


@include respond-to(tablet-portrait){
  .image-richtext--editor{
    .img-content--holder{
      .img-holder{
        width: 100%;
        clear: both;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 15px;
      }
      .content-holder{
        width: 100%;
        clear: both;
      }
      &.logo-holder{
        .img-holder{
          max-width: 100%;
          img{
            margin: 0 auto;
          }
        }
        .content-holder{
          max-width:100%;
        }
      }
    }
  }
}