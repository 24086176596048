.enrich-points-redeem-container {
    .enrich-points-redeem-wrapper {
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 10px;
        .tab-content {
            background-color: #fff;
            padding: 10px;
        }
        .nav-tabs {
            border-bottom: 0px;
            li {
                margin-bottom: -2px;
                a {
                    font-weight: bold;
                    color:#666;
                    padding: 15px 15px;
                    border: none; 
                    @include respond-to(tablet-portrait) {
                        font-size: 11px;
                        padding: 10px 5px;
                    }                  
                }
                &.active {
                    a {
                        border: none;
                        color: #005aaa;
                    }
                }
               
            }
        }
        .tab-content {
            .panel.panel-default {
                border: 2px solid #ccc;
                border-radius: 5px;
                padding: 10px;
                margin-bottom: 10px;
                &:nth-child(even) {
                    background-color: #f1f1f1;
                } 

                &:last-child {
                    margin-bottom: 0px;
                }
                .panel-heading {
                    .panel-title {
                        margin-bottom: 0px;
                        a {
                            display: inline-block;
                            width: 100%;
                            span {
                                color: #666;
                                @include respond-to(tablet-portrait) {
                                    font-size: 13px;
                                    font-weight: bold;
                                }
                            }
                            i.fa{
                                -webkit-transition: 0.35s all ease-in-out;
                                -o-transition: 0.35s all ease-in-out;
                                transition: 0.35s all ease-in-out;
                                &.fa-angle-up {
                                    font-weight: bold;
                                    float: right;
                                    margin-right: 10px; 
                                }
                            }
                            &[aria-expanded="true"] {
                                i.fa{
                                    -webkit-transition: 0.35s all ease-in-out;
                                    -o-transition: 0.35s all ease-in-out;
                                    transition: 0.35s all ease-in-out;
                                    &.fa-angle-up {
                                    -ms-transform: rotate(180deg) !important; /* IE 9 */
                                    -webkit-transform: rotate(180deg)!important; /* Safari prior 9.0 */
                                    transform: rotate(180deg)!important;
                                }
                            }
                            }
                        }
                    }
                }
            }
        }
    }
}