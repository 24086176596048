#members--messages {
    .dataTables_wrapper {
        .dataTables_length {
            float: right;
        }
    }
    table {
        border-top: 1px solid $benefit-table-border;
        border-bottom: 1px solid $benefit-table-border;
        width: 100% !important;
        &.dataTable {
            &.display {
                thead {
                    tr th {
                        text-align: left;
                    }
                }
                tbody {
                    td {
                        border-top: 1px solid $benefit-table-odd-row;
                        font-size: 13px;
                    }
                    tr {
                        &.odd:hover {
                            background-color: $benefit-table-odd-row;
                        }
                        &.even:hover {
                            background-color: $white;
                            >.sorting_1 {
                                background-color: $white;
                            }
                        }
                        .date:focus{
                            outline: none;
                        }
                    }
                }
            }
        }
        tbody {
            .odd {
                .sorting_1 {
                    background-color: inherit;
                }

            }
        }
        &.display {
            tbody {
                tr.odd {
                    background-color: $benefit-table-odd-row;
                }
            }
        }
    }
}
#members--benefits
{
    background-color: white;
    padding-left: 17px;
    padding-right: 17px;
    .dataTables_wrapper {
        .dataTables_length {
            float: right;
        }
    }
    table {
        border-top: 1px solid $benefit-table-border;
        border-bottom: 1px solid $benefit-table-border;
        width: 100% !important;
        .sorting_1
        {
            background-color:  $white;
        }
        &.dataTable {
            &.display {
                thead {
                    background-color: $benefit-table-odd-row;
                    tr th {
                        text-align: left;
                    }
                }
                tbody {
                    td {
                        border-top: 1px solid #d6cece;
                        font-size: 13px;
                    }
                    tr {
                        &.odd:hover {
                            background-color: $white;
                        }
                        &.even:hover {
                            background-color: $white;
                            >.sorting_1 {
                                background-color: $white;
                            }
                        }
                        .date:focus{
                            outline: none;
                        }
                    }
                }
            }
        }
        tbody {
            .odd {
                .sorting_1 {
                    background-color: inherit;
                }

            }
        }
        &.display {
            tbody {
                tr.odd {
                    background-color:  $white;
                }
            }
        }
    }
}