.member--gold, .member--platinum, .member--silver, .member--blue, .member--BLUE, .member--SILV,.enrich-revamp, .member--GOLD, .member--PLAT,.member--PLATP {
    .mabe-nav--overlay, .mob-enrich-menu-right  {
         .sidebar-menu-link {    
    i.fa {
      -webkit-transition: 0.25s all ease-in-out;
      -o-transition: 0.25s all ease-in-out;
      transition: 0.25s all ease-in-out;
      float: right;    
      line-height: 4.375rem;
      font-size: x-large;
      width: 0 !important;
    margin-left: -64px;
    margin-right: 64px;
    }

i.fa-angle-right{
    -ms-transform: rotate(90deg) !important; /* IE 9 */
   -webkit-transform: rotate(90deg)!important;; /* Safari prior 9.0 */
   transform: rotate(90deg)!important;; 
}
    > .members__menu {
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    .sidebar-menu-link.collapsed {
      i.fa {
        transform: scaleY(-1) !important;
      }
    }
  }

        .sidebar-menu--level-1{
            .enborder{                 
                 border-bottom: 1px solid #F1F1F1;
                 border-top: 1px solid #F1F1F1;
            }
        }
      .enrichclose
      {
          border-bottom: 1px solid #F1F1F1;
          padding-left: 286px;
           @media screen and (min-width: 320px) and (max-width: 568px) 
           {
              padding-left: 233px;
           }
           @media screen and (min-width: 360px) and (max-width: 640px) 
           {
              padding-left: 270px;
           }
           @media screen and (min-width: 375px) and (max-width: 667px) 
           {
              padding-left: 286px;
           }  
           @media screen and (min-width: 411px) and (max-width: 731px) 
           {
              padding-left: 320px;
           }
            @media screen and (min-width: 414px) and (max-width: 736px) 
           {
              padding-left: 326px;
           }

           
       
          padding-top: 20px;
          width: 446px;
          height: 60px;
          .close_slide{
              margin-top: -18px;
              margin-left: 34px;
          }

      }
        .sidebar-menu--level-1
        {
               background-color:white !important;
               border: 1px solid white;
        .members__page
          {
            .sidebar-menu--level-2, .sidebar-menu--level-3 , .sidebar-menu--level-4
            {
             background: #61666F !important;
             color: white;
            }
            line-height: 4;
            .alleft{
                margin-left: 99px !important;
                color: white !important;
            }
            .sidebar-menu-link{
                font: normal normal 14px/16px Gotham,Arial;               
                color: white;
                margin-left: 66px;
            }
           }

        }

        .sidebar-menu-link{
           font: normal normal 14px/16px Gotham,Arial;            
        }


        z-index: 9 !important;
        width: 100% !important;
        @media screen and (min-width: 768px) and (max-width: 1200px) {
            width: 375px !important;
        }
        background: white;
        background-color:none !important;
        border: 1px solid #F1F1F1;

        ul {
            margin: auto!important;
            top:5%;
            max-width: 100%;
          
            li {  
               
                min-height: 45px;
                a {
                    color: black!important;
                    margin-top: 10px;
                    &:focus, &:hover, &:visited {
                       color: black!important;
                   }
               }
            }
            // @media screen and (min-width: 768px) {
            //     li {
            //         margin-left: 28px;
            //     }
            // }
        }
        a {
            &.slideOutClose {
                // margin-left: 336px;
                //  margin-top: 30px;
                 color: black;
                 padding-right: 30px;
                 font-size:14px;
                 vertical-align: middle;
                 .slide_close{
                     font: normal normal 14px/16px Gotham,Arial;
                 }
            }
            }
        }
        .fa-close {
            font-size: 21px;
            padding-left: 5px;
        }
        .logo {
            margin: 0 auto;
            position: relative;
            text-align: center;
            img {
                width: 131px;
                height: 23px;
               /* margin-top: 30px;*/
            }
        }
   
   @media screen and (min-width: 768px) and (max-width: 1200px) {
        .mabe-nav--overlay {
             
            &.height--expanded {
                height: 100%;
            }
            .navbar-header {
                .navbar-brand {
                    position: relative;
                }
            }
            .navbar-nav {
                margin: 0 !important;
                clear: both;
                width: 100%;
                background-color: $seashell;
            }
            .close-slide {
                @include font-size(20);
                line-height: 1em;
                color: $blue;
                cursor: pointer;
                float: right;
                display: block;
                text-align: right;
                padding: 2% 3% 2% 0;
            }
        }
        // .slideout-menu-right {
        //     right: 0;
        // }
        // .slideout-menu-member {
        //     position: fixed;
        //     top: 0;
        //     bottom: 0;
        //     width: 310px;
        //     overflow-y: auto;
        //     -webkit-overflow-scrolling: touch;
        //     z-index: 0;
        //     background-color: $white;
        // }
        .visible-tab-menu{
            display: block !important;
            height: 41rem !important;
        }
    }   
}
